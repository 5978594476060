import { BigNumber } from "@ethersproject/bignumber";
import { Currency } from "configs/web3";
import { BEToken } from "store/wallets/types";

export interface AmountToDepositProps {
  onChangeInput: (value: string) => void;
  onBlur: () => void;
  value: string;
  title: string;
  currency: BEToken | null | Currency;
  balancePosition?: BalancePositionEnum;
  balanceTitle?: string;
  tokenBalance?: string | BigNumber;
  isAmountDisabled?: boolean;
  isHaveFiatEquivalent?: boolean;
  isError?: boolean;
  isTouched?: boolean;
  pending?: boolean;
  isMinButtonDisabled?: boolean;
  isMaxButtonDisabled?: boolean;
  currentRate?: number;
  handleMaxButtonClick?: () => void;
  handleMinButtonClick?: () => void;
}

export type StyledAmountToDepositProps = { isTouched?: boolean; isError?: boolean };

export interface AmountToDepositButtonsSectionProps
  extends Pick<
    AmountToDepositProps,
    "handleMaxButtonClick" | "handleMinButtonClick" | "isMaxButtonDisabled" | "isMinButtonDisabled" | "pending"
  > {}

export interface AmountToDepositBalanceSectionProps {
  pending: boolean;
  formattedBalance: string;
  balanceTitle: string;
}

export enum BalancePositionEnum {
  top = "top",
  bottom = "bottom",
}
