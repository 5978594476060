import styled, { css } from "styled-components";
import { FlexGap } from "components/layout";
import { Svg, Text } from "components";
import { GetStylesProps, StyledStepProp, StyledStepTextProp } from "./types";

const getStyles = ({ currentStep, index, theme }: GetStylesProps) => {
  if (currentStep - 1 === index) {
    // current
    return css`
      background: ${({ theme }) =>
        theme.isDark
          ? `radial-gradient(circle at center bottom, ${theme.colors.primary + "33"} 0%, ${
              theme.colors.transparent
            } 100%)`
          : "none"};
      border: 1px solid ${theme.colors.primary};
      color: ${theme.colors.neutral800};
      justify-content: center;
      min-width: 150px;
      padding-left: 12px;
      padding-right: 12px;

      ${theme.mediaQueries.tablet} {
        justify-content: start;
      }
    `;
  } else if (currentStep > index) {
    // success
    return css`
      background: ${({ theme }) =>
        theme.isDark
          ? `radial-gradient(circle at center bottom, ${theme.colors.green + "33"} 0%, ${
              theme.colors.transparent
            } 100%)`
          : theme.colors.greenLight};
      border: 1px solid ${theme.colors.green};
      color: ${theme.colors.green};
    `;
  } else {
    // next
    return css`
      background-color: ${theme.colors.transparent};
      border: 1px solid ${theme.colors.neutral300};
      color: ${theme.colors.neutral500};
    `;
  }
};

const getBgColor = ({ currentStep, index, theme }: GetStylesProps) => {
  if (currentStep - 1 === index) {
    // current
    return css`
      background-color: ${theme.colors.primary};
    `;
  } else if (currentStep > index) {
    // success
    return css`
      background-color: ${theme.colors.green};
    `;
  } else {
    // next
    return css`
      background-color: ${theme.colors.neutral300};
    `;
  }
};

const getColor = ({ currentStep, index, theme }: GetStylesProps) => {
  if (currentStep - 1 === index) {
    // current
    return css`
      color: ${theme.colors.neutral800};
    `;
  } else if (currentStep > index) {
    // success
    return css`
      color: ${theme.colors.green};
    `;
  } else {
    // next
    return css`
      color: ${theme.colors.neutral500};
    `;
  }
};

export const StyledStep = styled.div<StyledStepProp>`
  display: flex;
  width: ${({ stepCount, currentStep, index }) => (currentStep - 1 === index ? `50%` : `${50 / (stepCount - 1)}%`)};
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  height: 72px;
  min-width: 0;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-out;
  position: relative;
  flex-direction: row;
  padding-left: 0;
  padding-right: 0;

  ${({ theme }) => theme.mediaQueries.tablet} {
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
  }

  &:not(:last-child):after {
    content: "";
    position: absolute;
    width: 8px;
    height: 1px;
    right: -9px;

    ${({ theme }) => theme.mediaQueries.tablet} {
      width: 18px;
      right: -19px;
    }

    ${getBgColor}
  }

  ${getStyles}
`;

export const StyledTextWrapper = styled(FlexGap)<StyledStepTextProp>`
  width: ${({ currentStep, index }) => (currentStep - 1 === index ? "100%" : "0")};
  flex-direction: column;
  transition: all 0.3s ease-in-out;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: ${({ currentStep, index }) => (currentStep - 1 === index ? "14px" : "0")};
  }
`;

export const StyledIconWrapper = styled.div<StyledStepTextProp>`
  transition: all 0.3s ease-in-out;
  width: ${({ currentStep, index }) => (currentStep - 1 === index ? "0" : "100%")};
  justify-content: center;
  display: ${({ currentStep, index }) => (currentStep - 1 < index ? "none" : "flex")};

  ${Svg} {
    transition: all 0.3s ease-in-out;
    width: ${({ currentStep, index }) => (currentStep - 1 === index ? "0" : "16px")};
    height: ${({ currentStep, index }) => (currentStep - 1 === index ? "0" : "16px")};
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    display: none;
  }
`;

export const StyledStepSubText = styled(Text)<StyledStepTextProp>`
  font-size: ${({ currentStep, index }) => (currentStep - 1 === index ? "initial" : "0")};
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  white-space: nowrap;
  min-width: fit-content;
  color: ${({ theme }) => (theme.isDark ? theme.colors.neutral1000 : theme.colors.neutral500)};
`;

export const StyledStepText = styled(Text)<StyledStepTextProp>`
  text-align: start;
  font-size: ${({ currentStep, index }) => (currentStep - 1 === index ? "initial" : "0")};
  width: ${({ currentStep, index }) => (currentStep - 1 === index ? "100%" : "0")};
  transition: all 0.3s ease-in-out;

  ${({ theme }) => theme.mediaQueries.tablet} {
    font-size: 16px;
    width: 100%;
    text-align: ${({ currentStep, index }) => (currentStep - 1 === index ? "start" : "center")};
  }

  ${getColor}
`;
