import styled from "styled-components";

export const StyledWrapper = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  top: 28px;
  left: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndices.dropdown};

  ${({ theme }) => theme.mediaQueries.laptop} {
    top: 100px;
  }
`;

export const StyledBackButton = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  width: fit-content;
  transition: all 0.3s ease;

  &:active {
    transform: scale(0.95);
  }
`;
