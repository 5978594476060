import React, { useEffect, useRef, useState } from "react";
import moment from "moment";

import { useHandleDateRange } from "hooks";
import { useTranslation } from "context";

import { Box, Flex, RangeDayPicker, Text } from "components";
import { CloseIcon, DropdownIcon } from "components/svg";
import { StyledDateSelect } from "../../styled";

import { AccordionContent } from "components/accordion/styled";

import { DateRange } from "react-day-picker";
import { MobileDateSelectProps } from "./types";

export const MobileRangeDateSelect: React.FC<MobileDateSelectProps> = ({
  setDates,
  dates,
  isNeedToScrollDown = false,
}) => {
  const [isShow, setIsShow] = useState(false);

  const { t } = useTranslation();
  const { selectedRange, handleSetSelectedDate, resetRange, handleSelectedRange, setSelectedRange } =
    useHandleDateRange({
      dateFrom: dates.dateFrom,
      dateTo: dates.dateTo,
      setDate: setDates,
    });

  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!dates.dateFrom && !dates.dateTo) {
      setSelectedRange({ rangeFrom: "", rangeTo: "" });
    }
  }, [dates.dateFrom, dates.dateTo]);

  const scrollToBottom = () => {
    setTimeout(() => {
      bottomRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, 200);
  };

  return (
    <Box>
      <StyledDateSelect
        position="relative"
        width="100%"
        onClick={() => {
          setIsShow(!isShow);
          if (isNeedToScrollDown) {
            scrollToBottom();
          }
        }}
      >
        <Flex p="8px" flex="1">
          {dates.dateFrom && dates.dateTo ? (
            <Text textScale="caption1" $fontWeight="medium" color="neutral600">
              {moment(dates.dateFrom).format("MM/DD/YY")} - {moment(dates.dateTo).format("MM/DD/YY")}
            </Text>
          ) : (
            <Text textScale="caption1" $fontWeight="medium" color="neutral600">
              {t("Filter Date")}
            </Text>
          )}
        </Flex>

        <Flex
          height="100%"
          p="8px"
          onClick={e => {
            e.stopPropagation();
          }}
          alignItems="center"
        >
          {dates.dateFrom && dates.dateTo ? (
            <CloseIcon
              width="14px"
              color="neutral500"
              onClick={e => {
                e.stopPropagation();
                setIsShow(false);
                resetRange();
                handleSelectedRange(undefined);
              }}
            />
          ) : (
            <DropdownIcon
              width="12px"
              onClick={() => {
                setIsShow(!isShow);
                if (isNeedToScrollDown) {
                  scrollToBottom();
                }
              }}
            />
          )}
        </Flex>
      </StyledDateSelect>

      <AccordionContent isOpen={isShow} ref={bottomRef}>
        {isShow && (
          <RangeDayPicker
            range={{ from: selectedRange.rangeFrom, to: selectedRange.rangeTo }}
            setDoneClick={(dateRange: DateRange | undefined) => {
              handleSelectedRange(dateRange);
              handleSetSelectedDate(dateRange);
              setIsShow(false);
            }}
            setCloseClick={() => setIsShow(false)}
            numberOfMonth={1}
            isSideMenuVersion
          />
        )}
      </AccordionContent>
    </Box>
  );
};
