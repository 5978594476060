import styled from "styled-components";
import { generateBackgroundGradient } from "utils/helpers";

export const StyledMobileMenu = styled.div<{ isActive: boolean; headerHeight: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral0};
  position: fixed;
  //dvh applied for correct display on iPhones
  height: ${({ headerHeight }) => `calc(100dvh - ${headerHeight}px)`};
  top: ${({ headerHeight }) => headerHeight}px;
  transform: ${({ isActive }) => (isActive ? `translateX(0%)` : `translateX(-100%)`)};
  transition: transform 0.3s ease;
  width: 100vw;
  overflow-y: auto;
  z-index: 10;
  padding: 16px;
  left: 0;
`;

export const StyledMobileMenuItem = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.neutral200};
  border-radius: 8px;
  max-width: 140px;
  width: 100%;
  padding: 14px;
  background-color: ${({ theme, isActive }) => (isActive ? theme.colors.neutral100 : theme.colors.neutral0)};
  cursor: pointer;

  &:hover {
    background-image: ${({ theme }) => generateBackgroundGradient(theme, "linear-180")};
  }
`;
