import React from "react";
import { motion } from "framer-motion";

import { Box, Column, Flex, Text } from "components";
import { RecordIcon, Svg } from "components/svg";
import { StyledAnimatedCircle, StyledRecordingCircle, StyledVoicePhrase } from "./styled";

import { useThemeContext, useTranslation } from "context";

import { useAppSelector } from "store/store";

import { getCircleFill } from "./animations";

const Recording: React.FC = () => {
  const isVoiceRecord = useAppSelector(state => state.verifyBiometric.isVoiceRecord);
  const { theme } = useThemeContext();
  const { t } = useTranslation();

  // radius of circle 45px + 5px thickness of line, we receive width 100px of circle
  const radius = 45;
  // circle length
  const circumference = Math.ceil(2 * Math.PI * radius);
  const fillPercents = Math.abs(Math.ceil(circumference / 100));

  return (
    <>
      <Column height="100px" width="100%" alignItems="center" mt="40px">
        <Flex justifyContent="center" alignItems="center">
          <Box position="absolute">
            <RecordIcon width="60px" />
          </Box>
          <Box height={100}>
            <Svg viewBox="0 0 100 100" width={100} height={100}>
              <StyledRecordingCircle
                cx="50"
                cy="50"
                r={radius}
                className="circle"
                strokeWidth={5}
                stroke={theme.colors.neutral300}
                fill="transparent"
              />
            </Svg>
            <StyledAnimatedCircle viewBox="0 0 100 100" width={100} height={100}>
              <motion.circle
                cx="50"
                cy="50"
                r={radius}
                strokeWidth={5}
                stroke={theme.colors.primary}
                fill="transparent"
                strokeDashoffset={fillPercents}
                strokeDasharray={circumference}
                variants={getCircleFill(circumference)}
                initial="hidden"
                animate={isVoiceRecord ? "show" : "hidden"}
              />
            </StyledAnimatedCircle>
          </Box>
        </Flex>
        <StyledVoicePhrase>
          <Text p="6px 12px" textScale="body3" $fontWeight="bold">
            {t("My voice is my password")}
          </Text>
        </StyledVoicePhrase>
      </Column>
    </>
  );
};

export default Recording;
