export const WITHDRAW_MODAL_COMMISSION_ITEMS = [
  // Monthly Subscription is temporarily disabled.
  // {
  //   commissionsName: "Monthly Subscription:",
  //   commissionsValue: CUSTODY_FEE,
  //   pending: false,
  //   tooltipText:
  //     'This fee is charged from your Hot Wallet monthly as per the standard procedure. However, if you withdraw earlier, you will be charged in accordance with the amount being withdrawn for the current month of using our Cold Storage. The rate is dynamic and depends on the total amount stored in Cold Vault upon fee retraction (regular monthly payment or upon withdrawal). By activating "Slide to confirm" you confirm that you are familiar with our Fee policy. If you don\'t have the required amount on your Hot Wallet, you will not be able to withdraw until a Monthly subscription fee retraction is possible.',
  // },
  {
    commissionsName: "Withdrawal fee:",
    commissionsValue: "",
    pending: false,
    tooltipText:
      "Withdrawal fee is charged from your Hot Wallet in the cryptocurrency of withdrawal and its amount depends on the current rate. If you don't have the required amount, you will not be able to withdraw until withdrawal fee retraction is possible.",
  },
];
