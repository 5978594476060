//key name in lower case, because we accept response fiat currency from backend in lower case

import { FiatCurrencyCodeEnum } from "store/profile/settings/types";

export const fiatCurrencies: Record<
  FiatCurrencyCodeEnum,
  {
    value: string;
    src: string;
    symbol: string;
  }
> = {
  aed: {
    value: "AED",
    src: "/images/flags/AED.svg",
    symbol: "AED",
  },
  ars: {
    value: "ARS",
    src: "/images/flags/ARS.svg",
    symbol: "$",
  },
  aud: {
    value: "AUD",
    src: "/images/flags/AUD.svg",
    symbol: "A$",
  },
  bdt: {
    value: "BDT",
    src: "/images/flags/BDT.svg",
    symbol: "৳",
  },
  bhd: {
    value: "BHD",
    src: "/images/flags/BHD.svg",
    symbol: "BHD",
  },
  bmd: {
    value: "BMD",
    src: "/images/flags/BMD.svg",
    symbol: "$",
  },
  brl: {
    value: "BRL",
    src: "/images/flags/BRL.svg",
    symbol: "R$",
  },
  cad: {
    value: "CAD",
    src: "/images/flags/CAD.svg",
    symbol: "$",
  },
  chf: {
    value: "CHF",
    src: "/images/flags/CHF.svg",
    symbol: "F",
  },
  clp: {
    value: "CLP",
    src: "/images/flags/CLP.svg",
    symbol: "$",
  },
  cny: {
    value: "CNY",
    src: "/images/flags/CNY.svg",
    symbol: "¥",
  },
  czk: {
    value: "CZK",
    src: "/images/flags/CZK.svg",
    symbol: "Kč",
  },
  dkk: {
    value: "DKK",
    src: "/images/flags/DKK.svg",
    symbol: "kr",
  },
  eur: {
    value: "EUR",
    src: "/images/flags/EUR.svg",
    symbol: "€",
  },
  gbp: {
    value: "GBP",
    src: "/images/flags/GBP.svg",
    symbol: "£",
  },
  hkd: {
    value: "HKD",
    src: "/images/flags/HKD.svg",
    symbol: "$",
  },
  huf: {
    value: "HUF",
    src: "/images/flags/HUF.svg",
    symbol: "Ft",
  },
  idr: {
    value: "IDR",
    src: "/images/flags/IDR.svg",
    symbol: "Rp",
  },
  ils: {
    value: "ILS",
    src: "/images/flags/ILS.svg",
    symbol: "₪",
  },
  inr: {
    value: "INR",
    src: "/images/flags/INR.svg",
    symbol: "₹",
  },
  jpy: {
    value: "JPY",
    src: "/images/flags/JPY.svg",
    symbol: "¥",
  },
  krw: {
    value: "KRW",
    src: "/images/flags/KRW.svg",
    symbol: "₩",
  },
  kwd: {
    value: "KWD",
    src: "/images/flags/KWD.svg",
    symbol: "KWD",
  },
  lkr: {
    value: "LKR",
    src: "/images/flags/LKR.svg",
    symbol: "₨",
  },
  mmk: {
    value: "MMK",
    src: "/images/flags/MMK.svg",
    symbol: "K",
  },
  mxn: {
    value: "MXN",
    src: "/images/flags/MXN.svg",
    symbol: "$",
  },
  myr: {
    value: "MYR",
    src: "/images/flags/MYR.svg",
    symbol: "RM",
  },
  ngn: {
    value: "NGN",
    src: "/images/flags/NGN.svg",
    symbol: "₦",
  },
  nok: {
    value: "NOK",
    src: "/images/flags/NOK.svg",
    symbol: "kr",
  },
  nzd: {
    value: "NZD",
    src: "/images/flags/NZD.svg",
    symbol: "$",
  },
  php: {
    value: "PHP",
    src: "/images/flags/PHP.svg",
    symbol: "₱",
  },
  pkr: {
    value: "PKR",
    src: "/images/flags/PKR.svg",
    symbol: "₨",
  },
  pln: {
    value: "PLN",
    src: "/images/flags/PLN.svg",
    symbol: "zł",
  },
  rub: {
    value: "RUB",
    src: "/images/flags/RUB.svg",
    symbol: "₽",
  },
  sar: {
    value: "SAR",
    src: "/images/flags/SAR.svg",
    symbol: "SAR",
  },
  sek: {
    value: "SEK",
    src: "/images/flags/SEK.svg",
    symbol: "kr",
  },
  sgd: {
    value: "SGD",
    src: "/images/flags/SGD.svg",
    symbol: "S$",
  },
  thb: {
    value: "THB",
    src: "/images/flags/THB.svg",
    symbol: "฿",
  },
  try: {
    value: "TRY",
    src: "/images/flags/TRY.svg",
    symbol: "₺",
  },
  uah: {
    value: "UAH",
    src: "/images/flags/UAH.svg",
    symbol: "₴",
  },
  usd: {
    value: "USD",
    src: "/images/flags/USD.svg",
    symbol: "$",
  },
  vef: {
    value: "VEF",
    src: "/images/flags/VEF.svg",
    symbol: "Bs",
  },
  vnd: {
    value: "VND",
    src: "/images/flags/VND.svg",
    symbol: "₫",
  },
  zar: {
    value: "ZAR",
    src: "/images/flags/ZAR.svg",
    symbol: "R",
  },
};

export const fiatCurrenciesList = Object.values(fiatCurrencies);
