import { Loader } from "components";
import { lazyLoad } from "utils/loadable";

export const LeftPart = lazyLoad(
  () => import("./left-part"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const RightPart = lazyLoad(
  () => import("./right-part"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);
