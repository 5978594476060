import styled from "styled-components";

import { DIMENSIONS } from "hooks/use-upload-image/constants";

export const StyledPreviewFile = styled.div`
  position: relative;

  width: ${DIMENSIONS.mobileWidthView}px;
  height: ${DIMENSIONS.heightView}px;
  background: ${({ theme }) => theme.colors.grey1};
  border-radius: ${({ theme }) => theme.radii.semiMedium};

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: ${DIMENSIONS.widthView}px;
  }

  & > div {
    border-radius: ${({ theme }) => theme.radii.semiMedium};
  }

  & > div > div {
    &:after,
    &:before {
      content: none;
    }
  }
`;

export const StyledImageCropperInput = styled.input`
  display: none;
`;
