import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 72 72" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_4623_103932)">
        <rect x="18" y="49" width="36" height="12" rx="4" fill={theme.colors.green} />
      </g>
      <circle cx="36" cy="36" r="28" fill={theme.colors.greenLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.green} strokeOpacity="0.5" />
      <path
        d="M28.4966 49.5057H25.4954C23.8378 49.5057 22.4941 48.162 22.4941 46.5044V43.5032"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.502 22.4944H46.5032C48.1607 22.4944 49.5045 23.8381 49.5045 25.4956V28.4969"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4941 28.4969V25.4956C22.4941 23.8381 23.8378 22.4944 25.4954 22.4944H28.4966"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.5045 43.5032V46.5044C49.5045 48.162 48.1607 49.5057 46.5032 49.5057H43.502"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="36" cy="36" r="9" fill={theme.colors.green} fillOpacity="0.14" />
      <path
        d="M32.4983 32.9988V33.9992"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.5002 32.9988V33.9992"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.501 38.6891C37.4947 40.4393 34.5043 40.4393 32.498 38.6891"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.5854 33.3519C44.8586 34.2082 44.9996 35.1011 45.0036 36C45.0036 40.9726 40.9725 45.0037 35.9998 45.0037C31.0272 45.0037 26.9961 40.9726 26.9961 36C26.9961 31.0273 31.0272 26.9962 35.9998 26.9962C36.8987 27.0002 37.7916 27.1412 38.6479 27.4144"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.0026 28.2717L43.0148 30.2596L41.7383 28.985"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.green} strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_4623_103932"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_4623_103932" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
