import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral600", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent" stroke={theme.colors[color]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4555 12.8675C11.8274 12.8675 12.9395 11.7553 12.9395 10.3834C12.9395 9.01155 11.8274 7.89941 10.4555 7.89941C9.08358 7.89941 7.97144 9.01155 7.97144 10.3834C7.97144 11.7553 9.08358 12.8675 10.4555 12.8675Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8915 10.9238C18.1107 7.49635 15.96 4.36271 12.6831 3.33473C9.40612 2.30674 5.8509 3.65045 4.07308 6.58889C2.29526 9.52734 2.75489 13.3001 5.18622 15.7258L10.4554 21.004"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M17.5024 14.001V17.0022" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M21.0039 17.781C21.0039 17.3511 20.6554 17.0026 20.2256 17.0026H14.7793C14.3494 17.0026 14.001 17.3511 14.001 17.781"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0014 21.0039H20.0035C20.556 21.0039 21.0039 20.556 21.0039 20.0035V16.437C21.0038 16.0316 20.9218 15.6304 20.7628 15.2575L20.4847 14.6082C20.3273 14.2401 19.9657 14.0012 19.5653 14.001H15.4396C15.0388 14.0008 14.6767 14.2397 14.5192 14.6082L14.2421 15.2565C14.0831 15.6294 14.001 16.0306 14.001 16.436V20.0035C14.001 20.556 14.4489 21.0039 15.0014 21.0039Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
