import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";

import { useTranslation } from "context";
import { useMatchBreakpoints, useTooltip } from "hooks";
import { useAppDispatch, useAppSelector } from "store/store";
import { useHandleFiltersTags } from "./hooks";

import { Box, Button, Flex, FlexGap, Skeleton, Text } from "components";
import { FiltersIcon, FiltersSelectedIcon, GreenCheckMark } from "components/svg";
import {
  SIDE_MODAL_LAPTOP_WIDTH,
  SIDE_MODAL_LAPTOP_X_PADDING,
  SIDE_MODAL_MOBILE_X_PADDING,
  SIDE_MODAL_MOBILE_WIDTH,
  StyledSideModalWrapper,
} from "../styled";
import CloseModalWrapper from "../close-modal-wrapper";
import { FiltersSection, WidgetBuyersListSideModalTable } from "./components";
import { StyledAnimatedFiltersSectionContainer, StyledWidgetBuyersListFiltersButton } from "./styled";
import { FiltersTag } from "components/colored-tags";

import { hideModal } from "store/modal";

const WidgetBuyersListSideModal: React.FC = () => {
  const pending = useAppSelector(state => state.escrowsTable.widgetBuyersListData.pending);
  const name = useAppSelector(state => state.escrowsTable.widgetBuyersListPayload.name);
  const escrowCount = useAppSelector(state => state.escrowsTable.widgetBuyersListPayload.escrowCount);

  const [isShow, setIsShow] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMobileM, isMobileS } = useMatchBreakpoints();

  const isMobileSideModal = isMobileM || isMobileS;

  const { tags } = useHandleFiltersTags();

  const { targetRef: targetRefName, tooltip: nameTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const showFiltersModal = () => {
    setIsShow(prev => !prev);
  };

  return (
    <StyledSideModalWrapper position="relative">
      <AnimatePresence>
        <StyledAnimatedFiltersSectionContainer
          animate={isShow ? "animate" : "initial"}
          variants={{
            initial: { x: isMobileSideModal ? SIDE_MODAL_MOBILE_WIDTH : SIDE_MODAL_LAPTOP_WIDTH },
            animate: { x: isMobileSideModal ? -SIDE_MODAL_MOBILE_X_PADDING : -SIDE_MODAL_LAPTOP_X_PADDING },
          }}
          transition={{ duration: 0.5 }}
        >
          <FiltersSection showFiltersModal={showFiltersModal} />
        </StyledAnimatedFiltersSectionContainer>
      </AnimatePresence>

      <CloseModalWrapper closeModalHandler={closeModalHandler} mb="4px">
        <Text textScale="caption2" color="neutral600">
          {t("Buyers List")}
        </Text>
      </CloseModalWrapper>

      <Flex mb="24px" minHeight="32px">
        {pending ? (
          <Skeleton width="328px" height="32px" />
        ) : (
          <>
            <Text textScale="body2" color="neutral600" $fontWeight="medium" ref={targetRefName} ellipsis>
              {name}
            </Text>
            {nameTooltip}

            <Text textScale="body2" color="neutral600" $fontWeight="medium">
              ,
            </Text>

            <Text textScale="body2" color="neutral600" $fontWeight="medium" ellipsis mx="4px" minWidth="fit-content">
              {escrowCount}
            </Text>
          </>
        )}
      </Flex>

      <Box mb="20px">
        <Box mb="8px">
          {pending ? (
            <Skeleton width={{ _: "100%", mobileL: "44px" }} height="44px" />
          ) : (
            <FlexGap gap="14px" flexDirection={{ _: "column", mobileL: "row" }} width="100%">
              {isMobileSideModal ? (
                <Button
                  onClick={showFiltersModal}
                  variant="outline"
                  borderColor="neutral300"
                  width="100%"
                  color="neutral600"
                  endIcon={tags.length && <GreenCheckMark width="24px" />}
                >
                  {t("Filters")}
                </Button>
              ) : (
                <StyledWidgetBuyersListFiltersButton
                  onClick={showFiltersModal}
                  minWidth="44px"
                  padding="10px"
                  variant="outline"
                  borderColor="neutral300"
                  startIcon={tags.length ? <FiltersSelectedIcon width="24px" /> : <FiltersIcon width="24px" />}
                />
              )}
            </FlexGap>
          )}
        </Box>

        <FlexGap gap="8px">
          {tags.map(({ name, removeHandler }, index) => (
            <FiltersTag key={index} name={name} removeTagHandler={removeHandler} />
          ))}
        </FlexGap>
      </Box>

      <WidgetBuyersListSideModalTable />
    </StyledSideModalWrapper>
  );
};

export default WidgetBuyersListSideModal;
