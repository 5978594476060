import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color={color}>
      <path
        d="M5 14H9"
        stroke={theme.colors.neutral600}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 18H9"
        stroke={theme.colors.neutral600}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 6H9"
        stroke={theme.colors.neutral600}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 10H9"
        stroke={theme.colors.neutral600}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 12L19 16"
        stroke={theme.colors.neutral600}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 8L15 12"
        stroke={theme.colors.neutral600}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
