import React, { useEffect, useState } from "react";
import { isEqual } from "lodash";

import { useTranslation } from "context";
import { useAppDispatch, useAppSelector } from "store/store";
import { useHandleFilters } from "./hooks";

import CloseModalWrapper from "../close-modal-wrapper";
import { Box, Button, MobileRangeDateSelect, RadioButton, RadioButtonGroup, Text } from "components";
import { StyledConfirmContainer, StyledFilterSectionTitle, StyledSideModalWrapper } from "../styled";

import { hideModal } from "store/modal";

import { EscrowDashboardMobileFiltersModalProps } from "./types";
import { SingleRadioOption } from "components/radio-button/types";

const EscrowDashboardMobileFiltersModal: React.FC = () => {
  const payload = useAppSelector(
    state => (state.modal.props as unknown as EscrowDashboardMobileFiltersModalProps).payload,
  );

  const { dateFrom, dateTo, status } = payload;

  const [dates, setDates] = useState({ dateFrom, dateTo });

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isHaveStatusInPayload = Object.hasOwn(payload, "status");

  const { selectsList, applyHandler } = useHandleFilters({ payload, isHaveStatusInPayload });

  const stateFiltersObj = {
    dateFrom: dates.dateFrom,
    dateTo: dates.dateTo,
    status: selectsList[0]?.value.value ? selectsList[0].value.value : undefined,
  };

  const storeFiltersObj = {
    dateFrom,
    dateTo,
    status,
  };

  useEffect(() => {
    if (!dateFrom && !dateTo) {
      setDates({ dateFrom: undefined, dateTo: undefined });
    }
  }, [dateFrom, dateTo]);

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const handleApplyFilters = () => {
    applyHandler(dates);
    closeModalHandler();
  };

  return (
    <StyledSideModalWrapper height="calc(100dvh - 92px)">
      <CloseModalWrapper closeModalHandler={closeModalHandler} mb="56px">
        <Text textScale="body2" $fontWeight="medium" color="neutral600" mx="16px">
          {t("Filters")}
        </Text>
      </CloseModalWrapper>

      <Box>
        {selectsList.map(({ value, handler, option, name }, index) => {
          const { value: selectedValue } = value;
          return (
            <Box key={index} mb="24px">
              <StyledFilterSectionTitle textScale="body3">{t(name)}</StyledFilterSectionTitle>

              <RadioButtonGroup
                selectedValue={selectedValue}
                onClickRadioButton={handler}
                name={name}
                mb="32px"
                mt="20px"
              >
                {(option as SingleRadioOption[]).map(({ value, label, name }, index) => {
                  return (
                    <RadioButton
                      name={name}
                      key={index}
                      value={value}
                      label={label}
                      width="100%"
                      mb={option.length - 1 !== index ? "16px" : ""}
                    />
                  );
                })}
              </RadioButtonGroup>
            </Box>
          );
        })}
      </Box>

      <Box
        borderTop={isHaveStatusInPayload ? "1px solid" : null}
        borderColor={isHaveStatusInPayload ? "neutral200" : "transparent"}
      >
        <Box pt={isHaveStatusInPayload ? "24px" : "0px"}>
          <StyledFilterSectionTitle textScale="body3">{t("Date")}</StyledFilterSectionTitle>

          <Box mt="20px">
            <MobileRangeDateSelect dates={dates} setDates={setDates} isNeedToScrollDown />
          </Box>
        </Box>
      </Box>

      <StyledConfirmContainer>
        <Button width="100%" onClick={handleApplyFilters} disabled={isEqual(stateFiltersObj, storeFiltersObj)}>
          {t("Apply")}
        </Button>
      </StyledConfirmContainer>
    </StyledSideModalWrapper>
  );
};

export default EscrowDashboardMobileFiltersModal;
