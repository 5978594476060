import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";
import { ColdStorageDepositModalProps } from "components/modal/components/cold-storage-deposit-modal/types";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => (state.modal.props as unknown as ColdStorageDepositModalProps).coldWallet,
    (state: AppState) => state.hotWallets.networks,
    (state: AppState) => state.walletsFee.fiatFeeColdStorage.minAmountToCreate.inFiat,
    (state: AppState) => state.walletsFee.fiatFeeColdStorage.minAmountToDeposit.inFiat,
  ],
  (coldWallet, networks, minAmountToCreateInFiat, minAmountToDepositInFiat) => ({
    coldWallet,
    networks,
    minAmountToCreateInFiat,
    minAmountToDepositInFiat,
  }),
);
