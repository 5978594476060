import { createAsyncThunk } from "@reduxjs/toolkit";
import { isErrorResult, makeApiRequest, ENDPOINTS_RATES } from "services";
import { ErrorResult } from "services/types";
import { ExchangeRatesResponse } from "./types";

export const getExchangeRates = createAsyncThunk<ExchangeRatesResponse[], undefined, { rejectValue: ErrorResult }>(
  "rates/get-exchange-rates",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<ExchangeRatesResponse[]>({
      method: "GET",
      url: ENDPOINTS_RATES.exchangeRates,
      isShowError: false,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);
