import { showModal } from "store/modal";
import { useAppDispatch } from "store/store";

import { useHandleVerify } from "./";
import { useTranslation } from "context";

import { ModalProps } from "store/modal/types";
import { SetupBiometricModalTypes } from "components/modal/components/setup-biometric-modal/types";

const useShowVerifyModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onConfirmClick = () => {
    const _showModal = showModal as ModalProps<SetupBiometricModalTypes>;

    dispatch(
      _showModal({
        modalName: "setupBiometricModal",
        modalType: "fullScreenOnMobile",
        rootId: "cold-storage",
        props: {
          useHandleBiometric: useHandleVerify,
          title: t("Face biometric verify"),
          imageSrc: "/images/biometrics/face-biometrics.webp",
        },
      }),
    );
  };

  return { onConfirmClick };
};

export default useShowVerifyModal;
