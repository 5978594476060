import React from "react";
import moment from "moment";

import { useTranslation } from "../context";
import { calculateExpiredTime } from "utils/helpers";

import { ModalWarningIcon } from "../components/svg";

import { useAppDispatch, useAppSelector } from "store/store";
import { UserTwoFaStatusEnum } from "store/profile/types";
import { hideModal, showModal } from "store/modal";
import { ModalProps } from "store/modal/types";

import { ActionModalProps } from "../components/modal/components/action-modal/types";
import { ROUTES } from "../navigation/routes";

const SECONDS_IN_ONE_DAY = 24 * 60 * 60; /*hours minutes seconds 86_400*/

export const useTwoFaLastUpdateCheck = ({ rootId }: { rootId: (typeof ROUTES)[keyof typeof ROUTES] }) => {
  const user = useAppSelector(state => state.profile.user);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const expirationSeconds = calculateExpiredTime(user?.twoFaLastUpdate, "seconds");
  const is24HoursPassed = expirationSeconds * -1 > SECONDS_IN_ONE_DAY;
  const hours = moment.utc(expirationSeconds * 1000).format("HH");
  const minutes = moment.utc(expirationSeconds * 1000).format("mm");

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const showActionIsDisabledModal = () => {
    const _showModal = showModal as ModalProps<ActionModalProps>;

    dispatch(
      _showModal({
        modalName: "actionModal",
        rootId: rootId,
        props: {
          icon: <ModalWarningIcon height="72px" width="72px" />,
          title: t("Action is disabled"),
          text: t(
            "The action was disabled for 24 hours after authenticator app reset. It will be restored in %hours% hours %minutes% minutes",
            {
              // remove the extra zero from the values like "07"
              hours: +hours + "",
              minutes: +minutes + "",
            },
          ),
          btnArr: [
            {
              buttonText: t("OK"),
              callback: closeModalHandler,
            },
          ],
        },
      }),
    );
  };

  const callbackWithTwoFaLastUpdateCheck = (successCallback: () => void, unsuccessCallback?: () => void) => {
    if (user?.twoFaStatus === UserTwoFaStatusEnum.REINSTALLED && !is24HoursPassed) {
      showActionIsDisabledModal();
      unsuccessCallback?.();
    } else {
      successCallback();
    }
  };

  return { callbackWithTwoFaLastUpdateCheck };
};
