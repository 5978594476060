import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";
import { SetupBiometricModalTypes } from "./types";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => (state.modal.props as unknown as SetupBiometricModalTypes).useHandleBiometric,
    (state: AppState) => (state.modal.props as unknown as SetupBiometricModalTypes).imageSrc,
    (state: AppState) => (state.modal.props as unknown as SetupBiometricModalTypes).title,
    (state: AppState) => (state.modal.props as unknown as SetupBiometricModalTypes).biometricAction,
    (state: AppState) => (state.modal.props as unknown as SetupBiometricModalTypes).type,
  ],
  (useHandleBiometric, imageSrc, title, biometricAction, type) => ({
    useHandleBiometric,
    imageSrc,
    title,
    biometricAction,
    type,
  }),
);
