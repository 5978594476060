import * as yup from "yup";
import BigNumber from "bignumber.js";

import { useGetFiatSymbolAndAbbreviation, useGetCurrencyRate } from "hooks";

import { useTranslation } from "context";
import { formatValueToBNString } from "configs/web3";

import { useAppSelector } from "store/store";

import { CommissionPayer } from "pages/main/create-escrow/types";
import { InitialValues } from "./types";

import { DESCRIPTION_LENGTH, MAX, MIN, BROKERS_COMMISSION_PERCENTAGE } from "pages/main/create-escrow/constants";

const useValidationSchema = () => {
  const currentEscrow = useAppSelector(state => state.escrowProcess.currentEscrow);
  const minAmount = useAppSelector(state => state.createEscrow.minAmount);

  const currencyCode = currentEscrow ? currentEscrow.body.head.currency.publicCode : "";

  const { currentRate } = useGetCurrencyRate(currencyCode);

  const { t } = useTranslation();
  const { fiatSymbol } = useGetFiatSymbolAndAbbreviation();

  const initialValues: InitialValues = {
    name: currentEscrow?.body.products[0].name || "",
    description: currentEscrow?.body.products[0].description || "",
    website: currentEscrow?.body.products[0].url || "",
    amount: currentEscrow ? formatValueToBNString(currentEscrow.body.products[0].price) : "",
    escryptoFee: currentEscrow ? formatValueToBNString(currentEscrow.body.head.fee) : "",
    coversEscryptoFee: currentEscrow?.body.head.feePaidBy ?? CommissionPayer.buyer,
    coversBrokersCommission: currentEscrow?.body.broker?.commissionPaidBy ?? CommissionPayer.buyer,
    brokersCommission: currentEscrow?.body.broker?.commission ?? "",
    isShipped: currentEscrow?.isShippable ?? false,
  };

  const amountMinValue = (val: string, context: yup.TestContext<yup.AnyObject>) => {
    const amountInFee = currentEscrow ? formatValueToBNString(BigNumber(val).times(currentRate)) : "0";

    if (currentEscrow?.body.broker && BigNumber(amountInFee).lt(minAmount.withBroker.inFiat)) {
      return context.createError({
        path: context.path,
        message: t("Minimum value, equivalent to %value%", {
          value: `${fiatSymbol}${BigNumber(minAmount.withBroker.inFiat).toFormatExtended(2)}`,
        }),
      });
    } else if (!currentEscrow?.body.broker && BigNumber(amountInFee).lt(minAmount.withoutBroker.inFiat)) {
      return context.createError({
        path: context.path,
        message: t("Minimum value, equivalent to %value%", {
          value: `${fiatSymbol}${BigNumber(minAmount.withoutBroker.inFiat).toFormatExtended(2)}`,
        }),
      });
    }

    return true;
  };

  const brokersCommissionIsRequired = (val: string | undefined) => {
    if (currentEscrow?.body.broker && !val) {
      return false;
    }
    return true;
  };

  const brokersCommissionValueLimit = (val: string | undefined, context: yup.TestContext<yup.AnyObject>) => {
    if (currentEscrow?.body.broker && val && +val <= 0) {
      return context.createError({
        path: context.path,
        message: t("Minimum broker fee > 0%"),
      });
    } else if (
      (currentEscrow?.body.broker && val && +val > +context.from?.[0].value.amount * BROKERS_COMMISSION_PERCENTAGE) ||
      !context.from?.[0].value.amount
    ) {
      return context.createError({
        path: context.path,
        message: t("Maximum fee for broker = 20%"),
      });
    }
    return true;
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required(t("This field is required"))
      .min(MIN, t("Must contain %value% or more characters", { value: MIN }))
      .max(MAX, t("The entered value is too long, maximum %value% characters", { value: MAX })),
    description: yup
      .string()
      .max(
        DESCRIPTION_LENGTH,
        t("Description must be less than %descriptionLength% characters", { descriptionLength: DESCRIPTION_LENGTH }),
      ),
    website: yup.string().url(t("Invalid URL address")),
    amount: yup.string().required(t("This field is required")).test("minValue", "", amountMinValue),
    escryptoFee: yup.string().required(t("This field is required")),
    coversEscryptoFee: yup.string(),
    coversBrokersCommission: yup.string(),
    brokersCommission: yup
      .string()
      .test("isRequired", t("This field is required"), brokersCommissionIsRequired)
      .test("valueLimit", "", brokersCommissionValueLimit),
    isShipped: yup.boolean(),
  });
  return { validationSchema, initialValues };
};

export default useValidationSchema;
