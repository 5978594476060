import styled from "styled-components";

export const StyledWidgetBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  border: 1px solid ${({ theme }) => theme.colors.neutral300};
  box-shadow: ${({ theme }) => theme.shadows.modal};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 156px;
  pointer-events: none;
  padding-left: 8px;
  padding-right: 8px;
`;
