import { useAppSelector } from "store/store";

import { PingOneRisk, UserTwoFaStatusEnum } from "store/profile/types";

export const useValidationTests = () => {
  const user = useAppSelector(state => state.profile.user);
  const isLoggedIn = useAppSelector(state => state.auth.isLoggedIn);

  const authenticatorOtpIsRequired = (val: string | undefined) => {
    return (
      (val && isLoggedIn) ||
      (val && !!user && user.riskLevel === PingOneRisk.high) ||
      (!!user && user.twoFaStatus === UserTwoFaStatusEnum.ABSENT && user.riskLevel === PingOneRisk.high) ||
      (!!user && user.riskLevel === PingOneRisk.medium)
    );
  };
  return {
    authenticatorOtpIsRequired,
  };
};
