import { useEffect } from "react";
import { SetFieldTouchedType } from "./types";

// this custom click hook handle the case of a user clicking on the
// autocomplete field from google maps to prevent overwriting
// the data (validation) for the selected country.

export const useCountryCode = (setFieldTouched: SetFieldTouchedType) => {
  const googleAutocompleteContainer = document.querySelector<HTMLElement>(".pac-container.pac-logo.hdpi");

  useEffect(() => {
    if (!googleAutocompleteContainer) {
      return;
    }

    const handleClickOutside = (event: MouseEvent): void => {
      const target = event.target as Node;
      if (!googleAutocompleteContainer.contains(target)) {
        setFieldTouched("country", true);
      } else {
        setFieldTouched("country", false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [googleAutocompleteContainer]);
};
