import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { savePreview, getEscrowPreview, removePreview, createEscrowFromWidget, getWidgetPreviewList } from "./actions";
import { WidgetPreviewPayload, WidgetState } from "./types";

const initialState: WidgetState = {
  error: null,
  pending: false,
  currentEscrowPreview: null,
  widgetPreviewList: {
    data: [],
    count: 0,
    pending: false,
  },
  widgetPreviewPayload: {
    page: 1,
    size: 10,
  },
};

const widgetSlice = createSlice({
  name: "widget",
  initialState: {
    ...initialState,
  },

  reducers: {
    resetWidget: () => {
      return initialState;
    },
    setWidgetPreviewPayload: (state, action: PayloadAction<Partial<WidgetPreviewPayload>>) => {
      state.widgetPreviewPayload = { ...state.widgetPreviewPayload, ...action.payload };
    },
  },

  extraReducers: builder => {
    builder
      .addCase(savePreview.fulfilled, state => {
        state.pending = false;
        state.error = null;
      })
      .addCase(savePreview.pending, state => {
        state.pending = true;
      })
      .addCase(savePreview.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(getEscrowPreview.fulfilled, (state, action) => {
        state.currentEscrowPreview = action.payload;

        state.pending = false;
        state.error = null;
      })
      .addCase(getEscrowPreview.pending, state => {
        state.pending = true;
      })
      .addCase(getEscrowPreview.rejected, (state, action) => {
        if (action.payload) {
          state.currentEscrowPreview = initialState.currentEscrowPreview;

          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(removePreview.fulfilled, state => {
        state.currentEscrowPreview = initialState.currentEscrowPreview;

        state.pending = false;
        state.error = null;
      })
      .addCase(removePreview.pending, state => {
        state.pending = true;
      })
      .addCase(removePreview.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(createEscrowFromWidget.fulfilled, state => {
        state.pending = false;
        state.error = null;
      })
      .addCase(createEscrowFromWidget.pending, state => {
        state.pending = true;
      })
      .addCase(createEscrowFromWidget.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(getWidgetPreviewList.fulfilled, (state, action) => {
        state.widgetPreviewList = { ...action.payload, pending: false };

        state.error = null;
      })
      .addCase(getWidgetPreviewList.pending, state => {
        state.widgetPreviewList.pending = true;
      })
      .addCase(getWidgetPreviewList.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.widgetPreviewList.pending = false;
        }
      });
  },
});

export const { resetWidget, setWidgetPreviewPayload } = widgetSlice.actions;

export default widgetSlice;
