import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "neutral500", ...props }) => {
  return (
    <Svg viewBox="0 0 19 17" {...props} color={color}>
      <path d="M16.9679 0H6.63498C6.27606 0 5.98633 0.28973 5.98633 0.648649V5.32973C5.98633 5.68865 6.27606 5.97838 6.63498 5.97838C6.9939 5.97838 7.28363 5.68865 7.28363 5.32973V1.2973H16.7928V14.9622H7.28363V10.9297C7.28363 10.5708 6.9939 10.2811 6.63498 10.2811C6.27606 10.2811 5.98633 10.5708 5.98633 10.9297V15.1373C5.98633 15.7557 6.49011 16.2595 7.10849 16.2595H16.9679C17.5863 16.2595 18.0901 15.7557 18.0901 15.1373V1.12216C18.0901 0.503783 17.5863 0 16.9679 0Z" />
      <path d="M10.3092 10.8128C10.0562 11.0658 10.0562 11.4766 10.3092 11.7296C10.4368 11.8571 10.6011 11.9198 10.7676 11.9198C10.9341 11.9198 11.1005 11.8571 11.2259 11.7296L14.3676 8.58795C14.6205 8.33498 14.6205 7.92417 14.3676 7.67119L11.2259 4.52957C10.973 4.2766 10.5622 4.2766 10.3092 4.52957C10.0562 4.78255 10.0562 5.19336 10.3092 5.44633L12.3438 7.48093H0.648649C0.28973 7.48093 0 7.77066 0 8.12957C0 8.48849 0.28973 8.77822 0.648649 8.77822H12.3438L10.3092 10.8128Z" />
    </Svg>
  );
};

export default Icon;
