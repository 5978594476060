import React from "react";

import { Column, Text } from "components";

import { useTranslation } from "context";
import { EmptyMessagesIcon } from "components/svg";

const EmptyMessages: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Column justifyContent="center" alignItems="center" height="100%">
      <EmptyMessagesIcon width="72px" />
      <Text pt="16px" textAlign="center" textScale="body2" color="neutral600">
        {t("No messages yet")}
      </Text>
    </Column>
  );
};

export default EmptyMessages;
