import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledModalWrapper = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.neutral1000 + "66"};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: grid;
  overflow: auto;
  justify-content: center;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndices.modal};
  cursor: pointer;
  overflow-x: hidden;
`;

export const StyledModalContainer = styled(motion.div)`
  margin: 24px 16px;
  ${({ theme }) => theme.mediaQueries.tablet} {
    margin: 24px 32px;
  }
  background-color: ${({ theme }) => theme.colors.neutral0};
  box-shadow: ${({ theme }) => theme.shadows.modal};
  border-radius: 16px;
  cursor: initial;
`;

export const StyledModalFullScreenContainer = styled(StyledModalContainer)`
  margin: 0;
  box-shadow: none;
  border-radius: 0;
  ${({ theme }) => theme.mediaQueries.tablet} {
    box-shadow: ${({ theme }) => theme.shadows.modal};
    border-radius: 16px;
  }
`;

export const StyledRightMenuContainer = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.neutral0};
  position: fixed;
  inset-inline-end: 0;
  height: 100vh;
  cursor: initial;
`;
