import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral600", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" color={color} {...props}>
      <path
        d="M17.5399 10.12L13.8799 6.45996"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.251 21.0003H3V17.7493C3 17.4843 3.105 17.2293 3.293 17.0423L16.627 3.70731C17.018 3.31631 17.651 3.31631 18.041 3.70731L20.292 5.95831C20.683 6.34931 20.683 6.98231 20.292 7.37231L6.958 20.7073C6.771 20.8953 6.516 21.0003 6.251 21.0003V21.0003Z"
        stroke={theme.colors[color]}
        fill={theme.colors.transparent}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
