import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral600", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" width="24px" {...props} color="transparent">
      <circle
        id="Oval"
        cx="12.0003"
        cy="11.9998"
        r="9.00375"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0019 14.0025H7.99854V9.00042C7.99854 8.44819 8.44672 8 8.99895 8H15.0015C15.5537 8 16.0019 8.44819 16.0019 9.00042V14.0025Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M5.99805 18.709V16.0008C5.99805 14.8954 6.89342 14 7.99888 14H16.0022C17.1077 14 18.003 14.8954 18.003 16.0008V18.709"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_3"
        d="M13.2507 10.5V11.5004"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_4"
        d="M10.7497 10.5V11.5004"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
