import React from "react";

import { EmptyWallets, FlexGap, Loader, StyledContentWrapper, Tab, TabPanelWithSelect } from "components";
import { HistoryTable, Swap } from "./components";

import { useAppSelector } from "store/store";

import { useHandleTabAndFilter } from "./hooks";

import { ROUTES } from "navigation/routes";
import { tabsIndex } from "configs";
import { selectCombinedData } from "./selectors";

const SwapPage: React.FC = () => {
  const { pendingUser, networks, pendingWallets, walletsData, activeTab } = useAppSelector(selectCombinedData);

  return (
    <FlexGap
      flexDirection={{ _: "column", laptop: "row" }}
      gap={{ _: "12px", laptop: "24px" }}
      height="100%"
      id={ROUTES.swap}
    >
      <StyledContentWrapper
        minWidth={{ _: "100%", laptop: activeTab === tabsIndex.swap.swap ? "calc(56% - 12px)" : "100%" }}
        // fixes a bug with empty space inside a container on mobile devices
        height="unset"
        flexGrow="1"
      >
        <TabPanelWithSelect useHandleTabAndFilter={useHandleTabAndFilter} />

        {(pendingUser || (pendingWallets && activeTab === tabsIndex.swap.swap)) && !walletsData.length ? (
          <Loader height="100%" />
        ) : !networks && activeTab === tabsIndex.swap.swap ? (
          <EmptyWallets />
        ) : (
          <Tab active={activeTab === tabsIndex.swap.swap}>
            <Swap />
          </Tab>
        )}

        <Tab active={activeTab === tabsIndex.swap.history}>
          <HistoryTable />
        </Tab>
      </StyledContentWrapper>
    </FlexGap>
  );
};

export default SwapPage;
