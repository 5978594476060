import { useEffect } from "react";
import { toast } from "react-toastify";

import { toastOptionsInfo } from "components";

import { useTranslation } from "context";

import { getMyKycStatus } from "store/kyc/actions";
import { showModal } from "store/modal";
import { useAppDispatch, useAppSelector } from "store/store";

import { getMyProfile } from "store/profile/actions";
import { MyKYCStatusResponse, KYCStatuses } from "store/kyc/types";

import { isErrorResult } from "../services";

export const useKycHandler = ({ shouldCheckStatus }: { shouldCheckStatus: boolean }) => {
  const user = useAppSelector(state => state.profile.user);
  const statusKYC = useAppSelector(state => state.kyc.statusKYC);
  const pending = useAppSelector(state => state.kyc.pending);

  const isInProgress = Object.keys(statusKYC).some(
    key => statusKYC[key as keyof KYCStatuses] && statusKYC[key as keyof KYCStatuses] !== "Completed",
  );

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user && shouldCheckStatus) {
      if (!user.isVerifiedKYC) {
        getKycStatus({ showInProgressToaster: false });
      }
    }
  }, [user, shouldCheckStatus]);

  const getIsAllCompleted = (data: MyKYCStatusResponse) => {
    return Object.keys(data).every(key => {
      const verifyTypeData = data[key as keyof MyKYCStatusResponse];
      if (verifyTypeData) {
        return verifyTypeData.status === "Completed";
      }
      return true;
    });
  };

  const getIsAllMatchStatus = (data: MyKYCStatusResponse) => {
    return Object.keys(data).every(key => {
      const verifyTypeData = data[key as keyof MyKYCStatusResponse];
      if (verifyTypeData) {
        return verifyTypeData.matchStatus === "match";
      }
      return true;
    });
  };

  const getKycStatus = ({ showInProgressToaster }: { showInProgressToaster: boolean }) => {
    if (!pending) {
      dispatch(getMyKycStatus()).then(response => {
        if (!isErrorResult(response.payload)) {
          // If no kyc status was found show modal
          type KYCStatusKeys = keyof MyKYCStatusResponse;
          const statusForKYC = response.payload!;
          const keysForKYCArray = Object.keys(statusForKYC) as KYCStatusKeys[];
          const isNoVerifyTypeData = keysForKYCArray.every(key => !statusForKYC[key]);
          if (isNoVerifyTypeData) {
            openModal();
          } else {
            const data = response.payload!;

            const isAllCompleted = getIsAllCompleted(data);
            const isAllMatchStatus = getIsAllMatchStatus(data);

            if (isAllCompleted && isAllMatchStatus) {
              // In that case user might not be updated in time so we call to update
              getCurrentUser();
            } else if (isAllCompleted && !isAllMatchStatus) {
              // No match means that user has something wrong with his documents in that case need to show modal
              openModal();
            } else {
              // in other cases when data.status is "WaitAsync" | "Created" | "InProgress" just wait for kyc to complete
              if (showInProgressToaster) {
                toast.info(
                  `${t("Your KYC is in progress.")} ${t(
                    "Please wait for the KYC to be completed, that may take some time.",
                  )}`,
                  toastOptionsInfo,
                );
              }
            }
          }
        }
      });
    }
  };

  const openModal = () => {
    dispatch(
      showModal({
        modalName: "identityVerification",
        rootId: "/",
        // Prevents user to accidentally close modal
        clickOutsideHandler: () => {},
      }),
    );
  };

  const getCurrentUser = () => {
    dispatch(getMyProfile());
  };

  const callbackWithKycCheck = (callback: () => void) => {
    if (!user?.isVerifiedKYC) {
      getKycStatus({ showInProgressToaster: true });
    } else {
      callback();
    }
  };

  return { isInProgress, callbackWithKycCheck, openModal, getIsAllCompleted, getIsAllMatchStatus };
};
