import React from "react";

import { Flex, SingleSelect, Skeleton, TabPanel } from "components";

import { SingleOption } from "components/selects/single-select/types";
import { TabPanelWithSelectProps } from "./types";

const TabsAndSelect: React.FC<TabPanelWithSelectProps> = ({
  maxWidthTabMobile = "420px",
  maxWidthTabTablet = "338px",
  maxWidthSelectMobile = "420px",
  maxWidthSelectTablet = "258px",
  idForTabPanel,
  useHandleTabAndFilter,
}) => {
  const { tabs, activeTab, pending, options, selectedOption, isSelectShow, onTabClick, handleSelectChange } =
    useHandleTabAndFilter();

  return (
    <Flex
      flexDirection={{ _: "column", tablet: "row" }}
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      mb={{ _: "24px", tablet: "40px" }}
    >
      <Flex width="100%" justifyContent={{ _: "center", tablet: "flex-start" }}>
        <TabPanel
          width="100%"
          maxWidth={{ _: maxWidthTabMobile, tablet: maxWidthTabTablet }}
          mb={{ _: isSelectShow ? "12px" : "0px", tablet: "0px" }}
          tabs={tabs}
          activeTab={activeTab}
          onTabClick={onTabClick}
          idForTabPanel={idForTabPanel}
        />
      </Flex>

      {isSelectShow && (
        <Flex width="100%" justifyContent={{ _: "center", tablet: "flex-end" }}>
          {isSelectShow && pending ? (
            <Skeleton height="44px" width="100%" maxWidth={{ _: maxWidthSelectMobile, tablet: maxWidthSelectTablet }} />
          ) : isSelectShow && !pending ? (
            <SingleSelect
              options={options}
              value={selectedOption}
              onChange={option => handleSelectChange(option as SingleOption)}
              maxWidth={{ _: maxWidthSelectMobile, tablet: maxWidthSelectTablet }}
            />
          ) : null}
        </Flex>
      )}
    </Flex>
  );
};

export default TabsAndSelect;
