import styled from "styled-components";

export const StyledMicrophone = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: ${({ theme }) => theme.radii.circle};
  background-color: ${({ theme }) => theme.colors.primary};
`;
