import { Loader } from "components";
import { lazyLoad } from "utils/loadable";

export { default as WalletBreadCrumbs } from "./breadcrumbs";
export { default as TotalBalance } from "./total-balance";

export const WalletsBalancePreview = lazyLoad(
  () => import("./balance-preview"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const WalletsNetworkPreview = lazyLoad(
  () => import("./network-preview"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const WalletsDeposit = lazyLoad(
  () => import("./deposit"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const WalletsWithdraw = lazyLoad(
  () => import("./withdraw"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const WalletsWhitelist = lazyLoad(
  () => import("./whitelist"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const WalletsHistory = lazyLoad(
  () => import("./history"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);
