import { useNavigate } from "react-router-dom";

import { ModalErrorIcon, ModalStatementCreateIcon } from "components/svg";

import { useTranslation } from "context";

import { useAppDispatch } from "store/store";
import { hideModal, showModal } from "store/modal";
import { resetCreateStatementsState } from "store/statements/create-statement";

import { ModalProps } from "store/modal/types";
import { ActionModalProps } from "components/modal/components/action-modal/types";

import { ROUTES } from "navigation/routes";

export const useShowModals = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const closeModalHandler = () => {
    dispatch(resetCreateStatementsState());
    dispatch(hideModal());
    navigate(`/${ROUTES.statements}`);
  };

  const callSuccessModal = () => {
    const _showModal = showModal as ModalProps<ActionModalProps>;

    dispatch(
      _showModal({
        modalName: "actionModal",
        rootId: "list-of-transaction",
        clickOutsideHandler: closeModalHandler,
        props: {
          icon: <ModalStatementCreateIcon height="72px" width="72px" />,
          title: t("Your statement was created successfully"),
          text: "",
          btnArr: [
            {
              buttonText: t("OK"),
              callback: closeModalHandler,
            },
          ],
        },
      }),
    );
  };

  const redirectToExistStatement = (id?: string) => {
    closeModalHandler();
    if (id) {
      navigate(`/${ROUTES.statements}/${id}`);
    }
  };

  const callErrorModal = (id?: string) => {
    const _showModal = showModal as ModalProps<ActionModalProps>;

    dispatch(
      _showModal({
        modalName: "actionModal",
        rootId: "list-of-transaction",
        clickOutsideHandler: closeModalHandler,
        props: {
          icon: <ModalErrorIcon height="72px" width="72px" />,
          title: t("The statement with these parameters has already been created."),
          text: "",
          btnArr: [
            {
              buttonText: t("OK"),
              callback: () => redirectToExistStatement(id),
            },
          ],
        },
      }),
    );
  };

  return {
    callSuccessModal,
    callErrorModal,
  };
};
