import React from "react";
import BigNumber from "bignumber.js";

import { useGetFiatSymbolAndAbbreviation, useTooltip } from "hooks";
import { useTranslation } from "context";

import { Flex, FlexGap, Heading, Text } from "components";
import { StyledAmountWrapper } from "./styled";

import { AmountPreviewerProps } from "./types";

const AmountPreviewer: React.FC<AmountPreviewerProps> = ({ amountToPay, symbol, amountInFiat }) => {
  const { t } = useTranslation();

  const { fiatSymbol } = useGetFiatSymbolAndAbbreviation();

  const { targetRef: amountToPayRef, tooltip: amountToPayTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });
  const { targetRef: amountInFiatRef, tooltip: amountInFiatTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  return (
    <StyledAmountWrapper>
      <Flex>
        <Text textScale="caption1" color="neutral500">
          {t("Amount of the transaction")}
        </Text>
      </Flex>
      <FlexGap gap="16px" alignItems="baseline" justifyContent="end" mb="6px">
        <Heading scale="h3" as="h3" color="neutral800" ellipsis ref={amountToPayRef}>
          {amountToPay}
        </Heading>
        {amountToPayTooltip}

        <Text textScale="body3">{symbol}</Text>
      </FlexGap>

      <Flex justifyContent="end">
        <Text textScale="caption2" color="neutral500" textAlign="end" ellipsis ref={amountInFiatRef}>
          ~{`${fiatSymbol}${BigNumber(amountInFiat).toFormatExtended(2)}`}
        </Text>
        {amountInFiatTooltip}
      </Flex>
    </StyledAmountWrapper>
  );
};

export default AmountPreviewer;
