import styled from "styled-components";

export const NetworkContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  width: calc(50% - 8px);
  height: 72px;
  padding: 8px;
  border: 1px solid ${({ theme, isSelected }) => (isSelected ? theme.colors.primary : theme.colors.neutral200)};
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme, isSelected }) => !isSelected && theme.colors.neutral100};
  }

  background-color: ${({ theme, isSelected }) => isSelected && theme.colors.neutral100};
`;
