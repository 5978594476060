import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 72 72" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_1906_68545)">
        <rect x="18" y="49" width="36" height="12" rx="4" fill={theme.colors.green} />
      </g>
      <circle cx="36" cy="36" r="28" fill={theme.colors.greenLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.green} strokeOpacity="0.5" />
      <path
        d="M48 27.75L31.5 44.25L24 36.75"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.green} strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_1906_68545"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_1906_68545" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
