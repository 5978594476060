export const MAX_SIZE = 5;

export const IMAGE_EXTENSIONS = {
  png: ".png",
  jpg: ".jpg",
  jpeg: ".jpeg",
  heic: ".heic",
};

export const DIMENSIONS = {
  heightView: 236,
  widthView: 354,
  mobileWidthView: 264,
  heightCrop: 179,
  widthCrop: 179,
};
