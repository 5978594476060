import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "context";
import { useAppDispatch, useAppSelector } from "store/store";
import { useSignInTransactionWithYubikey } from "hooks";

import { Text } from "components";

import { isErrorResult } from "services";

import { yubikeyLogin } from "store/yubikey/actions";
import { setIsLoggedIn } from "store/auth";

import { ROUTES } from "navigation/routes";

import { VerificationProps, HandleYubikeyLoginArgs } from "pages/auth/login-with-yubikey-verification/components/types";

const VerificationProcess: React.FC<VerificationProps> = ({ verificationCallback }) => {
  const email = useAppSelector(state => state.yubikey.email);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { signInTransactionWithYubikey } = useSignInTransactionWithYubikey({
    verificationCallback,
    handleSignInTransaction: handleYubikeyLogin,
    email,
  });

  useEffect(() => {
    if (email) {
      signInTransactionWithYubikey();
    } else {
      navigate(`/${ROUTES.login}`);
    }
  }, [email]);

  function handleYubikeyLogin({ rawId, clientDataJSON, email }: HandleYubikeyLoginArgs) {
    dispatch(
      yubikeyLogin({
        id: rawId,
        data: clientDataJSON,
        email,
      }),
    ).then(response => {
      if (!isErrorResult(response.payload)) {
        dispatch(setIsLoggedIn());
      } else {
        verificationCallback();
      }
    });
  }

  return (
    <div>
      <Text textScale="body3" color="neutral600" $fontWeight="medium">
        {t("Complete verification using your Yubikey")}
      </Text>

      <Text textScale="caption1" color="neutral600" pt="14px">
        {t(
          "Please review the notification in your browser and use Yubikey according to the instructions to finalize the process.",
        )}
      </Text>
    </div>
  );
};

export default VerificationProcess;
