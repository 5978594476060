import React from "react";

import { ROUTES } from "navigation/routes";

import { Tab, StyledContentWrapper, TabPanelWithSelect } from "components";
import { BuyCryptoTable, HistoryTable } from "./components";

import { useHandleTabAndFilter } from "./hooks";
import { useCheckIfBlacklisted } from "hooks";

import { useAppDispatch, useAppSelector } from "store/store";
import { setBuyTab } from "store/buy-crypto";

import { tabsIndex } from "configs";

const BuyCryptoPage: React.FC = () => {
  const activeTab = useAppSelector(state => state.buyCrypto.activeTab);

  const dispatch = useAppDispatch();

  useCheckIfBlacklisted(checkBuyCryptoIfBlacklisted);

  function checkBuyCryptoIfBlacklisted() {
    // if user blacklisted set active tab to 1(history tab)
    dispatch(setBuyTab(tabsIndex.buyCrypto.history));
  }

  return (
    <StyledContentWrapper id={ROUTES.buyCrypto} height="100%">
      <TabPanelWithSelect useHandleTabAndFilter={useHandleTabAndFilter} />

      <Tab active={activeTab === tabsIndex.buyCrypto.buy}>
        <BuyCryptoTable />
      </Tab>

      <Tab active={activeTab === tabsIndex.buyCrypto.history}>
        <HistoryTable />
      </Tab>
    </StyledContentWrapper>
  );
};

export default BuyCryptoPage;
