import React from "react";

import { Text, Heading } from "components";

import { useTranslation } from "context";

import { EmptyDataMessageProps } from "./types";

import { StyledEmptyDataMessage } from "./styled";

const EmptyDataMessage: React.FC<EmptyDataMessageProps> = ({ title, text, titleProps, textProps }) => {
  const { t } = useTranslation();

  const defaultTitle = t("List is empty");
  const defaultText = t("No values available at the moment");

  return (
    <StyledEmptyDataMessage>
      <Heading as="h2" scale="h2" textAlign="center" py="8px" {...titleProps}>
        {title ?? defaultTitle}
      </Heading>
      <Text textScale="caption1" textAlign="center" {...textProps}>
        {text ?? defaultText}
      </Text>
    </StyledEmptyDataMessage>
  );
};

export default EmptyDataMessage;
