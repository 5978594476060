import reactSelect from "react-select";
import styled from "styled-components";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";
import { Text, Flex } from "components";

export const StyledSingleSelectWrapper = styled.div<{ disabled?: boolean } & LayoutProps & SpaceProps>`
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? `not-allowed` : `auto`)};

  ${layout}
  ${space}
`;

export const InputError = styled(Text)`
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.red};
  white-space: pre-wrap;
`;

export const StyledSingleSelect = styled(reactSelect)`
  width: 100%;

  .select-item__single-value {
    color: ${({ theme }) => theme.colors.neutral600};
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  .select-item__control {
    border-radius: 8px;
    height: 44px;
    background-color: ${({ theme }) => theme.colors.transparent};
    border: 1px solid ${({ theme }) => theme.colors.neutral300};
    box-shadow: unset;
    cursor: pointer;
    &::placeholder {
      color: ${({ theme }) => theme.colors.neutral600};
      font-size: 14px;
    }

    &:focus:not(:disabled) {
      border: 1px solid ${({ theme }) => theme.colors.neutral300};
    }

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.neutral300};
    }
    &--is-disabled {
      background-color: ${({ theme }) => theme.colors.grey1};
      color: ${({ theme }) => theme.colors.neutral1000}24;
      box-shadow: none;
      border: none;
    }
  }

  .select-item__menu {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 14px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.colors.neutral0};
    z-index: 2;
    // Margin bottom for cases when list touches bottom of the modal
    margin-bottom: 24px;
    &-list {
      box-shadow: ${({ theme }) => theme.shadows.modal};
      background-color: ${({ theme }) => (theme.isDark ? theme.colors.neutral200 : theme.colors.neutral0)};
      border-radius: 6px;
      padding: 0;
      border: 1px solid ${({ theme }) => theme.colors.neutral300};
    }
  }

  .select-item__option {
    cursor: pointer;
    padding: 14px;
    color: ${({ theme }) => theme.colors.neutral800};
    &--is-focused {
      background-color: ${({ theme }) => (theme.isDark ? theme.colors.neutral300 : theme.colors.neutral100)};
    }

    &--is-selected {
      background-color: ${({ theme }) => (theme.isDark ? theme.colors.neutral300 : theme.colors.neutral100)};
      color: ${({ theme }) => theme.colors.neutral800};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors.neutral100};
    }
  }

  .select-item__option:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .select-item__option:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .select-item__placeholder {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral600};
    opacity: 0.6;
  }

  .select-item__indicator {
    color: ${({ theme }) => theme.colors.neutral500};
  }

  :hover {
    .select-item__indicator {
      color: ${({ theme }) => theme.colors.neutral600};
    }
  }

  .select-item__input-container {
    color: ${({ theme }) => theme.colors.neutral800};
  }

  ${layout}
`;

export const StyledSelect = styled(Flex)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.neutral400};
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  max-height: 44px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
`;
