import React from "react";
import { toast } from "react-toastify";

import { useTranslation } from "context";
import { useAppDispatch, useAppSelector } from "store/store";

import { StyledModalWrapper } from "../styled";
import CloseModalWrapper from "../close-modal-wrapper";
import { DeleteYubikeyIcon, WarningYubikeyIcon } from "components/svg";
import { Box, Button, FlexGap, Text, toastOptionsSuccess } from "components";

import { OPTION_DELETE_YUBIKEY } from "./constants";

import { hideModal, showModal } from "store/modal";
import { deleteYubikeyKeyCheck, deleteYubikeyKeyConfirm, getYubikeyKeysList } from "store/yubikey/actions";
import { updateSecuritySettings } from "store/profile/settings/actions";

import { DeleteYubikeyModalProps } from "./types";
import { ModalProps } from "store/modal/types";
import { TwoFactorConfirmModalProps } from "../two-factor-confirm-modal/types";
import { YubikeyDeleteKeyCheckPayload, YubikeyDeleteKeyConfirmPayload } from "store/yubikey/types";
import { CommonAuthMethodEnum } from "store/profile/settings/types";
import { isErrorResult } from "services";

const DeleteYubikeyModal: React.FC = () => {
  const pending = useAppSelector(state => state.yubikey.pending);
  const deviceName = useAppSelector(state => (state.modal.props as unknown as DeleteYubikeyModalProps).deviceName);
  const id = useAppSelector(state => (state.modal.props as unknown as DeleteYubikeyModalProps).id);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const cancelHandler = () => {
    dispatch(
      showModal({
        modalName: "yubikeysListModal",
        rootId: "settings",
      }),
    );
  };

  const continueHandler = () => {
    dispatch(deleteYubikeyKeyCheck({ id })).then(response => {
      if (!isErrorResult(response.payload)) {
        callTwoFactorModal();
      } else {
        closeModalHandler();
      }
    });
  };

  const callTwoFactorModal = () => {
    const _showModal = showModal as ModalProps<
      TwoFactorConfirmModalProps<YubikeyDeleteKeyConfirmPayload, YubikeyDeleteKeyCheckPayload>
    >;

    dispatch(
      _showModal({
        modalName: "twoFactorConfirmModal",
        rootId: "settings",
        props: {
          title: "Verification with codes",
          propsForAction: {
            otps: { emailOtp: "", authenticatorOtp: "" },
          },
          propsForResend: {
            id,
          },
          callbackForAction: deleteYubikeyKeyConfirm,
          callbackForSuccess: twoFaSuccessCallback,
          callbackForResend: deleteYubikeyKeyCheck,
        },
      }),
    );
  };

  const twoFaSuccessCallback = () => {
    dispatch(getYubikeyKeysList()).then(response => {
      if (!isErrorResult(response.payload)) {
        dispatch(updateSecuritySettings({ login: CommonAuthMethodEnum.TWO_FA }));
      }
    });
    toast.success(t("The %deviceName% was successfully deleted", { deviceName }), toastOptionsSuccess);
    closeModalHandler();
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} />

      <DeleteYubikeyIcon width="72px" />

      <Text textScale="body2" textAlign="center" color="neutral600" pb="12px">
        {t("Delete the “%deviceName%”", { deviceName })}
      </Text>

      <Text textScale="caption1" textAlign="center" pb="18px" color="neutral600">
        {t(
          "Before proceeding with the deletion of your Yubikey device, please consider the following options to maintain account security:",
        )}
      </Text>

      <FlexGap flexDirection="column" gap="18px" width="100%">
        {OPTION_DELETE_YUBIKEY.map(({ numberOfOption, text }, index) => (
          <Box
            key={index}
            borderRadius="8px"
            border="1px solid"
            borderColor="neutral200"
            $backgroundColor="neutral100"
            p="18px"
            width="100%"
          >
            <FlexGap gap="8px" alignItems="center" mb="12px">
              <WarningYubikeyIcon width="24px" />

              <Text textTransform="uppercase" textScale="hairlineS" color="neutral600" $fontWeight="medium">
                {t(`OPTION %numberOfOption%`, {
                  numberOfOption,
                })}
              </Text>
            </FlexGap>

            <Text color="neutral600" textScale="caption2">
              {t(text)}
            </Text>
          </Box>
        ))}
      </FlexGap>

      <FlexGap
        mt="28px"
        justifyContent="space-between"
        width="100%"
        gap="14px"
        flexDirection={{ _: "column", tablet: "row" }}
        mb={{ _: "28px", tablet: "30px" }}
      >
        <Button onClick={cancelHandler} variant="outline" width={{ _: "100%", tablet: "170px" }}>
          {t("Cancel")}
        </Button>

        <Button width={{ _: "100%", tablet: "170px" }} onClick={continueHandler} isLoading={pending}>
          {t("Confirm")}
        </Button>
      </FlexGap>
    </StyledModalWrapper>
  );
};

export default DeleteYubikeyModal;
