import styled, { DefaultTheme } from "styled-components";
// Types
import { Colors } from "theme/types";
import { StyledRangeInputProps } from "./types";

const mainDimension = "24px";

const getColor = ({ theme, disabled }: { theme: DefaultTheme; disabled?: boolean }) => {
  return disabled ? `${theme.colors.grey2}` : `${theme.colors.primary}`;
};

const makeGreyLineAfterThumb = (color: keyof Colors, width: number) => {
  let i = 10;
  let shadow = `${i}px 0 0 -20px ${color}`;

  for (; i < width; i++) {
    shadow = `${shadow}, ${i}px 0 0 -10.8px ${color}`;
  }

  return shadow;
};

export const StyledRangeInput = styled.input<StyledRangeInputProps>`
  overflow: hidden;
  display: block;
  appearance: none;
  width: 100%;
  margin: 0;
  height: ${mainDimension};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  // before thumb
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    background: ${getColor};
  }

  &::-moz-range-track {
    background: ${getColor};
  }

  // thumb + after
  &::-webkit-slider-thumb {
    position: relative;
    height: ${mainDimension};
    width: ${mainDimension};
    background: ${({ theme }) => theme.colors.neutral0};
    border: 2px solid ${getColor};
    border-radius: ${mainDimension};
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 0.1s ease-in-out;
    box-shadow: ${({ theme, afterThumbWidth, disabled }) =>
      !disabled ? makeGreyLineAfterThumb(theme.colors.neutral300 as keyof Colors, afterThumbWidth) : "none"};
  }

  &:not([disabled]):active {
    &::-webkit-slider-thumb {
      background-color: ${getColor};
    }
  }
`;
