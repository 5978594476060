import React from "react";

import { Box, FlexGap, MultiplyPendingRows, Skeleton } from "components";

import { useMatchBreakpoints } from "hooks";

import { PendingRowsProps } from "./types";

export const PendingRowSkeleton: React.FC<PendingRowsProps> = ({
  pendingRowHeightMobile = "79px",
  pendingRowHeightTablet = "79px",
  pendingRowHeightDesktop = "79px",
  tableGap = "1px",
  pendingRowWidthTablet = "100%",
  size = 10,
  headers,
}) => {
  const { isMobile, isTablet } = useMatchBreakpoints();

  const isNoHeaders = headers.every(header => header.text === "");

  return (
    <Box width="100%">
      {isTablet || isMobile ? (
        <FlexGap
          gap={typeof tableGap === "object" ? { _: "1px", ...tableGap } : { _: "1px", tablet: tableGap }}
          flexWrap="wrap"
        >
          {Array.from({ length: size }).map((_, index) => {
            return (
              <Box maxWidth={{ _: "100%", tablet: pendingRowWidthTablet }} key={index} width="100%">
                <Skeleton height={{ _: pendingRowHeightMobile, tablet: pendingRowHeightTablet }} width="100%" />
              </Box>
            );
          })}
        </FlexGap>
      ) : (
        <Box mt={!isNoHeaders ? "0px" : "24px"}>
          {!isNoHeaders && <Skeleton height="44px" width="100%" mb="1px" />}
          <MultiplyPendingRows rowHeight={pendingRowHeightDesktop} rowsCount={size} rowWidth="100%" gap="1px" />
        </Box>
      )}
    </Box>
  );
};
