import React from "react";
import { Input } from "components";

import { formatValueToBNString } from "configs/web3";

import { REGEX } from "configs";
import { InputProps } from "components/inputs/input/types";

export const InputNumeric = <E extends React.ElementType = "input">({
  onUserInput,
  onBlur,
  ...props
}: InputProps<E> & { onUserInput: (value: string) => void }): JSX.Element => {
  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/,/g, ".");

    if (REGEX.numericInputRegex.test(value)) {
      if (value.startsWith(".")) {
        onUserInput("0" + value);
      } else {
        onUserInput(value);
      }
    }
  };

  const onUnFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    onUserInput(formatValueToBNString(value));
    onBlur?.();
  };

  return (
    <Input
      inputMode="decimal"
      pattern="^[0-9]*[.,]?[0-9]*$"
      onChange={event => onChangeText(event)}
      onBlur={event => onUnFocus(event)}
      {...props}
    />
  );
};
