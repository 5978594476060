import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => state.profile.pending,
    (state: AppState) => state.hotWallets.wallets.pending,
    (state: AppState) => state.hotWallets.wallets.data,
    (state: AppState) => state.hotWallets.networks,
  ],
  (pendingUser, pendingWallets, walletsData, networks) => ({
    pendingUser,
    pendingWallets,
    walletsData,
    networks,
  }),
);
