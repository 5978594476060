import { useEffect, useState } from "react";

import { ChainId, Connection, SolanaConnection, getConnections, solanaConnection } from "configs/web3";
import { SOLANA_NETWORK } from "configs/solana";

const useProviders = (network?: ChainId) => {
  const [connections, setConnections] = useState<(Connection | SolanaConnection)[]>([]);

  const allConnections = getConnections();

  useEffect(() => {
    const showedConnections = allConnections.filter(connection => connection.shouldDisplay());

    const filteredProvidersByNetwork = network === SOLANA_NETWORK ? [solanaConnection] : showedConnections;

    setConnections(filteredProvidersByNetwork);
  }, []);

  return { connections };
};

export default useProviders;
