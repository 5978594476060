import { createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorResult } from "services/types";
import { CurrenciesList } from "./types";
import { isErrorResult, makeApiRequest } from "services";
import { ENDPOINTS_TOKENS } from "services/endpoints";

export const getTokens = createAsyncThunk<CurrenciesList, undefined, { rejectValue: ErrorResult }>(
  "tokens/getTokens",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<CurrenciesList>({
      method: "GET",
      url: ENDPOINTS_TOKENS.getAllCurrencies,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);
