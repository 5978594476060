import styled from "styled-components";

export const StyledVoicePhrase = styled.div`
  display: flex;
  margin-top: 10px;
  position: absolute;
  top: -10px;
  max-width: 250px;
  background-color: ${({ theme }) => theme.colors.neutral100};
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  border: 1px solid ${({ theme }) => theme.colors.neutral300};
`;

export const StyledRecordingCircle = styled.circle`
  overflow: "visible";
`;

export const StyledAnimatedCircle = styled.svg`
  position: absolute;
  transform: rotate(-90deg);
  overflow: visible;
  margin-left: -100px;
`;
