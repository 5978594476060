import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 97 112" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_13406_125965)">
        <rect x="24.5" y="65.333" width="48" height="22.6667" rx="5.33333" fill={theme.colors.primary} />
      </g>
      <circle cx="48.4998" cy="48.0003" r="37.3333" fill={theme.colors.primaryLight} />
      <circle
        cx="48.4998"
        cy="48.0003"
        r="36.6667"
        stroke={theme.colors.primary}
        strokeOpacity="0.5"
        strokeWidth="1.33333"
      />
      <path
        d="M67.787 57.5458L62.2677 52.0016V34.2599H57.8792L60.6523 31.4743C61.2446 30.8793 62.0523 30.5547 62.8331 30.5547C63.6139 30.5547 64.4216 30.8522 65.0139 31.4743C66.2254 32.6913 66.2254 34.6656 65.0139 35.8826L64.1792 36.721V42.2382L67.76 38.6412C70.4793 35.9097 70.4793 31.4472 67.76 28.7157C65.0408 25.9841 60.5984 25.9841 57.8792 28.7157L52.3869 34.2599H34.7252V38.6683L31.9521 35.8826C30.7406 34.6656 30.7406 32.6913 31.9521 31.4743C32.5444 30.8793 33.3521 30.5547 34.1329 30.5547C34.9137 30.5547 35.7214 30.8522 36.3137 31.4743L37.1483 32.3127H42.6406L39.0868 28.7157C36.3675 25.9841 31.9252 25.9841 29.2059 28.7157C26.4867 31.4472 26.4867 35.9097 29.2059 38.6412L34.7252 44.1855V61.9271H39.1137L36.3406 64.7128C35.1291 65.9298 33.1637 65.9298 31.9521 64.7128C30.7406 63.4957 30.7406 61.5215 31.9521 60.3044L32.7867 59.466V53.9488L29.2059 57.5458C26.4867 60.2774 26.4867 64.7398 29.2059 67.4714C30.5252 68.7966 32.2752 69.5268 34.1598 69.5268C36.0175 69.5268 37.7675 68.7966 39.1137 67.4714L44.633 61.9271H62.2946V57.5188L65.0677 60.3044C66.2793 61.5215 66.2793 63.4957 65.0677 64.7128C63.8562 65.9298 61.8908 65.9298 60.6792 64.7128L59.8446 63.8744H54.3523L57.933 67.4714C59.2523 68.7966 61.0023 69.5268 62.8869 69.5268C64.7446 69.5268 66.4946 68.7966 67.8408 67.4714C70.5062 64.7398 70.5062 60.2774 67.787 57.5458ZM58.3907 58.0056H48.5099L54.2715 52.2179L51.4984 49.4864L42.9906 58.0326H38.6022V48.08L44.3637 53.8677L47.1099 51.1091L38.6022 42.5628V38.1544H48.483L42.7483 43.9421L45.4945 46.7007L54.0023 38.1544H58.3907V48.08L52.6292 42.2923L49.8561 45.078L58.3907 53.6243V58.0056Z"
        fill={theme.colors.primary}
      />
      <circle opacity="0.18" cx="48.5" cy="48" r="47.6667" stroke={theme.colors.primary} strokeWidth="0.666667" />
      <defs>
        <filter
          id="filter0_f_13406_125965"
          x="0.5"
          y="41.333"
          width="96"
          height="70.667"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="12" result="effect1_foregroundBlur_13406_125965" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
