import React, { createContext, useContext, useState, useEffect, useCallback, PropsWithChildren } from "react";

import { EN, LOCAL_STORAGE_KEYS } from "configs";
import { fetchLocale, translatedTextIncludesVariable } from "./helpers";
import { ContextApi, ContextData, ContextType, Language, LanguageContextProviderProps, TranslationKey } from "./types";
// TODO: change language context to GDN strategy
// Now we use hardcoded En languages
// Also check places with `pathname`
const initialState: ContextType = {
  isFetching: true,
  currentLanguage: EN,
};

const langKey = LOCAL_STORAGE_KEYS.language;

export const languageMap = new Map();

// function to translate text not in components
// Usage: const translate = t(locale);
//        translate("Some Text")
// locale could be "en-US" or  other keys from src/configs/languages in languages
export const t = (key: string) => {
  const translationSet = languageMap.get(EN.locale);
  const translatedText = translationSet && translationSet[key] ? translationSet[key] : key;

  return translatedText;
};

const LanguageContext = createContext<ContextApi | null>(null);

const LanguageContextProvider: React.FC<PropsWithChildren<LanguageContextProviderProps>> = ({ fallback, children }) => {
  const [state, setState] = useState(initialState);

  const { currentLanguage } = state;

  useEffect(() => {
    fetchInitialLocales();
  }, []);

  const fetchInitialLocales = async () => {
    const initialLocale = await fetchLocale();

    if (initialLocale) {
      languageMap.set(EN.locale, { ...initialLocale });
      localStorage?.setItem(langKey, EN.locale);
    }

    setState(prevState => ({
      ...prevState,
      isFetching: false,
    }));
  };

  const changeLanguage = useCallback(async (_: Language) => {
    setState(prevState => ({
      ...prevState,
      isFetching: true,
    }));

    const locale = await fetchLocale();

    if (locale) {
      languageMap.set(EN.locale, { ...locale });
      localStorage?.setItem(langKey, EN.locale);
    }

    setState(prevState => ({
      ...prevState,
      isFetching: false,
      currentLanguage: EN,
    }));
  }, []);

  const translate = useCallback(
    (key: TranslationKey, data?: ContextData) => {
      const translationSet = languageMap.get(EN.locale);
      const translatedText = translationSet && translationSet[key] ? translationSet[key] : key;

      // Check the existence of at least one combination of %%, separated by 1 or more non space characters
      const includesVariable = translatedTextIncludesVariable(translatedText);

      if (includesVariable && data) {
        let interpolatedText = translatedText;
        Object.keys(data).forEach(dataKey => {
          const templateKey = new RegExp(`%${dataKey}%`, "g");
          interpolatedText = interpolatedText.replace(templateKey, data[dataKey].toString());
        });

        return interpolatedText;
      }

      return translatedText;
    },
    [currentLanguage],
  );

  if (state.isFetching && fallback) {
    return fallback;
  }

  return (
    <LanguageContext.Provider value={{ ...state, changeLanguage, t: translate }}>{children}</LanguageContext.Provider>
  );
};

export const useTranslation = () => {
  const languageContext = useContext(LanguageContext);

  if (languageContext === null) {
    throw new Error("Language context is not found");
  }

  return languageContext;
};

export default LanguageContextProvider;
