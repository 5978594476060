import React from "react";

import { useThemeContext } from "context";

import { Svg } from "components";

import { SvgProps } from "components/svg/types";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg width="72" viewBox="0 0 72 72" {...props} color={color}>
      <circle cx="36" cy="36" r="27.5" fill={theme.colors.neutral200} stroke={theme.colors.neutral300} />
      <path
        d="M36.102 42.339C35.1105 42.579 34.074 42.72 33 42.72C32.1465 42.72 31.314 42.636 30.5085 42.4815L25.8 44.8005C25.8 43.1955 25.797 41.667 25.7985 40.533C22.893 38.556 21 35.421 21 31.86C21 25.8345 26.397 21 33 21C39.603 21 45 25.8345 45 31.86C45 33.3315 44.673 34.7295 44.091 36.006"
        stroke={theme.colors.neutral500}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.5 51C39.3585 51 36 47.6415 36 43.5C36 39.3585 39.3585 36 43.5 36C47.643 36 51 39.3585 51 43.5C51 47.6415 47.643 51 43.5 51"
        stroke={theme.colors.neutral500}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.2655 31.816C33.4125 31.963 33.4125 32.2 33.2655 32.347C33.1185 32.494 32.8815 32.494 32.7345 32.347C32.5875 32.2 32.5875 31.963 32.7345 31.816C32.8815 31.6705 33.1185 31.6705 33.2655 31.816"
        stroke={theme.colors.neutral500}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.5975 31.816C38.7445 31.963 38.7445 32.2 38.5975 32.347C38.4505 32.494 38.2135 32.494 38.0665 32.347C37.9195 32.2 37.9195 31.963 38.0665 31.816C38.2135 31.6705 38.452 31.6705 38.5975 31.816"
        stroke={theme.colors.neutral500}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.9315 31.816C28.0785 31.963 28.0785 32.2 27.9315 32.347C27.7845 32.494 27.5475 32.494 27.4005 32.347C27.2535 32.2 27.2535 31.963 27.4005 31.816C27.5475 31.6705 27.786 31.6705 27.9315 31.816"
        stroke={theme.colors.neutral500}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.6148 41.3867L41.3848 45.6167"
        stroke={theme.colors.neutral500}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.6148 45.6167L41.3848 41.3867"
        stroke={theme.colors.neutral500}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="36" cy="36" r="35.75" stroke={theme.colors.neutral200} strokeWidth="0.5" />
    </Svg>
  );
};

export default Icon;
