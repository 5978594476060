import { TABS, tabsIndex } from "configs";
import { useAppDispatch, useAppSelector } from "store/store";
import { setSwapTab } from "store/swap";
import { useSetActiveTab } from "hooks";

export const useHandleTabAndFilter = () => {
  const activeTab = useAppSelector(state => state.swap.activeTab);
  const pending = useAppSelector(state => state.swap.historyInfo.pending);

  const dispatch = useAppDispatch();

  const onTabClick = (index: number) => {
    dispatch(setSwapTab(index));
  };

  useSetActiveTab(tabsIndex.swap, setSwapTab);

  return {
    tabs: TABS.swap,
    activeTab,
    pending,
    options: [],
    selectedOption: null,
    isSelectShow: false,
    onTabClick,
    handleSelectChange: () => {},
  };
};
