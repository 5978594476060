import styled from "styled-components";

export const StyledAmountWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  border: 1px solid ${({ theme }) => theme.colors.neutral300};
  padding: 24px;
  width: 100%;
  border-radius: 8px;
`;
