import { SvgProps } from "components/svg/types";
import { ElementType, ReactNode } from "react";
import { LayoutProps, SpaceProps } from "styled-system";
import { Colors } from "theme/types";

import { PolymorphicComponentProps } from "types";

export const variants = {
  PRIMARY: "primary",
  OUTLINE: "outline",
  CHIP: "chip",
} as const;

export const scales = {
  MD: "md",
  SM: "sm",
} as const;

export type Scale = (typeof scales)[keyof typeof scales];
export type Variant = (typeof variants)[keyof typeof variants];

export interface BaseButtonProps extends LayoutProps, SpaceProps {
  as?: "a" | "button" | ElementType;
  external?: boolean;
  isLoading?: boolean;
  scale?: Scale;
  variant?: Variant;
  color?: keyof Colors;
  borderColor?: keyof Colors;
  disabled?: boolean;
  startIcon?: ReactNode & SvgProps;
  endIcon?: ReactNode & SvgProps;
}

export type ButtonProps<P extends ElementType = "button"> = PolymorphicComponentProps<P, BaseButtonProps>;
