import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 72 84" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_12883_115622)">
        <rect x="18" y="49" width="36" height="17" rx="4" fill={theme.colors.yellow} />
      </g>
      <circle cx="36" cy="36" r="28" fill={theme.colors.yellowLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.yellow} strokeOpacity="0.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9961 26.7578L32.9986 23.2973C34.8558 22.2251 37.144 22.2251 39.0011 23.2973L45.0036 26.7593C46.8612 27.8318 48.0054 29.814 48.0049 31.959L48.0049 40.0413C48.0059 42.1868 46.8617 44.1697 45.0036 45.2425L39.0011 48.7029C37.144 49.7752 34.8558 49.7752 32.9986 48.7029L26.9961 45.241C25.1385 44.1685 23.9944 42.1863 23.9949 40.0413L23.9949 31.959C23.9939 29.8134 25.1381 27.8305 26.9961 26.7578Z"
        fill={theme.colors.yellow}
        fillOpacity="0.14"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
      />
      <path
        d="M32.2488 32.248L39.752 39.7512"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.2488 39.7512L39.752 32.248"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.yellow} strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_12883_115622"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_12883_115622" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
