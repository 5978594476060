import React from "react";

import { Box, Column, EmptyDataMessage, FlexGap, Skeleton, Text } from "components";
import { SingleNetworkItem } from "./components";

import { useTranslation } from "context";

import { useAppSelector } from "store/store";
import { UserWalletsStatusEnum } from "store/profile/types";

const MyNetworks: React.FC = () => {
  const networks = useAppSelector(state => state.hotWallets.networks);
  const pending = useAppSelector(state => state.hotWallets.wallets.pending);
  const user = useAppSelector(state => state.profile.user);

  const { t } = useTranslation();

  return (
    <Column mt="36px" flex="1">
      <Text mb="24px" textScale="body2" color="neutral600" $fontWeight="medium">
        {t("My Networks")}
      </Text>

      {pending ? (
        <FlexGap width="100%" gap="16px" flexWrap="wrap">
          {Array.from({ length: 6 }).map((_, index) => (
            <Skeleton
              width={{ _: "100%", tablet: "calc(50% - 8px)", laptopL: "calc(33% - 8px)" }}
              height="160px"
              key={index}
            />
          ))}
        </FlexGap>
      ) : networks && user?.walletsStatus === UserWalletsStatusEnum.CREATED ? (
        <FlexGap width="100%" gap="16px" flexWrap="wrap">
          {Object.keys(networks).map((item, index) => {
            return (
              <Box
                height="160px"
                width={{ _: "100%", tablet: "calc(50% - 8px)", laptopL: "calc(33% - 8px)" }}
                key={index}
              >
                <SingleNetworkItem network={networks[item]} />
              </Box>
            );
          })}
        </FlexGap>
      ) : (
        <EmptyDataMessage />
      )}
    </Column>
  );
};

export default MyNetworks;
