import { useAppDispatch, useAppSelector } from "store/store";
import { setTransactionPayload } from "store/statements/create-statement";
import { useEffect } from "react";
import { getStatementTransaction } from "store/statements/create-statement/actions";
import { TransactionTypeEnum } from "store/wallets/enums";
import { selectCombinedData } from "./selectors";

const useHandlePayload = () => {
  const { size, page, data, count, pending, dateRange } = useAppSelector(selectCombinedData);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (dateRange.dateFrom && dateRange.dateTo) {
      const query = {
        page,
        size,
        dateFrom: dateRange.dateFrom,
        dateTo: dateRange.dateTo,
        state: TransactionTypeEnum.COMPLETED,
      };

      dispatch(getStatementTransaction(query));
    }
  }, [page, size, dateRange.dateFrom, dateRange.dateTo]);

  const handlePageClick = (selectedItem: { selected: number }) => {
    const currentPage = selectedItem.selected + 1;
    dispatch(setTransactionPayload({ page: currentPage }));
  };

  return { data, count, pending, page, size, handlePageClick };
};

export default useHandlePayload;
