import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "store/store";

import { useSetActiveTab } from "hooks";

import { Tab, StyledContentWrapper, TabPanel, Loader } from "components";
import { ProfileTab, SystemTab, SecurityTab } from "./components";

import { TABS, tabsIndex } from "configs";
import { ROUTES } from "navigation/routes";
import { isErrorResult } from "services";

import { updateSecuritySettings } from "store/profile/settings/actions";
import { getYubikeyKeysList } from "store/yubikey/actions";
import { setSettingsTab } from "store/settings";

import { CommonAuthMethodEnum } from "store/profile/settings/types";
import { selectCombinedData } from "./selectors";

const SettingsPage: React.FC = () => {
  const { activeTab, pending, user, isYubikeyLogin } = useAppSelector(selectCombinedData);

  const dispatch = useAppDispatch();

  const onTabClick = (tab: number) => {
    dispatch(setSettingsTab(tab));
  };

  useSetActiveTab(tabsIndex.settings, setSettingsTab);

  useEffect(() => {
    dispatch(getYubikeyKeysList()).then(response => {
      if (!isErrorResult(response.payload)) {
        if (response.payload?.data.length) {
          if (user && !isYubikeyLogin) {
            dispatch(updateSecuritySettings({ login: CommonAuthMethodEnum.YUBIKEY }));
          }
        } else {
          dispatch(
            updateSecuritySettings({
              login: CommonAuthMethodEnum.TWO_FA,
            }),
          );
        }
      }
    });
  }, []);

  return (
    <StyledContentWrapper id={ROUTES.settings} height="100%">
      <TabPanel tabs={TABS.settings} activeTab={activeTab} onTabClick={onTabClick} />

      <Tab active={activeTab === tabsIndex.settings.profile}>
        {pending && !user ? <Loader width="100%" height="100%" /> : <ProfileTab />}
      </Tab>

      <Tab active={activeTab === tabsIndex.settings.system}>
        {pending && !user ? <Loader width="100%" height="100%" /> : <SystemTab />}
      </Tab>

      <Tab active={activeTab === tabsIndex.settings.security}>
        {pending && !user ? <Loader width="100%" height="100%" /> : <SecurityTab />}
      </Tab>
    </StyledContentWrapper>
  );
};

export default SettingsPage;
