export const ROUTES = {
  home: "/",

  login: "login",
  registration: "registration",
  verifyEmail: "verify-email",
  forgotPassword: "forgot-password",
  twoFactorSetup: "two-factor-setup",
  twoFactorReset: "reset-twofa",
  confirmAuth: "confirm-auth",
  loginWithYubikey: "login-with-yubikey",
  verification: "verification",
  deleteCompleted: "delete-account-completed",

  app: "app",
  wallet: "wallet",
  deposit: "deposit",
  withdraw: "withdraw",
  whitelist: "whitelist",
  history: "history",

  swap: "swap",
  coldStorage: "cold-storage",
  buyCrypto: "buy-crypto",
  settings: "settings",
  escrows: "escrows",
  createEscrow: "create-escrow",
  messages: "messages",
  escrowProcess: "escrow-process",
  escrowPreview: "escrow-preview",

  statements: "statements",
  createStatement: "create-statement",
  statementsTransaction: "list-of-transaction",

  comingSoon: "coming-soon",
  termsAndConditions: "terms-and-conditions",
  privacyPolicy: "privacy-policy",
  banned: "banned",

  dashboard: "dashboard",

  escrowWidgetTest: "escrow-widget-test",
} as const;

export const ROUTE_PARAMS = {
  network: "network",
  token: "token",
  escrowId: "escrowId",
  escrowPreviewId: "escrowPreviewId",
  statementId: "statementId",
};
