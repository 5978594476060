import { lazyLoad } from "utils/loadable";

export const LoginPage = lazyLoad(
  () => import("./login"),
  module => module.default,
);

export const RegistrationPage = lazyLoad(
  () => import("./registration"),
  module => module.default,
);

export const VerifyEmailPage = lazyLoad(
  () => import("./verify-email"),
  module => module.default,
);

export const ForgotPasswordPage = lazyLoad(
  () => import("./forgot-password"),
  module => module.default,
);

export const TwoFactorSetupPage = lazyLoad(
  () => import("./two-factor-setup"),
  module => module.default,
);

export const ConfirmAuthPage = lazyLoad(
  () => import("./confirm-auth"),
  module => module.default,
);

export const LoginWithYubikeyPage = lazyLoad(
  () => import("./login-with-yubikey"),
  module => module.default,
);

export const LoginWithYubikeyVerificationPage = lazyLoad(
  () => import("./login-with-yubikey-verification"),
  module => module.default,
);

export const AccountDeletionCompletedPage = lazyLoad(
  () => import("./account-deletion-completed"),
  module => module.default,
);
