import { useEffect, useState } from "react";
import { useIsMounted } from "hooks";
import { useTranslation } from "context";
import { FieldsType } from "../types";

export const useCameraHandle = ({ setIsCameraStarted }: { setIsCameraStarted: (arg: boolean) => void }) => {
  const [isCameraExist, setIsCameraExist] = useState(false);
  const [cameraFieldPath, setCameraFieldPath] = useState("");
  const [cameraErrorMessage, setCameraErrorMessage] = useState("");

  const { t } = useTranslation();
  const isMounted = useIsMounted();

  useEffect(() => {
    checkIsCameraExist();
  }, []);

  const checkIsCameraExist = async () => {
    try {
      const mediaDeviceInfoArr = await navigator.mediaDevices.enumerateDevices();

      if (isMounted.current) {
        mediaDeviceInfoArr.forEach(deviceInfo => {
          if (deviceInfo.kind === "videoinput") {
            setIsCameraExist(true);
          }
        });
      }
    } catch (error) {
      setIsCameraExist(false);
      setCameraErrorMessage(
        t(
          "Seems like your device doesn’t have a camera. Please, try another device. Otherwise, you will be unable to proceed",
        ),
      );
      console.error("Can't get access to MediaDeviceInfo", error);
    }
  };
  const startCamera = (cameraField: FieldsType, path: string) => {
    setCameraFieldPath(path);
    setIsCameraStarted(true);
  };

  return {
    isCameraExist,
    cameraErrorMessage,
    setIsCameraExist,
    cameraFieldPath,
    startCamera,
    setCameraErrorMessage,
  };
};
