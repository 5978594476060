import React from "react";
import { SingleValue } from "react-select";
import { toast } from "react-toastify";

import { useTranslation } from "context";
import { useForm } from "hooks";
import { useHandleFilters, useShowModals, useValidationSchema } from "./hooks";

import { Button, Column, Input, InputGroup, SingleSelect, Text, toastOptionsError } from "components";
import { ModalAddIcon } from "components/svg";
import { StyledModalWrapper } from "../styled";
import CloseModalWrapper from "../close-modal-wrapper";

import { isErrorResult } from "services";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal, showModal } from "store/modal";
import { createWhitelistItem, sendCodesForWhitelist } from "store/wallets/whitelist/actions";

import { SingleOption } from "components/selects/single-select/types";
import { ModalProps } from "store/modal/types";
import { YubikeyVerificationModalProps } from "../yubikey-verification-modal/types";
import { CreateWhitelistPayload } from "store/wallets/whitelist/types";
import { selectCombinedData } from "./selectors";

const WhitelistAddNewModal: React.FC = () => {
  const { user, pending, network, rootId } = useAppSelector(selectCombinedData);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { options, selectedOption, handleSelectChange } = useHandleFilters({ network });
  const { validationSchema, initialValues } = useValidationSchema(selectedOption.value);

  const { setFieldValue, setFieldTouched, fieldProps, handleSubmit, errors, isValid, touched } = useForm({
    initialValues,
    validationSchema,
    onSubmit(values) {
      onHandleSubmit(values);
    },
  });

  const { whitelistRejectModal, callTwoFactorModal, callSuccessModal, handleVerifyCallback } = useShowModals();

  const onHandleSubmit = (formikValues: typeof initialValues) => {
    const _showModal = showModal as ModalProps<YubikeyVerificationModalProps<CreateWhitelistPayload>>;

    // if isAddWithdrawalAddressWithYubikey equals true otp is not sent

    const isAddWithdrawalAddressWithYubikey = user?.securitySettings.yubikey.addWithdrawalWallet;

    const data = {
      chainId: selectedOption.value,
      nickname: formikValues.nickname,
      address: formikValues.address,
      sendOtp: !isAddWithdrawalAddressWithYubikey,
    };

    dispatch(sendCodesForWhitelist(data)).then(res => {
      if (!isErrorResult(res.payload)) {
        //Yubikey sign transaction modal call place here, because we need do check on exist wallet address
        if (isAddWithdrawalAddressWithYubikey) {
          dispatch(
            _showModal({
              modalName: "yubikeyVerificationModal",
              rootId,
              props: {
                signInSuccessCallback: callSuccessModal,
                signInActionCallback: createWhitelistItem,
                switchAnotherMethodVerificationCallback: () => handleVerifyCallback(data),
                propsForSignInCallback: {
                  yubico: {
                    ...data,
                  },
                },
              },
            }),
          );
        } else {
          callTwoFactorModal(data);
        }
      } else {
        const error = res.payload!;
        // 409 means that address already exists
        if (error.code === 409) {
          whitelistRejectModal(error.message);
        } else {
          toast.error(error.message, toastOptionsError);
        }
      }
    });
  };

  const onSelectChange = async (option: SingleValue<SingleOption>) => {
    await setFieldValue("address", "");
    await setFieldTouched("address", false);
    handleSelectChange(option);
  };

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} mb="18px" />

      <form onSubmit={handleSubmit}>
        <Column width="100%" alignItems="center">
          <ModalAddIcon width="72px" height="72px" />

          <Text mt="16px" textScale="body2" color="neutral600" textAlign="center">
            {t("Add a new withdrawal address")}
          </Text>

          <Text mt="12px" textAlign="center" textScale="caption1" color="neutral600">
            {t("Please note that withdrawals for an address are disabled in the first 24 hours.")}
          </Text>

          <InputGroup label={t("Network")} mt="24px" mb="8px">
            <SingleSelect
              options={options}
              value={selectedOption}
              onChange={option => onSelectChange(option as SingleOption)}
              width="100%"
            />
          </InputGroup>

          <InputGroup label={t("Nickname")} error={errors.nickname} isTouched={touched.nickname} mb="8px">
            <Input placeholder={t("Enter Nickname")} {...fieldProps("nickname")} />
          </InputGroup>

          <InputGroup label={t("Address")} error={errors.address} isTouched={touched.address} mb="24px">
            <Input placeholder={t("Enter Address")} {...fieldProps("address")} />
          </InputGroup>

          <Button width="100%" mb={{ _: "20px", tablet: "30px" }} type="submit" isLoading={pending} disabled={!isValid}>
            {t("Submit")}
          </Button>
        </Column>
      </form>
    </StyledModalWrapper>
  );
};

export default WhitelistAddNewModal;
