import React from "react";
import BigNumber from "bignumber.js";

import { useAppSelector } from "store/store";
import { useGetFiatSymbolAndAbbreviation, useTooltip } from "hooks";
import { useTranslation } from "context";

import { Box, Flex, RowBetween, Skeleton, Text } from "components";
import { TooltipIcon } from "components/svg";

import { CommissionsRowProps } from "../../types";

const CommissionRow: React.FC<CommissionsRowProps> = ({
  commissionsName,
  commissionsValue,
  tooltipText,
  showIcon,
  pending,
}) => {
  const coldWalletFee = useAppSelector(state => state.walletsFee.coldWalletFee);

  const { t } = useTranslation();
  const { fiatSymbol, isCurrentFiatUSD } = useGetFiatSymbolAndAbbreviation();

  const commissionsValueWithoutSymbol = commissionsValue.slice(1);

  const { targetRef, tooltip } = useTooltip(tooltipText, {
    placement: "top-start",
  });

  const { targetRef: targetRefCommission, tooltip: commissionTooltip } = useTooltip(
    !isCurrentFiatUSD
      ? `${t("The commission amount %amount% in equal to the amount of $%amountIsUsd%", {
          amount: `${fiatSymbol}${BigNumber(coldWalletFee.withdrawalFee.amountInFiat).toFormatExtended(2)}`,
          amountIsUsd: `${coldWalletFee.withdrawalFee.amountInUsd}`,
        })}`
      : undefined,
    {
      placement: "top",
      isEllipsis: isCurrentFiatUSD,
    },
  );

  return (
    <RowBetween mb="12px" alignItems="center">
      <Flex>
        <Text color="neutral500" textScale="caption1">
          {t(commissionsName)}
        </Text>

        {showIcon && (
          <Box ref={targetRef}>
            <TooltipIcon mx="14px" cursor="pointer" />
          </Box>
        )}
      </Flex>

      {showIcon && tooltip}

      {pending ? (
        <Skeleton width="86px" />
      ) : (
        <Box maxWidth="40%">
          <Text
            color="neutral800"
            textScale="caption1"
            ellipsis
            textAlign="end"
            ref={commissionsValueWithoutSymbol === "0" ? null : targetRefCommission}
          >
            {commissionsValue}
          </Text>
          {commissionTooltip}
        </Box>
      )}
    </RowBetween>
  );
};

export default CommissionRow;
