import React from "react";
import { useNavigate } from "react-router-dom";

import { useKycHandler, useMatchBreakpoints, useTwoFaLastUpdateCheck } from "hooks";
import { useHandleTabAndFilter } from "./hooks";
import { useAppSelector } from "store/store";
import { useTranslation } from "context";

import { Button, FlexGap, StyledContentWrapper, Tab, TabPanelWithSelect, Text } from "components";
import { MyEscrowsTable, WidgetsTable } from "./components";

import { ROUTES } from "navigation/routes";
import { tabsIndex } from "configs";
import { AccountType } from "store/profile/types";

const Escrows: React.FC = () => {
  const activeTab = useAppSelector(state => state.escrowsTable.activeTab);
  const user = useAppSelector(state => state.profile.user);
  const isBlacklisted = useAppSelector(state => state.profile.isBlacklisted);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useMatchBreakpoints();

  const { callbackWithKycCheck } = useKycHandler({ shouldCheckStatus: false });
  const { callbackWithTwoFaLastUpdateCheck } = useTwoFaLastUpdateCheck({ rootId: ROUTES.escrows });

  const twoFaLastUpdateCheck = () => {
    callbackWithTwoFaLastUpdateCheck(() => navigate(ROUTES.createEscrow));
  };
  const redirectToCreateEscrowPage = () => {
    callbackWithKycCheck(twoFaLastUpdateCheck);
  };

  return (
    <StyledContentWrapper id={ROUTES.escrows} height="100%">
      <FlexGap alignItems="center" justifyContent="space-between" gap="14px" mb="12px">
        {user?.accountType === AccountType.BUSINESS ? (
          <TabPanelWithSelect useHandleTabAndFilter={useHandleTabAndFilter} />
        ) : (
          <Text textScale="body2" $fontWeight="medium" color="neutral600">
            {t("My Escrows")}
          </Text>
        )}

        {!isMobile && (
          <Button
            maxWidth="166px"
            width="100%"
            mb={{ _: "8px", tablet: "12px" }}
            disabled={isBlacklisted}
            onClick={redirectToCreateEscrowPage}
          >
            {t("Start an Escrow")}
          </Button>
        )}
      </FlexGap>

      <Tab active={activeTab === tabsIndex.escrows.myEscrows}>
        <MyEscrowsTable />
      </Tab>

      <Tab active={activeTab === tabsIndex.escrows.widgets}>
        <WidgetsTable />
      </Tab>
    </StyledContentWrapper>
  );
};

export default Escrows;
