import React from "react";
import BigNumber from "bignumber.js";

import { Text, Column, FlexGap, AmountToDeposit, Skeleton } from "components";

import { useGetEscrowParticipants } from "pages/main/escrow-process/hooks";
import { useTranslation } from "context";
import { useAmountToDepositHandlers, useGetCurrencyRate } from "hooks";
import { useAppSelector } from "store/store";

import { getTokenFromTokenKey } from "utils/helpers";

import { InitialValues } from "../../hooks/types";
import { Roles } from "pages/main/create-escrow/types";
import { AmountsProps } from "./types";
import { selectCombinedData } from "./selectors";

const Amounts: React.FC<AmountsProps> = ({ values, errors, touched, setFieldValue, setFieldTouched }) => {
  const { currentEscrow, allTokensList, pending, error } = useAppSelector(selectCombinedData);

  const { t } = useTranslation();

  const { broker, userRole } = useGetEscrowParticipants();
  const { onUserInput, onBlur } = useAmountToDepositHandlers<InitialValues>({
    setFieldTouched,
    setFieldValue,
  });

  const currencyCode = currentEscrow ? currentEscrow.body.head.currency.publicCode : "";
  const { currentRate } = useGetCurrencyRate(currencyCode);
  const currency =
    currentEscrow && getTokenFromTokenKey(allTokensList, currentEscrow.body.head.currency.chainId, currencyCode);

  return (
    <>
      {currentEscrow && (
        <>
          <Column mb="12px">
            <AmountToDeposit
              value={values.amount}
              title="Amount of the transaction"
              onChangeInput={onUserInput}
              onBlur={onBlur}
              isError={!!errors.amount}
              isTouched={!!touched.amount}
              currency={currency}
              currentRate={currentRate}
              isHaveFiatEquivalent
            />
            {errors.amount && touched.amount && (
              <Text textScale="caption2" color="red" mt="4px">
                {errors.amount}
              </Text>
            )}
          </Column>

          <FlexGap alignItems="center" mb={broker && userRole === Roles.broker ? "0px" : "24px"} gap="4px">
            <Text textScale="caption2" color="neutral500">
              {t("Zert Fee:")}
            </Text>
            {pending ? (
              <Skeleton width="80px" height="16px" />
            ) : (
              <Text textScale="caption2" $fontWeight="medium" color="neutral800">
                {values.amount && !error
                  ? `${BigNumber(values.escryptoFee ? values.escryptoFee : "0").toFormatExtended(8)} ${currencyCode}`
                  : `${BigNumber("0").toFormatExtended(8)} ${currentEscrow.body.head.currency.publicCode}`}
              </Text>
            )}
          </FlexGap>

          {broker && userRole === Roles.broker ? (
            <Column mb="12px">
              <AmountToDeposit
                value={values.brokersCommission}
                title="Broker's commission"
                onChangeInput={value => onUserInput(value, "brokersCommission")}
                onBlur={() => onBlur("brokersCommission")}
                isError={!!errors.brokersCommission}
                isTouched={!!touched.brokersCommission}
                currency={currency}
                currentRate={currentRate}
                isHaveFiatEquivalent
              />
              {errors.brokersCommission && touched.brokersCommission && (
                <Text textScale="caption2" color="red" mt="4px">
                  {errors.brokersCommission}
                </Text>
              )}
            </Column>
          ) : null}
        </>
      )}
    </>
  );
};

export default Amounts;
