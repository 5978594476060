import { Loader } from "components";
import { lazyLoad } from "utils/loadable";

export { default as EscrowDetails } from "./escrow-details";
export { default as Payments } from "./payments";

export const EscrowHeader = lazyLoad(
  () => import("./escrow-header"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const DeliveryDescription = lazyLoad(
  () => import("./delivery-description"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const EscrowStepWrapper = lazyLoad(
  () => import("./escrow-step-wrapper"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const EscrowPaymentsInfo = lazyLoad(
  () => import("components/escrow/escrow-payments-info"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);
