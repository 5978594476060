import React from "react";
import moment from "moment";

import { useHandleData } from "./hooks";
import { useTranslation } from "context";
import { getAddress } from "configs/web3";

import { Text, Column, FlexGap, Flex, StatusTag } from "components";

import { EscrowStatusEnum } from "store/escrow/enums";

import { PreviewProps } from "./types";
import { Roles } from "../../types";

const Preview: React.FC<PreviewProps> = ({ setFieldValue, values }) => {
  const { t } = useTranslation();

  const { getParticipantName, targetRefWebSite, webSiteTooltip, address, sellersName, buyersName } = useHandleData({
    values,
    setFieldValue,
  });

  return (
    <Column mt="24px" height="100%">
      <FlexGap gap="24px" alignItems="center" justifyContent="space-between" mb="18px">
        <Text textScale="body1" color="neutral800" wordBreak="break-word" flex="1">
          {values.escrowInfo.name}
        </Text>

        <StatusTag status={EscrowStatusEnum.NEW} />
      </FlexGap>

      <FlexGap gap="16px" alignItems="center" mb="32px">
        <Text textScale="hairlineS" color="neutral500" $fontWeight="medium" textTransform="uppercase">
          {t("Date")}:
        </Text>

        <Text textScale="caption1" color="neutral800" textTransform="uppercase">
          {moment(new Date()).format("MM/DD/YY")}
        </Text>
      </FlexGap>

      {values.escrowInfo.description && (
        <Column mb="32px">
          <Text textScale="hairlineS" color="neutral500" textTransform="uppercase" $fontWeight="medium" mb="14px">
            {t("Item Description")}
          </Text>

          <Text textScale="caption1" color="neutral800" wordBreak="break-word">
            {values.escrowInfo.description}
          </Text>
        </Column>
      )}

      <Column mb="32px">
        <Text textScale="hairlineS" color="neutral500" textTransform="uppercase" $fontWeight="medium" mb="14px">
          {t("Parties")}
        </Text>

        {values.role.role === Roles.broker && (
          <Flex justifyContent="space-between" mb="12px">
            <FlexGap gap="4px">
              <Text textScale="caption1" color="neutral800">
                {getParticipantName(Roles.broker)}
              </Text>

              <Text textScale="caption1" color="neutral500">
                {`(${t("You")})`}
              </Text>
            </FlexGap>

            <Text textScale="caption1" color="neutral800">
              {t("Broker")}
            </Text>
          </Flex>
        )}

        {values.role.buyersEmail || values.role.role === Roles.buyer ? (
          <Flex justifyContent="space-between" mb="12px">
            <FlexGap gap="4px">
              <Text textScale="caption1" color="neutral800">
                {getParticipantName(Roles.buyer, buyersName)}
              </Text>

              {values.role.role === Roles.buyer && (
                <Text textScale="caption1" color="neutral500">
                  {`(${t("You")})`}
                </Text>
              )}
            </FlexGap>

            <Text textScale="caption1" color="neutral800">
              {t("Buyer")}
            </Text>
          </Flex>
        ) : null}

        <Flex justifyContent="space-between">
          <FlexGap gap="4px">
            <Text textScale="caption1" color="neutral800">
              {getParticipantName(Roles.seller, sellersName)}
            </Text>

            {values.role.role === Roles.seller && (
              <Text textScale="caption1" color="neutral500">
                {`(${t("You")})`}
              </Text>
            )}
          </FlexGap>

          <Text textScale="caption1" color="neutral800">
            {t("Seller")}
          </Text>
        </Flex>
      </Column>

      {values.escrowInfo.website && (
        <Column mb="32px">
          <Text textScale="hairlineS" color="neutral500" textTransform="uppercase" $fontWeight="medium" mb="14px">
            {t("Website URL")}
          </Text>

          <Text textScale="caption1" color="neutral800" ellipsis ref={targetRefWebSite}>
            {values.escrowInfo.website}
          </Text>
          {webSiteTooltip}
        </Column>
      )}

      {values.escrowInfo.address && values.escrowInfo.isShipped && address ? (
        <Column mb="32px">
          <Text textScale="hairlineS" color="neutral500" textTransform="uppercase" $fontWeight="medium" mb="14px">
            {t("Delivery Address")}
          </Text>

          <Text textScale="caption1" color="neutral800" wordBreak="break-word">
            {getAddress(address)}
          </Text>
        </Column>
      ) : null}
    </Column>
  );
};

export default Preview;
