import { createAsyncThunk } from "@reduxjs/toolkit";

import { isErrorResult, makeApiRequest } from "services";
import { ENDPOINTS_PROFILE } from "services";

import { ErrorResult } from "services/types";
import { ProfileResponse } from "store/profile/types";
import { SecuritySettingsPayload, SystemSettingsPayload } from "./types";

export const updateSecuritySettings = createAsyncThunk<
  ProfileResponse,
  SecuritySettingsPayload,
  { rejectValue: ErrorResult }
>("settings/update-securitySettings", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<ProfileResponse>({
    method: "PATCH",
    url: ENDPOINTS_PROFILE.updateSecuritySettings,
    data,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const updateSystemSettings = createAsyncThunk<
  ProfileResponse,
  SystemSettingsPayload,
  { rejectValue: ErrorResult }
>("settings/update-settings", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<ProfileResponse>({
    method: "PATCH",
    url: ENDPOINTS_PROFILE.updateSettings,
    data,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});
