import styled from "styled-components";
import { StyledModalWrapper as ModalWrapper } from "../styled";
import { layout } from "styled-system";

export const StyledModalWrapper = styled(ModalWrapper)`
  overflow: hidden;
  padding: 0;
  border: 0 none;
  border-radius: 16px;

  ${layout}
`;
