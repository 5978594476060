export type SetupBiometricModalTypes = {
  useHandleBiometric: () => {
    isLoading: boolean;
    stopMitekSDK: () => void;
    handleVerify: () => void;
    showErrorModal: (key?: string) => void;
  };
  imageSrc: string;
  type?: BiometricType;
  title?: string;
  biometricAction?: string;
};

export enum BiometricType {
  face = "face",
  voice = "voice",
  both = "both",
}
