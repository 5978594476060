import { YubikeyJsonResponseDataType } from "store/yubikey/types";

export const parsingYubikeyData = <T>(result: T) => {
  //Here we have response after yubikey register flow, but we take this response in binary format
  //So we need to parsing these data to JSON, and then, we need to parsing JSON in object

  const response = JSON.stringify(result, undefined, 2);

  const parsingResult = JSON.parse(response) as YubikeyJsonResponseDataType;

  const {
    rawId,
    response: { clientDataJSON },
    type,
  } = parsingResult;

  return { rawId, clientDataJSON, type };
};
