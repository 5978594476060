export type DefaultResponseList<T> = {
  data: T[];
  count: number;
  pending: boolean;
  total?: number;
  orderBy?: OrderEnum;
  order?: OrderEnum;
};

export enum OrderByEnum {
  id = "id",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export enum OrderEnum {
  asc = "asc",
  desc = "desc",
}
