import React from "react";
import { Box, Flex, Text, Timer, InputGroup, InputCode, StyledLink } from "components";
import { useTranslation } from "context";

import { MIN_LENGTH } from "../../hooks";

import { CodeInputFormProps } from "./types";

const CodeInputForm: React.FC<CodeInputFormProps> = ({
  text,
  timer,
  error,
  isTouched,
  isPending,
  value,
  isTimerExpired,
  autoFocus,
  formikKey,
  onUserInput,
  setFieldTouched,
  onResendClick,
  onTimerReachZero,
  resetTwoFa,
  cellHeight,
  cellWidth,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Box py={{ _: "24px", laptop: "32px" }} {...props}>
      <Flex mb="18px" width="100%" justifyContent="space-between">
        <Text color="neutral600" textScale="caption1">
          {`${t("Enter the %number%-digit authentication code", { number: MIN_LENGTH })} ${t("from")}`}
          <Text color="neutral600" textScale="caption1" $fontWeight="bold" mx="4px" as="span">
            {text}
          </Text>
        </Text>

        {timer && onTimerReachZero && (
          <Timer
            timestamp={timer}
            onReachZeroCallback={onTimerReachZero}
            textProps={{ textScale: "caption1", color: "primary", $fontWeight: "bold" }}
            numberHeight={14}
            numberWidth={14}
            keyListShow={["mins", "sec"]}
          />
        )}
      </Flex>

      <InputGroup error={error} isTouched={isTouched}>
        <InputCode
          autoFocus={autoFocus}
          cellCount={MIN_LENGTH}
          onUserInput={onUserInput}
          value={value}
          onFocus={async () => await setFieldTouched(formikKey, false)}
          onBlur={async () => await setFieldTouched(formikKey, true)}
          // Logic for formik was designed not properly for onBlur and onFocus events
          // to prevent rewrite all logic though the app logic for isFocused prop is vise versa
          // onFocus we mark the field as not touched and onBlur we mark it as touched
          isFocused={isTouched === false}
          isError={!!error && !!isTouched}
          cellHeight={cellHeight}
          cellWidth={cellWidth}
        />
      </InputGroup>

      {onResendClick && (
        <Flex width="100%" justifyContent="center">
          <StyledLink
            color="primary"
            onClick={onResendClick}
            disabled={!isTimerExpired || isPending}
            textScale="caption2"
            $fontWeight="bold"
            textAlign="center"
          >
            {t("Resend code")}
          </StyledLink>
        </Flex>
      )}
      {/* TODO: refactor resetTwoFa when new design will ready */}
      {resetTwoFa && (
        <Flex width="100%" justifyContent="center">
          <StyledLink
            color="red"
            onClick={resetTwoFa}
            disabled={isPending}
            textScale="caption2"
            $fontWeight="bold"
            textAlign="center"
          >
            {t("Reset authenticator app")}
          </StyledLink>
        </Flex>
      )}
    </Box>
  );
};

export default CodeInputForm;
