import React from "react";
import { DateRange } from "react-day-picker";

import { useThemeContext, useTranslation } from "context";
import { useHandleDateRange, useTooltip } from "hooks";
import { getTooltipStyles } from "hooks/use-tooltip/theme";
import { StyledDateSelect } from "../../styled";

import { Flex, RangeDayPicker, Text } from "components";
import { CloseIcon, DropdownIcon } from "components/svg";

import { DateRangeProps } from "./type";

export const DesktopRangeDateSelect: React.FC<DateRangeProps> = ({ dateFrom, dateTo, action }) => {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const { selectedRange, handleSetSelectedDate, resetRange } = useHandleDateRange({
    dateFrom,
    dateTo,
    action,
  });

  const { targetRef, tooltip, setVisible, insideElementRef } = useTooltip(
    <RangeDayPicker
      range={{ from: selectedRange.rangeFrom, to: selectedRange.rangeTo }}
      setDoneClick={(dateRange: DateRange | undefined) => {
        toggleToolTip();
        handleSetSelectedDate(dateRange);
      }}
      setCloseClick={toggleToolTip}
    />,
    {
      trigger: "click",
      customStyles: getTooltipStyles(theme),
      placement: "bottom",
    },
  );

  function toggleToolTip() {
    setVisible(prev => !prev);
  }

  return (
    <>
      <StyledDateSelect position="relative" width="100%" ref={targetRef}>
        <Flex p="10px" flex="1">
          {selectedRange.rangeFrom && selectedRange.rangeTo ? (
            <Text textScale="caption1" $fontWeight="medium" color="neutral600">
              {selectedRange.rangeFrom} - {selectedRange.rangeTo}
            </Text>
          ) : (
            <Text textScale="caption1" $fontWeight="medium" color="neutral600">
              {t("Filter Date")}
            </Text>
          )}
        </Flex>

        <Flex
          height="100%"
          p="8px"
          ref={insideElementRef}
          onClick={selectedRange.rangeFrom && selectedRange.rangeTo ? resetRange : toggleToolTip}
          alignItems="center"
        >
          {selectedRange.rangeFrom && selectedRange.rangeTo ? (
            <CloseIcon width="14px" color="neutral600" />
          ) : (
            <DropdownIcon width="12px" />
          )}
        </Flex>
      </StyledDateSelect>
      {tooltip}
    </>
  );
};
