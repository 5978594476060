import React from "react";

import { useTranslation } from "context";

import { Text } from "components";

import { ShowingOutOfProps } from "components/pagination-container/types";

const ShowingOutOf: React.FC<ShowingOutOfProps> = ({ count, lastIndex, firstIndex }) => {
  const { t } = useTranslation();

  return (
    <Text textScale="caption2" color="neutral500">
      {t("Showing")} {firstIndex} - {lastIndex}
      {" " + t("out of")} {count}
    </Text>
  );
};

export default ShowingOutOf;
