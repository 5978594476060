import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => state.notifications.notificationsListPayload.page,
    (state: AppState) => state.notifications.notificationsListPayload.size,
    (state: AppState) => state.notifications.unreadNotificationCount,
    (state: AppState) => state.notifications.notificationsListData.data,
  ],
  (page, size, unreadNotificationCount, data) => ({
    page,
    size,
    unreadNotificationCount,
    data,
  }),
);
