import React from "react";
import { Svg } from "components";
import { useThemeContext } from "context";

import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "primaryHover", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        d="M20.0033 9.9951V4.99302C20.0033 3.88799 19.1075 2.99219 18.0025 2.99219H4.99708C3.89205 2.99219 2.99625 3.88799 2.99625 4.99302V18.9989C2.99625 20.1039 3.89205 20.9997 4.99708 20.9997H9.99916"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99875 15.0002H9.99917"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99875 11.0002H14.0008"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.12255 15.1253C6.19162 15.1253 6.24761 15.0693 6.24761 15.0002C6.24761 14.9311 6.19162 14.8752 6.12255 14.8752C6.05349 14.8752 5.9975 14.9311 5.9975 15.0002C5.9975 15.0693 6.05349 15.1253 6.12255 15.1253"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.12255 11.1253C6.19162 11.1253 6.24761 11.0693 6.24761 11.0002C6.24761 10.9311 6.19162 10.8752 6.12255 10.8752C6.05349 10.8752 5.9975 10.9311 5.9975 11.0002C5.9975 11.0693 6.05349 11.1253 6.12255 11.1253"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5023 22.0039C15.6814 22.0039 14.0399 20.907 13.3431 19.2248C12.6463 17.5426 13.0314 15.6062 14.319 14.3187C15.6065 13.0312 17.5428 12.646 19.2251 13.3428C20.9073 14.0396 22.0042 15.6812 22.0042 17.502C22.0042 19.9883 19.9886 22.0039 17.5023 22.0039"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6856 20.6859L17.5023 17.5026V13.0078"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99875 6.9924H14.0008"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.12255 7.11745C6.19162 7.11745 6.24761 7.06146 6.24761 6.9924C6.24761 6.92333 6.19162 6.86734 6.12255 6.86734C6.05349 6.86734 5.9975 6.92333 5.9975 6.9924C5.9975 7.06146 6.05349 7.11745 6.12255 7.11745"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
