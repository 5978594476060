import React from "react";

import { Button, Text, Flex, FlexGap, StyledLink } from "components";
import { StyledModalWrapper } from "../styled";
import CloseModalWrapper from "../close-modal-wrapper";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";

import { selectCombinedData } from "./selectors";

const ActionModal: React.FC = () => {
  const {
    icon,
    title,
    text,
    btnArr,
    isLoading,
    linkInfo,
    isShowTitle = true,
    showCloseModalIcon = true,
    clickOutsideHandler,
  } = useAppSelector(selectCombinedData);

  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    if (clickOutsideHandler) {
      clickOutsideHandler();
    } else {
      dispatch(hideModal());
    }
  };

  return (
    <StyledModalWrapper>
      {showCloseModalIcon && <CloseModalWrapper closeModalHandler={closeModalHandler} />}

      <Flex mt="26px" mb="16px" justifyContent="center" width="100%">
        {React.isValidElement(icon) && icon}
      </Flex>

      {isShowTitle && (
        <Text color="neutral600" textAlign="center" textScale="body2" $fontWeight="bold">
          {title}
        </Text>
      )}

      {typeof text === "string" ? (
        <Text wordBreak="break-word" color="neutral600" mt="12px" mb="12px" textAlign="center" textScale="caption1">
          {text}
        </Text>
      ) : (
        text
      )}

      {btnArr && btnArr.length ? (
        <FlexGap
          width="100%"
          gap="14px 16px"
          mb={{ _: "20px", tablet: "30px" }}
          mt={{ _: "32px", tablet: "40px" }}
          justifyContent="center"
          alignItems="center"
          flexDirection={{ _: "column", tablet: "row" }}
        >
          {btnArr.map((btn, index) => {
            return (
              <Button key={index} width="100%" isLoading={isLoading} onClick={btn.callback} {...btn.props}>
                {btn.buttonText}
              </Button>
            );
          })}
        </FlexGap>
      ) : null}
      {linkInfo && (
        <StyledLink
          color="primary"
          onClick={linkInfo.callback}
          textScale="caption1"
          $fontWeight="bold"
          textAlign="center"
        >
          {linkInfo.title}
        </StyledLink>
      )}
    </StyledModalWrapper>
  );
};

export default ActionModal;
