import { ErrorResult } from "services/types";
import { ConfirmTwoFaPayload } from "store/auth/types";

export type FaceBiometricState = {
  pending: boolean;
  error: ErrorResult | null;
  isShowErrorModal: boolean;
  isVoiceRecord: boolean;
};

export type EnrollVerifyDataPayload = {
  otps: ConfirmTwoFaPayload;
  voiceFeatures?: VerifyData[];
  selfieImages?: VerifyData[];
};

export type VerifyData = {
  data: string;
};

export type EnrollVerifyDataResponse = {
  faceStatus?: EnrollStatus;
  voiceStatus?: EnrollStatus;
  voiceReasons?: string[] | null;
  faceReasons?: string[] | null;
};

export enum EnrollStatus {
  failed = "Failed",
  enrolled = "Enrolled",
  updated = "Updated",
}

export enum MitekStatus {
  AUTHENTIC = "Authentic",
  FRAUDULENT = "Fraudulent",
  UNDETERMINED = "Undetermined",
}

export type MitekStatusResponse = {
  faceStatus?: MitekStatus;
  voiceStatus?: MitekStatus;
};

export enum VoiceTransactionTypeEnum {
  ENROLL = "Enroll",
  VERIFY = "Verify",
}

export type VoiceQualityPayload = {
  transactionType: VoiceTransactionTypeEnum;
  voiceFeature: VerifyData;
};

export type VoiceQualityResponse = {
  status: string;
  reasons?: string[];
};
