import { toast } from "react-toastify";

import { useTranslation } from "context";
import { usePendingItem } from "pages/main/wallets/components/whitelist/hooks";
import { useAppDispatch, useAppSelector } from "store/store";

import { toastOptionsError, toastOptionsInfo } from "components/alerts";

import { hideModal } from "store/modal";

import { WhitelistTableRowProps } from "../components/types";
import { WhitelistSideModalProps } from "components/modal/components/whitelist-side-modal/types";
import { WhitelistStateEnum } from "store/wallets/enums";

export const useHandleData = ({ item }: WhitelistTableRowProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const setFieldValue = useAppSelector(
    state => (state.modal.props as unknown as WhitelistSideModalProps).setFieldValue,
  );

  const { timer, onTimerReachZero } = usePendingItem({ validatedAt: item.validatedAt, id: item.id });

  const isStatusPending = item.status === WhitelistStateEnum.pending;
  const isStatusBlacklisted = item.status === WhitelistStateEnum.blacklisted;

  const handleSelectAddress = async () => {
    if (isStatusPending) {
      toast.info(`${t("You will be able to use this address after its validation")}`, toastOptionsInfo);
    } else {
      if (!isStatusBlacklisted) {
        await setFieldValue("walletAddressTo", item.address);
        dispatch(hideModal());
      } else {
        toast.error(
          t("This address was blacklisted. You can’t use it for any actions. Please, use another address."),
          toastOptionsError,
        );
      }
    }
  };

  return { timer, onTimerReachZero, handleSelectAddress, isStatusPending, isStatusBlacklisted };
};
