import { createAsyncThunk } from "@reduxjs/toolkit";

import { isErrorResult, makeApiRequest, ENDPOINTS_AUTH, ENDPOINTS_PROFILE } from "services";

import { ErrorResult } from "services/types";
import { SuccessResponse } from "store/wallets/types";
import {
  ConfirmTwoFaPayload,
  EmailPayload,
  GetZendeskTokenResponse,
  LoginApplePayload,
  LoginGooglePayload,
  LoginUserPayload,
  LoginUserResponse,
  RegistrationPayload,
  ResetPasswordPayload,
  ChangePasswordCheckRequest,
  ChangePasswordStatusResponse,
  ChangePasswordConfirmPayload,
  ChangePasswordConfirmResponse,
  ChangePasswordCheckResponse,
} from "./types";
import { ProfileResponse } from "store/profile/types";

const getDataAndHeadersFromPingOneObj = <T extends { pingOneResponse?: string }>(data: T) => {
  const { pingOneResponse } = data;
  const headers = {
    "x-sdk-data-payload": pingOneResponse,
  };

  delete data.pingOneResponse;

  return { data, headers };
};

export const loginUser = createAsyncThunk<LoginUserResponse, LoginUserPayload, { rejectValue: ErrorResult }>(
  "auth/login",
  async (dataObj, { rejectWithValue, dispatch }) => {
    const { data, headers } = getDataAndHeadersFromPingOneObj(dataObj);

    const result = await makeApiRequest<LoginUserResponse>({
      method: "POST",
      url: ENDPOINTS_AUTH.login,
      data,
      headers,
      timeout: 60_000,
    });

    if (isErrorResult(result)) {
      if (result.code === 418) {
        dispatch(sendVerifyEmail(data));
        return rejectWithValue(result);
      } else {
        return rejectWithValue(result);
      }
    }

    return result;
  },
);

export const sendVerifyEmail = createAsyncThunk<SuccessResponse, LoginUserPayload, { rejectValue: ErrorResult }>(
  "auth/send-verify-email",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<SuccessResponse>({
      method: "POST",
      url: ENDPOINTS_AUTH.verifyEmail,
      data,
      isShowError: false,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const loginUserGoogle = createAsyncThunk<LoginUserResponse, LoginGooglePayload, { rejectValue: ErrorResult }>(
  "auth/google-login",
  async (paramsObj, { rejectWithValue }) => {
    const { data: params, headers } = getDataAndHeadersFromPingOneObj(paramsObj);

    const result = await makeApiRequest<LoginUserResponse>({
      method: "GET",
      url: ENDPOINTS_AUTH.googleLoginRegister,
      params,
      headers,
      timeout: 60_000,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const loginUserApple = createAsyncThunk<LoginUserResponse, LoginApplePayload, { rejectValue: ErrorResult }>(
  "auth/apple-login",
  async (dataObj, { rejectWithValue }) => {
    const { data, headers } = getDataAndHeadersFromPingOneObj(dataObj);

    const result = await makeApiRequest<LoginUserResponse>({
      method: "POST",
      url: ENDPOINTS_AUTH.appleLoginRegister,
      data,
      headers,
      timeout: 60_000,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const registerUser = createAsyncThunk<undefined, RegistrationPayload, { rejectValue: ErrorResult }>(
  "auth/register",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<undefined>({
      method: "POST",
      url: ENDPOINTS_AUTH.register,
      data,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const verifyEmail = createAsyncThunk<SuccessResponse, LoginGooglePayload, { rejectValue: ErrorResult }>(
  "auth/verify-email",
  async (params, { rejectWithValue }) => {
    const result = await makeApiRequest<SuccessResponse>({
      method: "GET",
      url: ENDPOINTS_AUTH.verifyEmail,
      params,
      isShowError: false,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const forgotPassword = createAsyncThunk<SuccessResponse, EmailPayload, { rejectValue: ErrorResult }>(
  "auth/forgot-password",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<SuccessResponse>({
      method: "POST",
      url: ENDPOINTS_AUTH.forgotPassword,
      data,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const resetPassword = createAsyncThunk<SuccessResponse, ResetPasswordPayload, { rejectValue: ErrorResult }>(
  "auth/reset-password",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<SuccessResponse>({
      method: "POST",
      url: ENDPOINTS_AUTH.resetPassword,
      data,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getZendeskToken = createAsyncThunk<GetZendeskTokenResponse, undefined, { rejectValue: ErrorResult }>(
  "auth/get-zendesk-token",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<GetZendeskTokenResponse>({
      method: "POST",
      url: ENDPOINTS_AUTH.getZendeskToken,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const sendCodesForTwoFa = createAsyncThunk<SuccessResponse, undefined, { rejectValue: ErrorResult }>(
  "auth/get-two-fa-codes",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<SuccessResponse>({
      method: "POST",
      url: ENDPOINTS_AUTH.sendCodeForTwoFaCheck,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const confirmTwoFaAuth = createAsyncThunk<ProfileResponse, ConfirmTwoFaPayload, { rejectValue: ErrorResult }>(
  "auth/confirm-two-fa-codes",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<ProfileResponse>({
      method: "POST",
      url: ENDPOINTS_AUTH.verifyTwoFaCheck,
      data,
      isShowError: false,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const finishSession = createAsyncThunk<SuccessResponse, undefined, { rejectValue: ErrorResult }>(
  "auth/finish-session",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<SuccessResponse>({
      method: "POST",
      url: ENDPOINTS_AUTH.logout,
      isShowError: false,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const changePasswordCheck = createAsyncThunk<
  ChangePasswordCheckResponse,
  ChangePasswordCheckRequest,
  { rejectValue: ErrorResult }
>("user/change-password-check", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<ChangePasswordConfirmResponse>({
    method: "POST",
    url: ENDPOINTS_PROFILE.changePasswordCheck,
    data,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const changePasswordStatus = createAsyncThunk<
  ChangePasswordStatusResponse,
  undefined,
  { rejectValue: ErrorResult }
>("user/change-password-status", async (_, { rejectWithValue }) => {
  const result = await makeApiRequest<ChangePasswordStatusResponse>({
    method: "GET",
    url: ENDPOINTS_PROFILE.changePasswordStatus,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const changePasswordConfirm = createAsyncThunk<
  ChangePasswordConfirmResponse,
  ChangePasswordConfirmPayload,
  { rejectValue: ErrorResult }
>("user/change-password-confirm", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<ChangePasswordConfirmResponse>({
    method: "POST",
    url: ENDPOINTS_PROFILE.changePasswordConfirm,
    data,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});
