import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { checkVoiceQuality, enrollVerifyData, sendCodesForTwoFaVerifyBiometrics } from "./actions";

import { FaceBiometricState } from "./types";

const initialState: FaceBiometricState = {
  pending: false,
  error: null,
  isShowErrorModal: true,
  isVoiceRecord: false,
};

const verifyBiometricSlice = createSlice({
  name: "verifyBiometric",
  initialState: {
    ...initialState,
  },
  reducers: {
    resetVerifyBiometric: () => {
      return initialState;
    },
    setIsShowError: (state, action: PayloadAction<boolean>) => {
      state.isShowErrorModal = action.payload;
    },
    setIsVoiceRecord: (state, action: PayloadAction<boolean>) => {
      state.isVoiceRecord = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(enrollVerifyData.pending, state => {
        state.pending = true;
      })
      .addCase(enrollVerifyData.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(enrollVerifyData.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      // Resend Codes for two-fa verify biometrics on email
      .addCase(sendCodesForTwoFaVerifyBiometrics.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(sendCodesForTwoFaVerifyBiometrics.pending, state => {
        state.pending = true;
      })
      .addCase(sendCodesForTwoFaVerifyBiometrics.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(checkVoiceQuality.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(checkVoiceQuality.pending, state => {
        state.pending = true;
      })
      .addCase(checkVoiceQuality.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      });
  },
});

export const { resetVerifyBiometric, setIsVoiceRecord, setIsShowError } = verifyBiometricSlice.actions;
export default verifyBiometricSlice;
