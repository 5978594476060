import React, { useState } from "react";

import { useTranslation } from "context";
import { useAppDispatch } from "store/store";

import { StyledModalWrapper } from "../styled";
import CloseModalWrapper from "../close-modal-wrapper";
import { DeleteAssetsIcon } from "components/svg";
import { Box, Button, Checkbox, FlexGap, Text } from "components";

import { hideModal, showModal } from "store/modal";

import { UserAssets } from "./components";

const ReviewAssetsFromDeleteAccountModal: React.FC = () => {
  const [isConfirmedLoseAssets, setIsConfirmedLoseAssets] = useState(false);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const onAgreeHandler = () => {
    setIsConfirmedLoseAssets(!isConfirmedLoseAssets);
  };

  const onDeleteAccountClick = () => {
    dispatch(showModal({ rootId: "settings", modalName: "deletionReasonModal" }));
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} mb="8px" />

      <DeleteAssetsIcon width="72px" />

      <Box mt="14px" mb="24px">
        <Text pb="12px" textScale="body2" color="neutral600" textAlign="center">
          {t("Delete account")}
        </Text>

        <Text color="neutral600" textScale="caption1" textAlign="center">
          {t("You have the following assets:")}
        </Text>
      </Box>

      <UserAssets />

      <Checkbox
        checked={isConfirmedLoseAssets}
        onChange={onAgreeHandler}
        label={
          <Text color="neutral600" textScale="caption2" px="14px">
            {t("By continuing I agree and accept to lose all my assets.")}
          </Text>
        }
        my="24px"
      />

      <FlexGap gap="14px" mb="16px" flexDirection={{ _: "column-reverse", tablet: "row" }} width="100%">
        <Button variant="outline" minWidth={{ _: "100%", tablet: "170px" }} onClick={closeModalHandler}>
          {t("Cancel")}
        </Button>

        <Button
          minWidth={{ _: "100%", tablet: "170px" }}
          disabled={!isConfirmedLoseAssets}
          onClick={onDeleteAccountClick}
        >
          {t("Delete Account")}
        </Button>
      </FlexGap>
    </StyledModalWrapper>
  );
};

export default ReviewAssetsFromDeleteAccountModal;
