import { Token, nativeOnChain, NATIVE_ADDRESS } from "configs/web3";
import { CurrenciesList, TokenAddressMap } from "store/tokens/types";

export type TokenMap = { [currencyCode: string]: Token };

export const tokensToChainMap = (tokens: CurrenciesList): TokenAddressMap => {
  const map = tokens.reduce<TokenAddressMap>((map, info) => {
    try {
      // we receive native address(0x0000000000000000000000000000000000000000) from API for all native tokens, included Solana
      // and we set native token for every network from nativeOnChain func
      if (info.contractAddress === NATIVE_ADDRESS) {
        const nativeToken = nativeOnChain(info.chainId);

        map[info.chainId] = { [nativeToken.publicCode]: nativeToken, ...map[info.chainId] };
      } else {
        const token = new Token(
          info.chainId,
          info.contractAddress,
          info.decimals,
          info.symbol,
          info.name,
          info.publicCode,
          info.logoURI,
        );

        if (map[token.chainId]?.[token.publicCode] !== undefined) {
          console.warn(`Duplicate token skipped: ${token.address}`);
          return map;
        }

        if (!map[token.chainId]) {
          map[token.chainId] = {};
        }

        map[token.chainId][token.publicCode] = token;
      }

      return map;
    } catch {
      return map;
    }
  }, {});

  return map;
};
