import styled from "styled-components";
import { FlexGap } from "components";

export const StyledNetworkWrapper = styled(FlexGap)`
  gap: 10px;
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  border: 1px solid ${({ theme }) => theme.colors.neutral400};
  height: 44px;
  padding: 14px 10px;
  align-items: center;
`;
