import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 72 72" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_13398_144958)">
        <rect x="18" y="49" width="36" height="17" rx="4" fill={theme.colors.primary} />
      </g>
      <circle cx="36" cy="36" r="28" fill={theme.colors.primaryLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.primary} strokeOpacity="0.5" />
      <path
        d="M48.8762 32.0257C49.2858 33.3102 49.4973 34.6496 49.5034 35.9978C49.5034 43.4568 43.4568 49.5034 35.9978 49.5034C28.5389 49.5034 22.4922 43.4568 22.4922 35.9978C22.4922 28.5389 28.5389 22.4922 35.9978 22.4922C37.346 22.4983 38.6855 22.7098 39.97 23.1194"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.5227 27.3899L44.6094 25.4766"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.5067 24.4062L46.5234 27.3895"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.9961 32.8987H42.0011"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.3739 32.9V30.6491C33.3258 29.8734 33.9117 29.2038 34.6869 29.1484H37.313C38.0882 29.2038 38.6741 29.8734 38.626 30.6491V32.9"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3086 32.8984V41.527C31.3086 42.3558 31.9804 43.0277 32.8092 43.0277H39.1869C40.0156 43.0277 40.6875 42.3558 40.6875 41.527V32.8984"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.primary} strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_13398_144958"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_13398_144958" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
