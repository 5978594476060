import * as yup from "yup";

import { useTranslation } from "context";
import { REGEX } from "configs";
import { InitialValuesType } from "./types";

export const initialValues: InitialValuesType = {
  password: "",
  confirmPassword: "",
};

const useValidationSchema = () => {
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required(t("This field is required"))
      .matches(
        REGEX.password,
        t("Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"),
      ),
    confirmPassword: yup
      .string()
      .required(t("This field is required"))
      .oneOf([yup.ref("password")], t("Passwords do not match")),
  });

  return { validationSchema, initialValues };
};

export default useValidationSchema;
