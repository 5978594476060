// Global
import React, { useCallback, useState } from "react";
import Cropper, { Area } from "react-easy-crop";
// Context
import { useTranslation } from "context";
// Components
import { Button, Flex, InputRange, FlexGap } from "components";
import { StyledPreviewFile } from "./styled";
// Configs

import { ImageCropperProps } from "./types";
// Utils
import getCroppedImg from "./crop-image";
// Hooks
import { useUploadImage } from "hooks";
import { DIMENSIONS, MAX_SIZE, IMAGE_EXTENSIONS } from "hooks/use-upload-image/constants";
// Store
import { useAppDispatch } from "store/store";
import { hideModal } from "store/modal";

const ImageCropper: React.FC<ImageCropperProps> = ({ image, setImage, handleImageCropper, dimension = DIMENSIONS }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { zoom, onZoomChange, crop, setCrop, onMediaLoaded, dataUrlToFile } = useUploadImage();

  const [croppedAreaPixels, setCroppedAreaPixels] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [result, setResult] = useState<File>(new File([""], "emptyFile"));

  const onCropComplete = useCallback(
    async (croppedArea: Area) => {
      setCroppedAreaPixels(croppedArea);

      try {
        const cropImage = await getCroppedImg(image.src, croppedArea);
        if (cropImage) {
          const formattedFile = await dataUrlToFile(cropImage, image.name);
          const reader = new FileReader();
          reader.onload = () => {
            const baseURL = reader.result;
            setImage({ src: baseURL as string, name: formattedFile.name });
          };
          setResult(formattedFile);
        }
      } catch (error) {
        console.error("Error in ImageCropper:", error);
      }
    },
    [croppedAreaPixels, image.src],
  );

  return (
    <>
      {image.src ? (
        <Flex flexDirection="column" alignItems="center">
          <StyledPreviewFile>
            <Cropper
              image={image.src}
              crop={crop}
              zoom={zoom.zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={onZoomChange}
              cropSize={{ width: dimension.widthCrop, height: dimension.heightCrop }}
              onMediaLoaded={onMediaLoaded}
            />
          </StyledPreviewFile>

          <Flex my="30px" width={{ _: "264px", tablet: "354px" }}>
            <InputRange
              name="img-cropper"
              min={zoom.initialZoom}
              max={zoom.initialZoom + 10}
              value={zoom.zoom}
              onValueChanged={onZoomChange}
            />
          </Flex>

          <FlexGap
            mt="18px"
            mb={{ _: "20px", tablet: "30px" }}
            flexDirection={{ _: "column", tablet: "row" }}
            justifyContent="space-between"
            gap="14px 16px"
            width="100%"
          >
            <Button
              width={{ _: "100%", tablet: "170px" }}
              height="44px"
              onClick={() => dispatch(hideModal())}
              variant="outline"
            >
              {t("Cancel")}
            </Button>

            <Button
              width={{ _: "100%", tablet: "170px" }}
              height="44px"
              onClick={() => {
                handleImageCropper(result);
                dispatch(hideModal());
              }}
            >
              {t("Crop")}
            </Button>
          </FlexGap>
        </Flex>
      ) : null}
    </>
  );
};

ImageCropper.defaultProps = {
  maxSizeMb: MAX_SIZE,
  fileExtensions: IMAGE_EXTENSIONS,
};

export default ImageCropper;
