import React from "react";

import { Box, Flex, Image, Text } from "components";
import { StyledButton } from "../choose-network/styled";
import { SpinnerIcon } from "components/svg";

import { useTranslation } from "context";

import { ChooseTokenProps } from "./types";

const ChooseToken: React.FC<ChooseTokenProps> = ({ currency, currencyKey, isLoading, onButtonClick }) => {
  const { t } = useTranslation();

  return (
    <Box mt={{ _: "18px", tablet: "24px" }}>
      <Text textScale="caption1" mb="8px" color="neutral600">
        {t("Token")}
      </Text>
      <StyledButton isLoading={isLoading} onClick={onButtonClick}>
        {isLoading ? (
          <Flex flex="1" justifyContent="center">
            <SpinnerIcon color="neutral300" />
          </Flex>
        ) : (
          <>
            {currency && currencyKey ? (
              <Flex alignItems="center">
                <Image src={currency.logoURI} alt={currency.name} width="24px" aspectRatio={1} variant="circle" />
                <Text textScale="caption1" $fontWeight="bold" color="neutral800" mx="10px">
                  {currency.symbol}
                </Text>
              </Flex>
            ) : (
              <Text textScale="caption1" $fontWeight="bold" color="neutral800">
                {t("Choose Token")}
              </Text>
            )}
          </>
        )}
      </StyledButton>
    </Box>
  );
};

export default ChooseToken;
