import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

import { UserWalletsStatusEnum } from "store/profile/types";

import { useAppDispatch, useAppSelector } from "store/store";
import { setWhitelistPayload } from "store/wallets/whitelist";
import { getWhitelist } from "store/wallets/whitelist/actions";
import { selectCombinedDataForUseHandlePayload } from "./selectors";

export const useHandlePayload = () => {
  const { pending, data, count, page, size, chainId, search, user } = useAppSelector(
    selectCombinedDataForUseHandlePayload,
  );

  const { activeTab } = useOutletContext<{ activeTab: number }>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // chainId and search undefined due to BE enum not accepting empty
    if (user?.walletsStatus === UserWalletsStatusEnum.CREATED && activeTab === 3) {
      dispatch(
        getWhitelist({
          page,
          size,
          search: search ? search : undefined,
          chainId: chainId ? chainId : undefined,
        }),
      );
    }
  }, [activeTab, page, size, chainId, search, user?.walletsStatus]);

  const handlePageClick = (selectedItem: { selected: number }) => {
    const currentPage = selectedItem.selected + 1;
    dispatch(setWhitelistPayload({ page: currentPage }));
  };

  return { data, count, pending, page, size, handlePageClick };
};
