import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";
import { WithdrawPayWithTokenModalProps } from "./type";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => (state.modal.props as unknown as WithdrawPayWithTokenModalProps).handleWithdraw,
    (state: AppState) => (state.modal.props as unknown as WithdrawPayWithTokenModalProps).paramsChainId,
    (state: AppState) => (state.modal.props as unknown as WithdrawPayWithTokenModalProps).tokenKey,
    (state: AppState) => (state.modal.props as unknown as WithdrawPayWithTokenModalProps).amount,
    (state: AppState) => state.walletsFee.hotWalletWithdrawFee,
    (state: AppState) => state.hotWallets.pending,
  ],
  (handleWithdraw, paramsChainId, tokenKey, amount, fee, pending) => ({
    handleWithdraw,
    paramsChainId,
    tokenKey,
    amount,
    fee,
    pending,
  }),
);
