import BigNumber from "bignumber.js";
import { BigNumber as BigNumberETH } from "@ethersproject/bignumber";
import { formatUnits } from "@ethersproject/units";

import { useTranslation } from "context";
import { useGetFiatSymbolAndAbbreviation, useTooltip } from "hooks";

import { Box, InputGroup, InputNumeric, RowBetween, Text } from "components";
import { StyledAmountToDeposit } from "./styled";
import { BalanceSection, ButtonsSection } from "./components";

import { AmountToDepositProps, BalancePositionEnum } from "./types";

const AmountToDeposit: React.FC<AmountToDepositProps> = ({
  value,
  title,
  tokenBalance,
  currency,
  balanceTitle,
  balancePosition,
  isAmountDisabled = false,
  isError,
  isTouched,
  isHaveFiatEquivalent = false,
  isMaxButtonDisabled,
  isMinButtonDisabled,
  pending,
  currentRate,
  onChangeInput,
  onBlur,
  handleMaxButtonClick,
  handleMinButtonClick,
}) => {
  const { t } = useTranslation();
  const { fiatSymbol } = useGetFiatSymbolAndAbbreviation();

  const { targetRef: targetRefAmountCurrency, tooltip: amountCurrencyTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });
  const { targetRef: targetRefAmountInFiat, tooltip: amountInFiatTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const formattedBalance = BigNumberETH.isBigNumber(tokenBalance)
    ? formatUnits(tokenBalance, currency?.decimals)
    : tokenBalance;
  const symbol = currency ? currency.symbol : "";
  const amountInFiat = BigNumber(value ? value : "0")
    .times(currentRate ?? 0)
    .toFormatExtended(2);

  return (
    <StyledAmountToDeposit isError={isError} isTouched={isTouched} cursor={isAmountDisabled ? "not-allowed" : "unset"}>
      <RowBetween>
        <Text textScale="caption1" color="neutral500">
          {t(title)}
        </Text>

        {balancePosition === BalancePositionEnum.top && balanceTitle && formattedBalance ? (
          <BalanceSection pending={pending!} formattedBalance={formattedBalance} balanceTitle={balanceTitle} />
        ) : null}
      </RowBetween>

      <Box my={{ _: "0px", laptop: "12px" }}>
        <RowBetween alignItems="end">
          <Box pointerEvents={isAmountDisabled ? "none" : "auto"} width="100%">
            <InputGroup isShowError={false} width="100%" scale="lg">
              <InputNumeric
                onUserInput={onChangeInput}
                placeholder="0"
                value={value}
                onBlur={onBlur}
                fontSize="40px"
                borderColor="transparent"
                textAlign="end"
              />
            </InputGroup>
          </Box>

          <Box width="60px">
            <Text mb="6px" textScale="body3" ref={targetRefAmountCurrency} ellipsis textAlign="end">
              {symbol}
            </Text>

            {amountCurrencyTooltip}
          </Box>
        </RowBetween>

        {isHaveFiatEquivalent && (
          <Box ellipsis>
            <Text textScale="caption2" color="neutral500" textAlign="end" ellipsis ref={targetRefAmountInFiat}>
              ~{`${fiatSymbol}${amountInFiat}`}
            </Text>

            {amountInFiatTooltip}
          </Box>
        )}
      </Box>

      {handleMaxButtonClick || handleMinButtonClick ? (
        <ButtonsSection
          handleMaxButtonClick={handleMaxButtonClick}
          handleMinButtonClick={handleMinButtonClick}
          isMaxButtonDisabled={isMaxButtonDisabled}
          isMinButtonDisabled={isMinButtonDisabled}
          pending={pending}
        />
      ) : null}

      {balancePosition === BalancePositionEnum.bottom && balanceTitle && formattedBalance ? (
        <Box mt="34px">
          <BalanceSection pending={pending!} formattedBalance={formattedBalance} balanceTitle={balanceTitle} />
        </Box>
      ) : null}
    </StyledAmountToDeposit>
  );
};

export default AmountToDeposit;
