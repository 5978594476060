import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "green", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 72 84" color="transparent" {...props} stroke={theme.colors[color]}>
      <g opacity="0.25" filter="url(#filter0_f_498_25595)">
        <rect x="18" y="49" width="36" height="17" rx="4" fill={theme.colors.green} />
      </g>
      <circle cx="36" cy="36" r="28" fill={theme.colors.greenLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.green} strokeOpacity="0.5" />
      <path
        d="M32.998 29.9964H28.4961"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.001 40.5L40.5016 42.0006L39.001 43.5013"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.9967 40.5L28.4961 42.0006L29.9967 43.5013"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.749 44.2519L35.2496 39.75"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="45.0035"
        cy="26.9947"
        rx="6.0025"
        ry="6.0025"
        fill={theme.colors.green}
        fillOpacity="0.14"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.0046 25.9961L44.5049 28.4971L43.0029 26.9965"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.4998 35.2503H28.4961"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.5054 37.4984V42.7506C49.5054 46.4801 46.4821 49.5034 42.7526 49.5034H29.247C25.5175 49.5034 22.4941 46.4801 22.4941 42.7506V29.245C22.4941 25.5155 25.5175 22.4922 29.247 22.4922H34.4991"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.green} strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_498_25595"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_498_25595" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
