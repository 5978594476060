import { useEffect } from "react";

import { useAppSelector } from "store/store";

const useCheckIfBlacklisted = (callback: () => void) => {
  const isBlacklisted = useAppSelector(state => state.profile.isBlacklisted);

  useEffect(() => {
    if (isBlacklisted) {
      callback();
    }
  }, [isBlacklisted]);
};

export default useCheckIfBlacklisted;
