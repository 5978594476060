import React from "react";
import { Text } from "components";

export const parseAndFormatText = (text: string) => {
  const parts = text.split(/(%.*?%)/);

  const formattedText = parts.map(part => {
    if (part.startsWith("%") && part.endsWith("%")) {
      const value = part.slice(1, -1);
      return (
        <Text textScale="caption1" as="span" color="neutral800" $fontWeight="medium" key={value}>
          {value}
        </Text>
      );
    } else {
      return part;
    }
  });

  return formattedText;
};
