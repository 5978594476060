import React from "react";
import BigNumber from "bignumber.js";

import { Column, Flex, SlideToConfirm, Text } from "components";
import { LogoLifiSwapIcon } from "components/svg";

import { useTranslation } from "context";

import { useAppSelector } from "store/store";

import { formatValueToBNString } from "configs/web3";

import { SliderProps } from "./types";
import { tabsIndex } from "configs";

const Slider: React.FC<SliderProps> = ({ isConfirmed, isValid, values, onConfirmCallback }) => {
  const { t } = useTranslation();

  const pending = useAppSelector(state => state.swap.pending);
  const activeTab = useAppSelector(state => state.swap.activeTab);
  const swapData = useAppSelector(state => state.swap.swapData);

  return (
    <Column width="100%" mt="40px">
      {/* This prevents slider to be collapsed by tab */}
      {activeTab === tabsIndex.swap.swap && (
        <SlideToConfirm
          disabled={!isValid || !swapData || BigNumber(formatValueToBNString(values.amount)).lte(0)}
          isLoading={pending}
          isConfirmed={isConfirmed}
          onConfirmCallback={onConfirmCallback}
        />
      )}

      <Column alignItems="center" mt="14px">
        <Text textScale="caption2" color="neutral500">
          {t("The price shown is an estimate only.")}
        </Text>

        <Flex alignItems="center" mt="10px">
          <Text textScale="caption2" color="neutral500" mx="8px">
            {t("Powered by")}
          </Text>

          <LogoLifiSwapIcon width="49px" height="19px" />
        </Flex>
      </Column>
    </Column>
  );
};

export default Slider;
