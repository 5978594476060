import React from "react";
import { AnimatePresence, motion } from "framer-motion";

import { Text, Flex } from "components";

import { variants } from "./animation";

import { AnimatedWordProps } from "./types";

export const AnimatedWord: React.FC<AnimatedWordProps> = ({ word, isAnimate }) => {
  return (
    <Flex overflow="hidden">
      <AnimatePresence>
        {word.split("").map((char, index) => (
          <motion.div
            key={index}
            initial="initial"
            animate={isAnimate ? "animate" : "initial"}
            variants={variants}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <Text fontSize="32px" $fontWeight="bold">
              {char}
            </Text>
          </motion.div>
        ))}
      </AnimatePresence>
    </Flex>
  );
};
