import { useEffect, useState } from "react";

import { useTooltip } from "hooks";
import { useHandleData as useEscrowInfoHandleData } from "../../escrow-info/hooks";
import { useTranslation } from "context";
import { isErrorResult } from "services";

import { useAppDispatch, useAppSelector } from "store/store";
import { getParticipant } from "store/escrow/create-escrow/actions";

import { AccountType } from "store/profile/types";
import { useHandleDataProps } from "../types";

export const useHandleData = ({ values, setFieldValue }: useHandleDataProps) => {
  const user = useAppSelector(state => state.profile.user);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { address } = useEscrowInfoHandleData({
    setFieldValue,
    values,
  });

  const [sellersName, setSellersName] = useState("");
  const [buyersName, setBuyersName] = useState("");

  const { targetRef: targetRefWebSite, tooltip: webSiteTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  useEffect(() => {
    if (values.role.sellersEmail) {
      dispatch(getParticipant(values.role.sellersEmail)).then(res => {
        if (!isErrorResult(res.payload)) {
          if (res.payload?.accountType !== AccountType.EMPTY) {
            setSellersName(`${res.payload?.firstName} ${res.payload?.lastName}`);
          } else {
            setSellersName(t("Unknown"));
          }
        }
      });
    }

    if (values.role.buyersEmail) {
      dispatch(getParticipant(values.role.buyersEmail)).then(res => {
        if (!isErrorResult(res.payload)) {
          if (res.payload?.accountType !== AccountType.EMPTY) {
            setBuyersName(`${res.payload?.firstName} ${res.payload?.lastName}`);
          } else {
            setBuyersName(t("Unknown"));
          }
        }
      });
    }
  }, []);

  const getParticipantName = (role: string, participantName?: string) => {
    if (participantName && values.role.role !== role) {
      return participantName;
    } else {
      return `${user?.firstName} ${user?.lastName}`;
    }
  };

  return { getParticipantName, targetRefWebSite, webSiteTooltip, address, sellersName, buyersName };
};
