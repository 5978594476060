import * as yup from "yup";

import { useTranslation } from "context";
import { REGEX } from "configs";
import { InitialValuesType } from "./types";

export const initialValues: InitialValuesType = {
  email: "",
};

const useValidationSchema = () => {
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    email: yup.string().required(t("This field is required")).matches(REGEX.email, t("Invalid email address")),
  });

  return { validationSchema, initialValues };
};

export default useValidationSchema;
