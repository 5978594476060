import styled from "styled-components";

import { ContainerProps, LabelProps } from "./types";

export const StyledRadioButtonLabel = styled.label<LabelProps>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const StyledRadioButtonContainer = styled.div<ContainerProps>`
  display: flex;
  cursor: pointer;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  position: relative;
  justify-content: center;
  opacity: ${({ disabled, checked }) => (disabled && checked ? "0.5" : "")};

  &::before {
    content: "";
    border-radius: 50%;
    border: 1.5px solid ${({ theme }) => theme.colors.neutral700};
    background: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
    transition: all 0.3s ease-out;
  }

  &:hover {
    &::before {
      border: 1.5px solid ${({ theme }) => theme.colors.blue};
      background-color: ${({ theme }) => theme.colors.blueLight};
      transition: all 0.3s ease-out;
    }
  }
`;

export const StyledCustomRadioButton = styled.div`
  width: 0;
  height: 0;
  border-radius: 100%;
  border: 5px solid ${({ theme }) => theme.colors.blue};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition:
    width 0.3s ease-in,
    height 0.3s ease-in,
    opacity 0.3s ease;
  pointer-events: none;
  z-index: 1;
  opacity: 0;

  &::before {
    opacity: 0;
    content: "";
    width: 14px;
    position: absolute;
    height: 14px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 100%;
  }
`;

export const StyledDefaultRadioButton = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${StyledCustomRadioButton} {
      width: 100%;
      height: 100%;
      opacity: 1;
      transition:
        width 0.3s ease-out,
        height 0.3s ease-out,
        opacity 0.3s ease;

      &::before {
        opacity: 1;
        transition: opacity 0.3s ease;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
