import styled from "styled-components";
import { layout, space } from "styled-system";

import { Box } from "components/layout";

import { StyledProps } from "../types";

export const StyledStatusTag = styled(Box)<StyledProps>`
  padding: 4px 14px;
  border-radius: 6px;
  text-align: center;
  background-color: ${({ theme, color }) => color && `${theme.colors[color]}`};
  max-width: fit-content;
  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 8px 14px;
  }
  ${space};
  ${layout};
`;
