import styled, { css } from "styled-components";
import { Loader } from "components";

export const StyledChartTooltip = styled.div`
  display: flex;
  border-radius: ${({ theme }) => theme.radii.large};
  background-color: ${({ theme }) => theme.colors.neutral800};
  flex-direction: column;
  align-items: center;
  padding: 8px 18px;
`;

export const StyledLoader = styled(Loader)`
  position: absolute;
  z-index: 3;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: unset;
  left: 50%;
`;

export const StyledWrapper = styled.div<{ isDataLoading: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  pointer-events: ${({ isDataLoading }) => (isDataLoading ? "none" : "auto")};

  ${({ isDataLoading }) =>
    isDataLoading &&
    css`
      filter: blur(2px);
    `};
`;
