import React, { PropsWithChildren } from "react";
import { Connector } from "@web3-react/types";
import { Web3ReactProvider, Web3ReactHooks } from "@web3-react/core";

import { useOrderedConnections } from "configs/web3";

const Web3Provider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const connections = useOrderedConnections();
  const connectors: [Connector, Web3ReactHooks][] = connections.map(({ connector, hooks }) => [connector, hooks]);

  return <Web3ReactProvider connectors={connectors}>{children}</Web3ReactProvider>;
};

export default Web3Provider;
