import React from "react";

import { InputGroup, Input, Button, Box } from "components";
import { Divider, AdditionalLoginMethods } from "pages/auth/components";
import { EyeClosedIcon, EyeIcon, RotateIcon } from "components/svg";

import { useTranslation } from "context";

import { useAppSelector } from "store/store";

import { tabsIndex } from "configs";
import { useHandleData, usePingOneSdk } from "pages/auth/login/hooks";

import { RegistrationFormProps } from "./types";
import { ButtonPrefixEnum } from "pages/auth/components/additional-login-methods/types";

const RegistrationForm: React.FC<RegistrationFormProps> = ({
  errors,
  touched,
  isValid,
  isPassword,
  activeTab,
  togglePasswordVisibleHandler,
  fieldProps,
  handleSubmit,
}) => {
  const pending = useAppSelector(state => state.auth.pending);

  const { t } = useTranslation();
  const { pingOneResponse, status } = usePingOneSdk();
  const { onAppleLoginHandler, onGoogleLoginHandler, redirectURI } = useHandleData();

  return (
    // Added height from larger business form(643px) + 2px
    <Box minHeight="645px">
      <form onSubmit={handleSubmit}>
        <InputGroup label={t("First Name")} error={errors.firstName} isTouched={touched.firstName}>
          <Input {...fieldProps("firstName")} placeholder={t("First Name")} />
        </InputGroup>

        <InputGroup label={t("Last Name")} error={errors.lastName} isTouched={touched.lastName}>
          <Input {...fieldProps("lastName")} placeholder={t("Last Name")} />
        </InputGroup>

        <InputGroup label={t("Email")} error={errors.email} isTouched={touched.email}>
          <Input {...fieldProps("email")} placeholder={t("Email")} />
        </InputGroup>

        <InputGroup
          label={t("Password")}
          error={errors.password}
          isTouched={touched.password}
          endIcon={
            <RotateIcon
              variant="rotateY"
              isToggled={isPassword}
              onClick={togglePasswordVisibleHandler}
              firstIcon={<EyeIcon width="24px" />}
              secondIcon={<EyeClosedIcon width="24px" />}
            />
          }
        >
          <Input {...fieldProps("password")} type={isPassword ? "password" : "text"} placeholder={t("Password")} />
        </InputGroup>

        <InputGroup
          label={t("Confirm Password")}
          error={errors.confirmPassword}
          isTouched={touched.confirmPassword}
          endIcon={
            <RotateIcon
              variant="rotateY"
              isToggled={isPassword}
              onClick={togglePasswordVisibleHandler}
              firstIcon={<EyeIcon width="24px" />}
              secondIcon={<EyeClosedIcon width="24px" />}
            />
          }
        >
          <Input
            {...fieldProps("confirmPassword")}
            type={isPassword ? "password" : "text"}
            placeholder={t("Confirm Password")}
          />
        </InputGroup>

        {activeTab === tabsIndex.auth.businessAccount && (
          <InputGroup label={t("Business Name")} error={errors.businessName} isTouched={touched.businessName}>
            <Input {...fieldProps("businessName")} placeholder={t("Business Name")} />
          </InputGroup>
        )}

        <Button disabled={!isValid} type="submit" isLoading={pending} color="primary" width="100%">
          {t("Register account")}
        </Button>
      </form>

      {activeTab === tabsIndex.auth.personalAccount && <Divider />}

      {activeTab === tabsIndex.auth.personalAccount && (
        <AdditionalLoginMethods
          pingOneResponse={pingOneResponse}
          status={status}
          textButtonPrefix={ButtonPrefixEnum.continue}
          onAppleLoginHandler={onAppleLoginHandler}
          onGoogleLoginHandler={onGoogleLoginHandler}
          redirectURI={redirectURI}
        />
      )}
    </Box>
  );
};

export default RegistrationForm;
