import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Components + styling
import { BottomMenuItems } from "./components";
import { Logo, Box, Flex } from "components";
import { StyledSideBar, StyledSideBarNavigateItem, StyledSideBarNavigateItemInnerText } from "./styled";
// Context
import { useTranslation } from "context";
//Types
import { SideBarProps } from "./types";
// Const
import { NAVIGATE_ITEM, DESKTOP_MENU_CLOSE_SIZE, DESKTOP_MENU_OPEN_SIZE } from "./constants";
import { scrollToTop } from "utils/helpers";

const SideBar: React.FC<SideBarProps> = ({ isDesktopMenuOpen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUrl = useLocation();

  const pathnameRoutes = currentUrl.pathname.split("/");

  const handleNavigate = (path: string) => {
    navigate(path);
    scrollToTop();
  };

  return (
    <StyledSideBar width={isDesktopMenuOpen ? DESKTOP_MENU_OPEN_SIZE : DESKTOP_MENU_CLOSE_SIZE}>
      <Box>
        <Logo size="small" isShowLogoText={isDesktopMenuOpen} />

        <Box my="40px">
          {NAVIGATE_ITEM.map(({ icon: Icon, title, path }, index) => {
            const isActive = pathnameRoutes.includes(path);

            return (
              <StyledSideBarNavigateItem
                key={index}
                isActive={isActive}
                justifyContent={isDesktopMenuOpen ? "flex-start" : "center"}
                onClick={() => handleNavigate(path)}
                cursor="pointer"
              >
                <Flex alignItems="center" width="100%">
                  <Flex justifyContent="flex-start">
                    <Icon color={isActive ? "primary" : "neutral500"} width="24px" height="24px" />
                  </Flex>

                  <StyledSideBarNavigateItemInnerText
                    color={isActive ? "primary" : "neutral500"}
                    $fontWeight="medium"
                    px="14px"
                    isDesktopMenuOpen={isDesktopMenuOpen}
                  >
                    {t(`${title}`)}
                  </StyledSideBarNavigateItemInnerText>
                </Flex>
              </StyledSideBarNavigateItem>
            );
          })}
        </Box>
      </Box>

      <BottomMenuItems isDesktopMenuOpen={isDesktopMenuOpen} />
    </StyledSideBar>
  );
};

export default SideBar;
