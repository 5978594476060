import imageCompression, { Options } from "browser-image-compression";

import { convertHeic, dataUrlToFile, getFileExtension } from "hooks/use-upload-image/utils";
import { BUFFER_LENGTH } from "hooks/use-upload-image";

const DEFAULT_FILE_NAME = "photo.jpeg";

export const compressImage = async (file: File | string, maxSizeMB: number) => {
  let currentFile: File;
  if (typeof file === "string") {
    // we pass all the files and photos through the compressor, so we need to convert all the data into a file format
    currentFile = await dataUrlToFile(file, DEFAULT_FILE_NAME);
  } else {
    currentFile = file;
  }

  const extensionFile = getFileExtension(currentFile.name);
  const fileToCompress = extensionFile === "heic" ? await convertHeic(currentFile) : currentFile;

  const options: Options = {
    maxSizeMB,
    useWebWorker: true,
    fileType: "image/jpeg",
    // maximum width of the resulting image, 3840 pixels corresponds to 4k resolution
    maxWidthOrHeight: 3840,
  };

  if (fileToCompress.size / BUFFER_LENGTH > maxSizeMB) {
    return await imageCompression(fileToCompress, options);
  } else {
    return fileToCompress;
  }
};
