import { useShowModals } from "./";
import { useShowMitekModal } from "hooks";

import { hideModal } from "store/modal";
import store, { useAppDispatch } from "store/store";
import { setIsShowError } from "store/verify-biometric";
import { sendCodesForWithdraw } from "store/wallets/cold-wallets/actions";

import { isErrorResult } from "services";

const useShow2FaModal = () => {
  const dispatch = useAppDispatch();

  const { showErrorModal, showLoadingModal } = useShowMitekModal();

  const { callNextModal, callSuccessModal } = useShowModals();

  // on click Verify with codes, we stop mitek SDK, and after it show MFA modal window
  const handleVerifyCallback = (stopMitekSDK: () => void, verifyType: "voice" | "face" = "face") => {
    const { withdrawPayload } = store.getState().coldWallets;

    if (withdrawPayload) {
      dispatch(setIsShowError(false));
      stopMitekSDK();
      showLoadingModal({ isShowTitle: false, verifyType });
      dispatch(sendCodesForWithdraw(withdrawPayload.withdrawPayload)).then(res => {
        if (!isErrorResult(res.payload)) {
          callNextModal();
        } else {
          dispatch(hideModal());
        }
      });
    }
  };

  return { handleVerifyCallback, callSuccessModal, showErrorModal, showLoadingModal };
};

export default useShow2FaModal;
