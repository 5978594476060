import { ModalNames } from "store/modal";
import {
  ActionModal,
  IdentityVerificationModal,
  ImageCropperModal,
  TestModal,
  ForgotPasswordModal,
  NewPasswordModal,
  BuyCryptoModal,
  SwapNetworkTokenModal,
  ColdStorageDepositModal,
  ColdStorageDepositSuccessModal,
  ColdStorageWithdrawModal,
  ColdStorageWithdrawSuccessModal,
  TwoFactorConfirmModal,
  SwapSuccessModal,
  WithdrawPayWithTokenModal,
  DepositChooseWalletModal,
  DepositChooseNetworkModal,
  DepositChooseTokenModal,
  WhitelistAddNewModal,
  WhitelistSideModal,
  WalletHistoryMobileFiltersModal,
  AddressesSideModal,
  WidgetBuyersListSideModal,
  DeliveryAddressesSideModal,
  SetupBiometricModal,
  AutomaticFaceCaptureModal,
  WidgetCreatedModal,
  EscrowCreatedModal,
  VoiceCaptureModal,
  EscrowDetailsModifySideModal,
  EscrowConfirmPaymentModal,
  AddYourYubikeyModal,
  YubikeyNameRegisterModal,
  YubikeysListModal,
  DeleteYubikeyModal,
  YubikeyVerificationModal,
  EscrowDashboardMobileFiltersModal,
  ReviewAssetsFromDeleteAccountModal,
  DeletionReasonModal,
  DeleteAccountModal,
  TwoFactorSetupModal,
  NotificationsSideModal,
} from "./components";

export const components = {
  [ModalNames.testModal]: () => <TestModal />,
  [ModalNames.imageCropperModal]: () => <ImageCropperModal />,
  [ModalNames.actionModal]: () => <ActionModal />,
  [ModalNames.identityVerification]: () => <IdentityVerificationModal />,
  [ModalNames.forgotPassword]: () => <ForgotPasswordModal />,
  [ModalNames.newPassword]: () => <NewPasswordModal />,
  [ModalNames.buyCryptoModal]: () => <BuyCryptoModal />,
  [ModalNames.swapNetworkTokenModal]: () => <SwapNetworkTokenModal />,
  [ModalNames.coldStorageDepositModal]: () => <ColdStorageDepositModal />,
  [ModalNames.coldStorageDepositSuccessModal]: () => <ColdStorageDepositSuccessModal />,
  [ModalNames.coldStorageWithdrawModal]: () => <ColdStorageWithdrawModal />,
  [ModalNames.coldStorageWithdrawSuccessModal]: () => <ColdStorageWithdrawSuccessModal />,
  [ModalNames.twoFactorConfirmModal]: () => <TwoFactorConfirmModal />,
  [ModalNames.swapSuccessModal]: () => <SwapSuccessModal />,
  [ModalNames.withdrawPayWithTokensModal]: () => <WithdrawPayWithTokenModal />,
  [ModalNames.depositChooseWalletModal]: () => <DepositChooseWalletModal />,
  [ModalNames.depositChooseNetworkModal]: () => <DepositChooseNetworkModal />,
  [ModalNames.depositChooseTokenModal]: () => <DepositChooseTokenModal />,
  [ModalNames.whitelistAddNewModal]: () => <WhitelistAddNewModal />,
  [ModalNames.whitelistSideModal]: () => <WhitelistSideModal />,
  [ModalNames.walletHistoryFiltersMobileModal]: () => <WalletHistoryMobileFiltersModal />,
  [ModalNames.addressesSideModal]: () => <AddressesSideModal />,
  [ModalNames.widgetBuyersListSideModal]: () => <WidgetBuyersListSideModal />,
  [ModalNames.deliveryAddressesSideModal]: () => <DeliveryAddressesSideModal />,
  [ModalNames.setupBiometricModal]: () => <SetupBiometricModal />,
  [ModalNames.automaticFaceCaptureModal]: () => <AutomaticFaceCaptureModal />,
  [ModalNames.voiceCaptureModal]: () => <VoiceCaptureModal />,
  [ModalNames.widgetCreatedModal]: () => <WidgetCreatedModal />,
  [ModalNames.escrowCreatedModal]: () => <EscrowCreatedModal />,
  [ModalNames.escrowDetailsModifySideModal]: () => <EscrowDetailsModifySideModal />,
  [ModalNames.escrowConfirmPaymentModal]: () => <EscrowConfirmPaymentModal />,
  [ModalNames.addYourYubikeyModal]: () => <AddYourYubikeyModal />,
  [ModalNames.yubikeyNameRegisterModal]: () => <YubikeyNameRegisterModal />,
  [ModalNames.yubikeysListModal]: () => <YubikeysListModal />,
  [ModalNames.deleteYubikeyModal]: () => <DeleteYubikeyModal />,
  [ModalNames.yubikeyVerificationModal]: () => <YubikeyVerificationModal />,
  [ModalNames.escrowDashboardMobileFilterModal]: () => <EscrowDashboardMobileFiltersModal />,
  [ModalNames.reviewAssetsFromDeleteAccountModal]: () => <ReviewAssetsFromDeleteAccountModal />,
  [ModalNames.deletionReasonModal]: () => <DeletionReasonModal />,
  [ModalNames.deleteAccountModal]: () => <DeleteAccountModal />,
  [ModalNames.twoFactorSetupModal]: () => <TwoFactorSetupModal />,
  [ModalNames.notificationsSideModal]: () => <NotificationsSideModal />,
};
