import React from "react";
import { WidgetProps } from "./types";

import { ROUTES } from "navigation/routes";

const URL = process.env.REACT_APP_URL;

const Widget: React.FC<WidgetProps> = ({ widgetId }) => {
  return (
    <a
      href={`${widgetId ? `${URL}/${ROUTES.escrows}/${ROUTES.escrowProcess}/${widgetId}?widgetId=${widgetId}` : null}`}
      target="_blank"
      rel="noreferrer"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        maxWidth: "232px",
        backgroundColor: "#3F7FFF",
        borderRadius: "120px",
        cursor: "pointer",
        color: "#FFFFFF",
        transition: "all 0.3s ease-in-out",
        height: "62px",
        backgroundImage: `url(${URL}/images/widget-logo.webp)`,
        backgroundPosition: "center",
        backgroundSize: "50%",
        backgroundRepeat: "no-repeat",
        padding: "16px",
      }}
    />
  );
};

export default Widget;
