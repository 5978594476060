import { createAsyncThunk } from "@reduxjs/toolkit";
import { ENDPOINTS_HOT_WALLET, isErrorResult, makeApiRequest } from "services";
import { ErrorResult } from "services/types";

import { formatAmount } from "configs/web3";

import { SuccessStatusResponse } from "../types";
import { WithdrawToHotWalletPayload } from "./types";

export const withdrawToHotWallet = createAsyncThunk<
  SuccessStatusResponse,
  WithdrawToHotWalletPayload,
  { rejectValue: ErrorResult }
>("wallets/withdraw-to-hot-wallet", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<SuccessStatusResponse>({
    method: "POST",
    url: ENDPOINTS_HOT_WALLET.hotWalletWithdraw,
    data: formatAmount(data),
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});
