import React from "react";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

import { useHandleData, useShowModal } from "./hooks";
import { useTranslation } from "context";
import { parseAndFormatText } from "utils/helpers";

import { BellIcon, NoNotificationIcon } from "components/svg";
import { StyledLink, Text, Flex, Loader, Column } from "components";
import CloseModalWrapper from "../close-modal-wrapper";
import {
  StyledNotificationItem,
  StyledNotificationItemInner,
  StyledNotificationSideModalWrapper,
  StyledNotificationText,
  StyledInfiniteScrollWrapper,
} from "./styled";

import { useAppSelector } from "store/store";
import { selectCombinedData } from "./selectors";
import { ELEMENTS_ID } from "configs";

const NotificationsSideModal: React.FC = () => {
  const { currentNotification, data, total, pending, unreadNotificationCount } = useAppSelector(selectCombinedData);
  const { t } = useTranslation();
  const { portal } = useShowModal();
  const { closeModalHandler, readAllHandler, loadMore, getCurrentNotification } = useHandleData();

  return (
    <StyledNotificationSideModalWrapper isActive={!!currentNotification}>
      <CloseModalWrapper closeModalHandler={closeModalHandler} mb="20px">
        <>
          <BellIcon width="24px" height="24px" color="neutral600" />

          <Text color="neutral600" textScale="body2" $fontWeight="medium" px="12px">
            {t("Notifications")}
          </Text>
        </>
      </CloseModalWrapper>

      <StyledLink
        color="neutral600"
        mb="18px"
        textScale="caption2"
        onClick={readAllHandler}
        width="100%"
        justifyContent="start"
        disabled={!unreadNotificationCount}
      >
        {t("Mark all as read")}
      </StyledLink>

      <StyledInfiniteScrollWrapper id={ELEMENTS_ID.notificationContainer}>
        <InfiniteScroll
          scrollableTarget={ELEMENTS_ID.notificationContainer}
          dataLength={data.length}
          hasMore={data.length < (total ?? 0)}
          next={loadMore}
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "visible",
          }}
          loader={
            <Flex width="100%" justifyContent="center" py="24px">
              <Loader height="44px" width="44px" />
            </Flex>
          }
          endMessage={
            data.length ? (
              <Text mx="auto" py="24px" textAlign="center" color="neutral600" textScale="body2">
                {t("No more notifications")}
              </Text>
            ) : null
          }
        >
          {data.length ? (
            <>
              {data.map((item, index) => {
                return (
                  <StyledNotificationItem key={index} onClick={() => getCurrentNotification(item)}>
                    <StyledNotificationItemInner>
                      <StyledNotificationText isNew={!item.isRead} color="neutral600" textScale="caption1" mb="8px">
                        {parseAndFormatText(item.text)}
                      </StyledNotificationText>

                      <Text textScale="caption2" color="neutral500">
                        {moment(item.createdAt).format("MM/DD/YY h:mm A")}
                      </Text>
                    </StyledNotificationItemInner>
                  </StyledNotificationItem>
                );
              })}
            </>
          ) : pending ? (
            <Flex width="100%" justifyContent="center" m="auto" height="calc(100vh - 117px)" alignItems="center">
              <Loader height="44px" width="44px" />
            </Flex>
          ) : (
            <Column mt="90px">
              <NoNotificationIcon width="72px" height="72px" mb="16px" />

              <Text textAlign="center" color="neutral600" textScale="body2">
                {t("No notifications yet")}
              </Text>
            </Column>
          )}
        </InfiniteScroll>
      </StyledInfiniteScrollWrapper>

      {!!currentNotification && portal()}
    </StyledNotificationSideModalWrapper>
  );
};

export default NotificationsSideModal;
