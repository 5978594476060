import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";
import { AddWhitelistModalProps } from "./types";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => state.profile.user,
    (state: AppState) => state.whitelist.pending,
    (state: AppState) => (state.modal.props as unknown as AddWhitelistModalProps).network,
    (state: AppState) => (state.modal.props as unknown as AddWhitelistModalProps).rootId,
  ],
  (user, pending, network, rootId) => ({
    user,
    pending,
    network,
    rootId,
  }),
);
