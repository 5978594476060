export const ELEMENTS_ID = {
  chatsListContainer: "chatsListContainer",
  messengerHeader: "messengerHeader",
  chatWindowContainer: "chatWindowContainer",
  messengerFooter: "messengerFooter",
  alertContainer: "alertContainer",
  mobileOutletTitle: "mobileOutletTitle",
  header: "header",
  webWidget: "bp-web-widget",
  sliderText: "slider-text",
  cameraContainer: "cameraContainer",
  notificationContainer: "notificationContainer",
};
