import React, { useState } from "react";

import { useTranslation } from "context";

import { Column, Text, Button } from "components";
import { ModalSwapSuccessIcon } from "components/svg";
import { FromToInfo, PriceFeeInfo } from "./components";
import { StyledModalWrapper } from "../styled";
import CloseModalWrapper from "../close-modal-wrapper";

import { useAppSelector } from "store/store";

import { SwapSuccessProps } from "./types";

const SwapSuccessModal: React.FC = () => {
  const closeModalHandler = useAppSelector(
    state => (state.modal.props as unknown as SwapSuccessProps).closeModalHandler,
  );
  const swapData = useAppSelector(state => state.swap.swapData);

  const { t } = useTranslation();
  // When updating offers, swapData is overwritten and the necessary data is lost,
  // for this reason, on the mount of the modal window, writes data to the local state
  const [swapDataState] = useState(swapData);
  const { fromAmount, toAmount, payload, networkFee } = swapDataState!;

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} mb="18px" />

      <Column width="100%" alignItems="center">
        <ModalSwapSuccessIcon width="72px" height="72px" />

        <Text mt="16px" textScale="body2" color="neutral600">
          {t("Swap Successful")}
        </Text>

        <FromToInfo fromAmount={fromAmount} toAmount={toAmount} />

        <PriceFeeInfo
          fromToken={payload.fromCurrencyCode}
          toToken={payload.toCurrencyCode}
          networkFee={networkFee}
          fromTokenPriceUSD={payload.fromTokenPriceUSD}
          toTokenPriceUSD={payload.toTokenPriceUSD}
        />
        <Button mt="32px" mb={{ _: "20px", tablet: "30px" }} height="44px" width="100%" onClick={closeModalHandler}>
          {t("Done")}
        </Button>
      </Column>
    </StyledModalWrapper>
  );
};

export default SwapSuccessModal;
