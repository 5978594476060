import { createAsyncThunk } from "@reduxjs/toolkit";
import { isErrorResult, makeApiRequest, ENDPOINTS_WALLET_FEE } from "services";
import { ErrorResult } from "services/types";
import { formatAmount } from "configs/web3";

import { WithdrawToHotWalletPayload } from "../types";
import {
  WithdrawFeePayload,
  WithdrawFeeResponse,
  HotWalletWithdrawFeeResponse,
  FiatFeeColdStorageResponse,
  FiatFeeColdStoragePayload,
} from "./types";

export const estimateColdWalletWithdrawFee = createAsyncThunk<
  WithdrawFeeResponse,
  WithdrawFeePayload,
  { rejectValue: ErrorResult }
>("wallets-fee/estimate-coldWallet-withdraw-fee", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<WithdrawFeeResponse>({
    method: "POST",
    url: ENDPOINTS_WALLET_FEE.coldWalletWithdrawFee,
    data: formatAmount(data),
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const estimateHotWalletWithdrawFee = createAsyncThunk<
  HotWalletWithdrawFeeResponse,
  WithdrawToHotWalletPayload,
  { rejectValue: ErrorResult }
>("wallets-fee/estimate-hot-wallet-withdraw-fee", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<HotWalletWithdrawFeeResponse>({
    method: "POST",
    url: ENDPOINTS_WALLET_FEE.hotWalletWithdrawFee,
    data: formatAmount(data),
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const estimateFiatFeeColdStorage = createAsyncThunk<
  FiatFeeColdStorageResponse,
  FiatFeeColdStoragePayload,
  { rejectValue: ErrorResult }
>("wallets-fee/estimate-fiat-fee-cold-storage", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<FiatFeeColdStorageResponse>({
    method: "POST",
    url: ENDPOINTS_WALLET_FEE.estimateFiatFeeColdStorage,
    data,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});
