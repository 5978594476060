import { ErrorInErrorsArrayFromBE } from "services/types";
import { TruliooResponseByType } from "store/kyc/types";

export const parseErrorFromBE = (item: ErrorInErrorsArrayFromBE[]) => {
  const errorsArr: string[] = [];

  const parseError = (item: ErrorInErrorsArrayFromBE[]) => {
    if (item && Object.keys(item[0].constraints).length) {
      const errorMessage = `${item[0].property}: ${item[0].constraints[Object.keys(item[0].constraints)[0]]}`;
      errorsArr.push(errorMessage);
    }

    if (item && item[0].children.length) {
      parseError(item[0].children);
    }
  };

  parseError(item);

  return errorsArr.join(", ");
};

export const parseErrorFromTrulioo = (response: TruliooResponseByType) => {
  const errorsArr: string[] = [];

  if (response.status === "error") {
    const errorObj = response.data.ModelState;

    if (Object.keys(errorObj).length) {
      const entries = Object.entries(errorObj);
      entries.forEach(([key, value]) => {
        const errorMessage = `${key}: ${value.join(" ")}`;
        errorsArr.push(errorMessage);
      });
    }
  }
  return errorsArr.join(", ");
};
