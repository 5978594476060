import React from "react";

import { useHandlePayload } from "../../hooks";

import { TableWithPagination } from "components";
import { TableRowDesktop, TableRowMobile } from "./components";

import { HEADERS } from "./constants";

import { WhitelistResponseSingleItem } from "store/wallets/types";

const WhitelistModalTable: React.FC = () => {
  return (
    <TableWithPagination<WhitelistResponseSingleItem>
      useHandlePayload={useHandlePayload}
      TableRowDesktop={TableRowDesktop}
      headers={HEADERS}
      TableRowMobile={TableRowMobile}
      isHiddenQuantity
      isSideModal
    />
  );
};

export default WhitelistModalTable;
