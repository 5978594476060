import React, { PropsWithChildren } from "react";

import { useTranslation } from "context";
import { Text, Link } from "components";

import { getScanLink, truncateHash } from "configs/web3";

import { ToastDescriptionWithTxProps } from "./types";

const ToastDescriptionWithTx: React.FC<PropsWithChildren<ToastDescriptionWithTxProps>> = ({
  txHash,
  children,
  chainId,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {typeof children === "string" ? (
        <Text color="neutral600" textScale="caption1">
          {children}
        </Text>
      ) : (
        children
      )}
      {txHash && chainId && (
        <Link external href={getScanLink(txHash, "transaction", chainId)}>
          {t("View on scan")}: {truncateHash(txHash, 8, 0)}
        </Link>
      )}
    </>
  );
};

export default ToastDescriptionWithTx;
