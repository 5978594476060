import styled, { css } from "styled-components";
import { Text } from "components";
import { StyledSideModalWrapper } from "../styled";
import { NOTIFICATION_ITEM_HEIGHT } from "./constants";

export const StyledNotificationItem = styled.div`
  display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
  min-height: ${NOTIFICATION_ITEM_HEIGHT}px;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral300};
  }
`;

export const StyledNotificationItemInner = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%;
  position: relative;

  &:hover {
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      width: 110%;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.neutral100};
      border-radius: ${({ theme }) => theme.radii.semiMedium};
      left: -5%;
      z-index: -1;
      top: 0;
    }
  }
`;
export const StyledNotificationSideModalWrapper = styled(StyledSideModalWrapper)<{ isActive: boolean }>`
  ${({ isActive }) =>
    isActive &&
    css`
      &:after {
        content: "";
        position: absolute;
        background-color: ${({ theme }) => theme.colors.neutral1000 + "66"};
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
      }
    `}
`;

export const StyledNotificationText = styled(Text)<{ isNew: boolean }>`
  // trim text after second line
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${({ isNew }) =>
    isNew &&
    css`
      &:before {
        content: "";
        position: absolute;
        height: 8px;
        width: 8px;
        background-color: ${({ theme }) => theme.colors.primary};
        border-radius: ${({ theme }) => theme.radii.circle};
        left: -16px;
        top: 18px;
      }
    `}
`;

export const StyledInfiniteScrollWrapper = styled.div`
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  height: calc(100vh - 116px);
  padding-left: 36px;
  padding-right: 36px;
  width: 100%;
  left: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral300};
`;

export const StyledPortalWrapper = styled.div`
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  cursor: pointer;
  position: fixed;
  display: grid;
`;
