import { FlexGap } from "components";

import { MobileGridProps } from "./types";

export const MobileGrid = <T,>({ data, TableRow, tableGap }: MobileGridProps<T>): JSX.Element => {
  return (
    <FlexGap
      flexWrap="wrap"
      width="100%"
      gap={typeof tableGap === "object" ? { _: "1px", ...tableGap } : { _: "1px", tablet: tableGap }}
    >
      {data.map(item => (
        <TableRow
          item={item}
          key={
            Object.hasOwn(item as object, "body")
              ? (
                  item as {
                    body: {
                      id: number | string;
                    };
                  }
                ).body.id
              : (item as { id: number | string }).id
          }
        />
      ))}
    </FlexGap>
  );
};
