import React from "react";

import { useTranslation } from "context";

import { Box, Button, Column, StyledLink, Text } from "components";

import { VerificationProps } from "../types";

const VerificationFailed: React.FC<VerificationProps> = ({ verificationCallback, onSwitchMethodCallback }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Box mb={{ _: "18px", tablet: "32px" }}>
        <Text textScale="body3" color="neutral600" $fontWeight={{ _: "regular", tablet: "medium" }}>
          {t("Verification failed")}
        </Text>

        <Text textScale="caption1" color="neutral600" pt="14px">
          {t(
            "An error occurred during the verification. Please initiate the process again using your authenticator to proceed further.",
          )}
        </Text>
      </Box>

      <Column width="100%" alignItems={{ _: "center", tablet: "flex-start" }}>
        <Button mb={{ _: "18px", tablet: "24px" }} width="100%" onClick={verificationCallback}>
          {t("Try again")}
        </Button>

        <Box cursor="pointer" width="fit-content" onClick={onSwitchMethodCallback}>
          <StyledLink color="blue" textScale="button" $fontWeight="medium">
            {t("Switch to another Verification method")}
          </StyledLink>
        </Box>
      </Column>
    </div>
  );
};

export default VerificationFailed;
