import { createAsyncThunk } from "@reduxjs/toolkit";

import { isErrorResult, makeApiRequest } from "services";
import { ENDPOINTS_PROFILE } from "services";

import { ErrorResult } from "services/types";
import {
  ProfileResponse,
  Secret2FAResponse,
  Verify2FARequest,
  UploadAvatarRequest,
  ResetTwoFaPayload,
  UserAssetsResponse,
  HighRiskResetTwoFaPayload,
  SoftDeletePayload,
} from "./types";
import { SuccessResponse } from "store/wallets/types";

export const getMyProfile = createAsyncThunk<ProfileResponse, undefined, { rejectValue: ErrorResult }>(
  "profile/get-my-profile",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<ProfileResponse>({
      method: "GET",
      url: ENDPOINTS_PROFILE.getMyProfile,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const generate2FASecret = createAsyncThunk<Secret2FAResponse, undefined, { rejectValue: ErrorResult }>(
  "2fa/generate-secret",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<Secret2FAResponse>({
      method: "POST",
      url: ENDPOINTS_PROFILE.generateSecret,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const resetAuthenticatorApp = createAsyncThunk<SuccessResponse, undefined, { rejectValue: ErrorResult }>(
  "2fa/reset-authenticator-app",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<SuccessResponse>({
      method: "POST",
      url: ENDPOINTS_PROFILE.resetTwoFa,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const highRiskResetAuthenticatorApp = createAsyncThunk<SuccessResponse, undefined, { rejectValue: ErrorResult }>(
  "2fa/high-risk-reset-authenticator-app",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<SuccessResponse>({
      method: "POST",
      url: ENDPOINTS_PROFILE.highRiskResetTwoFa,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const confirmResetAuthenticatorApp = createAsyncThunk<
  SuccessResponse,
  ResetTwoFaPayload,
  { rejectValue: ErrorResult }
>("2fa/confirm-reset-authenticator-app", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<SuccessResponse>({
    method: "POST",
    url: ENDPOINTS_PROFILE.confirmResetTwoFa,
    data,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const confirmHighRiskResetAuthenticatorApp = createAsyncThunk<
  SuccessResponse,
  HighRiskResetTwoFaPayload,
  { rejectValue: ErrorResult }
>("2fa/confirm-high-risk-reset-authenticator-app", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<SuccessResponse>({
    method: "POST",
    url: ENDPOINTS_PROFILE.confirmHighRiskResetTwoFa,
    data,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const verify2FASecret = createAsyncThunk<ProfileResponse, Verify2FARequest, { rejectValue: ErrorResult }>(
  "2fa/verify-secret",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<ProfileResponse>({
      method: "POST",
      url: ENDPOINTS_PROFILE.verifySecret,
      data,
      isShowError: false,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const changeAvatar = createAsyncThunk<ProfileResponse, UploadAvatarRequest, { rejectValue: ErrorResult }>(
  "user/change-avatar",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<ProfileResponse>({
      method: "POST",
      url: ENDPOINTS_PROFILE.changeAvatar,
      data,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const deleteAvatar = createAsyncThunk<ProfileResponse, undefined, { rejectValue: ErrorResult }>(
  "user/delete-avatar",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<ProfileResponse>({
      method: "DELETE",
      url: ENDPOINTS_PROFILE.changeAvatar,
      data,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const softDeleteValidation = createAsyncThunk<UserAssetsResponse, undefined, { rejectValue: ErrorResult }>(
  "user/soft-delete-validation",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<UserAssetsResponse>({
      method: "GET",
      url: ENDPOINTS_PROFILE.softDeleteValidation,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const softDeleteCheck = createAsyncThunk<SuccessResponse, undefined, { rejectValue: ErrorResult }>(
  "user/soft-delete-check",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<SuccessResponse>({
      method: "POST",
      url: ENDPOINTS_PROFILE.softDeleteCheck,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const softDeleteConfirm = createAsyncThunk<SuccessResponse, SoftDeletePayload, { rejectValue: ErrorResult }>(
  "user/soft-delete-confirm",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<SuccessResponse>({
      method: "POST",
      url: ENDPOINTS_PROFILE.softDeleteConfirm,
      data: data,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);
