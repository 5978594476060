import { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import * as yup from "yup";

import { useTranslation } from "context";

import { FieldsConfig } from "../types";

export type InitialValues = {
  [key: string]: string | InitialValues;
};

const useValidationSchema = (fieldsList: FieldsConfig) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState<InitialValues>({});
  // Effect prevents a bug when initialValues is persisting during account change
  // In that case fields from personal appears at business form and vise versa
  useEffect(() => {
    if (!isEmpty(fieldsList)) {
      setInitialValues(createInitialValues(fieldsList));
    } else {
      setInitialValues({});
    }
  }, [fieldsList]);

  const createInitialValues = (obj: FieldsConfig) => {
    const result: InitialValues = {};

    Object.entries(obj).forEach(([key, value]) => {
      if (value && typeof value === "object" && "name" in value) {
        if (key === "Type" && typeof value.value === "string") {
          result[key] = value.value;
        } else {
          result[key] = "";
        }
      } else {
        result[key] = createInitialValues(value);
      }
    });

    return result;
  };

  const createValidationSchema = (obj: FieldsConfig) => {
    const schema: {
      [key: string]:
        | yup.StringSchema<string | undefined, yup.AnyObject, undefined, "">
        | yup.ObjectSchema<{}, yup.AnyObject, {}, "">;
    } = {};

    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === "object" && !("name" in value) && !Array.isArray(value)) {
        schema[key] = createValidationSchema(value);
      } else {
        schema[key] = value.required ? yup.string().required(t("This field is required")) : yup.string();
      }
    }

    return yup.object().shape(schema);
  };

  const validationSchema = createValidationSchema(fieldsList);

  return { validationSchema, initialValues };
};

export default useValidationSchema;
