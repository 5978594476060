import styled from "styled-components";
import { FlexGap } from "components/layout";

export const StyledFiltersTag = styled(FlexGap)`
  padding: 6px 14px;
  border: 1px solid ${({ theme }) => theme.colors.neutral300};
  border-radius: 40px;
  background-color: ${({ theme }) => theme.colors.neutral0};
  gap: 10px;
  max-width: fit-content;
`;
