import { UseScriptStatus } from "hooks/use-script";
import { LoginApplePayload } from "store/auth/types";

export type OptionProps = {
  access_type: string;
  include_granted_scopes: string;
  scope: string;
  response_type: string;
  client_id: string;
  redirect_uri: string;
};

export type AppleSigninRenderProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export type AdditionalLoginMethodsProps = {
  textButtonPrefix: ButtonPrefixEnum;
  pingOneResponse: string;
  status: UseScriptStatus;
  onAppleLoginHandler: (res: LoginApplePayload) => void;
  onGoogleLoginHandler: (params: { code: string; redirectUrl: string; pingOneResponse: string }) => void;
  redirectURI: string;
};

export enum ButtonPrefixEnum {
  continue = "Continue with",
  logIn = "Log In with",
}
