import { ChainId, Currency, SUPPORTED_CHAINS } from "configs/web3";
import { TokenAddressMap } from "store/tokens/types";

import { getNumOrStrFromStringChainId } from "./get-num-or-str-from-string-chainId";

export const getUniqueTokenListByKey = (allTokensList: TokenAddressMap | null, network?: ChainId) => {
  let filteredTokens: {
    [x: string]: Currency;
  }[] = [];

  if (network && allTokensList) {
    const allTokensWithNetwork: [string, Currency][] = Object.entries(allTokensList[network]);

    filteredTokens = allTokensWithNetwork.map(([key, value]) => {
      return { [key]: value };
    });
  } else {
    const allTokens: [string, { [key: string]: Currency }][] = allTokensList ? Object.entries(allTokensList) : [];

    const filteredNetworks = allTokens.filter(([key]) => {
      const chainId = getNumOrStrFromStringChainId(key);
      return SUPPORTED_CHAINS.includes(chainId);
    });

    const checkingObj: { [key: string]: boolean } = {};
    filteredNetworks.forEach(([_, item]) => {
      for (const key in item) {
        if (!checkingObj[key]) {
          checkingObj[key] = true;
          filteredTokens = [...filteredTokens, { [key]: item[key] }];
        }
      }
    });
  }

  return filteredTokens;
};
