import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral600", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        d="M12.0001 19.0014C11.1581 19.0014 10.3151 18.8234 9.49609 18.5064"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.882 12.4688C18.99 15.9678 15.495 19.0008 12 19.0008"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0791 8.92188C19.7701 9.73088 20.3841 10.6129 20.8821 11.5339C21.0391 11.8249 21.0391 12.1779 20.8821 12.4689"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 19L19 5"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.77309 14.2252C8.54309 12.9952 8.54309 11.0002 9.77309 9.77016C11.0031 8.54016 12.9981 8.54016 14.2281 9.77016"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0437 6.956C15.4967 5.759 13.7478 5 11.9998 5C8.50475 5 5.00975 8.033 3.11775 11.533C2.96075 11.824 2.96075 12.177 3.11775 12.468C4.06375 14.217 5.40975 15.849 6.95575 17.045"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
