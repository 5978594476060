import React from "react";
import { Text, Flex, Box } from "components";
import {
  StyledCustomRadioButton,
  StyledDefaultRadioButton,
  StyledRadioButtonContainer,
  StyledRadioButtonLabel,
} from "./styled";
import { RadioButtonProps } from "./types";

const RadioButton: React.FC<RadioButtonProps> = ({ onChange, value, label, checked, name, disabled, ...props }) => {
  return (
    <Flex {...props}>
      <StyledRadioButtonLabel htmlFor={name} disabled={disabled}>
        <StyledRadioButtonContainer disabled={disabled} checked={checked}>
          <StyledDefaultRadioButton
            id={name}
            type="radio"
            onChange={onChange}
            name={name}
            value={value}
            checked={checked}
            disabled={disabled}
          />
          <StyledCustomRadioButton />
        </StyledRadioButtonContainer>

        {React.isValidElement(label) ? (
          <Box px="20px">{label}</Box>
        ) : (
          <Text width="max-content" color="neutral600" textScale="body3" px="20px">
            {label}
          </Text>
        )}
      </StyledRadioButtonLabel>
    </Flex>
  );
};

RadioButton.defaultProps = {
  onChange: () => {},
  value: "",
  label: "",
  checked: false,
};

export default RadioButton;
