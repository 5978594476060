import React from "react";
import { Text } from "components";
import { StyledErrorAndWarningMessage } from "./styled";
import { ErrorAndWarningMessageProps, variant as themeVariant } from "./types";
import { useThemeContext } from "context";

const WalletErrorMessage: React.FC<ErrorAndWarningMessageProps> = ({
  message,
  variant = "error",
  textColor,
  bgColor,
  ...props
}) => {
  const { theme } = useThemeContext();
  const defaultTextColor = theme.isDark ? "yellow" : themeVariant[variant];

  return (
    <StyledErrorAndWarningMessage {...props} bgColor={bgColor ? bgColor : `${themeVariant[variant]}Light`}>
      {typeof message === "string" ? (
        <Text textScale="caption1" color={textColor ? textColor : defaultTextColor}>
          {message}
        </Text>
      ) : (
        React.isValidElement(message) && message
      )}
    </StyledErrorAndWarningMessage>
  );
};

export default WalletErrorMessage;
