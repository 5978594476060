import React from "react";
import { useParams } from "react-router-dom";

import { Breadcrumbs, Link, Flex, Image, Text, Box } from "components";

import { useTranslation } from "context";

import { useTooltip, useTokenBE } from "hooks";

import { ROUTES } from "navigation/routes";

import { SHORT_CHAIN_NAMES, NATIVE_CURRENCIES_LOGO } from "configs/web3";

const WalletBreadCrumbs: React.FC = () => {
  const { network, token } = useParams();

  const { t } = useTranslation();
  const { token: breadCrumbsToken } = useTokenBE(network, token);

  const { targetRef: targetRefNetwork, tooltip: networkTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });
  const { targetRef: targetRefToken, tooltip: tokenTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  return (
    <>
      {network && (
        <Breadcrumbs mb="18px">
          <Link mx="2px" textScale="body3" color="neutral600" href={`/${ROUTES.wallet}`}>
            {t("Wallets")}
          </Link>

          {network && (
            <Flex>
              <Box minWidth="24px" minHeight="24px">
                <Image mx="2px" width="24px" aspectRatio={1} src={NATIVE_CURRENCIES_LOGO[network]} alt="network" />
              </Box>

              <Text mx="2px" textScale="body3" color="neutral600" ellipsis ref={targetRefNetwork}>
                {SHORT_CHAIN_NAMES[network]}
              </Text>
              {networkTooltip}
            </Flex>
          )}

          {token && (
            <Flex>
              <Box minWidth="24px" minHeight="24px">
                <Image mx="2px" width="24px" aspectRatio={1} src={breadCrumbsToken?.logoURI ?? ""} alt="network" />
              </Box>

              <Text mx="2px" textScale="body3" color="neutral600" ellipsis ref={targetRefToken}>
                {breadCrumbsToken?.symbol}
              </Text>
              {tokenTooltip}
            </Flex>
          )}
        </Breadcrumbs>
      )}
    </>
  );
};

export default WalletBreadCrumbs;
