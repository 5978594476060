import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";
import { DepositChooseTokenModalProps } from "./types";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => (state.modal.props as unknown as DepositChooseTokenModalProps).network,
    (state: AppState) => (state.modal.props as unknown as DepositChooseTokenModalProps).tokenKey,
    (state: AppState) => (state.modal.props as unknown as DepositChooseTokenModalProps).onClick,
    (state: AppState) => state.hotWallets.networks,
    (state: AppState) => state.hotWallets.wallets.pending,
  ],
  (network, tokenKey, onClick, networks, pending) => ({
    network,
    tokenKey,
    onClick,
    networks,
    pending,
  }),
);
