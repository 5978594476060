import { useTranslation } from "context";

import { useShow2FaModal } from "../";
import { useHandleVoiceBiometric } from "./";

import { hideModal, showModal } from "store/modal";
import store, { useAppDispatch } from "store/store";
import { setIsShowError } from "store/verify-biometric";

import { convertToMaybeBase64 } from "utils/helpers";

import { ResponseData } from "types";
import { ModalProps } from "store/modal/types";
import { AutomaticFaceCaptureModalProps } from "components/modal/components/automatic-face-capture-modal/types";
import { MitekSDK } from "hooks/use-get-mitek-sdk/types";

const useHandleFaceBiometricData = (stopSDK: () => void, mitekScienceSDK?: MitekSDK) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { handleVerifyCallback, showErrorModal, showLoadingModal } = useShow2FaModal();

  const { handleVerify } = useHandleVoiceBiometric(mitekScienceSDK);

  const checkImageResult = ({ response }: ResponseData) => {
    const isShowErrorModal = store.getState().verifyBiometric.isShowErrorModal;

    if (isShowErrorModal) {
      if (response.status === "success") {
        stopSDK();
        showLoadingModal();
        const imageData = convertToMaybeBase64(response.imageData);
        // When the image is verified correctly, we stop the image verification SDK and initiate the voice verification SDK. We have added a timeout to ensure that the voice verification SDK starts only after the image verification SDK has been stopped.
        setTimeout(() => {
          handleVerify({ selfieImage: { data: imageData } });
        }, 1000);
      } else {
        handleErrorModal(response.warnings.key);
      }
    }
  };

  const handleCloseVerifyModal = () => {
    dispatch(setIsShowError(false));
    stopSDK();
    dispatch(hideModal());
  };

  const handleErrorModal = (key?: string) => {
    showErrorModal(key, { callback: () => handleVerifyCallback(stopSDK), title: t("Verify with codes") });
  };

  const showVerifyCaptureModal = () => {
    const _showModal = showModal as ModalProps<AutomaticFaceCaptureModalProps>;
    dispatch(
      _showModal({
        modalName: "automaticFaceCaptureModal",
        modalType: "fullScreenOnMobile",
        rootId: "cold-storage",
        clickOutsideHandler: handleCloseVerifyModal,
        props: {
          isCameraLoaded: false,
          isShowHintMessage: false,
          title: t("Verify with face biometric"),
          verifyDescription: t("Have troubles with the verification?"),
          verifyCallback: () => handleVerifyCallback(stopSDK),
          closeCallback: handleCloseVerifyModal,
        },
      }),
    );
  };

  return {
    handleErrorModal,
    showVerifyCaptureModal,
    checkImageResult,
  };
};

export default useHandleFaceBiometricData;
