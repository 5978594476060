import React from "react";

import { getAddress } from "configs/web3";

import { Text } from "components";
import { AddressPopUp } from "./components";
import { StyledAddressCard, StyledAddressCardHeader, StyledAddressText } from "./styled";

import { AddressCardProps } from "./types";

const AddressCard: React.FC<AddressCardProps> = ({
  address,
  onClick,
  rootId,
  currentAddress,
  showPopUp = false,
  ...props
}) => {
  return (
    <StyledAddressCard
      isDefault={currentAddress ? currentAddress.id === address.id : address.isDefault}
      onClick={onClick}
      {...props}
    >
      <StyledAddressCardHeader>
        <Text textScale="caption1" color="neutral800" $fontWeight="medium" ellipsis>
          {address.address}
        </Text>
        {showPopUp && <AddressPopUp address={address} rootId={rootId} />}
      </StyledAddressCardHeader>
      <StyledAddressText textScale="body2" color="neutral800" mt="24px">
        {getAddress(address)}
      </StyledAddressText>
    </StyledAddressCard>
  );
};

export default AddressCard;
