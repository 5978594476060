import { Loader } from "components";
import { lazyLoad } from "utils/loadable";

export const BuyCryptoTable = lazyLoad(
  () => import("./buy-crypto-table"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const HistoryTable = lazyLoad(
  () => import("./history-table"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);
