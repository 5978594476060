import * as Sentry from "@sentry/react";

type ErrorData = {
  code: number | string;
  message: string;
};

export type TxError = {
  data: ErrorData;
  error: string;
};

export const isGasEstimationError = (error: TxError | Error | string) => {
  if ((error as Error).message.includes("insufficient funds")) {
    return true;
  } else {
    return false;
  }
};

export const isUserRejected = (error: ErrorData) => {
  return typeof error === "object" && "code" in error && (error.code === 4001 || error.code === "ACTION_REJECTED");
};

export const logError = (error: Error | unknown, description: string) => {
  Sentry.captureException(error, { tags: { description } });

  console.error(description, error);
};
