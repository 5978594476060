import { useState } from "react";

import { isErrorResult } from "services";

import { loginUser, loginUserApple, loginUserGoogle } from "store/auth/actions";
import { useAppDispatch } from "store/store";

import { useCheckRisk } from "./use-check-risk";
import { usePingOneSdk } from "./use-ping-one-sdk";

import { ROUTES } from "navigation/routes";

import { InitialValues } from "./types";
import { LoginApplePayload } from "store/auth/types";

const URL = process.env.REACT_APP_URL;
const redirectURI = `${URL}/${ROUTES.login}`;

export const useHandleData = () => {
  const [isPassword, setIsPassword] = useState(true);

  const dispatch = useAppDispatch();
  const { checkRiskLevel } = useCheckRisk();
  const { pingOneResponse, status } = usePingOneSdk();

  const handleLogin = async (_values: InitialValues) => {
    const { email, password } = _values;

    dispatch(loginUser({ email: email.toLowerCase(), password, pingOneResponse })).then(res => {
      if (!isErrorResult(res.payload)) {
        const {
          user: { riskLevel, twoFaStatus },
        } = res.payload!;
        checkRiskLevel(riskLevel, twoFaStatus);
      }
    });
  };

  const onGoogleLoginHandler = (params: { code: string; pingOneResponse: string }) => {
    dispatch(loginUserGoogle(params)).then(res => {
      if (!isErrorResult(res.payload)) {
        const {
          user: { riskLevel, twoFaStatus },
        } = res.payload!;
        checkRiskLevel(riskLevel, twoFaStatus);
      }
    });
  };

  const onAppleLoginHandler = (res: LoginApplePayload) => {
    dispatch(loginUserApple(res)).then(res => {
      if (!isErrorResult(res.payload)) {
        const {
          user: { riskLevel, twoFaStatus },
        } = res.payload!;
        checkRiskLevel(riskLevel, twoFaStatus);
      }
    });
  };

  const togglePasswordVisibleHandler = () => {
    setIsPassword(!isPassword);
  };

  return {
    isPassword,
    pingOneResponse,
    status,
    handleLogin,
    togglePasswordVisibleHandler,
    onGoogleLoginHandler,
    onAppleLoginHandler,
    redirectURI,
  };
};
