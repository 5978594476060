import { Loader } from "components";
import { lazyLoad } from "utils/loadable";

export const ProfileTab = lazyLoad(
  () => import("./profile"),
  module => module.default,
  { fallback: <Loader width="100%" height="100%" /> },
);

export const SystemTab = lazyLoad(
  () => import("./system"),
  module => module.default,
  { fallback: <Loader width="100%" height="100%" /> },
);

export const SecurityTab = lazyLoad(
  () => import("./security"),
  module => module.default,
  { fallback: <Loader width="100%" height="100%" /> },
);
