import { useEffect, useState } from "react";
import { FormikValues } from "formik";

import { useAppSelector } from "store/store";

import { REGEX } from "configs";

import { FieldsConfig } from "../types";
import { KYCFieldsResponse, KYCFieldsResponseItem } from "store/kyc/types";

import { convertToMaybeBase64 } from "utils/helpers";

export const useKYCFieldsFormat = () => {
  const fields = useAppSelector(state => state.kyc.fields);

  const [fieldsList, setFieldsList] = useState<FieldsConfig>({});

  useEffect(() => {
    const sortedList = sortObjectKeysReverseAlphabetically(createConfig(fields));
    setFieldsList(sortedList);
  }, [fields]);

  function sortObjectKeysReverseAlphabetically(obj: FieldsConfig) {
    const sortedKeys = Object.keys(obj).sort((a, b) => b.localeCompare(a));
    const sortedObj: FieldsConfig = {};

    sortedKeys.forEach((key, index) => {
      sortedObj[sortedKeys[index]] = obj[sortedKeys[index]];
    });

    return sortedObj;
  }

  const trimImageData = (formValues: FormikValues) => {
    const newObj: FormikValues = {};

    for (const key in formValues) {
      if (typeof formValues[key] === "object") {
        newObj[key] = trimImageData(formValues[key]);
      } else {
        const maybeBase64 = convertToMaybeBase64(formValues[key]);

        if (REGEX.base64.test(maybeBase64)) {
          newObj[key] = maybeBase64;
        } else {
          newObj[key] = formValues[key];
        }
      }
    }
    return newObj;
  };

  const fieldsToFormConfig = (obj: KYCFieldsResponseItem, cumulativeObj: FieldsConfig) => {
    if ("properties" in obj && "name" in obj) {
      cumulativeObj[obj.name] = {};
      obj.properties!.forEach((item: KYCFieldsResponseItem) => {
        fieldsToFormConfig(item, cumulativeObj[obj.name] as FieldsConfig);
      });
    } else if (!("properties" in obj) && "name" in obj) {
      cumulativeObj[obj.name] = { ...obj };
    }
  };

  const createConfig = (fields: KYCFieldsResponse) => {
    const fieldsKeys = Object.entries(fields);
    const res: FieldsConfig = {};

    fieldsKeys.forEach(([key, val]) => {
      if (val) {
        res[key] = {};
        fieldsToFormConfig(val, res[key] as FieldsConfig);
      }
    });

    return res;
  };

  const configuresNationalIds = (formValues: FormikValues) => {
    const newObj: FormikValues = {};
    for (const key in formValues) {
      if (typeof formValues[key] === "object" && key !== "NationalIds") {
        newObj[key] = configuresNationalIds(formValues[key]);
      } else if (typeof formValues[key] === "object" && key === "NationalIds") {
        newObj[key] = Object.values(formValues[key]);
      } else {
        newObj[key] = formValues[key];
      }
    }
    return newObj;
  };

  const configuresData = (formValues: FormikValues) => {
    const formValuesWithTrimmedImageData = trimImageData(formValues);
    return configuresNationalIds(formValuesWithTrimmedImageData);
  };

  return {
    fieldsList,
    configuresData,
  };
};
