import { createAsyncThunk } from "@reduxjs/toolkit";

import { isErrorResult, makeApiRequest, ENDPOINTS_PROFILE } from "services";

import { ErrorResult } from "services/types";
import {
  EnrollVerifyDataPayload,
  EnrollVerifyDataResponse,
  MitekStatusResponse,
  VoiceQualityPayload,
  VoiceQualityResponse,
} from "./types";
import { SuccessResponse } from "store/wallets/types";

export const enrollVerifyData = createAsyncThunk<
  EnrollVerifyDataResponse,
  EnrollVerifyDataPayload,
  { rejectValue: ErrorResult }
>("setings/mitek-verify-enroll", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<EnrollVerifyDataResponse>({
    method: "POST",
    url: ENDPOINTS_PROFILE.enrollMitekVerify,
    data,
    isShowError: false,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const sendCodesForTwoFaVerifyBiometrics = createAsyncThunk<
  SuccessResponse,
  undefined,
  { rejectValue: ErrorResult }
>("settings/get-two-fa-codes", async (_, { rejectWithValue }) => {
  const result = await makeApiRequest<SuccessResponse>({
    method: "POST",
    url: ENDPOINTS_PROFILE.sendCodeForTwoFaCheck,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const checkMitekStatus = createAsyncThunk<MitekStatusResponse, undefined, { rejectValue: ErrorResult }>(
  "user/check-mitek-status",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<MitekStatusResponse>({
      method: "GET",
      url: ENDPOINTS_PROFILE.checkMitekStatus,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const checkVoiceQuality = createAsyncThunk<
  VoiceQualityResponse,
  VoiceQualityPayload,
  { rejectValue: ErrorResult }
>("user/check-voice-quality", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<VoiceQualityResponse>({
    method: "POST",
    url: ENDPOINTS_PROFILE.checkVoiceQuality,
    data,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});
