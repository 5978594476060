import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => state.escrowsTable.widgetBuyersListData.data,
    (state: AppState) => state.escrowsTable.widgetBuyersListData.count,
    (state: AppState) => state.escrowsTable.widgetBuyersListData.pending,
    (state: AppState) => state.escrowsTable.widgetBuyersListPayload.page,
    (state: AppState) => state.escrowsTable.widgetBuyersListPayload.size,
    (state: AppState) => state.escrowsTable.widgetBuyersListPayload.skip,
    (state: AppState) => state.escrowsTable.widgetBuyersListPayload.dateFrom,
    (state: AppState) => state.escrowsTable.widgetBuyersListPayload.dateTo,
    (state: AppState) => state.escrowsTable.widgetBuyersListPayload.status,
    (state: AppState) => state.escrowsTable.widgetBuyersListPayload.id,
    (state: AppState) => state.escrowsTable.widgetBuyersListPayload.search,
  ],
  (data, count, pending, page, size, skip, dateFrom, dateTo, status, id, search) => ({
    data,
    count,
    pending,
    page,
    size,
    skip,
    dateFrom,
    dateTo,
    status,
    id,
    search,
  }),
);
