import styled from "styled-components";
import { motion } from "framer-motion";

import { Button, Svg } from "components";

export const StyledWidgetBuyersListFiltersButton = styled(Button<"button">)`
  ${Svg} {
    fill: ${({ theme }) => theme.colors.transparent};
    stroke: ${({ theme }) => theme.colors.neutral600};
  }
  &:hover {
    ${Svg} {
      fill: ${({ theme }) => theme.colors.primaryHover};
      stroke: ${({ theme }) => theme.colors.primaryHover};
    }
  }
`;

export const StyledAnimatedFiltersSectionContainer = styled(motion.div)`
  background: ${({ theme }) => theme.colors.neutral0};
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
`;
