import React, { useState } from "react";
import { toast } from "react-toastify";

import { useTranslation } from "context";
import { isErrorResult } from "services";

import {
  FlexGap,
  NetworkAndToken,
  Text,
  Column,
  Row,
  Timer,
  Button,
  AmountPreviewer,
  toastOptionsSuccess,
} from "components";
import CloseModalWrapper from "../close-modal-wrapper";
import { StyledModalWrapper } from "../styled";

import { useAppDispatch, useAppSelector } from "store/store";
import { payEscrow } from "store/escrow/escrow-process/actions";
import { hideModal } from "store/modal";

import { selectCombinedData } from "./selectors";

const EscrowConfirmPaymentModal: React.FC = () => {
  const { currentEscrow, pending, amountToPay, symbol, amountInFiat, timer } = useAppSelector(selectCombinedData);
  const [stopTimer, setStopTimer] = useState(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const payHandler = () => {
    if (currentEscrow) {
      setStopTimer(true);

      dispatch(payEscrow(currentEscrow.body.id)).then(res => {
        if (!isErrorResult(res.payload)) {
          toast.success(
            t("Your funds are being transferred, please wait until the end of the transaction"),
            toastOptionsSuccess,
          );
        }
        closeModalHandler();
      });
    }
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} />

      <Text color="neutral600" textAlign="center" textScale="body2" mb="16px">
        {t("Confirm Payment?")}
      </Text>

      <Text color="neutral500" textAlign="center" textScale="caption2" mb="24px">
        {t(
          'We\'ve recalculated our fee based on the current gas prices. Please, click "Pay" and we will transfer funds into Zert Vault. Bear in mind that the seller will only receive them when you confirm delivery',
        )}
      </Text>

      <Row justifyContent="center" mb="24px">
        <Timer
          onReachZeroCallback={closeModalHandler}
          keyListShow={["mins", "sec"]}
          timestamp={timer}
          numberHeight={48}
          numberWidth={48}
          textProps={{ textScale: "caption1", color: "primary", $fontWeight: "bold" }}
          stopTimer={stopTimer}
        />
      </Row>

      <Column width="100%">
        <NetworkAndToken />
      </Column>

      <AmountPreviewer amountToPay={amountToPay} symbol={symbol} amountInFiat={amountInFiat} />

      <FlexGap gap="14px" mt="32px" width="100%" alignItems="center" flexDirection={{ _: "column", mobileL: "row" }}>
        <Button
          onClick={closeModalHandler}
          isLoading={pending}
          variant="outline"
          width="100%"
          maxWidth={{ _: "100%", mobileL: "184px" }}
        >
          {t("Cancel")}
        </Button>
        <Button onClick={payHandler} isLoading={pending} width="100%" maxWidth={{ _: "100%", mobileL: "184px" }}>
          {t("Pay")}
        </Button>
      </FlexGap>
    </StyledModalWrapper>
  );
};

export default EscrowConfirmPaymentModal;
