import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ ...props }) => {
  return (
    <Svg viewBox="0 0 49 19" color="logoLifi" {...props}>
      <g clipPath="url(#clip0_1292_40630)">
        <path d="M6.1772 12.8812L0.387156 14.1846C0.175967 14.2361 0.0175781 14.4419 0.0175781 14.6477V18.5237C0.0175781 18.8495 0.281562 19.0554 0.615942 18.9868L8.69385 17.1687C9.02822 17.1002 9.16903 16.7914 9.02822 16.4999L7.56752 13.5672C7.33873 13.1041 7.56752 12.6239 8.07788 12.521L13.3576 11.2862C13.7271 11.2004 14.0439 10.8231 14.0439 10.4458V6.86135C14.0439 6.53548 13.7799 6.31253 13.4456 6.39828L5.82523 8.16478C5.35005 8.26769 5.13886 8.71359 5.35005 9.14236L6.68756 11.835C6.91635 12.298 6.68757 12.7611 6.1772 12.8812Z" />
        <path d="M2.35923 3.19118L3.87275 6.20967C4.06634 6.60413 3.87275 7.01574 3.43277 7.1015L0.634537 7.73607C0.28256 7.82182 0.000976562 8.16483 0.000976562 8.50784V11.7493C0.000976562 12.2981 0.458548 12.6411 1.00411 12.5211L3.60876 11.9379C3.96073 11.8693 4.24232 11.5092 4.24232 11.1662L4.25992 7.83896C4.25992 7.3759 4.6471 6.91283 5.10466 6.80994L13.6401 4.82047C13.8513 4.76902 14.0097 4.56322 14.0097 4.35741V0.4814C14.0097 0.155541 13.7457 -0.0674159 13.4114 0.0183367L2.69362 2.5223C2.35923 2.59091 2.21844 2.89962 2.35923 3.19118Z" />
        <path d="M28.4961 12.2143H23.6211V4.75H21.3711V14.25H28.4961V12.2143Z" />
        <path d="M29.9629 14.25H32.2129V4.75H29.9629V14.25Z" />
        <path d="M33.8936 14.25H36.0323V12.16H33.8936V14.25Z" />
        <path d="M39.9571 10.545H44.6653V8.67215H39.9571V6.67715H45.0682V4.75H37.707V14.25H39.9571V10.545Z" />
        <path d="M46.5977 14.25H48.8475V4.75H46.5977V14.25Z" />
      </g>
      <defs>
        <clipPath id="clip0_1292_40630">
          <rect width="49" height="19" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
