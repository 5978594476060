import { showModal } from "store/modal";
import { useAppDispatch } from "store/store";

const useShowModals = () => {
  const dispatch = useAppDispatch();

  const historyWalletMobileFiltersModal = () => {
    dispatch(
      showModal({
        modalName: "walletHistoryFiltersMobileModal",
        rootId: "history",
        modalType: "rightMenu",
        props: {},
      }),
    );
  };

  return { historyWalletMobileFiltersModal };
};

export default useShowModals;
