import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getNotificationById,
  getNotificationsList,
  getUnreadNotificationCount,
  markAllNotificationAsRead,
} from "./actions";
import { NotificationsListSingleItem, NotificationsState } from "./types";
import { DefaultPayloadList } from "../../types";

const initialState: NotificationsState = {
  error: null,
  pending: false,
  notificationsListData: {
    pending: false,
    data: [],
    count: 0,
  },
  notificationsListPayload: {
    page: 1,
    size: 10,
  },
  currentNotification: null,
  unreadNotificationCount: 0,
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    ...initialState,
  },
  reducers: {
    resetNotifications: () => {
      return initialState;
    },
    setNotificationsListPayload: (state, action: PayloadAction<Partial<DefaultPayloadList>>) => {
      state.notificationsListPayload = { ...state.notificationsListPayload, ...action.payload };
    },
    resetCurrentNotification: state => {
      state.currentNotification = initialState.currentNotification;
    },
    addNewNotification: (state, action: PayloadAction<NotificationsListSingleItem>) => {
      if (state.notificationsListData.data.length) {
        state.notificationsListData.data = [action.payload, ...state.notificationsListData.data];
      }
    },
    setUnreadNotificationCount: (state, action: PayloadAction<number>) => {
      state.unreadNotificationCount = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getNotificationsList.fulfilled, (state, action) => {
        const page = action.meta.arg.page;

        if (page === 1) {
          state.notificationsListData = {
            ...action.payload,
            pending: false,
          };
        } else {
          state.notificationsListData = {
            ...action.payload,
            data: [...state.notificationsListData.data, ...action.payload.data],
            pending: false,
          };
        }

        state.error = null;
      })
      .addCase(getNotificationsList.pending, state => {
        state.notificationsListData.pending = true;
      })
      .addCase(getNotificationsList.rejected, (state, action) => {
        if (action.payload) {
          state.notificationsListData = initialState.notificationsListData;
          state.error = action.payload;
        }
      })
      .addCase(getNotificationById.fulfilled, (state, action) => {
        const newData = state.notificationsListData.data.map(item => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        });

        state.notificationsListData = { ...state.notificationsListData, data: newData };
        state.currentNotification = action.payload;
        state.pending = false;
        state.error = null;
      })
      .addCase(getNotificationById.pending, state => {
        state.pending = true;
      })
      .addCase(getNotificationById.rejected, (state, action) => {
        if (action.payload) {
          state.currentNotification = initialState.currentNotification;
          state.error = action.payload;

          state.pending = false;
        }
      })
      .addCase(markAllNotificationAsRead.fulfilled, state => {
        const newData = state.notificationsListData.data.map(item => {
          return { ...item, isRead: true };
        });

        state.notificationsListData = { ...state.notificationsListData, data: newData, pending: false };
        state.unreadNotificationCount = initialState.unreadNotificationCount;
        state.error = null;
      })
      .addCase(markAllNotificationAsRead.pending, state => {
        state.notificationsListData.pending = true;
      })
      .addCase(markAllNotificationAsRead.rejected, (state, action) => {
        if (action.payload) {
          state.notificationsListData.pending = false;
        }
      })
      .addCase(getUnreadNotificationCount.fulfilled, (state, action) => {
        state.unreadNotificationCount = action.payload;

        state.pending = false;
        state.error = null;
      })
      .addCase(getUnreadNotificationCount.pending, state => {
        state.pending = true;
      })
      .addCase(getUnreadNotificationCount.rejected, (state, action) => {
        if (action.payload) {
          state.pending = false;
        }
      });
  },
});

export const {
  resetNotifications,
  setNotificationsListPayload,
  resetCurrentNotification,
  addNewNotification,
  setUnreadNotificationCount,
} = notificationsSlice.actions;

export default notificationsSlice;
