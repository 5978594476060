import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 72 72" {...props} color={color} stroke={theme.colors.primary}>
      <g opacity="0.25" filter="url(#filter0_f_4485_78040)">
        <rect x="18" y="49" width="36" height="12" rx="4" fill={theme.colors.primary} />
      </g>
      <circle cx="36" cy="36" r="28" fill={theme.colors.primaryLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.primary} strokeOpacity="0.5" />
      <path d="M36 30V42" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M42 36H30" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 49.5V49.5C28.5435 49.5 22.5 43.4565 22.5 36V36C22.5 28.5435 28.5435 22.5 36 22.5V22.5C43.4565 22.5 49.5 28.5435 49.5 36V36C49.5 43.4565 43.4565 49.5 36 49.5Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_4485_78040"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_4485_78040" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
