import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";
import { AddWhitelistModalProps } from "../types";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => (state.modal.props as unknown as AddWhitelistModalProps).rootId,
    (state: AppState) => state.whitelist.whitelistPayload.page,
    (state: AppState) => state.whitelist.whitelistPayload.size,
    (state: AppState) => state.whitelist.whitelistPayload.chainId,
    (state: AppState) => state.whitelist.whitelistPayload.search,
    (state: AppState) => state.whitelist.whitelistWithdrawalPayload.page,
    (state: AppState) => state.whitelist.whitelistWithdrawalPayload.size,
  ],
  (rootId, page, size, chainId, search, withdrawalPage, withdrawalSize) => ({
    rootId,
    page,
    size,
    chainId,
    search,
    withdrawalPage,
    withdrawalSize,
  }),
);
