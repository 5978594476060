import { combineReducers } from "redux";

import modal from "store/modal";
import auth from "./auth";
import profile from "./profile";
import kyc from "./kyc";
import buyCrypto from "./buy-crypto";
import swap from "./swap";
import coldWallets from "./wallets/cold-wallets";
import rates from "./rates";
import hotWallets from "./wallets/hot-wallets";
import walletsFee from "./wallets/wallets-fee";
import walletsHistory from "./wallets/wallets-history";
import whitelist from "./wallets/whitelist";
import settings from "./settings";
import tokens from "./tokens";
import addresses from "./addresses";
import escrowsTable from "./escrow/escrows-table";
import createEscrow from "./escrow/create-escrow";
import messages from "./messages";
import verifyBiometric from "./verify-biometric";
import escrowProcess from "./escrow/escrow-process";
import yubikey from "./yubikey";
import widget from "./escrow/widget";
import web3Wallet from "./web3-wallet";
import createStatement from "./statements/create-statement";
import statementsList from "./statements/statements-list";
import statementInfo from "./statements/statement-info";
import dashboard from "./dashboard";
import notifications from "./notifications";

import multicall from "./multicall";
import transactions from "./transactions";

export const rootReducer = combineReducers({
  [modal.name]: modal.reducer,
  [auth.name]: auth.reducer,
  [profile.name]: profile.reducer,
  [kyc.name]: kyc.reducer,
  [buyCrypto.name]: buyCrypto.reducer,
  [coldWallets.name]: coldWallets.reducer,
  [rates.name]: rates.reducer,
  [swap.name]: swap.reducer,
  [hotWallets.name]: hotWallets.reducer,
  [walletsFee.name]: walletsFee.reducer,
  [walletsHistory.name]: walletsHistory.reducer,
  [whitelist.name]: whitelist.reducer,
  [settings.name]: settings.reducer,
  [addresses.name]: addresses.reducer,
  [escrowsTable.name]: escrowsTable.reducer,
  [createEscrow.name]: createEscrow.reducer,
  [messages.name]: messages.reducer,
  [verifyBiometric.name]: verifyBiometric.reducer,
  [escrowProcess.name]: escrowProcess.reducer,
  [tokens.name]: tokens.reducer,
  [yubikey.name]: yubikey.reducer,
  [widget.name]: widget.reducer,
  [web3Wallet.name]: web3Wallet.reducer,
  [createStatement.name]: createStatement.reducer,
  [statementsList.name]: statementsList.reducer,
  [statementInfo.name]: statementInfo.reducer,
  [dashboard.name]: dashboard.reducer,
  [notifications.name]: notifications.reducer,

  multicall: multicall,
  transactions: transactions,
});

export default rootReducer;
