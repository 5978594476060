import styled from "styled-components";

export const StyledDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 12px;

  &::before,
  &::after {
    content: "";
    height: 1px;
    flex-grow: 1;
    background-color: ${({ theme }) => theme.colors.neutral200};
  }
`;
