import {
  parseRequestOptionsFromJSON,
  get,
  AuthenticationPublicKeyCredential,
} from "@github/webauthn-json/browser-ponyfill";

import { useAppDispatch, useAppSelector } from "store/store";

import { isErrorResult } from "services";
import { parsingYubikeyData } from "utils/helpers";

import { requestYubikeyVerification } from "store/yubikey/actions";

import { YubikeyVerificationDataResponse } from "store/yubikey/types";
import { HandleYubikeyLoginArgs } from "pages/auth/login-with-yubikey-verification/components/types";

export const useSignInTransactionWithYubikey = ({
  verificationCallback,
  handleSignInTransaction,
  email,
}: {
  verificationCallback: () => void;
  handleSignInTransaction: ({ rawId, clientDataJSON, email }: HandleYubikeyLoginArgs) => void;
  email?: string;
}) => {
  const user = useAppSelector(state => state.profile.user);

  const dispatch = useAppDispatch();

  const emailForLogin = email ?? "";

  const signInTransactionWithYubikey = async () => {
    let signInData: YubikeyVerificationDataResponse | undefined = undefined;

    await dispatch(requestYubikeyVerification({ email: user?.email ? user.email : emailForLogin })).then(response => {
      if (!isErrorResult(response.payload)) {
        signInData = response.payload!;
      } else {
        verificationCallback();
      }
    });

    if (signInData) {
      try {
        //It`s for develop on localhost, if you`ve receive "bad data origin provided", you should ask backend to comment "bad data origin" verification in yubico controller

        // const newSignInData = { ...(signInData as YubikeyVerificationDataResponse) };
        // const signInDataForLocalHost = {
        //   publicKey: {
        //     ...newSignInData.publicKey,
        //     rpId: "localhost",
        //   },
        // };

        const result = await get(parseRequestOptionsFromJSON(signInData));

        const { rawId, clientDataJSON } = parsingYubikeyData<AuthenticationPublicKeyCredential>(result);

        handleSignInTransaction({ rawId, clientDataJSON, email: user?.email ? user.email : emailForLogin });
      } catch {
        verificationCallback();
      }
    }
  };

  return { signInTransactionWithYubikey };
};
