import React from "react";

import { CustomPaginate, ShowingOutOf } from "./components";
import { StyledPaginateWrapper, StyledTotalCardWrapper } from "./styled";

import { PaginateContainerProps } from "./types";
import { scrollToTop } from "utils/helpers";

const PaginationContainer: React.FC<PaginateContainerProps> = ({
  page,
  size,
  handlePageClick,
  count,
  isHiddenQuantity = false,
  ...props
}) => {
  const firstIndex = page * size - (size - 1);
  const lastIndex = page * size - count > 0 ? page * size - (page * size - count) : page * size;

  return (
    <StyledPaginateWrapper {...props}>
      {!isHiddenQuantity && (
        <StyledTotalCardWrapper>
          <ShowingOutOf count={count} lastIndex={lastIndex} firstIndex={firstIndex} />
        </StyledTotalCardWrapper>
      )}

      <CustomPaginate
        page={page ? page - 1 : 0}
        pageCount={Math.ceil(count / size)}
        handlePageClick={selectedItem => {
          handlePageClick(selectedItem);
          scrollToTop();
        }}
      />
    </StyledPaginateWrapper>
  );
};

export default PaginationContainer;
