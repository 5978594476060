import { createSlice } from "@reduxjs/toolkit";

import { estimateColdWalletWithdrawFee, estimateHotWalletWithdrawFee, estimateFiatFeeColdStorage } from "./actions";

import { WalletsFeeState } from "./types";

const initialState: WalletsFeeState = {
  error: null,
  coldWalletFee: {
    error: null,
    isDisable: true,
    pending: false,
    subscriptionFee: {
      amountInCurrency: "",
      amountInFiat: "",
      amountInUsd: "",
      inPercent: "",
    },
    withdrawalFee: {
      amountInFiat: "",
      amountInUsd: "",
    },
    debt: "",
  },
  hotWalletWithdrawFee: {
    pending: false,
    isDisable: true,
    error: null,
    nativeAmount: "0",
    tokensAmount: "0",
    tokensAmountInUsd: "0",
    nativeAmountInUsd: "0",
    debtInCurrency: "0",
    debtInUsd: "0",
    nativeCurrencyCode: "",
    tokensCurrencyCode: "",
    feeInTokensCurrency: true,
  },
  fiatFeeColdStorage: {
    pending: false,
    minAmountToCreate: {
      inFiat: "0",
      inUsd: "0",
    },
    minAmountToDeposit: {
      inFiat: "0",
      inUsd: "0",
    },
  },
};

const walletsFeeSlice = createSlice({
  name: "walletsFee",
  initialState: {
    ...initialState,
  },
  reducers: {
    resetWalletsFee: () => {
      return initialState;
    },
    resetColdWalletFee: state => {
      state.coldWalletFee = initialState.coldWalletFee;
    },
    resetHotWalletWithdrawFee: state => {
      state.hotWalletWithdrawFee = initialState.hotWalletWithdrawFee;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(estimateColdWalletWithdrawFee.fulfilled, (state, action) => {
        state.coldWalletFee = { ...action.payload, pending: false, isDisable: false, error: null };
        state.error = null;
      })
      .addCase(estimateColdWalletWithdrawFee.pending, state => {
        state.coldWalletFee.pending = true;
      })
      .addCase(estimateColdWalletWithdrawFee.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.coldWalletFee = { ...initialState.coldWalletFee, error: action.payload };
        }
      })
      .addCase(estimateHotWalletWithdrawFee.fulfilled, (state, action) => {
        state.hotWalletWithdrawFee = { ...action.payload, pending: false, isDisable: false, error: null };
        state.error = null;
      })
      .addCase(estimateHotWalletWithdrawFee.pending, state => {
        state.hotWalletWithdrawFee.pending = true;
      })
      .addCase(estimateHotWalletWithdrawFee.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.hotWalletWithdrawFee = initialState.hotWalletWithdrawFee;
        }
      })
      .addCase(estimateFiatFeeColdStorage.fulfilled, (state, action) => {
        state.fiatFeeColdStorage = { ...action.payload, pending: false };
        state.error = null;
      })
      .addCase(estimateFiatFeeColdStorage.pending, state => {
        state.fiatFeeColdStorage.pending = true;
      })
      .addCase(estimateFiatFeeColdStorage.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.fiatFeeColdStorage = initialState.fiatFeeColdStorage;
        }
      });
  },
});

export const { resetColdWalletFee, resetHotWalletWithdrawFee, resetWalletsFee } = walletsFeeSlice.actions;
export default walletsFeeSlice;
