import { useEffect } from "react";

import { useGetMitekSDK } from "hooks";
import { useHandleFaceBiometricData } from "./";

import { useTranslation } from "context";

import { partialUpdateModalProps } from "store/modal";
import store, { useAppDispatch } from "store/store";

import { MITEK_ERRORS, autoCaptureOptions, mitekActions, ELEMENTS_ID } from "configs";

import { ResponseData, MitekVerifyError, FrameProcessHint } from "types";
import { PartialUpdateModalProps } from "store/modal/types";
import { AutomaticFaceCaptureModalProps } from "components/modal/components/automatic-face-capture-modal/types";

const useVerifyWithBothBiometric = () => {
  const { mitekScienceSDK } = useGetMitekSDK();

  // When an error appears, we close the modal. We call this function when the SDK is initiated.
  const stopMitekSDK = () => {
    mitekScienceSDK?.cmd(mitekActions.sdkStop);
  };

  const { checkImageResult, showVerifyCaptureModal, handleErrorModal } = useHandleFaceBiometricData(
    stopMitekSDK,
    mitekScienceSDK,
  );

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (mitekScienceSDK) {
      // When the component is loaded, we set up the Mitek SDK with base parameters.
      // preloadComponents is array of different actions, that we will do. ex: make photo, upload files, voice verify, etc.
      mitekScienceSDK.cmd(mitekActions.componentPreload, {
        preloadComponents: ["SELFIE", "VOICE"],
        mitekSDKPath: "mitekSDK/",
        options: {
          license: process.env.REACT_APP_MIPASS_LICENSE,
        },
      });

      mitekScienceSDK.on(mitekActions.sdkError, (err: MitekVerifyError) => {
        handleErrorModal(err.type);
      });
    }
  }, []);

  const onConfirmClick = () => {
    const _partialProps = partialUpdateModalProps as PartialUpdateModalProps<AutomaticFaceCaptureModalProps>;

    showVerifyCaptureModal();

    // This function is called every time a camera is connected. It returns a result that includes keys for warnings or errors. To successfully verify, follow these steps.
    mitekScienceSDK?.on(mitekActions.frameProcessFeedback, (result: FrameProcessHint) => {
      const hintKey = result.key;
      const hintMessage = hintKey in MITEK_ERRORS ? MITEK_ERRORS[hintKey as keyof typeof MITEK_ERRORS] : hintKey;
      // This function provides the user with a hint to help them successfully complete the verification.
      mitekScienceSDK.cmd(mitekActions.showHint, t(hintMessage));
    });

    // when camera started, before frameProcessFeedback start, we will show hint messages
    mitekScienceSDK?.on(mitekActions.frameProcessingStated, () => {
      dispatch(_partialProps({ isShowHintMessage: true }));
    });

    // If the selfie is captured successfully or the timer expires, you will receive either a correct or failure image.
    // In the case of a poor image, you will receive face reasons explaining why the face verification did not pass."
    // If the result is correct, we send it to the backend to save the data in the Mitek database.
    mitekScienceSDK?.on(mitekActions.frameCaptureResult, (response: ResponseData) => {
      checkImageResult(response);
    });

    // this function call when CAPTURE_AND_PROCESS_FRAME finish successfully, you see the camera, which will be in videoContainerId parent element
    mitekScienceSDK?.on(mitekActions.cameraStarted, () => {
      dispatch(_partialProps({ isCameraLoaded: true }));
    });

    // handle all error of all functions
    mitekScienceSDK?.on(mitekActions.sdkError, (err: MitekVerifyError) => {
      const isShowErrorModal = store.getState().verifyBiometric.isShowErrorModal;
      if (isShowErrorModal) {
        handleErrorModal(err.type);
      }
    });

    // start automatic verify process, access camera in browser if needed, add base options to sdk verify
    mitekScienceSDK?.cmd(mitekActions.captureAndProcessFrame, {
      mode: "AUTO_CAPTURE",
      documentType: "SELFIE",
      mitekSDKPath: "mitekSDK/",
      options: { ...autoCaptureOptions, videoContainerId: ELEMENTS_ID.cameraContainer },
    });
  };

  return { onConfirmClick };
};

export default useVerifyWithBothBiometric;
