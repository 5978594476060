import React from "react";

import { useThemeContext, useTranslation } from "context";
import { useValidationSchema, useHandleData } from "./hooks";
import { useForm, useSupport } from "hooks";

import { Text, Button, Box, StyledLink, Column } from "components";
import { StyledModalWrapper } from "../styled";
import { CodeInputForm } from "./components";
import CloseModalWrapper from "../close-modal-wrapper";

import { useAppSelector } from "store/store";
import { TwoFactorConfirmModalProps } from "./types";
import { SuccessResponse } from "store/wallets/types";

const TwoFactorConfirmModal = <T, P>() => {
  const { title, propsForAction, propsForResend, callbackForAction, callbackForSuccess, callbackForResend } =
    useAppSelector(state => state.modal.props as unknown as TwoFactorConfirmModalProps<T, P>);

  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const { supportHandler } = useSupport();

  const { validationSchema, initialValues } = useValidationSchema();
  const { handleSubmit, setFieldValue, setFieldError, setFieldTouched, errors, isValid, touched, values } = useForm({
    initialValues,
    validationSchema,
    onSubmit(values) {
      submitHandler(values);
    },
  });

  const {
    isTimerExpired,
    timer,
    isPending,
    submitHandler,
    onAuthenticatorOtpInput,
    onEmailOtpInput,
    closeModalHandler,
    onResendClick,
    onTimerReachZero,
  } = useHandleData<T, P, SuccessResponse>({
    setFieldValue,
    setFieldError,
    propsForAction,
    propsForResend,
    callbackForAction,
    callbackForResend,
    callbackForSuccess,
  });

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} />

      <Text color="neutral600" textAlign="center" textScale="body2" $fontWeight="bold">
        {title}
      </Text>

      <Box width="100%">
        <form onSubmit={handleSubmit}>
          <CodeInputForm
            borderBottom={`1px solid ${theme.colors.neutral200}`}
            timer={timer}
            autoFocus
            formikKey="emailOtp"
            text={t("email")}
            value={values.emailOtp}
            error={errors.emailOtp}
            isTouched={touched.emailOtp}
            isPending={isPending}
            isTimerExpired={isTimerExpired}
            onResendClick={onResendClick}
            onUserInput={onEmailOtpInput}
            setFieldTouched={setFieldTouched}
            onTimerReachZero={onTimerReachZero}
            cellHeight={{ _: "36px", tablet: "44px" }}
            cellWidth={{ _: "36px", tablet: "44px" }}
          />

          <CodeInputForm
            autoFocus={false}
            text={t("authenticator")}
            formikKey="authenticatorOtp"
            value={values.authenticatorOtp}
            error={errors.authenticatorOtp}
            isTouched={touched.authenticatorOtp}
            onUserInput={onAuthenticatorOtpInput}
            setFieldTouched={setFieldTouched}
            cellHeight={{ _: "36px", tablet: "44px" }}
            cellWidth={{ _: "36px", tablet: "44px" }}
          />

          <Column alignItems="center" width="100%">
            <Button width="100%" type="submit" disabled={!isValid} isLoading={isPending}>
              {t("Next")}
            </Button>
            <StyledLink
              color="neutral500"
              my="18px"
              mb={{ _: "20px", tablet: "30px" }}
              textScale="caption2"
              textAlign="center"
              $fontWeight="medium"
              onClick={() => supportHandler()}
            >
              {t("Do you need help?")}
            </StyledLink>
          </Column>
        </form>
      </Box>
    </StyledModalWrapper>
  );
};

export default TwoFactorConfirmModal;
