import React from "react";

import StyledButton from "./styled";
import { ButtonProps } from "./types";

import { SpinnerIcon } from "components/svg";
import { Text } from "components";

export const getExternalLinkProps = () => ({
  target: "_blank",
  rel: "noreferrer noopener",
});

const Button = <E extends React.ElementType = "button">(props: ButtonProps<E>): JSX.Element => {
  const { startIcon, endIcon, children, isLoading, disabled, external, ...rest } = props;
  const isDisabled = isLoading || disabled;
  const internalProps = external ? getExternalLinkProps() : {};

  return (
    <StyledButton {...internalProps} {...rest} type={props.type || "button"} disabled={isDisabled}>
      {isLoading ? (
        <SpinnerIcon color="neutral100" />
      ) : (
        <>
          {React.isValidElement(startIcon) && React.cloneElement(startIcon)}

          {typeof children === "string" ? <Text textScale="button">{children}</Text> : children}

          {React.isValidElement(endIcon) && React.cloneElement(endIcon)}
        </>
      )}
    </StyledButton>
  );
};

Button.defaultProps = {
  isLoading: false,
  variant: "primary",
  scale: "md",
  disabled: false,
};

export default Button;
