import { useState } from "react";
import { SingleValue } from "react-select";

import { LabelWithIcon } from "components";

import { useGetFilteredOptions } from "hooks";

import { useTranslation } from "context";

import { useAppDispatch, useAppSelector } from "store/store";
import { setWalletsHistoryPayload } from "store/wallets/wallets-history";

import { tokensLogo } from "configs";
import { NATIVE_CURRENCIES_LOGO, SHORT_CHAIN_NAMES } from "configs/web3";
import { historyTypeSelectList } from "../constants";
import { getFilteredOptions } from "utils/helpers";

import { SingleOption } from "components/selects/single-select/types";
import { HotWalletTransactionsTypeEnum } from "store/wallets/enums";
import { LabelWithIconProps } from "components/selects/label-with-icon/types";
import { SingleRadioOption } from "components/radio-button/types";

const useHandleFilters = ({ isDesktop }: { isDesktop: boolean }) => {
  const chainId = useAppSelector(state => state.walletsHistory.historyPayload.chainId);
  const currencyCode = useAppSelector(state => state.walletsHistory.historyPayload.currencyCode);
  const type = useAppSelector(state => state.walletsHistory.historyPayload.type);

  const [mobileChainId, setMobileChainId] = useState<undefined | string>(chainId);

  const { options } = useGetFilteredOptions({
    chainId: isDesktop ? chainId : mobileChainId,
    typeList: historyTypeSelectList,
  });
  const { currencyOptions, typeOptions, networksOptions } = options;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const typeRadioOption = typeOptions.map(item => {
    const { value, label } = item;
    return {
      value,
      label,
      name: label ?? t("All Types"),
    };
  });

  const networksRadioOption = networksOptions.map(item => {
    const { value } = item;
    return {
      value,
      label: (
        <LabelWithIcon
          text={SHORT_CHAIN_NAMES[value] ?? t("All Networks")}
          src={NATIVE_CURRENCIES_LOGO[value]}
          textProps={{ color: "neutral600", $fontWeight: "regular", textScale: "body3" }}
        />
      ),
      name: SHORT_CHAIN_NAMES[value] ?? t("All Networks"),
    };
  });

  const currencyRadioOption = currencyOptions.map(item => {
    const { value } = item;
    return {
      value,
      label: (
        <LabelWithIcon
          text={(item.label as JSX.Element & LabelWithIconProps).props?.text ?? t("All Currencies")}
          src={tokensLogo[value as keyof typeof tokensLogo]}
          textProps={{ color: "neutral600", $fontWeight: "regular", textScale: "body3" }}
        />
      ),
      name: ((item.label as JSX.Element & LabelWithIconProps).props?.text as string) ?? t("All Currencies"),
    };
  });

  const presetNetworkOption = networksOptions.find(el => el.value === chainId) ?? networksOptions[0];
  const presetCurrencyOption = currencyOptions.find(el => el.value === currencyCode) ?? currencyOptions[0];
  const presetTypeOption = typeOptions.find(el => el.value === type) ?? typeOptions[0];

  const [selectedType, setSelectedType] = useState(presetTypeOption);
  const [selectedNetworks, setSelectedNetworks] = useState(presetNetworkOption);
  const [selectedCurrency, setSelectedCurrency] = useState(presetCurrencyOption);

  const handleSelectedType = (selectedOption: SingleValue<SingleOption>) => {
    if (selectedOption) {
      const type = typeOptions.find(item => selectedOption.value !== "" && selectedOption.value === item.value);
      setSelectedType({ value: selectedOption.value, label: selectedOption.label });
      if (isDesktop) {
        dispatch(setWalletsHistoryPayload({ page: 1, type: type?.value as HotWalletTransactionsTypeEnum }));
      }
    }
  };

  const handleSelectedCurrency = (selectedOption: SingleValue<SingleOption>) => {
    if (selectedOption) {
      const currencyCode = Object.hasOwn(tokensLogo, selectedOption.value) ? selectedOption.value : undefined;
      setSelectedCurrency({ value: selectedOption.value, label: selectedOption.label });
      if (isDesktop) {
        dispatch(setWalletsHistoryPayload({ page: 1, currencyCode }));
      }
    }
  };

  const handleSelectedNetworks = (selectedOption: SingleValue<SingleOption>) => {
    if (selectedOption) {
      const chainId = Object.hasOwn(SHORT_CHAIN_NAMES, selectedOption.value) ? selectedOption.value : undefined;
      setSelectedNetworks({ value: selectedOption.value, label: selectedOption.label });
      setSelectedCurrency(currencyOptions[0]);
      if (isDesktop) {
        dispatch(setWalletsHistoryPayload({ page: 1, chainId, currencyCode: undefined }));
      } else {
        setMobileChainId(chainId);
      }
    }
  };

  const confirmHandler = (dates: { dateFrom: string | undefined; dateTo: string | undefined }) => {
    dispatch(
      setWalletsHistoryPayload({
        page: 1,
        type: selectedType.value ? (selectedType.value as HotWalletTransactionsTypeEnum) : undefined,
        currencyCode: selectedCurrency.value ? selectedCurrency.value : undefined,
        chainId: selectedNetworks.value ? selectedNetworks.value : undefined,
        dateFrom: dates.dateFrom ? dates.dateFrom : undefined,
        dateTo: dates.dateTo ? dates.dateTo : undefined,
      }),
    );
  };

  const selectsList: {
    option: SingleOption[] | SingleRadioOption[];
    handler: (selectedOption: SingleValue<SingleOption>) => void;
    value: SingleOption;
    name: string;
  }[] = [
    {
      option: isDesktop ? getFilteredOptions(typeOptions, selectedType.value) : typeRadioOption,
      handler: handleSelectedType,
      value: selectedType,
      name: "Type",
    },
    {
      option: isDesktop ? getFilteredOptions(networksOptions, selectedNetworks.value) : networksRadioOption,
      handler: handleSelectedNetworks,
      value: selectedNetworks,
      name: "Network",
    },
    {
      option: isDesktop ? getFilteredOptions(currencyOptions, selectedCurrency.value) : currencyRadioOption,
      handler: handleSelectedCurrency,
      value: selectedCurrency,
      name: "Currency",
    },
  ];

  return {
    selectsList,
    currencyOptions,
    typeOptions,
    networksOptions,
    confirmHandler,
  };
};

export default useHandleFilters;
