import React from "react";

import { Column, Flex, Text } from "components";
import { ModalPhotoIcon } from "components/svg";

import { useTranslation } from "context";

const CameraLoading: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Column width="100%" alignItems="center">
      <Flex mt="26px" mb="16px" justifyContent="center" width="100%">
        <ModalPhotoIcon height="72px" width="72px" />
      </Flex>

      <Text color="neutral600" textAlign="center" textScale="body2" $fontWeight="bold">
        {t("Starting face capture")}
      </Text>

      <Text wordBreak="break-word" color="neutral600" mt="12px" mb="12px" textAlign="center" textScale="caption1">
        {t("Please, wait...")}
      </Text>
    </Column>
  );
};

export default CameraLoading;
