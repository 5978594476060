import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  sendCodesForWhitelist,
  createWhitelistItem,
  deleteWhitelistItem,
  getWhitelist,
  getWhitelistWithdrawal,
  getWhitelistModal,
} from "./actions";

import { WhitelistState } from "./types";
import { HistoryPayload } from "../types";
import { WhitelistResponseSingleItem } from "./types";
import { WhitelistStateEnum } from "../enums";

const changeItemStatus = (list: WhitelistResponseSingleItem[], id: number) => {
  return list.map(item => (item.id === id ? { ...item, status: WhitelistStateEnum.active } : item));
};

const initialState: WhitelistState = {
  error: null,
  pending: false,

  whitelistInfo: {
    pending: false,
    data: [],
    count: 0,
  },
  whitelistWithdrawalInfo: {
    pending: false,
    data: [],
    count: 0,
  },
  whitelistModalInfo: {
    pending: false,
    data: [],
    count: 0,
  },
  whitelistPayload: {
    page: 1,
    size: 10,
  },
  whitelistModalPayload: {
    page: 1,
    size: 10,
  },
  whitelistWithdrawalPayload: {
    page: 1,
    size: 10,
  },
};

const whitelistSlice = createSlice({
  name: "whitelist",
  initialState: {
    ...initialState,
  },
  reducers: {
    setWhitelistPayload: (state, action: PayloadAction<Partial<HistoryPayload>>) => {
      state.whitelistPayload = { ...state.whitelistPayload, ...action.payload };
    },
    setWhitelistModalPayload: (state, action: PayloadAction<Partial<HistoryPayload>>) => {
      state.whitelistModalPayload = { ...state.whitelistModalPayload, ...action.payload };
    },
    changeWhitelistItemStatus: (state, action: PayloadAction<Pick<WhitelistResponseSingleItem, "id">>) => {
      state.whitelistInfo.data = changeItemStatus(state.whitelistInfo.data, action.payload.id);
      state.whitelistModalInfo.data = changeItemStatus(state.whitelistModalInfo.data, action.payload.id);
    },
    resetWhitelist: () => {
      return initialState;
    },
    resetWhitelistPayload: state => {
      state.whitelistPayload = initialState.whitelistPayload;
      state.whitelistModalPayload = initialState.whitelistModalPayload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(sendCodesForWhitelist.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(sendCodesForWhitelist.pending, state => {
        state.pending = true;
      })
      .addCase(sendCodesForWhitelist.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(createWhitelistItem.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(createWhitelistItem.pending, state => {
        state.pending = true;
      })
      .addCase(createWhitelistItem.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(getWhitelist.fulfilled, (state, action) => {
        state.whitelistInfo = { ...action.payload, pending: false };
        state.error = null;
      })
      .addCase(getWhitelist.pending, state => {
        state.whitelistInfo.pending = true;
      })
      .addCase(getWhitelist.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.whitelistInfo.pending = false;
        }
      })
      .addCase(getWhitelistWithdrawal.fulfilled, (state, action) => {
        state.whitelistWithdrawalInfo = { ...action.payload, pending: false };
        state.error = null;
      })
      .addCase(getWhitelistWithdrawal.pending, state => {
        state.whitelistWithdrawalInfo.pending = true;
      })
      .addCase(getWhitelistWithdrawal.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.whitelistWithdrawalInfo.pending = false;
        }
      })
      .addCase(getWhitelistModal.fulfilled, (state, action) => {
        state.whitelistModalInfo = { ...action.payload, pending: false };
        state.error = null;
      })
      .addCase(getWhitelistModal.pending, state => {
        state.whitelistModalInfo.pending = true;
      })
      .addCase(getWhitelistModal.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.whitelistModalInfo.pending = false;
        }
      })
      .addCase(deleteWhitelistItem.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(deleteWhitelistItem.pending, state => {
        state.pending = true;
      })
      .addCase(deleteWhitelistItem.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      });
  },
});

export const {
  resetWhitelist,
  setWhitelistPayload,
  setWhitelistModalPayload,
  changeWhitelistItemStatus,
  resetWhitelistPayload,
} = whitelistSlice.actions;
export default whitelistSlice;
