import { useEffect } from "react";

export const useSetFocusInputAfterResponse = ({
  pending,
  inputRef,
  searchValue,
}: {
  pending: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  searchValue: string | undefined;
}) => {
  useEffect(() => {
    if (!pending && inputRef.current && searchValue) {
      inputRef.current.focus();
    }
  }, [pending, searchValue]);
};
