import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Components + styling

import { StyledMobileMenu, StyledMobileMenuItem } from "./styled";
import { Text, FlexGap } from "components";
import { LogoutIcon, SpinnerIcon, SupportIcon, SettingsIcon } from "components/svg";
// Context
import { useTranslation } from "context";
// Hooks
import { useElementDimensions, useSupport } from "hooks";
import { useActivationState } from "configs/web3";

//Services
import { resetStore } from "services/axios";
// Types
import { HeaderDesktopProps } from "components/header/type";
// Const
import { NAVIGATE_ITEM } from "components/sidebar/constants";
import { scrollToTop } from "utils/helpers";
import { ROUTES } from "navigation/routes";
import { ELEMENTS_ID } from "configs";

const MobileMenu: React.FC<HeaderDesktopProps> = ({ isMenuOpen, setIsMenuOpen }) => {
  const { t } = useTranslation();
  const currentUrl = useLocation();
  const navigate = useNavigate();
  const { supportHandler, pending } = useSupport();
  const { deactivate } = useActivationState();
  const { offsetHeight: headerHeight } = useElementDimensions(ELEMENTS_ID.header);

  const pathnameRoutes = currentUrl.pathname.split("/");

  const handleNavigate = (path: string) => {
    navigate(path);
    scrollToTop();
    setIsMenuOpen(false);
  };

  const logoutHandler = async () => {
    await setIsMenuOpen(false);
    await deactivate();
    resetStore();
  };

  return (
    <StyledMobileMenu isActive={isMenuOpen} headerHeight={headerHeight}>
      <FlexGap flexWrap="wrap" justifyContent="center" gap="8px" maxWidth="320px">
        {NAVIGATE_ITEM.map(({ icon: Icon, title, path }, index) => {
          const isActive = pathnameRoutes.includes(path);

          return (
            <StyledMobileMenuItem key={index} isActive={isActive} onClick={() => handleNavigate(path)}>
              <Icon color={isActive ? "primary" : "neutral500"} width="24px" height="24px" mb="8px" />

              <Text color={isActive ? "primary" : "neutral500"} textScale="caption1" $fontWeight="medium">
                {t(`${title}`)}
              </Text>
            </StyledMobileMenuItem>
          );
        })}

        <StyledMobileMenuItem isActive={false} onClick={() => handleNavigate(ROUTES.settings)}>
          <SettingsIcon width="24px" height="24px" mb="8px" />

          <Text color="neutral500" textScale="caption1" $fontWeight="medium">
            {t("Settings")}
          </Text>
        </StyledMobileMenuItem>

        <StyledMobileMenuItem isActive={false} onClick={() => supportHandler()}>
          {pending ? (
            <SpinnerIcon color="neutral500" width="24px" height="24px" mb="8px" />
          ) : (
            <SupportIcon width="24px" height="24px" mb="8px" />
          )}
          <Text color="neutral500" textScale="caption1" $fontWeight="medium">
            {t("Support")}
          </Text>
        </StyledMobileMenuItem>

        <StyledMobileMenuItem isActive={false} onClick={logoutHandler}>
          <LogoutIcon width="24px" height="24px" mb="8px" />

          <Text color="neutral500" textScale="caption1" $fontWeight="medium">
            {t(`Logout`)}
          </Text>
        </StyledMobileMenuItem>
      </FlexGap>
    </StyledMobileMenu>
  );
};

export default MobileMenu;
