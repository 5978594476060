import React from "react";
import { Svg } from "components";
import { useThemeContext } from "context";

import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "neutral500", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 72 72" {...props} color="transparent">
      <circle cx="36" cy="36" r="27.5" fill={theme.colors.neutral200} stroke={theme.colors.neutral300} />
      <path
        d="M38.3996 48H33.5996"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.5 33.048V33V33C43.5 28.8585 40.1415 25.5 36 25.5V25.5C31.8585 25.5 28.5 28.8585 28.5 33V33V33.048V36.756C28.5 37.242 28.2255 37.6845 27.792 37.902L27.0375 38.2785C26.0955 38.751 25.5 39.714 25.5 40.767V40.767C25.5 42.303 26.745 43.548 28.281 43.548H43.719C45.255 43.548 46.5 42.303 46.5 40.767V40.767C46.5 39.714 45.9045 38.751 44.9625 38.28L44.208 37.9035C43.7745 37.6845 43.5 37.242 43.5 36.756V33.048Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.5 31.9375L33.5 36.9375"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.5 31.9375L38.5 36.9375"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="36" cy="36" r="35.75" stroke={theme.colors.neutral200} strokeWidth="0.5" />
    </Svg>
  );
};

export default Icon;
