import React from "react";

import { useThemeContext } from "context";

import { Svg } from "components";

import { SvgProps } from "components/svg/types";

const Icon: React.FC<SvgProps> = ({ color = "blue", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        d="M19.5832 4.40587C20.2432 5.06487 20.2432 6.13387 19.5832 6.79287C18.9232 7.45187 17.8542 7.45187 17.1942 6.79287C16.5342 6.13387 16.5342 5.06487 17.1942 4.40587C17.8542 3.74687 18.9232 3.74687 19.5832 4.40587"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.43055 19.5939C3.77055 18.9349 3.77055 17.8659 4.43055 17.2069C5.09055 16.5479 6.15955 16.5479 6.81955 17.2069C7.47955 17.8659 7.47955 18.9349 6.81955 19.5939C6.15955 20.2529 5.08955 20.2529 4.43055 19.5939"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.94043 19.4479C10.4464 21.8299 15.2584 21.4719 18.3674 18.3659C20.1264 16.6079 21.0064 14.3039 21.0064 11.9999C21.0064 11.4679 20.9744 10.9369 20.8804 10.4119"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.13284 13.5879C3.03884 13.0629 3.00684 12.5319 3.00684 11.9999C3.00684 9.69594 3.88684 7.39194 5.64584 5.63394C8.75384 2.52794 13.5668 2.16994 17.0728 4.55194"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 15.75C12 15.75 15 14.311 15 12.153V9.67899L12.812 8.89199C12.286 8.70299 11.712 8.70299 11.186 8.89199L9 9.67899V12.153C9 14.311 12 15.75 12 15.75Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
