import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

export const StyledPaginateWrapper = styled.div<SpaceProps>`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  position: relative;
  ${space}
`;

export const StyledTotalCardWrapper = styled.div`
  display: none;

  ${({ theme }) => theme.mediaQueries.tablet} {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    transform: translateY(-50%);
    top: 50%;
  }
`;
