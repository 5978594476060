import React, { useEffect, useState } from "react";
import { isEqual } from "lodash";

import { useAppDispatch, useAppSelector } from "store/store";
import { useTranslation } from "context";

import { useHandleFilters } from "pages/main/wallets/components/history/hooks";
import { useMatchBreakpoints } from "hooks";

import { Box, Button, MobileRangeDateSelect, RadioButton, RadioButtonGroup } from "components";
import { StyledSideModalWrapper, StyledFilterSectionTitle, StyledConfirmContainer } from "../styled";
import CloseModalWrapper from "../close-modal-wrapper";
import { StyledWalletHistoryTitleContainer } from "./styled";

import { hideModal } from "store/modal";

import { SingleRadioOption } from "components/radio-button/types";
import { selectCombinedData } from "./selectors";

const WalletHistoryMobileFiltersModal: React.FC = () => {
  const { dateFrom, dateTo, type, chainId, currencyCode } = useAppSelector(selectCombinedData);

  const { t } = useTranslation();
  const { selectsList, confirmHandler } = useHandleFilters({ isDesktop: false });
  const { isDesktop } = useMatchBreakpoints();

  const [dates, setDates] = useState({ dateFrom, dateTo });

  const dispatch = useAppDispatch();

  const stateFiltersObj = {
    dateFrom: dates.dateFrom,
    dateTo: dates.dateTo,
    type: selectsList[0].value.value ? selectsList[0].value.value : undefined,
    chainId: selectsList[1].value.value ? selectsList[1].value.value : undefined,
    currencyCode: selectsList[2].value.value ? selectsList[2].value.value : undefined,
  };

  const storeFiltersObj = {
    dateFrom,
    dateTo,
    type,
    chainId,
    currencyCode,
  };

  useEffect(() => {
    if (isDesktop) {
      closeModalHandler();
    }
  }, [isDesktop]);

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const handleConfirmFilters = () => {
    confirmHandler(dates);
    closeModalHandler();
  };

  return (
    <>
      <StyledSideModalWrapper height="calc(100dvh - 92px)">
        <CloseModalWrapper closeModalHandler={closeModalHandler} mb="24px" />

        {selectsList.map(({ value, handler, option, name }, index) => {
          const { value: selectedValue } = value;
          return (
            <Box key={index}>
              <StyledWalletHistoryTitleContainer>
                <StyledFilterSectionTitle textScale="body3">{t(name)}</StyledFilterSectionTitle>
              </StyledWalletHistoryTitleContainer>

              <RadioButtonGroup
                selectedValue={selectedValue}
                onClickRadioButton={handler}
                name={name}
                mb="32px"
                mt="20px"
              >
                {(option as SingleRadioOption[]).map(({ value, label, name }, index) => {
                  return (
                    <RadioButton
                      name={name}
                      key={index}
                      value={value}
                      label={label}
                      width="100%"
                      mb={option.length - 1 !== index ? "16px" : ""}
                    />
                  );
                })}
              </RadioButtonGroup>
            </Box>
          );
        })}

        <Box>
          <StyledWalletHistoryTitleContainer>
            <StyledFilterSectionTitle textScale="body3">{t("Date")}</StyledFilterSectionTitle>
          </StyledWalletHistoryTitleContainer>

          <Box mt="20px">
            <MobileRangeDateSelect setDates={setDates} dates={dates} isNeedToScrollDown />
          </Box>
        </Box>
      </StyledSideModalWrapper>

      <StyledConfirmContainer>
        <Button width="100%" onClick={handleConfirmFilters} disabled={isEqual(stateFiltersObj, storeFiltersObj)}>
          {t("Confirm")}
        </Button>
      </StyledConfirmContainer>
    </>
  );
};

export default WalletHistoryMobileFiltersModal;
