import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  getYubikeyKeysList,
  requestYubikeyRegistration,
  yubikeyRegistration,
  yubikeyRegisterName,
  requestYubikeyVerification,
  deleteYubikeyKeyCheck,
  deleteYubikeyKeyConfirm,
} from "./actions";

import { YubikeyState } from "./types";

const initialState: YubikeyState = {
  pending: false,
  error: null,
  yubikeyRegisterData: null,
  yubikeyLoginData: null,
  yubikeyKeysListData: {
    total: 0,
    data: [],
    pending: false,
  },
  email: "",
};

export const yubikeySlice = createSlice({
  name: "yubikey",
  initialState,
  reducers: {
    resetYubikey: () => {
      return initialState;
    },
    setEmailForYubikeyLogin: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(requestYubikeyRegistration.fulfilled, (state, action) => {
        state.yubikeyRegisterData = action.payload;
        state.error = null;
        state.pending = false;
      })
      .addCase(requestYubikeyRegistration.pending, state => {
        state.pending = true;
      })
      .addCase(requestYubikeyRegistration.rejected, (state, action) => {
        if (action.payload) {
          state.yubikeyRegisterData = initialState.yubikeyRegisterData;
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(yubikeyRegistration.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(yubikeyRegistration.pending, state => {
        state.pending = true;
      })
      .addCase(yubikeyRegistration.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(getYubikeyKeysList.fulfilled, (state, action) => {
        state.yubikeyKeysListData = { ...action.payload, pending: false };
        state.error = null;
      })
      .addCase(getYubikeyKeysList.pending, state => {
        state.yubikeyKeysListData.pending = true;
      })
      .addCase(getYubikeyKeysList.rejected, (state, action) => {
        if (action.payload) {
          state.yubikeyKeysListData = initialState.yubikeyKeysListData;
          state.error = action.payload;
        }
      })
      .addCase(yubikeyRegisterName.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(yubikeyRegisterName.pending, state => {
        state.pending = true;
      })
      .addCase(yubikeyRegisterName.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(requestYubikeyVerification.fulfilled, (state, action) => {
        state.yubikeyLoginData = action.payload;
        state.error = null;
        state.pending = false;
      })
      .addCase(requestYubikeyVerification.pending, state => {
        state.pending = true;
      })
      .addCase(requestYubikeyVerification.rejected, (state, action) => {
        if (action.payload) {
          state.yubikeyLoginData = initialState.yubikeyLoginData;
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(deleteYubikeyKeyCheck.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(deleteYubikeyKeyCheck.pending, state => {
        state.pending = true;
      })
      .addCase(deleteYubikeyKeyCheck.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(deleteYubikeyKeyConfirm.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(deleteYubikeyKeyConfirm.pending, state => {
        state.pending = true;
      })
      .addCase(deleteYubikeyKeyConfirm.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      });
  },
});

export const { resetYubikey, setEmailForYubikeyLogin } = yubikeySlice.actions;

export default yubikeySlice;
