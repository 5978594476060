import * as yup from "yup";

import { useTranslation } from "context";
import { REGEX } from "configs";
import { InitialValues } from "./types";

const useValidationSchema = () => {
  const initialValues: InitialValues = {
    email: "",
    password: "",
  };

  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    email: yup.string().required(t("This field is required")).matches(REGEX.email, t("Invalid email address")),
    password: yup
      .string()
      .required(t("This field is required"))
      .matches(
        REGEX.password,
        t("Must contain 8 characters, one uppercase, one lowercase, one number and one special case character"),
      ),
  });

  return { validationSchema, initialValues };
};

export default useValidationSchema;
