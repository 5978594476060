import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 72 72" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_8795_113936)">
        <rect x="18" y="49" width="36" height="12" rx="4" fill={theme.colors.primary} />
      </g>
      <circle cx="36" cy="36" r="28" fill={theme.colors.primaryLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.primary} strokeOpacity="0.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 34.8626C48 41.4146 43.2645 47.5556 36.78 49.3916C36.273 49.5356 35.727 49.5356 35.22 49.3916C28.7355 47.5571 24 41.4146 24 34.8626V28.8206C24 27.6026 24.7365 26.5046 25.8645 26.0441L33.1605 23.0591C34.9815 22.3136 37.0215 22.3136 38.841 23.0591L46.137 26.0441C47.2635 26.5046 48 27.6026 48 28.8206V34.8626Z"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.4004 35.9786L41.6503 37.3171L42.9002 35.9786"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.6143 31.2778C33.5528 30.6378 34.6798 30.2578 35.9011 30.2578C39.1409 30.2578 41.7664 32.8834 41.7664 36.1232C41.7664 36.516 41.725 36.8988 41.6507 37.2717"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4002 36.1445L30.1503 34.8074L28.9004 36.1445"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.1863 40.8469C38.2492 41.4869 37.1208 41.8654 35.8994 41.8654C32.6597 41.8654 30.0342 39.2399 30.0342 36.0001C30.0342 35.6072 30.0756 35.2244 30.1499 34.8516"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.primary} strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_8795_113936"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_8795_113936" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
