import React from "react";
import { LineChart, Line, YAxis, ResponsiveContainer } from "recharts";

import { useThemeContext } from "context";

import { DynamicPriceChartProps } from "./types";

const DynamicPriceChart: React.FC<DynamicPriceChartProps> = ({ data, dynamicPrice, maxHeight = 50, width = 100 }) => {
  const { theme } = useThemeContext();

  return (
    <ResponsiveContainer maxHeight={maxHeight} aspect={3 / 4} width={width}>
      <LineChart
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
        style={{ cursor: "inherit" }}
      >
        <YAxis type="number" domain={["dataMin", "dataMax"]} hide />
        <Line
          type="monotone"
          dot={false}
          dataKey="point"
          stroke={dynamicPrice > 0 ? theme.colors.green : theme.colors.red}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DynamicPriceChart;
