import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal, partialUpdateModalProps, showModal } from "store/modal";

import { AddressResponse } from "store/addresses/types";
import { ModalProps, PartialUpdateModalProps } from "store/modal/types";
import { Roles } from "pages/main/create-escrow/types";
import { DeliveryAddressesSideModalProps } from "components/modal/components/delivery-addresses-modal/types";
import { AddressesSideModalProps } from "components/modal/components/addresses-side-modal/types";
import { useHandleDataProps } from "../types";

export const useHandleData = ({ setFieldValue, values }: useHandleDataProps) => {
  const addressesList = useAppSelector(state => state.addresses.addressesList);

  const [address, setAddress] = useState<null | AddressResponse>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const setDefaultAddress = () => {
      // default address preselect
      if (values.role.role === Roles.buyer && values.escrowInfo.isShipped && !values.escrowInfo.address) {
        const defaultAddress = addressesList.find(address => address.isDefault);

        setAddressHandler(defaultAddress);
        //   preselect previously selected address
      } else if (values.role.role === Roles.buyer && values.escrowInfo.isShipped && values.escrowInfo.address) {
        const currentAddress = addressesList.find(address => address.id === values.escrowInfo.address);

        setAddressHandler(currentAddress);
      }
    };

    setDefaultAddress();
  }, [values.escrowInfo.isShipped]);

  useEffect(() => {
    // if the user removed from the list the address that previously indicated in the form
    if (values.escrowInfo.address && !addressesList.find(address => address.id === values.escrowInfo.address)) {
      const defaultAddress = addressesList.find(address => address.isDefault);

      setAddressHandler(defaultAddress);
      // when the user creates the first address add it to the form and to the screen
    } else if (
      !values.escrowInfo.address &&
      addressesList.length &&
      values.escrowInfo.isShipped &&
      values.role.role === Roles.buyer
    ) {
      const defaultAddress = addressesList.find(address => address.isDefault);

      setAddressHandler(defaultAddress);
    }
  }, [addressesList]);

  const setAddressHandler = async (address?: AddressResponse) => {
    const _partialProps = partialUpdateModalProps as PartialUpdateModalProps<DeliveryAddressesSideModalProps>;

    if (address) {
      setAddress(address);
      await setFieldValue("escrowInfo.address", address.id);
      dispatch(_partialProps({ currentAddress: address }));
      dispatch(hideModal());
    } else {
      setAddress(null);
      await setFieldValue("escrowInfo.address", null);
      dispatch(_partialProps({ currentAddress: null }));
      dispatch(hideModal());
    }
  };

  const handleSwitchIsShipped = async () => {
    await setFieldValue("escrowInfo.isShipped", !values.escrowInfo.isShipped);
  };

  const showDeliveryAddressesSideModal = () => {
    const _showModal = showModal as ModalProps<DeliveryAddressesSideModalProps>;

    dispatch(
      _showModal({
        modalName: "deliveryAddressesSideModal",
        modalType: "rightMenu",
        rootId: "create-escrow",
        props: {
          currentAddress: address,
          setAddressHandler,
        },
      }),
    );
  };

  const handleAddressesSideModal = () => {
    const _showModal = showModal as ModalProps<AddressesSideModalProps>;

    dispatch(
      _showModal({
        modalName: "addressesSideModal",
        modalType: "rightMenu",
        rootId: "create-escrow",
        props: {},
      }),
    );
  };

  return { address, handleSwitchIsShipped, showDeliveryAddressesSideModal, handleAddressesSideModal };
};
