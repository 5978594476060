import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Components + styling
import { Box, Flex } from "components";
import { LogoutIcon, SpinnerIcon, SupportIcon, SettingsIcon } from "components/svg";
import { StyledSideBarNavigateItem, StyledSideBarNavigateItemInnerText } from "../../styled";
// Context
import { useTranslation } from "context";
// Hooks
import { useSupport } from "hooks";
import { useActivationState } from "configs/web3";
// Services
import { resetStore } from "services/axios";
//Types
import { SideBarProps } from "../../types";
// Const
import { ROUTES } from "navigation/routes";

const BottomMenuItems: React.FC<SideBarProps> = ({ isDesktopMenuOpen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUrl = useLocation();
  const { supportHandler, pending } = useSupport();
  const { deactivate } = useActivationState();

  const pathnameRoutes = currentUrl.pathname.split("/");

  const logoutHandler = () => {
    deactivate();
    resetStore();
  };

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <Box>
      <StyledSideBarNavigateItem
        isActive={pathnameRoutes.includes(ROUTES.settings)}
        justifyContent={isDesktopMenuOpen ? "flex-start" : "center"}
        onClick={() => handleNavigate(ROUTES.settings)}
        cursor="pointer"
      >
        <Flex alignItems="center" width="100%">
          <Flex justifyContent="flex-start">
            <SettingsIcon
              color={pathnameRoutes.includes(ROUTES.settings) ? "primary" : "neutral500"}
              width="24px"
              height="24px"
            />
          </Flex>

          <StyledSideBarNavigateItemInnerText
            $fontWeight="medium"
            px="14px"
            color={pathnameRoutes.includes(ROUTES.settings) ? "primary" : "neutral500"}
            isDesktopMenuOpen={isDesktopMenuOpen}
          >
            {t("Settings")}
          </StyledSideBarNavigateItemInnerText>
        </Flex>
      </StyledSideBarNavigateItem>

      <StyledSideBarNavigateItem isActive={false} justifyContent={isDesktopMenuOpen ? "flex-start" : "center"}>
        <Flex alignItems="center" width="100%" cursor="pointer" onClick={() => supportHandler()}>
          <Flex>
            {pending ? (
              <SpinnerIcon color="neutral500" width="24px" height="24px" />
            ) : (
              <SupportIcon width="24px" height="24px" />
            )}
          </Flex>

          <StyledSideBarNavigateItemInnerText
            $fontWeight="medium"
            px="14px"
            color="neutral500"
            textScale="body3"
            isDesktopMenuOpen={isDesktopMenuOpen}
          >
            {t("Support")}
          </StyledSideBarNavigateItemInnerText>
        </Flex>
      </StyledSideBarNavigateItem>

      <StyledSideBarNavigateItem isActive={false} justifyContent={isDesktopMenuOpen ? "flex-start" : "center"}>
        <Flex alignItems="center" width="100%" cursor="pointer" onClick={logoutHandler}>
          <Flex>
            <LogoutIcon width="24px" height="24px" />
          </Flex>

          <StyledSideBarNavigateItemInnerText
            $fontWeight="medium"
            px="14px"
            color="neutral500"
            textScale="body3"
            isDesktopMenuOpen={isDesktopMenuOpen}
          >
            {t("Log Out")}
          </StyledSideBarNavigateItemInnerText>
        </Flex>
      </StyledSideBarNavigateItem>
    </Box>
  );
};

export default BottomMenuItems;
