import React, { PropsWithChildren, cloneElement } from "react";
import styled from "styled-components";
import { Box } from "components/layout";

import { NameProps } from "./types";
import { RadioButtonGroupProps } from "./types";

export const StyledRadioButtonGroup = styled(Box)<NameProps>``;

const RadioButtonGroup: React.FC<PropsWithChildren<RadioButtonGroupProps>> = ({
  name,
  selectedValue,
  onClickRadioButton,
  children,
  disabled,
  ...props
}) => {
  return (
    <StyledRadioButtonGroup role="radiogroup" name={name} {...props}>
      {React.Children.map(children, element => {
        return (
          React.isValidElement(element) &&
          cloneElement(element, {
            ...element.props,
            checked: selectedValue === element.props.value,
            onChange: () => onClickRadioButton(element.props),
            disabled,
          })
        );
      })}
    </StyledRadioButtonGroup>
  );
};

RadioButtonGroup.defaultProps = {
  name: "",
  onClickRadioButton: () => {},
  selectedValue: "",
};

export default RadioButtonGroup;
