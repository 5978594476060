import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ ...props }) => {
  return (
    <Svg viewBox="0 0 40 40" {...props}>
      <g clipPath="url(#clip0_3644_74161)">
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.9076 8.23065C9.97964 8.16279 10.0749 8.125 10.1739 8.125H34.701C35.045 8.125 35.2192 8.53928 34.9785 8.78505L29.9547 13.9142C29.8817 13.9887 29.7817 14.0308 29.6773 14.0308H4.72855C4.37697 14.0308 4.20637 13.6008 4.4623 13.3598L9.9076 8.23065ZM29.4672 17.0486C29.3952 16.9808 29.2999 16.943 29.2009 16.943H4.67377C4.32975 16.943 4.15563 17.3573 4.39635 17.603L9.42008 22.7321C9.49312 22.8067 9.59311 22.8488 9.6975 22.8488H34.6462C34.9978 22.8488 35.1684 22.4188 34.9125 22.1778L29.4672 17.0486ZM10.1739 25.7615C10.0749 25.7615 9.97964 25.7993 9.9076 25.8671L4.4623 30.9963C4.20637 31.2373 4.37697 31.6673 4.72855 31.6673H29.6773C29.7817 31.6673 29.8817 31.6252 29.9547 31.5507L34.9785 26.4215C35.2192 26.1758 35.045 25.7615 34.701 25.7615H10.1739Z"
          fill="url(#paint0_linear_3644_74161)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3644_74161"
          x1="31.3507"
          y1="8.125"
          x2="6.3405"
          y2="32.0928"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#15E9AA" />
          <stop offset="1" stopColor="#D426FB" />
        </linearGradient>
        <clipPath id="clip0_3644_74161">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
