import styled from "styled-components";

export const StyledEmptyDataMessage = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  background: ${({ theme }) => theme.colors.transparent};
`;
