import { useEffect, useState } from "react";

import { useTranslation } from "context";
import { useGetFilteredOptions } from "hooks";
import { useAppDispatch } from "store/store";

import { escrowStatusList } from "pages/main/escrows/const";

import { SingleValue } from "react-select";
import { SingleOption } from "components/selects/single-select/types";
import { EscrowStatusEnum } from "store/escrow/enums";
import { setMyEscrowsPayload, setWidgetsListPayload } from "store/escrow/escrows-table";

export const useHandleFilters = ({
  payload,
  isHaveStatusInPayload,
}: {
  payload: {
    dateFrom?: string;
    dateTo?: string;
    status?: string;
  };
  isHaveStatusInPayload: boolean;
}) => {
  const { t } = useTranslation();
  const { options } = useGetFilteredOptions({
    statusList: escrowStatusList,
  });
  const dispatch = useAppDispatch();

  const { statusOptions } = options;
  const { status } = payload;

  const statusRadioOption = statusOptions.map(item => {
    const { value, label } = item;
    return {
      value,
      label,
      name: label ?? t("All Statuses"),
    };
  });

  const presetStatusOption = statusOptions.find(el => el.value === status) ?? statusOptions[0];

  const [selectedStatus, setSelectedStatus] = useState(presetStatusOption);

  useEffect(() => {
    if (isHaveStatusInPayload) {
      if (!status) {
        setSelectedStatus(statusOptions[0]);
      }
    }
  }, [status, isHaveStatusInPayload]);

  const handleSelectedStatus = (selectedOption: SingleValue<SingleOption>) => {
    if (selectedOption) {
      setSelectedStatus({ value: selectedOption.value, label: selectedOption.label });
    }
  };

  const applyHandler = (dates: { dateFrom: string | undefined; dateTo: string | undefined }) => {
    if (isHaveStatusInPayload) {
      dispatch(
        setMyEscrowsPayload({
          page: 1,
          status: selectedStatus.value ? (selectedStatus.value as EscrowStatusEnum) : undefined,
          dateFrom: dates.dateFrom ? dates.dateFrom : undefined,
          dateTo: dates.dateTo ? dates.dateTo : undefined,
        }),
      );
    } else {
      dispatch(
        setWidgetsListPayload({
          page: 1,
          dateFrom: dates.dateFrom ? dates.dateFrom : undefined,
          dateTo: dates.dateTo ? dates.dateTo : undefined,
        }),
      );
    }
  };

  const selectsList = [
    {
      option: statusRadioOption,
      handler: handleSelectedStatus,
      value: selectedStatus,
      name: "Status",
    },
  ];

  return {
    selectsList: isHaveStatusInPayload ? selectsList : [],
    applyHandler,
  };
};
