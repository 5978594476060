import styled from "styled-components";
import QRCode from "react-qr-code";

export const StyledQrContainer = styled(QRCode)`
  max-width: 148px;
  max-height: 148px;
  border: 1px solid ${({ theme }) => theme.colors.neutral400};
  padding: 6px;
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  background-color: ${({ theme }) => theme.isDark && theme.colors.neutral1000};
`;
