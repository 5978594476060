import React from "react";
import moment from "moment";

import { useThemeContext, useTranslation } from "context";
import { useTooltip } from "hooks";
import { getSingleDayPickerTooltipStyles } from "hooks/use-tooltip/theme";

import { StyledDateSelect } from "../styled";
import { Flex, SingleDayPicker, Text } from "components";
import { DropdownIcon } from "components/svg";

import { SingleDateSelectProps } from "./type";

export const SingleDateSelect: React.FC<SingleDateSelectProps> = ({ selectedDay, setSelectedDay, onBlur }) => {
  const { t } = useTranslation();
  const { theme } = useThemeContext();

  const onSelect = (val: Date | undefined) => {
    if (val) {
      setSelectedDay(val);
    }
    toggleToolTip();
  };

  const { targetRef, tooltip, setVisible, insideElementRef } = useTooltip(
    <SingleDayPicker selectedDay={selectedDay} onSelect={onSelect} onUnmountCallback={onBlur} />,
    {
      trigger: "click",
      customStyles: getSingleDayPickerTooltipStyles(theme),
      placement: "bottom-start",
    },
  );

  function toggleToolTip() {
    setVisible(prev => !prev);
  }

  return (
    <Flex position="relative" flexDirection="column">
      <StyledDateSelect width="100%" ref={targetRef}>
        <Flex p="8px" flex="1">
          {selectedDay ? (
            <Text textScale="caption1" $fontWeight="medium" color="neutral600">
              {moment(selectedDay).format("MM/DD/YY")}
            </Text>
          ) : (
            <Text textScale="caption1" $fontWeight="medium" color="neutral600">
              {t("Select Date")}
            </Text>
          )}
        </Flex>

        <Flex height="100%" p="8px" ref={insideElementRef} onClick={toggleToolTip} alignItems="center">
          <DropdownIcon width="12px" />
        </Flex>
      </StyledDateSelect>
      {tooltip}
    </Flex>
  );
};
