import React, { PropsWithChildren, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store/store";
import { useGetSearchParams } from "hooks";

import { ROUTES } from "navigation/routes";
import { SEARCH_PARAMS } from "navigation/search-params";
import { LOCAL_STORAGE_KEYS } from "configs";
import { resetStore } from "services/axios";
import { isErrorResult } from "services";

import { getTokens } from "store/tokens/actions";
import { setTokens } from "store/tokens";
import { savePreview } from "store/escrow/widget/actions";

const RequireAuth: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const isLoggedIn = useAppSelector(state => state.auth.isLoggedIn);
  const user = useAppSelector(state => state.profile.user);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentSearchParams: widgetId } = useGetSearchParams(SEARCH_PARAMS.widgetId);

  useEffect(() => {
    if (widgetId) {
      dispatch(savePreview(widgetId));
      localStorage.setItem(LOCAL_STORAGE_KEYS.widgetId, widgetId);
    }

    const widgetIdLS = localStorage.getItem(LOCAL_STORAGE_KEYS.widgetId);

    if (widgetIdLS) {
      navigate(`/${ROUTES.escrows}/${ROUTES.escrowPreview}/${widgetIdLS}`);
    }

    if (!isLoggedIn) {
      resetStore();
    } else {
      dispatch(getTokens()).then(res => {
        if (!isErrorResult(res.payload)) {
          dispatch(setTokens(res.payload!));
        }
      });
    }
  }, []);

  if (!isLoggedIn) {
    return <Navigate to={"/" + ROUTES.login} replace />;
  } else if (user && user.isBanned) {
    return <Navigate to={"/" + ROUTES.banned} replace />;
  }
  return <>{children}</>;
};

export default RequireAuth;
