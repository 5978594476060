import React, { useEffect, useState } from "react";
import { SingleValue } from "react-select";
import { toast } from "react-toastify";

import { useTranslation } from "context";
import { useValidationSchema, useKYCFieldsFormat } from "./hooks";
import { useForm } from "hooks";

import { Loader, Text, toastOptionsSuccess, toastOptionsError } from "components";
import { IdentityVerificationStep1, IdentityVerificationStep2 } from "./components";
import { StyledModalWrapper } from "../styled";
import { PaginationLeftArrow } from "components/svg";
import CloseModalWrapper from "../close-modal-wrapper";

import { parseErrorFromTrulioo } from "utils/helpers/error-parser";
import { isErrorResult } from "services";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";

import { SingleOption } from "components/selects/single-select/types";
import { getKycCountryCodes, getKycFields, getMyKycStatus, verifyKyc } from "store/kyc/actions";

const IdentityVerificationModal: React.FC = () => {
  const pending = useAppSelector(state => state.kyc.pending);
  const fields = useAppSelector(state => state.kyc.fields);

  const [selectedCountry, setSelectedCountry] = useState<SingleOption | null>(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [isCameraStarted, setIsCameraStarted] = useState(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { fieldsList, configuresData } = useKYCFieldsFormat();
  const stepCount = Object.keys(fieldsList).length;

  const { validationSchema, initialValues } = useValidationSchema(fieldsList);

  const {
    fieldProps,
    handleSubmit,
    errors,
    isValid,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    resetForm,
    validateForm,
  } = useForm({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit(values) {
      submitFormHandler(values);
    },
  });

  useEffect(() => {
    dispatch(getKycCountryCodes());
  }, []);

  // this effect revalidate form because by default initialValues is {} and it's isValid is always true
  useEffect(() => {
    validateForm();
  }, [fieldsList]);

  const submitFormHandler = (formValues: typeof values) => {
    const data = configuresData(formValues);
    const params = { countryCode: selectedCountry!.value };

    dispatch(verifyKyc({ params, data })).then(res => {
      if (!isErrorResult(res.payload)) {
        let truliooErrors = "";

        Object.values(res.payload!).forEach(value => {
          if (value.status !== "ok") {
            const error = parseErrorFromTrulioo(value);
            if (error) {
              truliooErrors += error;
            }
          }
        });

        if (truliooErrors) {
          toast.error(truliooErrors, toastOptionsError);
        } else {
          closeModalHandler();
          toast.success(t("Your KYC was submitted"), toastOptionsSuccess);
          dispatch(getMyKycStatus());
        }
      }
    });
  };

  const handleCountryChange = (selectedOption: SingleValue<SingleOption | null>) => {
    if (selectedOption) {
      setSelectedCountry({ value: selectedOption.value, label: selectedOption.label });
      dispatch(getKycFields({ countryCode: selectedOption.value }));
    } else {
      setSelectedCountry(null);
    }
  };

  const onBackClick = () => {
    setSelectedCountry(null);
    setCurrentStep(1);
    resetForm();
  };

  const closeModalHandler = () => {
    resetForm();
    dispatch(hideModal());
  };

  const checkIsFieldsEmpty = () => {
    return Object.values(fields).every(val => !val);
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler}>
        <>
          {isCameraStarted && (
            <PaginationLeftArrow
              width="24px"
              height="24px"
              cursor="pointer"
              onClick={() => setIsCameraStarted(false)}
            />
          )}
        </>
      </CloseModalWrapper>

      {pending ? (
        <Loader height="200px" />
      ) : selectedCountry && checkIsFieldsEmpty() ? (
        <Text textScale="body2" color="neutral600" textAlign="center" my="16px">
          {t("Sorry, no fields for selected country to confirm, please select another one")}
        </Text>
      ) : !selectedCountry ? (
        <IdentityVerificationStep1
          selectedOption={selectedCountry}
          onSelectChange={option => handleCountryChange(option as SingleOption)}
        />
      ) : (
        <>
          {Object.entries(fieldsList).map(([key, _fieldsList], index) => {
            return index + 1 === currentStep ? (
              <IdentityVerificationStep2
                key={key}
                type={key}
                fieldsList={fieldsList}
                errors={errors}
                touched={touched}
                values={values}
                isValid={isValid}
                stepCount={stepCount}
                currentStep={currentStep}
                pending={pending}
                isCameraStarted={isCameraStarted}
                fieldProps={fieldProps}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                handleSubmit={handleSubmit}
                setCurrentStep={setCurrentStep}
                backToStep1={onBackClick}
                setIsCameraStarted={setIsCameraStarted}
              />
            ) : null;
          })}
        </>
      )}
    </StyledModalWrapper>
  );
};

export default IdentityVerificationModal;
