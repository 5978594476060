import React from "react";

import AppleSignin from "react-apple-signin-auth";

import { useTranslation } from "context";
import { useHandleData } from "./hooks";

import { Button, FlexGap } from "components";
import { AppleIcon, GoogleIcon, LoginWithYubikeyIcon } from "components/svg";

import { LoginApplePayload } from "store/auth/types";

import { AppleSigninRenderProps, AdditionalLoginMethodsProps, ButtonPrefixEnum } from "./types";

const AdditionalLoginMethods: React.FC<AdditionalLoginMethodsProps> = ({
  textButtonPrefix,
  pingOneResponse,
  status,
  onGoogleLoginHandler,
  onAppleLoginHandler,
  redirectURI,
}) => {
  const { t } = useTranslation();

  const { appleClientId, pending, onGoogleLoginClick, onAppleSuccess, onYubikeyLoginClick } = useHandleData({
    pingOneResponse,
    status,
    onGoogleLoginHandler,
    onAppleLoginHandler,
    redirectURI,
  });

  const renderAppleButton = ({ onClick }: AppleSigninRenderProps) => {
    return (
      <Button
        width="100%"
        px="6px"
        variant="outline"
        isLoading={pending}
        startIcon={<AppleIcon width="24px" />}
        onClick={onClick}
      >
        {t(`${textButtonPrefix} %with%`, { with: t("Apple") })}
      </Button>
    );
  };

  return (
    <FlexGap flexDirection="column" justifyContent="space-between" gap="8px">
      <Button
        width="100%"
        px="6px"
        variant="outline"
        isLoading={pending}
        startIcon={<GoogleIcon width="24px" />}
        onClick={onGoogleLoginClick}
      >
        {t(`${textButtonPrefix} %with%`, { with: t("Google") })}
      </Button>

      <AppleSignin
        authOptions={{
          clientId: appleClientId,
          scope: "name email",
          redirectURI: redirectURI,
          usePopup: true,
        }}
        onSuccess={(res: LoginApplePayload) => onAppleSuccess({ ...res, pingOneResponse })}
        render={renderAppleButton}
      />

      {textButtonPrefix === ButtonPrefixEnum.logIn && (
        <Button
          width="100%"
          variant="outline"
          isLoading={pending}
          startIcon={<LoginWithYubikeyIcon width="24px" />}
          onClick={onYubikeyLoginClick}
        >
          {t(`${textButtonPrefix} %with%`, { with: t("Yubikey") })}
        </Button>
      )}
    </FlexGap>
  );
};

export default AdditionalLoginMethods;
