import React from "react";

import { Box, Flex, Image, Text, WalletErrorMessage } from "components";
import { StyledButton } from "./styled";
import { SpinnerIcon } from "components/svg";

import { useTranslation } from "context";

import { SHORT_CHAIN_NAMES, NATIVE_CURRENCIES_LOGO } from "configs/web3";

import { ChooseNetworkProps } from "./types";

const ChooseNetwork: React.FC<ChooseNetworkProps> = ({
  chainId,
  isDifferentNetwork,
  isUnsupportedNetwork,
  isLoading,
  onButtonClick,
}) => {
  const { t } = useTranslation();

  return (
    <Box mt={{ _: "18px", tablet: "24px" }}>
      <Text textScale="caption1" mb="8px" color="neutral600">
        {t("Network")}
      </Text>
      <StyledButton isLoading={isLoading} isError={isUnsupportedNetwork || isDifferentNetwork} onClick={onButtonClick}>
        {isLoading ? (
          <Flex flex="1" justifyContent="center">
            <SpinnerIcon color="neutral300" />
          </Flex>
        ) : (
          <>
            {chainId ? (
              <Flex alignItems="center">
                <Image
                  src={NATIVE_CURRENCIES_LOGO[chainId]}
                  alt={SHORT_CHAIN_NAMES[chainId]}
                  width="24px"
                  aspectRatio={1}
                  variant="circle"
                />
                <Text textScale="caption1" $fontWeight="bold" color="neutral800" mx="10px">
                  {SHORT_CHAIN_NAMES[chainId]}
                </Text>
              </Flex>
            ) : (
              <Text textScale="caption1" $fontWeight="bold" color="neutral800">
                {t("Choose Network")}
              </Text>
            )}
          </>
        )}
      </StyledButton>

      {isUnsupportedNetwork && !isLoading ? (
        <WalletErrorMessage mt="12px" message={t("Network in a wallet is not supported")} />
      ) : null}

      {isDifferentNetwork && !isLoading ? (
        <WalletErrorMessage
          mt="12px"
          message={t(
            "The network selected in your external wallet does not match the network selected on the Platform ",
          )}
        />
      ) : null}
    </Box>
  );
};

export default ChooseNetwork;
