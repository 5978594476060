import { generatePath, matchPath, useLocation, useParams } from "react-router-dom";

import { MATCH_ROUTES } from "../constants";

import { useAppSelector } from "store/store";

import { ChainId, SUPPORTED_ETH_CHAIN_ID, isSupportedChain } from "configs/web3";
import { getTokenFromTokenKey } from "utils/helpers";

import { BEToken } from "store/wallets/types";

export const useGenerateRoutes = () => {
  const allTokensList = useAppSelector(state => state.tokensList.allTokensList);

  const params = useParams();
  const { pathname } = useLocation();

  const pathPattern = MATCH_ROUTES.find(pattern => matchPath(pattern, pathname));

  const generateRouteForNetworkChange = (chainId: ChainId) => {
    const supportedChainId = isSupportedChain(chainId) ? chainId : SUPPORTED_ETH_CHAIN_ID;
    const newToken = getTokenForRoute(supportedChainId);

    return generatePath(pathPattern!, { ...params, network: chainId, token: newToken });
  };

  const getTokenForRoute = (chainId: ChainId) => {
    const currentToken = params.token && getTokenFromTokenKey(allTokensList, chainId, params.token);

    const defaultToken = allTokensList && allTokensList[chainId] ? Object.keys(allTokensList[chainId])[0] : "";
    return currentToken ? params.token : defaultToken;
  };

  const generateRouteForTokenChange = (token: BEToken) => {
    return generatePath(pathPattern!, { ...params, token: token.publicCode });
  };

  const generateRouteForPageRefresh = (chainId: ChainId, tokenKey: string) => {
    return generatePath(pathPattern!, { ...params, network: chainId, token: tokenKey });
  };

  return { generateRouteForNetworkChange, generateRouteForTokenChange, generateRouteForPageRefresh };
};
