import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { tabsIndex } from "configs/tabs";

import { getMyEscrowsList, getWidgetList, getWidgetBuyersList } from "./action";

import {
  EscrowsTableState,
  MyEscrowsPayload,
  MyEscrowsSingleItem,
  WidgetBuyersListPayload,
  WidgetListPayload,
} from "./types";

const initialState: EscrowsTableState = {
  error: null,
  activeTab: tabsIndex.escrows.myEscrows,
  myEscrowsListData: {
    pending: false,
    data: [],
    count: 0,
  },
  myEscrowsListPayload: {
    page: 1,
    size: 10,
  },
  widgetsListData: {
    pending: false,
    data: [],
    count: 0,
  },
  widgetsListPayload: {
    page: 1,
    size: 10,
  },
  widgetBuyersListData: {
    pending: false,
    data: [],
    count: 0,
  },
  widgetBuyersListPayload: {
    page: 1,
    size: 10,
    id: "",
  },
};

const escrowsTableSlice = createSlice({
  name: "escrowsTable",
  initialState: {
    ...initialState,
  },
  reducers: {
    resetEscrowsTable: () => {
      return initialState;
    },
    resetWidgetBuyersList: state => {
      state.widgetBuyersListPayload = initialState.widgetBuyersListPayload;
    },
    setMyEscrowsPayload: (state, action: PayloadAction<Partial<MyEscrowsPayload>>) => {
      state.myEscrowsListPayload = { ...state.myEscrowsListPayload, ...action.payload };
    },
    setWidgetsListPayload: (state, action: PayloadAction<Partial<WidgetListPayload>>) => {
      state.widgetsListPayload = { ...state.widgetsListPayload, ...action.payload };
    },
    setWidgetsBuyersListPayload: (state, action: PayloadAction<Partial<WidgetBuyersListPayload>>) => {
      state.widgetBuyersListPayload = { ...state.widgetBuyersListPayload, ...action.payload };
    },
    setEscrowsTableTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
    addNewEscrow: (state, action: PayloadAction<MyEscrowsSingleItem>) => {
      const newMyEscrowsListData = [...state.myEscrowsListData.data];

      if (state.myEscrowsListPayload.page === 1) {
        newMyEscrowsListData.unshift(action.payload);

        if (newMyEscrowsListData.length > state.myEscrowsListPayload.size) {
          newMyEscrowsListData.pop();
        }

        state.myEscrowsListData.data = newMyEscrowsListData;
      }
    },
    updateEscrowList: (state, action: PayloadAction<MyEscrowsSingleItem>) => {
      const newEscrowList = state.myEscrowsListData.data.map(escrowItem => {
        if (escrowItem.body.id === action.payload.body.id) {
          return action.payload;
        }
        return escrowItem;
      });

      state.myEscrowsListData = { ...state.myEscrowsListData, data: newEscrowList };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getMyEscrowsList.fulfilled, (state, action) => {
        state.myEscrowsListData = { ...action.payload, pending: false };
        state.error = null;
      })
      .addCase(getMyEscrowsList.pending, state => {
        state.myEscrowsListData.pending = true;
      })
      .addCase(getMyEscrowsList.rejected, (state, action) => {
        if (action.payload) {
          state.myEscrowsListData = initialState.myEscrowsListData;
          state.error = action.payload;
        }
      })
      .addCase(getWidgetList.fulfilled, (state, action) => {
        state.widgetsListData = { ...action.payload, pending: false };
        state.error = null;
      })
      .addCase(getWidgetList.pending, state => {
        state.widgetsListData.pending = true;
      })
      .addCase(getWidgetList.rejected, (state, action) => {
        if (action.payload) {
          state.widgetsListData = initialState.widgetsListData;
          state.error = action.payload;
        }
      })
      .addCase(getWidgetBuyersList.fulfilled, (state, action) => {
        state.widgetBuyersListData = { ...action.payload, pending: false };
        state.error = null;
      })
      .addCase(getWidgetBuyersList.pending, state => {
        state.widgetBuyersListData.pending = true;
      })
      .addCase(getWidgetBuyersList.rejected, (state, action) => {
        if (action.payload) {
          state.widgetBuyersListData = initialState.widgetBuyersListData;
          state.error = action.payload;
        }
      });
  },
});

export const {
  resetEscrowsTable,
  setEscrowsTableTab,
  setMyEscrowsPayload,
  setWidgetsListPayload,
  setWidgetsBuyersListPayload,
  resetWidgetBuyersList,
  addNewEscrow,
  updateEscrowList,
} = escrowsTableSlice.actions;
export default escrowsTableSlice;
