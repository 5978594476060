import React, { useEffect } from "react";

import { useThemeContext, useTranslation } from "context";
import { useValidationSchema, MIN_LENGTH, useHandleData } from "./hooks";
import { useForm, useCopyContent } from "hooks";

import { Flex, Text, InputCode, InputGroup, Button, Box, StyledLink, Column, StyledQr, Loader } from "components";

import { useAppDispatch, useAppSelector } from "store/store";
import { generate2FASecret } from "store/profile/actions";

import { TwoFactorSetupProps } from "./types";

const TwoFactorSetup: React.FC<TwoFactorSetupProps> = ({ title, text, successCallback, showErrorToaster = false }) => {
  const pendingVerify = useAppSelector(state => state.profile.twoFactorAuth.pendingVerify);
  const secret = useAppSelector(state => state.profile.twoFactorAuth.secret);
  const pendingGenerate = useAppSelector(state => state.profile.twoFactorAuth.pendingGenerate);

  const { theme } = useThemeContext();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const qrCodeString = secret?.otpauth_url ?? t("Code wasn't generated properly. Please contact support.");

  const { copyContentHandler } = useCopyContent();

  const { validationSchema, initialValues } = useValidationSchema();

  const { handleSubmit, setFieldValue, setFieldError, setFieldTouched, errors, isValid, touched, values } = useForm({
    initialValues,
    validationSchema,
    onSubmit(values) {
      submitHandler(values);
    },
  });

  const { submitHandler, onUserInput } = useHandleData({
    setFieldValue,
    setFieldError,
    successCallback,
    showErrorToaster,
  });

  useEffect(() => {
    dispatch(generate2FASecret());
  }, []);

  return (
    <>
      {pendingGenerate ? (
        <Loader height="200px" />
      ) : (
        <Box>
          <Text textAlign="center" textScale="body2">
            {title}
          </Text>

          <Text color="neutral600" mt="24px" mb={{ _: "24px", laptop: "40px" }} textScale="caption1" width="100%">
            {!secret?.otpauth_url ? t("Code wasn't generated properly. Please contact support.") : text}
          </Text>

          <Column alignItems="center">
            <StyledQr
              fgColor={secret?.otpauth_url && !theme.isDark ? theme.colors.neutral1000 : theme.colors.neutral200}
              value={qrCodeString}
            />

            {secret?.base32 && (
              <StyledLink
                mt="12px"
                color="primary"
                onClick={() => copyContentHandler(secret?.base32 ?? "")}
                disabled={!secret?.otpauth_url}
                textScale="caption2"
                $fontWeight="bold"
                textAlign="center"
              >
                {t("Copy Code")}
              </StyledLink>
            )}
          </Column>

          <Text color="neutral600" mt="44px" mb="18px" textScale="caption1" width="100%">
            {t("Enter the %number%-digit authentication code", { number: MIN_LENGTH })}
          </Text>

          <Box width="100%">
            <form onSubmit={handleSubmit}>
              <InputGroup error={errors.code} isTouched={touched.code}>
                <InputCode
                  cellCount={MIN_LENGTH}
                  onUserInput={onUserInput}
                  value={values.code}
                  onFocus={async () => await setFieldTouched("code", false)}
                  onBlur={async () => await setFieldTouched("code", true)}
                  // Logic for formik was designed not properly for onBlur and onFocus events
                  // to prevent rewrite all logic though the app logic for isFocused prop is vise versa
                  // onFocus we mark the field as not touched and onBlur we mark it as touched
                  isFocused={touched.code === false}
                  isError={!!errors.code && !!touched.code}
                  disabled={!secret?.otpauth_url}
                  cellHeight={{ _: "36px", tablet: "44px" }}
                  cellWidth={{ _: "36px", tablet: "44px" }}
                />
              </InputGroup>

              <Flex alignItems="center" mt="40px" width="100%">
                <Button
                  width="100%"
                  type="submit"
                  disabled={!isValid || !secret?.otpauth_url}
                  isLoading={pendingVerify}
                >
                  {t("Next")}
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TwoFactorSetup;
