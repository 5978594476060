import styled from "styled-components";

export const StyledButton = styled.div<{ isError?: boolean; isLoading?: boolean }>`
  width: 100%;
  cursor: ${({ isLoading }) => (isLoading ? "not-allowed" : "pointer")};
  border: 1px solid
    ${({ theme, isError, isLoading }) =>
      isError ? theme.colors.red : isLoading ? theme.colors.neutral200 : theme.colors.neutral400};
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  padding: 10px 14px;
`;
