import React, { useEffect } from "react";
import moment from "moment";

import { useTranslation } from "context";
import { useAppDispatch, useAppSelector } from "store/store";
import { useMatchBreakpoints } from "hooks";

import CloseModalWrapper from "../close-modal-wrapper";
import { StyledModalWrapper } from "../styled";
import { Box, Button, Flex, FlexGap, Skeleton, Text } from "components";
import { StyledClicableYubikeyText, StyledYubikeysListModalInnerContainer } from "./styled";
import { SmallYubikeyIcon } from "components/svg";

import { YUBIKEY_LIST_MAX_CAPACITY } from "./constants";

import { hideModal, showModal } from "store/modal";
import { getYubikeyKeysList } from "store/yubikey/actions";
import { DeleteYubikeyModalProps } from "../delete-yubikey-modal/types";
import { ModalProps } from "store/modal/types";

const YubikeysListModal: React.FC = () => {
  const data = useAppSelector(state => state.yubikey.yubikeyKeysListData.data);
  const total = useAppSelector(state => state.yubikey.yubikeyKeysListData.total);
  const pending = useAppSelector(state => state.yubikey.yubikeyKeysListData.pending);

  const { t } = useTranslation();
  const { isMobile } = useMatchBreakpoints();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getYubikeyKeysList());
  }, []);

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const addNewDeviceHandler = () => {
    dispatch(showModal({ modalName: "addYourYubikeyModal", rootId: "settings" }));
  };

  const removeDeviceHandler = ({ id, deviceName }: DeleteYubikeyModalProps) => {
    const _showModal = showModal as ModalProps<DeleteYubikeyModalProps>;

    dispatch(_showModal({ modalName: "deleteYubikeyModal", rootId: "settings", props: { id, deviceName } }));
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler}>
        {isMobile ? null : (
          <Text $fontWeight="medium" textScale="body2" color="neutral600">
            {t("Manage your Yubikeys")}
          </Text>
        )}
      </CloseModalWrapper>

      {isMobile ? (
        <Text textScale="body2" color="neutral600" textAlign="center">
          {t("Manage your Yubikeys")}
        </Text>
      ) : null}

      <Box width="100%" mt={{ _: "18px", tablet: "24px" }}>
        <StyledYubikeysListModalInnerContainer width="100%" flexDirection="column" gap="18px">
          {pending
            ? Array.from({ length: 3 }).map((_, index) => {
                return (
                  <Box key={index}>
                    <Skeleton height="172px" width="100%" />
                  </Box>
                );
              })
            : data.map(({ createdAt, updatedAt, name, id }, index) => (
                <FlexGap
                  flexDirection="column"
                  gap="18px"
                  borderRadius="8px"
                  border="1px solid"
                  borderColor="neutral300"
                  key={index}
                  p="18px"
                >
                  <FlexGap gap="8px" onClick={closeModalHandler}>
                    <SmallYubikeyIcon width="24px" />

                    <Text textScale="body3" $fontWeight="medium" color="neutral600">
                      {name ?? "Yubikey Primary"}
                    </Text>
                  </FlexGap>

                  <FlexGap flexDirection="column" gap="12px">
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text textScale="caption2" color="neutral500" $fontWeight="medium">
                        {t("Added")}
                      </Text>

                      <Text textScale="body3" textAlign="right" color="neutral600">
                        {moment(createdAt).format("MM/DD/YY HH:mm")}
                      </Text>
                    </Flex>

                    <Flex justifyContent="space-between" alignItems="center">
                      <Text textScale="caption2" color="neutral500" $fontWeight="medium">
                        {t("Last used")}
                      </Text>

                      <Text textScale="body3" textAlign="right" color="neutral600">
                        {moment(updatedAt).format("MM/DD/YY HH:mm")}
                      </Text>
                    </Flex>
                  </FlexGap>

                  <Box
                    cursor="pointer"
                    width="fit-content"
                    onClick={() => removeDeviceHandler({ id, deviceName: name ?? "Yubikey Primary" })}
                  >
                    <StyledClicableYubikeyText textScale="button" color="red" $fontWeight="medium">
                      {t("Remove device")}
                    </StyledClicableYubikeyText>
                  </Box>
                </FlexGap>
              ))}
        </StyledYubikeysListModalInnerContainer>

        {total >= YUBIKEY_LIST_MAX_CAPACITY ? null : (
          <Box width="100%" pt="18px" onClick={addNewDeviceHandler}>
            <Button variant="outline" width="100%">
              {t("+ Add new device")}
            </Button>
          </Box>
        )}
      </Box>
    </StyledModalWrapper>
  );
};

export default YubikeysListModal;
