import React from "react";
import { components, DropdownIndicatorProps } from "react-select";

import { DropdownIcon } from "components/svg";
import { Text } from "components";
import { StyledSingleSelectWrapper, StyledSingleSelect, InputError } from "./styled";

import { useTranslation } from "context";

import { SingleOption, SingleSelectProps } from "./types";

const SingleSelect: React.FC<SingleSelectProps> = ({
  options,
  error,
  isTouched,
  placeholder,
  noOptionsText,
  value = null,
  isSearchable = false,
  label,
  onChange,
  onBlur,
  withError = false,
  disabled = false,
  ...props
}) => {
  const { DropdownIndicator: CustomDropdownIndicator } = components;

  const { t } = useTranslation();

  const DropdownIndicator: React.ComponentType<DropdownIndicatorProps> = props => {
    return (
      <CustomDropdownIndicator {...props}>
        <DropdownIcon width="12px" />
      </CustomDropdownIndicator>
    );
  };
  // onBlur is a mandatory field if u work with formik
  // Please pass it like this
  // onBlur={async () => await setFieldTouched(fieldName, true)}
  return (
    <StyledSingleSelectWrapper disabled={disabled} {...props}>
      {label && (
        <Text mb="8px" textScale="caption1" color="neutral500">
          {label}
        </Text>
      )}

      <StyledSingleSelect
        isDisabled={disabled}
        placeholder={placeholder ?? t("Select...")}
        classNamePrefix="select-item"
        options={options}
        isSearchable={isSearchable}
        value={value}
        // this entry fixes a bug with verification on mobile devices
        onChange={async value => {
          await onChange(value as SingleOption);
          await onBlur?.();
        }}
        onBlur={onBlur}
        noOptionsMessage={() => noOptionsText ?? t("No values available at the moment")}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator,
        }}
      />
      {withError && <InputError textScale="caption2">{error && isTouched ? error : " "}</InputError>}
    </StyledSingleSelectWrapper>
  );
};

export default SingleSelect;
