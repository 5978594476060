import { Loader } from "components";
import { lazyLoad } from "utils/loadable";

export { default as Role } from "./role";
export { default as EscrowInfo } from "./escrow-info";
export { default as Currency } from "./currency";
export { default as Preview } from "./preview";

export const EscrowHeader = lazyLoad(
  () => import("./escrow-header"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const WidgetPreview = lazyLoad(
  () => import("./widget-preview"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const EscrowPaymentsInfo = lazyLoad(
  () => import("components/escrow/escrow-payments-info"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const CreateEscrowStepWrapper = lazyLoad(
  () => import("./create-escrow-step-wrapper"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);
