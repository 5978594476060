import { LabelWithIcon } from "components";

import { useTranslation } from "context";

import { useAppSelector } from "store/store";

import { SUPPORTED_CHAINS, NATIVE_CURRENCIES_LOGO, SHORT_CHAIN_NAMES } from "configs/web3";
import { getUniqueTokenListByKey } from "configs/web3/utils/get-token-list";

import { SingleOption } from "components/selects/single-select/types";

export const useGetFilteredOptions = (filterParams?: {
  typeList?: SingleOption[];
  statusList?: SingleOption[];
  chainId?: string;
}) => {
  const allTokensList = useAppSelector(state => state.tokensList.allTokensList);

  const { t } = useTranslation();

  const typeOptions = filterParams?.typeList ?? [];
  const statusOptions = filterParams?.statusList ?? [];
  const filterParamsChainId = filterParams?.chainId;

  const networksOptions: SingleOption[] = SUPPORTED_CHAINS.map(network => {
    return {
      value: network.toString(),
      label: <LabelWithIcon text={SHORT_CHAIN_NAMES[network]} src={NATIVE_CURRENCIES_LOGO[network]} />,
    };
  });

  const currencyOptions: SingleOption[] = getUniqueTokenListByKey(allTokensList, filterParamsChainId).map(item => {
    const [key, value] = Object.entries(item)[0];

    return {
      value: key,
      label: <LabelWithIcon text={value.name} subText={value.symbol} src={value.logoURI} />,
    };
  });

  //If you don`t needed default options, you can slice first element of array
  return {
    options: {
      typeOptions: [
        {
          value: "",
          label: t("All Types"),
        },
        ...typeOptions,
      ],
      networksOptions: [
        {
          value: "",
          label: t("All Networks"),
        },
        ...networksOptions,
      ],
      currencyOptions: [
        {
          value: "",
          label: t("All Currencies"),
        },
        ...currencyOptions,
      ],
      statusOptions: [
        {
          value: "",
          label: t("All Statuses"),
        },
        ...statusOptions,
      ],
    },
  };
};
