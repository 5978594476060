export const OPTION_DELETE_YUBIKEY = [
  {
    numberOfOption: 1,
    text: "Add another Yubikey device before deleting the existing one. This will ensure you have a backup security method in place, providing an extra layer of protection.",
  },
  {
    numberOfOption: 2,
    text: "If you choose to proceed with the deletion without adding another Yubikey, your Security Settings will revert to the default configuration. In this case, make sure you have access to the Google Auth app, as it will be required for logging in and withdrawing funds",
  },
];
