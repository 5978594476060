import styled from "styled-components";
import { space } from "styled-system";

export const Separator = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral1000};
  display: flex;
  justify-content: center;
  padding-top: 4px;
  padding-bottom: 4px;
`;

export const StyledBreadcrumbs = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  row-gap: 10px;

  ${space}
`;

export const StyledBreadcrumbsItem = styled.li`
  overflow: hidden;
`;
