import React from "react";

import { Flex } from "components";
import { StyledMicrophone } from "./styled";
import { MicrophoneIcon } from "components/svg";

const Microphone: React.FC = () => {
  return (
    <Flex width="100%" justifyContent="center">
      <StyledMicrophone>
        <MicrophoneIcon width="60px" />
      </StyledMicrophone>
    </Flex>
  );
};

export default Microphone;
