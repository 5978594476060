import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { tabsIndex } from "configs/tabs";

import { estimateEscrowFee, getParticipant, createEscrow, createWidget, getEscrowMinAmount } from "./actions";

import { CreateEscrowState } from "./types";

const initialState: CreateEscrowState = {
  error: null,
  pending: false,
  roleTab: tabsIndex.createEscrow.buyer,
  coversBrokersCommissionTab: tabsIndex.createEscrowCommissionPayer.buyer,
  coversEscryptoFeeTab: tabsIndex.createEscrowCommissionPayer.buyer,
  minAmount: {
    withBroker: {
      inFiat: "0",
      inUsd: "0",
    },
    withoutBroker: {
      inFiat: "0",
      inUsd: "0",
    },
  },
};

const createEscrowSlice = createSlice({
  name: "createEscrow",
  initialState: {
    ...initialState,
  },
  reducers: {
    resetCreateEscrow: () => {
      return initialState;
    },
    setRoleTab: (state, action: PayloadAction<number>) => {
      state.roleTab = action.payload;
    },
    setCoversBrokersCommissionTab: (state, action: PayloadAction<number>) => {
      state.coversBrokersCommissionTab = action.payload;
    },
    setCoversEscryptoFeeTab: (state, action: PayloadAction<number>) => {
      state.coversEscryptoFeeTab = action.payload;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(estimateEscrowFee.fulfilled, state => {
        state.pending = false;
        state.error = null;
      })
      .addCase(estimateEscrowFee.pending, state => {
        state.pending = true;
        state.error = null;
      })
      .addCase(estimateEscrowFee.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(getParticipant.fulfilled, state => {
        state.pending = false;
        state.error = null;
      })
      .addCase(getParticipant.pending, state => {
        state.pending = true;
      })
      .addCase(getParticipant.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(createEscrow.fulfilled, state => {
        state.pending = false;
        state.error = null;
      })
      .addCase(createEscrow.pending, state => {
        state.pending = true;
      })
      .addCase(createEscrow.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(createWidget.fulfilled, state => {
        state.pending = false;
        state.error = null;
      })
      .addCase(createWidget.pending, state => {
        state.pending = true;
      })
      .addCase(createWidget.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(getEscrowMinAmount.fulfilled, (state, action) => {
        state.minAmount = action.payload;

        state.pending = false;
        state.error = null;
      })
      .addCase(getEscrowMinAmount.pending, state => {
        state.pending = true;
      })
      .addCase(getEscrowMinAmount.rejected, (state, action) => {
        if (action.payload) {
          state.minAmount = initialState.minAmount;

          state.error = action.payload;
          state.pending = false;
        }
      });
  },
});

export const { resetCreateEscrow, setRoleTab, setCoversBrokersCommissionTab, setCoversEscryptoFeeTab } =
  createEscrowSlice.actions;
export default createEscrowSlice;
