import React from "react";

import { useTranslation } from "context";

import { Row, Text, Link } from "components";

import { ROUTES } from "navigation/routes";

const TermsOfUse: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Row my="20px" justifyContent={{ _: "center", laptop: "flex-start" }}>
      <Text width="100%" textAlign={{ _: "center", tablet: "left" }}>
        <Text textScale="caption2" color="neutral500" as="span">
          {t("By continuing, you agree to")}
        </Text>{" "}
        <Link
          underline
          external
          href={`/${ROUTES.termsAndConditions}`}
          target="_blank"
          rel="noreferrer"
          textScale="caption2"
          display="inline-flex"
        >
          {t("Terms of Use")}
        </Link>{" "}
        <Text textScale="caption2" color="neutral500" as="span">
          and
        </Text>{" "}
        <Link
          underline
          external
          href={`/${ROUTES.privacyPolicy}`}
          target="_blank"
          rel="noreferrer"
          textScale="caption2"
          display="inline-flex"
        >
          {t("Privacy Policy")}
        </Link>
      </Text>
    </Row>
  );
};

export default TermsOfUse;
