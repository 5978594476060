import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";
import { EscrowConfirmPaymentModalProps } from "./types";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => state.escrowProcess.currentEscrow,
    (state: AppState) => state.escrowProcess.pending,
    (state: AppState) => (state.modal.props as unknown as EscrowConfirmPaymentModalProps).amountToPay,
    (state: AppState) => (state.modal.props as unknown as EscrowConfirmPaymentModalProps).symbol,
    (state: AppState) => (state.modal.props as unknown as EscrowConfirmPaymentModalProps).amountInFiat,
    (state: AppState) => (state.modal.props as unknown as EscrowConfirmPaymentModalProps).timer,
  ],
  (currentEscrow, pending, amountToPay, symbol, amountInFiat, timer) => ({
    currentEscrow,
    pending,
    amountToPay,
    symbol,
    amountInFiat,
    timer,
  }),
);
