import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";
import { SwapNetworkTokenProps } from "./types";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => state.swap,
    (state: AppState) => (state.modal.props as unknown as SwapNetworkTokenProps).swapType,
    (state: AppState) => (state.modal.props as unknown as SwapNetworkTokenProps).resetAmount,
    (state: AppState) => state.hotWallets.networks,
  ],
  (swap, swapType, resetAmount, networks) => ({
    swap,
    swapType,
    resetAmount,
    networks,
  }),
);
