import { toast } from "react-toastify";

import { toastOptionsError } from "components";

import { useShow2FaModal } from "../";

import { useTranslation } from "context";

import { partialUpdateModalProps } from "store/modal";
import store, { useAppDispatch } from "store/store";
import { checkVoiceQuality } from "store/verify-biometric/actions";
import { setIsVoiceRecord } from "store/verify-biometric";
import { confirmWithdraw } from "store/wallets/cold-wallets/actions";

import { isErrorResult } from "services";
import { mitekActions, voiceCaptureOptions } from "configs";
import { convertToMaybeBase64 } from "utils/helpers";

import { PartialUpdateModalProps } from "store/modal/types";
import { VoiceQualityPayload, VoiceTransactionTypeEnum } from "store/verify-biometric/types";
import { MitekVerifyError, VoiceResponseData } from "types/mitek-face-verify";
import { VoiceCaptureModalProps, microphoneStatusEnum } from "components/modal/components/voice-capture-modal/types";
import { MitekSDK } from "hooks/use-get-mitek-sdk/types";
import { VerificationData } from "store/wallets/cold-wallets/types";

const useHandleVerifyStatus = (mitekScienceSDK: MitekSDK | undefined) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { callSuccessModal, handleVerifyCallback, showErrorModal, showLoadingModal } = useShow2FaModal();

  const handleErrorModal = (key?: string) => {
    showErrorModal(key, { callback: () => handleVerifyCallback(stopMitekSDK, "voice"), title: t("Verify with codes") });
  };

  const handleVoiceVerify = (verificationData: VerificationData = {}) => {
    const _partialProps = partialUpdateModalProps as PartialUpdateModalProps<VoiceCaptureModalProps>;

    // when microphone sdk is loaded, we show microphone button
    mitekScienceSDK?.on(mitekActions.voiceProcessingStarted, () => {
      dispatch(_partialProps({ microphoneStatus: microphoneStatusEnum.LOADED }));
    });

    // when we click microphone button, recording start
    mitekScienceSDK?.on(mitekActions.voiceRecordingStarted, () => {
      dispatch(setIsVoiceRecord(false));

      dispatch(_partialProps({ microphoneStatus: microphoneStatusEnum.RECORDING }));
    });
    // start mitek voice sdk
    mitekScienceSDK?.cmd(mitekActions.captureAndProcessVoice, {
      mitekSDKPath: "mitekSDK/",
      options: {
        ...voiceCaptureOptions,
      },
    });

    // when error appear, whe show error modal
    mitekScienceSDK?.on(mitekActions.sdkError, (err: MitekVerifyError) => {
      const isShowErrorModal = store.getState().verifyBiometric.isShowErrorModal;
      if (isShowErrorModal) {
        handleErrorModal(err.type);
      }
    });

    checkRecordingVoiceResult(verificationData);
  };

  const checkRecordingVoiceResult = (verificationData: VerificationData) => {
    mitekScienceSDK?.on(mitekActions.voiceCaptureResult, ({ response }: VoiceResponseData) => {
      dispatch(setIsVoiceRecord(true));
      stopMitekSDK();

      const isShowErrorModal = store.getState().verifyBiometric.isShowErrorModal;

      if (response.status === "success" && isShowErrorModal) {
        const data = convertToMaybeBase64(response.audioData);
        const sendData: VoiceQualityPayload = {
          transactionType: VoiceTransactionTypeEnum.ENROLL,
          voiceFeature: { data },
        };
        // we send record data to mitek, and check quality status
        dispatch(checkVoiceQuality(sendData)).then(res => {
          if (!isErrorResult(res.payload)) {
            const { status } = res.payload!;
            if (status === "Pass") {
              showLoadingModal({ verifyType: "voice" });
              const { withdrawPayload } = store.getState().coldWallets;
              const verifyData = {
                ...withdrawPayload!.withdrawPayload,
                verificationData: { ...verificationData, voiceFeature: { data } },
              };
              // if quality is good, we confirm withdraw
              dispatch(confirmWithdraw({ mitek: verifyData })).then(res => {
                if (isErrorResult(res.payload)) {
                  const errorKey = res?.payload?.message ? res.payload.message.split(",")[0] : "DEFAULT";
                  handleErrorModal(errorKey);
                } else {
                  callSuccessModal();
                }
              });
            } else {
              // show toast error if voice quality is bad, and restart mitek sdk
              toast.error(
                t("There was a problem during the voice recording. Please, click button and try again."),
                toastOptionsError,
              );
              handleVoiceVerify(verificationData);
            }
          }
        });
      }
    });
  };

  // when error appear on we close modal when SDK is started, we call this func
  const stopMitekSDK = () => {
    mitekScienceSDK?.cmd(mitekActions.sdkStop);
  };

  return { checkRecordingVoiceResult, handleVoiceVerify, stopMitekSDK, handleVerifyCallback, showErrorModal };
};

export default useHandleVerifyStatus;
