import React, { useEffect, useState } from "react";

import { Box, Text, FlexGap, Button, RowBetween } from "components";
import { StyledButton } from "../choose-network/styled";
import { InputError } from "components/inputs/input-group/styled";
import { CopyIcon } from "components/svg";

import { useCopyContent, useTooltip } from "hooks";
import { useTranslation } from "context";

import { SHORT_CHAIN_NAMES } from "configs/web3";

import { ConnectWalletFieldProps } from "./types";

const SHOW_ERROR_TIME_STAMP = 5_000;

const ConnectWalletField: React.FC<ConnectWalletFieldProps> = ({
  address,
  disconnecting,
  isLoading,
  isUnsupportedNetwork,
  chainForSwitchNetwork,
  switchNetwork,
  onConnectClick,
  onLogout,
}) => {
  const [disconnectingTimeStamp, setDisconnectingTimeStamp] = useState<null | number>(null);

  const { t } = useTranslation();

  const { copyContentHandler } = useCopyContent();

  useEffect(() => {
    if (disconnecting) {
      setDisconnectingTimeStamp(new Date().getTime());
    }
  }, [disconnecting]);

  const isLongDisconnect =
    disconnecting && new Date().getTime() - (disconnectingTimeStamp ?? new Date().getTime()) > SHOW_ERROR_TIME_STAMP;

  const { targetRef: targetRefWalletAdressTo, tooltip: walletAdressToTooltip } = useTooltip(address, {
    placement: "top",
    isEllipsis: true,
  });

  return (
    <Box mt="24px">
      <Text textScale="caption1" mb="8px" color="neutral600">
        {t("Wallet")}
      </Text>

      {onConnectClick && !address ? (
        <Button variant="outline" width="100%" onClick={onConnectClick} isLoading={isLoading}>
          {t("Connect")} {t("Wallet")}
        </Button>
      ) : (
        <div>
          <StyledButton
            isError={!address || isLongDisconnect}
            onClick={address ? () => copyContentHandler(address) : () => {}}
          >
            <RowBetween>
              <Text textScale="caption1" $fontWeight="bold" color="neutral800" ref={targetRefWalletAdressTo} ellipsis>
                {address}
              </Text>
              {walletAdressToTooltip}
              <CopyIcon width="24px" />
            </RowBetween>
          </StyledButton>

          {isLongDisconnect && (
            <InputError textScale="caption2">{t("Sorry couldn't disconnect, please refresh the page")}</InputError>
          )}

          <FlexGap flexDirection={{ _: "column", mobileS: "row" }} gap="12px">
            {onLogout && (
              <Button
                width={{ mobileS: "auto", _: "100%" }}
                mt="12px"
                variant="outline"
                onClick={onLogout}
                isLoading={disconnecting || isLoading}
              >
                {t("Log Out")}
              </Button>
            )}

            {switchNetwork && chainForSwitchNetwork && isUnsupportedNetwork && (
              <Button
                width={{ mobileS: "auto", _: "100%" }}
                mt="12px"
                variant="outline"
                onClick={() => switchNetwork(chainForSwitchNetwork)}
                isLoading={isLoading}
              >
                {t("Switch to %network%", { network: SHORT_CHAIN_NAMES[chainForSwitchNetwork] })}
              </Button>
            )}
          </FlexGap>
        </div>
      )}
    </Box>
  );
};

export default ConnectWalletField;
