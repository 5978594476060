import { ErrorResult } from "services/types";
import { FiatCurrencyCodeEnum, ProfileSettingsPayload } from "./settings/types";
import { ConfirmTwoFaPayload } from "../auth/types";
import { Currency, WalletSingleItem } from "store/wallets/types";

export type ProfileState = {
  error: ErrorResult | null;
  pending: boolean;
  user: ProfileResponse | null;
  twoFactorAuth: TwoFactorAuth;
  isBlacklisted: boolean;
  userAssets: UserAssetsType;
  deletionReason: string;
};

export enum PingOneRisk {
  low = "LOW",
  high = "HIGH",
  medium = "MEDIUM",
}

export enum UserTwoFaStatusEnum {
  ABSENT = "absent",
  INSTALLED = "installed",
  REINSTALLED = "reinstalled",
}

export type ProfileResponse = {
  id: number;
  createdAt: string;
  updatedAt: string;
  accountType: AccountType;
  language: string;
  lastActivities: string;
  role: string;
  firstName: string;
  lastName: string;
  email: string;
  fiatCurrencyCode: FiatCurrencyCodeEnum;
  isVerifiedKYC: boolean;
  isBanned: boolean;
  phone: string;
  businessName: string;
  businessPhone: string;
  businessAddress: string;
  fireblocksAccountId: string;
  imageUrl: string;
  settings: ProfileSettingsPayload;
  twoFaStatus: UserTwoFaStatusEnum;
  twoFaLastUpdate: string;
  walletsStatus: UserWalletsStatusEnum;
  riskLevel: PingOneRisk;
  isUnderAml: boolean;
  securitySettings: SecuritySettingsType;
  mitekInfo: MitekInfo;
  lastPasswordUpdateDate: string | null;
  showPasswordInfo: boolean;
};

export type UserAssetsType = {
  pending: boolean;
  wallets: {
    hot: WalletSingleItem[];
    cold: WalletSingleItem[];
  } | null;
};

export interface AssetsCurrencyType extends Currency {
  logoURI: string;
}

export enum UserWalletsStatusEnum {
  ABSENT = "absent",
  ERROR = "error",
  CREATION_IN_PROGRESS = "creation_in_progress",
  CREATED = "created",
}

export enum AccountType {
  PERSONAL = "personal",
  BUSINESS = "business",
  EMPTY = "empty",
}

type MitekInfo = {
  faceExpirationDate?: string;
  voiceExpirationDate?: string;
  faceState: boolean;
  voiceState: boolean;
};

export type TwoFactorAuth = {
  pendingVerify: boolean;
  pendingGenerate: boolean;
  pendingReset: boolean;
  secret: Secret2FAResponse | null;
  error: ErrorResult | null;
};

export type Secret2FAResponse = {
  ascii: string;
  hex: string;
  base32: string;
  otpauth_url: string;
};

export interface UserAssetsResponse extends UserAssetsType {
  status: boolean;
}

export type Verify2FARequest = {
  token: string;
};

export type UploadAvatarRequest = FormData;

export type SecuritySettingsType = {
  mitek: MitekSecuritySettingsType;
  twoFa: YubiKeyAndTwoFASecuritySettingsType;
  yubikey: YubiKeyAndTwoFASecuritySettingsType;
};

export type MitekSecuritySettingsType = {
  face: {
    withdrawFromCW: boolean;
  };
  voice: {
    withdrawFromCW: boolean;
  };
};

export type YubiKeyAndTwoFASecuritySettingsType = {
  addWithdrawalWallet: boolean;
  login: boolean;
  withdrawFromCW: boolean;
};

export type ResetTwoFaPayload = {
  otps?: ConfirmTwoFaPayload;
};

export type HighRiskResetTwoFaPayload = {
  code: string;
};

export type SoftDeletePayload = {
  otps?: ConfirmTwoFaPayload;
  deletionReason?: string;
};
