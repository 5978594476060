import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

import { useAppSelector } from "store/store";

import { ROUTES } from "navigation/routes";
import { PingOneRisk } from "store/profile/types";

const TemporaryTokenHandler: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const isTemporaryLoggedIn = useAppSelector(state => state.auth.isTemporaryLoggedIn);
  const user = useAppSelector(state => state.profile.user);

  if (!isTemporaryLoggedIn && user?.riskLevel !== PingOneRisk.high) {
    return <Navigate to={"/" + ROUTES.login} replace />;
  }

  return <>{children}</>;
};

export default TemporaryTokenHandler;
