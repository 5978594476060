import React from "react";
// Context
import { useTranslation } from "context";
// Components
import { Flex, ImageCropper, Text } from "components";
import { StyledModalWrapper } from "../styled";
import CloseModalWrapper from "../close-modal-wrapper";
// Store
import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";
// Types
import { ImageCropperModalProps } from "./types";

const ImageCropperModal: React.FC = () => {
  const { t } = useTranslation();
  const { image, setImage, handleUploadImage } = useAppSelector(
    state => state.modal.props as unknown as ImageCropperModalProps,
  );

  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  return (
    <StyledModalWrapper>
      <Flex position="relative" width="100%" flexDirection="column" mb={{ _: "20px", tablet: "30px" }}>
        <CloseModalWrapper closeModalHandler={closeModalHandler} mb="26px">
          <Text textScale="body2" mx="auto" color="neutral600">
            {t("Crop image")}
          </Text>
        </CloseModalWrapper>

        <ImageCropper image={image} setImage={setImage} handleImageCropper={handleUploadImage} />
      </Flex>
    </StyledModalWrapper>
  );
};

export default ImageCropperModal;
