import { lazyLoad } from "utils/loadable";

export { default as WalletsPage } from "./wallets";
export { default as BuyCryptoPage } from "./buy-crypto";
export { default as SwapPage } from "./swap";
export { default as ColdStoragePage } from "./cold-storage";
export { default as EscrowsPage } from "./escrows";
export { default as SettingsPage } from "./settings";
export { default as Messages } from "./messages";
export { default as EscrowProcessPage } from "./escrow-process";
export { default as CreateEscrowPage } from "./create-escrow";
export { default as EscrowPreviewPage } from "./escrow-preview";
export { default as DashboardPage } from "./dashboard";
export { CreateStatementPage, ListOfTransactionsPage, StatementsListPage, StatementItemPage } from "./statements";

export const PrivacyPolicyPage = lazyLoad(
  () => import("./privacy-policy"),
  module => module.default,
);

export const TermsAndConditionsPage = lazyLoad(
  () => import("./terms-and-conditions"),
  module => module.default,
);

export const NotFoundPage = lazyLoad(
  () => import("./not-found"),
  module => module.default,
);

export const ComingSoonPage = lazyLoad(
  () => import("./coming-soon"),
  module => module.default,
);

export const BannedPage = lazyLoad(
  () => import("./banned"),
  module => module.default,
);

export const TwoFactorResetPage = lazyLoad(
  () => import("./two-fa-reset"),
  module => module.default,
);

export const EscrowWidgetTest = lazyLoad(
  () => import("./escrow-widget-test"),
  module => module.default,
);
