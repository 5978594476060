import { useAppSelector } from "store/store";
import { Roles } from "pages/main/create-escrow/types";

export const useGetEscrowParticipants = () => {
  const user = useAppSelector(state => state.profile.user);
  const currentEscrow = useAppSelector(state => state.escrowProcess.currentEscrow);

  const seller = currentEscrow?.body?.head?.seller;
  const buyer = currentEscrow?.body?.buyer;
  const broker = currentEscrow?.body?.broker ? currentEscrow.body.broker.user : null;

  const userRole =
    user?.id === seller?.id
      ? Roles.seller
      : user?.id === buyer?.id
        ? Roles.buyer
        : user?.id === broker?.id
          ? Roles.broker
          : null;

  return { seller, buyer, broker, userRole };
};
