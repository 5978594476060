import { useState } from "react";
import { SingleValue } from "react-select";

import { LabelWithIcon } from "components";

import { NATIVE_CURRENCIES_LOGO, SHORT_CHAIN_NAMES, SUPPORTED_CHAINS } from "configs/web3";

import { SingleOption } from "components/selects/single-select/types";
import { getFilteredOptions } from "utils/helpers";

const useHandleFilters = ({ network }: { network: string }) => {
  const options: SingleOption[] = SUPPORTED_CHAINS.map(chainId => {
    return {
      value: chainId.toString(),
      label: <LabelWithIcon text={SHORT_CHAIN_NAMES[chainId]} src={NATIVE_CURRENCIES_LOGO[chainId]} />,
    };
  });

  const preSelectedOption = options.find(option => option.value === network);
  const [selectedOption, setSelectedOption] = useState(preSelectedOption ?? options[0]);

  const filteredOptions = getFilteredOptions(options, selectedOption.value);

  const handleSelectChange = (selectedOption: SingleValue<SingleOption>) => {
    if (selectedOption) {
      setSelectedOption(selectedOption);
    }
  };

  return { selectedOption, options: filteredOptions, handleSelectChange };
};

export default useHandleFilters;
