import { ChainId } from "configs/web3";
import { ErrorResult } from "services/types";
import { HistoryPayload, HistoryResponse } from "../wallets/wallets-history/types";

export type DashboardState = {
  pending: boolean;
  error: ErrorResult | null;
  totalBalance: string;
  networkBalances: NetworkBalance[];
  historyPayload: HistoryPayload;
  historyInfo: HistoryResponse;
  balanceChart: BalanceChart;
  balanceChartPayload: InitialBalanceChartPayload;
};

export type BalanceChart = {
  pending: boolean;
  chart: BalanceCharItem[];
};

export type NetworkBalance = {
  chainId: ChainId;
  balance: number;
};

export type BalanceChartPayload = {
  currencyCode?: string;
  period?: string;
  chainId?: string;
};

export type BalanceChartResponse = BalanceCharItem[];

export type BalanceCharItem = {
  amount: string;
  date: string;
};

export type InitialBalanceChartPayload = {
  period: PeriodStatusEnum;
  chainId?: string;
  currencyCode?: string;
};

export enum PeriodStatusEnum {
  WEEK = "week",
  MONTH = "month",
  QUARTER = "quarter",
  HALF_YEAR = "half_year",
  YEAR = "year",
}
