import { useEffect } from "react";
import { toast } from "react-toastify";

import { useDebounce } from "hooks";
import { isErrorResult } from "services";
import { useTranslation } from "context";

import { toastOptionsSuccess } from "components";

import { useAppDispatch, useAppSelector } from "store/store";
import { estimateEscrowFee, getEscrowMinAmount } from "store/escrow/create-escrow/actions";
import { setCoversBrokersCommissionTab, setCoversEscryptoFeeTab } from "store/escrow/create-escrow";
import { modifyEscrowProducts } from "store/escrow/escrow-process/actions";
import { hideModal } from "store/modal";

import { CommissionPayer } from "pages/main/create-escrow/types";
import { UseHandleDataArgs } from "./types";

import { tabsIndex } from "configs";

export const useHandleData = ({ setFieldValue, setFieldTouched, values }: UseHandleDataArgs) => {
  const currentEscrow = useAppSelector(state => state.escrowProcess.currentEscrow);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getEscrowMinAmount());
    if (currentEscrow) {
      onTabClick(tabsIndex.createEscrowCommissionPayer[currentEscrow.body.head.feePaidBy], true);
      if (currentEscrow?.body.broker?.commissionPaidBy) {
        onTabClick(tabsIndex.createEscrowCommissionPayer[currentEscrow.body.broker.commissionPaidBy]);
      }
    }
  }, [currentEscrow]);

  const debounceAmount = useDebounce(values.amount ? values.amount : "0", 1000);

  useEffect(() => {
    if (+debounceAmount > 0) {
      const data = {
        currencyCode: currentEscrow?.body.head.currency.publicCode ?? "",
        chainId: currentEscrow?.body.head.currency.chainId ?? "",
        price: +debounceAmount,
        withBroker: !!currentEscrow?.body.broker ?? false,
        isWidget: currentEscrow?.body.head.isWidget,
      };

      dispatch(estimateEscrowFee(data)).then(async res => {
        if (!isErrorResult(res.payload)) {
          await setFieldValue("escryptoFee", res.payload?.amount ?? "");
        }
      });
    }
  }, [debounceAmount]);

  const closeModalHandler = () => {
    dispatch(hideModal());
  };
  const onTabClick = async (tab: number, escryptoFee?: boolean) => {
    const commissionPayerValue = Object.values(CommissionPayer);

    if (escryptoFee) {
      await setFieldValue("coversEscryptoFee", commissionPayerValue[tab]);
      await setFieldTouched("coversEscryptoFee", true);

      dispatch(setCoversEscryptoFeeTab(tab));
    } else {
      await setFieldValue("coversBrokersCommission", commissionPayerValue[tab]);
      await setFieldTouched("coversBrokersCommission", true);

      dispatch(setCoversBrokersCommissionTab(tab));
    }
  };

  const handleSwitchIsShipped = async () => {
    await setFieldValue("isShipped", !values.isShipped);
  };

  const saveHandler = () => {
    if (currentEscrow) {
      const data = {
        escrowId: currentEscrow.body.id,
        productId: currentEscrow.body.products[0].id,
        data: {
          name: values.name,
          description: values.description,
          url: values.website ? values.website : undefined,
          shippable: values.isShipped,
          price: +values.amount,
          feePaidBy: values.coversEscryptoFee,
          commission: currentEscrow.body.broker ? +values.brokersCommission : undefined,
          commissionPaidBy: currentEscrow.body.broker ? values.coversBrokersCommission : undefined,
        },
      };

      dispatch(modifyEscrowProducts(data)).then(res => {
        if (!isErrorResult(res.payload)) {
          toast.success(t("Escrow changes saved successfully"), toastOptionsSuccess);
          closeModalHandler();
        }
      });
    }
  };

  return { closeModalHandler, onTabClick, handleSwitchIsShipped, saveHandler };
};
