import React from "react";
import BigNumber from "bignumber.js";

import { useTranslation } from "context";
import { useTooltip } from "hooks";

import { Box, Flex, Skeleton, Text } from "components";

import { AmountToDepositBalanceSectionProps } from "components/amount-to-deposit/types";

const BalanceSection: React.FC<AmountToDepositBalanceSectionProps> = ({ pending, formattedBalance, balanceTitle }) => {
  const { t } = useTranslation();

  const { targetRef: targetRefBalance, tooltip: balanceTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  return (
    <>
      {pending ? (
        <Skeleton width="160px" height="24px" />
      ) : (
        <Flex justifyContent="flex-start">
          <Box maxWidth="50%" minWidth="60px">
            <Text textScale="caption1" color="neutral500">
              {t(`${balanceTitle}:`)}
            </Text>
          </Box>

          <Box ellipsis>
            <Text px="4px" textScale="caption1" ellipsis ref={targetRefBalance}>
              {BigNumber(formattedBalance).toFormatExtended(8, 1)}
            </Text>
          </Box>

          {balanceTooltip}
        </Flex>
      )}
    </>
  );
};

export default BalanceSection;
