export const baseColors = {
  transparent: "transparent",
  logoLifi: "#3F49E1",
  white: "#FFFFFF",
  yubikeyLoginLogo: "#98C93C",
  googleRed: "#EA4335",
  googleBlue: "#4285F4",
  googleGreen: "#34A853",
  googleYellow: "#FBBC05",
} as const;

export const tokenColors = {
  ETH: "#647CEC",
  BUSD: "#F3BB31",
  EUROC: "#4487F9",
  PAX: "#7AB253",
  TUSD: "#2596BE",
  USDC: "#2A74C6",
  USDT: "#08A47C",
  WBTC: "#F59723",
  WETH: "#8A92B2",
  WMATIC: "#8444E4",
  WXRP: "#262626",
  BNB: "#F8BC2C",
  WAVAX: "#F74343",
  WBNB: "#F8BC2C",
  MATIC: "#8444E4",
  AVAX: "#F74343",
  SOL: "#A437D0",
  BTC: "#F4941C",
} as const;

export const lightColors = {
  ...baseColors,
  ...tokenColors,

  neutral1000: "#0A0D14",
  neutral900: "#1B1F27",
  neutral800: "#23272F",
  neutral700: "#3F444D",
  neutral600: "#5F6C85",
  neutral500: "#94A0B8",
  neutral400: "#CBD2E1",
  neutral300: "#E1E6EF",
  neutral200: "#F1F3F9",
  neutral100: "#F8F9FC",
  neutral0: "#FFFFFF",

  grey1: "#F7F7F7",
  grey2: "#E3E3E3",

  primary: "#3F7FFF",
  primaryHover: "#1462FF",
  primaryLight: "#EBF1FF",

  purple: "#8E59FF",
  purpleLight: "#EFEBFF",

  blue: "#3F7FFF",
  blueLight: "#EBF1FF",

  red: "#FF4747",
  redLight: "#FFEBEE",

  green: "#50CF7C",
  greenLight: "#ECF8EF",

  yellow: "#FFAF4C",
  yellowLight: "#FFF3E5",
  yellowDark: "#A35A00",

  pink: "#FF478A",
  pinkLight: "#FFEBF2",

  // Put light colors here
} as const;

export const darkColors = {
  ...baseColors,
  ...tokenColors,
  // Put dark colors here

  neutral1000: "#FFFFFF",
  neutral900: "#F8F9FC",
  neutral800: "#F1F3F9",
  neutral700: "#E1E6EF",
  neutral600: "#CBD2E1",
  neutral500: "#94A0B8",
  neutral400: "#5F6C85",
  neutral300: "#3F444D",
  neutral200: "#23272F",
  neutral100: "#1B1F27",
  neutral0: "#0A0D14",

  grey1: "#23272F",
  grey2: "#3F444D",

  primary: "#3F7FFF",
  primaryHover: "#1462FF",
  primaryLight: "#EBF1FF",

  purple: "#8E59FF",
  purpleLight: "#EFEBFF",

  blue: "#3F7FFF",
  blueLight: "#EBF1FF",

  red: "#FF4747",
  redLight: "#FFEBEE",

  green: "#50CF7C",
  greenLight: "#ECF8EF",

  yellow: "#FFAF4C",
  yellowLight: "#FFF3E5",
  yellowDark: "#A35A00",

  pink: "#FF478A",
  pinkLight: "#FFEBF2",
} as const;
