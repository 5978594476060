import React from "react";

import { CheckIcon } from "components/svg";
import { FlexGap, Flex } from "components";
import { StyledStep, StyledStepText, StyledStepSubText, StyledTextWrapper, StyledIconWrapper } from "./styled";

import { useTranslation } from "context";

import { StepperProps } from "./types";

const Stepper: React.FC<StepperProps> = ({ currentStep, steps }) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="column" width="100%">
      <FlexGap gap={{ _: "8px", tablet: "18px" }}>
        {steps.map((step, index) => {
          return (
            <StyledStep key={index} currentStep={currentStep} index={index} stepCount={steps.length}>
              <StyledTextWrapper currentStep={currentStep} index={index}>
                <StyledStepSubText
                  textScale={{ _: "caption1", tablet: "body3" }}
                  currentStep={currentStep}
                  index={index}
                >
                  {t("Step")} {index + 1}
                </StyledStepSubText>

                <StyledStepText
                  textScale={{ _: "caption1", tablet: "body3" }}
                  $fontWeight="medium"
                  currentStep={currentStep}
                  index={index}
                  ellipsis
                >
                  {t(`${step.text}`)}
                </StyledStepText>
              </StyledTextWrapper>

              <StyledIconWrapper currentStep={currentStep} index={index}>
                <CheckIcon color="green" width="16px" height="16px" />
              </StyledIconWrapper>
            </StyledStep>
          );
        })}
      </FlexGap>
    </Flex>
  );
};
export default Stepper;
