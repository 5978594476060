import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 72 72" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_1292_55800)">
        <rect x="18" y="49" width="36" height="12" rx="4" fill={theme.colors.primary} />
      </g>
      <circle cx="36" cy="36" r="28" fill={theme.colors.primaryLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.primary} strokeOpacity="0.5" />
      <path
        d="M29.25 22.5H25.875C24.0105 22.5 22.5 24.0105 22.5 25.875V29.25"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 42.75V46.125C22.5 47.9895 24.0105 49.5 25.875 49.5H29.25"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.75 49.5H46.125C47.9895 49.5 49.5 47.9895 49.5 46.125V42.75"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.5 29.25V25.875C49.5 24.0105 47.9895 22.5 46.125 22.5H42.75"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.0113 43.4994L42.5853 42.4299C42.3693 41.8884 42.0348 41.4009 41.6073 41.0049V41.0049C40.8828 40.3329 39.9303 39.9609 38.9433 39.9609H33.0573C32.0688 39.9609 31.1178 40.3344 30.3933 41.0049V41.0049C29.9658 41.4009 29.6313 41.8884 29.4153 42.4299L28.9893 43.4994"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 36C38.4853 36 40.5 33.9853 40.5 31.5C40.5 29.0147 38.4853 27 36 27C33.5147 27 31.5 29.0147 31.5 31.5C31.5 33.9853 33.5147 36 36 36Z"
        fill={theme.colors.primary}
        fillOpacity="0.14"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="36" cy="36" r="35.75" stroke={theme.colors.primary} strokeOpacity="0.18" strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_1292_55800"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_1292_55800" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
