import React, { useState, useEffect } from "react";
import BigNumber from "bignumber.js";

import {
  useForm,
  useGetFiatSymbolAndAbbreviation,
  useAmountToDepositHandlers,
  useGetCurrencyRate,
  useTokenBE,
} from "hooks";
import { useValidationSchema } from "./hooks";

import { useAppDispatch, useAppSelector } from "store/store";

import { Flex, Commissions, WalletErrorMessage, SlideToConfirm, Column, AmountToDeposit } from "components";

import { useTranslation } from "context";

import { isErrorResult } from "services";
import { formatValueToBNString } from "configs/web3";
import { getTokenFromNetworks } from "utils/helpers";

import { hideModal, showModal } from "store/modal";
import { depositToColdWallet } from "store/wallets/cold-wallets/actions";
import { getWallets } from "store/wallets/cold-wallets/actions";
import { estimateFiatFeeColdStorage } from "store/wallets/wallets-fee/actions";

import { ModalProps } from "store/modal/types";
import { DEPOSIT_MODAL_COMMISSION_ITEMS } from "components/modal/components/cold-storage-deposit-modal/constants";
import { ColdStorageDepositModalProps } from "components/modal/components/cold-storage-deposit-modal/types";
import { ColdStorageDepositSuccessModalProps } from "components/modal/components/cold-storage-deposit-success-modal/types";
import { BalancePositionEnum } from "components/amount-to-deposit/types";
import { WalletsTypeEnum } from "store/wallets/enums";
import { selectCombinedData } from "./selectors";

const DepositSection: React.FC<ColdStorageDepositModalProps> = ({ coldWallet }) => {
  const { pending, networks, coldWalletFee, minAmountToDepositInFiat, minAmountToCreateInFiat } =
    useAppSelector(selectCombinedData);

  const [isConfirmed, setIsConfirmed] = useState(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { validationSchema, initialValues } = useValidationSchema();
  const { setFieldValue, setFieldTouched, validateForm, errors, isValid, touched, values } = useForm({
    initialValues,
    validationSchema,
    onSubmit() {},
  });
  const { token } = useTokenBE(coldWallet.currency.chainId, coldWallet.currency.publicCode);
  const { fiatSymbol } = useGetFiatSymbolAndAbbreviation();

  const currencyCode = coldWallet.currency.publicCode;

  const { currentRate } = useGetCurrencyRate(currencyCode);

  const tokenBE = getTokenFromNetworks(networks, coldWallet.currency.chainId, coldWallet.currency.publicCode);
  const tokenBalance = tokenBE?.balance ?? "0";

  const { onBlur, onUserInput, isColdStorageMaxBtnDisabled, isColdStorageMinBtnDisabled } = useAmountToDepositHandlers<
    typeof initialValues
  >({
    setFieldTouched,
    setFieldValue,
    tokenBalance,
    currentRate,
  });

  // Rates updates once in a minute so formik should be updated
  // currentBalance could be updated via sockets so the formik should be updated
  useEffect(() => {
    validateForm();
  }, [currentRate, tokenBalance]);

  useEffect(() => {
    dispatch(estimateFiatFeeColdStorage({ currencyCode }));
  }, [currentRate]);

  const getCommissionsList = () => {
    const commissionsList = [...DEPOSIT_MODAL_COMMISSION_ITEMS];

    // Monthly Subscription is temporarily disabled.
    commissionsList[0].commissionsValue = coldWalletFee.withdrawalFee.amountInFiat
      ? `${fiatSymbol}${BigNumber(coldWalletFee.withdrawalFee.amountInFiat).toFormatExtended(2)}`
      : `${fiatSymbol}${BigNumber(0).toFormatExtended(2)}`;
    commissionsList[0].pending = coldWalletFee.pending;

    // commissionsList[0].commissionsValue = coldWalletFee.subscriptionFee.amount
    //     ? `${coldWalletFee.subscriptionFee.inPercent}%`
    //     : "0%";
    // commissionsList[0].pending = coldWalletFee.pending;
    // commissionsList[1].commissionsValue = coldWalletFee.withdrawalFee
    //     ? `$${BigNumber(coldWalletFee.withdrawalFee).toFormatExtended(2)}`
    //     : `$${BigNumber(0).toFormatExtended(2)}`;
    // commissionsList[1].pending = coldWalletFee.pending;

    return commissionsList;
  };

  const onConfirmCallback = async (formikValues: typeof values) => {
    const _showModal = showModal as ModalProps<ColdStorageDepositSuccessModalProps>;

    setIsConfirmed(true);

    const data = {
      currencyCode,
      chainId: coldWallet.currency.chainId,
      amount: formikValues.amount,
    };

    dispatch(depositToColdWallet(data)).then(async res => {
      setIsConfirmed(false);

      if (!isErrorResult(res.payload)) {
        await setFieldValue("amount", "");
        await setFieldTouched("amount", false);

        const currentVaultPrice = formatValueToBNString(
          BigNumber(coldWallet.availableBalance).times(coldWallet.marketInfo[0].price),
        );

        dispatch(
          _showModal({
            modalName: "coldStorageDepositSuccessModal",
            modalType: "modal",
            rootId: "cold-storage",
            clickOutsideHandler: () => {
              dispatch(getWallets({ type: WalletsTypeEnum.cold }));
              dispatch(hideModal());
            },
            props: {
              amount: formikValues.amount,
              currencyCode: token?.symbol,
              currentVaultPrice: currentVaultPrice,
              currentRate,
              withdrawalFee: coldWalletFee.withdrawalFee.amountInFiat,
              subscriptionFee: `${BigNumber(coldWalletFee.subscriptionFee.inPercent).toFormatExtended(2)}%`,
            },
          }),
        );
      }
    });
  };

  const setAmount = async (type: "min" | "max") => {
    if (type === "min") {
      const amount = formatValueToBNString(
        BigNumber(
          BigNumber(coldWallet.availableBalance).times(currentRate).gte(minAmountToCreateInFiat)
            ? minAmountToDepositInFiat
            : minAmountToCreateInFiat,
        )
          .div(currentRate ?? 0)
          .toFixed(8, 0),
      );

      await setFieldValue("amount", amount);
      await setFieldTouched("amount", true);
    } else {
      await setFieldValue("amount", tokenBalance);
      await setFieldTouched("amount", true);
    }
  };

  return (
    <Column flex="1" justifyContent="space-between">
      <Column>
        <AmountToDeposit
          value={values.amount}
          title="Amount to deposit"
          isError={!!errors.amount}
          isTouched={!!touched.amount}
          currency={token}
          balanceTitle="Wallet Balance"
          balancePosition={BalancePositionEnum.bottom}
          onChangeInput={onUserInput}
          onBlur={onBlur}
          handleMaxButtonClick={() => setAmount("max")}
          handleMinButtonClick={() => setAmount("min")}
          isMaxButtonDisabled={isColdStorageMaxBtnDisabled}
          isMinButtonDisabled={isColdStorageMinBtnDisabled}
          tokenBalance={tokenBalance}
          isHaveFiatEquivalent
          currentRate={currentRate}
        />

        <Commissions commissionsList={getCommissionsList()} mt="32px" width="100%" />

        {!!errors["amount"] && touched["amount"] ? <WalletErrorMessage message={errors["amount"]} /> : null}

        {coldWalletFee.error ? (
          <WalletErrorMessage
            mt="10px"
            message={t("Sorry, something went wrong while estimating fee, try close and open deposit modal again")}
          />
        ) : null}
      </Column>

      <Flex mt="12px">
        <SlideToConfirm
          isConfirmed={isConfirmed}
          onConfirmCallback={() => onConfirmCallback(values)}
          disabled={!isValid || !!coldWalletFee.error}
          isLoading={pending || coldWalletFee.pending}
        />
      </Flex>
    </Column>
  );
};

export default DepositSection;
