import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { tabsIndex } from "configs/tabs";

import { SettingsState } from "./types";

const initialState: SettingsState = {
  error: null,
  pending: false,
  activeTab: tabsIndex.settings.profile,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    ...initialState,
  },
  reducers: {
    setSettingsTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
  },
});

export const { setSettingsTab } = settingsSlice.actions;
export default settingsSlice;
