import { DefaultTheme } from "styled-components";
import { SpaceProps } from "styled-system";

export const variants = {
  INFO: "info",
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
} as const;

export type Variants = (typeof variants)[keyof typeof variants];

export interface ThemedAlert {
  variant?: AlertBannerProps["variant"];
  theme: DefaultTheme;
}

export interface AlertTextWithDescriptionProps {
  text: string;
  description?: string;
}

export interface AlertBannerProps extends AlertTextWithDescriptionProps, SpaceProps {
  variant?: Variants;
  visible?: boolean;
  actionButton?: React.ReactNode;
  onCloseClick?: () => void;
}
