import { createAsyncThunk } from "@reduxjs/toolkit";

import { ENDPOINTS_ESCROW, isErrorResult, makeApiRequest } from "services";

import { ErrorResult } from "services/types";
import {
  EscrowDeliveryAddressPayload,
  EscrowIdPayload,
  EscrowIdResponse,
  ModifyEscrowProductsPayload,
  GetDeliveryCouriersResponse,
  ShipmentsPayload,
} from "./types";

export const getEscrowById = createAsyncThunk<EscrowIdResponse, EscrowIdPayload, { rejectValue: ErrorResult }>(
  "escrow-process/get-escrow-by-id",
  async (id, { rejectWithValue }) => {
    const result = await makeApiRequest<EscrowIdResponse>({
      method: "GET",
      url: ENDPOINTS_ESCROW.getMyEscrowsList + id,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const cancelEscrow = createAsyncThunk<EscrowIdResponse, EscrowIdPayload, { rejectValue: ErrorResult }>(
  "escrow-process/cancel-escrow",
  async (id, { rejectWithValue }) => {
    const result = await makeApiRequest<EscrowIdResponse>({
      method: "POST",
      url: ENDPOINTS_ESCROW.getMyEscrowsList + id + "/cancel",
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const setEscrowDeliveryAddress = createAsyncThunk<
  EscrowIdResponse,
  EscrowDeliveryAddressPayload,
  { rejectValue: ErrorResult }
>("escrow-process/set-escrow-delivery-address", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<EscrowIdResponse>({
    method: "PATCH",
    url: ENDPOINTS_ESCROW.getMyEscrowsList + data.id,
    data: { addressId: data.addressId },
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const modifyEscrowProducts = createAsyncThunk<
  EscrowIdResponse,
  ModifyEscrowProductsPayload,
  { rejectValue: ErrorResult }
>("escrow-process/modify-escrow-products", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<EscrowIdResponse>({
    method: "PATCH",
    url: ENDPOINTS_ESCROW.getMyEscrowsList + data.escrowId + "/products/" + data.productId,
    data: data.data,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const acceptEscrow = createAsyncThunk<EscrowIdResponse, EscrowIdPayload, { rejectValue: ErrorResult }>(
  "escrow-process/accept-escrow",
  async (id, { rejectWithValue }) => {
    const result = await makeApiRequest<EscrowIdResponse>({
      method: "POST",
      url: ENDPOINTS_ESCROW.getMyEscrowsList + id + "/accept/",
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const prepayEscrow = createAsyncThunk<EscrowIdResponse, EscrowIdPayload, { rejectValue: ErrorResult }>(
  "escrow-process/prepay-escrow",
  async (id, { rejectWithValue }) => {
    const result = await makeApiRequest<EscrowIdResponse>({
      method: "POST",
      url: ENDPOINTS_ESCROW.getMyEscrowsList + id + "/prepay/",
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const payEscrow = createAsyncThunk<EscrowIdResponse, EscrowIdPayload, { rejectValue: ErrorResult }>(
  "escrow-process/pay-escrow",
  async (id, { rejectWithValue }) => {
    const result = await makeApiRequest<EscrowIdResponse>({
      method: "POST",
      url: ENDPOINTS_ESCROW.getMyEscrowsList + id + "/pay/",
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getDeliveryCouriers = createAsyncThunk<
  GetDeliveryCouriersResponse[],
  undefined,
  { rejectValue: ErrorResult }
>("escrow-process/get-delivery-couriers", async (_, { rejectWithValue }) => {
  const result = await makeApiRequest<GetDeliveryCouriersResponse[]>({
    method: "GET",
    url: ENDPOINTS_ESCROW.getDeliveryCouriers,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const setShipments = createAsyncThunk<EscrowIdResponse, ShipmentsPayload, { rejectValue: ErrorResult }>(
  "escrow-process/set-shipments",
  async (obj, { rejectWithValue }) => {
    const result = await makeApiRequest<EscrowIdResponse>({
      method: "POST",
      url: ENDPOINTS_ESCROW.getMyEscrowsList + obj.escrowId + "/delivery/",
      data: obj.body ? obj.body : undefined,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const confirmDelivery = createAsyncThunk<EscrowIdResponse, EscrowIdPayload, { rejectValue: ErrorResult }>(
  "escrow-process/confirm-delivery",
  async (id, { rejectWithValue }) => {
    const result = await makeApiRequest<EscrowIdResponse>({
      method: "POST",
      url: ENDPOINTS_ESCROW.getMyEscrowsList + id + "/delivery-confirm/",
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);
