import heic2any from "heic2any";

export const getFileExtension = (name: string) => {
  const dotIndex = name.lastIndexOf(".") + 1;
  const extensionFile = name.slice(dotIndex).toLowerCase();
  return extensionFile;
};

export const extractFilenameWithoutExtension = (filename: string) => {
  const parts = filename.split(".");
  const filenameWithoutExtension = parts.slice(0, -1).join(".");
  return filenameWithoutExtension;
};

export const dataUrlToFile = async (dataUrl: string, fileName: string): Promise<File> => {
  const fileFormat = getFileExtension(fileName);
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: `image/${fileFormat}` });
};

export const convertHeic = async (file: File) => {
  const convertedBlob = await heic2any({
    blob: file,
    toType: "image/jpeg",
    quality: 1,
  });

  const res = new File([convertedBlob as Blob], `${extractFilenameWithoutExtension(file.name)}.jpeg`, {
    type: `image/jpeg`,
  });

  return res;
};
