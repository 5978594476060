import { useEffect, useState } from "react";

import { useDebounce } from "hooks";
import { isErrorResult } from "services";

import { useAppDispatch, useAppSelector } from "store/store";
import { setCoversBrokersCommissionTab, setCoversEscryptoFeeTab } from "store/escrow/create-escrow";
import { estimateEscrowFee } from "store/escrow/create-escrow/actions";

import { CommissionPayer, Roles } from "pages/main/create-escrow//types";
import { BEToken } from "store/wallets/hot-wallets/types";
import { useHandleDataProps } from "../types";

import { Currency, ChainId } from "configs/web3";
import { getTokenFromTokenKey } from "utils/helpers";

export const useHandleData = ({ setFieldValue, setFieldTouched, values }: useHandleDataProps) => {
  const allTokensList = useAppSelector(state => state.tokensList.allTokensList);
  const dispatch = useAppDispatch();

  const [token, setToken] = useState<BEToken | null | Currency>(null);

  const debounceAmount = useDebounce(values.currency.amount ? values.currency.amount : "0", 1000);
  // TODO: check if allTokensList dependency is needed

  useEffect(() => {
    if (allTokensList) {
      const setWhoCoversEscryptoFee = async () => {
        await setFieldValue("currency.coversEscryptoFee", CommissionPayer.seller);
        await setFieldTouched("currency.coversEscryptoFee", true);

        dispatch(setCoversEscryptoFeeTab(1));
      };

      if (values.role.role === Roles.seller && values.role.createAWidget) {
        setWhoCoversEscryptoFee();
      }

      setToken(
        Object.values(allTokensList[values.currency.network]).find(
          item => item.publicCode === values.currency.currency,
        ) ?? null,
      );
    }
  }, [allTokensList]);

  useEffect(() => {
    if (+debounceAmount > 0) {
      const data = {
        currencyCode: values.currency.currency,
        chainId: values.currency.network.toString(),
        price: +debounceAmount,
        withBroker: values.role.role === Roles.broker,
        isWidget: values.role.role === Roles.seller && values.role.createAWidget,
      };
      dispatch(estimateEscrowFee(data)).then(async res => {
        if (!isErrorResult(res.payload)) {
          await setFieldValue("currency.escryptoFee", res.payload?.amount);
        }
      });
    }
  }, [debounceAmount]);

  const resetFields = async () => {
    await setFieldValue("currency.amount", "");
    await setFieldTouched("currency.amount", false);

    await setFieldValue("currency.escryptoFee", "");
    await setFieldTouched("currency.escryptoFee", false);

    await setFieldValue("currency.brokersCommission", "");
    await setFieldTouched("currency.brokersCommission", false);
  };

  const setNetwork = async (chainId: ChainId) => {
    const currentNetworkTokensList = (allTokensList ? Object.values(allTokensList[chainId]) : []).map(
      item => item.symbol,
    );
    const nativeCurrency = currentNetworkTokensList[0];
    const preSelectedCurrency = currentNetworkTokensList.find(currency => currency === values.currency.currency);

    await setFieldValue("currency.network", chainId);
    await setFieldTouched("currency.network", true);

    if (!preSelectedCurrency) {
      await setFieldValue("currency.currency", nativeCurrency);
      await setFieldTouched("currency.currency", true);
    }

    resetFields();
    if (preSelectedCurrency) {
      setToken(getTokenFromTokenKey(allTokensList, chainId, preSelectedCurrency));
    } else {
      setToken(getTokenFromTokenKey(allTokensList, chainId, nativeCurrency));
    }
  };

  const setCurrency = async (token: BEToken) => {
    await setFieldValue("currency.currency", token.publicCode);
    await setFieldTouched("currency.currency", true);

    resetFields();
    setToken(token);
  };

  const onTabClick = async (tab: number, escryptoFee?: boolean) => {
    const commissionPayerValue = Object.values(CommissionPayer);

    if (escryptoFee) {
      await setFieldValue("currency.coversEscryptoFee", commissionPayerValue[tab]);
      await setFieldTouched("currency.coversEscryptoFee", true);

      dispatch(setCoversEscryptoFeeTab(tab));
    } else {
      await setFieldValue("currency.coversBrokersCommission", commissionPayerValue[tab]);
      await setFieldTouched("currency.coversBrokersCommission", true);

      dispatch(setCoversBrokersCommissionTab(tab));
    }
  };

  return { setNetwork, setCurrency, token, onTabClick };
};
