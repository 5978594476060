import { createAsyncThunk } from "@reduxjs/toolkit";
import { ENDPOINTS_NOTIFICATIONS, isErrorResult, makeApiRequest } from "services";
import { ErrorResult } from "services/types";
import {
  NotificationsListPayload,
  NotificationsListResponse,
  NotificationsListSingleItem,
  NotificationIdPayload,
  SuccessResponse,
} from "./types";

export const getNotificationsList = createAsyncThunk<
  NotificationsListResponse,
  NotificationsListPayload,
  { rejectValue: ErrorResult }
>("notifications/get-notifications-list", async (params, { rejectWithValue }) => {
  const result = await makeApiRequest<NotificationsListResponse>({
    method: "GET",
    url: ENDPOINTS_NOTIFICATIONS.getNotifications,
    params,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const getNotificationById = createAsyncThunk<
  NotificationsListSingleItem,
  NotificationIdPayload,
  { rejectValue: ErrorResult }
>("notifications/get-notification-by-id", async (id, { rejectWithValue }) => {
  const result = await makeApiRequest<NotificationsListSingleItem>({
    method: "GET",
    url: ENDPOINTS_NOTIFICATIONS.getNotifications + id,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const markAllNotificationAsRead = createAsyncThunk<SuccessResponse, undefined, { rejectValue: ErrorResult }>(
  "notifications/mark-all-notification-as-read",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<SuccessResponse>({
      method: "PATCH",
      url: ENDPOINTS_NOTIFICATIONS.getNotifications,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getUnreadNotificationCount = createAsyncThunk<number, undefined, { rejectValue: ErrorResult }>(
  "notifications/get-unread-notification-count",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<number>({
      method: "GET",
      url: ENDPOINTS_NOTIFICATIONS.getUnreadNotificationCount,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);
