import { createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorResult } from "services/types";
import { ENDPOINTS_WALLET, isErrorResult, makeApiRequest } from "services";
import { BalanceChartResponse, BalanceChartPayload } from "./types";
import { WalletSingleItem } from "../wallets/cold-wallets/types";

export const getBalanceChart = createAsyncThunk<
  BalanceChartResponse,
  BalanceChartPayload,
  { rejectValue: ErrorResult }
>("dashboard/get-balance-chart", async (params, { rejectWithValue }) => {
  const result = await makeApiRequest<BalanceChartResponse>({
    method: "GET",
    url: ENDPOINTS_WALLET.walletsUpdateChart,
    params,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const getDashboardWalletBalance = createAsyncThunk<WalletSingleItem[], undefined, { rejectValue: ErrorResult }>(
  "dashboard/get-dashboard-wallet-balance",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<WalletSingleItem[]>({
      method: "GET",
      url: ENDPOINTS_WALLET.wallets,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);
