import React from "react";

import { useTooltip } from "hooks";

import { Column, FlexGap, Text } from "components";
import { CircleCheckIcon, ModalDeleteIcon, TrashIcon } from "components/svg";

import { useAppDispatch } from "store/store";
import { deleteAddress, getAddressesList, updateAddress } from "store/addresses/actions";
import { hideModal, partialUpdateModalProps, showModal } from "store/modal";

import { useTranslation } from "context";

import { ErrorResult } from "services/types";
import { ModalProps, PartialUpdateModalProps } from "store/modal/types";
import { ActionModalProps } from "components/modal/components/action-modal/types";
import { AddressCardProps } from "components/address-сard/types";

export const useHandleData = ({ address, rootId }: AddressCardProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const setAsDefaultHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setVisible(false);

    dispatch(updateAddress({ ...address, isDefault: true })).then(res => {
      if (!(res.payload as ErrorResult).isError) {
        dispatch(getAddressesList());
      }
    });
  };

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const confirmAddressDeletion = () => {
    const _partialProps = partialUpdateModalProps as PartialUpdateModalProps<ActionModalProps>;

    dispatch(_partialProps({ isLoading: true }));
    dispatch(deleteAddress(address)).then(res => {
      if (!(res.payload as ErrorResult).isError) {
        dispatch(getAddressesList());
        closeModalHandler();
      }
      dispatch(_partialProps({ isLoading: false }));
    });
  };

  const showDeleteModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    const _showModal = showModal as ModalProps<ActionModalProps>;
    setVisible(false);

    dispatch(
      _showModal({
        modalName: "actionModal",
        rootId: rootId,
        props: {
          icon: <ModalDeleteIcon height="72px" width="72px" />,
          title: t("Delete Address?"),
          text: t("Are you sure you want to delete this address?"),
          isLoading: false,
          btnArr: [
            {
              buttonText: t("No"),
              callback: closeModalHandler,
              props: {
                variant: "outline",
              },
            },
            {
              buttonText: t("Delete"),
              callback: confirmAddressDeletion,
            },
          ],
        },
      }),
    );
  };

  const {
    targetRef: targetRefPopUp,
    tooltip: popUpTooltip,
    setVisible,
  } = useTooltip(
    <Column>
      {!address.isDefault && (
        <FlexGap gap="14px" mb="18px" alignItems="center" cursor="pointer" onClick={e => setAsDefaultHandler(e)}>
          <CircleCheckIcon />
          <Text textScale="caption1" color="neutral800">
            {t("Set as Default")}
          </Text>
        </FlexGap>
      )}

      <FlexGap gap="14px" alignItems="center" cursor="pointer" onClick={e => showDeleteModal(e)}>
        <TrashIcon />
        <Text textScale="caption1" color="red">
          {t("Delete Address")}
        </Text>
      </FlexGap>
    </Column>,
    { placement: "top" },
  );

  return { targetRefPopUp, popUpTooltip };
};
