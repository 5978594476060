import React, { useEffect } from "react";

import { useTranslation } from "context";

import { Text } from "components";
import { Widget } from "./index";
import { StyledWidgetBtnWrapper } from "./styled";

import { WidgetBtnProps } from "./types";

const WidgetButton: React.FC<WidgetBtnProps> = ({ widgetId, setMarkup, isShowTitle }) => {
  const { t } = useTranslation();

  const content = Widget({ widgetId });

  useEffect(() => {
    if (setMarkup) {
      setMarkup(content);
    }
  }, []);

  return (
    <StyledWidgetBtnWrapper>
      {isShowTitle && (
        <Text textScale="body3" $fontWeight="medium" color="neutral600" mb="16px">
          {t("Widget Preview")}
        </Text>
      )}

      {content}
    </StyledWidgetBtnWrapper>
  );
};

export default WidgetButton;
