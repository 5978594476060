import moment, { unitOfTime } from "moment";

export const calculateExpiredTime = (date?: string, unit?: unitOfTime.Diff) => {
  if (date) {
    const expirationDate = moment(date);
    const currentDate = moment();
    return expirationDate.diff(currentDate, unit);
  }
  return 0;
};
