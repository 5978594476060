import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral500", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent" stroke={theme.colors[color]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 11.1808C20 15.7218 16.587 19.9678 12 20.9988C7.413 19.9678 4 15.7218 4 11.1808V7.61384C4 6.80184 4.491 6.06984 5.243 5.76284L10.243 3.71684C11.369 3.25584 12.631 3.25584 13.757 3.71684L18.757 5.76284C19.509 6.07084 20 6.80184 20 7.61384V11.1808V11.1808Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.25 10.125L11.5 13.875L9.25 11.625"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
