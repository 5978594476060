import BigNumber from "bignumber.js";

import { Column, Text } from "components";

import { useTooltip } from "hooks";

import { NATIVE_CURRENCIES, formatValueToBNString } from "configs/web3";

import { TransactionResponseSingleItem } from "store/statements/create-statement/types";

const useHandleTooltip = (item: TransactionResponseSingleItem) => {
  const fiatCurrencyCode = item.statement ? item.statement.fiatCurrencyCode : item.fiatCurrencyCode ?? "";

  const amountInFiat =
    +item.amountInFiat > 0 && fiatCurrencyCode
      ? `${BigNumber(item.amountInFiat).toFormatExtended(2)} ${fiatCurrencyCode.toUpperCase()}`
      : "";

  const firstFeeItem = item.fees[0];

  const creationDate = item.transactionDate ? item.transactionDate : item.createdAt;

  const feeInFiat =
    item.feeInFiat && fiatCurrencyCode
      ? `${BigNumber(item.feeInFiat).toFormatExtended(2)} ${fiatCurrencyCode.toUpperCase()}`
      : "";

  const firstItemFeeStr = `${BigNumber(firstFeeItem?.amount ?? "0").toFormatExtended(8)} ${
    firstFeeItem?.currency ? firstFeeItem.currency.symbol : NATIVE_CURRENCIES[item.currency.chainId]?.symbol
  }`;

  const { targetRef: targetRefDate, tooltip: dateTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const { targetRef: targetRefType, tooltip: typeTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const { targetRef: targetRefOperation, tooltip: operationTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const { targetRef: targetRefAmount, tooltip: amountTooltip } = useTooltip(
    <Column>
      <Text textScale="caption1">{`${BigNumber(item.amount).toFormatExtended(8)} ${item.currency.symbol}`}</Text>
      <Text textScale="caption1">{amountInFiat}</Text>
    </Column>,
    {
      placement: "top",
    },
  );

  const { targetRef: targetRefFee, tooltip: feeTooltip } = useTooltip(
    <Column>
      {item.fees.length ? (
        item.fees.map((fee, index) => {
          return (
            <Text key={index} textScale="caption1">
              {BigNumber(formatValueToBNString(fee.amount)).toFormatExtended(8)} {fee.currency.symbol}
            </Text>
          );
        })
      ) : (
        <Text textScale="body3">{firstItemFeeStr}</Text>
      )}
      {item.feeInFiat && (
        <Text color="neutral500" textScale="caption2">
          {feeInFiat}
        </Text>
      )}
    </Column>,
    {
      placement: "top",
    },
  );

  return {
    targetRefDate,
    targetRefType,
    targetRefOperation,
    targetRefFee,
    targetRefAmount,
    creationDate,
    dateTooltip,
    typeTooltip,
    operationTooltip,
    feeTooltip,
    amountTooltip,
    amountInFiat,
    feeInFiat,
    firstItemFeeStr,
  };
};

export default useHandleTooltip;
