import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";

import { useMatchBreakpoints } from "hooks";

import { Text, Column, FlexGap, AddressCard } from "components";
import { EscrowDetailsIcon } from "components/svg";
import { AddressesSideModalInner } from "../addresses-side-modal/components";
import CloseModalWrapper from "../close-modal-wrapper";
import {
  SIDE_MODAL_LAPTOP_WIDTH,
  SIDE_MODAL_LAPTOP_X_PADDING,
  SIDE_MODAL_MOBILE_WIDTH,
  SIDE_MODAL_MOBILE_X_PADDING,
  StyledSideModalWrapper,
} from "../styled";
import { StyledAddBtn } from "pages/main/settings/components/profile/components/addresses/styled";
import { StyledAnimatedFiltersSectionContainer } from "../widget-buyers-list-side-modal/styled";

import { useTranslation } from "context";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";

import { AddressResponse } from "store/addresses/types";

import { ROUTES } from "navigation/routes";
import { selectCombinedData } from "./selectors";

const DeliveryAddressesSideModal: React.FC = () => {
  const { currentAddress, setAddressHandler, addressesList } = useAppSelector(selectCombinedData);

  const [isShow, setIsShow] = useState(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isMobileM, isMobileS } = useMatchBreakpoints();

  const isMobileSideModal = isMobileM || isMobileS;

  const addressesListSorted = [...addressesList].sort((a, b) => {
    if (a.id === currentAddress?.id) {
      return -1;
    }
    if (b.id === currentAddress?.id) {
      return 1;
    }
    return 0;
  });

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const onAddressCardClick = (address: AddressResponse) => {
    setAddressHandler(address);
  };

  const showCreateAddressModal = () => {
    setIsShow(prev => !prev);
  };

  return (
    <StyledSideModalWrapper>
      <AnimatePresence>
        <StyledAnimatedFiltersSectionContainer
          animate={isShow ? "animate" : "initial"}
          variants={{
            initial: { x: isMobileSideModal ? SIDE_MODAL_MOBILE_WIDTH : SIDE_MODAL_LAPTOP_WIDTH },
            animate: { x: isMobileSideModal ? -SIDE_MODAL_MOBILE_X_PADDING : -SIDE_MODAL_LAPTOP_X_PADDING },
          }}
          transition={{ duration: 0.5 }}
        >
          <AddressesSideModalInner
            closeHandler={showCreateAddressModal}
            onBackBtnClick={showCreateAddressModal}
            saveCallBack={showCreateAddressModal}
          />
        </StyledAnimatedFiltersSectionContainer>
      </AnimatePresence>
      <Column width="100%" height="max-content" flexGrow="1">
        <CloseModalWrapper closeModalHandler={closeModalHandler} mb="56px">
          <FlexGap gap="12px">
            <EscrowDetailsIcon width="24px" height="24px" color="neutral600" />

            <Text textScale="body2" color="neutral600" $fontWeight="medium">
              {t("My Delivery Addresses")}
            </Text>
          </FlexGap>
        </CloseModalWrapper>

        <FlexGap gap="24px" flexDirection="column">
          {addressesListSorted.map((address, index) => (
            <AddressCard
              key={index}
              address={address}
              currentAddress={currentAddress}
              rootId={ROUTES.createEscrow}
              cursor="pointer"
              onClick={() => onAddressCardClick(address)}
            />
          ))}

          {addressesListSorted.length < 6 && (
            <StyledAddBtn width="100%" onClick={showCreateAddressModal}>
              <Text textScale="body3" color="neutral600" $fontWeight="medium">
                {t("+ Add New Address")}
              </Text>
            </StyledAddBtn>
          )}
        </FlexGap>
      </Column>
    </StyledSideModalWrapper>
  );
};

export default DeliveryAddressesSideModal;
