import React from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "context";
import { useHandleInputDebounce } from "hooks";

import { Text, Column, InputGroup, Input, Button } from "components";
import { CloseIcon, SearchIcon } from "components/svg";
import { WhitelistModalTable } from "./components";
import { StyledSideModalWrapper } from "../styled";
import CloseModalWrapper from "../close-modal-wrapper";

import { ROUTES } from "navigation/routes";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";
import { setWhitelistModalPayload } from "store/wallets/whitelist";

import { WhitelistSideModalProps } from "./types";

const WhitelistSideModal: React.FC = () => {
  const network = useAppSelector(state => (state.modal.props as unknown as WhitelistSideModalProps).network);
  const token = useAppSelector(state => (state.modal.props as unknown as WhitelistSideModalProps).token);
  const search = useAppSelector(state => state.whitelist.whitelistModalPayload.search);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { value, handleInputChange, clearValue } = useHandleInputDebounce(search ?? "", debounceCallback);

  function debounceCallback(val: string) {
    dispatch(setWhitelistModalPayload({ page: 1, search: val }));
  }

  const redirect = () => {
    closeModalHandler();
    navigate(`/${ROUTES.wallet}/${network}/${token}/${ROUTES.whitelist}`);
  };

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  return (
    <StyledSideModalWrapper>
      <Column width="100%" height="max-content" flexGrow="1">
        <CloseModalWrapper closeModalHandler={closeModalHandler} mb="24px">
          <Text textScale="body2" color="neutral600" $fontWeight="medium">
            {t("Select withdrawal address")}
          </Text>
        </CloseModalWrapper>

        <InputGroup
          width="100%"
          startIcon={<SearchIcon width="24px" height="24px" />}
          endIcon={
            value ? <CloseIcon width="14px" cursor="pointer" color="neutral600" onClick={clearValue} /> : undefined
          }
        >
          <Input placeholder={t("Search")} value={value} onChange={handleInputChange} />
        </InputGroup>

        <Button width="100%" variant="outline" mb="36px" p="14px 20px" onClick={redirect}>
          {t("Add and manage whitelist address")}
        </Button>

        <WhitelistModalTable />
      </Column>
    </StyledSideModalWrapper>
  );
};

export default WhitelistSideModal;
