import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => state.profile.user,
    (state: AppState) => state.messages.chatsListData,
    (state: AppState) => state.messages.chatInfo.pending,
    (state: AppState) => state.messages.chatInfo.chatByReference,
    (state: AppState) => state.messages.chatListRole,
  ],
  (user, chatsListData, pending, chatByReference, chatListRole) => ({
    user,
    chatsListData,
    pending,
    chatByReference,
    chatListRole,
  }),
);
