import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BigNumber from "bignumber.js";

import { useThemeContext } from "context";

import { useGetFiatSymbolAndAbbreviation, useTooltip, useGetCurrencyRate } from "hooks";

import { Column, Image, Row, Box, Text } from "components";

import { hiddenBalance } from "pages/main/wallets/components/balance-preview";
import { formatValueToBNString, SHORT_CHAIN_NAMES, NATIVE_CURRENCIES_LOGO } from "configs/web3";

import { useAppDispatch, useAppSelector } from "store/store";
import { resetWhitelistPayload, setWhitelistPayload } from "store/wallets/whitelist";
import { resetHotWalletHistoryPayload, setWalletsHistoryPayload } from "store/wallets/wallets-history";

import { SingleNetworkProps } from "./types";

export const SingleNetworkItem: React.FC<SingleNetworkProps> = ({ network }) => {
  const rates = useAppSelector(state => state.rates.rates);
  const networks = useAppSelector(state => state.hotWallets.networks);
  const isBalanceVisible = useAppSelector(state => state.hotWallets.isBalanceVisible);

  const { getCurrencyRate } = useGetCurrencyRate();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { theme } = useThemeContext();
  const { fiatAbbreviation } = useGetFiatSymbolAndAbbreviation();

  const { targetRef: targetRefBalance, tooltip: balanceTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const { targetRef: targetRefNetwork, tooltip: networkTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const [balance, setTotalBalance] = useState("0");

  const currentChainId: keyof typeof SHORT_CHAIN_NAMES = network[Object.keys(network)[0]].chainId;

  useEffect(() => {
    if (networks && rates) {
      const res = Object.values(networks[currentChainId]).reduce((acc, { balance, publicCode }) => {
        const currentRate = getCurrencyRate(publicCode);
        return BigNumber(balance).times(currentRate).plus(acc);
      }, BigNumber(0));

      setTotalBalance(formatValueToBNString(res));
    }
  }, [networks, rates]);

  const handleRedirectToNetwork = () => {
    navigate(`${network[Object.keys(network)[0]].chainId}`);
    dispatch(resetHotWalletHistoryPayload());
    dispatch(resetWhitelistPayload());
    dispatch(setWalletsHistoryPayload({ chainId: network[Object.keys(network)[0]].chainId }));
    dispatch(setWhitelistPayload({ chainId: network[Object.keys(network)[0]].chainId }));
  };

  return (
    <Box
      cursor="pointer"
      borderRadius={theme.radii.semiMedium}
      border={`1px solid ${theme.colors.neutral300}`}
      p="18px"
      height="100%"
      onClick={handleRedirectToNetwork}
    >
      <Column justifyContent="space-between" height="100%">
        <Row>
          <Image
            width="24px"
            aspectRatio={1}
            src={NATIVE_CURRENCIES_LOGO[currentChainId]}
            alt={SHORT_CHAIN_NAMES[currentChainId]}
            variant="circle"
          />
          <Text mx="8px" textScale="body3" color="neutral600" maxWidth="300px" ref={targetRefNetwork} ellipsis>
            {SHORT_CHAIN_NAMES[currentChainId]}
          </Text>
          {networkTooltip}
        </Row>

        <Row>
          <Text
            textScale="body1"
            $fontWeight="medium"
            color="neutral800"
            maxWidth="300px"
            ref={targetRefBalance}
            ellipsis
          >
            {isBalanceVisible ? `~${BigNumber(balance).toFormatExtended(2)}` : hiddenBalance}
          </Text>
          {balanceTooltip}
          <Text mx="4px" textScale="body1" $fontWeight="medium" color="neutral800">
            {fiatAbbreviation}
          </Text>
        </Row>
      </Column>
    </Box>
  );
};
export default SingleNetworkItem;
