import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getWalletsHistory } from "../wallets/wallets-history/actions";
import { getBalanceChart, getDashboardWalletBalance } from "./actions";

import { getTotalBalance } from "configs/web3";

import { DashboardState, InitialBalanceChartPayload, NetworkBalance, PeriodStatusEnum } from "./types";
import { HistoryPayload } from "store/wallets/types";

const initialState: DashboardState = {
  error: null,
  pending: false,
  totalBalance: "0",
  networkBalances: [],
  historyPayload: {
    page: 1,
    size: 10,
  },
  historyInfo: {
    pending: false,
    data: [],
    count: 0,
  },
  balanceChart: {
    pending: false,
    chart: [],
  },
  balanceChartPayload: {
    period: PeriodStatusEnum.WEEK,
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    ...initialState,
  },
  reducers: {
    resetDashboard: () => {
      return initialState;
    },
    setNetworkBalances: (state, action: PayloadAction<NetworkBalance[]>) => {
      state.pending = false;
      state.networkBalances = action.payload;
    },
    setDashboardTransactionsPayload: (state, action: PayloadAction<Partial<HistoryPayload>>) => {
      state.historyPayload = { ...state.historyPayload, ...action.payload };
    },
    setBalanceChartPayload: (state, action: PayloadAction<Partial<InitialBalanceChartPayload>>) => {
      state.balanceChartPayload = { ...state.balanceChartPayload, ...action.payload };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getDashboardWalletBalance.fulfilled, (state, action) => {
        state.error = null;
        state.totalBalance = getTotalBalance(action.payload, "fiat");
      })
      .addCase(getDashboardWalletBalance.pending, state => {
        state.pending = true;
      })
      .addCase(getDashboardWalletBalance.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(getWalletsHistory.fulfilled, (state, action) => {
        state.historyInfo = { ...action.payload, pending: false };
        state.error = null;
      })
      .addCase(getWalletsHistory.pending, state => {
        state.historyInfo.pending = true;
      })
      .addCase(getWalletsHistory.rejected, (state, action) => {
        if (action.payload) {
          state.historyInfo = initialState.historyInfo;
          state.error = action.payload;
        }
      })
      .addCase(getBalanceChart.fulfilled, (state, action) => {
        state.balanceChart.chart = action.payload;
        state.error = null;
        state.balanceChart.pending = false;
      })
      .addCase(getBalanceChart.pending, state => {
        state.balanceChart.pending = true;
      })
      .addCase(getBalanceChart.rejected, (state, action) => {
        if (action.payload) {
          state.balanceChart = initialState.balanceChart;
          state.error = action.payload;
        }
      });
  },
});

export const { resetDashboard, setNetworkBalances, setDashboardTransactionsPayload, setBalanceChartPayload } =
  dashboardSlice.actions;

export default dashboardSlice;
