import { useAppDispatch, useAppSelector } from "store/store";
import { setRoleTab } from "store/escrow/create-escrow";

import { AccountType } from "store/profile/types";
import { Roles } from "pages/main/create-escrow/types";
import { useHandleDataProps } from "../types";

import { TABS } from "configs";

export const useHandleData = ({ setFieldValue, setFieldTouched, values }: useHandleDataProps) => {
  const user = useAppSelector(state => state.profile.user);

  const dispatch = useAppDispatch();

  const getTabs = () => {
    if (user?.accountType === AccountType.PERSONAL) {
      const filteredTabs = TABS.createEscrowRole.filter(item => {
        return item.title !== "Broker";
      });
      return filteredTabs;
    }
    return TABS.createEscrowRole;
  };

  const onTabClick = async (tab: number) => {
    const roleValues = Object.values(Roles).filter(value => {
      if (user?.accountType === AccountType.PERSONAL) {
        return value !== "broker";
      }
      return value;
    });

    await setFieldValue("role.role", roleValues[tab]);
    await setFieldValue("role.sellersEmail", "");
    await setFieldTouched("role.sellersEmail", false);
    await setFieldValue("role.buyersEmail", "");
    await setFieldTouched("role.buyersEmail", false);

    if (user?.accountType === AccountType.BUSINESS) {
      await setFieldValue("role.createAWidget", true);
      await setFieldTouched("role.createAWidget", false);
    }

    dispatch(setRoleTab(tab));
  };

  const handleSwitchWidget = async () => {
    await setFieldValue("role.createAWidget", !values.role.createAWidget);
  };

  return { getTabs, onTabClick, handleSwitchWidget };
};
