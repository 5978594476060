import { Modal2FASuccess } from "components/svg";

import { useTranslation } from "context";

import { useAppDispatch } from "store/store";
import { hideModal, showModal } from "store/modal";

import { ModalProps } from "store/modal/types";
import { ActionModalProps } from "components/modal/components/action-modal/types";

export const useShowModals = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const callSuccessModal = () => {
    const _showModal = showModal as ModalProps<ActionModalProps>;

    dispatch(
      _showModal({
        modalName: "actionModal",
        rootId: "settings",
        clickOutsideHandler: closeModalHandler,
        props: {
          icon: <Modal2FASuccess height="72px" width="72px" />,
          title: t("Setup complete!"),
          text: t("You've successfully set up a new authenticator app"),
          btnArr: [
            {
              buttonText: t("OK"),
              callback: closeModalHandler,
            },
          ],
        },
      }),
    );
  };

  return {
    callSuccessModal,
  };
};
