import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "primary", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 40 40" color={color} {...props}>
      <circle cx="20" cy="20" r="20" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.754 20.149L18.525 28.118C18.746 29.111 20.065 29.325 20.588 28.453L28.837 14.704C29.288 13.954 28.748 13 27.873 13H12.322C11.319 13 10.817 14.212 11.526 14.921L16.754 20.149Z"
        stroke={theme.colors.white}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.84 13.5586L16.75 20.1486"
        stroke={theme.colors.white}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
