import React from "react";

import { Button, Text, Column, FlexGap, StyledLink } from "components";
import { StyledFaceBiometricModalWrapper, StyledCameraContainerWrapper } from "./styled";
import CloseModalWrapper from "../close-modal-wrapper";
import { CameraLoading } from "./components";

import { useTranslation } from "context";

import { useHandlePageVisibility, useMatchBreakpoints } from "hooks";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";

import { AutomaticFaceCaptureModalProps } from "./types";
import { ELEMENTS_ID } from "configs";

const AutomaticFaceCaptureModal: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useMatchBreakpoints();
  const { isCameraLoaded, isShowHintMessage, title, verifyDescription, verifyCallback, closeCallback } = useAppSelector(
    state => state.modal.props as unknown as AutomaticFaceCaptureModalProps,
  );

  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    closeCallback?.();
    dispatch(hideModal());
  };

  useHandlePageVisibility(closeModalHandler);

  return (
    <StyledFaceBiometricModalWrapper isShowHintMessage={isShowHintMessage} isVerify={!!verifyDescription}>
      <Column width="100%" px={{ _: "12px", laptop: "24px" }}>
        <CloseModalWrapper closeModalHandler={closeModalHandler}>
          {isMobile && (
            <Text color="neutral600" textAlign="center" textScale="body2" $fontWeight="bold">
              {title}
            </Text>
          )}
        </CloseModalWrapper>

        {!isMobile && (
          <Text color="neutral600" textAlign="center" textScale="body2" $fontWeight="bold">
            {title}
          </Text>
        )}

        <Text
          wordBreak="break-word"
          color="neutral600"
          mt={{ _: "18px", laptop: "12px" }}
          textAlign={{ _: "left", tablet: "center" }}
          textScale="caption1"
        >
          {t("Position the camera at a distance that fully covers the oval guideline")}
        </Text>
      </Column>

      <StyledCameraContainerWrapper isVerify={!!verifyDescription} id={ELEMENTS_ID.cameraContainer}>
        {!isCameraLoaded && <CameraLoading />}
      </StyledCameraContainerWrapper>

      {isMobile && (
        <FlexGap width="100%" gap="16px" mb={verifyDescription ? "0" : "48px"} px="16px" justifyContent="center">
          <Button width="100%" maxWidth={{ _: "100%", laptop: "170px" }} variant="outline" onClick={closeModalHandler}>
            {t("Cancel")}
          </Button>
        </FlexGap>
      )}

      {verifyDescription && (
        <Text textScale="caption1" color="neutral600" mt="24px">
          {verifyDescription}
        </Text>
      )}

      {verifyCallback && (
        <StyledLink
          mt="12px"
          color="primary"
          onClick={verifyCallback}
          textScale="caption1"
          $fontWeight="bold"
          textAlign="center"
        >
          {t("Verify with codes")}
        </StyledLink>
      )}
    </StyledFaceBiometricModalWrapper>
  );
};

export default AutomaticFaceCaptureModal;
