import { createGlobalStyle } from "styled-components";

export const SCROLL_WIDTH = "8px";

const GlobalStyle = createGlobalStyle`
html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
    font-size: 16px;
  }
  ol,
  ul {
    list-style: disc;
    list-style-position: inside;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  [role="button"] {
    cursor: pointer;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* Number */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  /* Scrollbar */
  ::-webkit-scrollbar {
    width: ${SCROLL_WIDTH};
  }

  ::-webkit-scrollbar-thumb {
    background-image: linear-gradient(180deg, #708AD4 50%, #D0368A 99%);
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    border-radius: 100px;
  }

  /* Slider */ 
  input[type=range] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
  }
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  input[type=range]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }
  input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
    /* Hides the slider so custom styles can be added */
    background: transparent; 
    border-color: transparent;
    color: transparent;
  }
  html{
    scroll-behavior: smooth;
  }
  body {
    line-height: 1;
    font-size: 16px;
    overflow-x: hidden;
    min-width: 100vw; 
    min-height: 100vh;
    scroll-behavior: smooth;
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
    background-color: ${({ theme }) => (theme.isDark ? theme.colors.neutral0 : theme.colors.neutral100)};
    img {
      height: auto;
      max-width: 100%;
    }
  }
  #root {
    height: 100%;
    min-height: 100vh;
  }
  &:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.colors.neutral0} inset;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.neutral800};
  }

  .mitekDisplayContainerCustom {
    background-color: transparent !important;
  }

  .mitek-voice-container {
    z-index: 105 !important;
    width: 288px !important;
    height: 200px !important;
    background: none !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin-top: 50px;

    ${({ theme }) => theme.mediaQueries.tablet}  {
      width: 416px !important;
  }
}

.ripple-circle, .ripple-circle2  {
    top: 50% !important;
    border: 1px solid ${({ theme }) => theme.colors.primaryLight} !important;
}


#mitekVoiceRecordButton {
top: 50% !important;
opacity: 0 !important;
cursor: pointer;
}

.mitek-voice-container-ui {
  border: none !important;

  & #mitekHintMessage {
  display: none !important;
}
}

#mitekVoicePhrase {
  display: none !important;
}
`;

export default GlobalStyle;
