import styled from "styled-components";
import { space, typography } from "styled-system";

import { getBorderStyles } from "../input";
import { TextareaProps } from "./types";

export const Textarea = styled.textarea<TextareaProps>`
  height: 90px;
  background-color: transparent;
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  color: ${({ theme }) => theme.colors.neutral800};
  font-family: ${({ theme }) => theme.fonts.ns};
  display: block;
  resize: none;
  font-size: 14px;
  font-weight: 600;
  outline: 0;
  padding: 16px;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral600};
    opacity: 0.6;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey1};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.neutral1000}24;
    cursor: not-allowed;
    border: none;
  }

  &:focus:not(:disabled) {
    border: 1px solid ${({ theme }) => theme.colors.neutral400};
  }

  &:hover:not(:disabled) {
    border: 1px solid ${({ theme }) => theme.colors.neutral400};
  }

  ${getBorderStyles}

  ${typography}
  ${space}
`;
