import React, { PropsWithChildren, createContext, useContext, useEffect } from "react";
import { io } from "socket.io-client";

import { useAppSelector } from "store/store";
import { SOCKET_EVENT_NAMES } from "./socket-event-names";

import { useOnSocketEventHandlers } from "./hooks";
import { timeout } from "services/axios";

import { SendMessagePayload } from "store/messages/types";
import { useEmitSocketEventHandlers } from "./hooks";

type ContextType = {
  sendMessage: (data: SendMessagePayload, reference: string) => void;
  readMessage: (reference: string, messageId: number) => void;
};

const socket = io(`${process.env.REACT_APP_SOCKET_URL}` as string, {
  transports: ["websocket"],
  autoConnect: false,
  reconnection: true,
  reconnectionAttempts: 3,
  reconnectionDelay: 3_000,
  withCredentials: true,
});

const SocketContext = createContext<ContextType | null>(null);

const SocketContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const isLoggedIn = useAppSelector(state => state.auth.isLoggedIn);

  const {
    onCompleteKycHandler,
    onUpdateUserHandler,
    onSocketErrorHandler,
    onUpdateWalletHandler,
    onCurrenciesRatesUpdatedHandler,
    onWalletHistoryUpdatedHandler,
    onSwapHistoryUpdatedHandler,
    onBuyHistoryUpdatedHandler,
    onChatReceiveMessage,
    onChatCreateMessage,
    onTransactionCompletedHandler,
    onChatSendMessageException,
    onChatReadMessageUpdate,
    onAddNewEscrow,
    onEscrowUpdate,
    onAddNewNotification,
    onUnreadNotificationCountChange,
  } = useOnSocketEventHandlers();

  const { sendMessage, readMessage } = useEmitSocketEventHandlers(socket);

  useEffect(() => {
    if (isLoggedIn) {
      socket.connect();
    } else {
      socket.disconnect();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    socket.on(SOCKET_EVENT_NAMES.complete_kyc, onCompleteKycHandler);
    socket.on(SOCKET_EVENT_NAMES.exception, onSocketErrorHandler);
    socket.on(SOCKET_EVENT_NAMES.update_current_user, onUpdateUserHandler);
    socket.on(SOCKET_EVENT_NAMES.balance_updated, onUpdateWalletHandler);
    socket.on(SOCKET_EVENT_NAMES.currencies_rates_updated, onCurrenciesRatesUpdatedHandler);
    socket.on(SOCKET_EVENT_NAMES.swap_updated, onSwapHistoryUpdatedHandler);
    socket.on(SOCKET_EVENT_NAMES.crypto_purchase_updated, onBuyHistoryUpdatedHandler);
    socket.on(SOCKET_EVENT_NAMES.wallet_history_updated, onWalletHistoryUpdatedHandler);
    socket.on(SOCKET_EVENT_NAMES.receive_message, onChatReceiveMessage);
    socket.on(SOCKET_EVENT_NAMES.created_message, onChatCreateMessage);
    socket.on(SOCKET_EVENT_NAMES.transaction_completed, onTransactionCompletedHandler);
    socket.on(SOCKET_EVENT_NAMES.send_message_exception, onChatSendMessageException);
    socket.on(SOCKET_EVENT_NAMES.read_message_update, onChatReadMessageUpdate);
    socket.on(SOCKET_EVENT_NAMES.create_escrow, onAddNewEscrow);
    socket.on(SOCKET_EVENT_NAMES.update_escrow, onEscrowUpdate);
    socket.on(SOCKET_EVENT_NAMES.notification, onAddNewNotification);
    socket.on(SOCKET_EVENT_NAMES.unread_notification_count, onUnreadNotificationCountChange);
    socket.on(SOCKET_EVENT_NAMES.kicked, () => {
      setTimeout(() => {
        socket.connect();
      }, timeout);
    });

    return () => {
      socket.off(SOCKET_EVENT_NAMES.complete_kyc);
      socket.off(SOCKET_EVENT_NAMES.update_current_user);
      socket.off(SOCKET_EVENT_NAMES.exception);
      socket.off(SOCKET_EVENT_NAMES.balance_updated);
      socket.off(SOCKET_EVENT_NAMES.currencies_rates_updated);
      socket.off(SOCKET_EVENT_NAMES.swap_updated);
      socket.off(SOCKET_EVENT_NAMES.crypto_purchase_updated);
      socket.off(SOCKET_EVENT_NAMES.wallet_history_updated);
      socket.off(SOCKET_EVENT_NAMES.kicked);
      socket.off(SOCKET_EVENT_NAMES.receive_message);
      socket.off(SOCKET_EVENT_NAMES.created_message);
      socket.off(SOCKET_EVENT_NAMES.transaction_completed);
      socket.off(SOCKET_EVENT_NAMES.send_message_exception);
      socket.off(SOCKET_EVENT_NAMES.read_message_update);
      socket.off(SOCKET_EVENT_NAMES.create_escrow);
      socket.off(SOCKET_EVENT_NAMES.update_escrow);
      socket.off(SOCKET_EVENT_NAMES.notification);
      socket.off(SOCKET_EVENT_NAMES.unread_notification_count);
    };
  }, []);

  const context = { sendMessage, readMessage };

  return <SocketContext.Provider value={context}>{children}</SocketContext.Provider>;
};

export const useSocketContext = () => {
  const socketContext = useContext(SocketContext);
  if (socketContext === null) {
    throw new Error("Socket context is not found");
  }
  return socketContext;
};

export default SocketContextProvider;
