import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

import { useAppSelector } from "store/store";

import { ROUTES } from "navigation/routes";

const TokenHandler: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const isLoggedIn = useAppSelector(state => state.auth.isLoggedIn);

  if (isLoggedIn) {
    return <Navigate to={ROUTES.home} replace />;
  }

  return <>{children}</>;
};

export default TokenHandler;
