import styled, { css } from "styled-components";
import { space, typography, layout, opacity, flexbox } from "styled-system";
import { TextProps, ThemedProps, Scales } from "./types";
import { styles, fontWeights } from "./theme";
import { getStylesFromResponsiveValue } from "utils/helpers";

export const getEllipsis = ({ ellipsis }: ThemedProps) => {
  // if you use ellipsis in components with no width restrictions and ellipsis don't work correctly
  // for parent component add property width, and add wrapper Box (with display: grid) to component Text ellipsis
  if (ellipsis) {
    return css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `;
  }
};

export const wordBreak = ({ wordBreak }: ThemedProps) => {
  if (wordBreak) {
    return css`
      word-break: ${wordBreak};
    `;
  }
};

export const Text = styled.p<TextProps>`
  color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.neutral900)};
  font-family: ${({ theme }) => theme.fonts.ns};
  direction: ${({ direction }) => direction};
  white-space: ${({ whiteSpace }) => whiteSpace};
  word-break: ${({ wordBreak }) => wordBreak};
  text-transform: ${({ textTransform }) => textTransform};

  ${({ textScale }) => textScale && getStylesFromResponsiveValue<Scales>(textScale, styles)}

  ${wordBreak}
  ${fontWeights}
  ${getEllipsis}
  ${space}
  ${typography}
  ${layout}
  ${opacity}
  ${flexbox}
`;

export default Text;
