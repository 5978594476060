import React from "react";

import { Column, Flex, StyledLink, Text } from "components";
import { ModalErrorIcon } from "components/svg";
import { StyledModalWrapper } from "./styled";
import CloseModalWrapper from "../close-modal-wrapper";

import { useAppDispatch } from "store/store";

import { useTranslation } from "context";
import { SHORT_CHAIN_NAMES } from "configs/web3";

import { hideModal } from "store/modal";
import { useHandleData } from "./hooks";
import { useSupport } from "hooks";

const BuyCryptoModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { supportHandler } = useSupport();
  const { iframeSrc, onramperTokenId, tokenId, fireBlocksWalletAddress, chainId } = useHandleData();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  return (
    <StyledModalWrapper width={{ mobileM: "342px", mobileL: "392px", tablet: "418px" }}>
      {onramperTokenId && fireBlocksWalletAddress ? (
        <>
          <CloseModalWrapper mt="18px" px="18px" closeModalHandler={closeModalHandler} />

          <iframe
            src={iframeSrc}
            height="648px"
            width="100%"
            title="Onramper widget"
            allow="accelerometer; autoplay; camera; gyroscope; payment"
          ></iframe>
        </>
      ) : (
        <Column padding={{ _: "12px", tablet: "24px" }} alignItems="center">
          <CloseModalWrapper closeModalHandler={closeModalHandler} />

          <Flex mt="26px" mb="16px" justifyContent="center" width="100%">
            <ModalErrorIcon height="72px" width="72px" />
          </Flex>

          <Text color="neutral600" textAlign="center" textScale="body2">
            {t("Sorry, %token% is not available to buy in %network% network.", {
              token: tokenId,
              network: SHORT_CHAIN_NAMES[chainId],
            })}
          </Text>

          <StyledLink
            color="neutral500"
            mt="12px"
            mb={{ _: "20px", tablet: "30px" }}
            textScale="caption2"
            textAlign="center"
            $fontWeight="medium"
            onClick={() => supportHandler()}
          >
            {t("Do you need help?")}
          </StyledLink>
        </Column>
      )}
    </StyledModalWrapper>
  );
};

export default BuyCryptoModal;
