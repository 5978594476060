import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral600", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" width="24px" {...props} color="transparent">
      <path
        id="Path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 21H5.5C3.567 21 2 19.433 2 17.5V17.5C2 15.567 3.567 14 5.5 14H18.5C20.433 14 22 15.567 22 17.5V17.5C22 19.433 20.433 21 18.5 21Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M18.244 17.499C18.244 17.364 18.135 17.255 18 17.256C17.865 17.256 17.756 17.365 17.756 17.5C17.756 17.635 17.865 17.744 18 17.744C18.135 17.744 18.244 17.635 18.244 17.499"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_3"
        d="M14.244 17.499C14.244 17.364 14.135 17.255 14 17.256C13.865 17.256 13.756 17.365 13.756 17.5C13.756 17.635 13.865 17.744 14 17.744C14.134 17.744 14.244 17.635 14.244 17.499"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_4"
        d="M10.244 17.499C10.244 17.364 10.135 17.255 10 17.256C9.865 17.256 9.756 17.365 9.756 17.5C9.756 17.635 9.865 17.744 10 17.744C10.134 17.744 10.244 17.635 10.244 17.499"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_5"
        d="M6.244 17.499C6.244 17.364 6.135 17.255 6 17.256C5.865 17.256 5.756 17.365 5.756 17.5C5.756 17.635 5.865 17.744 6 17.744C6.134 17.744 6.244 17.635 6.244 17.499"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_6"
        d="M19 8.57275V5.96875"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_7"
        d="M15 8.604V6"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_8"
        d="M10.121 3.879C11.293 5.051 11.293 6.95 10.121 8.122C8.94902 9.294 7.05002 9.294 5.87802 8.122C4.70602 6.95 4.70602 5.051 5.87802 3.879C7.05002 2.707 8.95002 2.707 10.121 3.879"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_9"
        d="M19 5.96788L11 6.00087"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
