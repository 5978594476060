import { css, DefaultTheme } from "styled-components";
import { scales } from "./types";

export const styles = (theme: DefaultTheme) => {
  return {
    [scales.h1]: css`
      line-height: 1;
      font-size: 24px;

      ${theme.mediaQueries.tablet} {
        font-size: 28px;
      }

      ${theme.mediaQueries.laptop} {
        font-size: 64px;
      }
    `,
    [scales.h2]: css`
      line-height: 1.16;
      font-size: 22px;

      ${theme.mediaQueries.tablet} {
        font-size: 24px;
      }

      ${theme.mediaQueries.laptop} {
        font-size: 48px;
      }
    `,
    [scales.h3]: css`
      line-height: 1.2;
      font-size: 32px;

      ${theme.mediaQueries.tablet} {
        font-size: 40px;
      }
    `,
    [scales.h4]: css`
      line-height: 0.8;
      font-size: 20px;

      ${theme.mediaQueries.tablet} {
        font-size: 32px;
      }
    `,
  };
};
