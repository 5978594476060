import React from "react";
import BigNumber from "bignumber.js";

import { useHandleData, useShowModals } from "./hooks";
import { useTranslation } from "context";
import { useAmountToDepositHandlers, useCurrencies, useGetCurrencyRate } from "hooks";

import { useAppSelector } from "store/store";

import { Column, TabPanel, Text, WalletErrorMessage, FlexGap, AmountToDeposit, Skeleton } from "components";
import { ChooseNetwork, ChooseToken } from "pages/main/wallets/components/deposit/components";

import { TABS } from "configs";

import { CurrencyProps } from "./types";
import { Roles } from "../../types";

import { InitialValues } from "../../hooks/types";

const Currency: React.FC<CurrencyProps> = ({ setFieldValue, setFieldTouched, errors, values, touched }) => {
  const { coversBrokersCommissionTab, coversEscryptoFeeTab, pending, error } = useAppSelector(
    state => state.createEscrow,
  );

  const { currentRate } = useGetCurrencyRate(values.currency.currency);

  const tokenList = useCurrencies(values.currency.network);

  const { t } = useTranslation();

  const { setNetwork, setCurrency, token, onTabClick } = useHandleData({
    setFieldValue,
    setFieldTouched,
    values,
  });
  const { showNetworksModal, showTokenModal } = useShowModals({ setNetwork, setCurrency, values });
  const { onUserInput, onBlur } = useAmountToDepositHandlers<InitialValues>({
    setFieldTouched,
    setFieldValue,
  });

  return (
    <Column mt="24px" height="100%">
      <ChooseNetwork
        chainId={values.currency.network}
        isDifferentNetwork={false}
        isUnsupportedNetwork={false}
        onButtonClick={showNetworksModal}
      />

      <ChooseToken
        currency={tokenList && Object.values(tokenList).find(item => item.publicCode === values.currency.currency)}
        currencyKey={values.currency.currency}
        onButtonClick={showTokenModal}
      />

      <Column mb="24px">
        <AmountToDeposit
          value={values.currency.amount}
          title="Amount of the transaction"
          onChangeInput={value => onUserInput(value, "currency.amount")}
          onBlur={() => onBlur("currency.amount")}
          isError={!!errors.currency?.amount}
          isTouched={!!touched.currency?.amount}
          currency={token}
          currentRate={currentRate}
          isHaveFiatEquivalent
        />
        {errors.currency?.amount && touched.currency?.amount && (
          <Text textScale="caption2" color="red" mt="4px">
            {errors.currency?.amount}
          </Text>
        )}
      </Column>

      {values.role.role === Roles.broker && (
        <Column mb="24px">
          <AmountToDeposit
            value={values.currency.brokersCommission}
            title="Broker's commission"
            onChangeInput={value => onUserInput(value, "currency.brokersCommission")}
            onBlur={() => onBlur("currency.brokersCommission")}
            isError={!!errors.currency?.brokersCommission}
            isTouched={!!touched.currency?.brokersCommission}
            currency={token}
            currentRate={currentRate}
            isHaveFiatEquivalent
          />
          {errors.currency?.brokersCommission && touched.currency?.brokersCommission && (
            <Text textScale="caption2" color="red" mt="4px">
              {errors.currency?.brokersCommission}
            </Text>
          )}
        </Column>
      )}

      {values.role.role === Roles.broker && (
        <Column>
          <Text textScale="caption1" color="neutral500" mb="8px">
            {t("Broker's commission is paid by")}
          </Text>

          <TabPanel
            tabs={TABS.createEscrowCommissionPayer}
            activeTab={coversBrokersCommissionTab}
            onTabClick={tab => onTabClick(tab)}
            maxWidth="100% !important"
            dontScrollTop
            mb="24px"
          />
        </Column>
      )}

      <Column>
        <Text textScale="caption1" color="neutral500" mb="8px">
          {t("Who covers Zert fee?")}
        </Text>

        <TabPanel
          tabs={TABS.createEscrowCommissionPayer}
          activeTab={coversEscryptoFeeTab}
          onTabClick={tab => onTabClick(tab, true)}
          maxWidth="100% !important"
          disabled={values.role.role === Roles.seller && values.role.createAWidget}
          dontScrollTop
          mb="12px"
        />
      </Column>

      <FlexGap alignItems="center" mb="8px" gap="4px">
        <Text textScale="caption2" color="neutral500">
          {t("Zert Fee:")}
        </Text>
        {pending ? (
          <Skeleton width="80px" height="16px" />
        ) : (
          <Text textScale="caption2" $fontWeight="medium" color="neutral800">
            {values.currency.amount && !error
              ? `${BigNumber(values.currency.escryptoFee ? values.currency.escryptoFee : "0").toFormatExtended(8)} ${
                  values.currency.currency
                }`
              : `${BigNumber("0").toFormatExtended(8)} ${values.currency.currency}`}
          </Text>
        )}
      </FlexGap>

      {!values.currency.escryptoFee && !errors.currency?.amount && error ? (
        <WalletErrorMessage
          message={t("Sorry, something went wrong while estimating fee, try entering amount of the transaction again")}
          variant="warning"
          mt="18px"
        />
      ) : null}

      {values.role.role === Roles.seller && values.role.createAWidget && (
        <WalletErrorMessage
          message={t("In case a widget is added, commission is covered by the Seller")}
          variant="warning"
          mt="18px"
        />
      )}
    </Column>
  );
};

export default Currency;
