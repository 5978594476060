import styled from "styled-components";

export const StyledCountWrapper = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.primary};
  padding-left: 4px;
  padding-right: 4px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  position: absolute;
  min-height: 20px;
  min-width: 20px;
  top: -8px;
  left: 12px;
`;
