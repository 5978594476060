import React from "react";
// Components
import { Text, RowBetween, Row, Box, Image, Flex } from "components";
import { ArrowRightIcon } from "components/svg";
import { StyledHr } from "components/modal/components/styled";
import { StyledBox, StyledIconBox } from "./styled";
// Context
import { useTranslation } from "context";
// Store
import { useAppSelector } from "store/store";
// Hooks
import { useMatchBreakpoints, useTooltip } from "hooks";
// Types
import { SwapFromToInfoProps } from "../../types";
import { formatBigNumberToFixed } from "configs/web3";

const FromToInfo: React.FC<SwapFromToInfoProps> = ({ fromAmount, toAmount }) => {
  const swap = useAppSelector(state => state.swap);

  const { t } = useTranslation();
  const { isMobile } = useMatchBreakpoints();

  const currentFromToken = swap?.from?.token;
  const currentToToken = swap?.to?.token;

  const { targetRef: targetRefFrom, tooltip: fromTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const { targetRef: targetRefTo, tooltip: toTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  return (
    <Box mt={{ _: "24px", tablet: "0px" }} mb={{ _: "32px", tablet: "0px" }} width="100%">
      {!isMobile && <StyledHr my="32px" />}

      {!isMobile && (
        <RowBetween>
          <Text textScale="caption1" color="neutral500">
            {t("From")}
          </Text>

          <ArrowRightIcon width="24px" height="24px" color="neutral500" />

          <Text textScale="caption1" color="neutral500">
            {t("To")}
          </Text>
        </RowBetween>
      )}

      <Flex flexDirection={{ _: "column", tablet: "row" }} justifyContent={{ _: "center", tablet: "space-between" }}>
        <StyledBox>
          {isMobile && (
            <Text textScale="caption1" color="neutral500">
              {t("From")}
            </Text>
          )}

          <Row mt="24px">
            <Box minWidth="24px" minHeight="24px">
              <Image
                src={currentFromToken?.logoURI ?? ""}
                alt={currentFromToken?.symbol}
                variant="circle"
                width="24px"
                aspectRatio={1}
              />
            </Box>

            <Text
              textScale="body3"
              mx="12px"
              $fontWeight="medium"
              maxWidth={{ _: "160px", tablet: "80px" }}
              ref={targetRefFrom}
              ellipsis
            >
              {formatBigNumberToFixed(fromAmount, 8, currentFromToken?.decimals)}
            </Text>
            {fromTooltip}

            <Text textScale="body3" $fontWeight="medium">
              {currentFromToken?.symbol}
            </Text>
          </Row>
        </StyledBox>

        {isMobile && (
          <StyledIconBox>
            <ArrowRightIcon width="24px" height="24px" color="neutral500" />
          </StyledIconBox>
        )}

        <StyledBox mt={{ _: "20px", tablet: "0px" }}>
          {isMobile && (
            <Text textScale="caption1" color="neutral500">
              {t("To")}
            </Text>
          )}
          <Row mt="24px" justifyContent={{ _: "flex-start", tablet: "flex-end" }}>
            <Box minWidth="24px" minHeight="24px">
              <Image
                src={currentToToken?.logoURI ?? ""}
                alt={currentToToken?.symbol}
                variant="circle"
                width="24px"
                aspectRatio={1}
              />
            </Box>

            <Text
              mx="12px"
              textScale="body3"
              $fontWeight="medium"
              maxWidth={{ _: "160px", tablet: "80px" }}
              ref={targetRefTo}
              ellipsis
            >
              {formatBigNumberToFixed(toAmount, 8, currentToToken?.decimals)}
            </Text>
            {toTooltip}

            <Text textScale="body3" $fontWeight="medium">
              {currentToToken?.symbol}
            </Text>
          </Row>
        </StyledBox>
      </Flex>
      {!isMobile && <StyledHr my="32px" mb="24px" />}
    </Box>
  );
};

export default FromToInfo;
