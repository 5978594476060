import invariant from "tiny-invariant";
import { BaseCurrency } from "./base-currency";
import { Currency } from "./currency";
import { ChainId } from "../types";

import { checkValidAddress, validateAndParseAddress } from "../utils/validate-and-parse-address";
import { isAddressSolana } from "configs/solana";
import { isAddressBitcoin } from "configs/bitcoin";

/**
 * Represents an ERC20 token with a unique address and some metadata.
 */
export class Token extends BaseCurrency {
  public readonly isNative = false;
  public readonly isToken = true;

  /**
   * The contract address on the chain on which this token lives
   */
  public readonly address: string;

  /**
   *
   * @param chainId {@link BaseCurrency#chainId}
   * @param address The contract address on the chain on which this token lives
   * @param publicCode The currecy code of token to which network it exist
   * @param decimals {@link BaseCurrency#decimals}
   * @param symbol {@link BaseCurrency#symbol}
   * @param name {@link BaseCurrency#name}
   * @param bypassChecksum If true it only checks for length === 42, startsWith 0x and contains only hex characters
   */
  public constructor(
    chainId: ChainId,
    address: string,
    decimals: number,
    symbol: string,
    name: string,
    publicCode: string,
    logoURI?: string,
    bypassChecksum?: boolean,
  ) {
    super(chainId, decimals, symbol, name, publicCode, address, logoURI);
    if (isAddressSolana(address) || isAddressBitcoin(address)) {
      this.address = address;
    } else if (bypassChecksum) {
      this.address = checkValidAddress(address);
    } else {
      this.address = validateAndParseAddress(address);
    }
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: Currency): boolean {
    return (
      other.isToken && this.chainId === other.chainId && this.address.toLowerCase() === other.address.toLowerCase()
    );
  }

  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  public sortsBefore(other: Token): boolean {
    invariant(this.chainId === other.chainId, "CHAIN_IDS");
    invariant(this.address.toLowerCase() !== other.address.toLowerCase(), "ADDRESSES");
    return this.address.toLowerCase() < other.address.toLowerCase();
  }

  /**
   * Return this token, which does not need to be wrapped
   */

  // TODO: check in future if this function is needed

  // public get wrapped(): Token {
  //   return this;
  // }
}
