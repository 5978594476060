import React from "react";

import { useThemeContext } from "context";

import { Svg } from "components";

import { SvgProps } from "components/svg/types";

const Icon: React.FC<SvgProps> = ({ color = "blue", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6597 13.4146L10.5857 6.3406L14.1227 2.80359C14.5132 2.41311 15.147 2.41311 15.5375 2.80359L21.1967 8.4628C21.5872 8.85329 21.5872 9.48712 21.1967 9.87761L17.6597 13.4146Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.17815 19.9107L4.08963 16.8222C2.20795 14.9405 2.20795 11.8887 4.08963 10.007L7.7561 6.34058C8.53777 5.55891 9.80402 5.55891 10.5857 6.34058L17.6597 13.4146C18.4414 14.1963 18.4414 15.4625 17.6597 16.2442L13.9933 19.9107C12.1116 21.7924 9.05984 21.7924 7.17815 19.9107Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5373 6.34052L14.8299 7.04792"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6594 8.46283L16.952 9.17023"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8792 14.122C12.8792 15.7795 11.5364 17.1223 9.87891 17.1223C8.22145 17.1223 6.87865 15.7795 6.87865 14.122C6.87865 12.4646 8.22145 11.1218 9.87891 11.1218C11.5364 11.1218 12.8799 12.4653 12.8792 14.122"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
