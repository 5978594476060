import { TABS, tabsIndex } from "configs";
import { useAppDispatch, useAppSelector } from "store/store";
import { setColdStorageTab } from "store/wallets/cold-wallets";
import { useSetActiveTab } from "hooks";

export const useHandleTabAndFilter = () => {
  const activeTab = useAppSelector(state => state.coldWallets.coldWallets.activeTab);

  const dispatch = useAppDispatch();

  const onTabClick = (index: number) => {
    dispatch(setColdStorageTab(index));
  };

  useSetActiveTab(tabsIndex.coldStorage, setColdStorageTab);

  return {
    tabs: TABS.coldStorage,
    activeTab,
    pending: false,
    options: [],
    selectedOption: null,
    isSelectShow: false,
    onTabClick,
    handleSelectChange: () => {},
  };
};
