const tokensLogo = {
  ETH: "/images/tokens/ETH.webp",
  WETH: "/images/tokens/WETH.webp",
  MATIC: "/images/tokens/MATIC.webp",
  WMATIC: "/images/tokens/MATIC.webp",
  BNB: "/images/tokens/BNB.webp",
  WBNB: "/images/tokens/BNB.webp",
  AVAX: "/images/tokens/AVAX.webp",
  WAVAX: "/images/tokens/AVAX.webp",
  BTC: "/images/tokens/BTC.webp",
  WBTC: "/images/tokens/WBTC.webp",
  WXRP: "/images/tokens/XRP.webp",
  SOL: "/images/tokens/SOL.webp",
  BUSD: "/images/tokens/BUSD.webp",
  EUROC: "/images/tokens/EUROC.webp",
  PAX: "/images/tokens/PAX.webp",
  TUSD: "/images/tokens/TUSD.webp",
  USDC: "/images/tokens/USDC.webp",
  USDT: "/images/tokens/USDT.webp",
};

export { tokensLogo };
