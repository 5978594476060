import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral500", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent" stroke={theme.colors[color]}>
      <path
        d="M13.499 13.48C13.361 13.481 13.25 13.593 13.25 13.731C13.25 13.869 13.362 13.981 13.5 13.98C13.638 13.98 13.75 13.868 13.75 13.73C13.75 13.592 13.638 13.48 13.499 13.48"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 6.283V6C3 4.343 4.343 3 6 3H18C19.657 3 21 4.343 21 6V15C21 16.657 19.657 18 18 18H17"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.325 20.922C15.205 21.356 17 19.928 17 17.999V9.38705C17 7.99005 16.036 6.77805 14.675 6.46405L5.45 4.33505C4.196 4.04505 3 4.99705 3 6.28305V16.152C3 17.549 3.964 18.761 5.325 19.075L13.325 20.922V20.922Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
