import { useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { useWallet } from "@solana/wallet-adapter-react";

import { SUPPORTED_CHAIN_IDS, SUPPORTED_ETH_CHAIN_ID, isSupportedChain, networkConnection } from "configs/web3";
import { Network } from "@web3-react/network";
import { useParams } from "react-router-dom";

export const useSyncNetworkConnection = () => {
  const { connector, chainId } = useWeb3React();
  const { network: paramsChainId } = useParams();
  const { connected } = useWallet();

  // Keep the network connector in sync with any active user connector to prevent chain-switching on wallet disconnection.
  useEffect(() => {
    const chainIdFromRoute =
      paramsChainId && SUPPORTED_CHAIN_IDS.includes(+paramsChainId) ? +paramsChainId : SUPPORTED_ETH_CHAIN_ID;
    const supportedChainId = chainId ? chainId : chainIdFromRoute;

    if (supportedChainId && isSupportedChain(supportedChainId) && !(connector instanceof Network)) {
      networkConnection.connector.activate(chainId);
    }
  }, [chainId, connector, paramsChainId, connected]);
};
