import { lazyLoad } from "utils/loadable";

import { Loader } from "components";

export const MessagesList = lazyLoad(
  () => import("./messages-list"),
  module => module.default,
  { fallback: <Loader width="100%" height="100%" /> },
);

export const MessengerWindow = lazyLoad(
  () => import("./messenger-window"),
  module => module.default,
  { fallback: <Loader width="100%" height="100%" /> },
);
