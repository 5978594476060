import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import useMatchBreakpoints from "./use-match-breakpoints/use-match-breakpoints";

import { ROUTES } from "navigation/routes";
import { ELEMENTS_ID } from "configs";

export const useHideChatBot = ({ isMobileMenuOpen }: { isMobileMenuOpen: boolean }) => {
  const { pathname } = useLocation();
  const { isMobile } = useMatchBreakpoints();

  const chatBot = document.getElementById(ELEMENTS_ID.webWidget);

  useEffect(() => {
    if (chatBot) {
      if (isMobile) {
        if (isMobileMenuOpen) {
          chatBot.style.display = "block";
        } else {
          chatBot.style.display = "none";
        }
      } else {
        if (pathname === `/${ROUTES.messages}`) {
          chatBot.style.display = "none";
        } else {
          chatBot.style.display = "block";
        }
      }
    }
  }, [chatBot, pathname, isMobile, isMobileMenuOpen]);
};
