import styled from "styled-components";
import { Box } from "components";
import { StyledAmountToDepositProps } from "./types";

export const StyledAmountToDeposit = styled(Box)<StyledAmountToDepositProps>`
  margin-top: 24px;
  border: 1px solid
    ${({ theme, isTouched, isError }) => (isError && isTouched ? theme.colors.red : theme.colors.neutral300)};
  padding: 24px;
  width: 100%;
  border-radius: 8px;
`;
