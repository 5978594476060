import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral500", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent" stroke={theme.colors[color]}>
      <path
        d="M9.67993 4.942C12.2689 2.352 16.4679 2.353 19.0579 4.942C21.6479 7.531 21.6479 11.73 19.0579 14.32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.264 14.368C16.264 18.031 13.295 21 9.632 21C5.969 21 3 18.031 3 14.368C3 10.705 5.969 7.73596 9.632 7.73596C13.295 7.73596 16.264 10.705 16.264 14.368"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.63212 11.8669L9.63212 16.869" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.63115 14.8682L9.63198 16.869L11.6328 14.8682"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
