import React, { useEffect } from "react";
import NetworkBalancesWorker from "workers/NetworkBalances.worker";

import { FlexGap, StyledContentWrapper } from "components";
import { BalanceChart, DashboardTransactions, TotalBalanceChart } from "./components";
import { isErrorResult } from "services";

import { useAppDispatch } from "store/store";
import { setNetworkBalances } from "store/dashboard";
import { getDashboardWalletBalance } from "store/dashboard/actions";

import { ROUTES } from "navigation/routes";

const DashboardPage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const balancesWorker = new NetworkBalancesWorker();

    dispatch(getDashboardWalletBalance()).then(res => {
      if (!isErrorResult(res.payload)) {
        const payload = res.payload!;
        balancesWorker.postMessage(payload);
      }
    });

    balancesWorker.onmessage = event => {
      const res = event.data;
      dispatch(setNetworkBalances(res));
    };

    return () => {
      balancesWorker.terminate();
    };
  }, []);

  return (
    <FlexGap flexGrow="1" width="100%" id={ROUTES.dashboard} flexDirection="column" gap="24px">
      <FlexGap gap="24px" flexDirection={{ _: "column", laptop: "row" }}>
        <StyledContentWrapper minWidth={{ _: "unset", tablet: "340px" }} alignItems="center" minHeight="310px">
          <TotalBalanceChart />
        </StyledContentWrapper>

        <StyledContentWrapper width={{ _: "100%", laptopL: "calc(100% - 340px)" }} minWidth="unset" minHeight="310px">
          <BalanceChart />
        </StyledContentWrapper>
      </FlexGap>

      <StyledContentWrapper height="100%">
        <DashboardTransactions />
      </StyledContentWrapper>
    </FlexGap>
  );
};

export default DashboardPage;
