import { createAsyncThunk } from "@reduxjs/toolkit";
import { isErrorResult, makeApiRequest, ENDPOINTS_WHITELIST } from "services";
import { ErrorResult } from "services/types";

import { SuccessResponse, HistoryPayload } from "../types";
import { CreateWhitelistPayload, DeleteWhitelistPayload, WhitelistResponse, SendCodesPayloadWhiteList } from "./types";

export const sendCodesForWhitelist = createAsyncThunk<
  SuccessResponse,
  SendCodesPayloadWhiteList,
  { rejectValue: ErrorResult }
>("whitelist/whitelist-codes", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<SuccessResponse>({
    method: "POST",
    url: ENDPOINTS_WHITELIST.sendCodesForWhitelist,
    data: data,
    isShowError: false,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const createWhitelistItem = createAsyncThunk<
  SuccessResponse,
  CreateWhitelistPayload,
  { rejectValue: ErrorResult }
>("whitelist/new-whitelist-item", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<SuccessResponse>({
    method: "POST",
    url: ENDPOINTS_WHITELIST.whitelistAddresses,
    data: data,
    isShowError: false,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const getWhitelist = createAsyncThunk<WhitelistResponse, HistoryPayload, { rejectValue: ErrorResult }>(
  "whitelist/get-whitelist",
  async (params, { rejectWithValue }) => {
    const result = await makeApiRequest<WhitelistResponse>({
      method: "GET",
      url: ENDPOINTS_WHITELIST.whitelistAddresses,
      params,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getWhitelistWithdrawal = createAsyncThunk<WhitelistResponse, HistoryPayload, { rejectValue: ErrorResult }>(
  "whitelist/get-whitelist-withdrawal",
  async (params, { rejectWithValue }) => {
    const result = await makeApiRequest<WhitelistResponse>({
      method: "GET",
      url: ENDPOINTS_WHITELIST.whitelistAddresses,
      params,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getWhitelistModal = createAsyncThunk<WhitelistResponse, HistoryPayload, { rejectValue: ErrorResult }>(
  "whitelist/get-whitelist-modal",
  async (params, { rejectWithValue }) => {
    const result = await makeApiRequest<WhitelistResponse>({
      method: "GET",
      url: ENDPOINTS_WHITELIST.whitelistAddresses,
      params,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const deleteWhitelistItem = createAsyncThunk<
  SuccessResponse,
  DeleteWhitelistPayload,
  { rejectValue: ErrorResult }
>("whitelist/delete-whitelist-item", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<SuccessResponse>({
    method: "DELETE",
    url: ENDPOINTS_WHITELIST.whitelistAddresses + data.id,
    data,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});
