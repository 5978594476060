import React, { Children, isValidElement, PropsWithChildren, ReactNode } from "react";

import { PaginationRightArrow } from "components/svg";
import { Separator, StyledBreadcrumbs, StyledBreadcrumbsItem } from "./styled";
import { Row } from "components";

import { BreadcrumbsProps } from "./types";

const insertSeparators = (items: ReactNode[], separator: BreadcrumbsProps["separator"]) =>
  items.reduce((accum: ReactNode[], item, index) => {
    if (index === 0) {
      return [...accum, item];
    }

    return [
      ...accum,
      <Row key={`seperator-${index}`} alignItems="center">
        <Separator aria-hidden>{separator}</Separator>
        {item}
      </Row>,
    ];
  }, []);

const DefaultSeparator = <PaginationRightArrow />;

const Breadcrumbs: React.FC<PropsWithChildren<BreadcrumbsProps>> = ({
  separator = DefaultSeparator,
  children,
  ...props
}) => {
  const validItems = Children.toArray(children).filter(child => isValidElement(child));
  const items = insertSeparators(validItems, separator);

  return (
    <StyledBreadcrumbs mt={{ _: "6px", laptop: "0px" }} {...props}>
      {items.map((item, index) => (
        <StyledBreadcrumbsItem key={`child-${index}`}>{item}</StyledBreadcrumbsItem>
      ))}
    </StyledBreadcrumbs>
  );
};

export default Breadcrumbs;
