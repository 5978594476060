import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "white", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent" stroke={theme.colors[color]}>
      <path
        d="M14 20H5.125C3.951 20 3 19.049 3 17.875V7.855C3 7.568 3.058 7.283 3.171 7.02L4.338 4.29C4.672 3.508 5.441 3 6.292 3H16.709C17.56 3 18.328 3.508 18.663 4.29L19.83 7.02C19.942 7.284 20 7.568 20 7.855V12"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 3V7.44"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.957 7.43945H3.04297"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6 16H8" stroke={theme.colors[color]} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M17 21C14.239 21 12 18.761 12 16C12 13.239 14.239 11 17 11C19.762 11 22 13.239 22 16C22 18.761 19.762 21 17 21"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9437 15.0273L16.5127 17.4583L15.0537 15.9993"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
