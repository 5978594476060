import { createAsyncThunk } from "@reduxjs/toolkit";

import { ENDPOINTS_ESCROW, isErrorResult, makeApiRequest } from "services";

import { ErrorResult } from "services/types";
import {
  MyEscrowsPayload,
  MyEscrowsResponse,
  WidgetListPayload,
  WidgetListResponse,
  WidgetBuyersListResponse,
  WidgetBuyersListPayload,
} from "./types";

export const getMyEscrowsList = createAsyncThunk<MyEscrowsResponse, MyEscrowsPayload, { rejectValue: ErrorResult }>(
  "escrow/get-my-escrows-list",
  async (params, { rejectWithValue }) => {
    const result = await makeApiRequest<MyEscrowsResponse>({
      method: "GET",
      url: ENDPOINTS_ESCROW.getMyEscrowsList,
      params,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getWidgetList = createAsyncThunk<WidgetListResponse, WidgetListPayload, { rejectValue: ErrorResult }>(
  "escrow/get-widget-list",
  async (params, { rejectWithValue }) => {
    const result = await makeApiRequest<WidgetListResponse>({
      method: "GET",
      url: ENDPOINTS_ESCROW.getWidgetsList,
      params,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getWidgetBuyersList = createAsyncThunk<
  WidgetBuyersListResponse,
  WidgetBuyersListPayload,
  { rejectValue: ErrorResult }
>("escrow/get-escrows-widget-list", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<WidgetBuyersListResponse>({
    method: "GET",
    url: ENDPOINTS_ESCROW.getWidgetsList + data.id + "/escrows",
    params: data,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});
