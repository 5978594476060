import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 72 72" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_4623_103755)">
        <rect x="18" y="49" width="36" height="12" rx="4" fill={theme.colors.primary} />
      </g>
      <circle cx="36" cy="36" r="28" fill="#EBF1FF" />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.primary} strokeOpacity="0.5" />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.primary} strokeWidth="0.5" />
      <path
        d="M28.4966 49.5057H25.4954C23.8378 49.5057 22.4941 48.162 22.4941 46.5044V43.5032"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.5029 22.4944H46.5042C48.1617 22.4944 49.5054 23.8381 49.5054 25.4956V28.4969"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4941 28.4969V25.4956C22.4941 23.8381 23.8378 22.4944 25.4954 22.4944H28.4966"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.5054 43.5032V46.5044C49.5054 48.162 48.1617 49.5057 46.5042 49.5057H43.5029"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="36.001"
        cy="35.9989"
        r="9"
        fill={theme.colors.primary}
        fillOpacity="0.14"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
      />
      <path
        d="M32.4983 32.9988V33.9992"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.5012 32.9988V33.9992"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.5019 38.6891C37.4957 40.4393 34.5052 40.4393 32.499 38.6891"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_f_4623_103755"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_4623_103755" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
