import { MAINNET_CHAIN_IDS, TESTNET_CHAIN_IDS } from "configs/web3/chains";

const contractsAddresses = {
  multicall: {
    [MAINNET_CHAIN_IDS.MAINNET]: "0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696",
    [TESTNET_CHAIN_IDS.SEPOLIA]: "0xCb5e100fdF7d24f25865fa85673D9bD6Bb4674ab",

    [MAINNET_CHAIN_IDS.BSC]: "0xC50F4c1E81c873B2204D7eFf7069Ffec6Fbe136D",
    [TESTNET_CHAIN_IDS.BSC_TEST]: "0x73CCde5acdb9980f54BcCc0483B28B8b4a537b4A",

    [MAINNET_CHAIN_IDS.POLYGON]: "0x275617327c958bD06b5D6b871E7f491D76113dd8",
    [TESTNET_CHAIN_IDS.POLYGON_MUMBAI]: "0xe9939e7Ea7D7fb619Ac57f648Da7B1D425832631",

    [MAINNET_CHAIN_IDS.AVAX]: "0xed386Fe855C1EFf2f843B910923Dd8846E45C5A4",
    [TESTNET_CHAIN_IDS.FUJI]: "0x3D015943d2780fE97FE3f69C97edA2CCC094f78c",
  },
};

export default contractsAddresses;
