import { Loader } from "components";
import { lazyLoad } from "utils/loadable";

export { TableRowDesktop, TableRowMobile } from "./list-of-transactions/components";
export { StyledDivider } from "./create-statement/styled";
export { SectionHeder } from "./create-statement/components";

export const StatementsListPage = lazyLoad(
  () => import("./statements-list"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const StatementItemPage = lazyLoad(
  () => import("./statement-item"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const CreateStatementPage = lazyLoad(
  () => import("./create-statement"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const ListOfTransactionsPage = lazyLoad(
  () => import("./list-of-transactions"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);
