import React from "react";

import { useHandleData } from "./hooks";
import { useTranslation } from "context";
import { formatValueToBNString } from "configs/web3";
import { getCommissionPayer } from "utils/helpers";

import { StyledContentWrapper, FlexGap, Loader, EmptyDataMessage } from "components";
import { DeliveryDescription, EscrowHeader, EscrowStepWrapper, EscrowPaymentsInfo } from "./components";

import { useAppSelector } from "store/store";

import { stepsName, stepsNumbers } from "./constants";
import { ROUTES } from "navigation/routes";

const EscrowProcess: React.FC = () => {
  const currentEscrow = useAppSelector(state => state.escrowProcess.currentEscrow);
  const pending = useAppSelector(state => state.escrowProcess.pending);
  const currentStepNumber = useAppSelector(state => state.escrowProcess.currentStepNumber);

  const { t } = useTranslation();

  useHandleData();

  return (
    <FlexGap gap="24px" id={ROUTES.escrowProcess} flexDirection="column" flexGrow="1">
      <StyledContentWrapper minHeight="136px" height="unset">
        {pending && !currentEscrow ? <Loader height="100%" /> : currentEscrow ? <EscrowHeader /> : null}
      </StyledContentWrapper>

      {currentStepNumber === stepsNumbers[stepsName.delivery] && (
        <StyledContentWrapper minHeight="unset" height="unset">
          <DeliveryDescription />
        </StyledContentWrapper>
      )}

      <FlexGap gap="24px" flexGrow="1" flexDirection={{ _: "column", laptop: "row" }}>
        {/*EscrowPaymentsInfo minWidth + gap (400+24)*/}
        <StyledContentWrapper width={{ _: "100%", laptop: "calc(100% - 424px)" }} minWidth="unset" minHeight="unset">
          {pending ? <Loader height="100%" /> : <EscrowStepWrapper />}
        </StyledContentWrapper>

        <StyledContentWrapper
          minWidth={{ _: "unset", laptop: "400px" }}
          maxWidth={{ _: "unset", laptop: "456px" }}
          width="100%"
          minHeight="unset"
        >
          <>
            {pending && !currentEscrow ? (
              <Loader height="100%" />
            ) : currentEscrow ? (
              <EscrowPaymentsInfo
                currency={currentEscrow.body.head.currency.publicCode}
                network={currentEscrow.body.head.currency.chainId}
                amount={formatValueToBNString(currentEscrow.body.products[0].price)}
                escryptoFee={currentEscrow.body.head.fee}
                showBrokerFields={!!currentEscrow.body.broker}
                brokersCommission={currentEscrow.body.broker?.commission}
                coversEscryptoFee={getCommissionPayer(currentEscrow.body.head.feePaidBy)}
                coversBrokersCommission={getCommissionPayer(currentEscrow.body.broker?.commissionPaidBy)}
                isShipped={currentEscrow.isShippable}
              />
            ) : (
              <EmptyDataMessage title="" text={t("Looks like there is no such Escrow")} />
            )}
          </>
        </StyledContentWrapper>
      </FlexGap>
    </FlexGap>
  );
};

export default EscrowProcess;
