import React from "react";
import BigNumber from "bignumber.js";

import { useTooltip } from "hooks";
import { useHandleData } from "./hooks";
import { useTranslation } from "context";

import { Text, Column, Flex } from "components";
import { InfoRow } from "./components";
import { StyledPointsWrapper } from "./styled";

import { useAppSelector } from "store/store";

import { EscrowPaymentsInfoProps } from "./types";
import { Roles } from "pages/main/create-escrow/types";

import { getTokenFromTokenKey } from "utils/helpers";

const EscrowPaymentsInfo: React.FC<EscrowPaymentsInfoProps> = ({
  currency,
  network,
  amount,
  escryptoFee,
  showBrokerFields,
  brokersCommission,
  coversEscryptoFee,
  coversBrokersCommission,
  isShipped,
}) => {
  const allTokensList = useAppSelector(state => state.tokensList.allTokensList);

  const { t } = useTranslation();

  const { targetRef: targetRefBuyerPays, tooltip: buyerPaysTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });
  const { targetRef: targetRefSellerReceives, tooltip: sellerReceivesTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });
  const { targetRef: targetRefBrokerReceives, tooltip: brokerReceivesTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const { getPaysAndReceives } = useHandleData({
    coversEscryptoFee,
    escryptoFee,
    brokersCommission,
    coversBrokersCommission,
    amount,
  });

  const currentCurrency = getTokenFromTokenKey(allTokensList, network, currency);

  return (
    <Column>
      <Text textScale={{ _: "body3", tablet: "body2" }} color="neutral600" $fontWeight="medium" mb="24px">
        {t("Payments Info")}
      </Text>

      <StyledPointsWrapper>
        <InfoRow
          title={t("Currency:")}
          currency={currency}
          logoURI={currentCurrency?.logoURI}
          value={currentCurrency?.name}
        />

        <InfoRow title={t("Price:")} value={BigNumber(amount).toFormatExtended(8)} />

        <InfoRow title={t("Zert fee:")} value={BigNumber(escryptoFee).toFormatExtended(8)} />

        {showBrokerFields && (
          <InfoRow title={t("Broker's commission:")} value={BigNumber(brokersCommission ?? "0").toFormatExtended(8)} />
        )}

        <InfoRow title={t("Who covers Zert fee:")} value={coversEscryptoFee} />

        {showBrokerFields && <InfoRow title={t("Who covers Broker's commission:")} value={coversBrokersCommission} />}

        {isShipped && <InfoRow title={t("Who covers delivery*:")} value={t("Buyer")} />}
      </StyledPointsWrapper>

      <Column>
        <Flex justifyContent="space-between" mb="14px" alignItems="baseline">
          <Text textScale="body3" color="neutral500">
            {t("Buyer pays:")}
          </Text>

          <Text
            textScale="body2"
            $fontWeight="medium"
            color="neutral800"
            ellipsis
            ref={targetRefBuyerPays}
            maxWidth="50%"
          >
            {getPaysAndReceives(Roles.buyer)}
          </Text>
          {buyerPaysTooltip}
        </Flex>

        <Flex justifyContent="space-between" mb="14px" alignItems="baseline">
          <Text textScale="body3" color="neutral500">
            {t("Seller receives:")}
          </Text>

          <Text
            textScale="body2"
            $fontWeight="medium"
            color="neutral800"
            ellipsis
            ref={targetRefSellerReceives}
            maxWidth="50%"
          >
            {getPaysAndReceives(Roles.seller)}
          </Text>
          {sellerReceivesTooltip}
        </Flex>

        {showBrokerFields && (
          <Flex justifyContent="space-between" mb="14px" alignItems="baseline">
            <Text textScale="body3" color="neutral500">
              {t("Broker receives:")}
            </Text>

            <Text
              textScale="body2"
              $fontWeight="medium"
              color="neutral800"
              ellipsis
              ref={targetRefBrokerReceives}
              maxWidth="50%"
            >
              {BigNumber(brokersCommission ?? "0").toFormatExtended(8)}
            </Text>
            {brokerReceivesTooltip}
          </Flex>
        )}

        {isShipped && (
          <Text textScale="caption2" color="neutral500">
            {t("*Warning: the delivery cost is covered by the Buyer")}
          </Text>
        )}
      </Column>
    </Column>
  );
};

export default EscrowPaymentsInfo;
