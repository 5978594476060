import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

import { MAINNET_CHAIN_IDS, TESTNET_CHAIN_IDS, NATIVE_CURRENCIES_LOGO } from "./chains";
import { BITCOIN_CHAINS } from "configs/bitcoin";
import { Currency, Ether, NativeCurrency, Token } from "./entities";
import { ChainId } from "./types";
import { isSupportedChain } from "./utils/is-supported-chain";
import { NATIVE_ADDRESS } from "configs/web3";
import { NATIVE_ADDRESS_SOLANA } from "configs/solana";

export function isMatic(chainId: ChainId) {
  return +chainId === MAINNET_CHAIN_IDS.POLYGON || +chainId === TESTNET_CHAIN_IDS.POLYGON_MUMBAI;
}

class MaticNativeCurrency extends NativeCurrency {
  equals(other: Currency) {
    return other.isNative && other.chainId === this.chainId;
  }

  //   get wrapped() {
  //     if (!isMatic(this.chainId)) {
  //       throw new Error("Not matic");
  //     }
  //     const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId];
  //     invariant(wrapped instanceof Token);
  //     return wrapped;
  //   }

  public constructor(chainId: ChainId) {
    if (!isMatic(chainId)) {
      throw new Error("Not matic");
    }
    super(chainId, 18, "MATIC", "Polygon Matic", "MATIC", NATIVE_ADDRESS, NATIVE_CURRENCIES_LOGO[chainId]);
  }
}

export function isBsc(chainId: ChainId) {
  return +chainId === MAINNET_CHAIN_IDS.BSC || +chainId == TESTNET_CHAIN_IDS.BSC_TEST;
}

class BscNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId;
  }
  // TODO: check in future if this function is needed

  //   get wrapped(): Token {
  //     if (!isBsc(this.chainId)) {
  //       throw new Error("Not bnb");
  //     }
  //     const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId];
  //     invariant(wrapped instanceof Token);
  //     return wrapped;
  //   }

  public constructor(chainId: ChainId) {
    if (!isBsc(chainId)) {
      throw new Error("Not bnb");
    }
    super(chainId, 18, "BNB", "BNB", "BNB", NATIVE_ADDRESS, NATIVE_CURRENCIES_LOGO[chainId]);
  }
}

export function isAvalanche(chainId: ChainId) {
  return +chainId === MAINNET_CHAIN_IDS.AVAX || +chainId === TESTNET_CHAIN_IDS.FUJI;
}

class AvaxNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId;
  }
  // TODO: check in future if this function is needed

  //   get wrapped(): Token {
  //     if (!isAvalanche(this.chainId)) {
  //       throw new Error("Not avalanche");
  //     }
  //     const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId];
  //     invariant(wrapped instanceof Token);
  //     return wrapped;
  //   }

  public constructor(chainId: ChainId) {
    if (!isAvalanche(chainId)) {
      throw new Error("Not avalanche");
    }
    super(chainId, 18, "AVAX", "AVAX", "AVAX", NATIVE_ADDRESS, NATIVE_CURRENCIES_LOGO[chainId]);
  }
}

export function isBitcoin(chainId: ChainId) {
  return chainId === BITCOIN_CHAINS.BTC || chainId === BITCOIN_CHAINS.BTC_TEST;
}

class BitcoinNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId;
  }
  // TODO: check in future if this function is needed

  //   get wrapped(): Token {
  //     if (!isAvalanche(this.chainId)) {
  //       throw new Error("Not avalanche");
  //     }
  //     const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId];
  //     invariant(wrapped instanceof Token);
  //     return wrapped;
  //   }

  public constructor(chainId: ChainId) {
    if (!isBitcoin(chainId)) {
      throw new Error("Not Bitcoin");
    }
    super(chainId, 8, "BTC", "Bitcoin", "BTC", NATIVE_ADDRESS, NATIVE_CURRENCIES_LOGO[chainId]);
  }
}

export function isSolana(chainId: ChainId) {
  return chainId === WalletAdapterNetwork.Devnet || chainId === WalletAdapterNetwork.Mainnet;
}

class SolanaNativeCurrency extends NativeCurrency {
  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId;
  }

  //   get wrapped(): Token {
  //     if (!isAvalanche(this.chainId)) {
  //       throw new Error("Not avalanche");
  //     }
  //     const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId];
  //     invariant(wrapped instanceof Token);
  //     return wrapped;
  //   }

  public constructor(chainId: ChainId) {
    if (!isSolana(chainId)) {
      throw new Error("Not Solana");
    }
    super(chainId, 9, "SOL", "Solana", "SOL", NATIVE_ADDRESS_SOLANA, NATIVE_CURRENCIES_LOGO[chainId]);
  }
}

class ExtendedEther extends Ether {
  // TODO: check in future if this function is needed

  //   public get wrapped(): Token {
  //     const wrapped = WRAPPED_NATIVE_CURRENCY[this.chainId];
  //     if (wrapped) return wrapped;
  //     throw new Error(`Unsupported chain ID: ${this.chainId}`);
  //   }

  private static _cachedExtendedEther: { [chainId: ChainId]: NativeCurrency } = {};

  public static onChain(chainId: ChainId): ExtendedEther {
    return this._cachedExtendedEther[chainId] ?? (this._cachedExtendedEther[chainId] = new ExtendedEther(chainId));
  }
}

export const nativeOnChain = (chainId?: ChainId) => {
  let nativeCurrency: NativeCurrency | Token = ExtendedEther.onChain(MAINNET_CHAIN_IDS.MAINNET);

  if (chainId) {
    if (isMatic(chainId)) {
      nativeCurrency = new MaticNativeCurrency(chainId);
    } else if (isBsc(chainId)) {
      nativeCurrency = new BscNativeCurrency(chainId);
    } else if (isAvalanche(chainId)) {
      nativeCurrency = new AvaxNativeCurrency(chainId);
    } else if (isBitcoin(chainId)) {
      nativeCurrency = new BitcoinNativeCurrency(chainId);
    } else if (isSolana(chainId)) {
      nativeCurrency = new SolanaNativeCurrency(chainId);
    } else if (isSupportedChain(chainId)) {
      nativeCurrency = ExtendedEther.onChain(chainId);
    }
  }

  return nativeCurrency;
};
