import React from "react";

import { useTranslation } from "context";

import { Flex, Text, Image, MultiplyPendingRows, Box } from "components";
import { StyledModalWrapper } from "../styled";
import { StyledCard, StyledColumn } from "./styled";
import CloseModalWrapper from "../close-modal-wrapper";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";

import { DepositChooseTokenModalProps } from "./types";
import { BEToken } from "store/wallets/types";
import { selectCombinedData } from "./selectors";

const DepositChooseTokenModal: React.FC = () => {
  const { network, tokenKey, onClick, networks, pending } = useAppSelector(selectCombinedData);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const tokensBEMap = (networks && networks[network]) ?? {};
  const tokensWithKeysBE: [string, BEToken][] = Object.entries(tokensBEMap);

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const onCardClick: DepositChooseTokenModalProps["onClick"] = token => {
    onClick(token);
    closeModalHandler();
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} pb="24px">
        <Text textAlign="start" color="neutral600" textScale="body2" $fontWeight="bold" wordBreak="break-all">
          {t("Select Token")}
        </Text>
      </CloseModalWrapper>

      {tokensWithKeysBE.length ? (
        <StyledColumn>
          {pending ? (
            <MultiplyPendingRows rowsCount={10} rowHeight="56px" rowWidth="100%" gap="4px" />
          ) : (
            tokensWithKeysBE.map(([key, token]) => {
              return (
                <StyledCard key={key} onClick={() => onCardClick(token)} isSelected={tokenKey === token.publicCode}>
                  <Box minHeight="24px" minWidth="24px">
                    <Image src={token.logoURI} alt={key} variant="circle" width="24px" aspectRatio={1} />
                  </Box>

                  <Box ellipsis>
                    <Text color="neutral800" textScale="body3" ellipsis>
                      {token.name}
                    </Text>
                  </Box>

                  <Text color="neutral500" textScale="body3">
                    {token.symbol}
                  </Text>
                </StyledCard>
              );
            })
          )}
        </StyledColumn>
      ) : (
        <Flex width="100%" height="200px" alignItems="center">
          <Text textScale="body1" textAlign="center">
            {t("There are no available tokens at the moment")}
          </Text>
        </Flex>
      )}
    </StyledModalWrapper>
  );
};

export default DepositChooseTokenModal;
