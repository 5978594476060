import { TranslateFunction } from "context/language-context/types";
import { ROUTES, ROUTE_PARAMS } from "navigation/routes";
import { removeTrailingSlashIfExists } from "utils/helpers/pathname-helpers";

const URL = process.env.REACT_APP_URL;

export const getDefaultMeta = (t: TranslateFunction) => {
  return {
    title: "",
    description: t(
      "ZERT, the secure crypto platform with DeFi tech, multilingual support & institutional grade security for everyone. Buy, store, stake and pay in crypto.",
    ),
    image: `${URL}/logo512.png`,
    keywords: "zert, crypto, tokens",
  };
};

export const getCustomMeta = (path: string, t: TranslateFunction) => {
  let basePath = removeTrailingSlashIfExists(path);

  if (path.startsWith(`/${ROUTES.wallet}`)) {
    basePath = `/${ROUTES.wallet}`;
  } else if (path.startsWith(`/${ROUTES.escrows}`)) {
    basePath = `/${ROUTES.escrows}`;
  } else if (path.startsWith(`/${ROUTES.statements}`)) {
    const secondRouteParam = path.split("/")[2];
    if (secondRouteParam) {
      if (secondRouteParam === ROUTES.createStatement) {
        basePath = `/${ROUTES.createStatement}`;
      } else {
        basePath = `/:${ROUTE_PARAMS.statementId}`;
      }
    } else {
      basePath = `/${ROUTES.statements}`;
    }
  }

  switch (basePath) {
    case `/${ROUTES.login}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Login"),
      };
    }
    case `/${ROUTES.registration}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Registration"),
      };
    }
    case `/${ROUTES.twoFactorSetup}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Set 2FA"),
      };
    }
    case `/${ROUTES.confirmAuth}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Confirm Auth"),
      };
    }
    case `/${ROUTES.wallet}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Wallet"),
      };
    }
    case `/${ROUTES.buyCrypto}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Buy"),
      };
    }
    case `/${ROUTES.swap}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Swap"),
      };
    }
    case `/${ROUTES.coldStorage}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Cold Storage"),
      };
    }
    case `/${ROUTES.comingSoon}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Coming Soon"),
      };
    }
    case `/${ROUTES.settings}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Settings"),
      };
    }
    case `/${ROUTES.escrows}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Escrows"),
      };
    }
    case `/${ROUTES.messages}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Messages"),
      };
    }
    case `/${ROUTES.statements}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Statements"),
      };
    }
    case `/${ROUTES.createStatement}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Create statement"),
      };
    }
    case `/:${ROUTE_PARAMS.statementId}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Statement Information"),
      };
    }
    case `/${ROUTES.twoFactorReset}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Reset authenticator app"),
      };
    }
    case `/${ROUTES.dashboard}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Dashboard"),
      };
    }
    case `/${ROUTES.deleteCompleted}`: {
      return {
        ...getDefaultMeta(t),
        title: t("Delete Account"),
      };
    }
    default:
      return {
        ...getDefaultMeta(t),
      };
  }
};
