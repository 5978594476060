import React from "react";

import { useTranslation } from "context";

import { Text, Link } from "components";

import { errorSubStatus } from "configs";

import { useAppDispatch } from "store/store";

import { TransactionFailedProps } from "./types";

const ToastTransactionStatusWithLink: React.FC<TransactionFailedProps> = ({ transaction, subStatus }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { title, action, activeTab, link } = transaction;

  const onLinkClick = () => {
    dispatch(action(activeTab));
  };

  const errorMessage = errorSubStatus[subStatus as keyof typeof errorSubStatus]
    ? t(`${errorSubStatus[subStatus as keyof typeof errorSubStatus]}`)
    : t(`Looks like your %title% failed. Follow the link to check transaction status`, {
        title: `${title}`,
      });

  return (
    <>
      <Text color="neutral600" textScale="caption1">
        {errorMessage}:
      </Text>
      <Link onClick={onLinkClick} href={link} textScale="caption1">
        {t("Link")}
      </Link>
    </>
  );
};

export default ToastTransactionStatusWithLink;
