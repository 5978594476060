import React, { PropsWithChildren } from "react";

import { AlertTextWithDescriptionProps } from "./types";
import { Box, Text } from "components";

const AlertTextWithDescription: React.FC<PropsWithChildren<AlertTextWithDescriptionProps>> = ({
  text,
  description,
  children,
}) => {
  return (
    <Box>
      <Text $fontWeight="bold" textScale="body3" mb={description ? "6px" : "0"}>
        {text}
      </Text>

      {description && (
        <Text textScale="caption1" mb={React.isValidElement(children) ? "6px" : "0"}>
          {description}
        </Text>
      )}

      {React.isValidElement(children) && children}
    </Box>
  );
};

export default AlertTextWithDescription;
