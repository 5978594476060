import styled from "styled-components";

import { Flex } from "../flex";

import { AutoRowProps, FlexProps } from "../types";

export const Row = styled(Flex)<FlexProps>`
  width: 100%;
`;

export const RowBetween = styled(Row)`
  justify-content: space-between;
`;

export const AutoRow = styled(Row)<AutoRowProps>`
  flex-wrap: wrap;
  margin: ${({ gap }) => gap && `-${gap}`};
  justify-content: ${({ justify }) => justify};

  & > * {
    margin: ${({ gap }) => gap} !important;
  }
`;
