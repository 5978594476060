const REGEX = {
  includesVariableRegex: new RegExp(/%\S+?%/, "gm"),
  // eslint-disable-next-line no-useless-escape
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~])(?=.{8,})/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  firstNameAndLastName: /^[a-zA-Z ,'-]+$/,
  numericInputRegex: /^[0-9]*[.,]?[0-9]*$/,
  onlyNumbers: /^[0-9]*$/,
  base64: /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/,
  zerosAfterDot: /\.?0+$/,
  yubikeyDeviceName: /^(?! )(?![\s\S]* $)[a-zA-Z0-9\s]{3,20}$/,
};

export default REGEX;
