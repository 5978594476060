import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useValidationSchema } from "./hooks";
import { useForm, useGetSearchParams } from "hooks";
import { useTranslation } from "context";

import { Text, Flex, InputGroup, Input, Box, Button } from "components";
import { StyledModalWrapper } from "../styled";
import { EyeClosedIcon, EyeIcon, ModalNewPasswordIcon, ModalSuccessIcon } from "components/svg";
import CloseModalWrapper from "../close-modal-wrapper";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal, showModal } from "store/modal";
import { resetPassword } from "store/auth/actions";

import { isErrorResult } from "services";

import { SEARCH_PARAMS } from "navigation/search-params";
import { ROUTES } from "navigation/routes";

import { ModalProps } from "store/modal/types";
import { ActionModalProps } from "../action-modal/types";

const NewPasswordModal: React.FC = () => {
  const pending = useAppSelector(state => state.auth.pending);

  const [isPassword, setIsPassword] = useState(true);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentSearchParams: code } = useGetSearchParams(SEARCH_PARAMS.code);

  const Icon = isPassword ? EyeClosedIcon : EyeIcon;

  const { validationSchema, initialValues } = useValidationSchema();

  const { fieldProps, handleSubmit, errors, isValid, touched, values } = useForm({
    initialValues,
    validationSchema,
    onSubmit(values) {
      submitHandler(values);
    },
  });

  useEffect(() => {
    if (!code) {
      navigate(`/${ROUTES.login}`);
    }
  }, [code]);

  const submitHandler = (formValues: typeof values) => {
    if (code) {
      const data = {
        password: formValues.password,
        code,
      };

      dispatch(resetPassword(data)).then(res => {
        if (!isErrorResult(res.payload)) {
          showSuccessModal();
        }
      });
    }
  };

  const showSuccessModal = () => {
    const _showModal = showModal as ModalProps<ActionModalProps>;

    dispatch(
      _showModal({
        modalName: "actionModal",
        rootId: "forgot-password",
        props: {
          icon: <ModalSuccessIcon height="72px" width="72px" />,
          title: t("Password changed"),
          text: t("You password has been changed successfully"),
          btnArr: [
            {
              buttonText: t("Done"),
              callback: onLinkClick,
            },
          ],
        },
        clickOutsideHandler: onLinkClick,
      }),
    );
  };

  const closeModalHandler = () => {
    dispatch(hideModal());
    navigateToLogin();
  };

  const navigateToLogin = () => {
    navigate(`/${ROUTES.login}`);
  };

  const togglePasswordVisibleHandler = () => {
    setIsPassword(!isPassword);
  };

  const onLinkClick = () => {
    closeModalHandler();
    navigateToLogin();
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} />

      <Flex mt="18px" mb="16px" justifyContent="center" width="100%">
        <ModalNewPasswordIcon width="72px" height="72px" />
      </Flex>

      <Text color="neutral600" textAlign="center" textScale="body2">
        {t("Enter a new password")}
      </Text>

      <Text color="neutral600" mt="12px" mb="24px" textAlign="center" textScale="caption1">
        {t("Create a new, strong password that you don’t use for other websites / applications")}
      </Text>

      <Box width="100%">
        <form onSubmit={handleSubmit}>
          <InputGroup
            label={t("New password")}
            error={errors.password}
            isTouched={touched.password}
            endIcon={<Icon cursor="pointer" width="24px" height="24px" onClick={togglePasswordVisibleHandler} />}
          >
            <Input
              placeholder={t("New password")}
              {...fieldProps("password")}
              type={isPassword ? "password" : "text"}
            />
          </InputGroup>

          <InputGroup
            label={t("Confirm Password")}
            error={errors.confirmPassword}
            isTouched={touched.confirmPassword}
            endIcon={<Icon cursor="pointer" width="24px" height="24px" onClick={togglePasswordVisibleHandler} />}
            mb="12px"
          >
            <Input
              placeholder={t("Confirm Password")}
              {...fieldProps("confirmPassword")}
              type={isPassword ? "password" : "text"}
            />
          </InputGroup>

          <Flex
            flexDirection={{ _: "column", tablet: "row" }}
            mb={{ _: "20px", tablet: "30px" }}
            justifyContent="space-between"
          >
            <Button width={{ _: "100%", tablet: "calc(50% - 6px)" }} variant="outline" onClick={onLinkClick}>
              {t("No")}
            </Button>

            <Button
              mt={{ _: "14px", tablet: "0px" }}
              px="26px"
              width={{ _: "100%", tablet: "calc(50% - 6px)" }}
              isLoading={pending}
              disabled={!isValid}
              type="submit"
            >
              {t("Change password")}
            </Button>
          </Flex>
        </form>
      </Box>
    </StyledModalWrapper>
  );
};

export default NewPasswordModal;
