import React, { PropsWithChildren } from "react";
import { createPortal } from "react-dom";

import {
  StyledModalWrapper,
  StyledModalContainer,
  StyledRightMenuContainer,
  StyledModalFullScreenContainer,
} from "./styled";
import { ModalWrapperProps } from "./types";
import { appearanceAnimationMap, appearanceAnimationVariants } from "theme";

import { useAppSelector } from "store/store";

const ModalWrapper: React.FC<PropsWithChildren<ModalWrapperProps>> = ({ children, id, onOutsideClick }) => {
  const modalType = useAppSelector(state => state.modal.modalType);
  const modalRoot = document.getElementById(id);
  const components = {
    modal: StyledModalContainer,
    rightMenu: StyledRightMenuContainer,
    fullScreenOnMobile: StyledModalFullScreenContainer,
  };

  const onParentContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    return event.stopPropagation();
  };

  if (modalRoot) {
    const Component = modalType ? components[modalType] : components.modal;

    return createPortal(
      <StyledModalWrapper
        key="modal"
        {...appearanceAnimationMap}
        variants={appearanceAnimationVariants}
        transition={{ duration: 0.3 }}
        onClick={onOutsideClick}
      >
        <Component
          onClick={onParentContainerClick}
          animate={{ x: modalType === "rightMenu" ? ["100%", "0%"] : undefined }}
          transition={{ duration: 0.5 }}
        >
          {children}
        </Component>
      </StyledModalWrapper>,

      modalRoot,
    );
  }
  return null;
};

export default ModalWrapper;
