import React from "react";
import BigNumber from "bignumber.js";

import { useTooltip } from "hooks";

import { Text } from "components";
import { StyledPercentTag } from "./styled";

import { PercentTagProps } from "../types";

const PercentTag: React.FC<PercentTagProps> = ({ percent, ...props }) => {
  const { targetRef, tooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  return (
    <StyledPercentTag value={percent} {...props}>
      <Text textScale="caption2" $fontWeight="medium" color={percent > 0 ? "green" : "red"} ellipsis ref={targetRef}>
        {percent > 0 ? "+" : null}
        {BigNumber(percent).toFormat(2)}
      </Text>

      <Text textScale="caption2" $fontWeight="medium" color={percent > 0 ? "green" : "red"}>
        %
      </Text>

      {tooltip}
    </StyledPercentTag>
  );
};

export default PercentTag;
