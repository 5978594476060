import React from "react";
import { useNavigate } from "react-router-dom";

import { useHandleData } from "./hooks";
import { useGetEscrowParticipants } from "../../hooks";
import { useTranslation } from "context";

import { Column, FlexGap, Button, WalletErrorMessage, NetworkAndToken, AmountPreviewer } from "components";
import { EscrowDetails } from "../index";

import { useAppSelector } from "store/store";

import { Roles } from "pages/main/create-escrow/types";
import { EscrowStateEnum } from "store/escrow/enums";
import { ROUTES } from "navigation/routes";

const Payments: React.FC = () => {
  const currentEscrow = useAppSelector(state => state.escrowProcess.currentEscrow);
  const pending = useAppSelector(state => state.escrowProcess.pending);

  const { userRole } = useGetEscrowParticipants();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { symbol, amountToPay, isEnoughFunds, amountInFiat, showPayModal } = useHandleData();

  return (
    <>
      {userRole !== Roles.buyer ? (
        <EscrowDetails />
      ) : (
        <Column mt="24px" height="100%">
          {currentEscrow && (
            <>
              <NetworkAndToken />
              <AmountPreviewer amountToPay={amountToPay} symbol={symbol} amountInFiat={amountInFiat} />

              {!isEnoughFunds && currentEscrow.body.state === EscrowStateEnum.WAITING_PAYMENT && !pending && (
                <WalletErrorMessage
                  message={t("There are not enough funds on your hot wallet, please replenish your wallet")}
                  variant="warning"
                  mb="24px"
                />
              )}

              {currentEscrow.body.state !== EscrowStateEnum.WAITING_PAYMENT && (
                <WalletErrorMessage
                  message={t("Your funds are being transferred, please wait until the end of the transaction")}
                  variant="success"
                />
              )}
              <FlexGap
                gap="14px"
                justifyContent="end"
                mt="24px"
                alignItems="center"
                flexDirection={{ _: "column", mobileL: "row" }}
              >
                <Button
                  onClick={() => {
                    navigate(`/${ROUTES.escrows}`);
                  }}
                  width="100%"
                  maxWidth={{ _: "100%", mobileL: "184px" }}
                  variant="outline"
                  isLoading={pending}
                >
                  {t("Back")}
                </Button>
                {currentEscrow.body.state === EscrowStateEnum.WAITING_PAYMENT && (
                  <Button
                    disabled={!isEnoughFunds}
                    isLoading={pending}
                    width="100%"
                    maxWidth={{ _: "100%", mobileL: "184px" }}
                    onClick={showPayModal}
                  >
                    {t("Pay")}
                  </Button>
                )}
              </FlexGap>
            </>
          )}
        </Column>
      )}
    </>
  );
};

export default Payments;
