import { ChainId } from "../types";
import { Currency } from "./currency";
import { NativeCurrency } from "./native-currency";

import { NATIVE_ADDRESS, NATIVE_CURRENCIES_LOGO } from "configs/web3";

/**
 * Ether is the main usage of a 'native' currency, i.e. for Ethereum mainnet and all testnets
 */
export class Ether extends NativeCurrency {
  protected constructor(chainId: ChainId) {
    super(chainId, 18, "ETH", "Ether", "ETH", NATIVE_ADDRESS, NATIVE_CURRENCIES_LOGO[chainId]);
  }
  // TODO: check in future if this function is needed

  //   public get wrapped(): Token {
  //     const weth9 = WETH9[this.chainId];
  //     invariant(!!weth9, "WRAPPED");
  //     return weth9;
  //   }

  private static _etherCache: { [chainId: ChainId]: Ether } = {};

  public static onChain(chainId: ChainId): Ether {
    return this._etherCache[chainId] ?? (this._etherCache[chainId] = new Ether(chainId));
  }

  public equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId;
  }
}
