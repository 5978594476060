import styled, { DefaultTheme } from "styled-components";
import { FlexGap } from "components/layout";

const getBackgroundColor = (theme: DefaultTheme, value: number) => {
  if (theme.isDark) {
    return theme.colors.transparent;
  } else if (value > 0) {
    return theme.colors.greenLight;
  } else {
    return theme.colors.redLight;
  }
};

export const StyledPercentTag = styled(FlexGap)<{ value: number }>`
  padding: 2px 8px;
  border-radius: 24px;
  text-align: center;
  background-color: ${({ theme, value }) => getBackgroundColor(theme, value)};
  height: 100%;
  max-width: 60px;
`;
