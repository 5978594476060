import { Roles, DeliverySteps } from "../../create-escrow/types";

export const ESCROW_PROCESS_TAB_STEPS = [
  {
    text: "Details and Terms",
  },
  {
    text: "Payments",
  },
  {
    text: "Delivery",
  },
  {
    text: "Preview",
  },
];

export const ESCROW_PROCESS_DESCRIPTION = {
  detailsAndTerms: {
    buyer:
      "Congratulations! Your Escrow has now started. Wait until all participants accept the terms to proceed to the next step.",
    seller:
      "Congratulations! Your Escrow has now started. Wait until all participants accept the terms to proceed to the next step.",
    broker:
      "Please, wait for the other parties to accept the terms of this escrow. Then they will be able to proceed with the payments",
  },
  payments: {
    buyer:
      "As a buyer, you must deposit funds before the item is shipped or the service is provided. Once the funds are deposited, seller can proceed with the delivery of goods.",
    seller:
      "Please wait for buyer to deposit funds before shipping item or providing service. Once the buyer deposit funds, you can proceed with the delivery of goods.",
    broker: "Please wait for the buyer to deposit the funds.",
  },
  delivery: {
    buyer: "delivery.buyer",
    seller: "delivery.seller",
    broker: "delivery.broker",
  },
  preview: {
    buyer: "Congratulations! Your Escrow has been completed.",
    seller: "Congratulations! Your Escrow has been completed. We will transfer the funds into your account soon.",
    broker: "Congratulations! Your Escrow has been completed. We will transfer the funds into your account soon.",
  },
};

export enum stepsName {
  accepting = "accepting",
  canceled = "canceled",
  delivery = "delivery",
  closed = "closed",
  payment = "payment",
  claimed_issue = "claimed_issue",
}

export const stepsNumbers = {
  [stepsName.accepting]: 1,
  [stepsName.canceled]: 1,
  [stepsName.payment]: 2,
  [stepsName.delivery]: 3,
  [stepsName.claimed_issue]: 3,
  [stepsName.closed]: 4,
};

export const DELIVERY_SHIPPABLE_DESCRIPTION: Record<DeliverySteps, Record<Roles, string>> = {
  notShipped: {
    buyer:
      "Your payment is confirmed. Once the seller submits a shipping code, you will be able to track your parcel. Your funds will be safely kept in Zert Vault until the delivery is confirmed. Please, click “Confirm Delivery” when you receive the goods. Please, bear in mind that any kind of fraud or malicious behavior may result in the loss of funds and your account.",
    seller:
      "The buyer has transferred funds into Zert Vault. Please, ship the goods or provide the service. Once the buyer confirms the delivery, we will transfer the funds into your wallet. Don’t forget to provide the shipping code for tracking purposes. If the correct tracking code was not provided, we would not be able to transfer funds into your wallet.",
    broker:
      "The funds have been transferred by the buyer into the Zert Vault. Upon the buyer's confirmation of receipt of goods or services, the payment will be transferred to the seller, and you will receive your commission.",
  },
  shippedWaitingInfo: {
    buyer: "The package is being delivered",
    seller:
      "Please, wait until the buyer confirms the delivery of goods or services. Once done, funds will be transferred into your wallet. Please, bear in mind that any kind of fraud or malicious behavior may result in the loss of funds and your account.",
    broker:
      "The funds have been transferred by the buyer into the Zert Vault. Upon the buyer's confirmation of receipt of goods or services, the payment will be transferred to the seller, and you will receive your commission.",
  },
  inProgress: {
    buyer: "The package is being delivered",
    seller:
      "Please, wait until the buyer confirms the delivery of goods or services. Once done, funds will be transferred into your wallet. Please, bear in mind that any kind of fraud or malicious behavior may result in the loss of funds and your account.",
    broker:
      "The funds have been transferred by the buyer into the Zert Vault. Upon the buyer's confirmation of receipt of goods or services, the payment will be transferred to the seller, and you will receive your commission.",
  },
  distributeFunds: {
    buyer:
      "Appreciate your confirmation of delivery. We've started the fund transfer to the seller. Upon the seller's receipt of funds, the escrow process will be successfully concluded.",
    seller:
      "The buyer has confirmed receiving the goods, and we've begun transferring funds to your wallet. Once the funds are received, the escrow process will be finalized.",
    broker:
      "The buyer has confirmed the receipt of goods or services. We are in the process of transferring funds to the seller, along with the commission to you.",
  },
  delivered: {
    buyer:
      "Please confirm that you have received the goods or services. Bear in mind that any kind of fraud or malicious behavior may result in the loss of funds and the suspension of your account. If you are unable to track your parcel or have faced any other issues, please reach out to the seller or broker (if applicable) directly, outside of our platform, to resolve the issues amicably.",
    seller:
      "Please, wait until the buyer confirms the delivery of goods or services. Once done, funds will be transferred into your wallet. Please, bear in mind that any kind of fraud or malicious behavior may result in the loss of funds and your account.",
    broker:
      "The funds have been transferred by the buyer into the Zert Vault. Upon the buyer's confirmation of receipt of goods or services, the payment will be transferred to the seller, and you will receive your commission.",
  },
};

export const DELIVERY_NOT_SHIPPABLE_DESCRIPTION: { [key in DeliverySteps]?: { [key in Roles]: string } } = {
  notShipped: {
    buyer:
      "Your payment is confirmed. Once the seller submits a shipping code, you will be able to track your parcel. Your funds will be safely kept in Zert Vault until the delivery is confirmed. Please, click “Confirm Delivery” when you receive the goods. Please, bear in mind that any kind of fraud or malicious behavior may result in the loss of funds and your account.",
    seller:
      "The buyer has transferred funds into Zert Vault. Please, ship the goods or provide the service. Once the buyer confirms the delivery, we will transfer the funds into your wallet. Don’t forget to provide the shipping code for tracking purposes. If the correct tracking code was not provided, we would not be able to transfer funds into your wallet.",
    broker:
      "The funds have been transferred by the buyer into the Zert Vault. Upon the buyer's confirmation of receipt of goods or services, the payment will be transferred to the seller, and you will receive your commission.",
  },
  distributeFunds: {
    buyer:
      "Appreciate your confirmation of delivery. We've started the fund transfer to the seller. Upon the seller's receipt of funds, the escrow process will be successfully concluded.",
    seller:
      "The buyer has confirmed receiving the goods, and we've begun transferring funds to your wallet. Once the funds are received, the escrow process will be finalized.",
    broker:
      "The buyer has confirmed the receipt of goods or services. We are in the process of transferring funds to the seller, along with the commission to you.",
  },
  delivered: {
    buyer:
      "Please confirm that you have received the goods or services. Bear in mind that any kind of fraud or malicious behavior may result in the loss of funds and the suspension of your account. If you are unable to track your parcel or have faced any other issues, please reach out to the seller or broker (if applicable) directly, outside of our platform, to resolve the issues amicably.",
    seller:
      "Please, wait until the buyer confirms the delivery of goods or services. Once done, funds will be transferred into your wallet. Please, bear in mind that any kind of fraud or malicious behavior may result in the loss of funds and your account.",
    broker:
      "The funds have been transferred by the buyer into the Zert Vault. Upon the buyer's confirmation of receipt of goods or services, the payment will be transferred to the seller, and you will receive your commission.",
  },
};
