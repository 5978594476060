import { buyTokensList } from "configs";
import { useAppSelector } from "store/store";
import { useThemeContext } from "context";
import { BuyCryptoModalProps } from "../types";

const onramperApiKey = process.env.REACT_APP_ONRAMPER_API_KEY;

const useHandleData = () => {
  const tokenId = useAppSelector(state => (state.modal.props as unknown as BuyCryptoModalProps).tokenId);
  const chainId = useAppSelector(state => state.buyCrypto.buyCryptoPayload.chainId);
  const networks = useAppSelector(state => state.hotWallets.networks);

  const { theme } = useThemeContext();

  const tokensInChain = buyTokensList[chainId];
  const onramperTokenId: string | undefined = tokensInChain
    ? tokensInChain[tokenId as keyof typeof tokensInChain]
    : undefined;
  const fireBlocksWalletAddress = networks?.[chainId]?.[tokenId]?.depositAddress;
  const wallets =
    onramperTokenId && fireBlocksWalletAddress
      ? {
          // Take full name like USDC_BSC:
          [onramperTokenId]: { address: fireBlocksWalletAddress },
        }
      : undefined;

  const iframeWalletsParam = wallets
    ? Object.entries(wallets)
        .map(([key, value]) => `${key}:${value.address}`)
        .join(",")
    : "";

  const gatewaysList = ["Wyre", "Transak", "Mercuryo", "Itez", "Utorg", "BTC Direct", "Xanpool", "Indacoin", "Coinify"];
  const onlyGatewaysParam = gatewaysList.join(",");

  const themeParams = {
    primaryColor: removeHashFromColor(theme.colors.primary),
    secondaryColor: removeHashFromColor(theme.colors.neutral0),
    primaryTextColor: removeHashFromColor(theme.colors.neutral600),
    secondaryTextColor: removeHashFromColor(theme.colors.neutral800),
    containerColor: removeHashFromColor(theme.colors.neutral0),
    cardColor: removeHashFromColor(theme.colors.neutral0),
    borderRadius: "0.5rem",
  };

  const iframeParams = new URLSearchParams({
    ...themeParams,
    themeName: theme.isDark ? "dark" : "light",
    apiKey: onramperApiKey || "",
    wallets: iframeWalletsParam,
    isAddressEditable: "false",
    onlyCryptos: onramperTokenId || "",
    onlyGateways: onlyGatewaysParam,
  });

  const iframeSrc = `https://buy.onramper.com?${iframeParams.toString()}`;

  function removeHashFromColor(color: string) {
    return color.startsWith("#") ? color.slice(1) : color;
  }

  return { iframeSrc, onramperTokenId, tokenId, fireBlocksWalletAddress, chainId };
};

export default useHandleData;
