import { Contract, ContractInterface } from "@ethersproject/contracts";

import { JsonRpcProvider } from "@ethersproject/providers";
import { AddressZero } from "@ethersproject/constants";

import { SUPPORTED_CHAIN_IDS } from "configs/web3/chains";

import { isAddress } from "./address-helpers";

export function getContract(address: string, ABI: ContractInterface, provider: JsonRpcProvider, account?: string) {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, getProviderOrSigner(provider, account));
}

// account is not optional
export const getSigner = (provider: JsonRpcProvider, account: string) => {
  return provider.getSigner(account).connectUnchecked();
};
// account is optional
export const getProviderOrSigner = (provider: JsonRpcProvider, account?: string) => {
  return account ? getSigner(provider, account) : provider;
};

export const getAddress = (address: { [key: number]: string }, chainId: number | undefined) => {
  if (!chainId) {
    throw new Error("Invalid chain id");
  }

  if (!SUPPORTED_CHAIN_IDS.includes(chainId)) {
    throw new Error("Unsupported chain id");
  }

  if (!address[chainId].length) {
    throw new Error("Contract is not deployed");
  }

  if (!isAddress(address[chainId]) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address[chainId]}'.`);
  }

  return address[chainId];
};
