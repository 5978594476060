export enum EscrowStatusEnum {
  NEW = "new",
  ACTIVE = "active",
  REQUIRES_ACTION = "requires_action",
  COMPLETED = "completed",
  CANCELED = "canceled",
  CLAIMED = "claimed",
  COMPLETED_BY_AML = "completed_by_aml",
  CANCELED_BY_AML = "canceled_by_aml",
}

export enum EscrowRolesEnum {
  BUYER = "buyer",
  SELLER = "seller",
  BROKER = "broker",
}

export enum EscrowPaidByEnum {
  BUYER = "buyer",
  SELLER = "seller",
  IN_HALF = "inHalf",
}

export enum EscrowHeadStatusEnum {
  OPEN = "open",
  CLOSED = "closed",
}

export enum EscrowStepsEnum {
  ACCEPTING = "accepting",
  PAYMENT = "payment",
  DELIVERY = "delivery",
  CLOSED = "closed",
  CANCELED = "canceled",
}

export enum EscrowStateEnum {
  NOT_ACCEPTED = "not_accepted",
  ACCEPTED_BY_BUYER = "accepted_by_buyer",
  ACCEPTED_BY_SELLER = "accepted_by_seller",
  MODIFIED_BY_SELLER = "modified_by_seller",
  MODIFIED_BY_BUYER = "modified_by_buyer",
  MODIFIED_BY_ADMIN = "modified_by_admin",
  MODIFIED_BY_BROKER = "modified_by_broker",
  PAYMENT_STARTING = "payment_starting",
  PAYMENT_SENT_GAS = "payment_sent_gas",
  PAYMENT_RECEIVED_GAS = "payment_received_gas",
  PAYMENT_ESCROW_WALLET_ASSIGNED = "payment_escrow_wallet_assigned",
  PAYMENT_SENT_FEE_TO_ESCROW_WALLET = "payment_sent_fee_to_escrow_wallet",
  PAYMENT_SENT_FUNDS_TO_ESCROW_WALLET = "payment_sent_funds_to_escrow_wallet",
  WAITING_SENDING = "waiting_sending",
  WAITING_PAYMENT = "waiting_payment",
  WAIT_DELIVERY = "wait_accept_delivery",
  WAIT_DELIVERY_ADMIN = "wait_accept_admin_delivery",
  PAYMENT_COMPLETED_SENT_FUNDS_TO_SELLER_WALLET = "payment_completed_sent_funds_to_seller_wallet",
  PAYMENT_COMPLETED_SENT_FUNDS_TO_BROKER_WALLET = "payment_completed_sent_funds_to_broker_wallet",
  PAYMENT_COMPLETED_SENT_FUNDS_TO_FEE_WALLET = "payment_completed_sent_funds_to_fee_wallet",
  PAYMENT_COMPLETED_BY_ADMIN_SENT_FUNDS_TO_SELLER_WALLET = "payment_completed_by_admin_sent_funds_to_seller_wallet",
  PAYMENT_COMPLETED_BY_ADMIN_SENT_FUNDS_TO_BROKER_WALLET = "payment_completed_by_admin_sent_funds_to_broker_wallet",
  PAYMENT_COMPLETED_BY_ADMIN_SENT_FUNDS_TO_FEE_WALLET = "payment_completed_by_admin_sent_funds_to_fee_wallet",
  PAYMENT_CANCELED_BY_ADMIN_SENT_FUNDS_TO_BUYER_WALLET = "payment_canceled_by_admin_sent_funds_to_buyer_wallet",
  PAYMENT_CANCELED_BY_ADMIN_SENT_FUNDS_TO_FEE_WALLET = "payment_canceled_by_admin_sent_funds_to_fee_wallet",
  COMPLETED = "completed",
  COMPLETED_BY_ADMIN = "completed_by_admin",
  CANCELED_BY_BUYER = "canceled_by_buyer",
  CANCELED_BY_SELLER = "canceled_by_seller",
  CANCELED_BY_BROKER = "canceled_by_broker",
  CANCELED_BY_ADMIN = "canceled_by_admin",
  FAILED = "failed",
  PAYMENT_COMPLETED_BY_AML_SENT_FUNDS_TO_SELLER_WALLET = "payment_completed_by_aml_sent_funds_to_seller_wallet",
  PAYMENT_COMPLETED_BY_AML_SENT_FUNDS_TO_FEE_WALLET = "payment_completed_by_aml_sent_funds_to_fee_wallet",
  PAYMENT_CANCELED_BY_AML_SENT_FUNDS_TO_BUYER_WALLET = "payment_canceled_by_aml_sent_funds_to_buyer_wallet",
  PAYMENT_CANCELED_BY_AML_SENT_FUNDS_TO_FEE_WALLET = "payment_canceled_by_aml_sent_funds_to_fee_wallet",
  COMPLETED_BY_AML = "completed_by_aml",
  CANCELED_BY_AML = "canceled_by_aml",
}
