import { useState } from "react";
import { SingleValue } from "react-select";

import { useAppDispatch, useAppSelector } from "store/store";
import { setBuyCryptoParams } from "store/buy-crypto";

import { getFilteredOptions } from "utils/helpers";

import { SingleOption } from "components/selects/single-select/types";
import { useGetFilteredOptions } from "hooks";

const useHandleFilters = () => {
  const chainId = useAppSelector(state => state.buyCrypto.buyCryptoPayload.chainId);

  const { options } = useGetFilteredOptions();
  const { networksOptions } = options;
  const networkOptionsWithoutDefaultLabel = networksOptions.slice(1);

  const preselectNetworkOption =
    networkOptionsWithoutDefaultLabel.find(option => option.value === chainId) ?? networkOptionsWithoutDefaultLabel[0];

  const [selectedNetworkOption, setSelectedNetworkOption] = useState(preselectNetworkOption);

  const dispatch = useAppDispatch();

  const handleSelectNetworkChange = (selectedOption: SingleValue<SingleOption>) => {
    if (selectedOption) {
      setSelectedNetworkOption({ value: selectedOption.value, label: selectedOption.label });
      dispatch(setBuyCryptoParams({ page: 1, chainId: selectedOption.value }));
    }
  };

  return {
    selectedOption: selectedNetworkOption,
    options: getFilteredOptions(networkOptionsWithoutDefaultLabel, selectedNetworkOption.value),
    handleSelectChange: handleSelectNetworkChange,
  };
};

export default useHandleFilters;
