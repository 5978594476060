import { Svg } from "components/svg";
import { css, DefaultTheme } from "styled-components";
import { Colors } from "theme/types";
import { scales, Variant } from "./types";

export const variantStyles = (
  theme: DefaultTheme,
  variant: Variant = "primary",
  color?: keyof Colors,
  borderColor?: keyof Colors,
) => {
  return {
    primary: css`
      background-color: ${({ theme }) => (color ? theme.colors[color] : theme.colors.primary)};
      color: ${({ theme }) => (theme.isDark ? theme.colors.neutral1000 : theme.colors.neutral0)};

      &:not([disabled]):hover {
        background-color: ${({ theme }) => (color ? theme.colors[color] : theme.colors.primaryHover)};
        color: ${({ theme }) => (theme.isDark ? theme.colors.neutral1000 : theme.colors.neutral0)};
        text-decoration: none;
      }

      ${Svg} {
        fill: ${({ theme }) => (theme.isDark ? theme.colors.neutral1000 : theme.colors.neutral0)};
      }

      &:disabled {
        background-color: ${theme.colors.grey2};
        cursor: not-allowed;
      }
    `,

    outline: css`
      background-color: ${({ theme }) => theme.colors.transparent};
      border: 1.5px solid ${({ theme }) => (borderColor ? theme.colors[borderColor] : theme.colors.neutral1000)};
      color: ${({ theme }) => (color ? theme.colors[color] : theme.colors.neutral1000)};

      &:not([disabled]):hover {
        background-color: ${({ theme }) =>
          theme.isDark ? theme.colors.primaryHover + "20" : theme.colors.primaryLight};
        border: 1.5px solid ${theme.colors.primaryHover};
        color: ${theme.colors.primaryHover};
        ${Svg} {
          fill: ${theme.colors.primaryHover};
        }
      }

      ${Svg} {
        fill: ${({ theme }) => (color ? theme.colors[color] : theme.colors.neutral1000)};
      }

      &:disabled {
        opacity: 0.33;
        cursor: not-allowed;
      }
    `,

    chip: css`
      background-color: ${({ theme }) => theme.colors.transparent};
      border: 1.5px solid ${({ theme }) => (borderColor ? theme.colors[borderColor] : theme.colors.neutral300)};
      color: ${({ theme }) => (color ? theme.colors[color] : theme.colors.neutral800)};
      font-weight: ${({ theme }) => theme.fontWeight.regular};
      height: 32px;

      &:not([disabled]):hover {
        background-color: ${({ theme }) =>
          theme.isDark ? theme.colors.primaryHover + "20" : theme.colors.primaryLight};
        border: 1.5px solid ${theme.colors.primaryHover};
        color: ${theme.colors.primaryHover};
        ${Svg} {
          fill: ${theme.colors.primaryHover};
        }
      }

      ${Svg} {
        fill: ${({ theme }) => (color ? theme.colors[color] : theme.colors.neutral800)};
      }

      &:disabled {
        background-color: ${({ theme }) => theme.colors.transparent};
        color: ${({ theme }) => (color ? theme.colors[color] : theme.colors.neutral300)};
        cursor: not-allowed;
      }
    `,
  }[variant];
};

export const scaleVariants = {
  [scales.MD]: {
    padding: "14px 28px",
    minWidth: 100,
  },
  [scales.SM]: {
    padding: "6px 16px",
    minWidth: 52,
  },
};
