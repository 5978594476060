import styled from "styled-components";

export const StyledQrWrapper = styled.div`
  display: flex;
  padding: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral200};
  border-top: 1px solid ${({ theme }) => theme.colors.neutral200};
  width: 100%;
  justify-content: center;
  margin-bottom: 24px;
`;

export const StyledURLWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 44px;
  padding: 10px 14px;
  border: 1px solid ${({ theme }) => theme.colors.neutral300};
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  margin-bottom: 12px;
`;
