import { useState } from "react";
import { toast } from "react-toastify";

import { useTranslation } from "context";
import { toastOptionsError } from "components/alerts";

import { compressImage } from "utils/helpers";
import { getFileExtension, dataUrlToFile } from "./utils";

import { DIMENSIONS, IMAGE_EXTENSIONS, MAX_SIZE } from "./constants";

export const BUFFER_LENGTH = 1024 * 1024;

const defaultImage = { src: "", name: "" };
const defaultZoom = { zoom: 0, initialZoom: 0 };
const defaultCrop = { x: 0, y: 0 };

export const useUploadImage = (onImageLoad?: (args: typeof defaultImage) => void, maxSize: number = MAX_SIZE) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(defaultImage);
  const [zoom, setZoom] = useState(defaultZoom);
  const [crop, setCrop] = useState(defaultCrop);

  const suitableExtension = Object.values(IMAGE_EXTENSIONS).toString();

  const handleUploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    if (e.target.files) {
      const loadedFile = e.target.files[0];
      const extensionFile = getFileExtension(loadedFile.name);
      if (!(extensionFile in IMAGE_EXTENSIONS)) {
        setIsLoading(false);
        toast.error(
          t("Only files in %formats% formats are allowed", { formats: suitableExtension.replace(",", ", ") }),
          toastOptionsError,
        );
      } else {
        try {
          const currentFile = await compressImage(loadedFile, maxSize);
          if (currentFile && currentFile.name) {
            if (currentFile.name) {
              const reader = new FileReader();

              reader.onload = async () => {
                const baseURL = reader.result;

                const data = { src: baseURL as string, name: currentFile.name };
                setImage(data);
                setIsLoading(false);

                if (onImageLoad) {
                  onImageLoad(data);
                }
              };
              reader.readAsDataURL(currentFile);
            }
          }
        } catch {
          setIsLoading(false);

          toast.error(t("We were unable to compress your file, please try another one."), toastOptionsError);
        }
      }
    }
  };

  const onZoomChange = (_zoom: number) => {
    if (_zoom < zoom.initialZoom) {
      return;
    }
    setZoom(prev => ({ ...prev, zoom: _zoom }));
  };

  const onMediaLoaded = (mediaSize: { width: number; height: number }) => {
    const size = mediaSize.width < mediaSize.height ? mediaSize.width : mediaSize.height;
    // rounding up to 14 decimal places, due to the fact that InputRange does not work with a large number
    const zoom = +(DIMENSIONS.heightCrop / size).toFixed(14);

    setZoom({ zoom, initialZoom: zoom });
  };

  const resetImage = () => {
    setImage(defaultImage);
    setZoom(defaultZoom);
    setCrop(defaultCrop);
  };

  return {
    image,
    zoom,
    crop,
    suitableExtension,
    isLoading,
    setZoom,
    onZoomChange,
    setImage,
    setCrop,
    dataUrlToFile,
    handleUploadImage,
    onMediaLoaded,
    resetImage,
  } as const;
};

export default useUploadImage;
