import { Loader } from "components";
import { lazyLoad } from "utils/loadable";

export { default as Slider } from "./slider";

export const SwapInfo = lazyLoad(
  () => import("./swap-info"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);
export const Swap = lazyLoad(
  () => import("./swap"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const Offers = lazyLoad(
  () => import("./offers"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const HistoryTable = lazyLoad(
  () => import("./history"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);
