import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 72 72" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_9151_117497)">
        <rect x="18" y="49" width="36" height="12" rx="4" fill={theme.colors.primary} />
      </g>
      <circle cx="36" cy="36" r="28" fill="#EBF1FF" />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.primary} strokeOpacity="0.5" />
      <path
        d="M35.9993 27.7466V36"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.5013 32.2484V32.9988"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4974 39.7515V32.2484"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4941 42.0025V45.0038C22.4941 47.4901 24.5097 49.5056 26.996 49.5056H29.9973"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4941 29.9975V26.9963C22.4941 24.5099 24.5097 22.4944 26.996 22.4944H29.9973"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.002 22.4944H45.0032C47.4895 22.4944 49.5051 24.5099 49.5051 26.9963V29.9975"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.9964 37.5006V34.4994"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.253 51.0062C41.5218 51.0062 39.0594 49.361 38.0142 46.8376C36.969 44.3143 37.5468 41.4098 39.4781 39.4785C41.4093 37.5472 44.3138 36.9694 46.8372 38.0147C49.3606 39.0599 51.0058 41.5222 51.0058 44.2534C51.0058 47.9829 47.9825 51.0062 44.253 51.0062Z"
        fill={theme.colors.primary}
        fillOpacity="0.15"
      />
      <path
        d="M44.253 51.0062C41.5218 51.0062 39.0594 49.361 38.0142 46.8376C36.969 44.3143 37.5468 41.4098 39.4781 39.4785C41.4093 37.5472 44.3138 36.9694 46.8372 38.0147C49.3606 39.0599 51.0058 41.5222 51.0058 44.2534C51.0058 47.9829 47.9825 51.0062 44.253 51.0062"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.0872 44.1784C46.0458 44.1784 46.0122 44.212 46.0122 44.2534C46.0122 44.2949 46.0458 44.3285 46.0872 44.3285C46.1287 44.3285 46.1623 44.2949 46.1623 44.2534C46.1623 44.212 46.1287 44.1784 46.0872 44.1784"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.4178 44.1784C42.3771 44.1784 42.3442 44.2113 42.3442 44.2519V44.2534C42.3442 44.2949 42.3778 44.3285 42.4193 44.3285C42.4607 44.3285 42.4943 44.2949 42.4943 44.2534C42.4943 44.212 42.4607 44.1784 42.4193 44.1784"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.primary} strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_9151_117497"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_9151_117497" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
