import React, { PropsWithChildren } from "react";
import { toast } from "react-toastify";
import { clusterApiUrl } from "@solana/web3.js";
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  MathWalletAdapter,
  TrustWalletAdapter,
  SpotWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { WalletDisconnectedError, WalletError } from "@solana/wallet-adapter-base";
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";

import { useTranslation } from "context";

import { useMulticallUpdater, useBlockNumber, useTransactionsUpdater } from "configs/web3";
import { toastOptionsError } from "components";
import { LOCAL_STORAGE_KEYS } from "configs";
import { SOLANA_NETWORK } from "configs/solana";

const Updaters = () => {
  useBlockNumber();
  useMulticallUpdater();
  useTransactionsUpdater();

  return null;
};

const SolanaProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const network = SOLANA_NETWORK;
  const mainNetEndpoint = process.env.REACT_APP_SOLANA_RPC_HOST as string;
  const endpoint = process.env.REACT_APP_ENV === "development" ? clusterApiUrl(network) : mainNetEndpoint;

  const { t } = useTranslation();

  const wallets = [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter({ network }),
    new MathWalletAdapter({ network }),
    new TrustWalletAdapter(),
    new SpotWalletAdapter(),
  ];

  const onError = (error: WalletError) => {
    if (error instanceof WalletDisconnectedError) {
      return;
    }
    const message = error.message ? error.message : t("Problems with solana wallet were detected");
    toast.error(message, toastOptionsError);

    console.error("Errors from Solana wallet: ", error);
  };

  // autoConnect works only if LOCAL_STORAGE_KEYS.solanaWallet is exists
  // It will be set when choose solana wallet
  // And will be removed after solana disconnect
  return (
    <ConnectionProvider
      endpoint={endpoint}
      config={
        process.env.REACT_APP_ENV === "development"
          ? undefined
          : {
              fetchMiddleware: (info, init, fetch) => {
                type Init = typeof init & { credentials: string };
                const request: Init = { ...init, credentials: "include" };
                fetch(info, request);
              },
            }
      }
    >
      <WalletProvider wallets={wallets} autoConnect localStorageKey={LOCAL_STORAGE_KEYS.solanaWallet} onError={onError}>
        <WalletModalProvider>
          <>
            {/*Updaters are required for web3Provider and SolanaProvider. And must be turned around by each of the providers*/}
            <Updaters />
            {children}
          </>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default SolanaProvider;
