import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => state.escrowProcess.currentEscrow,
    (state: AppState) => state.tokensList.allTokensList,
    (state: AppState) => state.createEscrow.pending,
    (state: AppState) => state.createEscrow.error,
  ],
  (currentEscrow, allTokensList, pending, error) => ({
    currentEscrow,
    allTokensList,
    pending,
    error,
  }),
);
