import React from "react";
import {
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  // FacebookMessengerIcon,
  // FacebookMessengerShareButton,
  TwitterShareButton,
  XIcon
} from "react-share";

import { useCopyContent, useTooltip } from "hooks";
import { useThemeContext, useTranslation } from "context";

import { Text, StyledQr, Column, FlexGap, StyledLink } from "components";
import CloseModalWrapper from "../close-modal-wrapper";
import { EscrowCreatedIcon } from "components/svg";
import { StyledModalWrapper } from "../styled";
import { StyledQrWrapper, StyledURLWrapper } from "./styled";

import { useAppSelector } from "store/store";

import { EscrowCreatedModalProps } from "./types";

import { ROUTES } from "navigation/routes";

const EscrowCreatedModal: React.FC = () => {
  const { escrowId, closeModalHandler } = useAppSelector(
    state => state.modal.props as unknown as EscrowCreatedModalProps,
  );

  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const { copyContentHandler } = useCopyContent();

  const URL = process.env.REACT_APP_URL;
  const redirectURI = `${URL}/${ROUTES.escrows}/${ROUTES.escrowProcess}/${escrowId}`;

  const { targetRef: targetRefURL, tooltip: URLTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} />
      <EscrowCreatedIcon width="72px" mt="18px" mb="16px" />
      <Text color="neutral600" textAlign="center" textScale="body2" mb="16px">
        {t("Escrow Created!")}
      </Text>
      <Text color="neutral500" textAlign="center" textScale="caption2" mb="24px">
        {t(
          "Your escrow has been successfully initiated. We have notified the other party and are currently awaiting their acceptance of the terms.",
        )}
      </Text>
      <StyledQrWrapper>
        <StyledQr
          fgColor={redirectURI && !theme.isDark ? theme.colors.neutral1000 : theme.colors.neutral200}
          value={redirectURI}
        />
      </StyledQrWrapper>
      <Column width="100%" mb="24px">
        <Text color="neutral500" textScale="caption2" mb="12px">
          {t("Share")}
        </Text>

        <FlexGap justifyContent="space-between" gap="26px">
          <Column>
            <TelegramShareButton url={redirectURI}>
              <TelegramIcon round size={32} />
            </TelegramShareButton>

            <Text color="neutral800" textScale="caption2" mt="4px">
              {t("Telegram")}
            </Text>
          </Column>

          {/*TODO finish after adding facebook appId*/}
          {/*<Column>*/}
          {/*  <FacebookMessengerShareButton url={redirectURI}>*/}
          {/*    <FacebookMessengerIcon round size={32} />*/}
          {/*  </FacebookMessengerShareButton>*/}

          {/*  <Text color="neutral800" textScale="caption2" mt="4px">*/}
          {/*    {t("Messenger")}*/}
          {/*  </Text>*/}
          {/*</Column>*/}

          <Column>
            <WhatsappShareButton url={redirectURI}>
              <WhatsappIcon round size={32} />
            </WhatsappShareButton>

            <Text color="neutral800" textScale="caption2" mt="4px">
              {t("WhatsApp")}
            </Text>
          </Column>

          <Column>
            <TwitterShareButton url={redirectURI}>
              <XIcon round size={32} />
            </TwitterShareButton>

            <Text color="neutral800" textScale="caption2" mt="4px">
              {t("Twitter")}
            </Text>
          </Column>
        </FlexGap>
      </Column>

      <Column width="100%">
        <Text color="neutral500" textScale="caption2" mb="12px">
          {t("URL")}
        </Text>

        <StyledURLWrapper>
          <Text color="neutral800" textScale="caption1" ellipsis ref={targetRefURL}>
            {redirectURI}
          </Text>
          {URLTooltip}
        </StyledURLWrapper>
        <StyledLink
          color="primary"
          mb="30px"
          textScale="caption2"
          onClick={() => copyContentHandler(redirectURI)}
          width="100%"
          justifyContent="center"
        >
          {t("Click to copy link")}
        </StyledLink>
      </Column>
    </StyledModalWrapper>
  );
};

export default EscrowCreatedModal;
