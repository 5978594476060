import * as yup from "yup";

import { useTranslation } from "context";
import { InitialValuesType } from "./types";

export const initialValues: InitialValuesType = {
  code: "",
};

export const MIN_LENGTH = 6;

const useValidationSchema = () => {
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    code: yup
      .string()
      .required(t("Please enter the code"))
      .min(MIN_LENGTH, t("Code length must contain %amount% digits", { amount: MIN_LENGTH })),
  });

  return { validationSchema, initialValues };
};

export default useValidationSchema;
