import { useTranslation } from "context";
import { useAppDispatch } from "store/store";

import { Text } from "components";

import { isErrorResult } from "services";

import { partialUpdateModalProps } from "store/modal";

import {
  VerificationProcessInterface,
  YubikeyVerificationModalProps,
} from "components/modal/components/yubikey-verification-modal/types";
import { YubikeyPayloadSignTransactionPropsAssignment } from "store/yubikey/types";
import { HandleYubikeyLoginArgs } from "pages/auth/login-with-yubikey-verification/components/types";
import { PartialUpdateModalProps } from "store/modal/types";
import { useSignInTransactionWithYubikey } from "hooks";
import { useEffect } from "react";

const VerificationProcess = <T, R>({
  verificationCallback,
  signInActionCallback,
  signInSuccessCallback,
  propsForSignInCallback,
}: VerificationProcessInterface<T, R>): JSX.Element => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { signInTransactionWithYubikey } = useSignInTransactionWithYubikey({
    verificationCallback,
    handleSignInTransaction,
  });

  useEffect(() => {
    signInTransactionWithYubikey();
  }, []);

  function handleSignInTransaction({ rawId, clientDataJSON, email }: HandleYubikeyLoginArgs) {
    const _partialProps = partialUpdateModalProps as PartialUpdateModalProps<YubikeyVerificationModalProps<T, R>>;

    const newData = propsForSignInCallback as YubikeyPayloadSignTransactionPropsAssignment; //This for we can take yubico props from propsForSignInCallback, because propsForSignInCallback it`s T generic

    const data = {
      yubico: {
        ...newData.yubico,
        id: rawId,
        data: clientDataJSON,
        email,
      },
    };

    dispatch(_partialProps({ pending: true }));

    dispatch(signInActionCallback(data as T)).then(response => {
      dispatch(_partialProps({ pending: false }));

      if (!isErrorResult(response.payload)) {
        signInSuccessCallback();
      } else {
        verificationCallback();
      }
    });
  }

  return (
    <>
      <Text textScale="body3" color="neutral600" pb="14px">
        {t("Complete verification using your Yubikey")}
      </Text>

      <Text textScale="caption2" color="neutral600">
        {t(
          "Please review the notification in your browser and use Yubikey according to the instructions to finalize the process.",
        )}
      </Text>
    </>
  );
};

export default VerificationProcess;
