import React from "react";

import { useThemeContext } from "context";

import { Svg } from "components";

import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "neutral800", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color={color}>
      <path
        d="M12.6797 10.3633V13.8487H17.5233C17.3106 14.9696 16.6724 15.9188 15.7151 16.5569L18.636 18.8233C20.3378 17.2525 21.3196 14.9452 21.3196 12.2043C21.3196 11.5661 21.2624 10.9524 21.156 10.3634L12.6797 10.3633Z"
        fill={theme.colors.googleBlue}
      />
      <path
        d="M7.63614 13.7148L6.97736 14.2191L4.64551 16.0355C6.12641 18.9727 9.16164 21.0018 12.6798 21.0018C15.1097 21.0018 17.147 20.2 18.6361 18.8255L15.7152 16.5591C14.9134 17.0991 13.8907 17.4264 12.6798 17.4264C10.3398 17.4264 8.35169 15.8473 7.63982 13.72L7.63614 13.7148Z"
        fill={theme.colors.googleGreen}
      />
      <path
        d="M4.64507 7.96484C4.03147 9.1757 3.67969 10.5421 3.67969 11.9984C3.67969 13.4548 4.03147 14.8211 4.64507 16.032C4.64507 16.0401 7.63967 13.7084 7.63967 13.7084C7.45967 13.1684 7.35327 12.5957 7.35327 11.9983C7.35327 11.401 7.45967 10.8283 7.63967 10.2883L4.64507 7.96484Z"
        fill={theme.colors.googleYellow}
      />
      <path
        d="M12.68 6.58363C14.0055 6.58363 15.1836 7.0418 16.1245 7.92545L18.7018 5.34821C17.139 3.89187 15.11 3 12.68 3C9.16183 3 6.12641 5.0209 4.64551 7.96637L7.64001 10.29C8.35179 8.16271 10.34 6.58363 12.68 6.58363Z"
        fill={theme.colors.googleRed}
      />
    </Svg>
  );
};

export default Icon;
