export const HEADERS = [
  {
    width: "70%",
    text: "",
  },
  {
    width: "30%",
    text: "",
  },
];
