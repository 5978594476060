import { useState } from "react";

import { useShowMitekModal, useShowWarningModal } from "hooks";
import { useShowVoiceVerifyModal } from "./voice-verify";
import { useVerifyWithBothBiometric } from "./face-and-voice-verify";
import { useShowVerifyModal } from "./face-verify";
import { useTranslation } from "context";

import { isErrorResult } from "services";

import { showModal } from "store/modal";
import { useAppDispatch, useAppSelector } from "store/store";
import { checkMitekStatus } from "store/verify-biometric/actions";
import { confirmWithdraw, sendCodesForWithdraw } from "store/wallets/cold-wallets/actions";
import { setIsShowError } from "store/verify-biometric";

import { MitekStatus } from "store/verify-biometric/types";
import { ConfirmWithdrawPayload, SendCodesPayloadWithdraw } from "store/wallets/types";
import { BiometricType } from "components/modal/components/setup-biometric-modal/types";
import { YubikeyVerificationModalProps } from "components/modal/components/yubikey-verification-modal/types";
import { ModalProps } from "store/modal/types";

const useHandleWithdraw = (callNextModal: () => void, callSuccessModal: () => void) => {
  const user = useAppSelector(state => state.profile.user);

  const [isConfirmed, setIsConfirmed] = useState(false);

  const { t } = useTranslation();

  const { handlerBiometric } = useShowWarningModal({
    useShowModal: useShowVerifyModal,
    actionText: "Verify",
    actionType: "verify with",
  });

  const { handlerBiometric: handleVoiceBiometric } = useShowWarningModal({
    useShowModal: useShowVoiceVerifyModal,
    actionText: "Verify",
    actionType: "verify with",
    biometricType: BiometricType.voice,
  });

  const { handlerBiometric: handleBothBiometricVerify } = useShowWarningModal({
    useShowModal: useVerifyWithBothBiometric,
    actionText: "Verify",
    actionType: "verify with",
    biometricType: BiometricType.both,
  });

  const dispatch = useAppDispatch();

  const { showErrorModal } = useShowMitekModal();

  const handleVerifyCallback = (data: SendCodesPayloadWithdraw) => {
    dispatch(sendCodesForWithdraw(data)).then(res => {
      setIsConfirmed(false);
      if (!isErrorResult(res.payload)) {
        callNextModal();
      }
    });
  };

  const handleVerifyWithYubikeyCallBack = (data: SendCodesPayloadWithdraw) => {
    const _showModal = showModal as ModalProps<YubikeyVerificationModalProps<ConfirmWithdrawPayload>>;

    dispatch(
      _showModal({
        modalName: "yubikeyVerificationModal",
        rootId: "cold-storage",
        props: {
          signInSuccessCallback: callSuccessModal,
          signInActionCallback: confirmWithdraw,
          switchAnotherMethodVerificationCallback: () => handleVerifyCallback(data),
          propsForSignInCallback: {
            yubico: {
              ...data,
            },
          },
        },
      }),
    );
  };

  const handleErrorModal = (key: string, data: SendCodesPayloadWithdraw) => {
    showErrorModal(key, { callback: () => handleVerifyCallback(data), title: t("Verify with codes") });
  };

  const handleConfirmWithdraw = (data: SendCodesPayloadWithdraw) => {
    dispatch(setIsShowError(true));
    if (user) {
      const {
        securitySettings: {
          mitek: { face, voice },
          twoFa,
          yubikey,
        },
      } = user;
      if (twoFa.withdrawFromCW) {
        handleVerifyCallback(data);
      } else if (yubikey.withdrawFromCW) {
        handleVerifyWithYubikeyCallBack(data);
      } else {
        dispatch(checkMitekStatus()).then(res => {
          setIsConfirmed(false);
          if (!isErrorResult(res.payload)) {
            const mitekStatuses = res.payload!;
            if (face.withdrawFromCW && voice.withdrawFromCW) {
              const isAuthentic = checkIsStatusAuthentic([mitekStatuses.faceStatus, mitekStatuses.voiceStatus]);
              if (isAuthentic) {
                handleBothBiometricVerify();
              } else {
                callErrorModal([mitekStatuses.faceStatus, mitekStatuses.voiceStatus], data);
              }
            } else if (face.withdrawFromCW) {
              const isAuthentic = checkIsStatusAuthentic([mitekStatuses.faceStatus]);
              if (isAuthentic) {
                handlerBiometric();
              } else {
                callErrorModal([mitekStatuses.faceStatus], data);
              }
            } else {
              const isAuthentic = checkIsStatusAuthentic([mitekStatuses.voiceStatus]);
              if (isAuthentic) {
                handleVoiceBiometric();
              } else {
                callErrorModal([mitekStatuses.voiceStatus], data);
              }
            }
          }
        });
      }
    }
  };

  const checkIsStatusAuthentic = (statuses: (string | undefined)[]) => {
    return statuses.every(status => status === MitekStatus.AUTHENTIC);
  };

  const callErrorModal = (statuses: (string | undefined)[], data: SendCodesPayloadWithdraw) => {
    if (statuses.some(item => item === MitekStatus.FRAUDULENT)) {
      handleErrorModal(MitekStatus.FRAUDULENT.toUpperCase(), data);
    } else {
      handleErrorModal(MitekStatus.UNDETERMINED.toUpperCase(), data);
    }
  };

  return { isConfirmed, handleConfirmWithdraw };
};

export default useHandleWithdraw;
