import React, { useEffect, useState } from "react";
import moment from "moment";
import BigNumber from "bignumber.js";
import { LineChart, Line, YAxis, ResponsiveContainer, CartesianGrid, XAxis, Tooltip, TooltipProps } from "recharts";

import { useGetFiatSymbolAndAbbreviation } from "hooks";
import { useThemeContext } from "context";

import { Text, Flex } from "components";
import { StyledChartTooltip, StyledLoader, StyledWrapper } from "./styled";

import { DynamicBalanceChartProps } from "./types";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";

const DynamicBalanceChart: React.FC<DynamicBalanceChartProps> = ({ data, isDataLoading = false }) => {
  const { theme } = useThemeContext();
  const { fiatAbbreviation } = useGetFiatSymbolAndAbbreviation();

  const [isShowTooltip, setIsShowTooltip] = useState(true);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setIsShowTooltip(false);
  };

  const onMouseEnterHandler = () => {
    if (!isShowTooltip) {
      setIsShowTooltip(true);
    }
  };

  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <StyledChartTooltip>
          <Text textScale="caption1" $fontWeight="medium" color="neutral0">
            {`${BigNumber((payload[0].value as string) ?? "0").toFormatExtended(2)} ${fiatAbbreviation}`}
          </Text>
          <Text textScale="caption2" $fontWeight="medium" color="neutral500">
            {moment(payload[0].payload.date).format("MM/DD/YYYY")}
          </Text>
        </StyledChartTooltip>
      );
    }

    return null;
  };

  return (
    <Flex
      width="100%"
      height="100%"
      minHeight="300px"
      position="relative"
      onMouseLeave={handleResize}
      onMouseMove={onMouseEnterHandler}
    >
      {isDataLoading ? <StyledLoader /> : null}

      <StyledWrapper isDataLoading={isDataLoading}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <CartesianGrid stroke={theme.colors.neutral200} vertical={false} />
            <Line
              type="monotone"
              dataKey="uv"
              stroke={theme.colors.primary}
              strokeWidth={2}
              dot={false}
              activeDot={{ stroke: theme.colors.blueLight, strokeWidth: 6, r: 7 }}
            />
            <XAxis
              dataKey="name"
              tickLine={false}
              interval="preserveStartEnd"
              axisLine={{ stroke: theme.colors.neutral200 }}
              stroke={theme.colors.neutral500}
              hide={true}
            />
            <YAxis tickCount={8} hide={true} />
            <Tooltip active={isShowTooltip} cursor={false} content={<CustomTooltip />} />
          </LineChart>
        </ResponsiveContainer>
      </StyledWrapper>
    </Flex>
  );
};

export default DynamicBalanceChart;
