import { PropsWithChildren } from "react";
import { SolanaProvider, Web3Provider } from "..";

const Web3SolanaProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Web3Provider>
      <SolanaProvider>{children}</SolanaProvider>
    </Web3Provider>
  );
};

export default Web3SolanaProvider;
