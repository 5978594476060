import React from "react";
import moment from "moment";
import BigNumber from "bignumber.js";

import { Column, Flex, Td, Text, Tr } from "components";
import { TokenToChainInfo } from "./components";
import { useParams } from "react-router-dom";

import { useTranslation } from "context";

import { TransactionsListTableRowProps } from "../types";

import { statementTransactionText, transactionOperationText } from "../../../constants";
import { useHandleTooltip } from "../../hooks";

const TableRowDesktop: React.FC<TransactionsListTableRowProps> = ({ item }) => {
  const { statementId } = useParams();
  const { t } = useTranslation();

  const {
    targetRefDate,
    targetRefType,
    targetRefOperation,
    targetRefFee,
    targetRefAmount,
    creationDate,
    dateTooltip,
    typeTooltip,
    operationTooltip,
    feeTooltip,
    amountTooltip,
    amountInFiat,
    feeInFiat,
    firstItemFeeStr,
  } = useHandleTooltip(item);

  return (
    <Tr hover>
      <Td p={{ laptop: "24px 12px" }}>
        <Column>
          <Text textScale="body3" color="neutral600" ref={targetRefDate} ellipsis>
            {moment(creationDate).format("MM/DD/YYYY")} {moment(creationDate).format(" h:mm:ss A")}
          </Text>
          {dateTooltip}
        </Column>
      </Td>

      <Td p={{ laptop: "24px 12px" }}>
        <Flex>
          <Text color="neutral600" textScale="body3" ref={targetRefType} ellipsis>
            {statementTransactionText[item.type]}
          </Text>
          {typeTooltip}
        </Flex>
      </Td>

      <Td p={{ laptop: "24px 12px" }}>
        <Flex>
          <Text color="neutral600" textScale="body3" ref={targetRefOperation} ellipsis>
            {transactionOperationText[item.operation]}
          </Text>
          {operationTooltip}
        </Flex>
      </Td>

      <Td p={{ laptop: "24px 12px" }}>
        <Flex flexDirection="column">
          <TokenToChainInfo
            currencyChainId={item.currency.chainId}
            tokenSymbol={item.currency.symbol}
            publicCode={item.currency.publicCode}
          />
        </Flex>
      </Td>

      <Td p={{ laptop: "24px 12px" }}>
        <Column ref={targetRefAmount}>
          <Text textScale="body3" ellipsis>
            {BigNumber(item.amount).toFormatExtended(8)}
          </Text>
          {statementId && (
            <Text color="neutral500" textScale="caption2" ellipsis>
              {amountInFiat}
            </Text>
          )}
          {amountTooltip}
        </Column>
      </Td>

      <Td p={{ laptop: "24px 12px" }}>
        <Column ref={targetRefFee}>
          <Text textScale="body3" ellipsis>
            {item.fees.length > 1 ? t("Multiple") : firstItemFeeStr}
          </Text>
          {statementId && (
            <Text color="neutral500" textScale="caption2" ellipsis>
              {feeInFiat}
            </Text>
          )}
          {feeTooltip}
        </Column>
      </Td>
    </Tr>
  );
};

export default TableRowDesktop;
