import { createTransferInstruction, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey, SystemProgram, Transaction } from "@solana/web3.js";
import { toast } from "react-toastify";
import { BigNumber } from "@ethersproject/bignumber";

import { SolanaToastDescriptionWithTx, toastOptionsSuccess } from "components";

import { useTranslation } from "context";

import { useWaitTransactionSolana } from "./";
import { Currency, logError, truncateHash } from "configs/web3";
import { getOrCreateAssociatedTokenAccount } from "../utils";

export const useSendTokenSolana = (currency: Currency | null, toPublicKey?: PublicKey) => {
  const { connection } = useConnection();
  const { publicKey: fromPubkey, connected, sendTransaction } = useWallet();
  const { fetchWithCatchTxErrorSolana, loading: pendingTx } = useWaitTransactionSolana();
  const { t } = useTranslation();

  //getOrCreateAssociatedTokenAccount - it`s our custom method to get token account for transaction on SPL token or create this token account for SPL token transaction in future
  const createCustomTransferTx = async (value: BigNumber) => {
    try {
      if (!fromPubkey) {
        throw new WalletNotConnectedError(t("Solana wallet is not connected"));
      }

      if (!currency?.address) {
        throw new Error(t("There's no address for selected token"));
      }

      if (!toPublicKey) {
        throw new Error(t("There's no deposit address"));
      }

      const transaction = new Transaction();
      const tokenPublicKey = new PublicKey(currency.address);

      const associatedTokenFrom = await getOrCreateAssociatedTokenAccount(
        connection,
        fromPubkey,
        tokenPublicKey,
        fromPubkey,
        sendTransaction,
      );

      const associatedTokenTo = await getOrCreateAssociatedTokenAccount(
        connection,
        fromPubkey,
        tokenPublicKey,
        toPublicKey,
        sendTransaction,
      );

      const instruction = createTransferInstruction(
        associatedTokenFrom.address,
        associatedTokenTo.address,
        fromPubkey,
        +value.toString(),
        [],
        TOKEN_PROGRAM_ID,
      );

      transaction.add(instruction);

      const { blockhash } = await connection.getLatestBlockhash();
      transaction.feePayer = fromPubkey;
      transaction.recentBlockhash = blockhash;

      return transaction;
    } catch (error) {
      logError(
        error,
        `createCustomTransferTx in useSendTokenSolana - ${currency?.address} ${toPublicKey?.toBase58()} ${value.toString()} -`,
      );
    }
  };

  const createNativeTransferTx = async (value: BigNumber) => {
    try {
      if (!fromPubkey) {
        throw new WalletNotConnectedError(t("Solana wallet is not connected"));
      }

      if (!toPublicKey) {
        throw new Error(t("There's no deposit address"));
      }

      const transaction = new Transaction();
      transaction.add(
        SystemProgram.transfer({
          fromPubkey,
          toPubkey: toPublicKey,
          lamports: +value,
        }),
      );
      const { blockhash } = await connection.getLatestBlockhash("finalized");

      transaction.recentBlockhash = blockhash;
      transaction.feePayer = fromPubkey;

      return transaction;
    } catch (error) {
      logError(
        error,
        `createNativeTransferTx in useSendTokenSolana - ${currency?.address} ${toPublicKey?.toBase58()} ${value.toString()} -`,
      );
    }
  };

  const createTxToSend = async (value: BigNumber) => {
    const createHandler = currency?.isNative ? createNativeTransferTx : createCustomTransferTx;

    return createHandler(value);
  };

  const sendToken = async (value: BigNumber, callback: (() => Promise<void>) | (() => void)) => {
    const tx = await createTxToSend(value);

    if (tx) {
      const confirmed = await fetchWithCatchTxErrorSolana(tx);

      if (confirmed?.signature && currency?.address) {
        toast.success(
          <SolanaToastDescriptionWithTx txHash={confirmed.signature} connected={connected}>
            {t("Transfer to")} {truncateHash(toPublicKey?.toBase58() ?? "")}
          </SolanaToastDescriptionWithTx>,
          toastOptionsSuccess,
        );
        callback();
      }
    } else {
      toast.error(t("Transfer failed: %message%", { message: t("Couldn't create transaction") }));
    }
  };

  return { pendingTx, sendToken, createNativeTransferTx, createTxToSend };
};
