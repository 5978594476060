import { MITEK_ERRORS } from "configs";

export const getBiometricErrorMessage = (keys?: string) => {
  const errorKey = keys ? keys.split(",")[0] : "DEFAULT";

  const errorMessage =
    errorKey in MITEK_ERRORS ? MITEK_ERRORS[errorKey as keyof typeof MITEK_ERRORS] : MITEK_ERRORS.DEFAULT;

  return errorMessage;
};
