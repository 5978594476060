import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "store/store";

import { setWidgetsBuyersListPayload } from "store/escrow/escrows-table";
import { getWidgetBuyersList } from "store/escrow/escrows-table/action";
import { selectCombinedData } from "./selectors";

export const useHandlePayload = () => {
  const { data, count, pending, page, size, skip, dateFrom, dateTo, status, id, search } =
    useAppSelector(selectCombinedData);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const data = {
      page,
      size,
      id,
      status: status ? status : undefined,
      search: search ? search : undefined,
      skip: skip ? skip : undefined,
      dateFrom: dateFrom ? dateFrom : undefined,
      dateTo: dateTo ? dateTo : undefined,
    };

    dispatch(getWidgetBuyersList(data));
  }, [page, size, status, search, skip, dateTo, dateFrom, id]);

  const handlePageClick = (selectedItem: { selected: number }) => {
    const currentPage = selectedItem.selected + 1;
    dispatch(setWidgetsBuyersListPayload({ page: currentPage }));
  };

  return { data, count, pending, page, size, handlePageClick };
};
