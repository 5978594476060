import React from "react";

import { useHandleData } from "./hooks";

import { Box } from "components";
import { DotsIcon } from "components/svg";

import { AddressCardProps } from "components/address-сard/types";

const AddressPopUp: React.FC<AddressCardProps> = ({ address, rootId }) => {
  const { targetRefPopUp, popUpTooltip } = useHandleData({ address, rootId });

  return (
    <>
      <Box ref={targetRefPopUp} cursor="pointer" width="24px" height="24px">
        <DotsIcon />
      </Box>
      {popUpTooltip}
    </>
  );
};

export default AddressPopUp;
