import { useEffect, useState } from "react";
import moment from "moment";

import { useAppDispatch, useAppSelector } from "store/store";

import { PayloadObjectType, TagsFilterProps } from "../types";

import { setWidgetsBuyersListPayload } from "store/escrow/escrows-table";

export const useHandleFiltersTags = () => {
  const status = useAppSelector(state => state.escrowsTable.widgetBuyersListPayload.status);
  const dateFrom = useAppSelector(state => state.escrowsTable.widgetBuyersListPayload.dateFrom);
  const dateTo = useAppSelector(state => state.escrowsTable.widgetBuyersListPayload.dateTo);

  const [tags, setTags] = useState<TagsFilterProps[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const dateFormatValue =
      dateFrom && dateTo ? `${moment(dateFrom).format("MM/DD/YY")} - ${moment(dateTo).format("MM/DD/YY")}` : undefined;
    const payloadObj: PayloadObjectType = {
      status: {
        name: status,
        removeHandler: () => {
          dispatch(setWidgetsBuyersListPayload({ status: undefined }));
        },
      },
      date: {
        name: dateFormatValue,
        removeHandler: () => {
          dispatch(setWidgetsBuyersListPayload({ dateFrom: undefined, dateTo: undefined }));
        },
      },
    };

    if (!status) {
      delete payloadObj.status;
    }

    if (!dateFormatValue) {
      delete payloadObj.date;
    }

    const filtersTagsArray = Object.values(payloadObj);

    setTags(filtersTagsArray);
  }, [status, dateFrom, dateTo]);

  return { tags };
};
