import { useState } from "react";
import { toast } from "react-toastify";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";

import { toastOptionsError, toastOptionsSuccess } from "components/alerts";
import { useTranslation } from "context";

import { initialValues } from "./use-validation-schema";

import { isErrorResult } from "services";

import { UseHandleDataArgs } from "../types";
import { PingOneRisk, UserTwoFaStatusEnum } from "store/profile/types";

export const useHandleData = <T, P, R>({
  setFieldValue,
  setFieldError,
  callbackForAction,
  callbackForResend,
  callbackForSuccess,
  propsForAction,
  propsForResend,
}: UseHandleDataArgs<T, P, R>) => {
  const user = useAppSelector(state => state.profile.user);

  const [isTimerExpired, setTimerExpired] = useState(false);
  const [timer, setTimer] = useState(getFiveMinutesSeconds());
  const [isPending, setIsPending] = useState(false);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const submitHandler = async (formValues: typeof initialValues) => {
    setIsPending(true);
    let otpData = propsForAction;
    if (user?.twoFaStatus !== UserTwoFaStatusEnum.ABSENT && user?.riskLevel === PingOneRisk.high) {
      otpData = { ...otpData, authenticatorOtp: formValues.authenticatorOtp };
    }

    const data = {
      ...otpData,
      otps: { emailOtp: formValues.emailOtp, authenticatorOtp: formValues.authenticatorOtp },
    };

    dispatch(callbackForAction(data)).then(async res => {
      if (!isErrorResult(res.payload)) {
        callbackForSuccess(res.payload);
      } else {
        const error = res.payload!;

        if (error.message.toLowerCase().includes("email")) {
          await setFieldValue("emailOtp", "");
          setFieldError("emailOtp", error.message);
        } else if (error.message.toLowerCase().includes("authenticator")) {
          await setFieldValue("authenticatorOtp", "");
          setFieldError("authenticatorOtp", error.message);
        } else {
          toast.error(error.message, toastOptionsError);
        }
      }
      setIsPending(false);
    });
  };

  function getFiveMinutesSeconds() {
    const secondsInFiveMinutes = 300;
    return (new Date().getTime() + secondsInFiveMinutes * 1000) / 1000;
  }

  const onAuthenticatorOtpInput = async (value: string) => {
    await setFieldValue("authenticatorOtp", value);
  };

  const onEmailOtpInput = async (value: string) => {
    await setFieldValue("emailOtp", value);
  };

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const onResendClick = () => {
    if (isTimerExpired) {
      setIsPending(true);
      dispatch(callbackForResend({ ...propsForResend })).then(res => {
        if (!isErrorResult(res.payload)) {
          setTimer(getFiveMinutesSeconds());
          setTimerExpired(false);
          toast.success(t("New OTP code was send to your email"), toastOptionsSuccess);
        }
        setIsPending(false);
      });
    }
  };

  const onTimerReachZero = async () => {
    await setFieldValue("emailOtp", "");
    setFieldError("emailOtp", t("Email OTP code is expired"));
    setTimerExpired(true);
  };

  return {
    isTimerExpired,
    timer,
    isPending,
    submitHandler,
    onAuthenticatorOtpInput,
    onEmailOtpInput,
    closeModalHandler,
    onResendClick,
    onTimerReachZero,
  };
};
