import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ColdWalletHistoryPayload, HistoryPayload, HistoryResponseSingleItem, WalletsHistoryState } from "./types";

import { getWalletsHistory, getColdWalletsHistory } from "./actions";

const initialState: WalletsHistoryState = {
  error: null,
  historyInfo: {
    pending: false,
    data: [],
    count: 0,
  },
  historyPayload: {
    page: 1,
    size: 10,
  },
  coldWalletHistoryInfo: {
    pending: false,
    data: [],
    count: 0,
  },
  coldWalletHistoryPayload: {
    page: 1,
    size: 10,
  },
};

const walletsHistorySlice = createSlice({
  name: "walletsHistory",
  initialState: {
    ...initialState,
  },
  reducers: {
    setWalletsHistoryPayload: (state, action: PayloadAction<Partial<HistoryPayload>>) => {
      state.historyPayload = { ...state.historyPayload, ...action.payload };
    },

    setColdWalletsHistoryPayload: (state, action: PayloadAction<Partial<ColdWalletHistoryPayload>>) => {
      state.coldWalletHistoryPayload = { ...state.coldWalletHistoryPayload, ...action.payload };
    },

    resetWalletsHistory: () => {
      return initialState;
    },
    resetHotWalletHistoryPayload: state => {
      state.historyPayload = initialState.historyPayload;
    },
    updateHistory: (state, action: PayloadAction<HistoryResponseSingleItem>) => {
      const newColdWalletHistoryData = state.coldWalletHistoryInfo.data.map(historyItem => {
        if (historyItem.id === action.payload.id) {
          return action.payload;
        }
        return historyItem;
      });
      const newHotWalletHistoryData = state.historyInfo.data.map(historyItem => {
        if (historyItem.id === action.payload.id) {
          return action.payload;
        }
        return historyItem;
      });

      state.coldWalletHistoryInfo = { ...state.coldWalletHistoryInfo, data: newColdWalletHistoryData };
      state.historyInfo = { ...state.historyInfo, data: newHotWalletHistoryData };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getWalletsHistory.fulfilled, (state, action) => {
        state.historyInfo = { ...action.payload, pending: false };
        state.error = null;
      })
      .addCase(getWalletsHistory.pending, state => {
        state.historyInfo.pending = true;
      })
      .addCase(getWalletsHistory.rejected, (state, action) => {
        if (action.payload) {
          state.historyInfo = initialState.historyInfo;
          state.error = action.payload;
        }
      })
      .addCase(getColdWalletsHistory.fulfilled, (state, action) => {
        state.coldWalletHistoryInfo = { ...action.payload, pending: false };
        state.error = null;
      })
      .addCase(getColdWalletsHistory.pending, state => {
        state.coldWalletHistoryInfo.pending = true;
      })
      .addCase(getColdWalletsHistory.rejected, (state, action) => {
        if (action.payload) {
          state.coldWalletHistoryInfo = initialState.coldWalletHistoryInfo;
          state.error = action.payload;
        }
      });
  },
});

export const {
  resetWalletsHistory,
  setWalletsHistoryPayload,
  setColdWalletsHistoryPayload,
  resetHotWalletHistoryPayload,
  updateHistory,
} = walletsHistorySlice.actions;
export default walletsHistorySlice;
