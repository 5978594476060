import { ChangeEvent, RefObject } from "react";
import lookup from "country-code-lookup";
import { isErrorResult } from "services";
import { useAppDispatch } from "store/store";
import { createAddress, getAddressesList } from "store/addresses/actions";
import { HandleAddressProps } from "./types";
import { InitialValues } from "./types";

export const useHandleAddress = ({ values, setFieldValue, setFieldTouched, saveCallBack }: HandleAddressProps) => {
  const dispatch = useAppDispatch();

  const handleScriptLoad = (
    autoCompleteRef: RefObject<HTMLInputElement>,
    autoComplete: Window,
    type: string,
    field: string,
  ) => {
    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, { types: [type] });
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () => handlePlaceSelect(autoComplete, type, field));
  };

  const handlePlaceSelect = async (autoComplete: Window, type: string, field: string) => {
    const addressObject = autoComplete.getPlace();

    if (addressObject && addressObject.address_components) {
      const val = addressObject.address_components.find(item => {
        return item.types.includes(type);
      });

      if (field === "street") {
        const street = addressObject.address_components.find(item => {
          return item.types.includes("route");
        });
        const streetNumber = addressObject.address_components.find(item => {
          return item.types.includes("street_number");
        });
        const postCode = addressObject.address_components.find(item => {
          return item.types.includes("postal_code");
        });

        await setFieldValue("street", `${street?.short_name ?? ""} ${streetNumber?.short_name ?? ""}`);
        await setFieldValue("postCode", postCode?.long_name ?? "");
      } else if (field === "country") {
        const country = addressObject.address_components.find(item => {
          return item.short_name;
        });
        const countryFormatted = lookup.byIso(country?.short_name || addressObject.formatted_address);

        await setFieldValue("country", addressObject.formatted_address);
        await setFieldValue("countryCode", countryFormatted ? countryFormatted.iso2 : "");
      } else {
        await setFieldValue(field, val?.long_name ?? "");
      }
    } else {
      await setFieldTouched("country", true);
    }
  };

  const saveHandler = (values: InitialValues) => {
    dispatch(createAddress(values)).then(res => {
      if (!isErrorResult(res.payload)) {
        dispatch(getAddressesList());
        saveCallBack?.(res.payload);
      }
    });
  };

  const setDefaultAddress = async () => {
    await setFieldValue("isDefault", !values.isDefault);
    await setFieldTouched("isDefault", true);
  };

  const setCountryCodeOnBlur = async (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const country = lookup.byCountry(val);

    await setFieldValue("countryCode", country ? country.iso2 : "");
  };

  return { handleScriptLoad, saveHandler, setDefaultAddress, setCountryCodeOnBlur };
};
