export const TABS = {
  auth: [
    { title: "Personal account", disableIfBlacklisted: false },
    { title: "Business account", disableIfBlacklisted: false },
  ],
  swap: [
    { title: "Swap", disableIfBlacklisted: true },
    { title: "History", disableIfBlacklisted: false },
  ],
  coldStorage: [
    { title: "Overview", disableIfBlacklisted: false },
    { title: "Create vault", disableIfBlacklisted: true },
    { title: "History", disableIfBlacklisted: false },
  ],
  buy: [
    { title: "Buy", disableIfBlacklisted: true },
    { title: "History", disableIfBlacklisted: false },
  ],
  deposit: [
    { title: "Automatic", disableIfBlacklisted: true },
    { title: "Manual", disableIfBlacklisted: true },
  ],
  wallets: [
    { title: "Network", disableIfBlacklisted: false },
    { title: "Deposit", disableIfBlacklisted: true },
    { title: "Withdraw", disableIfBlacklisted: false },
    { title: "Whitelist", disableIfBlacklisted: false },
    { title: "History", disableIfBlacklisted: false },
  ],
  settings: [
    { title: "Profile", disableIfBlacklisted: false },
    { title: "System", disableIfBlacklisted: false },
    { title: "Security", disableIfBlacklisted: false },
  ],
  escrows: [
    { title: "My Escrows", disableIfBlacklisted: false },
    { title: "Widgets", disableIfBlacklisted: false },
  ],
  createEscrowRole: [
    //   if the user is blacklisted page createEscrow redirect him to the home page
    { title: "Buyer", disableIfBlacklisted: true },
    { title: "Seller", disableIfBlacklisted: true },
    { title: "Broker", disableIfBlacklisted: true },
  ],
  // user can see chats tabs, can switch chat tabs, but can`t write on chat
  messagesList: [
    { title: "Buyer", disableIfBlacklisted: false },
    { title: "Seller", disableIfBlacklisted: false },
  ],
  createEscrowCommissionPayer: [
    { title: "Buyer", disableIfBlacklisted: true },
    { title: "Seller", disableIfBlacklisted: true },
    { title: "50/50", disableIfBlacklisted: true },
  ],
};

export const tabsIndex = {
  auth: {
    personalAccount: 0,
    businessAccount: 1,
  },
  swap: {
    swap: 0,
    history: 1,
  },
  coldStorage: {
    overView: 0,
    createVault: 1,
    history: 2,
  },
  buyCrypto: {
    buy: 0,
    history: 1,
  },
  depositMethod: {
    automatic: 0,
    manual: 1,
  },
  wallets: {
    network: 0,
    deposit: 1,
    withdraw: 2,
    whitelist: 3,
    history: 4,
  },
  settings: {
    profile: 0,
    system: 1,
    security: 2,
  },
  escrows: {
    myEscrows: 0,
    widgets: 1,
  },
  createEscrow: {
    buyer: 0,
    seller: 1,
    broker: 2,
  },
  messagesList: {
    buyer: 0,
    seller: 1,
  },
  createEscrowCommissionPayer: {
    buyer: 0,
    seller: 1,
    inHalf: 2,
  },
};
