import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg viewBox="0 0 40 40" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 0C31.0469 0 40 8.95312 40 20C40 31.0469 31.0469 40 20 40C8.95312 40 0 31.0469 0 20C0 8.95312 8.95312 0 20 0Z"
        fill="url(#paint0_radial_3644_73851)"
      />
      <path
        d="M12.7109 15.4473C16.7344 11.5176 23.2656 11.5176 27.2891 15.4473L27.7734 15.9238C27.9766 16.1191 27.9766 16.4395 27.7734 16.6348L26.1172 18.252C26.0156 18.3535 25.8516 18.3535 25.75 18.252L25.0859 17.6035C22.2734 14.8613 17.7266 14.8613 14.9141 17.6035L14.2031 18.2988C14.1016 18.4004 13.9375 18.4004 13.8359 18.2988L12.1797 16.6816C11.9766 16.4863 11.9766 16.166 12.1797 15.9707L12.7109 15.4473ZM30.7188 18.791L32.1953 20.2285C32.3984 20.4238 32.3984 20.7441 32.1953 20.9395L25.5469 27.4316C25.3438 27.627 25.0156 27.627 24.8203 27.4316L20.1016 22.8223C20.0547 22.7754 19.9688 22.7754 19.9219 22.8223L15.2031 27.4316C15 27.627 14.6719 27.627 14.4766 27.4316L7.80469 20.9395C7.60156 20.7441 7.60156 20.4238 7.80469 20.2285L9.28125 18.791C9.48437 18.5957 9.8125 18.5957 10.0078 18.791L14.7266 23.4004C14.7734 23.4473 14.8594 23.4473 14.9062 23.4004L19.625 18.791C19.8281 18.5957 20.1562 18.5957 20.3516 18.791L25.0703 23.4004C25.1172 23.4473 25.2031 23.4473 25.25 23.4004L29.9688 18.791C30.1875 18.5957 30.5156 18.5957 30.7188 18.791Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_3644_73851"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0.000123978 20.0006) scale(40)"
        >
          <stop stopColor="#5D9DF6" />
          <stop offset="1" stopColor="#006FFF" />
        </radialGradient>
      </defs>
    </Svg>
  );
};

export default Icon;
