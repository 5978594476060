import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => state.createStatement.transactionPayload.size,
    (state: AppState) => state.createStatement.transactionPayload.page,
    (state: AppState) => state.createStatement.transactionInfo.data,
    (state: AppState) => state.createStatement.transactionInfo.count,
    (state: AppState) => state.createStatement.transactionInfo.pending,
    (state: AppState) => state.createStatement.date.dateRange,
  ],
  (size, page, data, count, pending, dateRange) => ({
    size,
    page,
    data,
    count,
    pending,
    dateRange,
  }),
);
