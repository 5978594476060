import React from "react";

import { useShowModals } from "./hooks";
import { useTranslation } from "context";

import { hideModal } from "store/modal";
import { useAppDispatch } from "store/store";

import CloseModalWrapper from "../close-modal-wrapper";
import { StyledModalWrapper } from "../styled";
import { TwoFactorSetup } from "components";

const TwoFactorSetupModal: React.FC = () => {
  const { t } = useTranslation();
  const { callSuccessModal } = useShowModals();
  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} />
      <TwoFactorSetup
        title={t("Set up new authenticator app")}
        text={t("Please scan this QR code with your Authenticator app.")}
        successCallback={callSuccessModal}
        showErrorToaster
      />
    </StyledModalWrapper>
  );
};

export default TwoFactorSetupModal;
