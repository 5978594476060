import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { useKycHandler, useTooltip, useTwoFaLastUpdateCheck } from "hooks";
import { useShowModals } from "./hooks";
import { useGetEscrowParticipants } from "../../hooks";
import { useTranslation } from "context";
import { scrollToTop } from "utils/helpers";
import { isErrorResult } from "services";

import { Button, Column, FlexGap, Text } from "components";
import { DeliveryAddress, Parties } from "./components";

import { useAppDispatch, useAppSelector } from "store/store";
import { acceptEscrow } from "store/escrow/escrow-process/actions";

import { Roles } from "pages/main/create-escrow/types";
import { EscrowStatusEnum } from "store/escrow/enums";

import { ROUTES } from "navigation/routes";

const EscrowDetails: React.FC = () => {
  const currentEscrow = useAppSelector(state => state.escrowProcess.currentEscrow);
  const pending = useAppSelector(state => state.escrowProcess.pending);
  const currentStepNumber = useAppSelector(state => state.escrowProcess.currentStepNumber);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { callbackWithKycCheck } = useKycHandler({ shouldCheckStatus: false });
  const { broker, userRole } = useGetEscrowParticipants();
  const { modify, cancelHandler } = useShowModals();
  const { callbackWithTwoFaLastUpdateCheck } = useTwoFaLastUpdateCheck({ rootId: "escrow-process" });

  const { targetRef: targetRefId, tooltip: idTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const accept = () => {
    if (currentEscrow) {
      dispatch(acceptEscrow(currentEscrow.body.id)).then(res => {
        if (!isErrorResult(res.payload)) {
          scrollToTop();
        }
      });
    }
  };

  const acceptHandler = () => {
    callbackWithKycCheck(() => callbackWithTwoFaLastUpdateCheck(accept));
  };

  const modifyHandler = () => {
    callbackWithKycCheck(() => callbackWithTwoFaLastUpdateCheck(modify));
  };

  return (
    <Column height="100%">
      <Text
        textScale={currentStepNumber === 1 || currentStepNumber === 2 ? "body3" : "body2"}
        color="neutral600"
        mb="18px"
        $fontWeight="medium"
      >
        {t("Escrow Details")}
      </Text>

      <FlexGap gap="32px 40px" mb="32px" flexWrap="wrap">
        <FlexGap gap="16px" alignItems="center">
          <Text textScale="hairlineS" color="neutral500" $fontWeight="medium" textTransform="uppercase">
            {t("Date")}:
          </Text>

          <Text textScale="caption1" color="neutral800">
            {currentEscrow ? moment(new Date(currentEscrow.body.createdAt)).format("MM/DD/YY") : ""}
          </Text>
        </FlexGap>

        <FlexGap gap="16px" alignItems="center" width="100%">
          <Text
            textScale="hairlineS"
            color="neutral500"
            $fontWeight="medium"
            textTransform="uppercase"
            whiteSpace="nowrap"
          >
            {t("Escrow ID:")}
          </Text>

          <Text textScale="caption1" color="neutral800" ellipsis ref={targetRefId}>
            {currentEscrow?.body.id}
          </Text>
          {idTooltip}
        </FlexGap>
      </FlexGap>

      {currentEscrow?.body.products[0].description && (
        <Column mb="32px">
          <Text textScale="hairlineS" color="neutral500" $fontWeight="medium" textTransform="uppercase" mb="14px">
            {t("Description")}
          </Text>

          <Text textScale="caption1" color="neutral800" wordBreak="break-word">
            {currentEscrow?.body.products[0].description}
          </Text>
        </Column>
      )}

      <Parties />

      {currentEscrow?.body.products[0].url && (
        <Column mb="32px">
          <Text textScale="hairlineS" color="neutral500" textTransform="uppercase" $fontWeight="medium" mb="14px">
            {t("Website URL")}
          </Text>

          <Text textScale="caption1" color="neutral800" wordBreak="break-all">
            {currentEscrow?.body.products[0].url}
          </Text>
        </Column>
      )}

      {currentEscrow?.isShippable && <DeliveryAddress />}

      <FlexGap
        gap="14px"
        mt="auto"
        justifyContent={{ _: "center", tablet: "end" }}
        flexDirection={{ _: "column", tablet: "row" }}
        alignItems="center"
      >
        <Button
          onClick={() => {
            navigate(`/${ROUTES.escrows}`);
          }}
          width="100%"
          maxWidth={{ _: "100%", tablet: "184px" }}
          variant="outline"
          isLoading={pending}
        >
          {t("Back")}
        </Button>

        {currentStepNumber === 1 && currentEscrow?.status !== EscrowStatusEnum.CANCELED && (
          <>
            <Button
              onClick={() => {
                callbackWithKycCheck(cancelHandler);
              }}
              isLoading={pending}
              variant="outline"
              width="100%"
              maxWidth={{ _: "100%", tablet: "184px" }}
            >
              {t("Cancel")}
            </Button>

            {currentEscrow?.body.head.isWidget ||
            (broker && (userRole === Roles.seller || userRole === Roles.buyer)) ? null : (
              <Button
                onClick={modifyHandler}
                isLoading={pending}
                variant="outline"
                width="100%"
                maxWidth={{ _: "100%", tablet: "184px" }}
              >
                {t("Modify")}
              </Button>
            )}

            {userRole && userRole !== Roles.broker && !currentEscrow?.body.isAcceptedBy[userRole] && (
              <Button
                isLoading={pending}
                onClick={acceptHandler}
                width="100%"
                maxWidth={{ _: "100%", tablet: "184px" }}
                disabled={currentEscrow?.isShippable && !currentEscrow?.body.delivery.address}
              >
                {t("Accept")}
              </Button>
            )}
          </>
        )}
      </FlexGap>
    </Column>
  );
};

export default EscrowDetails;
