import React from "react";
import { useLocation, useParams } from "react-router-dom";

import { Breadcrumbs, Link, Text } from "components";

import { useTranslation } from "context";

import { useAppSelector } from "store/store";

import { BREADCRUMBS_NAME } from "./constants";

const StatementsBreadCrumbs: React.FC = () => {
  const statementItem = useAppSelector(state => state.statementInfo.statementItem);

  const { t } = useTranslation();

  const { pathname } = useLocation();
  const { statementId } = useParams();

  const validRouterKeys = pathname.split("/").filter(item => !!item);

  const getRoutePath = (index: number) => {
    const routePath = validRouterKeys.slice(0, index + 1).join("/");
    return "/" + routePath;
  };

  return (
    <Breadcrumbs mb="18px">
      {validRouterKeys.map((routerKey, index) => {
        const breadcrumbName =
          statementId === routerKey
            ? statementItem?.name ?? ""
            : t(BREADCRUMBS_NAME[routerKey as keyof typeof BREADCRUMBS_NAME]);

        return (
          <Link key={routerKey} disabled={index === validRouterKeys.length} mx="2px" href={getRoutePath(index)}>
            <Text maxWidth="200px" textScale="caption1" color="neutral500" ellipsis>
              {breadcrumbName}
            </Text>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default StatementsBreadCrumbs;
