import { ErrorResult } from "services/types";
import { EscrowRolesEnum, EscrowStatusEnum } from "store/escrow/enums";
import { DefaultPayloadList, DefaultResponseList } from "types";
import { OrderByEnum } from "types/responses";

export type MessagesState = {
  activeTab: number;
  error: ErrorResult | null;
  chatsListData: ChatsListType;
  chatInfo: CurrentChatInfoResponse;
  chatMessagesListData: ChatMessagesListType;
  buyerChatsListPayload: ChatListPayload;
  sellerChatsListPayload: ChatListPayload;
  currentChatRoomPayload: CurrentChatRoomPayload;
  chatMessagesIdArray: number[];
  chatListRole: ChatRoles;
};

export interface ChatListPayload extends DefaultPayloadList {
  role?: ChatRoles;
  orderBy?: OrderByEnum;
}

export interface CurrentChatRoomPayload extends DefaultPayloadList, ChatInfoPayload {}

export interface ChatInfoPayload {
  reference?: string;
}

export interface SendMessagePayload {
  reference: string;
  createdAt: string;
  updatedAt: string;
  status: ChatMessageStatusEnum;
  body: string;
  userId: number;
  currentMessageId?: number;
  id?: number;
  pending?: boolean;
}

export interface SetNewMessagePayload extends ChatInfoPayload {
  newMessage: SendMessagePayload;
}

export interface SetCurrentChatInfoPayload extends ChatInfoPayload {
  chat: ChatInfoResponse;
  activeTabForRole?: number;
}

export interface SetStatusToMessagePayload {
  chatReference: string;
  currentMessageId?: number;
  isSendSuccess?: boolean;
  isMessagesRead?: boolean;
  isDeliveredMessageSuccessfully?: boolean;
  pending?: boolean;
}

export type ChatsListType = {
  buyer: ChatsListResponse;
  seller: ChatsListResponse;
};

export interface ChatsListResponse extends DefaultResponseList<ChatInfoResponse> {}

export interface ChatMessagesListResponse extends DefaultResponseList<ChatMessagesType> {}

export interface CurrentChatInfoResponse {
  pending: boolean;
  chatByReference: { [key: string]: ChatInfoResponse };
}

export interface ReceivedMessagesResponse {
  chat: ChatInfoResponse;
  message: ChatMessagesType;
}

export interface ViewedMessagesResponse {
  chat: ChatInfoResponse;
  messages: ChatMessagesType[];
}

export interface ChatInfoResponse {
  id: number;
  escrowStatus: EscrowStatusEnum | null;
  createdAt: string;
  updatedAt: string;
  reference: string;
  name: string;
  participants: ChatParticipantType[];
  messages: ChatMessagesType[];
  lastMessage: ChatMessagesType;
  status: ChatStatusEnum;
  isUnread: boolean;
}

export type ChatParticipantType = {
  id: number;
  createdAt: string;
  updatedAt: string;
  chatId: number;
  userId: number;
  lastRead: number;
  role: ChatRoles;
  userData: {
    firstName: string;
    lastName: string;
  };
};

export type ChatMessagesListType = {
  [key: string]: { data: (ChatMessagesType | SendMessagePayload)[]; count: number };
};

export type ChatMessagesType = {
  id: number;
  createdAt: string;
  updatedAt: string;
  userId: number;
  body: string;
  chatId: number;
  status: ChatMessageStatusEnum;
  currentMessageId?: number;
  pending?: boolean;
};

export type ChatReadMessageUpdateType = {
  chatReference: string;
  messageId: number;
  userId: number;
};

export enum ChatRoles {
  buyer = EscrowRolesEnum.BUYER,
  seller = EscrowRolesEnum.SELLER,
}

export enum ChatMessageStatusEnum {
  read = "read",
  unread = "unread",
}

export enum ChatStatusEnum {
  ACTIVE = "active",
  ARCHIVED = "archived",
}
