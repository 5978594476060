import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

export const StyledBox = styled.div<SpaceProps>`
  height: 120px;
  width: 100%;
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.colors.neutral300};
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  ${({ theme }) => theme.mediaQueries.tablet} {
    border: none;
    padding: 0;
    width: 45%;
    height: max-content;
  }
  ${space}
`;

export const StyledIconBox = styled.div`
  position: absolute;
  left: 47%;
  padding: 5px;
  width: 36px;
  height: 36px;
  border: ${({ theme }) => `1px solid ${theme.colors.neutral300}`};
  background-color: ${({ theme }) => theme.colors.neutral0};
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  transform: rotate(90deg);
`;
