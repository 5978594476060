import React from "react";

import { useThemeContext } from "context";

import { Svg } from "components";

import { SvgProps } from "components/svg/types";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg width="72" viewBox="0 0 72 84" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_13429_22116)">
        <rect x="18" y="49" width="36" height="17" rx="4" fill={theme.colors.yellow} />
      </g>
      <circle cx="36" cy="36" r="28" fill={theme.colors.yellowLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.yellow} strokeOpacity="0.5" />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.yellow} strokeWidth="0.5" />
      <path
        d="M33.2006 35.798C33.2824 35.8798 33.3069 36.0028 33.2626 36.1097C33.2183 36.2166 33.1141 36.2862 32.9984 36.2862C32.8827 36.2862 32.7784 36.2166 32.7341 36.1097C32.6898 36.0028 32.7143 35.8798 32.7961 35.798C32.9079 35.6865 33.0888 35.6865 33.2006 35.798"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.4988 43.5023H23.9944C23.1984 43.5026 22.4349 43.1864 21.872 42.6235C21.3091 42.0606 20.9929 41.2971 20.9932 40.5011V31.4973C20.9929 30.7013 21.3091 29.9378 21.872 29.3749C22.4349 28.812 23.1984 28.4959 23.9944 28.4961H34.4988"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1987 35.798C27.2805 35.8798 27.3049 36.0028 27.2607 36.1097C27.2164 36.2166 27.1121 36.2862 26.9964 36.2862C26.8807 36.2862 26.7764 36.2166 26.7321 36.1097C26.6879 36.0028 26.7123 35.8798 26.7942 35.798C26.906 35.6865 27.0868 35.6865 27.1987 35.798"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="39.001"
        y="33.748"
        width="12.005"
        height="9.75406"
        rx="1.65"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.2959 33.7489V30.7035C41.2959 28.656 42.9558 26.9961 45.0033 26.9961V26.9961C45.9866 26.9961 46.9296 27.3867 47.6249 28.082C48.3202 28.7773 48.7108 29.7203 48.7108 30.7035V30.7035V33.7743"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_f_13429_22116"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_13429_22116" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
