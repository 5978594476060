import React from "react";

import { Flex, Text } from "components";

import { useTranslation } from "context";

const EmptyWallets: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex width="100%" height="100%" justifyContent="center" alignItems="center" m="auto">
      <Text textScale="body1" $fontWeight="medium" textAlign="center">
        {t("Oops, it looks like you haven't created any wallets in our system")}
      </Text>
    </Flex>
  );
};

export default EmptyWallets;
