import styled from "styled-components";
import { space, typography, TypographyProps, SpaceProps, layout, LayoutProps } from "styled-system";
import { Text } from "components";

interface TdProps extends TypographyProps, SpaceProps, LayoutProps {}

interface TrProps extends LayoutProps {
  hover?: boolean;
}

export const Tr = styled.tr<TrProps>`
  &:hover {
    background-color: ${({ theme, hover }) => hover && theme.colors.grey1};
    cursor: ${({ hover }) => hover && "url('/images/cursor.svg') 0 0, auto"};
  }

  ${layout}
`;

export const Td = styled.td<TdProps>`
  color: ${({ theme }) => theme.colors.neutral500};
  padding: 12px;
  vertical-align: middle;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.neutral200};
  }

  &:nth-child(1) {
    padding-inline-start: 24px;
    &:after {
      inset-inline-start: 24px;
    }
  }
  &:nth-last-child(1) {
    padding-inline-end: 24px;
    &:after {
      inset-inline-end: 24px;
    }
  }

  ${typography}
  ${space}
  ${layout}
`;

export const Th = styled(Text).attrs({ as: "th", textScale: "caption2", fontWeight: "600" })<TdProps>`
  color: ${({ theme }) => theme.colors.neutral500};
  padding: 12px;
  text-align: start;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.neutral200};
  }

  &:nth-child(1) {
    padding-inline-start: 24px;
    &:after {
      inset-inline-start: 24px;
    }
  }
  &:nth-last-child(1) {
    padding-inline-end: 24px;
    &:after {
      inset-inline-end: 24px;
    }
  }

  ${typography}
  ${space}
`;
