import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "navigation/routes";

import { Flex, Heading, Text, Button, FlexGap, Container } from "components";

import { ErrorBoundaryFallbackProps } from "./types";

const ErrorBoundaryFallback: React.FC<ErrorBoundaryFallbackProps> = ({ error, resetError }) => {
  const navigate = useNavigate();

  const onPressHandler = () => {
    resetError();
    navigate(ROUTES.home);
  };

  const reloadPage = () => {
    resetError();
    window.location.reload();
  };

  return (
    <Container>
      <Flex justifyContent="center" alignItems="center" flexDirection="column" height="100vh">
        <Heading as="h2" scale="h2">
          Something went wrong!
        </Heading>
        <Text my="12px" textScale="body3" textAlign="center">
          {error?.toString()}
        </Text>

        <FlexGap width="100%" gap="8px" my="8px" justifyContent="center" flexWrap="wrap">
          <Button minWidth="200px" onClick={onPressHandler}>
            Go to main page
          </Button>

          <Button minWidth="200px" onClick={reloadPage}>
            Reload page
          </Button>
        </FlexGap>
      </Flex>
    </Container>
  );
};

export default ErrorBoundaryFallback;
