import React, { cloneElement } from "react";

import { InputError, InputIcon, StyledInputGroup } from "./styled";
import { Box, Text, Flex } from "components";

import { InputGroupProps } from "./types";
import { scales as inputScales } from "components/inputs/input/types";

export const InputGroup: React.FC<InputGroupProps> = ({
  scale = inputScales.MD,
  startIcon,
  endIcon,
  children,
  error,
  label,
  isTouched,
  isShowError = true,
  endLabelText,
  ...props
}) => (
  <StyledInputGroup scale={scale} width="100%" hasStartIcon={!!startIcon} hasEndIcon={!!endIcon} {...props}>
    {label || endLabelText ? (
      <Flex justifyContent={label ? "space-between" : "flex-end"}>
        {label && (
          <Text textScale="caption1" color="neutral600" mb="8px">
            {label}
          </Text>
        )}

        {endLabelText && (
          <Text textScale="caption1" color="neutral600" mb="8px">
            {endLabelText}
          </Text>
        )}
      </Flex>
    ) : null}

    <Box position="relative">
      {startIcon && <InputIcon scale={scale}>{startIcon}</InputIcon>}

      {cloneElement(children, { scale, error, isTouched })}

      {endIcon && (
        <InputIcon scale={scale} isEndIcon>
          {endIcon}
        </InputIcon>
      )}
    </Box>

    {isShowError && <InputError textScale="caption2">{error && isTouched ? error : " "}</InputError>}
  </StyledInputGroup>
);
