import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral600", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" width="24px" {...props} color="transparent">
      <path
        d="M6.99825 21.0017H4.99742C3.89239 21.0017 2.99658 20.1059 2.99658 19.0008V17"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0024 2.99219H19.0033C20.1083 2.99219 21.0041 3.88799 21.0041 4.99302V6.99385"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.99658 6.99385V4.99302C2.99658 3.88799 3.89239 2.99219 4.99742 2.99219H6.99825"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0041 17V19.0008C21.0041 20.1059 20.1083 21.0017 19.0033 21.0017H17.0024"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12.0005" cy="12.0039" r="6" stroke={theme.colors[color]} strokeWidth="1.5" />
      <path
        d="M9.6655 10.0039V10.6709"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3344 10.0039V10.6709"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3341 13.7969C12.9967 14.9637 11.003 14.9637 9.66553 13.7969"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
