import { useEffect } from "react";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

import { useGetSearchParams } from "hooks";

import { SEARCH_PARAMS } from "navigation/search-params";
import { useAppDispatch } from "store/store";

export const useSetActiveTab = (tabs: { [key: string]: number }, setter: ActionCreatorWithPayload<number>) => {
  const {
    currentSearchParams: activeTabFromParams,
    setSearchParams,
    searchParams,
  } = useGetSearchParams(SEARCH_PARAMS.tab);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (activeTabFromParams) {
      if (
        Number.isInteger(+activeTabFromParams) &&
        +activeTabFromParams <= Object.keys(tabs).length &&
        +activeTabFromParams > 0
      ) {
        dispatch(setter(+activeTabFromParams - 1));
      } else {
        searchParams.set(SEARCH_PARAMS.tab, "1");
        setSearchParams(searchParams);
      }
    }
  }, []);
};
