import { LogoProps } from "./types";

const sizesLogo = {
  small: "width: 38px; height: 38px;",
  big: "width: 58px; height: 58px;",
};

const sizesLogoText = {
  default: "width: 0px; height: 0px; transition: all 0.3s ease;",
  small: "width: 70px; height: 19px; margin: 0px 12px; transition: all 0.3s ease;",
  big: "width: 104px; height: 28px; margin: 0px 18px; transition: all 0.3s ease;",
};

export const getSizeLogo = ({ size }: LogoProps) => {
  return sizesLogo[size];
};

export const getSizeLogoText = ({ size, isShowLogoText }: LogoProps) => {
  if (!isShowLogoText) {
    return sizesLogoText.default;
  }

  return sizesLogoText[size];
};
