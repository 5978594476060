import React, { PropsWithChildren } from "react";

import { Box } from "components";
import BackButton from "../back-button";

const WrapperWithBackButton: React.FC<PropsWithChildren<{ id?: string; onBackButtonClick?: () => void }>> = ({
  children,
  id,
  onBackButtonClick,
}) => {
  return (
    <Box id={id} mt={{ _: "28px", laptop: "160px" }}>
      <BackButton onBackButtonClick={onBackButtonClick} />
      {children}
    </Box>
  );
};

export default WrapperWithBackButton;
