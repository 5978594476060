import React, { useState } from "react";
import { DateRange, DayPicker } from "react-day-picker";
import moment from "moment";

import "react-day-picker/src/style.css";

import { useTranslation } from "context";
import { useRangeDayPickerHandlers } from "./hooks";

import { StyledRangeDayPicker, StyledRangeDayPickerInputMask } from "../styled";
import { Button, Box, Flex, FlexGap } from "components";
import { ArrowRightIcon } from "components/svg";

import { RangeDatePickerProps } from "./types";

export const RangeDayPicker: React.FC<RangeDatePickerProps> = ({
  range,
  numberOfMonth = 2,
  setDoneClick,
  setCloseClick,
  isSideMenuVersion = false,
}) => {
  const { t } = useTranslation();

  const defaultRangeFrom = range.from ? moment(range.from, "MM/DD/YY", true).toDate() : undefined;
  const defaultRangeTo = range.to ? moment(range.to, "MM/DD/YY", true).toDate() : undefined;

  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>({
    from: defaultRangeFrom,
    to: defaultRangeTo,
  });

  const [fromValue, setFromValue] = useState<string | undefined>(range.from);
  const [toValue, setToValue] = useState<string | undefined>(range.to);

  const { handleDoneClick, handleRangeSelect, handleDateChange } = useRangeDayPickerHandlers({
    selectedRange,
    setFromValue,
    setSelectedRange,
    setToValue,
    setDoneClick,
  });

  const today = new Date();

  const isRangeValid =
    moment(selectedRange?.from, "MM/DD/YY", true).isValid() && moment(selectedRange?.to, "MM/DD/YY", true).isValid();
  const defaultMonth = selectedRange && selectedRange.from ? new Date(selectedRange.from) : undefined;

  return (
    <StyledRangeDayPicker
      pt={isSideMenuVersion ? "0px" : "20px"}
      pb={isSideMenuVersion ? "0px" : "28px"}
      onClick={e => e.stopPropagation()}
      isSideMenuVersion={isSideMenuVersion}
    >
      <Box
        mb={isSideMenuVersion ? "0px" : "28px"}
        px={isSideMenuVersion ? "0px" : "44px"}
        width={isSideMenuVersion ? "100%" : "unset"}
      >
        <DayPicker
          numberOfMonths={numberOfMonth}
          mode="range"
          selected={selectedRange}
          onSelect={handleRangeSelect}
          disabled={{ after: today }}
          defaultMonth={defaultMonth}
        />
      </Box>

      <Box pt="28px" borderTop="1px solid" borderColor="neutral200" width={isSideMenuVersion ? "100%" : "unset"}>
        <FlexGap
          width="100%"
          px={isSideMenuVersion ? "0px" : "44px"}
          flexDirection={isSideMenuVersion ? "column" : "row"}
          justifyContent="space-between"
          gap="16px"
        >
          <Flex width="100%" height="44px" justifyContent={isSideMenuVersion ? "center" : "stretch"}>
            <StyledRangeDayPickerInputMask
              mask="99/99/99"
              value={fromValue ?? ""}
              maskPlaceholder=""
              placeholder={t("MM/DD/YY")}
              onChange={e => handleDateChange({ value: e.target.value, rangeType: "from" })}
            />

            <ArrowRightIcon color="neutral500" width="18px" mx="12px" />

            <StyledRangeDayPickerInputMask
              mask="99/99/99"
              value={toValue ?? ""}
              maskPlaceholder=""
              placeholder={t("MM/DD/YY")}
              disabled={!selectedRange?.from}
              onChange={e => handleDateChange({ value: e.target.value, rangeType: "to" })}
            />
          </Flex>

          <FlexGap
            width="100%"
            justifyContent="end"
            alignItems="center"
            gap="10px"
            flexDirection={isSideMenuVersion ? "column" : "row"}
          >
            <Button
              scale="md"
              width={isSideMenuVersion ? "264px" : "100px"}
              height="44px"
              variant="outline"
              onClick={setCloseClick}
            >
              {t("Cancel")}
            </Button>

            <Button
              scale="md"
              disabled={!isRangeValid}
              width={isSideMenuVersion ? "264px" : "100px"}
              height="44px"
              onClick={handleDoneClick}
            >
              {t("Done")}
            </Button>
          </FlexGap>
        </FlexGap>
      </Box>
    </StyledRangeDayPicker>
  );
};
