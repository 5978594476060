import { useEffect, useState } from "react";

import { useScript } from "hooks";

import { SCRIPT_SRCS } from "configs";

export const usePingOneSdk = () => {
  const [pingOneResponse, setPingOneResponse] = useState("");
  const status = useScript(SCRIPT_SRCS.pingone, {
    removeOnUnmount: true,
  });

  useEffect(() => {
    if (status === "ready") {
      handlePingOneSdk();
    }
  }, [status]);

  const handlePingOneSdk = async () => {
    await initPingOneSdk();
    await getDataFromPingOneSdk();
  };

  const initPingOneSdk = async () => {
    try {
      await window?._pingOneSignals?.initSilent({
        envId: process.env.REACT_APP_PING_ONE_ENV,
      });
    } catch (error) {
      console.error("Error in initPingOneSdk:", error);
    }
  };

  const getDataFromPingOneSdk = async () => {
    try {
      const response = await window?._pingOneSignals?.getData();

      if (response) {
        setPingOneResponse(response);
      }
    } catch (error) {
      console.error("Error in getDataFromPingOneSdk:", error);
    }
  };

  return { pingOneResponse, status };
};
