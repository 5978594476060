import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 72 72" {...props} color={color}>
      <circle cx="36" cy="36" r="28" fill={theme.colors.primaryLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.primary} strokeOpacity="0.5" />
      <path
        d="M36 44.4297V49.4997"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.03 41.9688L26.46 45.5387"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.57 36H22.5"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.46 26.4609L30.03 30.0309"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36 27V22.5"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.1553 28.8459L45.5403 26.4609"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.5 36H47.25"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.5401 45.5381L44.7451 44.7431"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.primary} strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_1906_68534"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_1906_68534" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
