import { Column, Text } from "components";
import { PropsWithChildren } from "react";

const SectionHeder: React.FC<PropsWithChildren<{ title: string }>> = ({ title, children }) => {
  return (
    <Column width="100%" mt="24px">
      <Text textScale="body3" color="neutral600" mb="18px" $fontWeight="medium">
        {title}
      </Text>
      {children}
    </Column>
  );
};

export default SectionHeder;
