import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { createStatement, deleteStatement, getStatementTransaction } from "./actions";

import { CreateStatementState, DateInfo, DateRange, TransactionPayload } from "./types";

function getPreviousMonthDates() {
  const today = new Date();
  const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth.getTime() - 1);

  const dateFrom = `${lastDayOfPreviousMonth.getFullYear()}-${lastDayOfPreviousMonth.getMonth() + 1}-${1}`;
  const dateTo = `${lastDayOfPreviousMonth.getFullYear()}-${
    lastDayOfPreviousMonth.getMonth() + 1
  }-${lastDayOfPreviousMonth.getDate()}`;

  return {
    dateRange: { dateFrom, dateTo },
    month: `${lastDayOfPreviousMonth.getMonth()}`,
    year: `${lastDayOfPreviousMonth.getFullYear()}`,
  };
}

const initialState: CreateStatementState = {
  error: null,
  pending: false,
  statementName: "",
  date: getPreviousMonthDates(),
  transactionPayload: {
    page: 1,
    size: 10,
  },
  transactionInfo: {
    pending: false,
    data: [],
    count: 0,
  },
};

const createStatementSlice = createSlice({
  name: "createStatement",
  initialState: {
    ...initialState,
  },
  reducers: {
    resetCreateStatementsState: () => {
      return initialState;
    },

    setStatementName: (state, action: PayloadAction<string>) => {
      state.statementName = action.payload;
    },

    setDatePayload: (state, action: PayloadAction<{ key: keyof DateInfo; value: string | DateRange }>) => {
      const { key, value } = action.payload;
      state.date = { ...state.date, [key]: value };
    },

    setTransactionPayload: (state, action: PayloadAction<Partial<TransactionPayload>>) => {
      state.transactionPayload = { ...state.transactionPayload, ...action.payload };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createStatement.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(createStatement.pending, state => {
        state.pending = true;
      })
      .addCase(createStatement.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(getStatementTransaction.fulfilled, (state, action) => {
        state.transactionInfo = { ...action.payload, pending: false };
        state.error = null;
      })
      .addCase(getStatementTransaction.pending, state => {
        state.transactionInfo.pending = true;
      })
      .addCase(getStatementTransaction.rejected, (state, action) => {
        if (action.payload) {
          state.transactionInfo = initialState.transactionInfo;
          state.error = action.payload;
        }
      })
      .addCase(deleteStatement.fulfilled, state => {
        state.pending = false;
        state.error = null;
      })
      .addCase(deleteStatement.pending, state => {
        state.pending = true;
      })
      .addCase(deleteStatement.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      });
  },
});

export const { resetCreateStatementsState, setStatementName, setDatePayload, setTransactionPayload } =
  createStatementSlice.actions;

export default createStatementSlice;
