export const HEADERS = [
  {
    width: "64%",
    text: "",
  },
  {
    width: "36%",
    text: "",
  },
];
