import React from "react";

import { useThemeContext } from "context";

import { Svg } from "components";

import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        d="M8 11.6429L10.9091 14.5L16 9.5"
        fill="transparent"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
