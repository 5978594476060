import React from "react";
import styled from "styled-components";
import { layout, LayoutProps } from "styled-system";
import { Text } from "components";

export const StyledAddressCard = styled.div<
  {
    isDefault: boolean;
    cursor?: React.CSSProperties["cursor"];
  } & LayoutProps
>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme, isDefault }) => (isDefault ? theme.colors.green : theme.colors.neutral300)};
  width: 100%;
  min-height: 206px;
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  padding: 24px;
  cursor: ${({ cursor }) => cursor && cursor};

  ${layout}
`;

export const StyledAddressCardHeader = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral300};
  padding-bottom: 14px;
  justify-content: space-between;
`;

export const StyledAddressText = styled(Text)`
  word-break: break-word;
`;
