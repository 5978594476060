import React from "react";

import { useTranslation } from "context";
import { useAppDispatch, useAppSelector } from "store/store";
import { useValidationSchema } from "./hooks";
import { useForm } from "hooks";

import { StyledModalWrapper } from "../styled";
import CloseModalWrapper from "../close-modal-wrapper";
import { Box, Button, Input, InputGroup, Text } from "components";
import { ModalSuccessIcon, YourYubikeyRegisteredIcon } from "components/svg";
import { StyledFormContainer } from "./styled";

import { hideModal, showModal } from "store/modal";

import { ActionModalProps } from "../action-modal/types";
import { ModalProps } from "store/modal/types";
import { YubikeyNameRegisterModalProps } from "./types";
import { yubikeyRegisterName } from "store/yubikey/actions";
import { isErrorResult } from "services";

const YubikeyNameRegisterModal: React.FC = () => {
  const pending = useAppSelector(state => state.yubikey.pending);
  const data = useAppSelector(state => (state.modal.props as unknown as YubikeyNameRegisterModalProps).data);

  const { t } = useTranslation();

  const { validationSchema, initialValues } = useValidationSchema();
  const { errors, touched, isValid, fieldProps, handleSubmit } = useForm({
    initialValues,
    validationSchema,
    onSubmit(values) {
      const { name } = values;

      onSubmitName(name);
    },
  });

  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  // If you close this modal, server automatically set name to yubikey

  const onSubmitName = (name: string) => {
    const _showModal = showModal as ModalProps<ActionModalProps>;

    dispatch(yubikeyRegisterName({ name, data })).then(response => {
      if (!isErrorResult(response.payload)) {
        dispatch(
          _showModal({
            modalName: "actionModal",
            rootId: "settings",
            props: {
              icon: <ModalSuccessIcon height="72px" width="72px" />,
              title: t("Authentication method added!"),
              text: t(
                "Return to Security page to specify when it should be used. You can use it when signing in instead of password, to sign withdrawals from Cold Storage and to whitelist Hot Wallet addresses in Wallets.",
              ),
              btnArr: [
                {
                  buttonText: t("Done"),
                  callback: closeModalHandler,
                },
              ],
            },
          }),
        );
      }
    });
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} />

      <YourYubikeyRegisteredIcon width="72px" mt="14px" />

      <Box mb="24px">
        <Text textScale="body2" color="neutral600" textAlign="center" py="12px">
          {t("Your Yubikey registered")}
        </Text>

        <Text textScale="caption1" color="neutral600" pt="12px" textAlign="center">
          {t("Your Yubikey has been successfully registered! Please fill in the name for this device.")}
        </Text>
      </Box>

      <StyledFormContainer onSubmit={handleSubmit}>
        <Box width="100%" mb="20px">
          <InputGroup label={t("Device name")} error={errors.name} isTouched={touched.name}>
            <Input {...fieldProps("name")} placeholder={t("Type Your device name")} />
          </InputGroup>
        </Box>

        <Box width="100%" mb={{ _: "28px", tablet: "30px" }}>
          <Button width="100%" type="submit" disabled={!isValid} isLoading={pending}>
            {t("Next")}
          </Button>
        </Box>
      </StyledFormContainer>
    </StyledModalWrapper>
  );
};

export default YubikeyNameRegisterModal;
