import { PropsWithChildren } from "react";
import * as Sentry from "@sentry/react";

import { ErrorBoundaryFallback } from "components";

const SentryErrorBoundary: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      showDialog
      beforeCapture={scope => {
        scope.setLevel("fatal");
      }}
      fallback={({ error, resetError }) => <ErrorBoundaryFallback error={error} resetError={resetError} />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryErrorBoundary;
