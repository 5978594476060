import { toast } from "react-toastify";
import moment from "moment";

import { toastOptionsError, toastOptionsSuccess, ToastTransactionStatusWithLink } from "components";

import { resetStore } from "services/axios";

import { useTranslation } from "context";
import { useKycHandler } from "hooks";

import store, { useAppDispatch } from "store/store";
import { updateUser } from "store/profile";
import { updateKYCData } from "store/kyc";
import { updateRates } from "store/rates";
import { updateHotWallet } from "store/wallets/hot-wallets";
import { updateColdWallet } from "store/wallets/cold-wallets";
import { updateSwapHistory } from "store/swap";
import { updateBuyHistory } from "store/buy-crypto";
import { updateHistory } from "store/wallets/wallets-history";
import { addNewEscrow, updateEscrowList } from "store/escrow/escrows-table";
import { updateCurrentEscrow } from "store/escrow/escrow-process";
import { setStatusToMessage, updateCurrentChatInfo, updateMessagesList } from "store/messages";
import { addNewNotification, setUnreadNotificationCount } from "store/notifications";

import { walletsErrorMessageConfig } from "../constants";

import { ErrorResult } from "services/types";
import { MyKYCStatusResponse } from "store/kyc/types";
import { ProfileResponse } from "store/profile/types";
import { HistoryResponseSingleItem as SwapHistoryResponseSingleItem } from "store/swap/types";
import { HistoryResponseSingleItem as BuyHistoryResponseSingleItem } from "store/buy-crypto/types";
import { ExchangeRatesResponse } from "store/rates/types";
import { HistoryResponseSingleItem, UpdateWalletResponse } from "store/wallets/types";
import {
  ChatMessageStatusEnum,
  ChatReadMessageUpdateType,
  ChatRoles,
  ReceivedMessagesResponse,
} from "store/messages/types";
import { Transaction } from "store/wallets/cold-wallets/types";
import { MyEscrowsSingleItem } from "store/escrow/escrows-table/types";
import { TransactionOperationEnum } from "store/wallets/enums";
import { NotificationsListSingleItem } from "store/notifications/types";

export const useOnSocketEventHandlers = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openModal, getIsAllCompleted, getIsAllMatchStatus } = useKycHandler({ shouldCheckStatus: false });

  const onCompleteKycHandler = (response: MyKYCStatusResponse) => {
    dispatch(updateKYCData(response));

    const isAllCompleted = getIsAllCompleted(response);
    const isAllMatchStatus = getIsAllMatchStatus(response);

    if (isAllCompleted) {
      if (isAllMatchStatus) {
        return toast.success(t("KYC verification was successful! Now you can proceed."), toastOptionsSuccess);
      }

      if (!isAllMatchStatus) {
        toast.error(t("KYC verification failed! Please pass KYC again."), toastOptionsError);
        return openModal();
      }
    }
  };

  const onUpdateUserHandler = (user: ProfileResponse) => {
    if (user.isBanned) {
      resetStore();
    } else {
      dispatch(updateUser(user));
    }
  };

  const onSocketErrorHandler = (error: ErrorResult) => {
    console.warn("Couldn't update socket event: ", error);
  };

  const onUpdateWalletHandler = (response: UpdateWalletResponse) => {
    const { allTokensList } = store.getState().tokensList;

    dispatch(updateHotWallet({ ...response, allTokensList }));
    dispatch(updateColdWallet(response));
  };

  const onTransactionCompletedHandler = (response: Transaction) => {
    const transaction = walletsErrorMessageConfig(response);

    if (
      transaction &&
      response.status === "FAILED" &&
      response.operation !== "unknown" &&
      response.operation !== TransactionOperationEnum.ESCROW // we don`t need to handle fail of escrow transaction
    ) {
      toast.error(
        <ToastTransactionStatusWithLink transaction={transaction} subStatus={response.subStatus} />,
        toastOptionsError,
      );
    }
  };

  const onCurrenciesRatesUpdatedHandler = (response: ExchangeRatesResponse[]) => {
    dispatch(updateRates(response));
  };

  const onSwapHistoryUpdatedHandler = (historyItem: SwapHistoryResponseSingleItem) => {
    dispatch(updateSwapHistory(historyItem));
  };

  const onBuyHistoryUpdatedHandler = (historyItem: BuyHistoryResponseSingleItem) => {
    dispatch(updateBuyHistory(historyItem));
  };

  const onWalletHistoryUpdatedHandler = (historyItem: HistoryResponseSingleItem) => {
    dispatch(updateHistory(historyItem));
  };

  const onChatReceiveMessage = (receivedMessagesData: ReceivedMessagesResponse) => {
    const {
      chat,
      message: { body, createdAt, updatedAt, userId, chatId, id },
    } = receivedMessagesData;
    const { reference, participants } = chat;

    const newMessage = {
      reference,
      body,
      createdAt: moment(createdAt).format(),
      updatedAt: moment(updatedAt).format(),
      userId,
      id,
      chatId,
      status: ChatMessageStatusEnum.unread,
    };

    const userRole = participants.find(item => item.userId !== userId)?.role;

    const activeTabForRole = userRole === ChatRoles.seller ? 1 : 0;

    dispatch(updateMessagesList({ reference, newMessage }));

    dispatch(updateCurrentChatInfo({ reference, chat, activeTabForRole }));
  };

  const onChatSendMessageException = ({ message }: { message: string }) => {
    toast.error(t(`${message}`), toastOptionsError);
  };

  const onChatCreateMessage = (createdMessageData: ReceivedMessagesResponse) => {
    const { chat } = createdMessageData;
    const { reference } = chat;

    dispatch(updateCurrentChatInfo({ reference, chat }));

    dispatch(
      setStatusToMessage({
        chatReference: reference,
        pending: false,
        isSendSuccess: true,
      }),
    );
  };

  const onChatReadMessageUpdate = (readMessageData: ChatReadMessageUpdateType) => {
    const { chatReference } = readMessageData;

    dispatch(
      setStatusToMessage({
        chatReference,
        isMessagesRead: true,
      }),
    );
  };

  const onAddNewEscrow = (escrowItem: MyEscrowsSingleItem) => {
    dispatch(addNewEscrow(escrowItem));
  };

  const onEscrowUpdate = (escrowItem: MyEscrowsSingleItem) => {
    dispatch(updateEscrowList(escrowItem));
    dispatch(updateCurrentEscrow(escrowItem));
  };

  const onAddNewNotification = (notificationItem: NotificationsListSingleItem) => {
    dispatch(addNewNotification(notificationItem));
  };

  const onUnreadNotificationCountChange = (count: number) => {
    dispatch(setUnreadNotificationCount(count));
  };

  return {
    onCompleteKycHandler,
    onUpdateUserHandler,
    onSocketErrorHandler,
    onUpdateWalletHandler,
    onCurrenciesRatesUpdatedHandler,
    onSwapHistoryUpdatedHandler,
    onBuyHistoryUpdatedHandler,
    onWalletHistoryUpdatedHandler,
    onChatReceiveMessage,
    onChatCreateMessage,
    onTransactionCompletedHandler,
    onChatSendMessageException,
    onChatReadMessageUpdate,
    onAddNewEscrow,
    onEscrowUpdate,
    onAddNewNotification,
    onUnreadNotificationCountChange,
  };
};
