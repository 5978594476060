import React from "react";

import { Flex, Box, Image, Text, FlexGap } from "components";

import { useCurrency } from "hooks";

import { NATIVE_CURRENCIES_LOGO, SHORT_CHAIN_NAMES } from "configs/web3";

import { TokenToChainInfoProps } from "./types";

const TokenToChainInfo: React.FC<TokenToChainInfoProps> = ({ currencyChainId, tokenSymbol, publicCode }) => {
  const currency = useCurrency(currencyChainId, publicCode);
  return (
    <Flex>
      <FlexGap gap="8px" minWidth="60px">
        <Box minHeight="24px" minWidth="24px">
          <Image
            src={NATIVE_CURRENCIES_LOGO[currencyChainId]}
            alt={SHORT_CHAIN_NAMES[currencyChainId]}
            width="24px"
            aspectRatio={1}
            variant="circle"
          />
        </Box>
        <Text color="neutral800" textScale="body3" ellipsis>
          {SHORT_CHAIN_NAMES[currencyChainId]}
        </Text>
      </FlexGap>

      <Text px="8px" color="neutral800" textScale="body3">
        /
      </Text>

      <Flex>
        <Box minHeight="24px" minWidth="24px">
          <Image src={currency?.logoURI ?? ""} alt={tokenSymbol} width="24px" aspectRatio={1} variant="circle" />
        </Box>

        <Text px="8px" color="neutral800" textScale="body3">
          {tokenSymbol}
        </Text>
      </Flex>
    </Flex>
  );
};

export default TokenToChainInfo;
