import React from "react";

import { useTooltip } from "hooks";

import { Text, Flex, FlexGap, Image, Box } from "components";

import { InfoRowProps } from "./types";

const InfoRow: React.FC<InfoRowProps> = ({ title, currency, logoURI, value }) => {
  const { targetRef, tooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  return (
    <Flex justifyContent="space-between" mb="14px">
      <Text textScale="caption1" color="neutral500">
        {title}
      </Text>

      <FlexGap gap="12px" maxWidth="50%" justifyContent="end">
        {currency && logoURI && (
          <Box minHeight="24px" minWidth="24px">
            <Image src={logoURI} alt={currency} width="24px" aspectRatio={1} variant="circle" />
          </Box>
        )}

        <Text ref={targetRef} textScale="body3" $fontWeight="medium" color="neutral800" ellipsis>
          {value}
        </Text>

        {tooltip}
      </FlexGap>
    </Flex>
  );
};

export default InfoRow;
