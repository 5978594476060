import * as yup from "yup";

import { useValidationTests } from "../hooks";
import { useTranslation } from "context";

import { useAppSelector } from "store/store";

import { InitialValues } from "./types";
import { Roles, CommissionPayer } from "../types";

import { SUPPORTED_BSC_CHAIN_ID, TESTNET_CHAIN_IDS } from "configs/web3";
import { DESCRIPTION_LENGTH, MIN, MAX } from "../constants";
import { AccountType } from "store/profile/types";

export const initialValues: InitialValues = {
  role: {
    role: Roles.buyer,
    createAWidget: true,
    sellersEmail: "",
    buyersEmail: "",
  },
  escrowInfo: {
    name: "",
    description: "",
    website: "",
    isShipped: false,
    address: null,
  },
  currency: {
    network: TESTNET_CHAIN_IDS.BSC_TEST,
    currency: "BNB",
    amount: "",
    brokersCommission: "",
    coversEscryptoFee: CommissionPayer.buyer,
    coversBrokersCommission: CommissionPayer.buyer,
    escryptoFee: "",
  },
};

const useValidationSchema = () => {
  const user = useAppSelector(state => state.profile.user);
  const allTokensList = useAppSelector(state => state.tokensList.allTokensList);

  const { t } = useTranslation();

  const initialValues: InitialValues = {
    role: {
      role: Roles.buyer,
      createAWidget: user?.accountType === AccountType.BUSINESS,
      sellersEmail: "",
      buyersEmail: "",
    },
    escrowInfo: {
      name: "",
      description: "",
      website: "",
      isShipped: false,
      address: null,
    },
    currency: {
      network: SUPPORTED_BSC_CHAIN_ID,
      currency: allTokensList ? Object.keys(allTokensList[SUPPORTED_BSC_CHAIN_ID])[0] : "",
      amount: "",
      brokersCommission: "",
      coversEscryptoFee: CommissionPayer.buyer,
      coversBrokersCommission: CommissionPayer.buyer,
      escryptoFee: "",
    },
  };

  const {
    brokersCommissionValueLimit,
    brokersCommissionIsRequired,
    amountMinValue,
    addressIsRequired,
    buyersEmailValidation,
    sellersEmailEmailValidation,
  } = useValidationTests();

  const validationSchema = yup.object().shape({
    role: yup.object().shape({
      role: yup.string().required(t("This field is required")),
      createAWidget: yup.boolean(),
      sellersEmail: yup.string().when("role", sellersEmailEmailValidation),
      buyersEmail: yup.string().when(["role", "createAWidget"], buyersEmailValidation),
    }),
    escrowInfo: yup.object().shape({
      name: yup
        .string()
        .required(t("This field is required"))
        .min(MIN, t("Must contain %value% or more characters", { value: MIN }))
        .max(MAX, t("The entered value is too long, maximum %value% characters", { value: MAX })),
      description: yup
        .string()
        .max(
          DESCRIPTION_LENGTH,
          t("Description must be less than %descriptionLength% characters", { descriptionLength: DESCRIPTION_LENGTH }),
        ),
      website: yup.string().url(t("Invalid URL address")),
      isShipped: yup.boolean(),
      address: yup.number().nullable().test("isRequired", t("This field is required"), addressIsRequired),
    }),
    currency: yup.object().shape({
      network: yup.mixed().required(t("This field is required")),
      currency: yup.string().required(t("This field is required")),
      amount: yup.string().required(t("This field is required")).test("minValue", "", amountMinValue),
      brokersCommission: yup
        .string()
        .test("isRequired", t("This field is required"), brokersCommissionIsRequired)
        .test("valueLimit", "", brokersCommissionValueLimit),
      coversEscryptoFee: yup.string(),
      coversBrokersCommission: yup.string(),
      escryptoFee: yup.string().required(t("This field is required")),
    }),
  });

  return { validationSchema, initialValues };
};

export default useValidationSchema;
