import { useLayoutEffect } from "react";

import { SliderStates } from "./use-controls";

export const useEventSubscribers = (
  wrapperRef: React.RefObject<HTMLDivElement>,
  setSliderStates: React.Dispatch<React.SetStateAction<SliderStates>>,
) => {
  // Get slider width
  useLayoutEffect(() => {
    if (wrapperRef.current?.clientWidth) {
      setSliderStates(prev => ({ ...prev, sliderWidth: wrapperRef.current!.clientWidth }));
    }

    const updateDimensions = () => {
      if (wrapperRef.current?.clientWidth) {
        setSliderStates(prev => ({ ...prev, sliderWidth: wrapperRef.current!.clientWidth }));
      }
    };

    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [wrapperRef.current?.clientWidth]);
};
