import styled, { css } from "styled-components";
import { space, layout } from "styled-system";

import { Flex } from "components/layout";

import { StyledTabPanelProps, StyledTabPanelButtonProps } from "./types";

const getHovered = ({ isActive, theme }: StyledTabPanelButtonProps) => {
  if (!isActive) {
    return css`
      :hover {
        background: ${theme.isDark ? theme.colors.neutral100 : theme.colors.neutral0};
        color: ${theme.colors.neutral600};
      }
    `;
  }
};

const getPosition = ({ activeTab }: StyledTabPanelProps) => {
  const space = 100 * activeTab;

  return css`
    transform: translateX(calc(${space}% + (${activeTab} * 8px)));
  `;
};

export const StyledTabPanel = styled(Flex)<StyledTabPanelProps>`
  position: relative;
  align-items: center;
  overflow-y: hidden;
  height: 44px;
  padding: 6px;
  gap: 6px;
  background: ${({ theme }) => (theme.isDark ? theme.colors.neutral200 : theme.colors.neutral100)};
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  border: 1px solid ${({ theme }) => theme.colors.neutral300};

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    max-width: 420px;

    &::before {
      content: "";
      position: absolute;
      height: 32px;
      border: 1px solid ${({ theme }) => (theme.isDark ? theme.colors.neutral400 : theme.colors.neutral300)};
      background: ${({ theme }) => (theme.isDark ? theme.colors.neutral300 : theme.colors.neutral0)};
      border-radius: ${({ theme }) => theme.radii.small};
      transition: all 0.3s ease;
      width: ${({ tabsCount }) => `calc((100% - 12px - 8px * (${tabsCount} - 1)) / ${tabsCount})`};
      ${getPosition}
    }
  }

  ${space}
  ${layout}
`;

export const StyledTabPanelButton = styled.button.attrs({ type: "button" })<StyledTabPanelButtonProps>`
  font-size: 12px;
  line-height: 20px;
  padding: 5px 18px;
  width: 100%;
  font-weight: 600;
  border-radius: ${({ theme }) => theme.radii.small};
  background: ${({ theme, isActive }) =>
    isActive ? (theme.isDark ? theme.colors.neutral300 : theme.colors.neutral0) : theme.colors.transparent};
  color: ${({ theme, isActive }) => (isActive ? theme.colors.neutral800 : theme.colors.neutral600)};
  transition: all 0.3s ease;
  z-index: 1;
  box-shadow: ${({ theme, isActive }) => isActive && theme.shadows.tabs};
  border: 1px solid
    ${({ theme, isActive }) =>
      isActive ? (theme.isDark ? theme.colors.neutral400 : theme.colors.neutral300) : theme.colors.transparent};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  white-space: nowrap;

  &:focus {
    outline: unset;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    height: 32px;
    border: none;
    background: none;
  }

  ${getHovered}
`;

// Components inside Tab should export with lazyLoad with { fallback: null }
export const Tab = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? "flex" : "none")};
  flex-grow: 1;
`;
