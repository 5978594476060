import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useGetSearchParams } from "hooks";

import { useAppSelector } from "store/store";

import { SEARCH_PARAMS } from "navigation/search-params";
import { ROUTES } from "navigation/routes";

import { AdditionalLoginMethodsProps, OptionProps } from "../types";
import { LoginApplePayload } from "store/auth/types";

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const appleClientId = process.env.REACT_APP_APPLE_CLIENT_ID;

export const useHandleData = ({
  pingOneResponse,
  status,
  onGoogleLoginHandler,
  onAppleLoginHandler,
  redirectURI,
}: Omit<AdditionalLoginMethodsProps, "textButtonPrefix">) => {
  const pending = useAppSelector(state => state.auth.pending);

  const navigate = useNavigate();
  const { currentSearchParams: googleCode } = useGetSearchParams(SEARCH_PARAMS.code);

  useEffect(() => {
    if (googleCode && (status === "ready" || status === "error")) {
      const params = {
        code: googleCode,
        redirectUrl: redirectURI,
        pingOneResponse,
      };
      onGoogleLoginHandler(params);
    }
  }, [googleCode, status]);

  const onGoogleLoginClick = () => {
    const config = {
      clientId: googleClientId,
      authorizationEndpoint: "https://accounts.google.com/o/oauth2/v2/auth",
      scope: "openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    };

    const options = new URLSearchParams({
      access_type: "online",
      include_granted_scopes: "true",
      scope: config.scope,
      response_type: "code",
      client_id: config.clientId,
      redirect_uri: redirectURI,
    } as OptionProps);

    const url = `${config.authorizationEndpoint}?${options.toString()}`;

    window.location.replace(url);
  };

  const onAppleSuccess = (res: LoginApplePayload) => {
    onAppleLoginHandler(res);
  };

  const onYubikeyLoginClick = () => {
    navigate(`/${ROUTES.loginWithYubikey}`);
  };

  return {
    appleClientId,
    pending,
    redirectURI,
    onGoogleLoginClick,
    onAppleSuccess,
    onYubikeyLoginClick,
  };
};
