import { createSlice } from "@reduxjs/toolkit";
import { createAddress, getAddressesList, updateAddress, deleteAddress } from "./actions";
import { AddressesState } from "./types.js";

const initialState: AddressesState = {
  error: null,
  pending: false,
  addressesList: [],
};

const addressesSlice = createSlice({
  name: "addresses",
  initialState: {
    ...initialState,
  },
  reducers: {
    resetAddresses: () => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createAddress.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(createAddress.pending, state => {
        state.pending = true;
      })
      .addCase(createAddress.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(getAddressesList.fulfilled, (state, action) => {
        state.addressesList = action.payload;
        state.error = null;
        state.pending = false;
      })
      .addCase(getAddressesList.pending, state => {
        state.pending = true;
      })
      .addCase(getAddressesList.rejected, (state, action) => {
        if (action.payload) {
          state.addressesList = [];
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(updateAddress.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(updateAddress.pending, state => {
        state.pending = true;
      })
      .addCase(updateAddress.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(deleteAddress.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(deleteAddress.pending, state => {
        state.pending = true;
      })
      .addCase(deleteAddress.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      });
  },
});

export const { resetAddresses } = addressesSlice.actions;
export default addressesSlice;
