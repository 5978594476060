import { memo, useCallback, useEffect } from "react";
import FlipNumbers from "react-flip-numbers";
// Context
import { useThemeContext } from "context";
// Components
import { Flex, Text } from "components";
// Types
import { KeysToShow, TimerProps } from "./types";
// Utils
import { getTimePeriods } from "utils/helpers";
import { useTimer } from "hooks";

// timestamp in seconds, that comes from contract in future date
const Timer: React.FC<TimerProps> = ({
  timestamp,
  onReachZeroCallback,
  keyListShow = ["days", "hours", "mins", "sec"],
  numberWidth = 24,
  numberHeight = 24,
  perspective,
  textProps,
  isLetterFormat,
  stopTimer,
  ...props
}) => {
  const { theme } = useThemeContext();
  const { remainingSeconds, initTimeRemaining } = useTimer({ timestamp, stopTimer });

  const timer = getTimePeriods(remainingSeconds);

  useEffect(() => {
    if (remainingSeconds === 0 && initTimeRemaining !== 0) {
      onReachZeroCallback();
    }
  }, [remainingSeconds, initTimeRemaining]);

  const formatTimerValue = useCallback((value: number) => {
    return value.toString().length === 1 ? `0${value}` : value.toString();
  }, []);

  const setLetterFormat = useCallback((keyItem: string) => {
    return isLetterFormat ? keyItem[0] : "";
  }, []);

  return (
    <Flex justifyContent="space-between" {...props}>
      {Object.keys(timer).map(keyItem => {
        if (keyListShow.includes(keyItem as KeysToShow)) {
          return (
            <Flex key={keyItem} flexDirection="column" alignItems="center" justifyContent="center">
              <Text key={keyItem} {...textProps} as="div">
                <FlipNumbers
                  play
                  width={numberWidth}
                  height={numberHeight}
                  perspective={perspective}
                  color={textProps.color ? theme.colors[textProps.color] : theme.colors.yellow}
                  numbers={
                    formatTimerValue(timer[keyItem as keyof typeof timer]) +
                    setLetterFormat(keyItem) +
                    (keyItem === keyListShow[keyListShow.length - 1] ? "" : ":")
                  }
                />
              </Text>
            </Flex>
          );
        }
      })}
    </Flex>
  );
};

export default memo(Timer);
