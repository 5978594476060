import useSWR from "swr";
import { toast } from "react-toastify";

import { useAppDispatch, useAppSelector } from "store/store";
import { useTranslation } from "context";

import { toastOptionsError } from "components";

import { isErrorResult } from "../services";

import { UserWalletsStatusEnum } from "store/profile/types";

import { getExchangeRates } from "store/rates/actions";

export const useCurrenciesRate = () => {
  const user = useAppSelector(state => state.profile.user);
  const rates = useAppSelector(state => state.rates.rates);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useSWR(
    user && user.isVerifiedKYC && user.walletsStatus === UserWalletsStatusEnum.CREATED ? "currenciesRate" : null,
    async () => {
      const data = await dispatch(getExchangeRates());

      if (data && isErrorResult(data.payload) && !rates) {
        toast.error(
          t("Sorry, something went wrong. We couldn’t load the exchange rates. Try again later or contact our Support"),
          toastOptionsError,
        );
      }
    },
    { revalidateOnFocus: false },
  );
};
