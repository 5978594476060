import { EmptyDataMessage, MultiplyPendingRows, PaginationContainer, StyledTableWrapper } from "components";

import { DesktopGrid } from "./DesktopGrid";
import { MobileGrid } from "./MobileGrid";
import { PendingRowSkeleton } from "./PendingRowSkeleton";

import { useMatchBreakpoints } from "hooks";

import { TableWithPaginationProps } from "./types";

export const TableWithPagination = <T,>({
  useHandlePayload,
  TableRowDesktop,
  headers,
  TableRowMobile,
  emptyDataHeader,
  emptyDataMessage,
  tableGap,
  isHiddenQuantity,
  isSideModal,
  ...props
}: TableWithPaginationProps<T>): JSX.Element => {
  const { isDesktop, isMobile, isTablet } = useMatchBreakpoints();
  const { data, count, pending, page, size, handlePageClick } = useHandlePayload();

  return (
    <StyledTableWrapper>
      {pending ? (
        <PendingRowSkeleton {...props} tableGap={tableGap} headers={headers} size={size} />
      ) : !data.length ? (
        <EmptyDataMessage title={emptyDataHeader} text={emptyDataMessage} />
      ) : isDesktop ? (
        <DesktopGrid<T> data={data} TableRow={TableRowDesktop} isSideModal={isSideModal} headers={headers} />
      ) : (
        <MobileGrid<T> data={data} tableGap={tableGap} TableRow={TableRowMobile} />
      )}

      {pending && page && handlePageClick ? (
        <MultiplyPendingRows rowHeight="24px" mt={{ _: "24px", tablet: "32px", laptop: "50px" }} />
      ) : data.length && page && size && count && handlePageClick ? (
        <PaginationContainer
          mt={{ _: "24px", tablet: "32px", laptop: "50px" }}
          page={page}
          size={size}
          count={count}
          isHiddenQuantity={isHiddenQuantity ? isHiddenQuantity : isMobile || isTablet}
          handlePageClick={handlePageClick}
        />
      ) : null}
    </StyledTableWrapper>
  );
};
