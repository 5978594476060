import React from "react";
import BigNumber from "bignumber.js";
// Components
import { Flex, Text, RowBetween } from "components";
// Context
import { useTranslation } from "context";
// Hooks
import { useGetFiatSymbolAndAbbreviation, useTooltip } from "hooks";

import { formatValueToBNString } from "configs/web3";
// Types
import { SwapPriceFeeInfoProps } from "../../types";

const PriceFeeInfo: React.FC<SwapPriceFeeInfoProps> = ({
  fromToken,
  toToken,
  networkFee,
  fromTokenPriceUSD,
  toTokenPriceUSD,
}) => {
  const { t } = useTranslation();
  const { fiatSymbol } = useGetFiatSymbolAndAbbreviation();

  const { targetRef: targetRefPriceTo, tooltip: priceToTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const ratePrice = formatValueToBNString(BigNumber(fromTokenPriceUSD).div(toTokenPriceUSD));

  return (
    <>
      <RowBetween>
        <Text textScale="caption1" color="neutral500">
          {t("Price")}:
        </Text>
        <Flex alignItems="flex-end">
          <Text textScale="caption1" color="neutral800">
            {1}
          </Text>

          <Text mx="4px" textScale="caption1" color="neutral800">
            {fromToken}
          </Text>
          <Text textScale="caption1">=</Text>

          <Text mx="4px" textScale="caption1" color="neutral800" maxWidth="115px" ref={targetRefPriceTo} ellipsis>
            {BigNumber(ratePrice).toFormatExtended(8)}
          </Text>
          {priceToTooltip}

          <Text textScale="caption1" color="neutral800">
            {toToken}
          </Text>
        </Flex>
      </RowBetween>

      <RowBetween mt="14px">
        <Text textScale="caption1" color="neutral500">
          {t("Network Fee")}:
        </Text>

        <Text textScale="caption1" color="neutral800">
          {`${fiatSymbol}${networkFee.toFormatExtended(2)}`}
        </Text>
      </RowBetween>
    </>
  );
};

export default PriceFeeInfo;
