import styled from "styled-components";
import { layout, LayoutProps } from "styled-system";
import { Text, Flex } from "components";

import { SideBarNavItemProps, SideBarTextProps } from "./types";
import { generateBackgroundGradient } from "utils/helpers";

export const StyledSideBar = styled.div<LayoutProps>`
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  justify-content: space-between;
  padding: 32px 0;
  background-color: ${({ theme }) => (theme.isDark ? theme.colors.neutral100 : theme.colors.neutral0)};
  border-right: 1px solid ${({ theme }) => theme.colors.neutral300};
  transition: width 0.3s ease;

  ${layout}
`;

export const StyledSideBarNavigateItem = styled(Flex)<SideBarNavItemProps>`
  padding: 14px 24px;
  align-items: center;
  width: 100%;
  border-right: ${({ isActive, theme }) =>
    isActive ? `1px solid ${theme.colors.primary}` : `1px solid ${theme.colors.transparent}`};
  transition: border-right 0.3s ease;

  &:hover {
    background-image: ${({ theme }) => generateBackgroundGradient(theme, "linear-90")};
  }
`;

export const StyledSideBarNavigateItemInnerText = styled(Text)<SideBarTextProps>`
  font-size: ${({ isDesktopMenuOpen }) => (isDesktopMenuOpen ? "initial" : "0px")};
  width: ${({ isDesktopMenuOpen }) => (isDesktopMenuOpen ? "100%" : "0px")};
  transition: font-size 0.3s ease;
  white-space: nowrap;
`;
