import React from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/src/style.css";

import { useOutsideClick } from "hooks";

import { StyledSingleDayPicker } from "../styled";

import { SingleDayPickerProps } from "./types";

export const SingleDayPicker: React.FC<SingleDayPickerProps> = ({ selectedDay, onSelect, onUnmountCallback }) => {
  const outsideClickCallback = () => {
    onUnmountCallback?.();
  };
  const { ref } = useOutsideClick({ callback: outsideClickCallback });
  const today = new Date();

  return (
    <StyledSingleDayPicker ref={ref} onClick={e => e.stopPropagation()} isSideMenuVersion={false}>
      <DayPicker
        disabled={{ after: today }}
        mode="single"
        selected={new Date(selectedDay)}
        onSelect={onSelect}
        numberOfMonths={1}
        captionLayout="dropdown"
        fromYear={today.getFullYear() - 100}
        toYear={today.getFullYear()}
        defaultMonth={selectedDay ? new Date(selectedDay) : undefined}
      />
    </StyledSingleDayPicker>
  );
};
