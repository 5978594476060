import { useState } from "react";
import { SingleValue } from "react-select";

import { useGetFilteredOptions, useHandleInputDebounce } from "hooks";

import { getFilteredOptions } from "utils/helpers";

import { useAppDispatch, useAppSelector } from "store/store";
import { setWhitelistPayload } from "store/wallets/whitelist";

import { SingleOption } from "components/selects/single-select/types";

const useHandleFilters = () => {
  const search = useAppSelector(state => state.whitelist.whitelistPayload.search);
  const chainId = useAppSelector(state => state.whitelist.whitelistPayload.chainId);

  const dispatch = useAppDispatch();

  const { options } = useGetFilteredOptions();
  const { networksOptions } = options;

  const preselectNetworkOption = networksOptions.find(option => option.value === chainId) ?? networksOptions[0];

  const [selectedOption, setSelectedOption] = useState(preselectNetworkOption);

  const filteredOptions = getFilteredOptions(networksOptions, selectedOption.value);

  const { value, handleInputChange, clearValue } = useHandleInputDebounce(search ?? "", debounceCallback);

  function debounceCallback(val: string) {
    dispatch(setWhitelistPayload({ page: 1, search: val }));
  }

  const handleSelectChange = (selectedOption: SingleValue<SingleOption>) => {
    if (selectedOption) {
      setSelectedOption(selectedOption);
      dispatch(setWhitelistPayload({ page: 1, chainId: selectedOption.value }));
    }
  };

  return {
    searchValue: value,
    selectedOption,
    options: filteredOptions,
    handleSelectChange,
    handleInputChange,
    clearValue,
  };
};

export default useHandleFilters;
