import React from "react";
import {
  parseCreationOptionsFromJSON,
  create,
  RegistrationPublicKeyCredential,
} from "@github/webauthn-json/browser-ponyfill";
import { toast } from "react-toastify";

import { useTranslation } from "context";
import { useAppDispatch } from "store/store";

import CloseModalWrapper from "../close-modal-wrapper";
import { StyledModalWrapper } from "../styled";
import { Box, Button, Flex, FlexGap, Text, toastOptionsError } from "components";
import { AddYubikeyIcon } from "components/svg";

import { ADD_YOUR_YUBIKEY_ITEM } from "./constants";
import { isErrorResult } from "services";
import { parsingYubikeyData } from "utils/helpers";

import { hideModal, showModal } from "store/modal";
import { getYubikeyKeysList, requestYubikeyRegistration, yubikeyRegistration } from "store/yubikey/actions";
import { updateSecuritySettings } from "store/profile/settings/actions";

import { ModalProps } from "store/modal/types";
import { YubikeyNameRegisterModalProps } from "components/modal/components/yubikey-name-register-modal/types";
import { HandleYubikeyRegistrationArgs, YubikeyErrorEnum } from "./types";
import { YubikeyRegisterDataResponse } from "store/yubikey/types";
import { CommonAuthMethodEnum } from "store/profile/settings/types";

const AddYourYubikeyModal: React.FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const handleYubikeyRegistration = ({ rawId, clientDataJSON, type }: HandleYubikeyRegistrationArgs) => {
    const _showModal = showModal as ModalProps<YubikeyNameRegisterModalProps>;

    dispatch(
      yubikeyRegistration({
        id: rawId,
        data: clientDataJSON,
        type: type,
      }),
    ).then(response => {
      if (!isErrorResult(response.payload)) {
        dispatch(getYubikeyKeysList()).then(response => {
          if (!isErrorResult(response.payload)) {
            dispatch(updateSecuritySettings({ login: CommonAuthMethodEnum.YUBIKEY }));
          }
        });

        dispatch(
          _showModal({
            rootId: "settings",
            modalName: "yubikeyNameRegisterModal",
            props: { data: clientDataJSON },
          }),
        );
      } else {
        //Sometimes, you can accept error as "Bad origin data", it`s mean,value rp.id in credential data from requestYubikeyRegistration has another domain than the one you are on
        closeModalHandler();
      }
    });
  };

  const continueHandler = async () => {
    //First of all, we need to create request to accept credential data from server to user yubikey
    //requestYubikeyRegistration - it`s request to accept these credential data

    let authOptions: YubikeyRegisterDataResponse | undefined = undefined;

    await dispatch(requestYubikeyRegistration()).then(response => {
      if (!isErrorResult(response.payload)) {
        authOptions = response.payload!;
      }
    });

    if (authOptions) {
      try {
        //It`s for develop on localhost, if you`ve receive "bad data origin provided", you should ask backend to comment "bad data origin" verification in yubico controller

        // const newAuthOptions = { ...(authOptions as YubikeyRegisterDataResponse) };
        // const authOptionsForLocalHost = {
        //   publicKey: {
        //     ...newAuthOptions.publicKey,
        //     rp: {
        //       id: "localhost",
        //       name: newAuthOptions.publicKey.rp.name,
        //     },
        //   },
        // };

        //authOptions - credential data from server to user yubikey
        //parseCreationOptionsFromJSON - parsing authOptions data to format which accept yubikey
        //create - Render some specific UI of user operation system and pass authOptions to user yubikey
        const result = await create(parseCreationOptionsFromJSON(authOptions));

        const { rawId, clientDataJSON, type } = parsingYubikeyData<RegistrationPublicKeyCredential>(result);

        //After parsing data from response, we need to pass this data to server to register user yubikey in our server
        handleYubikeyRegistration({ rawId, clientDataJSON, type });
      } catch (e) {
        if (e instanceof Error) {
          if (e.message === YubikeyErrorEnum.sameKeyError) {
            toast.error(t("Same key already exist"), toastOptionsError);
          } else {
            toast.error(t("Abort registration"), toastOptionsError);
          }
          closeModalHandler();
        }
      }
    }
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} />

      <AddYubikeyIcon width="72px" mt="14px" />

      <Box mb="40px">
        <Text textScale="body2" color="neutral600" pt="16px" pb="32px" textAlign="center">
          {t("Add your Yubikey")}
        </Text>

        <FlexGap gap="18px" flexDirection="column">
          {ADD_YOUR_YUBIKEY_ITEM.map(({ icon: Icon, text }, index) => (
            <FlexGap gap="20px" key={index}>
              <Flex alignSelf="flex-start">
                <Icon width="24px" />
              </Flex>

              <Text textScale="caption1" color="neutral600">
                {t(text)}
              </Text>
            </FlexGap>
          ))}
        </FlexGap>
      </Box>

      <FlexGap
        gap="14px"
        flexDirection={{ _: "column", tablet: "row" }}
        width="100%"
        justifyContent="space-between"
        mb={{ _: "28px", tablet: "30px" }}
      >
        <Button variant="outline" minWidth={{ _: "100%", tablet: "170px" }} onClick={closeModalHandler}>
          {t("Cancel")}
        </Button>

        <Button minWidth={{ _: "100%", tablet: "170px" }} onClick={continueHandler}>
          {t("Continue")}
        </Button>
      </FlexGap>
    </StyledModalWrapper>
  );
};

export default AddYourYubikeyModal;
