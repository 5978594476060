import React from "react";
import { Svg } from "components";

import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "neutral800", ...props }) => {
  return (
    <Svg viewBox="0 0 218 59" {...props} color={color}>
      <path
        d="M51.1061 47.4837V58.5243H0.321777V49.7679L32.1567 11.1639H0.92816V0.12326H49.8176V8.87961L18.0584 47.4837H51.1061ZM103.937 47.636V58.4481H58.9891V0.12326H102.876V10.9354H72.3294V23.575H99.3134V34.0827H72.3294V47.5598H103.937V47.636ZM150.477 58.5243L139.259 42.2299H138.576H126.904V58.5243H113.488V0.12326H138.652C143.807 0.12326 148.279 0.960822 152.068 2.71209C155.858 4.46336 158.739 6.89991 160.861 10.0217C162.908 13.2197 163.969 16.9507 163.969 21.2908C163.969 25.6309 162.908 29.3618 160.861 32.4837C158.814 35.6055 155.858 38.042 151.993 39.7172L165.03 58.4481H150.477V58.5243ZM147.066 13.7527C144.943 12.0014 141.912 11.1639 137.818 11.1639H126.828V31.4938H137.818C141.836 31.4938 144.943 30.5801 147.066 28.8288C149.188 27.0776 150.249 24.5649 150.249 21.2908C150.174 18.0167 149.112 15.504 147.066 13.7527ZM185.344 11.1639H166.773V0.12326H217.406V11.1639H198.836V58.5243H185.344V11.1639Z"
        fill={color}
      />
    </Svg>
  );
};

export default Icon;
