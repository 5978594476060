import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { useTooltip } from "hooks";

import { Box, StatusTag, Td, Text, Tr } from "components";

import { useAppDispatch } from "store/store";
import { hideModal } from "store/modal";

import { WidgetBuyersListTableRowProps } from "../types";
import { ROUTES } from "navigation/routes";

const TableRowDesktop: React.FC<WidgetBuyersListTableRowProps> = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { targetRef: targetRefName, tooltip: nameTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const { targetRef: targetRefDate, tooltip: dateTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const redirectToEscrowProcess = () => {
    dispatch(hideModal());
    navigate(`/${ROUTES.escrows}/${ROUTES.escrowProcess}/${item.body.id}/`);
  };

  return (
    <Tr hover onClick={redirectToEscrowProcess}>
      <Td p={{ laptop: "14px 12px" }}>
        <Box>
          <Box>
            <Text color="neutral800" textScale="body3" $fontWeight="medium" ellipsis ref={targetRefName}>
              {`${item.body.buyer.firstName} ${item.body.buyer.lastName}`}
            </Text>

            {nameTooltip}
          </Box>

          <Box mt="4px" ellipsis>
            <Text color="neutral600" textScale="caption1" ellipsis ref={targetRefDate}>
              {moment(item.body.createdAt).format("MM/DD/YYYY")}
            </Text>

            {dateTooltip}
          </Box>
        </Box>
      </Td>

      <Td p={{ laptop: "14px 0px" }}>
        <StatusTag status={item.status} />
      </Td>
    </Tr>
  );
};

export default TableRowDesktop;
