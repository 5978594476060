import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  return (
    <Svg viewBox="0 0 41 40" {...props} color={color}>
      <path
        d="M20.2499 40C31.2956 40 40.2499 31.0457 40.2499 20C40.2499 8.95431 31.2956 0 20.2499 0C9.20419 0 0.249878 8.95431 0.249878 20C0.249878 31.0457 9.20419 40 20.2499 40Z"
        fill="#3375BB"
      />
      <path
        d="M20.3917 8.75C24.3442 12.051 28.8768 11.8474 30.1719 11.8474C29.8886 30.6211 27.7302 26.8983 20.3917 32.1625C13.0532 26.8983 10.9083 30.6211 10.625 11.8474C11.9066 11.8474 16.4391 12.051 20.3917 8.75Z"
        stroke="white"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
