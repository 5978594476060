import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => state.whitelist.whitelistModalInfo.pending,
    (state: AppState) => state.whitelist.whitelistModalInfo.data,
    (state: AppState) => state.whitelist.whitelistModalInfo.count,
    (state: AppState) => state.whitelist.whitelistModalPayload.page,
    (state: AppState) => state.whitelist.whitelistModalPayload.size,
    (state: AppState) => state.whitelist.whitelistModalPayload.search,
    (state: AppState) => state.whitelist.whitelistModalPayload.chainId,
  ],
  (pending, data, count, page, size, search, chainId) => ({
    pending,
    data,
    count,
    page,
    size,
    search,
    chainId,
  }),
);
