import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

import { Svg } from "components";
import { SvgProps } from "../types";

import { useThemeContext } from "context";

const keyFrame = keyframes`
  0% {
    stroke-dashoffset: 200;
  }
  50% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -150;
  }
`;

const StyledCircle = styled.circle.attrs({
  cx: 22,
  cy: 22,
  r: 20.5,
})`
  stroke: ${({ theme }) => theme.colors.primary};
  stroke-width: 1;
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: ${keyFrame} 3s linear infinite;
  transform: rotate(-90deg);
  transform-origin: center;
`;

const Icon: React.FC<SvgProps> = ({ ...props }) => {
  const { theme } = useThemeContext();

  const [percentage, setPercentage] = useState(0);

  const size = 44;
  const radius = 21.25;
  const circumference = radius * Math.PI * 2;
  const dash = (percentage * circumference) / 100;

  useEffect(() => {
    if (!props.spin) {
      const interval = setInterval(() => {
        setPercentage(prev => prev + 1.6);
      }, 1000);

      if (percentage > 100) {
        setPercentage(0);
      }
      return () => clearInterval(interval);
    } else {
      setPercentage(0);
    }
  }, [percentage, props.spin]);

  return (
    <Svg viewBox={`0 0 ${size} ${size}`} color="transparent" spin={props.spin} {...props}>
      {props.spin ? (
        <>
          <circle cx={size / 2} cy={size / 2} r={radius} stroke={theme.colors.neutral300} strokeWidth="1" />
          <StyledCircle />
        </>
      ) : (
        <>
          <circle stroke={theme.colors.neutral300} cx={size / 2} cy={size / 2} r={radius} strokeWidth="1" />
          <circle
            stroke={theme.colors.primary}
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth="1"
            transform={`rotate(-90 ${size / 2} ${size / 2})`}
            strokeDasharray={`${dash}, ${circumference - dash}`}
            style={{ transition: "all 0.3s ease" }}
          />
        </>
      )}
    </Svg>
  );
};

export default Icon;
