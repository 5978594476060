import React from "react";

import { useAppSelector } from "store/store";
import { useHandleTabAndFilter } from "./hooks";

import { StyledContentWrapper, Tab, TabPanelWithSelect } from "components";
import { CreateVault, Overview, History } from "./components";

import { ROUTES } from "navigation/routes";
import { tabsIndex } from "configs";

const ColdStorage: React.FC = () => {
  const activeTab = useAppSelector(state => state.coldWallets.coldWallets.activeTab);

  return (
    <StyledContentWrapper id={ROUTES.coldStorage} height="100%">
      <TabPanelWithSelect
        maxWidthTabTablet="505px"
        useHandleTabAndFilter={useHandleTabAndFilter}
        idForTabPanel="walletTabPanel"
      />

      <Tab active={activeTab === tabsIndex.coldStorage.overView}>
        <Overview />
      </Tab>

      <Tab active={activeTab === tabsIndex.coldStorage.createVault}>
        <CreateVault />
      </Tab>

      <Tab active={activeTab === tabsIndex.coldStorage.history}>
        <History />
      </Tab>
    </StyledContentWrapper>
  );
};

export default ColdStorage;
