import { ModalFaceLoadingIcon, ModalMitekErrorIcon, ModalVoiceIcon } from "components/svg";

import { useTranslation } from "context";

import { hideModal, showModal } from "store/modal";
import { useAppDispatch, useAppSelector } from "store/store";

import { ModalProps } from "store/modal/types";
import { ActionModalProps, LinkInfo } from "components/modal/components/action-modal/types";

import { getBiometricErrorMessage } from "utils/helpers";

const useShowMitekErrorModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const rootId = useAppSelector(state => state.modal.rootId);

  const showErrorModal = (errorKey?: string, linkInfo?: LinkInfo) => {
    const errorMessage = getBiometricErrorMessage(errorKey);

    const _showModal = showModal as ModalProps<ActionModalProps>;

    dispatch(
      _showModal({
        modalName: "actionModal",
        clickOutsideHandler: closeModalHandler,
        rootId,
        props: {
          icon: <ModalMitekErrorIcon height="72px" width="72px" />,
          title: t("Unable to continue"),
          text: t(errorMessage),
          btnArr: [
            {
              buttonText: t("OK"),
              callback: closeModalHandler,
              props: {
                width: "170px",
              },
            },
          ],
          linkInfo,
        },
      }),
    );
  };

  const showLoadingModal = ({
    isShowTitle = true,
    verifyType = "face",
  }: { isShowTitle?: boolean; verifyType?: "voice" | "face" } = {}) => {
    const action = verifyType === "face" ? t("face capture") : t("voice recording");

    const _showModal = showModal as ModalProps<ActionModalProps>;
    const ModalIcon = verifyType === "face" ? ModalFaceLoadingIcon : ModalVoiceIcon;
    dispatch(
      _showModal({
        modalName: "actionModal",
        rootId,
        props: {
          icon: <ModalIcon height="72px" width="72px" />,
          title: t("Processing %action%", { action }),
          text: t("Please, wait..."),
          showCloseModalIcon: false,
          isShowTitle,
        },
      }),
    );
  };

  return { showErrorModal, showLoadingModal };
};

export default useShowMitekErrorModal;
