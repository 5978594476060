import React from "react";
import { Svg } from "components";
import { useThemeContext } from "context";

import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "primaryHover", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        id="Path"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 20V5.778C20 4.796 19.204 4 18.222 4H5.778C4.796 4 4 4.796 4 5.778V16.445C4 17.427 4.796 18.223 5.778 18.223H16.667L20 20Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M12.177 11.0728C12.275 11.1708 12.275 11.3288 12.177 11.4268C12.079 11.5248 11.921 11.5248 11.823 11.4268C11.725 11.3288 11.725 11.1708 11.823 11.0728C11.921 10.9748 12.079 10.9758 12.177 11.0728"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_3"
        d="M16.177 11.0728C16.275 11.1708 16.275 11.3288 16.177 11.4268C16.079 11.5248 15.921 11.5248 15.823 11.4268C15.725 11.3288 15.725 11.1708 15.823 11.0728C15.921 10.9748 16.079 10.9758 16.177 11.0728"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_4"
        d="M8.177 11.0728C8.275 11.1708 8.275 11.3288 8.177 11.4268C8.079 11.5248 7.921 11.5248 7.823 11.4268C7.725 11.3288 7.725 11.1708 7.823 11.0728C7.921 10.9748 8.079 10.9758 8.177 11.0728"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
