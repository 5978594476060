import packages from "../../package.json";

const LOCAL_STORAGE_KEYS = {
  isLoggedIn: `${packages.name}_isLoggedIn`,
  theme: `${packages.name}_theme`,
  language: `${packages.name}_language`,
  solanaWallet: `${packages.name}_solanaWallet`,
  widgetId: `${packages.name}_widgetId`,
  connection: `${packages.name}_connection`,
};

export default LOCAL_STORAGE_KEYS;
