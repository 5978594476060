import styled from "styled-components";
import { StyledModalWrapper } from "../styled";

export const StyledBiometricModalWrapper = styled(StyledModalWrapper)`
  width: 100vw;
  min-height: 100vh;
  border: none;
  border-radius: 0px;
  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 418px;
    border: 1px solid ${({ theme }) => theme.colors.neutral200};
    border-radius: 16px;
    min-height: auto;
  }
`;
