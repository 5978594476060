import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color={color}>
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        fill={color}
        stroke={theme.colors.yubikeyLoginLogo}
        strokeWidth="1.7382"
      />
      <path
        d="M12.0003 17.8315H9.25783L10.5325 14.8573L7.44238 7.05469H10.1076L12.0003 12.1534L13.7771 7.05469H16.4038"
        fill={theme.colors.yubikeyLoginLogo}
      />
    </Svg>
  );
};

export default Icon;
