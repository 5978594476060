import { TransactionOperationEnum } from "store/wallets/enums";

import { setHotWalletTab } from "store/wallets/hot-wallets";
import { setColdStorageTab } from "store/wallets/cold-wallets";
import { setSwapTab } from "store/swap";

import { ROUTES } from "navigation/routes";
import { tabsIndex } from "configs";
import { Transaction } from "store/wallets/cold-wallets/types";

export const walletsErrorMessageConfig = (response: Transaction) => {
  const {
    currency: { chainId, publicCode },
  } = response;

  const operation = response.operation;

  switch (operation) {
    case TransactionOperationEnum.WITHDRAW: {
      return {
        title: "withdraw",
        link: `/${ROUTES.wallet}/${chainId}/${publicCode}/${ROUTES.history}`,
        action: setHotWalletTab,
        activeTab: tabsIndex.wallets.history,
      };
    }
    case TransactionOperationEnum.DEPOSIT: {
      return {
        title: "deposit",
        link: `/${ROUTES.wallet}/${chainId}/${publicCode}/${ROUTES.history}`,
        action: setHotWalletTab,
        activeTab: tabsIndex.wallets.history,
      };
    }
    case TransactionOperationEnum.DEPOSIT_TO_COLD: {
      return {
        title: "deposit to cold wallet",
        link: `/${ROUTES.coldStorage}`,
        action: setColdStorageTab,
        activeTab: tabsIndex.coldStorage.history,
      };
    }
    case TransactionOperationEnum.WITHDRAW_FROM_COLD: {
      return {
        title: "withdraw to cold wallet",
        link: `/${ROUTES.coldStorage}`,
        action: setColdStorageTab,
        activeTab: tabsIndex.coldStorage.history,
      };
    }
    case TransactionOperationEnum.SUBSCRIPTION_FEE: {
      return {
        title: "subscription fee transaction",
        link: `/${ROUTES.coldStorage}`,
        action: setColdStorageTab,
        activeTab: tabsIndex.coldStorage.history,
      };
    }
    case TransactionOperationEnum.SWAP: {
      return {
        title: "swap",
        link: `/${ROUTES.swap}`,
        action: setSwapTab,
        activeTab: tabsIndex.swap.history,
      };
    }
    default:
      return {
        title: "operation",
        link: `/${ROUTES.wallet}`,
        action: setHotWalletTab,
        activeTab: tabsIndex.wallets.network,
      };
  }
};
