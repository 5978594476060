import type { AddEthereumChainParameter } from "@web3-react/types";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

import { SOLANA, SOLANA_NETWORK } from "configs/solana/chains";
import { BITCOIN, BITCOIN_CHAINS, BITCOIN_NETWORK } from "configs/bitcoin/chains";

import { ChainId } from "./types";

export const MAINNET_CHAIN_IDS = {
  MAINNET: 1,
  BSC: 56,
  POLYGON: 137,
  AVAX: 43114,
};

export const TESTNET_CHAIN_IDS = {
  SEPOLIA: 11155111,
  BSC_TEST: 97,
  POLYGON_MUMBAI: 80001,
  FUJI: 43113,
};

export const CHAIN_IDS_MAINNET = Object.values(MAINNET_CHAIN_IDS);
export const CHAIN_IDS_TESTNET = Object.values(TESTNET_CHAIN_IDS);

export const SUPPORTED_ETH_CHAIN_ID =
  process.env.REACT_APP_ENV === "development" ? TESTNET_CHAIN_IDS.SEPOLIA : MAINNET_CHAIN_IDS.MAINNET;

export const SUPPORTED_BSC_CHAIN_ID =
  process.env.REACT_APP_ENV === "development" ? TESTNET_CHAIN_IDS.BSC_TEST : MAINNET_CHAIN_IDS.BSC;

export const SUPPORTED_CHAIN_IDS =
  process.env.REACT_APP_ENV === "development" ? Object.values(CHAIN_IDS_TESTNET) : Object.values(CHAIN_IDS_MAINNET);

export const SUPPORTED_CHAINS = [...SUPPORTED_CHAIN_IDS, SOLANA_NETWORK, BITCOIN_NETWORK];

export const RPC_URLS = {
  // Main
  [MAINNET_CHAIN_IDS.MAINNET]: "https://rpc.ankr.com/eth",
  [MAINNET_CHAIN_IDS.BSC]: "https://bsc-dataseed.binance.org",
  [MAINNET_CHAIN_IDS.POLYGON]: "https://polygon-rpc.com",
  [MAINNET_CHAIN_IDS.AVAX]: "https://api.avax.network/ext/bc/C/rpc",
  // Test
  [TESTNET_CHAIN_IDS.SEPOLIA]: "https://rpc.sepolia.org",
  [TESTNET_CHAIN_IDS.BSC_TEST]: "https://data-seed-prebsc-1-s1.binance.org:8545",
  [TESTNET_CHAIN_IDS.POLYGON_MUMBAI]: "https://rpc-mumbai.maticvigil.com",
  [TESTNET_CHAIN_IDS.FUJI]: "https://api.avax-test.network/ext/bc/C/rpc",
};

export const BLOCK_EXPLORER_URLS = {
  // Main
  [MAINNET_CHAIN_IDS.MAINNET]: "https://etherscan.io",
  [MAINNET_CHAIN_IDS.BSC]: "https://bscscan.com",
  [MAINNET_CHAIN_IDS.POLYGON]: "https://polygonscan.com",
  [MAINNET_CHAIN_IDS.AVAX]: "https://snowtrace.io",
  // Test
  [TESTNET_CHAIN_IDS.SEPOLIA]: "https://sepolia.etherscan.io",
  [TESTNET_CHAIN_IDS.BSC_TEST]: "https://testnet.bscscan.com",
  [TESTNET_CHAIN_IDS.POLYGON_MUMBAI]: "https://mumbai.polygonscan.com",
  [TESTNET_CHAIN_IDS.FUJI]: "https://testnet.snowtrace.io",
};

export const BLOCK_EXPLORER_URLS_SOLANA = "https://solscan.io";

export const CHAIN_NAMES = {
  // Main
  [MAINNET_CHAIN_IDS.MAINNET]: "Ethereum Mainnet",
  [MAINNET_CHAIN_IDS.BSC]: "Binance Smart Chain Mainnet",
  [MAINNET_CHAIN_IDS.POLYGON]: "Polygon Mainnet",
  [MAINNET_CHAIN_IDS.AVAX]: "Avalanche C-Chain",
  // Test
  [TESTNET_CHAIN_IDS.SEPOLIA]: "Sepolia Testnet",
  [TESTNET_CHAIN_IDS.BSC_TEST]: "Binance Smart Chain Testnet",
  [TESTNET_CHAIN_IDS.POLYGON_MUMBAI]: "Polygon Mumbai",
  [TESTNET_CHAIN_IDS.FUJI]: "Avalanche Fuji Testnet",
  // Solana
  [WalletAdapterNetwork.Mainnet]: "Solana",
  [WalletAdapterNetwork.Devnet]: "Solana Devnet",

  //  Bitcoin
  [BITCOIN_CHAINS.BTC]: "Bitcoin",
  [BITCOIN_CHAINS.BTC_TEST]: "Bitcoin Testnet",
};

export const SHORT_CHAIN_NAMES = {
  // Main
  [MAINNET_CHAIN_IDS.MAINNET]: "ETH",
  [MAINNET_CHAIN_IDS.BSC]: "BSC",
  [MAINNET_CHAIN_IDS.POLYGON]: "Polygon",
  [MAINNET_CHAIN_IDS.AVAX]: "AVAX C-Chain",
  // Test
  [TESTNET_CHAIN_IDS.SEPOLIA]: "Sepolia",
  [TESTNET_CHAIN_IDS.BSC_TEST]: "BSC",
  [TESTNET_CHAIN_IDS.POLYGON_MUMBAI]: "Mumbai",
  [TESTNET_CHAIN_IDS.FUJI]: "AVAX",

  // Solana
  [WalletAdapterNetwork.Mainnet]: "Solana",
  [WalletAdapterNetwork.Devnet]: "Solana Devnet",

  //  Bitcoin
  [BITCOIN_CHAINS.BTC]: "Bitcoin",
  [BITCOIN_CHAINS.BTC_TEST]: "Bitcoin Testnet",
};

export const ETH: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Ether",
  symbol: "ETH",
  decimals: 18,
};

export const BNB: AddEthereumChainParameter["nativeCurrency"] = { name: "BNB", symbol: "BNB", decimals: 18 };

export const MATIC: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Matic",
  symbol: "MATIC",
  decimals: 18,
};

export const AVAX: AddEthereumChainParameter["nativeCurrency"] = {
  name: "Avalanche",
  symbol: "AVAX",
  decimals: 18,
};

export const NATIVE_CURRENCIES = {
  // Main
  [MAINNET_CHAIN_IDS.MAINNET]: ETH,
  [MAINNET_CHAIN_IDS.BSC]: BNB,
  [MAINNET_CHAIN_IDS.POLYGON]: MATIC,
  [MAINNET_CHAIN_IDS.AVAX]: AVAX,
  // Test
  [TESTNET_CHAIN_IDS.SEPOLIA]: ETH,
  [TESTNET_CHAIN_IDS.BSC_TEST]: BNB,
  [TESTNET_CHAIN_IDS.POLYGON_MUMBAI]: MATIC,
  [TESTNET_CHAIN_IDS.FUJI]: AVAX,

  // Solana
  [WalletAdapterNetwork.Mainnet]: SOLANA,
  [WalletAdapterNetwork.Devnet]: SOLANA,

  // Bitcoin
  [BITCOIN_CHAINS.BTC]: BITCOIN,
  [BITCOIN_CHAINS.BTC_TEST]: BITCOIN,
};

export const NATIVE_CURRENCIES_LOGO = {
  // Main
  [MAINNET_CHAIN_IDS.MAINNET]: "/images/tokens/ETH.webp",
  [MAINNET_CHAIN_IDS.BSC]: "/images/tokens/BNB.webp",
  [MAINNET_CHAIN_IDS.POLYGON]: "/images/tokens/MATIC.webp",
  [MAINNET_CHAIN_IDS.AVAX]: "/images/tokens/AVAX.webp",
  // Test
  [TESTNET_CHAIN_IDS.SEPOLIA]: "/images/tokens/ETH.webp",
  [TESTNET_CHAIN_IDS.BSC_TEST]: "/images/tokens/BNB.webp",
  [TESTNET_CHAIN_IDS.POLYGON_MUMBAI]: "/images/tokens/MATIC.webp",
  [TESTNET_CHAIN_IDS.FUJI]: "/images/tokens/AVAX.webp",

  // Solana
  [WalletAdapterNetwork.Mainnet]: "/images/tokens/SOL.webp",
  [WalletAdapterNetwork.Devnet]: "/images/tokens/SOL.webp",

  // Bitcoin
  [BITCOIN_CHAINS.BTC]: "/images/tokens/BTC.webp",
  [BITCOIN_CHAINS.BTC_TEST]: "/images/tokens/BTC.webp",
};

type ChainConfig = {
  [chainId: ChainId]: AddEthereumChainParameter;
};

export const MAINNET_CHAINS: ChainConfig = {
  [MAINNET_CHAIN_IDS.MAINNET]: {
    chainId: MAINNET_CHAIN_IDS.MAINNET,
    chainName: CHAIN_NAMES[MAINNET_CHAIN_IDS.MAINNET],
    nativeCurrency: ETH,
    rpcUrls: [RPC_URLS[MAINNET_CHAIN_IDS.MAINNET]],
    blockExplorerUrls: [BLOCK_EXPLORER_URLS[MAINNET_CHAIN_IDS.MAINNET]],
  },

  [MAINNET_CHAIN_IDS.BSC]: {
    chainId: MAINNET_CHAIN_IDS.BSC,
    chainName: CHAIN_NAMES[MAINNET_CHAIN_IDS.BSC],
    nativeCurrency: BNB,
    rpcUrls: [RPC_URLS[MAINNET_CHAIN_IDS.BSC]],
    blockExplorerUrls: [BLOCK_EXPLORER_URLS[MAINNET_CHAIN_IDS.BSC]],
  },

  [MAINNET_CHAIN_IDS.POLYGON]: {
    chainId: MAINNET_CHAIN_IDS.POLYGON,
    chainName: CHAIN_NAMES[MAINNET_CHAIN_IDS.POLYGON],
    nativeCurrency: MATIC,
    rpcUrls: [RPC_URLS[MAINNET_CHAIN_IDS.POLYGON]],
    blockExplorerUrls: [BLOCK_EXPLORER_URLS[MAINNET_CHAIN_IDS.POLYGON]],
  },

  [MAINNET_CHAIN_IDS.AVAX]: {
    chainId: MAINNET_CHAIN_IDS.AVAX,
    chainName: CHAIN_NAMES[MAINNET_CHAIN_IDS.AVAX],
    nativeCurrency: AVAX,
    rpcUrls: [RPC_URLS[MAINNET_CHAIN_IDS.AVAX]],
    blockExplorerUrls: [BLOCK_EXPLORER_URLS[MAINNET_CHAIN_IDS.AVAX]],
  },
};

export const TESTNET_CHAINS: ChainConfig = {
  [TESTNET_CHAIN_IDS.SEPOLIA]: {
    chainId: TESTNET_CHAIN_IDS.SEPOLIA,
    chainName: CHAIN_NAMES[TESTNET_CHAIN_IDS.SEPOLIA],
    nativeCurrency: ETH,
    rpcUrls: [RPC_URLS[TESTNET_CHAIN_IDS.SEPOLIA]],
    blockExplorerUrls: [BLOCK_EXPLORER_URLS[TESTNET_CHAIN_IDS.SEPOLIA]],
  },

  [TESTNET_CHAIN_IDS.BSC_TEST]: {
    chainId: TESTNET_CHAIN_IDS.BSC_TEST,
    chainName: CHAIN_NAMES[TESTNET_CHAIN_IDS.BSC_TEST],
    nativeCurrency: BNB,
    rpcUrls: [RPC_URLS[TESTNET_CHAIN_IDS.BSC_TEST]],
    blockExplorerUrls: [BLOCK_EXPLORER_URLS[TESTNET_CHAIN_IDS.BSC_TEST]],
  },

  [TESTNET_CHAIN_IDS.POLYGON_MUMBAI]: {
    chainId: TESTNET_CHAIN_IDS.POLYGON_MUMBAI,
    chainName: CHAIN_NAMES[TESTNET_CHAIN_IDS.POLYGON_MUMBAI],
    nativeCurrency: MATIC,
    rpcUrls: [RPC_URLS[TESTNET_CHAIN_IDS.POLYGON_MUMBAI]],
    blockExplorerUrls: [BLOCK_EXPLORER_URLS[TESTNET_CHAIN_IDS.POLYGON_MUMBAI]],
  },

  [TESTNET_CHAIN_IDS.FUJI]: {
    chainId: TESTNET_CHAIN_IDS.FUJI,
    chainName: CHAIN_NAMES[TESTNET_CHAIN_IDS.FUJI],
    nativeCurrency: AVAX,
    rpcUrls: [RPC_URLS[TESTNET_CHAIN_IDS.FUJI]],
    blockExplorerUrls: [BLOCK_EXPLORER_URLS[TESTNET_CHAIN_IDS.FUJI]],
  },
};

export const CHAINS = { ...MAINNET_CHAINS, ...TESTNET_CHAINS };
