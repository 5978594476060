import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  return (
    <Svg viewBox="0 0 24 24" {...props} color={color}>
      <circle cx="12" cy="12" r="12" fill="#EBF1FF" />
      <path d="M12 6.75L12 17.25" stroke="#246BFD" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.24902 10.5L11.9998 6.74924L15.7505 10.5"
        stroke="#246BFD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
