import React from "react";

import { useThemeContext } from "context";

import { Svg } from "components";

import { SvgProps } from "components/svg/types";

const Icon: React.FC<SvgProps> = ({ color = "blue", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        d="M19.71 17V15.71C19.71 14.813 18.945 14 18 14C17.055 14 16.29 14.765 16.29 15.71V17"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 21H16C15.448 21 15 20.552 15 20V18C15 17.448 15.448 17 16 17H20C20.552 17 21 17.448 21 18V20C21 20.552 20.552 21 20 21Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 18.981H5C3.895 18.981 3 18.086 3 16.981V7.01904C3 5.91404 3.895 5.01904 5 5.01904H19C20.105 5.01904 21 5.91404 21 7.01904V11"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 9L12 12L7 9"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
