import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "white", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent" stroke={theme.colors[color]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9262 15.9987V8.0007C20.9262 7.2857 20.5452 6.6257 19.9262 6.2687L13.0002 2.2697C12.3812 1.9127 11.6192 1.9127 11.0002 2.2697L4.07422 6.2687C3.45522 6.6257 3.07422 7.2867 3.07422 8.0007V15.9977C3.07422 16.7127 3.45522 17.3727 4.07422 17.7297L11.0002 21.7297C11.6192 22.0867 12.3812 22.0867 13.0002 21.7297L19.9262 17.7307C20.5452 17.3737 20.9262 16.7127 20.9262 15.9987Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.17041 12.6289L9.17041 14.3689"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.3418 7.00195L11.9998 12.001L20.6578 7.00195"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 22V12" stroke={theme.colors[color]} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.66992 9.49961L16.5999 4.34961"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
