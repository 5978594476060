import { EscrowStatusEnum } from "store/escrow/enums";

export const escrowStatusList = [
  { value: EscrowStatusEnum.NEW, label: "New" },
  { value: EscrowStatusEnum.ACTIVE, label: "Active" },
  { value: EscrowStatusEnum.CANCELED, label: "Canceled" },
  { value: EscrowStatusEnum.COMPLETED, label: "Completed" },
  { value: EscrowStatusEnum.REQUIRES_ACTION, label: "Requires Action" },
  { value: EscrowStatusEnum.CLAIMED, label: "Claimed" },
];
