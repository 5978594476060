import React, { useState } from "react";
import { renderToString } from "react-dom/server";

import { useCopyContent } from "hooks";
import { useTranslation } from "context";

import { Text, Button, WidgetButton } from "components";
import CloseModalWrapper from "../close-modal-wrapper";
import { WidgetCreatedIcon } from "components/svg";
import { StyledModalWrapper } from "../styled";

import { useAppSelector } from "store/store";

import { WidgetCreatedModalProps } from "./types";

const WidgetCreatedModal: React.FC = () => {
  const { widgetId, closeModalHandler } = useAppSelector(
    state => state.modal.props as unknown as WidgetCreatedModalProps,
  );

  const [markup, setMarkup] = useState<React.ReactNode | null>(null);

  const { t } = useTranslation();
  const { copyContentHandler } = useCopyContent();

  const copyAndContinueHandler = () => {
    if (markup) {
      const markupInString = renderToString(markup as React.ReactElement);
      copyContentHandler(markupInString);
      closeModalHandler();
    }
  };
  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} />

      <WidgetCreatedIcon width="72px" mt="18px" mb="16px" />

      <Text color="neutral600" textAlign="center" textScale="body2" mb="40px">
        {t("Widget Created!")}
      </Text>

      <WidgetButton isShowTitle widgetId={widgetId} setMarkup={setMarkup} />

      <Button width="100%" onClick={copyAndContinueHandler} mt="40px" mb="30px">
        {t("Copy & Continue")}
      </Button>
    </StyledModalWrapper>
  );
};

export default WidgetCreatedModal;
