import { createAsyncThunk } from "@reduxjs/toolkit";

import { ENDPOINTS_MESSENGER, isErrorResult, makeApiRequest } from "services";

import { ErrorResult } from "services/types";
import {
  ChatsListResponse,
  ChatInfoResponse,
  ChatListPayload,
  ChatMessagesListResponse,
  CurrentChatRoomPayload,
  ChatInfoPayload,
} from "./types";

export const getChatsList = createAsyncThunk<ChatsListResponse, ChatListPayload, { rejectValue: ErrorResult }>(
  "escrow/get-chats-list",
  async (params, { rejectWithValue }) => {
    const result = await makeApiRequest<ChatsListResponse>({
      method: "GET",
      url: ENDPOINTS_MESSENGER.getChatsList,
      params,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getChatInfo = createAsyncThunk<ChatInfoResponse | null, ChatInfoPayload, { rejectValue: ErrorResult }>(
  "escrow/get-chat-info",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<ChatInfoResponse>({
      method: "GET",
      url: `${ENDPOINTS_MESSENGER.getChatsList}${data.reference}`,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getMessagesList = createAsyncThunk<
  ChatMessagesListResponse,
  CurrentChatRoomPayload,
  { rejectValue: ErrorResult }
>("escrow/get-messages-list", async (params, { rejectWithValue }) => {
  const result = await makeApiRequest<ChatMessagesListResponse>({
    method: "GET",
    url: `${ENDPOINTS_MESSENGER.getChatsList}${params.reference}/messages`,
    params,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});
