import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { tabsIndex } from "configs/tabs";

import { getWallets } from "../cold-wallets/actions";

import { getTotalBalance, setNetworksWithCurrencies } from "configs/web3";

import { withdrawToHotWallet } from "./actions";

import { HotWalletsState, Networks } from "./types";
import { UpdateWalletResponse } from "../types";
import { WalletsTypeEnum } from "../enums";

const initialState: HotWalletsState = {
  error: null,
  pending: false,
  networks: null,
  isBalanceVisible: true,
  depositTab: {
    activeTab: tabsIndex.depositMethod.automatic,
  },
  wallets: {
    activeTab: tabsIndex.wallets.network,
    pending: false,
    data: [],
    totalBalanceFiat: "0",
    totalBalanceCrypto: "0",
  },
};

const hotWalletsSlice = createSlice({
  name: "hotWallets",
  initialState: {
    ...initialState,
  },
  reducers: {
    setNetworks: (state, action: PayloadAction<Networks>) => {
      state.networks = action.payload;
    },
    setDepositTab: (state, action: PayloadAction<number>) => {
      state.depositTab.activeTab = action.payload;
    },
    setHotWalletTab: (state, action: PayloadAction<number>) => {
      state.wallets.activeTab = action.payload;
    },
    setTotalBalanceVisibility: (state, action: PayloadAction<boolean>) => {
      state.isBalanceVisible = action.payload;
    },

    updateHotWallet: (state, action: PayloadAction<UpdateWalletResponse>) => {
      if (action.payload.wallet.type === WalletsTypeEnum.hot) {
        const updatedList = state.wallets.data.map(wallet =>
          wallet.id === action.payload.wallet.id ? { ...wallet, ...action.payload.wallet } : wallet,
        );

        state.networks = setNetworksWithCurrencies(updatedList, action.payload.allTokensList);
        state.wallets.data = updatedList;
        state.wallets.totalBalanceFiat = getTotalBalance(updatedList, "fiat");
        state.wallets.totalBalanceCrypto = getTotalBalance(updatedList, "crypto");
      }
    },

    resetHotWallets: () => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getWallets.fulfilled, (state, action) => {
        if (action.payload.type === WalletsTypeEnum.hot) {
          state.wallets.data = action.payload.wallets;
          state.wallets.pending = false;
          state.wallets.totalBalanceFiat = getTotalBalance(action.payload.wallets, "fiat");
          state.wallets.totalBalanceCrypto = getTotalBalance(action.payload.wallets, "crypto");
        }
        state.error = null;
      })
      .addCase(getWallets.pending, (state, action) => {
        if (action.meta.arg.type === WalletsTypeEnum.hot) {
          state.wallets.pending = true;
        }
      })
      .addCase(getWallets.rejected, (state, action) => {
        if (action.payload) {
          if (action.payload.type === WalletsTypeEnum.hot) {
            state.wallets = initialState.wallets;
          }

          state.networks = null;
          state.error = action.payload;
        }
      })
      .addCase(withdrawToHotWallet.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(withdrawToHotWallet.pending, state => {
        state.pending = true;
      })
      .addCase(withdrawToHotWallet.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      });
  },
});

export const {
  setNetworks,
  resetHotWallets,
  updateHotWallet,
  setDepositTab,
  setHotWalletTab,
  setTotalBalanceVisibility,
} = hotWalletsSlice.actions;
export default hotWalletsSlice;
