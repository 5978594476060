import { YubikeyKeyEnum } from "store/yubikey/types";

export type HandleYubikeyRegistrationArgs = {
  rawId: string;
  clientDataJSON: string;
  type: YubikeyKeyEnum;
};

export enum YubikeyErrorEnum {
  sameKeyError = "The user attempted to register an authenticator that contains one of the credentials already registered with the relying party.",
}
