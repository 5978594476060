import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getTokens } from "./actions";

import { TokenState, CurrenciesList } from "./types";
import { tokensToChainMap } from "configs/web3";

const initialState: TokenState = {
  pending: false,
  error: null,
  allTokensList: null,
};

const tokenSlice = createSlice({
  name: "tokensList",
  initialState: {
    ...initialState,
  },
  reducers: {
    resetTokensState: () => {
      return initialState;
    },
    setTokens: (state, action: PayloadAction<CurrenciesList>) => {
      state.allTokensList = tokensToChainMap(action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getTokens.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(getTokens.pending, state => {
        state.pending = true;
      })
      .addCase(getTokens.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      });
  },
});

export const { resetTokensState, setTokens } = tokenSlice.actions;

export default tokenSlice;
