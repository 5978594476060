import { useParams } from "react-router-dom";

import { useTwoFaLastUpdateCheck } from "hooks";

import { showModal } from "store/modal";
import { useAppDispatch } from "store/store";

import { ModalProps } from "store/modal/types";
import { AddWhitelistModalProps } from "components/modal/components/whitelist-add-new/types";

import { ROUTES } from "navigation/routes";

const useShowModals = ({ rootId, networkId }: { rootId: (typeof ROUTES)[keyof typeof ROUTES]; networkId?: string }) => {
  const dispatch = useAppDispatch();
  const { network, token } = useParams();

  const { callbackWithTwoFaLastUpdateCheck } = useTwoFaLastUpdateCheck({ rootId });

  const addWhitelistModal = () => {
    callbackWithTwoFaLastUpdateCheck(showAddWhitelistModal);
  };

  const showAddWhitelistModal = () => {
    const _showModal = showModal as ModalProps<AddWhitelistModalProps>;

    dispatch(
      _showModal({
        modalName: "whitelistAddNewModal",
        rootId,
        props: {
          rootId,
          network: networkId ?? (network as string),
          token: token as string,
        },
      }),
    );
  };

  return { addWhitelistModal };
};

export default useShowModals;
