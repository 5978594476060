import isEmpty from "lodash/isEmpty";

import { Networks, WalletSingleItem } from "store/wallets/types";
import { TokenAddressMap } from "store/tokens/types";

export const setNetworksWithCurrencies = (wallets: WalletSingleItem[], allTokensList: TokenAddressMap | null) => {
  const networks: Networks = {};

  wallets.length && allTokensList
    ? wallets.forEach(item => {
        if (allTokensList[item.currency.chainId] && allTokensList[item.currency.chainId][item.currency.publicCode]) {
          networks[item.currency.chainId] = {
            ...networks[item.currency.chainId],
            [item.currency.publicCode]: {
              chainId: item.currency.chainId,
              depositAddress: item.depositAddress,
              decimals: item.currency.decimals,
              balance: item.availableBalance,
              name: allTokensList[item.currency.chainId][item.currency.publicCode]["name"],
              symbol: allTokensList[item.currency.chainId][item.currency.publicCode]["symbol"],
              debt: item.debt,
              isActive: item.isActive,
              publicCode: allTokensList[item.currency.chainId][item.currency.publicCode]["publicCode"],
              logoURI: allTokensList[item.currency.chainId][item.currency.publicCode]["logoURI"],
              contractAddress: allTokensList[item.currency.chainId][item.currency.publicCode]["address"],
            },
          };
        }
      })
    : null;

  return isEmpty(networks) ? null : networks;
};
