import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getEscrowById } from "store/escrow/escrow-process/actions";
import { useAppDispatch, useAppSelector } from "store/store";
import { setCurrentStepNumber, resetEscrowProcess } from "store/escrow/escrow-process";

import { stepsNumbers } from "../constants";
import { isErrorResult } from "services";
import { ROUTES } from "navigation/routes";

export const useHandleData = () => {
  const currentEscrow = useAppSelector(state => state.escrowProcess.currentEscrow);

  const { escrowId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (escrowId) {
      dispatch(getEscrowById(escrowId)).then(response => {
        if (isErrorResult(response.payload)) {
          navigate(`/${ROUTES.escrows}`);
        }
      });
    }

    return () => {
      dispatch(resetEscrowProcess());
    };
  }, [escrowId]);

  useEffect(() => {
    if (currentEscrow?.body.step) {
      dispatch(setCurrentStepNumber(stepsNumbers[currentEscrow.body.step]));
    }
  }, [currentEscrow?.body.step]);
};
