import styled from "styled-components";
import { space, layout } from "styled-system";
import { StyledErrorAndWarningMessageProps } from "./types";

export const StyledErrorAndWarningMessage = styled.div<StyledErrorAndWarningMessageProps>`
  width: 100%;
  padding: 8px 14px;
  background: ${({ theme, bgColor }) =>
    theme.isDark ? bgColor && theme.colors.yellowLight + "33" : bgColor && theme.colors[bgColor]};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;

  ${space}
  ${layout}
`;
