import BigNumber from "bignumber.js";

import { useGetCurrencyRate } from "hooks";

import { EscrowPaidByEnum } from "store/escrow/enums";
import { prepayEscrow } from "store/escrow/escrow-process/actions";
import { showModal } from "store/modal";
import { useAppDispatch, useAppSelector } from "store/store";

import { getTokenFromNetworks, getTokenFromTokenKey } from "utils/helpers";
import { isErrorResult } from "services";
import { formatValueToBNString } from "configs/web3";

import { ModalProps } from "store/modal/types";
import { EscrowConfirmPaymentModalProps } from "components/modal/components/escrow-confirm-payment-modal/types";

export const useHandleData = () => {
  const currentEscrow = useAppSelector(state => state.escrowProcess.currentEscrow);
  const networks = useAppSelector(state => state.hotWallets.networks);
  const allTokensList = useAppSelector(state => state.tokensList.allTokensList);

  const dispatch = useAppDispatch();
  const currentCurrency =
    currentEscrow &&
    getTokenFromTokenKey(
      allTokensList,
      currentEscrow.body.head.currency.chainId,
      currentEscrow.body.head.currency.publicCode,
    );
  const symbol = currentCurrency ? currentCurrency.symbol : "";

  const currencyCode = currentEscrow ? currentEscrow.body.head.currency.publicCode : "";

  const { currentRate } = useGetCurrencyRate(currencyCode);

  const zertFee =
    currentEscrow && currentEscrow.body.head.feePaidBy === EscrowPaidByEnum.BUYER
      ? currentEscrow.body.head.fee
      : currentEscrow && currentEscrow.body.head.feePaidBy === EscrowPaidByEnum.IN_HALF
        ? BigNumber(currentEscrow.body.head.fee).div(2)
        : "0";

  const brokerFee =
    currentEscrow && currentEscrow.body.broker && currentEscrow.body.broker.commissionPaidBy === EscrowPaidByEnum.BUYER
      ? currentEscrow.body.broker.commission
      : currentEscrow &&
          currentEscrow.body.broker &&
          currentEscrow.body.broker.commissionPaidBy === EscrowPaidByEnum.IN_HALF
        ? BigNumber(currentEscrow.body.broker.commission).div(2)
        : "0";

  const amountToPay = currentEscrow
    ? formatValueToBNString(BigNumber.sum(currentEscrow.body.products[0].price, zertFee, brokerFee))
    : "0";
  const tokenBE =
    currentEscrow &&
    getTokenFromNetworks(
      networks,
      currentEscrow.body.head.currency.chainId,
      currentEscrow.body.head.currency.publicCode,
    );
  const hotWalletBalance = tokenBE?.balance ?? "0";

  const isEnoughFunds = currentEscrow && networks ? BigNumber(hotWalletBalance).gte(amountToPay) : false;

  const amountInFiat = formatValueToBNString(BigNumber(amountToPay).times(currentRate ?? 0));

  const getOneMinutesSeconds = () => {
    const secondsInOneMinutes = 60;
    return (new Date().getTime() + secondsInOneMinutes * 1000) / 1000;
  };

  const showPayModal = () => {
    if (currentEscrow) {
      dispatch(prepayEscrow(currentEscrow.body.id)).then(res => {
        if (!isErrorResult(res.payload)) {
          const _showModal = showModal as ModalProps<EscrowConfirmPaymentModalProps>;

          dispatch(
            _showModal({
              modalName: "escrowConfirmPaymentModal",
              rootId: "escrow-process",
              props: {
                amountToPay,
                symbol,
                amountInFiat,
                timer: getOneMinutesSeconds(),
              },
            }),
          );
        }
      });
    }
  };

  return {
    symbol,
    currentRate,
    zertFee,
    brokerFee,
    amountToPay,
    hotWalletBalance,
    isEnoughFunds,
    amountInFiat,
    showPayModal,
  };
};
