import { createAsyncThunk } from "@reduxjs/toolkit";

import { isErrorResult, makeApiRequest } from "services";
import { ENDPOINTS_STATEMENT, ENDPOINTS_WALLET_HISTORY } from "services";

import { ErrorResult } from "services/types";
import {
  CreateStatementPayload,
  CreateStatementResponse,
  DeleteStatementPayload,
  TransactionListResponse,
  TransactionPayload,
} from "./types";
import { SuccessResponse } from "store/wallets/types";

export const createStatement = createAsyncThunk<
  CreateStatementResponse,
  CreateStatementPayload,
  { rejectValue: ErrorResult }
>("statement/create-statement", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<CreateStatementResponse>({
    method: "POST",
    url: ENDPOINTS_STATEMENT.statements,
    data,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const getStatementTransaction = createAsyncThunk<
  TransactionListResponse,
  TransactionPayload,
  { rejectValue: ErrorResult }
>("statement/get-transactions", async (params, { rejectWithValue }) => {
  const result = await makeApiRequest<TransactionListResponse>({
    method: "GET",
    url: ENDPOINTS_WALLET_HISTORY.history,
    params,
    paramsSerializer: {
      indexes: null, // no brackets at all
    },
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const deleteStatement = createAsyncThunk<SuccessResponse, DeleteStatementPayload, { rejectValue: ErrorResult }>(
  "statement/delete-statement",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<SuccessResponse>({
      method: "DELETE",
      url: ENDPOINTS_STATEMENT.statements + data.id,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);
