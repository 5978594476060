import { SpaceProps, LayoutProps } from "styled-system";
import { Colors } from "theme/types";

export const variant = {
  error: "red",
  warning: "yellow",
  success: "green",
  info: "blue",
} as const;

export type Variant = keyof typeof variant;

export interface ErrorAndWarningMessageProps extends SpaceProps, LayoutProps, StyledErrorAndWarningMessageProps {
  message: string | React.ReactNode;
  variant?: Variant;
  textColor?: keyof Colors;
}

export interface StyledErrorAndWarningMessageProps {
  bgColor?: keyof Colors;
}
