import { useEffect } from "react";

import { useGetMitekSDK } from "hooks";
import { useHandleMitekData } from "./";

import { useTranslation } from "context";

import { partialUpdateModalProps } from "store/modal";
import store, { useAppDispatch } from "store/store";

import { MITEK_ERRORS, autoCaptureOptions, mitekActions, ELEMENTS_ID } from "configs";

import { ResponseData, MitekVerifyError, FrameProcessHint } from "types";
import { PartialUpdateModalProps } from "store/modal/types";
import { AutomaticFaceCaptureModalProps } from "components/modal/components/automatic-face-capture-modal/types";

const useHandleVerify = () => {
  const { mitekScienceSDK } = useGetMitekSDK();

  // when error appear on we close modal when SDK is started, we call this func
  const stopMitekSDK = () => {
    mitekScienceSDK?.cmd(mitekActions.sdkStop);
  };

  const { checkImageResult, showVerifyCaptureModal, handleErrorModal } = useHandleMitekData(stopMitekSDK);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (mitekScienceSDK) {
      // on load component, we setup mitek SDK with base params
      // preloadComponents is array of different actions, that we will do. ex: make photo, upload files, voice verify, etc.
      mitekScienceSDK.cmd(mitekActions.componentPreload, {
        preloadComponents: ["SELFIE"],
        mitekSDKPath: "mitekSDK/",
        options: {
          license: process.env.REACT_APP_MIPASS_LICENSE,
        },
      });

      mitekScienceSDK.on(mitekActions.sdkError, (err: MitekVerifyError) => {
        handleErrorModal(err.type);
      });
    }
  }, []);

  const handleVerify = () => {
    const _partialProps = partialUpdateModalProps as PartialUpdateModalProps<AutomaticFaceCaptureModalProps>;

    showVerifyCaptureModal();

    // this function call all time when camera is connected, it return result, which have key of warnings or error, what you must to do for successfully verify
    mitekScienceSDK?.on(mitekActions.frameProcessFeedback, (result: FrameProcessHint) => {
      const hintKey = result.key;
      const hintMessage = hintKey in MITEK_ERRORS ? MITEK_ERRORS[hintKey as keyof typeof MITEK_ERRORS] : hintKey;
      // this function show user a hint for successfully verify
      mitekScienceSDK.cmd(mitekActions.showHint, t(hintMessage));
    });

    // when camera started, before frameProcessFeedback start, we will show hint messages
    mitekScienceSDK?.on(mitekActions.frameProcessingStated, () => {
      dispatch(_partialProps({ isShowHintMessage: true }));
    });

    // if selfie made successfully, or timer is end, you receive correct or failure image, and if image is bad - you receive faceReasons, why you didn't pass face verify
    // and if result is correct, we send it to backend for save data in mitek base
    mitekScienceSDK?.on(mitekActions.frameCaptureResult, (response: ResponseData) => {
      checkImageResult(response);
    });

    // this function call when CAPTURE_AND_PROCESS_FRAME finish successfully, you see the camera, which will be in videoContainerId parent element
    mitekScienceSDK?.on(mitekActions.cameraStarted, () => {
      dispatch(_partialProps({ isCameraLoaded: true }));
    });

    // handle all error of all functions
    mitekScienceSDK?.on(mitekActions.sdkError, (err: MitekVerifyError) => {
      const isShowErrorModal = store.getState().verifyBiometric.isShowErrorModal;
      if (isShowErrorModal) {
        handleErrorModal(err.type);
      }
    });

    // start automatic verify process, access camera in browser if needed, add base options to sdk verify
    mitekScienceSDK?.cmd(mitekActions.captureAndProcessFrame, {
      mode: "AUTO_CAPTURE",
      documentType: "SELFIE",
      mitekSDKPath: "mitekSDK/",
      options: { ...autoCaptureOptions, videoContainerId: ELEMENTS_ID.cameraContainer },
    });
  };

  return { handleVerify, isLoading: !mitekScienceSDK, stopMitekSDK, showErrorModal: handleErrorModal };
};

export default useHandleVerify;
