import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { useTooltip } from "hooks";
import { useAppDispatch } from "store/store";

import {
  StyledTableRowMobileContainer,
  StyledTableRowMobileInnerContainer,
} from "components/modal/components/whitelist-side-modal/components/table/components/table-row-mobile/styled";
import { Box, FlexGap, StatusTag, Text } from "components";

import { ROUTES } from "navigation/routes";

import { hideModal } from "store/modal";

import { WidgetBuyersListTableRowProps } from "../types";

const TableRowMobile: React.FC<WidgetBuyersListTableRowProps> = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { targetRef: targetRefName, tooltip: nameTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const { targetRef: targetRefDate, tooltip: dateTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const redirectToEscrowProcess = () => {
    dispatch(hideModal());
    navigate(`/${ROUTES.escrows}/${ROUTES.escrowProcess}/${item.body.id}/`);
  };

  return (
    <StyledTableRowMobileContainer>
      <StyledTableRowMobileInnerContainer alignItems="center" onClick={redirectToEscrowProcess}>
        <FlexGap maxWidth="60%" flexDirection="column" gap="10px">
          <Text color="neutral800" textScale="body3" $fontWeight="medium" ellipsis ref={targetRefName}>
            {`${item.body.buyer.firstName} ${item.body.buyer.lastName}`}
          </Text>

          {nameTooltip}

          <Text color="neutral600" textScale="caption1" ellipsis ref={targetRefDate}>
            {moment(item.body.createdAt).format("MM/DD/YYYY")}
          </Text>

          {dateTooltip}
        </FlexGap>

        <Box>
          <StatusTag status={item.status} />
        </Box>
      </StyledTableRowMobileInnerContainer>
    </StyledTableRowMobileContainer>
  );
};

export default TableRowMobile;
