import React from "react";
import { Flex, Image, Text } from "components";
import { LabelWithIconProps } from "./types";

const LabelWithIcon: React.FC<LabelWithIconProps> = ({ text, subText, src, textProps, width, height }) => {
  const aspectRatio = width && height ? +width.slice(0, -2) / +height.slice(0, -2) : 1;

  return (
    <Flex alignItems="center">
      {src ? (
        <Flex minWidth="24px" minHeight="24px" alignItems="center">
          <Image src={src} width={width ? `${width}` : "24px"} aspectRatio={aspectRatio} />
        </Flex>
      ) : null}

      <Text
        color="neutral800"
        textScale="caption1"
        $fontWeight="medium"
        px={src ? "8px" : "2px"}
        ellipsis
        {...textProps}
        maxWidth="75%"
      >
        {text}
      </Text>

      <Text color="neutral500" textScale="caption2" $fontWeight="medium" ellipsis>
        {subText}
      </Text>
    </Flex>
  );
};

export default LabelWithIcon;
