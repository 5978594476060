import { useState, useEffect } from "react";
import { unstable_usePrompt, useNavigate } from "react-router-dom";
import { isEqual, clone } from "lodash";

import { isErrorResult } from "services";
import { useTranslation } from "context";

import { useAppDispatch, useAppSelector } from "store/store";
import { getAddressesList } from "store/addresses/actions";
import { resetCreateEscrow } from "store/escrow/create-escrow";
import { createEscrow, createWidget, getEscrowMinAmount } from "store/escrow/create-escrow/actions";

import { CreateEscrowSteps, Roles } from "../types";
import { UseHandleDataArgs } from "./types";
import { AccountType } from "store/profile/types";

import { CREATE_ESCROW_TAB_STEPS } from "../constants";
import { ROUTES } from "navigation/routes";
import { useShowModals } from "./index";

export const useHandleData = ({
  isValid,
  errors,
  values,
  initialValues,
  handleSubmit,
  resetForm,
}: UseHandleDataArgs) => {
  const user = useAppSelector(state => state.profile.user);
  const isBlacklisted = useAppSelector(state => state.profile.isBlacklisted);

  const [currentStepNumber, setCurrentStepNumber] = useState(1);
  const [currentStep, setCurrentStep] = useState(CreateEscrowSteps.role);

  const cloneInitialValues = clone(initialValues);
  const cloneValues = clone(values);
  // In order to exclude the "role" field from comparison, I give the same value in advance. In order not to introduce an error into the actual value, I carry out actions on clones
  cloneInitialValues.role.role = cloneValues.role.role;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { showWidgetCreatedModal, showEscrowCreatedModal } = useShowModals({ resetForm });

  useEffect(() => {
    return () => {
      dispatch(resetCreateEscrow());
    };
  }, []);

  useEffect(() => {
    dispatch(getAddressesList());
    dispatch(getEscrowMinAmount());

    if ((user && !user.isVerifiedKYC) || isBlacklisted) {
      navigate(ROUTES.home);
    }
  }, [user]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [values]);

  // TODO in future versions react-router-dom should return a stable version of the usePrompt hook, for now we use it as is
  unstable_usePrompt({
    when: !isEqual(cloneInitialValues, cloneValues),
    message: t(
      "If you proceed, your current progress will be lost, and you'll need to start from step 1. Are you sure you want to exit without completing the escrow creation process?",
    ),
  });

  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    if (!isEqual(cloneInitialValues, cloneValues)) {
      event.preventDefault();
      event.returnValue = "";
    }
  };

  const onBackBtnClick = () => {
    if (currentStepNumber > 1) {
      const currentStepNumberValues = Object.values(CreateEscrowSteps);

      setCurrentStep(currentStepNumberValues[currentStepNumber - 2]);
      setCurrentStepNumber(currentStepNumber - 1);
    }
  };
  const onContinueBtnClick = () => {
    if (currentStepNumber < CREATE_ESCROW_TAB_STEPS.length) {
      const currentStepNumberValues = Object.values(CreateEscrowSteps);

      setCurrentStep(currentStepNumberValues[currentStepNumber]);
      setCurrentStepNumber(currentStepNumber + 1);
    } else {
      handleSubmit();
    }
  };

  const createWidgetHandler = (formikValues: typeof values) => {
    const data = {
      products: [
        {
          name: formikValues.escrowInfo.name,
          description: formikValues.escrowInfo.description,
          url: formikValues.escrowInfo.website ? formikValues.escrowInfo.website : undefined,
          shippable: formikValues.escrowInfo.isShipped,
          price: +formikValues.currency.amount,
        },
      ],
      currencyCode: formikValues.currency.currency,
      chainId: formikValues.currency.network.toString(),
    };

    dispatch(createWidget(data)).then(res => {
      if (!isErrorResult(res.payload) && res.payload) {
        showWidgetCreatedModal(res.payload.id);
      }
    });
  };

  const createEscrowHandler = (formikValues: typeof values) => {
    const data = {
      role: formikValues.role.role,
      sellerEmail: formikValues.role.sellersEmail.toLowerCase(),
      buyerEmail: formikValues.role.buyersEmail.toLowerCase(),
      products: [
        {
          name: formikValues.escrowInfo.name,
          description: formikValues.escrowInfo.description,
          url: formikValues.escrowInfo.website ? formikValues.escrowInfo.website : undefined,
          shippable: formikValues.escrowInfo.isShipped,
          price: +formikValues.currency.amount,
        },
      ],
      feePaidBy: formikValues.currency.coversEscryptoFee,
      broker: {
        commission: +formikValues.currency.brokersCommission,
        commissionPaidBy: formikValues.currency.coversBrokersCommission,
      },
      addressId:
        formikValues.role.role === Roles.buyer && formikValues.escrowInfo.isShipped && formikValues.escrowInfo.address
          ? formikValues.escrowInfo.address
          : undefined,
      currencyCode: formikValues.currency.currency,
      chainId: formikValues.currency.network.toString(),
    };

    dispatch(createEscrow(data)).then(res => {
      if (!isErrorResult(res.payload) && res.payload) {
        showEscrowCreatedModal(res.payload.body.id);
      }
    });
  };

  const submitHandler = async (formikValues: typeof values) => {
    if (formikValues.role.role === Roles.seller && formikValues.role.createAWidget) {
      createWidgetHandler(formikValues);
    } else {
      createEscrowHandler(formikValues);
    }
  };

  const isCurrentStepValid = () => {
    return currentStepNumber === CREATE_ESCROW_TAB_STEPS.length ? isValid : !errors[currentStep as keyof typeof errors];
  };

  const isWidgetShowed =
    values.role.role === Roles.seller &&
    values.role.createAWidget &&
    user?.accountType === AccountType.BUSINESS &&
    currentStepNumber === 1 &&
    currentStep === CreateEscrowSteps.role;

  return {
    currentStepNumber,
    currentStep,
    isWidgetShowed,
    setCurrentStepNumber,
    setCurrentStep,
    onBackBtnClick,
    onContinueBtnClick,
    isCurrentStepValid,
    submitHandler,
  };
};
