import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { BITCOIN_CHAINS } from "configs/bitcoin";

import { MAINNET_CHAIN_IDS, TESTNET_CHAIN_IDS } from "configs/web3/chains";

// tokens excluded due to task #as-2648
export const mainnetBuyTokens = {
  ETH: "ETH",
  WETH: "WETH_ETHEREUM",
  WMATIC: "MATIC_ETHEREUM",
  WBTC: "WBTC_ETHEREUM",
  WXRP: "XRP_ETHEREUM",
  BUSD: "BUSD_ETHEREUM",
  EUROC: "EUROC_ETHEREUM",
  // PAX: "USDP_ETHEREUM",
  TUSD: "TUSD_ETHEREUM",
  USDC: "USDC_ETHEREUM",
  USDT: "USDT_ETHEREUM",
};

export const bscBuyTokens = {
  BNB: "BNB_BSC",
  // WETH: "ETH_BSC",
  // WBNB: "WBNB_BSC",
  WMATIC: "MATIC_BSC",
  WAVAX: "AVAX_BSC",
  // WBTC: "WBTC_BSC",
  // WXRP: "XRP_BSC",
  BUSD: "BUSD_BSC",
  USDC: "USDC_BSC",
  USDT: "USDT_BSC",
};

export const polygonBuyTokens = {
  MATIC: "MATIC_POLYGON",
  // WETH: "WETH_POLYGON",
  // WMATIC: "WMATIC_POLYGON",
  // WAVAX: "AVAX_POLYGON",
  WBTC: "WBTC_POLYGON",
  BUSD: "BUSD_POLYGON",
  TUSD: "TUSD_POLYGON",
  USDC: "USDC_POLYGON",
  USDT: "USDT_POLYGON",
};

export const avaxBuyTokens = {
  AVAX: "AVAX_AVAXC",
  USDC: "USDC_AVAXC",
  // WAVAX: "WAVAX_AVAXC",
  BUSD: "BUSD_AVAXC",
  USDT: "USDT_AVAXC",
};

export const btcBuyTokens = {
  BTC: "BTC",
};

export const solanaBuyTokens = {
  SOL: "SOL",
  USDT: "USDT_SOLANA",
  USDC: "USDC_SOLANA",
};

export const buyTokensList = {
  [MAINNET_CHAIN_IDS.MAINNET]: mainnetBuyTokens,
  [MAINNET_CHAIN_IDS.BSC]: bscBuyTokens,
  [MAINNET_CHAIN_IDS.POLYGON]: polygonBuyTokens,
  [MAINNET_CHAIN_IDS.AVAX]: avaxBuyTokens,
  [BITCOIN_CHAINS.BTC]: btcBuyTokens,

  [TESTNET_CHAIN_IDS.SEPOLIA]: mainnetBuyTokens,
  [TESTNET_CHAIN_IDS.BSC_TEST]: bscBuyTokens,
  [TESTNET_CHAIN_IDS.POLYGON_MUMBAI]: polygonBuyTokens,
  [TESTNET_CHAIN_IDS.FUJI]: avaxBuyTokens,
  [BITCOIN_CHAINS.BTC_TEST]: btcBuyTokens,

  [WalletAdapterNetwork.Devnet]: solanaBuyTokens,
  [WalletAdapterNetwork.Mainnet]: solanaBuyTokens,
};
