import * as yup from "yup";

import { useTranslation } from "context";

import { REGEX } from "configs";

import { InitialValues } from "./types";

const useValidationSchema = () => {
  const initialValues: InitialValues = {
    name: "",
  };

  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required(t("This field is required"))
      .matches(
        REGEX.yubikeyDeviceName,
        t("The name must consist of numbers or letters, and be between %from% and %to% characters long", {
          from: 3,
          to: 20,
        }),
      ),
  });

  return { validationSchema, initialValues };
};

export default useValidationSchema;
