import { tabsIndex } from "configs";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { UserWalletsStatusEnum } from "store/profile/types";

import { useAppDispatch, useAppSelector } from "store/store";
import { setWalletsHistoryPayload } from "store/wallets/wallets-history";
import { getWalletsHistory } from "store/wallets/wallets-history/actions";
import { selectCombinedData } from "./selectors";

const useHandlePayload = () => {
  const { size, page, chainId, currencyCode, type, dateFrom, dateTo, data, count, pending, user } =
    useAppSelector(selectCombinedData);

  const dispatch = useAppDispatch();

  const { activeTab } = useOutletContext<{ activeTab: number }>();

  useEffect(() => {
    if (activeTab === tabsIndex.wallets.history && user?.walletsStatus === UserWalletsStatusEnum.CREATED) {
      const data = {
        page,
        size,
        chainId,
        currencyCode,
        type,
        dateFrom,
        dateTo,
      };

      dispatch(getWalletsHistory(data));
    }
  }, [page, activeTab, type, chainId, currencyCode, dateFrom, dateTo, user?.walletsStatus]);

  const handlePageClick = (selectedItem: { selected: number }) => {
    const currentPage = selectedItem.selected + 1;
    dispatch(setWalletsHistoryPayload({ page: currentPage }));
  };

  return { data, count, pending, page, size, handlePageClick };
};

export default useHandlePayload;
