import React from "react";

import { Text } from "components";
import { StyledDescription } from "./styled";

import { useTranslation } from "context";

import { EscrowDescriptionProps } from "./types";

const EscrowDescription: React.FC<EscrowDescriptionProps> = ({ currentStep, role, stepConfig, descriptionConfig }) => {
  const { t } = useTranslation();

  const descriptionValues = Object.values(descriptionConfig);

  return (
    <StyledDescription gap="12px 36px" pb="24px" mb="24px" flexDirection={{ _: "column", tablet: "row" }}>
      <Text textScale={{ _: "body3", tablet: "body2" }} $fontWeight="medium" color="neutral600" minWidth="fit-content">
        {t(stepConfig[currentStep - 1].text)}
      </Text>

      {role && (
        <Text textScale="caption2" color="neutral500" mt="8px">
          {t(descriptionValues[currentStep - 1][role])}
        </Text>
      )}
    </StyledDescription>
  );
};

export default EscrowDescription;
