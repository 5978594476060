import * as yup from "yup";
import BigNumber from "bignumber.js";

import { useTranslation } from "context";
import { useAppSelector } from "store/store";
import { useGetFiatSymbolAndAbbreviation, useGetCurrencyRate } from "hooks";

import { formatValueToBNString } from "configs/web3";
import { getTokenFromNetworks } from "utils/helpers";

import { MIN_AMOUNT_TO_WITHDRAW } from "pages/main/cold-storage/constants";

import { InitialValuesType } from "./types";
import { selectCombinedData } from "./selectors";

export const initialValues: InitialValuesType = {
  amount: "",
};

export const useValidationSchema = () => {
  const { coldWallet, networks, coldWalletFee, minAmountToCreateInFiat } = useAppSelector(selectCombinedData);

  const { t } = useTranslation();
  const { fiatSymbol } = useGetFiatSymbolAndAbbreviation();

  const currencyCode = coldWallet.currency.publicCode;

  const { currentRate } = useGetCurrencyRate(currencyCode);
  const vaultBalance = coldWallet.availableBalance;
  const coldWalletWithdrawFee = coldWalletFee.withdrawalFee.amountInFiat
    ? coldWalletFee.withdrawalFee.amountInFiat
    : "0";
  const subscriptionFeeAmount = coldWalletFee.subscriptionFee.amountInFiat
    ? coldWalletFee.subscriptionFee.amountInFiat
    : "0";

  const tokenBE = getTokenFromNetworks(networks, coldWallet.currency.chainId, coldWallet.currency.publicCode);
  const tokenBalance = tokenBE?.balance ?? "0";

  const validationSchema = yup.object().shape({
    amount: yup
      .string()
      .required(t("This field is required"))
      .test(
        "minAmountTest",
        `${t("Withdraw amount should be greater than %amount%", {
          amount: `${fiatSymbol}${MIN_AMOUNT_TO_WITHDRAW}`,
        })}`,
        value => {
          const _value = value ?? "0";

          return BigNumber(formatValueToBNString(_value)).gt(MIN_AMOUNT_TO_WITHDRAW);
        },
      )
      .test("minAmountTest", `${t("Insufficient funds")}`, value => {
        const _value = value ?? "0";

        return BigNumber(formatValueToBNString(_value)).lte(vaultBalance);
      })
      .test(
        "amountTest",
        `${t(
          "Your vault balance after withdrawal must be greater than %amount%, otherwise you must withdraw the entire amount",
          {
            amount: `${fiatSymbol}${BigNumber(minAmountToCreateInFiat).toFormatExtended(2)}`,
          },
        )}`,
        value => {
          const _value = value ?? "0";
          const rest = formatValueToBNString(BigNumber(vaultBalance).minus(_value));
          const restInFee = formatValueToBNString(BigNumber(rest).times(currentRate));

          if (!BigNumber(rest).eq(0)) {
            return BigNumber(restInFee).gte(minAmountToCreateInFiat);
          }
          return true;
        },
      )
      .test(
        "hotWalletBalanceTest",
        // Monthly Subscription is temporarily disabled.
        // `${t("Your wallet balance must be greater than Monthly Subscription plus Withdrawal fee")}`,
        `${t("Your wallet balance must be greater than Withdrawal fee")}`,
        () => {
          const subscriptionFee = formatValueToBNString(
            BigNumber(coldWalletWithdrawFee).div(currentRate).plus(subscriptionFeeAmount),
          );

          return BigNumber(tokenBalance).gte(subscriptionFee);
        },
      ),
  });

  return { validationSchema, initialValues };
};
