import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "green", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 72 84" color="transparent" {...props} stroke={theme.colors[color]}>
      <g opacity="0.25" filter="url(#filter0_f_476_27565)">
        <rect x="18" y="49" width="36" height="17" rx="4" fill={theme.colors.green} />
      </g>
      <circle cx="36" cy="36" r="28" fill={theme.colors.greenLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.green} strokeOpacity="0.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 34.7732C48 41.5847 42.8805 47.9537 36 49.5002C29.1195 47.9537 24 41.5847 24 34.7732V29.4227C24 28.2047 24.7365 27.1067 25.8645 26.6462L33.3645 23.5772C35.0535 22.8857 36.9465 22.8857 38.6355 23.5772L46.1355 26.6462C47.2635 27.1082 48 28.2047 48 29.4227V34.7732V34.7732Z"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.875 33.1875L35.25 38.8125L31.875 35.4375"
        stroke={theme.colors.green}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.green} strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_476_27565"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_476_27565" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
