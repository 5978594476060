export const DESCRIPTION_LENGTH = 200;
export const MIN = 3;
export const MAX = 45;
export const BROKERS_COMMISSION_PERCENTAGE = 0.2;

const ESCROW_INFO_DESCRIPTION =
  "Give this escrow a name and add a description of the object of this transaction. You can also add a URL if this object is available on a website. Please, select if an object of purchase is a physical thing that needs to be shipped. Later, Seller will be required to provide a shipping code for the tracking purposes.";
const CURRENCY_DESCRIPTION = "Please select a currency and enter the amount of the transaction.";

export const CREATE_ESCROW_TAB_STEPS = [
  {
    text: "Role",
  },
  {
    text: "Escrow Info",
  },
  {
    text: "Currency",
  },
  {
    text: "Preview",
  },
];

export const CREATE_ESCROW_DESCRIPTION = {
  role: {
    buyer:
      "Select your role in this escrow. Adding other party's email is required so that they will receive an invitation to join.",
    seller:
      "Select your role in this escrow. Adding other party's email is required so that they will receive an invitation to join. Widget can be encoded to allow your customers to purchase an item with a fixed amount of crypto and secure the funds until they confirm the delivery. It can be placed at the checkout on your website.",
    broker:
      "Select your role in this escrow. Adding other party's email is required so that they will receive an invitation to join.",
  },
  escrowInfo: {
    buyer: ESCROW_INFO_DESCRIPTION,
    seller: ESCROW_INFO_DESCRIPTION,
    broker: ESCROW_INFO_DESCRIPTION,
  },
  currency: {
    buyer: CURRENCY_DESCRIPTION,
    seller: CURRENCY_DESCRIPTION,
    broker: CURRENCY_DESCRIPTION,
  },
  preview: {
    buyer: "Please check escrow details and create an Escrow.",
    seller: "Please check escrow detail and create an Escrow. You will be able to copy the widget code afterwards.",
    broker: "Please check escrow details and create an Escrow.",
  },
};
