import useHandleFilters from "./use-handle-filters";
import { useSetActiveTab } from "hooks";

import { setBuyTab } from "store/buy-crypto";
import { useAppDispatch, useAppSelector } from "store/store";

import { TABS, tabsIndex } from "configs";

export const useHandleTabAndFilter = () => {
  const activeTab = useAppSelector(state => state.buyCrypto.activeTab);
  const pending = useAppSelector(state => state.buyCrypto.marketInfo.pending);

  const dispatch = useAppDispatch();

  const { selectedOption, options, handleSelectChange } = useHandleFilters();

  const isActiveTabBuyCrypto = activeTab === tabsIndex.buyCrypto.buy;

  const onTabClick = (index: number) => {
    dispatch(setBuyTab(index));
  };

  useSetActiveTab(tabsIndex.buyCrypto, setBuyTab);

  return {
    tabs: TABS.buy,
    activeTab,
    pending,
    options,
    selectedOption,
    isSelectShow: isActiveTabBuyCrypto,
    onTabClick,
    handleSelectChange,
  };
};
