import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";
import { ColdStorageWithdrawModalProps } from "components/modal/components/cold-storage-withdraw-modal/types";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => (state.modal.props as unknown as ColdStorageWithdrawModalProps).coldWallet,
    (state: AppState) => state.hotWallets.networks,
    (state: AppState) => state.walletsFee.coldWalletFee,
    (state: AppState) => state.walletsFee.fiatFeeColdStorage.minAmountToCreate.inFiat,
  ],
  (coldWallet, networks, coldWalletFee, minAmountToCreateInFiat) => ({
    coldWallet,
    networks,
    coldWalletFee,
    minAmountToCreateInFiat,
  }),
);
