import React from "react";

import { SingleSelect } from "components";

import { SingleOption } from "components/selects/single-select/types";
import { SelectProps } from "../types";

const Select: React.FC<SelectProps> = ({
  path,
  item,
  error,
  touched,
  currentValue,
  setFieldValue,
  setFieldTouched,
}) => {
  const selectedValue = item.options?.find(({ value }) => value === currentValue) ?? null;

  return (
    <SingleSelect
      options={item.options as SingleOption[]}
      onChange={async option => await setFieldValue(path, (option as SingleOption).value)}
      isTouched={touched}
      placeholder={item.label}
      onBlur={async () => await setFieldTouched(path, true)}
      error={error}
      value={selectedValue}
      isSearchable
      withError
      label={item.label}
    />
  );
};

export default Select;
