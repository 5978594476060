import React, { useEffect } from "react";
import BigNumber from "bignumber.js";

import {
  useForm,
  useDebounce,
  useGetColdWalletDebtAndFees,
  useGetFiatSymbolAndAbbreviation,
  useAmountToDepositHandlers,
  useTokenBE,
  useGetCurrencyRate,
} from "hooks";
import { useValidationSchema, useShowModals, useHandleWithdraw } from "./hooks";

import { Flex, Commissions, WalletErrorMessage, SlideToConfirm, Column, AmountToDeposit } from "components";

import { useTranslation } from "context";
import { formatValueToBNString } from "configs/web3";

import { useAppDispatch, useAppSelector } from "store/store";
import { setWithdrawPayload } from "store/wallets/cold-wallets";
import { estimateFiatFeeColdStorage } from "store/wallets/wallets-fee/actions";

import { WITHDRAW_MODAL_COMMISSION_ITEMS } from "components/modal/components/cold-storage-withdraw-modal/constants";

import { WithdrawSectionProps } from "./types";
import { BalancePositionEnum } from "components/amount-to-deposit/types";

const WithdrawSection: React.FC<WithdrawSectionProps> = ({ isDebt, coldWallet }) => {
  const pending = useAppSelector(state => state.coldWallets.pending);
  const coldWalletFee = useAppSelector(state => state.walletsFee.coldWalletFee);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { validationSchema, initialValues } = useValidationSchema();
  const { setFieldValue, setFieldTouched, validateForm, errors, isValid, touched, values } = useForm({
    initialValues,
    validationSchema,
    onSubmit() {},
  });
  const { token } = useTokenBE(coldWallet.currency.chainId, coldWallet.currency.publicCode);
  const { fiatSymbol } = useGetFiatSymbolAndAbbreviation();

  const currencyCode = coldWallet.currency.publicCode;

  const { currentRate } = useGetCurrencyRate(currencyCode);
  const tokenBalance = token?.balance ?? "0";

  const { callNextModal, callSuccessModal } = useShowModals();
  const { isConfirmed, handleConfirmWithdraw } = useHandleWithdraw(callNextModal, callSuccessModal);

  const { onBlur, onUserInput, isColdStorageMaxBtnDisabled } = useAmountToDepositHandlers<typeof initialValues>({
    setFieldTouched,
    setFieldValue,
    tokenBalance,
    currentRate,
  });

  const debounceAmount = useDebounce(formatValueToBNString(values.amount), 1000);
  useGetColdWalletDebtAndFees(coldWallet.currency.publicCode, coldWallet.currency.chainId, debounceAmount);

  useEffect(() => {
    dispatch(estimateFiatFeeColdStorage({ currencyCode }));
  }, [currentRate]);

  // Rates updates once in a minute so formik should be updated
  // currentBalance could be updated via sockets so the formik should be updated
  useEffect(() => {
    validateForm();
  }, [currentRate, tokenBalance, coldWalletFee]);

  const getCommissionsList = () => {
    const commissionsList = [...WITHDRAW_MODAL_COMMISSION_ITEMS];

    // Monthly Subscription is temporarily disabled.
    commissionsList[0].commissionsValue = coldWalletFee.withdrawalFee.amountInFiat
      ? `${fiatSymbol}${BigNumber(coldWalletFee.withdrawalFee.amountInFiat).toFormatExtended(2)}`
      : `${fiatSymbol}${BigNumber(0).toFormatExtended(2)}`;
    commissionsList[0].pending = coldWalletFee.pending;

    // const commissionsList = [...WITHDRAW_MODAL_COMMISSION_ITEMS];
    // commissionsList[0].commissionsValue = coldWalletFee.subscriptionFee.amount
    //     ? `${BigNumber(coldWalletFee.subscriptionFee.amount).toFormatExtended(8)} ${coldWallet.currency.publicCode}`
    //     : `${BigNumber(0).toFormatExtended(8)} ${coldWallet.currency.publicCode}`;
    // commissionsList[0].pending = coldWalletFee.pending;
    //
    // commissionsList[1].commissionsValue = coldWalletFee.withdrawalFee
    //     ? `$${BigNumber(coldWalletFee.withdrawalFee).toFormatExtended(2)}`
    //     : `$${BigNumber(0).toFormatExtended(2)}`;
    // commissionsList[1].pending = coldWalletFee.pending;

    return commissionsList;
  };

  const onConfirmCallback = async (formikValues: typeof values) => {
    if (token) {
      const data = {
        amount: formikValues.amount,
        currencyCode: token.publicCode,
        chainId: token.chainId,
      };
      dispatch(
        setWithdrawPayload({
          withdrawPayload: data,
          showModalArgs: { amount: values.amount, token, marketInfoPrice: coldWallet.marketInfo[0].price },
        }),
      );
      handleConfirmWithdraw(data);
    }
  };

  const setAmount = async () => {
    await setFieldValue("amount", coldWallet.availableBalance);
    await setFieldTouched("amount", true);
  };

  return (
    <Column flex="1" justifyContent="space-between">
      <Column>
        <AmountToDeposit
          value={values.amount}
          title="Amount to withdraw"
          balanceTitle="Wallet Balance"
          onChangeInput={onUserInput}
          onBlur={onBlur}
          handleMaxButtonClick={setAmount}
          balancePosition={BalancePositionEnum.bottom}
          isError={!!errors.amount}
          isTouched={!!touched.amount}
          currency={token}
          tokenBalance={tokenBalance}
          isAmountDisabled={isDebt ?? undefined}
          isMaxButtonDisabled={isColdStorageMaxBtnDisabled}
          isHaveFiatEquivalent
          currentRate={currentRate}
        />

        <Commissions commissionsList={getCommissionsList()} mt="32px" width="100%" />

        {!!errors["amount"] && touched["amount"] ? <WalletErrorMessage message={errors["amount"]} /> : null}

        {coldWalletFee.error ? (
          <WalletErrorMessage
            mt="10px"
            message={t(
              "Sorry, something went wrong while estimating fee, try entering amount of the transaction again",
            )}
          />
        ) : null}
      </Column>

      <Flex mt="12px">
        <SlideToConfirm
          isConfirmed={isConfirmed}
          onConfirmCallback={() => onConfirmCallback(values)}
          disabled={!isValid || coldWalletFee.isDisable}
          isLoading={pending || coldWalletFee.pending}
        />
      </Flex>
    </Column>
  );
};

export default WithdrawSection;
