import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 72 72" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_1906_68559)">
        <rect x="18" y="49" width="36" height="12" rx="4" fill={theme.colors.primary} />
      </g>
      <circle cx="36" cy="36" r="28" fill={theme.colors.primaryLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.primary} strokeOpacity="0.5" />
      <path
        d="M29.7735 29.6031C29.9925 29.8221 29.9925 30.1791 29.7735 30.3981C29.5545 30.6171 29.1975 30.6171 28.9785 30.3981C28.7595 30.1791 28.7595 29.8221 28.9785 29.6031C29.1975 29.3826 29.553 29.3826 29.7735 29.6031"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.3975 29.6031C36.6165 29.8221 36.6165 30.1791 36.3975 30.3981C36.1785 30.6171 35.8215 30.6171 35.6025 30.3981C35.3835 30.1791 35.3835 29.8221 35.6025 29.6031C35.8215 29.3826 36.1785 29.3826 36.3975 29.6031"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.0235 29.6031C43.2425 29.8221 43.2425 30.1791 43.0235 30.3981C42.8045 30.6171 42.4475 30.6171 42.2285 30.3981C42.0095 30.1791 42.0095 29.8221 42.2285 29.6031C42.4475 29.3826 42.803 29.3826 43.0235 29.6031"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.5 37.5H25.5C23.8425 37.5 22.5 36.1575 22.5 34.5V25.5C22.5 23.8425 23.8425 22.5 25.5 22.5H46.5C48.1575 22.5 49.5 23.8425 49.5 25.5V30"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.0892 41.5455V38.9445C47.0892 37.3185 45.7707 36 44.1447 36V36C42.5187 36 41.2002 37.3185 41.2002 38.9445V41.5455"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 41.5469H40.5C39.672 41.5469 39 42.2189 39 43.0469V47.9999C39 48.8279 39.672 49.4999 40.5 49.4999H48C48.828 49.4999 49.5 48.8279 49.5 47.9999V43.0469C49.5 42.2174 48.828 41.5469 48 41.5469Z"
        fill={theme.colors.primary}
        fillOpacity="0.14"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.primary} strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_1363_41548"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_1363_41548" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
