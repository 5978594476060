import React from "react";

import { useValidationSchema } from "./hooks";
import { useForm } from "hooks";
import { useTranslation } from "context";

import { Text, Flex, InputGroup, Input, Box, Button } from "components";
import { StyledModalWrapper } from "../styled";
import { ModalForgotPasswordIcon, ModalSuccessIcon } from "components/svg";
import CloseModalWrapper from "../close-modal-wrapper";

import { isErrorResult } from "services";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal, showModal } from "store/modal";
import { forgotPassword } from "store/auth/actions";

import { ActionModalProps } from "../action-modal/types";
import { ModalProps } from "store/modal/types";

const ForgotPasswordModal: React.FC = () => {
  const pending = useAppSelector(state => state.auth.pending);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { validationSchema, initialValues } = useValidationSchema();

  const { fieldProps, handleSubmit, errors, isValid, touched, values } = useForm({
    initialValues,
    validationSchema,
    onSubmit(values) {
      submitHandler(values);
    },
  });

  const submitHandler = (formValues: typeof values) => {
    const data = {
      email: formValues.email.toLowerCase(),
    };

    dispatch(forgotPassword(data)).then(res => {
      if (!isErrorResult(res.payload)) {
        closeModalHandler();
        showSuccessModal();
      }
    });
  };

  const showSuccessModal = () => {
    const _showModal = showModal as ModalProps<ActionModalProps>;

    dispatch(
      _showModal({
        modalName: "actionModal",
        rootId: "login",
        props: {
          icon: <ModalSuccessIcon height="72px" width="72px" />,
          title: t("Link sent"),
          text: `${t("Link was sent to")}\n ${values.email}`,
          btnArr: [
            {
              buttonText: t("Done"),
              callback: closeModalHandler,
            },
          ],
        },
      }),
    );
  };

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} />

      <Flex mt="26px" mb="16px" justifyContent="center" width="100%">
        <ModalForgotPasswordIcon width="72px" height="72px" />
      </Flex>

      <Text color="neutral600" textAlign="center" textScale="body2">
        {t("Reset password")}
      </Text>

      <Text color="neutral600" mt="12px" mb="24px" textAlign="center" textScale="caption1">
        {t("Enter your email address and we will send you a link to create a new password")}
      </Text>

      <Box width="100%">
        <form onSubmit={handleSubmit}>
          <InputGroup label={t("Email")} error={errors.email} isTouched={touched.email} mb="12px">
            <Input placeholder={t("Email")} {...fieldProps("email")} />
          </InputGroup>

          <Flex
            flexDirection={{ _: "column", tablet: "row" }}
            mb={{ _: "20px", tablet: "30px" }}
            justifyContent="space-between"
          >
            <Button width={{ _: "100%", tablet: "calc(50% - 6px)" }} variant="outline" onClick={closeModalHandler}>
              {t("Cancel")}
            </Button>

            <Button
              mt={{ _: "14px", tablet: "0px" }}
              width={{ _: "100%", tablet: "calc(50% - 6px)" }}
              type="submit"
              isLoading={pending}
              disabled={!isValid}
            >
              {t("Confirm")}
            </Button>
          </Flex>
        </form>
      </Box>
    </StyledModalWrapper>
  );
};

export default ForgotPasswordModal;
