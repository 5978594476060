import React from "react";
import moment from "moment";
import { createPortal } from "react-dom";

import { useTranslation } from "context";
import { parseAndFormatText } from "utils/helpers";

import { Text, FlexGap, Button } from "components";
import CloseModalWrapper from "../../close-modal-wrapper";
import { StyledModalContainer } from "../../modal-wrapper/styled";
import { StyledModalWrapper } from "../../styled";
import { StyledPortalWrapper } from "../styled";

import { useAppDispatch, useAppSelector } from "store/store";
import { resetCurrentNotification } from "store/notifications";

import { ROUTES } from "navigation/routes";

const useShowModal = () => {
  const currentNotification = useAppSelector(state => state.notifications.currentNotification);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const closeCurrentNotificationModal = () => {
    dispatch(resetCurrentNotification());
  };

  const rootContainer = document.getElementById(ROUTES.home);

  const onParentContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    return event.stopPropagation();
  };

  const portal = () => {
    if (rootContainer) {
      return createPortal(
        <StyledPortalWrapper onClick={closeCurrentNotificationModal}>
          <StyledModalContainer>
            <StyledModalWrapper onClick={onParentContainerClick} $backgroundColor="neutral100">
              <CloseModalWrapper closeModalHandler={closeCurrentNotificationModal} mb="14px" />

              <FlexGap gap="14px" width="100%" flexDirection="column" alignItems="start" mb="28px">
                <Text textScale="caption2" color="neutral500">
                  {moment(currentNotification?.createdAt).format("MM/DD/YY h:mm A")}
                </Text>

                <Text textScale="caption1" color="neutral600" maxHeight="350px" overflowX="auto">
                  {parseAndFormatText(currentNotification?.text ?? "")}
                </Text>
              </FlexGap>

              <Button
                variant="outline"
                width={{ _: "252px", laptop: "170px" }}
                mb="10px"
                onClick={closeCurrentNotificationModal}
              >
                {t("Close")}
              </Button>
            </StyledModalWrapper>
          </StyledModalContainer>
        </StyledPortalWrapper>,

        rootContainer,
      );
    }
  };

  return { portal, closeCurrentNotificationModal };
};

export default useShowModal;
