import React from "react";

import { Column } from "components";
import { Logo } from "components/svg";
import { AnimatedWord } from "./components";

import { LogoLoaderTypes } from "./types";

const LogoLoader: React.FC<LogoLoaderTypes> = ({ isAnimate = true, ...props }) => {
  return (
    <Column justifyContent="center" alignItems="center" height="100vh" {...props}>
      <Logo width="44px" />
      {/* Can't translate this word because LogoLoader is not inside Language Context */}
      <AnimatedWord word="ZERT" isAnimate={isAnimate} />
    </Column>
  );
};

export default LogoLoader;
