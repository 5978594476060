import { HotWalletTransactionsTypeEnum, TransactionOperationEnum } from "store/wallets/enums";

export const HEADERS = [
  {
    width: "25%",
    text: "Date and time",
  },
  {
    width: "15%",
    text: "Transaction type",
  },
  {
    width: "12%",
    text: "Transaction class",
  },
  {
    width: "28%",
    text: "Network / token",
  },
  {
    width: "10%",
    text: "Amount",
  },
  {
    width: "10%",
    text: "Fees",
  },
];

export const statementTransactionTypeSelectList = [
  { value: HotWalletTransactionsTypeEnum.CRYPTO_PURCHASE, label: "Wallet: Buy" },
  { value: HotWalletTransactionsTypeEnum.WALLET_WITHDRAW, label: "Wallet: Withdrawals" },
  { value: HotWalletTransactionsTypeEnum.WALLET_DEPOSIT, label: "Wallet: Deposits" },
  { value: HotWalletTransactionsTypeEnum.SWAP_INCOME, label: "Swap Income" },
  { value: HotWalletTransactionsTypeEnum.SWAP_OUTCOME, label: "Swap Outcome" },
  { value: HotWalletTransactionsTypeEnum.ESCROW_BUYER_OUTCOME, label: "Escrow: Buyer’s outcome" },
  { value: HotWalletTransactionsTypeEnum.ESCROW_SELLER_INCOME, label: "Escrow: Seller’s income" },
  { value: HotWalletTransactionsTypeEnum.ESCROW_BROKER_INCOME, label: "Escrow: Broker’s income" },
  { value: HotWalletTransactionsTypeEnum.ESCROW_BUYER_REFUND, label: "Escrow: Buyer’s refunds" },
  { value: HotWalletTransactionsTypeEnum.SWAP_INCOME_ADDITIONAL, label: "Miscellaneous adjustments" },
  {
    value: HotWalletTransactionsTypeEnum.COLD_WALLET_SUBSCRIPTION_FEE_REGULAR,
    label: "Cold Storage: Monthly subscription",
  },
  { value: HotWalletTransactionsTypeEnum.WITHDRAW_FROM_COLD_WALLET, label: "Cold Storage: Withdrawals" },
  { value: HotWalletTransactionsTypeEnum.DEPOSIT_TO_COLD_WALLET, label: "Cold Storage: Deposits" },
];

export const statementTransactionText = {
  [HotWalletTransactionsTypeEnum.CRYPTO_PURCHASE]: "Wallet: Buy",
  [HotWalletTransactionsTypeEnum.WALLET_WITHDRAW]: "Wallet: Withdrawals",
  [HotWalletTransactionsTypeEnum.WALLET_DEPOSIT]: "Wallet: Deposits",
  [HotWalletTransactionsTypeEnum.SWAP_INCOME]: "Swap Income",
  [HotWalletTransactionsTypeEnum.SWAP_OUTCOME]: "Swap Outcome",
  [HotWalletTransactionsTypeEnum.ESCROW_BUYER_OUTCOME]: "Escrow: Buyer’s outcome",
  [HotWalletTransactionsTypeEnum.ESCROW_SELLER_INCOME]: "Escrow: Seller’s income",
  [HotWalletTransactionsTypeEnum.ESCROW_BROKER_INCOME]: "Escrow: Broker’s income",
  [HotWalletTransactionsTypeEnum.ESCROW_BUYER_REFUND]: "Escrow: Buyer’s refunds",
  [HotWalletTransactionsTypeEnum.SWAP_INCOME_ADDITIONAL]: "Miscellaneous adjustments",
  [HotWalletTransactionsTypeEnum.OPERATIONAL_GAS_STATION]: "Miscellaneous adjustments",
  [HotWalletTransactionsTypeEnum.COLD_WALLET_SUBSCRIPTION_FEE_REGULAR]: "Cold Storage: Monthly subscription",
  [HotWalletTransactionsTypeEnum.COLD_WALLET_SUBSCRIPTION_FEE_DEBT]: "Cold Storage: Monthly subscription",
  [HotWalletTransactionsTypeEnum.WITHDRAW_FROM_COLD_WALLET]: "Cold Storage: Withdrawals",
  [HotWalletTransactionsTypeEnum.DEPOSIT_TO_COLD_WALLET]: "Cold Storage: Deposits",
};

export const transactionOperationText = {
  [TransactionOperationEnum.ESCROW]: "Escrow",
  [TransactionOperationEnum.DEPOSIT]: "Deposit",
  [TransactionOperationEnum.WITHDRAW]: "Withdraw",
  [TransactionOperationEnum.DEPOSIT_TO_COLD]: "Deposit to cold wallet",
  [TransactionOperationEnum.WITHDRAW_FROM_COLD]: "Withdraw from cold wallet",
  [TransactionOperationEnum.SUBSCRIPTION_FEE]: "Subscription Fee",
  [TransactionOperationEnum.SWAP]: "Swap",
  [TransactionOperationEnum.UNKNOWN]: "Unknown",
};
