import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";

export const selectCombinedDataForUseDeleteItem = createSelector(
  [
    (state: AppState) => state.whitelist.whitelistPayload.page,
    (state: AppState) => state.whitelist.whitelistPayload.size,
    (state: AppState) => state.whitelist.whitelistPayload.search,
    (state: AppState) => state.whitelist.whitelistPayload.chainId,
  ],
  (page, size, search, chainId) => ({
    page,
    size,
    search,
    chainId,
  }),
);

export const selectCombinedDataForUseHandlePayload = createSelector(
  [
    (state: AppState) => state.whitelist.whitelistInfo.pending,
    (state: AppState) => state.whitelist.whitelistInfo.data,
    (state: AppState) => state.whitelist.whitelistInfo.count,
    (state: AppState) => state.whitelist.whitelistPayload.page,
    (state: AppState) => state.whitelist.whitelistPayload.size,
    (state: AppState) => state.whitelist.whitelistPayload.chainId,
    (state: AppState) => state.whitelist.whitelistPayload.search,
    (state: AppState) => state.profile.user,
  ],
  (pending, data, count, page, size, chainId, search, user) => ({
    pending,
    data,
    count,
    page,
    size,
    chainId,
    search,
    user,
  }),
);
