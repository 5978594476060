import { createAsyncThunk } from "@reduxjs/toolkit";
import { isErrorResult, makeApiRequest, ENDPOINTS_WALLET_HISTORY } from "services";
import { ErrorResult } from "services/types";
import { HistoryResponse, HistoryPayload, ColdHistoryResponse, ColdWalletHistoryPayload } from "./types";

export const getWalletsHistory = createAsyncThunk<HistoryResponse, HistoryPayload, { rejectValue: ErrorResult }>(
  "wallets-history/history",
  async (params, { rejectWithValue }) => {
    const result = await makeApiRequest<HistoryResponse>({
      method: "GET",
      url: ENDPOINTS_WALLET_HISTORY.history,
      params,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getColdWalletsHistory = createAsyncThunk<
  ColdHistoryResponse,
  ColdWalletHistoryPayload,
  { rejectValue: ErrorResult }
>("wallets-history/cold-wallet-history", async (params, { rejectWithValue }) => {
  const result = await makeApiRequest<ColdHistoryResponse>({
    method: "GET",
    url: ENDPOINTS_WALLET_HISTORY.coldWalletHistory,
    params,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});
