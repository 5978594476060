export const ENDPOINTS_AUTH = {
  login: "/auth/login/",
  googleLoginRegister: "/auth/google-oauth/",
  appleLoginRegister: "/auth/apple-oauth/",
  register: "/auth/register/",
  verifyEmail: "/auth/verify-email/",
  forgotPassword: "/auth/forgot-password/",
  resetPassword: "/auth/reset-password/",
  loginToZendesk: "/auth/zendesk-login/",
  getZendeskToken: "/auth/zendesk-token/",
  sendCodeForTwoFaCheck: "/auth/two-fa/check/",
  verifyTwoFaCheck: "/auth/two-fa/confirm/",
  logout: "/auth/logout/",
};

export const ENDPOINTS_PROFILE = {
  getMyProfile: "/users/me/",
  generateSecret: "/users/two-fa/generate-secret/",
  verifySecret: "/users/two-fa/verify/",
  resetTwoFa: "/users/two-fa/reset/",
  highRiskResetTwoFa: "/users/two-fa/high-risk/reset/",
  confirmResetTwoFa: "/users/two-fa/reset-confirm/",
  confirmHighRiskResetTwoFa: "/users/two-fa/high-risk/reset-confirm/",
  changeAvatar: "/users/image/",
  addresses: "/users/addresses/",
  updateSecuritySettings: "/users/settings/security/",
  updateSettings: "/users/settings/",
  enrollMitekVerify: "/users/settings/mitek/enroll/confirm/",
  sendCodeForTwoFaCheck: "/users/settings/mitek/enroll/check/",
  checkMitekStatus: "/users/settings/mitek/status/",
  checkVoiceQuality: "/users/settings/mitek/check-quality/",
  changePasswordCheck: "/users/change-password/check/",
  changePasswordStatus: "/users/change-password/status/",
  changePasswordConfirm: "/users/change-password/confirm/",
  softDeleteValidation: "/users/settings/soft-delete/validate/",
  softDeleteCheck: "/users/settings/soft-delete/check/",
  softDeleteConfirm: "/users/settings/soft-delete/confirm/",
};

export const ENDPOINTS_STATEMENT = {
  statements: "/statements/",
  transactions: "/transactions/",
};

export const ENDPOINTS_KYC = {
  countryCodesKyc: "/users/kyc/country-codes/",
  fieldsKyc: "/users/kyc/fields/",
  verifyKyc: "/users/kyc/verify/",
  myKyc: "/users/kyc/",
};

export const ENDPOINTS_BUY = {
  getMarketInfo: "/buy-crypto/market-info/",
  getCryptoPurchases: "/buy-crypto/",
};

export const ENDPOINTS_RATES = {
  exchangeRates: "/wallets/exchange-rates/",
};

export const ENDPOINTS_HOT_WALLET = {
  hotWalletWithdraw: "/wallets/withdraws/",
};

export const ENDPOINTS_COLD_WALLET = {
  createVault: "/wallets/withdraws/to-cold/",
  sendCodesForWithdraw: "/wallets/withdraws/cold/check/",
  confirmWithdraw: "/wallets/withdraws/cold/confirm/",
  cancelColdWalletsWithdrawal: "/wallets/withdraws/cold/cancel/",
};

export const ENDPOINTS_WALLET_FEE = {
  coldWalletWithdrawFee: "/wallets/withdraws/cold/estimate-fee/",
  hotWalletWithdrawFee: "/wallets/withdraws/estimate-fee/",
  estimateFiatFeeColdStorage: "/wallets/withdraws/cold/min-deposit-amount/",
};

export const ENDPOINTS_WHITELIST = {
  sendCodesForWhitelist: "/whitelist-addresses/check/",
  whitelistAddresses: "/whitelist-addresses/",
};

export const ENDPOINTS_WALLET = {
  wallets: "/wallets/",
  walletsUpdateChart: "/wallets/update-chart/",
};

export const ENDPOINTS_WALLET_HISTORY = {
  coldWalletHistory: "/wallets/history/cold/",
  history: "/wallets/history/",
};

export const ENDPOINTS_SWAP = {
  getOffers: "https://li.quest/v1/advanced/routes",
  swaps: "/swaps/",
  healthStatus: "/swaps/health-status/",
};

export const ENDPOINTS_ESCROW = {
  getMyEscrowsList: "/escrow/",
  getWidgetsList: "/escrow-widgets/",
  getDeliveryCouriers: "/escrow/delivery-couriers/",
};

export const ENDPOINTS_CREATE_ESCROW = {
  estimateEscrowFee: "/escrow/estimate-fee/",
  getParticipant: "/escrow/participant/",
  createEscrow: "/escrow/",
  createWidget: "/escrow-widgets/",
  escrowMinAmount: "/escrow/min-amount/",
};

export const ENDPOINTS_WIDGET = {
  widgets: "/escrow-widgets/",
};

export const ENDPOINTS_MESSENGER = {
  getChatsList: "/chats/",
};

export const ENDPOINTS_TOKENS = {
  getAllCurrencies: "/wallets/currencies/",
};

export const ENDPOINTS_YUBIKEY = {
  requestYubikeyRegistration: "/users/settings/yubico/request-register/",
  yubikeyRegistration: "/users/settings/yubico/register/",
  yubikeyRegisterName: "/users/settings/yubico/register-name/",
  getYubikeyKeysList: "/users/settings/yubico/keys/",
  requestYubikeyVerification: "/users/settings/yubico/request-verification/",
  yubikeyLogin: "/auth/yubico-login/",
  deleteYubikeyKeyCheck: "/users/settings/yubico/keys/delete/check/",
  deleteYubikeyKeyConfirm: "/users/settings/yubico/keys/delete/confirm/",
};

export const ENDPOINTS_NOTIFICATIONS = {
  getNotifications: "/users/notifications/",
  getUnreadNotificationCount: "/users/notifications/unread-count/",
};
