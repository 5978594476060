import styled from "styled-components";

import { RowBetween } from "components";

export const StyledHeaderContainer = styled(RowBetween)`
  position: sticky;
  top: 0;
  padding: 16px;
  background-color: ${({ theme }) => (theme.isDark ? theme.colors.neutral100 : theme.colors.neutral0)};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral300};

  ${({ theme }) => theme.mediaQueries.tablet} {
    background-color: ${({ theme }) => (theme.isDark ? theme.colors.neutral0 : theme.colors.neutral100)};
    border-bottom: unset;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 36px;
  }
  z-index: ${({ theme }) => theme.zIndices.dropdown};
`;

export const StyledMenuIcon = styled.div<{ isMenuOpen: boolean }>`
  transition: all 0.3s ease;
  transform: rotate(${({ isMenuOpen }) => (isMenuOpen ? "0deg" : "-180deg")});
  cursor: pointer;
`;
