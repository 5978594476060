import { useAppDispatch } from "store/store";
import { showModal } from "store/modal";

import { DepositChooseNetworkModalProps } from "components/modal/components/deposit-choose-network-modal/types";
import { DepositChooseTokenModalProps } from "components/modal/components/deposit-choose-token-modal/types";
import { ModalProps } from "store/modal/types";
import { useShowModalsProps } from "../types";

export const useShowModals = ({ setNetwork, setCurrency, values }: useShowModalsProps) => {
  const dispatch = useAppDispatch();

  const showNetworksModal = () => {
    const _showModal = showModal as ModalProps<DepositChooseNetworkModalProps>;
    dispatch(
      _showModal({
        modalName: "depositChooseNetworkModal",
        rootId: "create-escrow",
        props: {
          onClick: setNetwork,
          network: values.currency.network,
        },
      }),
    );
  };

  const showTokenModal = () => {
    const _showModal = showModal as ModalProps<DepositChooseTokenModalProps>;
    dispatch(
      _showModal({
        modalName: "depositChooseTokenModal",
        rootId: "create-escrow",
        props: { network: values.currency.network, onClick: setCurrency, tokenKey: values.currency.currency },
      }),
    );
  };

  return { showNetworksModal, showTokenModal };
};
