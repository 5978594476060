import BigNumber from "bignumber.js";

import store, { useAppDispatch } from "store/store";
import { hideModal, showModal } from "store/modal";
import { confirmWithdraw, sendCodesForWithdraw } from "store/wallets/cold-wallets/actions";
import { getWallets } from "store/wallets/cold-wallets/actions";
import { resetColdWalletFee } from "store/wallets/wallets-fee";

import { formatValueToBNString } from "configs/web3";

import { TwoFactorConfirmModalProps } from "components/modal/components/two-factor-confirm-modal/types";
import { ModalProps } from "store/modal/types";
import { ConfirmWithdrawPayload, SendCodesPayloadWithdraw } from "store/wallets/types";
import { ColdStorageWithdrawSuccessModalProps } from "components/modal/components/cold-storage-withdraw-success-modal/types";
import { WalletsTypeEnum } from "store/wallets/enums";

export const useShowModals = () => {
  const dispatch = useAppDispatch();

  const callNextModal = () => {
    const { withdrawPayload } = store.getState().coldWallets;

    const _showModal = showModal as ModalProps<
      TwoFactorConfirmModalProps<ConfirmWithdrawPayload, SendCodesPayloadWithdraw>
    >;

    if (withdrawPayload?.showModalArgs?.token) {
      const { amount, token } = withdrawPayload.showModalArgs;
      dispatch(
        _showModal({
          modalName: "twoFactorConfirmModal",
          rootId: "cold-storage",
          props: {
            title: "Multi-Factor Authentication",
            propsForAction: {
              otps: { emailOtp: "", authenticatorOtp: "" },
            },
            propsForResend: {
              amount,
              currencyCode: token.publicCode,
              chainId: token.chainId,
            },
            callbackForAction: confirmWithdraw,
            callbackForResend: sendCodesForWithdraw,
            callbackForSuccess: callSuccessModal,
          },
        }),
      );
    }
  };

  const callSuccessModal = () => {
    const _showModal = showModal as ModalProps<ColdStorageWithdrawSuccessModalProps>;
    const { withdrawPayload } = store.getState().coldWallets;
    if (withdrawPayload?.showModalArgs?.token) {
      const { amount, token, marketInfoPrice } = withdrawPayload.showModalArgs;
      dispatch(
        _showModal({
          modalName: "coldStorageWithdrawSuccessModal",
          rootId: "cold-storage",
          clickOutsideHandler: () => {
            dispatch(hideModal());
            dispatch(getWallets({ type: WalletsTypeEnum.cold }));
            dispatch(resetColdWalletFee());
          },
          props: {
            amount,
            currencyCode: token.symbol,
            chainId: token.chainId,
            fiatAmount: formatValueToBNString(BigNumber(amount).times(marketInfoPrice)),
            // Empty strings due to mandatory props and in modal will reassign for correct values
            otps: { emailOtp: "", authenticatorOtp: "" },
          },
        }),
      );
    }
  };

  return { callNextModal, callSuccessModal };
};

export default useShowModals;
