import { createAsyncThunk } from "@reduxjs/toolkit";
import { isErrorResult, makeApiRequest } from "services";
import { ENDPOINTS_KYC } from "services/endpoints";

import { ErrorResult } from "services/types";
import {
  KYCCountryCodesResponse,
  KYCFieldsPayload,
  KYCFieldsResponse,
  MyKYCStatusResponse,
  VerifyKYCPayload,
  TruliooResponse,
} from "./types";

export const getKycCountryCodes = createAsyncThunk<KYCCountryCodesResponse, undefined, { rejectValue: ErrorResult }>(
  "kyc/get-country-codes",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<KYCCountryCodesResponse>({
      method: "GET",
      url: ENDPOINTS_KYC.countryCodesKyc,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getKycFields = createAsyncThunk<KYCFieldsResponse, KYCFieldsPayload, { rejectValue: ErrorResult }>(
  "kyc/get-fields",
  async (params, { rejectWithValue }) => {
    const result = await makeApiRequest<KYCFieldsResponse>({
      method: "GET",
      url: ENDPOINTS_KYC.fieldsKyc,
      params,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const verifyKyc = createAsyncThunk<TruliooResponse, VerifyKYCPayload, { rejectValue: ErrorResult }>(
  "kyc/verify-kyc",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<TruliooResponse>({
      method: "POST",
      url: ENDPOINTS_KYC.verifyKyc,
      params: data.params,
      data: data.data,
      timeout: 60_000,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getMyKycStatus = createAsyncThunk<MyKYCStatusResponse, undefined, { rejectValue: ErrorResult }>(
  "kyc/get-my-kyc",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<MyKYCStatusResponse>({
      method: "GET",
      url: ENDPOINTS_KYC.myKyc,
      isShowError: false,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);
