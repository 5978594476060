import { DefaultTheme } from "styled-components";

type BackgroundImageType = "linear-180" | "linear-90" | "radial" | "radial-active";

export const generateBackgroundGradient = (
  theme: DefaultTheme,
  type: BackgroundImageType,
  isActiveBalance?: boolean,
  tokenPublicCode?: keyof typeof theme.colors,
): string | undefined => {
  if (!theme.isDark) {
    return;
  }

  switch (type) {
    case "linear-180":
      return `linear-gradient(180deg, ${theme.colors.neutral100} 0%, ${theme.colors.primary + "80"} 100%)`;
    case "linear-90":
      return `linear-gradient(90deg, ${theme.colors.neutral100} 0%, ${theme.colors.primary + "80"} 100%)`;
    case "radial":
      return `radial-gradient(circle at center bottom, ${theme.colors.primary + "33"} 0%, ${
        theme.colors.neutral100
      } 100%)`;
    case "radial-active":
      if (isActiveBalance) {
        if (tokenPublicCode && theme.colors[tokenPublicCode]) {
          return `radial-gradient(circle at center bottom, ${theme.colors[tokenPublicCode] + "33"} 0%, ${
            theme.colors.neutral100
          } 100%)`;
        } else {
          return `radial-gradient(circle at center bottom, ${theme.colors.primary + "33"} 0%, ${
            theme.colors.neutral100
          } 100%)`;
        }
      }
      break;
    default:
      return;
  }
};
