import styled from "styled-components";

export const StyledCard = styled.div<{ isSelected?: boolean }>`
  display: flex;
  padding: 18px 24px;
  width: 100%;
  align-items: center;
  gap: 10px;
  position: relative;

  &:hover {
    background-color: ${({ theme, isSelected }) => !isSelected && theme.colors.neutral100};
    cursor: ${({ isSelected }) => !isSelected && `url("/images/cursor.svg") 12 12, auto`};
  }

  &:not(:last-child):after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 24px;
    right: 24px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.neutral200};
  }

  background-color: ${({ theme, isSelected }) => isSelected && theme.colors.neutral100};
  cursor: ${({ isSelected }) => isSelected && "not-allowed"};
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% + 24px);
  margin: 0 -12px;
  max-height: 400px;
  overflow: auto;

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: calc(100% + 48px);
    margin: 0 -24px;
  }
`;
