import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 72 84" {...props} color={color} fill={color}>
      <g opacity="0.25" filter="url(#filter0_f_4623_103796)">
        <rect x="18" y="49" width="36" height="12" rx="4" fill={theme.colors.red} />
      </g>
      <circle cx="36" cy="36" r="28" fill={theme.colors.redLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.red} strokeOpacity="0.5" />
      <path
        d="M28.667 29.6666L43.3337 44.3333"
        stroke={theme.colors.red}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.3337 29.6666L28.667 44.3333"
        stroke={theme.colors.red}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.red} strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_4623_103796"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_4623_103796" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
