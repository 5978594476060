import * as yup from "yup";
import postalCodes from "postal-codes-js";
import { useTranslation } from "context";
import { InitialValues } from "./types";

const MIN = 3;
const MAX_10 = 10;
const MAX_50 = 50;
const MAX_100 = 100;

interface StringSchemaCustom extends yup.StringSchema {
  validatePostCode: (appendStr: string) => yup.StringSchema;
}

export const initialValues: InitialValues = {
  address: "",
  street: "",
  buildingNumber: "",
  apartments: "",
  country: "",
  countryCode: "",
  state: "",
  city: "",
  postCode: "",
  isDefault: false,
};

const useValidationSchema = () => {
  const { t } = useTranslation();

  yup.addMethod(yup.string, "validatePostCode", function (message) {
    return this.test("validate", message, function (value) {
      const { path, createError } = this;
      const { countryCode } = this.parent;

      if (value) {
        const validationResult = postalCodes.validate(countryCode, value);
        if (validationResult !== true) {
          return createError({ path, message });
        }

        return validationResult;
      } else {
        return false;
      }
    });
  });

  const validationSchema = yup.object().shape({
    address: yup
      .string()
      .required(t("This field is required"))
      .min(MIN, t("Must contain %value% or more characters", { value: MIN }))
      .max(MAX_50, t("The entered value is too long, maximum %value% characters", { value: MAX_50 })),
    street: yup
      .string()
      .required(t("This field is required"))
      .min(MIN, t("Must contain %value% or more characters", { value: MIN }))
      .max(MAX_100, t("The entered value is too long, maximum %value% characters", { value: MAX_100 })),
    buildingNumber: yup
      .string()
      .required(t("This field is required"))
      .max(MAX_10, t("The entered value is too long, maximum %value% characters", { value: MAX_10 })),
    apartments: yup
      .string()
      .max(MAX_50, t("The entered value is too long, maximum %value% characters", { value: MAX_50 })),
    country: yup
      .string()
      .required(t("This field is required"))
      .max(MAX_50, t("The entered value is too long, maximum %value% characters", { value: MAX_50 }))
      .test("isValid", t("Country is not valid"), (val, context) => {
        if (val && !context.from?.[0].value.countryCode) {
          return false;
        }
        return true;
      }),
    state: yup.string().max(MAX_50, t("The entered value is too long, maximum %value% characters", { value: MAX_50 })),
    city: yup
      .string()
      .required(t("This field is required"))
      .min(MIN, t("Must contain %value% or more characters", { value: MIN }))
      .max(MAX_100, t("The entered value is too long, maximum %value% characters", { value: MAX_100 })),
    postCode: (yup.string().required(t("This field is required")) as StringSchemaCustom)
      .validatePostCode("This is not valid postal code")
      .max(MAX_100, t("The entered value is too long, maximum %value% characters", { value: MAX_100 })),
  });

  return { validationSchema, initialValues };
};

export default useValidationSchema;
