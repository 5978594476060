import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

import { useSelectChain } from "./use-select-chain";
import { useGenerateRoutes } from "pages/main/wallets/hooks";

import { isSupportedChain } from "../utils";
// If you want to setup exact network on mount pass network id
// Otherwise network id will be first from supported chains at login function
export const useSyncChains = (parsedChainId: number | undefined) => {
  const { chainId, isActive, account } = useWeb3React();
  const { selectChain } = useSelectChain();

  const navigate = useNavigate();

  const { generateRouteForNetworkChange } = useGenerateRoutes();

  const chainIdRef = useRef(chainId);
  const accountRef = useRef(account);

  useEffect(() => {
    // Update chainIdRef when the account is retrieved from Web3React
    if (account && account !== accountRef.current) {
      chainIdRef.current = chainId;
      accountRef.current = account;
    }
  }, [account, chainId]);

  useEffect(() => {
    // Change a user's chain on pageload if the connected chainId does not match the query param chain
    if (isActive && parsedChainId && chainIdRef.current === chainId && chainId !== parsedChainId) {
      selectChain(parsedChainId);
    }
    // If a user has a connected wallet and has manually changed their chain, update the query parameter if it's supported
    else if (
      account &&
      chainIdRef.current !== chainId &&
      chainId !== parsedChainId &&
      chainId &&
      isSupportedChain(chainId)
    ) {
      const route = generateRouteForNetworkChange(chainId);
      navigate(route);
    }
    // If a user has a connected wallet and the chainId matches the query param chain, update the chainIdRef
    else if (isActive && chainId === parsedChainId) {
      chainIdRef.current = parsedChainId;
    }
  }, [parsedChainId, isActive, chainId, account]);
};

export default useSyncChains;
