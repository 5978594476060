import React from "react";

import { useTooltip } from "hooks";
import { useHandleData } from "./hooks";

import { getAddress } from "configs/web3";

import { Text, Input, InputGroup, Switcher, Flex, FlexGap, Textarea, Box, Button } from "components";
import { TooltipIcon, EditIcon } from "components/svg";

import { useTranslation } from "context";

import { useAppSelector } from "store/store";

import { EscrowInfoProps } from "./types";
import { Roles } from "../../types";

import { DESCRIPTION_LENGTH } from "../../constants";

const EscrowInfo: React.FC<EscrowInfoProps> = ({ setFieldValue, errors, values, fieldProps, touched }) => {
  const addressesList = useAppSelector(state => state.addresses.addressesList);

  const { t } = useTranslation();

  const { address, handleSwitchIsShipped, showDeliveryAddressesSideModal, handleAddressesSideModal } = useHandleData({
    setFieldValue,
    values,
  });

  const { targetRef: targetRefAddressInfo, tooltip: tooltipAddressInfo } = useTooltip(
    t(
      "Please provide the delivery address for the goods. If you are a Seller or a Broker, you can skip this step as the Buyer will enter the information later.",
    ),
    {
      placement: "top",
    },
  );

  return (
    <Box mt="24px" height="100%">
      <form>
        <InputGroup label={t("Escrow Name *")} error={errors.escrowInfo?.name} isTouched={touched?.escrowInfo?.name}>
          <Input placeholder={t("Give escrow a name")} {...fieldProps("escrowInfo.name")} />
        </InputGroup>

        <InputGroup
          label={t("Item description")}
          error={errors.escrowInfo?.description}
          isTouched={touched?.escrowInfo?.description}
          endLabelText={`${values.escrowInfo.description.length}/${DESCRIPTION_LENGTH}`}
        >
          <Textarea placeholder={t("Enter description text")} {...fieldProps("escrowInfo.description")} />
        </InputGroup>

        <InputGroup
          label={t("Add Website URL for item/service")}
          error={errors.escrowInfo?.website}
          isTouched={touched?.escrowInfo?.website}
        >
          <Input placeholder={t("https://")} {...fieldProps("escrowInfo.website")} />
        </InputGroup>

        <Flex justifyContent="space-between" mb="24px">
          <Text textScale="caption1" color="neutral600">
            {t("Is this a physical item that needs to be shipped?")}
          </Text>

          <Switcher checked={values.escrowInfo.isShipped} onChange={handleSwitchIsShipped} />
        </Flex>

        {values.escrowInfo.isShipped && values.role.role === Roles.buyer ? (
          <>
            {addressesList.length ? (
              <>
                <FlexGap gap="14px" alignItems="center">
                  <Text textScale="caption1" color="neutral600">
                    {t("Delivery Address")}
                  </Text>

                  <Box ref={targetRefAddressInfo}>
                    <TooltipIcon cursor="pointer" />
                    {tooltipAddressInfo}
                  </Box>
                </FlexGap>

                <FlexGap gap="14px" justifyContent="space-between">
                  {values.escrowInfo.address && values.escrowInfo.isShipped && address ? (
                    <Text textScale="caption1" $fontWeight="medium" color="neutral800" mt="8px" wordBreak="break-word">
                      {getAddress(address)}
                    </Text>
                  ) : null}

                  <EditIcon width="24px" height="24px" cursor="pointer" onClick={showDeliveryAddressesSideModal} />
                </FlexGap>
              </>
            ) : (
              <Button variant="outline" onClick={handleAddressesSideModal}>
                {t("+ Add New Address")}
              </Button>
            )}
          </>
        ) : null}
      </form>
    </Box>
  );
};

export default EscrowInfo;
