import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral500", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent">
      <path
        d="M16.103 10.793L19 7.897L16.103 5"
        stroke={theme.colors[color]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5 7.89999L19 7.89999" stroke={theme.colors[color]} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.897 13.207L5 16.103L7.897 19"
        stroke={theme.colors[color]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M19 16.1L5 16.1" stroke={theme.colors[color]} strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
};

export default Icon;
