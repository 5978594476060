import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { tabsIndex } from "configs/tabs";

import { getMarketInfo, getCryptoPurchases } from "./actions";

import { BuyCryptoState, MarketPayload, HistoryPayload, HistoryResponseSingleItem } from "./types";

const initialState: BuyCryptoState = {
  error: null,
  activeTab: tabsIndex.buyCrypto.buy,
  marketInfo: {
    pending: false,
    data: [],
    count: 0,
  },
  historyInfo: {
    pending: false,
    data: [],
    count: 0,
  },
  buyCryptoPayload: {
    page: 1,
    size: 10,
    chainId: process.env.REACT_APP_ENV === "development" ? "97" : "56",
  },
  historyPayload: {
    page: 1,
    size: 10,
  },
};

const buyCryptoSlice = createSlice({
  name: "buyCrypto",
  initialState: {
    ...initialState,
  },
  reducers: {
    resetBuyCrypto: () => {
      return initialState;
    },
    setBuyCryptoParams: (state, action: PayloadAction<Partial<MarketPayload>>) => {
      state.buyCryptoPayload = { ...state.buyCryptoPayload, ...action.payload };
    },
    setBuyHistoryPayload: (state, action: PayloadAction<Partial<HistoryPayload>>) => {
      state.historyPayload = { ...state.historyPayload, ...action.payload };
    },
    setBuyTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
    setBuyChainId: (state, action: PayloadAction<string>) => {
      state.buyCryptoPayload.chainId = action.payload;
    },
    updateBuyHistory: (state, action: PayloadAction<HistoryResponseSingleItem>) => {
      const newBuyHistoryData = state.historyInfo.data.map(item => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });

      state.historyInfo = { ...state.historyInfo, data: newBuyHistoryData };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getMarketInfo.fulfilled, (state, action) => {
        state.marketInfo = { ...action.payload, pending: false };
        state.error = null;
      })
      .addCase(getMarketInfo.pending, state => {
        state.marketInfo.pending = true;
      })
      .addCase(getMarketInfo.rejected, (state, action) => {
        if (action.payload) {
          state.marketInfo = initialState.marketInfo;
          state.error = action.payload;
        }
      })
      .addCase(getCryptoPurchases.fulfilled, (state, action) => {
        state.historyInfo = { ...action.payload, pending: false };
        state.error = null;
      })
      .addCase(getCryptoPurchases.pending, state => {
        state.historyInfo.pending = true;
      })
      .addCase(getCryptoPurchases.rejected, (state, action) => {
        if (action.payload) {
          state.historyInfo = initialState.historyInfo;
          state.error = action.payload;
        }
      });
  },
});

export const { resetBuyCrypto, setBuyCryptoParams, setBuyHistoryPayload, setBuyTab, setBuyChainId, updateBuyHistory } =
  buyCryptoSlice.actions;
export default buyCryptoSlice;
