import React from "react";
import BigNumber from "bignumber.js";

import { useGetFiatSymbolAndAbbreviation, useTooltip } from "hooks";
import { useTranslation } from "context";

import { Flex, Text, Heading, Commissions, Button, RowBetween, FlexGap } from "components";
import { ModalDepositIcon } from "components/svg";
import CloseModalWrapper from "../close-modal-wrapper";
import { StyledModalWrapper, StyledRow } from "../styled";

import { REQUEST_CREATED_MODAL_COMMISSION_ITEMS } from "./constants";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";
import { getWallets } from "store/wallets/cold-wallets/actions";
import { resetColdWalletFee } from "store/wallets/wallets-fee";

import { WalletsTypeEnum } from "store/wallets/enums";
import { selectCombinedData } from "./selectors";

const ColdStorageWithdrawSuccessModal: React.FC = () => {
  const { amount, currencyCode, fiatAmount, coldWalletFee } = useAppSelector(selectCombinedData);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { fiatSymbol } = useGetFiatSymbolAndAbbreviation();

  const { targetRef: targetRefAmount, tooltip: amountTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const { targetRef: targetRefFiatAmount, tooltip: fiatAmountTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const getCommissionsList = () => {
    const commissionsList = [...REQUEST_CREATED_MODAL_COMMISSION_ITEMS];
    commissionsList[0].commissionsValue = `${fiatSymbol}${BigNumber(
      coldWalletFee.withdrawalFee.amountInFiat,
    ).toFormatExtended(2)}`;

    return commissionsList;
  };

  const closeModalHandler = () => {
    dispatch(getWallets({ type: WalletsTypeEnum.cold }));
    dispatch(resetColdWalletFee());
    dispatch(hideModal());
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} />

      <Flex mt={{ _: "0px", tablet: "18px" }} mb="16px" justifyContent="center" width="100%">
        <ModalDepositIcon height="72px" width="72px" rotate="180deg" />
      </Flex>

      <StyledRow>
        <Text color="neutral600" textAlign="center" textScale="body2">
          {t("Request created!")}
        </Text>
      </StyledRow>

      <StyledRow alignItems={{ _: "center", tablet: "end" }} flexDirection={{ _: "column", tablet: "row" }}>
        <RowBetween
          mt="24px"
          flexDirection={{ _: "column", tablet: "row" }}
          alignItems={{ _: "center", tablet: "stretch" }}
        >
          <Flex alignItems="center">
            <Text color="neutral500" textScale="caption1">
              {t("Amount to withdraw:")}
            </Text>
          </Flex>

          <FlexGap
            alignItems="baseline"
            my={{ _: "12px", tablet: "0px" }}
            gap="4px"
            maxWidth={{ _: "90%", tablet: "50%" }}
          >
            <Heading color="neutral800" scale="h3" as="h3" ellipsis ref={targetRefAmount}>
              {BigNumber(amount).toFormatExtended(8)}
            </Heading>
            {amountTooltip}
            <Text textScale="body3">{currencyCode}</Text>
          </FlexGap>
        </RowBetween>

        <Text
          textScale="caption2"
          color="neutral500"
          textAlign="end"
          ellipsis
          maxWidth="150px"
          ref={targetRefFiatAmount}
        >
          ~{`${fiatSymbol}${BigNumber(fiatAmount).toFormatExtended(2)}`}
        </Text>
        {fiatAmountTooltip}
      </StyledRow>

      <Commissions commissionsList={getCommissionsList()} showIcon={false} mt="24px" mb="20px" width="100%" />

      <Button minWidth="100%" mb={{ _: "12px", tablet: "30px" }} onClick={closeModalHandler}>
        {t("Done")}
      </Button>
    </StyledModalWrapper>
  );
};

export default ColdStorageWithdrawSuccessModal;
