import { useAppSelector } from "store/store";

export const useGetCurrencyRate = (currencyCode?: string) => {
  const rates = useAppSelector(state => state.rates.rates);

  const getCurrencyRate = (code?: string) => {
    return rates && code && rates[code] ? rates[code] : 0;
  };

  const currentRate = getCurrencyRate(currencyCode);

  return { getCurrencyRate, currentRate };
};
