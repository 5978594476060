import React, { PropsWithChildren } from "react";
import { StyledQrContainer } from "./styled";
import { StyledQrProps } from "./types";

const StyledQr: React.FC<PropsWithChildren<StyledQrProps>> = ({ fgColor, value, children }) => {
  return (
    <StyledQrContainer fgColor={fgColor} value={value}>
      {children}
    </StyledQrContainer>
  );
};

export default StyledQr;
