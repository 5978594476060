import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "context";

import { useSwitchChain, WalletError, ChainId, isUserReject, isRequestPending, isSupportedChain } from "configs/web3";
import { useGenerateRoutes } from "pages/main/wallets/hooks";

import { toastOptionsError } from "components";

export const useSelectChain = () => {
  const { connector } = useWeb3React();
  const { switchChain } = useSwitchChain();
  const { generateRouteForNetworkChange } = useGenerateRoutes();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const selectChain = async (targetChain: ChainId) => {
    if (!connector) return;

    try {
      await switchChain(connector, targetChain);
      if (isSupportedChain(targetChain)) {
        const route = generateRouteForNetworkChange(targetChain);
        navigate(route);
      }
    } catch (error) {
      const err = error as WalletError;

      if (isUserReject(err)) {
        return;
      } else if (isRequestPending(err)) {
        toast.error(t("Please check your external wallet, request is already pending"), toastOptionsError);
      } else {
        console.error(t("Failed to switch networks"), error);
        toast.error(
          t("Failed to switch networks. Please switch the network in your wallet’s settings."),
          toastOptionsError,
        );
      }
    }
  };

  return { selectChain };
};
