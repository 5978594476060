import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill={theme.colors.primary}
      />
      <path
        d="M10.8766 14.6694L6.30544 15.6984C6.13872 15.739 6.01367 15.9015 6.01367 16.064V19.124C6.01367 19.3812 6.22208 19.5438 6.48607 19.4896L12.8634 18.0543C13.1274 18.0002 13.2385 17.7564 13.1274 17.5263L11.9742 15.211C11.7936 14.8454 11.9742 14.4663 12.3771 14.385L16.5453 13.4102C16.8371 13.3425 17.0871 13.0446 17.0871 12.7467V9.91687C17.0871 9.6596 16.8787 9.48359 16.6148 9.55129L10.5987 10.9459C10.2235 11.0271 10.0568 11.3792 10.2235 11.7177L11.2795 13.8434C11.4601 14.209 11.2795 14.5746 10.8766 14.6694Z"
        fill={theme.colors.neutral0}
      />
      <path
        d="M7.86178 7.01921L9.05667 9.40224C9.20951 9.71366 9.05667 10.0386 8.70932 10.1063L6.50018 10.6073C6.2223 10.675 6 10.9458 6 11.2166V13.7756C6 14.2089 6.36124 14.4797 6.79195 14.3849L8.84826 13.9246C9.12614 13.8704 9.34844 13.5861 9.34844 13.3153L9.36234 10.6885C9.36234 10.323 9.66801 9.95737 10.0292 9.87614L16.7678 8.3055C16.9345 8.26489 17.0596 8.10241 17.0596 7.93992V4.87991C17.0596 4.62265 16.8512 4.44663 16.5872 4.51433L8.12578 6.49115C7.86178 6.54532 7.75064 6.78903 7.86178 7.01921Z"
        fill={theme.colors.neutral0}
      />
    </Svg>
  );
};

export default Icon;
