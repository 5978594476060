import { createAsyncThunk } from "@reduxjs/toolkit";

import { ENDPOINTS_YUBIKEY, isErrorResult, makeApiRequest } from "services";

import {
  YubikeyDeleteKeyCheckPayload,
  YubikeyDeleteKeyConfirmPayload,
  YubikeyKeysListResponse,
  YubikeyVerificationDataResponse,
  YubikeyLoginPayload,
  YubikeyRegisterDataResponse,
  YubikeyRegisterNamePayload,
  YubikeyRegisterPayload,
  YubikeyRegisterResponse,
  YubikeyRequestLoginPayload,
} from "./types";
import { ErrorResult } from "services/types";
import { LoginUserResponse } from "store/auth/types";

export const requestYubikeyRegistration = createAsyncThunk<
  YubikeyRegisterDataResponse,
  undefined,
  { rejectValue: ErrorResult }
>("profile/request-yubikey-registration", async (_, { rejectWithValue }) => {
  const result = await makeApiRequest<YubikeyRegisterDataResponse>({
    method: "GET",
    url: ENDPOINTS_YUBIKEY.requestYubikeyRegistration,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const yubikeyRegistration = createAsyncThunk<
  YubikeyRegisterResponse,
  YubikeyRegisterPayload,
  { rejectValue: ErrorResult }
>("profile/yubikey-registration", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<YubikeyRegisterResponse>({
    method: "POST",
    url: ENDPOINTS_YUBIKEY.yubikeyRegistration,
    data,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const getYubikeyKeysList = createAsyncThunk<YubikeyKeysListResponse, undefined, { rejectValue: ErrorResult }>(
  "profile/get-yubikey-keys-list",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<YubikeyKeysListResponse>({
      method: "GET",
      url: ENDPOINTS_YUBIKEY.getYubikeyKeysList,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const yubikeyRegisterName = createAsyncThunk<
  YubikeyRegisterResponse,
  YubikeyRegisterNamePayload,
  { rejectValue: ErrorResult }
>("profile/yubikey-register-name", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<YubikeyRegisterResponse>({
    method: "POST",
    url: ENDPOINTS_YUBIKEY.yubikeyRegisterName,
    data,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const deleteYubikeyKeyCheck = createAsyncThunk<
  YubikeyRegisterResponse,
  YubikeyDeleteKeyCheckPayload,
  { rejectValue: ErrorResult }
>("user/delete-yubikey-key-check", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<YubikeyRegisterResponse>({
    method: "POST",
    url: ENDPOINTS_YUBIKEY.deleteYubikeyKeyCheck,
    data,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const requestYubikeyVerification = createAsyncThunk<
  YubikeyVerificationDataResponse,
  YubikeyRequestLoginPayload,
  { rejectValue: ErrorResult }
>("profile/request-yubikey-verification", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<YubikeyVerificationDataResponse>({
    method: "POST",
    url: ENDPOINTS_YUBIKEY.requestYubikeyVerification,
    data,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const yubikeyLogin = createAsyncThunk<LoginUserResponse, YubikeyLoginPayload, { rejectValue: ErrorResult }>(
  "profile/yubikey-login",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<LoginUserResponse>({
      method: "POST",
      url: ENDPOINTS_YUBIKEY.yubikeyLogin,
      data,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);
export const deleteYubikeyKeyConfirm = createAsyncThunk<
  YubikeyRegisterResponse,
  YubikeyDeleteKeyConfirmPayload,
  { rejectValue: ErrorResult }
>("user/delete-yubikey-key-confirm", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<YubikeyRegisterResponse>({
    method: "POST",
    url: ENDPOINTS_YUBIKEY.deleteYubikeyKeyConfirm,
    data,
    isShowError: false,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});
