import React from "react";
import moment from "moment";
import BigNumber from "bignumber.js";
import { useParams } from "react-router-dom";

import { Column, StyledCardRowBetween, StyledCardTitle, StyledCardWrapper, Text } from "components";

import { useTooltip } from "hooks";
import { useHandleTooltip } from "../../hooks";

import { useTranslation } from "context";

import { TransactionsListTableRowProps } from "../types";

import { statementTransactionText, transactionOperationText } from "../../../constants";
import { SHORT_CHAIN_NAMES } from "configs/web3";

const TableRowMobile: React.FC<TransactionsListTableRowProps> = ({ item }) => {
  const { statementId } = useParams();
  const { t } = useTranslation();

  const {
    targetRefDate,
    targetRefType,
    targetRefOperation,
    targetRefFee,
    targetRefAmount,
    creationDate,
    dateTooltip,
    typeTooltip,
    operationTooltip,
    feeTooltip,
    amountTooltip,
    amountInFiat,
    feeInFiat,
    firstItemFeeStr,
  } = useHandleTooltip(item);

  const { targetRef: targetRefTokenInfo, tooltip: tokenInfoTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  return (
    <StyledCardWrapper rowGap="14px">
      <StyledCardRowBetween>
        <StyledCardTitle>{t("Date and time")}</StyledCardTitle>
        <Text ref={targetRefDate} textScale="body3" color="neutral600" ellipsis>
          {moment(creationDate).format("MM/DD/YYYY hh:mm A")}
        </Text>
        {dateTooltip}
      </StyledCardRowBetween>
      <StyledCardRowBetween>
        <StyledCardTitle>{t("Transaction type")}</StyledCardTitle>

        <Text color="neutral800" textScale="body3" ref={targetRefType} ellipsis>
          {statementTransactionText[item.type]}
        </Text>
        {typeTooltip}
      </StyledCardRowBetween>
      <StyledCardRowBetween>
        <StyledCardTitle>{t("Transaction class")}</StyledCardTitle>

        <Text ref={targetRefOperation} textScale="body3" ellipsis>
          {transactionOperationText[item.operation]}
        </Text>
        {operationTooltip}
      </StyledCardRowBetween>
      <StyledCardRowBetween>
        <StyledCardTitle>{t("Network / token")}</StyledCardTitle>

        <Text textScale="body3" ref={targetRefTokenInfo} ellipsis>
          {`${SHORT_CHAIN_NAMES[item.currency.chainId]} / ${item.currency.symbol}`}
        </Text>
        {tokenInfoTooltip}
      </StyledCardRowBetween>
      <StyledCardRowBetween>
        <StyledCardTitle>{t("Amount")}</StyledCardTitle>

        <Column ref={targetRefAmount}>
          <Text textScale="body3" ellipsis textAlign="end">
            {BigNumber(item.amount).toFormatExtended(8)}
          </Text>
          {statementId && (
            <Text color="neutral500" textScale="caption2" textAlign="end" ellipsis>
              {amountInFiat}
            </Text>
          )}
        </Column>
        {amountTooltip}
      </StyledCardRowBetween>
      <StyledCardRowBetween>
        <StyledCardTitle>{t("Fees")}</StyledCardTitle>

        <Column ref={targetRefFee}>
          <Text textScale="body3" textAlign="end" ellipsis>
            {item.fees.length > 1 ? t("Multiple") : firstItemFeeStr}
          </Text>
          {statementId && (
            <Text color="neutral500" textScale="caption2" textAlign="end" ellipsis>
              {feeInFiat}
            </Text>
          )}
        </Column>
        {feeTooltip}
      </StyledCardRowBetween>
    </StyledCardWrapper>
  );
};

export default TableRowMobile;
