import { ModalWarningIcon } from "components/svg";

import { useTranslation } from "context";

import { useKycHandler, useMatchBreakpoints } from "hooks";

import { showModal, hideModal } from "store/modal";
import { useAppDispatch, useAppSelector } from "store/store";

import { ModalProps } from "store/modal/types";
import { ActionModalProps } from "components/modal/components/action-modal/types";
import { UseShowWarningModalArgs } from "./types";
import { BiometricType } from "components/modal/components/setup-biometric-modal/types";

const useShowWarningModal = ({
  useShowModal,
  actionText = "Set up",
  actionType = "set up",
  biometricType = BiometricType.face,
}: UseShowWarningModalArgs) => {
  const rootId = useAppSelector(state => state.modal.rootId);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const { onConfirmClick } = useShowModal();
  const { callbackWithKycCheck } = useKycHandler({ shouldCheckStatus: false });

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const showBiometricWarningModal = () => {
    const _showModal = showModal as ModalProps<ActionModalProps>;

    dispatch(
      _showModal({
        modalName: "actionModal",
        rootId,
        props: {
          icon: <ModalWarningIcon height="72px" width="72px" />,
          title: t("Warning"),
          text: t(
            "%action% from the desktop is not recommended. Please, %actionType% %biometricType% biometric from smartphone to avoid possible issues",
            { action: t(actionText), biometricType: t(biometricType), actionType: t(actionType) },
          ),
          btnArr: [
            {
              buttonText: t("Cancel"),
              props: {
                variant: "outline",
              },
              callback: closeModalHandler,
            },
            {
              buttonText: t("Continue anyway"),
              props: {
                padding: "14px 0px",
              },
              callback: onConfirmClick,
            },
          ],
        },
      }),
    );
  };

  const handlerBiometric = () => {
    callbackWithKycCheck(isDesktop ? showBiometricWarningModal : onConfirmClick);
  };

  return { handlerBiometric };
};

export default useShowWarningModal;
