import React, { useEffect } from "react";

import { Button, Text, Flex, Image } from "components";
import { StyledBiometricModalWrapper } from "./styled";
import CloseModalWrapper from "../close-modal-wrapper";
import { TermsOfUse } from "pages/auth/components";

import { useTranslation } from "context";

import { useHandlePageVisibility, useMatchBreakpoints } from "hooks";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";

import { BiometricType } from "./types";

import { biometricImageHeight } from "./constants";
import { selectCombinedData } from "./selectors";

const SetupBiometricModal: React.FC = () => {
  const { t } = useTranslation();

  const {
    useHandleBiometric,
    imageSrc,
    title,
    biometricAction = t("taking a selfie"),
    type = BiometricType.face,
  } = useAppSelector(selectCombinedData);

  const dispatch = useAppDispatch();

  const { isMobile } = useMatchBreakpoints();

  const { isLoading, handleVerify, stopMitekSDK, showErrorModal } = useHandleBiometric();

  const closeModalHandler = () => {
    dispatch(hideModal());
    stopMitekSDK();
  };

  useHandlePageVisibility(closeModalHandler);

  useEffect(() => {
    // we check if mitekSDK is loaded. at first we receive true. then we wait 10sec, if mitekSDK didn't upload, we show error modal
    const timer = setTimeout(() => {
      if (isLoading) {
        showErrorModal();
      }
    }, 10000);

    return () => clearTimeout(timer);
  }, [isLoading]);

  return (
    <StyledBiometricModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler}>
        {isMobile && (
          <Text color="neutral600" textAlign="center" textScale="body2" $fontWeight="bold">
            {title}
          </Text>
        )}
      </CloseModalWrapper>

      {!isMobile && (
        <Text color="neutral600" textAlign="center" mt="14px" textScale="body2" $fontWeight="bold">
          {title}
        </Text>
      )}

      <Text
        wordBreak="break-word"
        color="neutral600"
        my={{ _: "18px", laptop: "12px" }}
        textAlign={{ _: "left", tablet: "center" }}
        textScale="caption1"
      >
        {t("On the following screen, you’ll begin the enrollment process by %biometricAction%", { biometricAction })}
      </Text>

      <Flex
        width="100%"
        maxWidth={{ _: "300px", laptop: "100%" }}
        my={{ _: "18px", laptop: "12px" }}
        justifyContent="center"
      >
        <Image
          src={imageSrc}
          width={{ _: "300px", tablet: "254px" }}
          aspectRatio={{
            _: biometricImageHeight[type].aspectRatioMobile,
            tablet: biometricImageHeight[type].aspectRatioLaptop,
          }}
        />
      </Flex>

      <Flex width="100%" my="20px">
        <Button width="100%" isLoading={isLoading} onClick={handleVerify}>
          {t("Continue")}
        </Button>
      </Flex>

      <TermsOfUse />
    </StyledBiometricModalWrapper>
  );
};

export default SetupBiometricModal;
