import React from "react";
// Components + styling
import { Box, Flex, Image, Text } from "components";
import { StyledCard } from "components/modal/components/deposit-choose-token-modal/styled";
// Store
import { useAppDispatch, useAppSelector } from "store/store";
import { setFromData, setToData } from "store/swap";
import { hideModal } from "store/modal";
// Types
import { SingleTokenItemProps } from "./types";
import { SwapNetworkTokenProps } from "components/modal/components/swap-network-token-modal/types";

const SingleTokenItem: React.FC<SingleTokenItemProps> = ({ tokenItem, resetAmount }) => {
  const dispatch = useAppDispatch();

  const swapType = useAppSelector(state => (state.modal.props as unknown as SwapNetworkTokenProps).swapType);
  const swap = useAppSelector(state => state.swap);
  const { from, to } = swap;

  const currentSwap = swap[swapType];

  const isSelected =
    (from?.token?.name === tokenItem.name && from?.token?.chainId === tokenItem.chainId) ||
    (to?.token?.name === tokenItem.name && to?.token?.chainId === tokenItem.chainId);

  const handleTokenChange = () => {
    if (tokenItem && currentSwap) {
      if (swapType === "from" && (to?.token?.name !== tokenItem.name || to?.token?.chainId !== tokenItem.chainId)) {
        dispatch(setFromData({ network: currentSwap.network, token: tokenItem }));
        resetAmount();
        dispatch(hideModal());
      }
      if (swapType === "to" && (from?.token?.name !== tokenItem.name || from?.token?.chainId !== tokenItem.chainId)) {
        dispatch(setToData({ network: currentSwap.network, token: tokenItem }));
        resetAmount();
        dispatch(hideModal());
      }
    }
  };

  return (
    <StyledCard isSelected={isSelected} onClick={handleTokenChange}>
      <Flex height="100%" width="100%" justifyContent="flex-start" alignItems="center">
        <Box minWidth="24px" minHeight="24px">
          <Image src={tokenItem.logoURI} alt={tokenItem.symbol} variant="circle" width="24px" aspectRatio={1} />
        </Box>

        <Text mx="8px" textScale="body3" color="neutral800">
          {tokenItem.name}
        </Text>
        <Text textScale="body3" color="neutral500">
          {tokenItem.symbol}
        </Text>
      </Flex>
    </StyledCard>
  );
};

export default SingleTokenItem;
