import * as yup from "yup";

import { useTranslation } from "context";

import { isAddress, ChainId } from "configs/web3";
import { BITCOIN_NETWORK, isAddressBitcoin } from "configs/bitcoin";
import { SOLANA_NETWORK, isAddressSolana } from "configs/solana";

import { InitialValuesType } from "./types";

interface StringSchemaCustom extends yup.StringSchema {
  isValidWallet: (appendStr: string) => yup.StringSchema;
}

export const initialValues: InitialValuesType = {
  nickname: "",
  address: "",
};

const useValidationSchema = (network: ChainId) => {
  const { t } = useTranslation();

  const isBitcoinChain = network === BITCOIN_NETWORK;
  const isSolanaChain = network === SOLANA_NETWORK;

  yup.addMethod(yup.string, "isValidWallet", function (message: string) {
    return this.test("validate", message, function (value) {
      const { path, createError } = this;

      if (value) {
        if (isBitcoinChain && isAddressBitcoin(value)) {
          return true;
        }

        if (isSolanaChain && isAddressSolana(value)) {
          return true;
        }

        if (!isBitcoinChain && !isSolanaChain && isAddress(value)) {
          return true;
        }

        return createError({ path, message });
      } else {
        return false;
      }
    });
  });

  const validationSchema = yup.object().shape({
    nickname: yup.string().required(t("This field is required")),
    address: (yup.string().required(t("This field is required")) as StringSchemaCustom).isValidWallet(
      t("This is not valid wallet address"),
    ),
  });

  return { validationSchema, initialValues };
};

export default useValidationSchema;
