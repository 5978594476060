import { ChainId } from "configs/web3";
import { getTokenFromNetworks } from "utils/helpers";

import { useAppSelector } from "store/store";

export const useTokenBE = (chainId?: ChainId | undefined, publicCode?: string | undefined) => {
  const networks = useAppSelector(state => state.hotWallets.networks);

  const getTokenBE = (chainId: ChainId | undefined, publicCode: string | undefined) => {
    return getTokenFromNetworks(networks, chainId, publicCode);
  };

  const token = getTokenBE(chainId, publicCode);

  return { token, getTokenBE };
};
