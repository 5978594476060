import styled from "styled-components";

export const StyledCard = styled.div<{ isSelected?: boolean }>`
  display: flex;
  padding: 16px 24px;
  width: 100%;
  gap: 10px;
  border: 1px solid ${({ theme }) => theme.colors.neutral300};
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  margin: 7px 0;
  align-items: center;

  &:hover {
    background-color: ${({ theme, isSelected }) => !isSelected && theme.colors.neutral100};
    cursor: ${({ isSelected }) => !isSelected && `url("/images/cursor.svg") 12 12, auto`};
  }

  background-color: ${({ theme, isSelected }) => isSelected && theme.colors.neutral200};
  cursor: ${({ isSelected }) => isSelected && "not-allowed"};
`;
