import React from "react";

import { useHandleData } from "components/modal/components/whitelist-side-modal/components/table/hooks";
import { useTooltip } from "hooks";

import { Column, Flex, StatusTag, Td, Text, Tr } from "components";

import { WhitelistTableRowProps } from "../types";
import { whitelistEnumStatusToStatus } from "store/wallets/enums";

const TableRowDesktop: React.FC<WhitelistTableRowProps> = ({ item }) => {
  const { targetRef: targetRefName, tooltip: nameTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });
  const { targetRef: targetRefAddress, tooltip: addressTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });
  const { timer, onTimerReachZero, handleSelectAddress, isStatusPending, isStatusBlacklisted } = useHandleData({
    item,
  });

  return (
    <Tr hover onClick={handleSelectAddress} width="100%">
      <Td p={{ laptop: "16px 12px" }}>
        <Column>
          <Text textScale="body3" color="neutral800" $fontWeight="medium" ref={targetRefName} ellipsis>
            {item.nickname}
          </Text>
          {nameTooltip}

          <Text textScale="caption1" color={isStatusBlacklisted ? "red" : "neutral600"} ref={targetRefAddress} ellipsis>
            {item.address}
          </Text>
          {addressTooltip}
        </Column>
      </Td>
      <Td p={{ laptop: "16px 12px" }}>
        <Flex justifyContent="flex-end">
          <StatusTag
            height="40px"
            status={whitelistEnumStatusToStatus[item.status]}
            isTimeOnly={isStatusPending}
            isLetterFormat
            timer={isStatusPending ? timer : null}
            onTimerReachZero={onTimerReachZero}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default TableRowDesktop;
