import { ChooseToken, ChooseNetwork, ConnectWalletField } from "../";

import { tabsIndex } from "configs";

import { DepositNetworkInfoProps } from "./types";

const DepositNetworkInfo: React.FC<DepositNetworkInfoProps> = ({
  chainForAutoConnect,
  isDifferentNetwork,
  isUnsupportedNetwork,
  currentChainId,
  isWalletLoading,
  isBitcoinChain,
  activeTab,
  tokenCode,
  address,
  currency,
  disconnecting,
  switchNetwork,
  showWalletModal,
  showNetworksModal,
  showTokenModal,
  onLogout,
}) => {
  return (
    <>
      <ChooseNetwork
        chainId={currentChainId}
        isLoading={isWalletLoading}
        isDifferentNetwork={!!isDifferentNetwork}
        isUnsupportedNetwork={!!isUnsupportedNetwork}
        onButtonClick={showNetworksModal}
      />

      {!isBitcoinChain && (
        <ChooseToken
          currency={currency}
          isLoading={isWalletLoading}
          currencyKey={tokenCode!}
          onButtonClick={showTokenModal}
        />
      )}

      {activeTab === tabsIndex.depositMethod.automatic && (
        <ConnectWalletField
          isLoading={isWalletLoading}
          address={address}
          disconnecting={disconnecting}
          isUnsupportedNetwork={!!isUnsupportedNetwork}
          chainForSwitchNetwork={chainForAutoConnect}
          switchNetwork={switchNetwork}
          onConnectClick={showWalletModal}
          onLogout={onLogout}
        />
      )}
    </>
  );
};

export default DepositNetworkInfo;
