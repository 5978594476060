import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "store/store";
import { hideModal, showModal } from "store/modal";
import { resetCreateEscrow } from "store/escrow/create-escrow";

import { WidgetCreatedModalProps } from "components/modal/components/widget-created-modal/types";
import { EscrowCreatedModalProps } from "components/modal/components/escrow-created-modal/types";
import { ModalProps } from "store/modal/types";
import { UseShowModalsProps } from "./types";

import { ROUTES } from "navigation/routes";

export const useShowModals = ({ resetForm }: UseShowModalsProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const closeModalHandler = async () => {
    await resetForm();
    resetCreateEscrow();
    dispatch(hideModal());
    navigate(`/${ROUTES.escrows}`);
  };

  const showWidgetCreatedModal = (widgetId: string) => {
    const _showModal = showModal as ModalProps<WidgetCreatedModalProps>;

    dispatch(
      _showModal({
        modalName: "widgetCreatedModal",
        rootId: "create-escrow",
        clickOutsideHandler: closeModalHandler,
        props: {
          widgetId,
          closeModalHandler,
        },
      }),
    );
  };

  const showEscrowCreatedModal = (escrowId: string) => {
    const _showModal = showModal as ModalProps<EscrowCreatedModalProps>;

    dispatch(
      _showModal({
        modalName: "escrowCreatedModal",
        rootId: "create-escrow",
        clickOutsideHandler: closeModalHandler,
        props: {
          escrowId,
          closeModalHandler,
        },
      }),
    );
  };

  return { showWidgetCreatedModal, showEscrowCreatedModal };
};
