import { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { useGenerateRoutes } from "./use-generate-routes";
import { useCurrencies } from "hooks";

import { useAppSelector } from "store/store";

import { ROUTES } from "navigation/routes";

import { SUPPORTED_CHAINS } from "configs/web3";
import { TABS } from "configs";
import { getKeyByValue, getTokenFromTokenKey } from "utils/helpers";

const routeToTab = {
  network: 0,
  deposit: 1,
  withdraw: 2,
  whitelist: 3,
  history: 4,
};

export const useHandleData = () => {
  const allTokensList = useAppSelector(state => state.tokensList.allTokensList);
  const navigate = useNavigate();
  const { network, token } = useParams();
  const { pathname } = useLocation();
  const { generateRouteForPageRefresh } = useGenerateRoutes();

  const lastPathname = pathname.split("/")[pathname.split("/").length - 1];
  const activeTab: number | undefined = routeToTab[lastPathname as keyof typeof routeToTab];

  const currentNetwork = SUPPORTED_CHAINS.find(chain => chain.toString() === network);

  const currentToken = getTokenFromTokenKey(allTokensList, network, token);

  const defaultNetwork = SUPPORTED_CHAINS[0];
  const _chainId = currentNetwork ? (network as string) : defaultNetwork;

  const tokenList = useCurrencies(_chainId);

  const defaultToken = tokenList ? Object.keys(tokenList)[0] : "";
  const _token = getCurrentToken();

  useEffect(() => {
    const route = generateRouteForPageRefresh(_chainId, _token);

    navigate(route);
  }, []);

  function getCurrentToken() {
    return token && tokenList && Object.keys(tokenList).includes(token) ? token : defaultToken;
  }

  function checkIsOnDisabledWalletTab() {
    if (TABS.wallets.find(item => item.title.toLowerCase() === lastPathname)?.disableIfBlacklisted) {
      navigate(`/${ROUTES.wallet}/${network}`);
    }
  }

  const onTabClick = (tab: number) => {
    if (tab === 0) {
      navigate(`/${ROUTES.wallet}/${network}`);
    } else {
      navigate(`/${ROUTES.wallet}/${network}/${currentToken ? token : defaultToken}/${getKeyByValue(routeToTab, tab)}`);
    }
  };

  return { network, activeTab, onTabClick, checkIsOnDisabledWalletTab };
};
