import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral500", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" color="transparent" stroke={theme.colors[color]} {...props}>
      <path
        d="M19.5759 4.40587C20.2359 5.06487 20.2359 6.13387 19.5759 6.79287C18.9159 7.45187 17.8469 7.45187 17.1869 6.79287C16.5269 6.13387 16.5269 5.06487 17.1869 4.40587C17.8469 3.74687 18.9159 3.74687 19.5759 4.40587"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.42396 19.5939C3.76396 18.9349 3.76396 17.8659 4.42396 17.2069C5.08396 16.5479 6.15296 16.5479 6.81296 17.2069C7.47296 17.8659 7.47296 18.9349 6.81296 19.5939C6.15296 20.2529 5.08396 20.2529 4.42396 19.5939"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.93506 19.4479C10.4411 21.8299 15.2531 21.4719 18.3621 18.3659C20.1201 16.6079 21.0001 14.3039 21.0001 11.9999C21.0001 11.4679 20.9681 10.9369 20.8741 10.4119"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.126 13.5879C3.032 13.0629 3 12.5319 3 11.9999C3 9.69594 3.88 7.39194 5.639 5.63394C8.747 2.52794 13.56 2.16994 17.066 4.55194"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9.00024L15.0002 12.0005L12 15.0007L8.99977 12.0005L12 9.00024Z"
        strokeWidth="1.49999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
