import { useHandleVerifyStatus } from "../voice-verify";

import { useTranslation } from "context";

import { hideModal, partialUpdateModalProps, showModal } from "store/modal";
import { useAppDispatch } from "store/store";
import { setIsShowError } from "store/verify-biometric";

import { ModalProps, PartialUpdateModalProps } from "store/modal/types";
import { VoiceCaptureModalProps, microphoneStatusEnum } from "components/modal/components/voice-capture-modal/types";
import { MitekSDK } from "hooks/use-get-mitek-sdk/types";
import { VerifyData } from "store/verify-biometric/types";

const useHandleVoiceBiometric = (mitekScienceSDK: MitekSDK | undefined) => {
  const { handleVoiceVerify, stopMitekSDK, handleVerifyCallback, showErrorModal } =
    useHandleVerifyStatus(mitekScienceSDK);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const handleCloseVerifyModal = () => {
    dispatch(setIsShowError(false));
    stopMitekSDK();
    dispatch(hideModal());
  };

  const handleVerify = (verificationData: { selfieImage?: VerifyData } = {}) => {
    const _showModal = showModal as ModalProps<VoiceCaptureModalProps>;
    const _partialProps = partialUpdateModalProps as PartialUpdateModalProps<VoiceCaptureModalProps>;

    dispatch(_partialProps({ microphoneStatus: microphoneStatusEnum.LOADING }));
    dispatch(hideModal());
    dispatch(
      _showModal({
        modalName: "voiceCaptureModal",
        rootId: "cold-storage",
        clickOutsideHandler: handleCloseVerifyModal,
        props: {
          microphoneStatus: microphoneStatusEnum.LOADING,
          title: t("Voice capture"),
          closeCallback: handleCloseVerifyModal,
          verifyCallback: () => handleVerifyCallback(stopMitekSDK, "voice"),
          verifyDescription: t("Have troubles with the verification?"),
        },
      }),
    );

    handleVoiceVerify(verificationData);
  };

  return { handleVerify, stopMitekSDK, showErrorModal };
};

export default useHandleVoiceBiometric;
