import styled from "styled-components";
import { layout, LayoutProps } from "styled-system";
// Types
import { DisabledProps, DraggingProps } from "./types";

export const SlideWrapper = styled.div`
  margin: auto;
  width: 100%;
  min-width: 100%;
  height: 54px;
  border: 1px solid ${({ theme }) => theme.colors.neutral300};
  border-radius: 60px;
`;

export const StyledBg = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  width: 0;
  position: relative;
  height: 52px;
  border-radius: 60px;
`;

export const StyledText = styled.div<DisabledProps & LayoutProps>`
  color: ${({ theme, disabled }) => (disabled ? theme.colors.grey2 : theme.colors.neutral900)};
  transition: color 0.3s ease;

  &:after {
    content: var(--content);
    display: block;
    text-align: center;
    font-size: 14px;
    padding: 20px;
  }

  ${layout}
`;

export const SlideIcon = styled.div<DraggingProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 46px;
  height: 46px;
  margin: 3px;
  top: 0;
  line-height: 60px;
  border-radius: ${({ theme }) => theme.radii.circle};
  transition: background-color 0.3s ease;

  cursor: ${({ isDragging, disabled }) => (disabled ? "not-allowed" : isDragging ? "grabbing" : "grab")};

  background-color: ${({ theme, isDragging, isConfirmed, disabled }) =>
    disabled ? theme.colors.grey2 : isDragging || isConfirmed ? theme.colors.neutral0 : theme.colors.primary};
`;
