import React from "react";
import { Svg } from "components";

import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "primary", ...props }) => {
  return (
    <Svg viewBox="0 0 121 121" {...props} color={color}>
      <path
        d="M114.36 86.9353L98.8211 71.3262V21.3769H86.466L94.2732 13.5343C95.9407 11.8591 98.2147 10.9454 100.413 10.9454C102.611 10.9454 104.885 11.783 106.552 13.5343C109.963 16.9607 109.963 22.519 106.552 25.9454L104.203 28.3059V43.8388L114.284 33.7119C121.939 26.0216 121.939 13.4581 114.284 5.76777C106.628 -1.92259 94.1216 -1.92259 86.466 5.76777L71.0033 21.3769H21.2802V33.7881L13.473 25.9454C10.0621 22.519 10.0621 16.9607 13.473 13.5343C15.1406 11.8591 17.4145 10.9454 19.6126 10.9454C21.8107 10.9454 24.0847 11.783 25.7522 13.5343L28.1019 15.8947H43.5646L33.5594 5.76777C25.9038 -1.92259 13.3972 -1.92259 5.74166 5.76777C-1.91389 13.4581 -1.91389 26.0216 5.74166 33.7119L21.2802 49.3211V99.2703H33.6352L25.828 107.113C22.4171 110.539 16.8839 110.539 13.473 107.113C10.0621 103.687 10.0621 98.1282 13.473 94.7018L15.8227 92.3414V76.8084L5.74166 86.9353C-1.91389 94.6257 -1.91389 107.189 5.74166 114.879C9.45574 118.61 14.3826 120.666 19.6884 120.666C24.9184 120.666 29.8453 118.61 33.6352 114.879L49.1737 99.2703H98.8969V86.8592L106.704 94.7018C110.115 98.1282 110.115 103.687 106.704 107.113C103.293 110.539 97.7599 110.539 94.349 107.113L91.9993 104.753H76.5366L86.6176 114.879C90.3317 118.61 95.2586 120.666 100.564 120.666C105.794 120.666 110.721 118.61 114.511 114.879C122.015 107.189 122.015 94.6257 114.36 86.9353ZM87.9062 88.2297H60.0885L76.3092 71.9353L68.502 64.2449L44.55 88.3059H32.195V60.2856L48.4157 76.58L56.147 68.8135L32.195 44.7526V32.3414H60.0127L43.8678 48.6358L51.5992 56.4023L75.5512 32.3414H87.9062V60.2856L71.6855 43.9911L63.8784 51.8338L87.9062 75.8947V88.2297Z"
        fill={color}
      />
    </Svg>
  );
};

export default Icon;
