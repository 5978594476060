import styled from "styled-components";

import { Logo, LogoText } from "components/svg";
import { LogoProps } from "./types";
import { Flex } from "components/layout";
import { getSizeLogo, getSizeLogoText } from "./theme";

export const StyledLogo = styled(Logo)<LogoProps>`
  ${getSizeLogo}
`;

export const StyledLogoText = styled(LogoText)<LogoProps>`
  transition: all 0.3s ease;
  ${getSizeLogoText}
`;

export const StyledFlex = styled(Flex)`
  transition: padding 0.3s ease;
`;
