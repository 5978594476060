import { useState } from "react";
import { DateRange } from "react-day-picker";
import moment from "moment";

import { useAppDispatch, useAppSelector } from "store/store";

import { DateRangeProps } from "components/date-selects/range-date-select/desktop-range-date-select/type";
import { UserWalletsStatusEnum } from "store/profile/types";

export const useHandleDateRange = ({ dateFrom, dateTo, action, setDate }: DateRangeProps) => {
  const user = useAppSelector(state => state.profile.user);

  const [selectedRange, setSelectedRange] = useState(() => {
    const from = dateFrom ? new Date(dateFrom) : "";
    const to = dateTo ? new Date(dateTo) : "";

    const formattedFrom = moment(from).format("MM/DD/YY");
    const formattedTo = moment(to).format("MM/DD/YY");

    const rangeFrom = moment(from).isValid() ? formattedFrom : "";
    const rangeTo = moment(to).isValid() ? formattedTo : "";

    return { rangeFrom, rangeTo };
  });

  const dispatch = useAppDispatch();

  const handleSelectedRange = (dateRange: DateRange | undefined) => {
    if (user?.walletsStatus === UserWalletsStatusEnum.CREATED) {
      if (dateRange) {
        const rangeFrom = moment(dateRange.from).format("MM/DD/YY");
        const rangeTo = moment(dateRange.to).format("MM/DD/YY");
        setSelectedRange({ rangeFrom, rangeTo });
      } else {
        setSelectedRange({ rangeFrom: "", rangeTo: "" });
      }
    }
  };

  const handleSetSelectedDate = (dateRange: DateRange | undefined) => {
    if (dateRange && user?.walletsStatus === UserWalletsStatusEnum.CREATED) {
      const from = moment(dateRange.from).format("YYYY-MM-DD");
      const to = moment(dateRange.to).format("YYYY-MM-DD");
      action ? dispatch(action({ page: 1, dateFrom: from, dateTo: to })) : null;
      setDate?.({ dateFrom: from, dateTo: to });
    }
  };

  const resetRange = () => {
    action ? dispatch(action({ page: 1, dateFrom: undefined, dateTo: undefined })) : null;
    setDate?.({ dateFrom: undefined, dateTo: undefined });
  };

  return { selectedRange, handleSetSelectedDate, resetRange, handleSelectedRange, setSelectedRange };
};
