import React from "react";

import { useTooltip } from "hooks";
import { useHandleData } from "components/modal/components/whitelist-side-modal/components/table/hooks";

import { StyledTableRowMobileContainer, StyledTableRowMobileInnerContainer, StyledTableRowMobileText } from "./styled";
import { Box, StatusTag } from "components";

import { WhitelistTableRowProps } from "../types";

import { whitelistEnumStatusToStatus } from "store/wallets/enums";

const TableRowMobile: React.FC<WhitelistTableRowProps> = ({ item }) => {
  const { targetRef: targetRefName, tooltip: nameTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });
  const { targetRef: targetRefAddress, tooltip: addressTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });
  const { timer, onTimerReachZero, handleSelectAddress, isStatusPending, isStatusBlacklisted } = useHandleData({
    item,
  });

  return (
    <StyledTableRowMobileContainer onClick={handleSelectAddress}>
      <StyledTableRowMobileInnerContainer>
        <Box maxWidth="60%">
          <StyledTableRowMobileText
            textScale="body3"
            color="neutral800"
            $fontWeight="medium"
            ref={targetRefName}
            ellipsis
          >
            {item.nickname}
          </StyledTableRowMobileText>
          {nameTooltip}

          <StyledTableRowMobileText textScale="caption1" color={isStatusBlacklisted ? "red" : "neutral600"} ref={targetRefAddress} ellipsis>
            {item.address}
          </StyledTableRowMobileText>

          {addressTooltip}
        </Box>

        <Box maxWidth="40%">
          <StatusTag
            height="40px"
            status={whitelistEnumStatusToStatus[item.status]}
            isLetterFormat
            isTimeOnly={isStatusPending}
            timer={isStatusPending ? timer : null}
            onTimerReachZero={onTimerReachZero}
          />
        </Box>
      </StyledTableRowMobileInnerContainer>
    </StyledTableRowMobileContainer>
  );
};

export default TableRowMobile;
