import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  cancelColdWalletsWithdrawal,
  confirmWithdraw,
  depositToColdWallet,
  getWallets,
  sendCodesForWithdraw,
} from "./actions";

import { tabsIndex } from "configs/tabs";
import { getTotalBalance } from "configs/web3";

import { checkMitekStatus } from "store/verify-biometric/actions";

import { ColdWalletCreateVaultPayload, ColdWalletsState, UpdateWalletResponse, WithdrawPayload } from "./types";
import { WalletsTypeEnum } from "../enums";

const initialState: ColdWalletsState = {
  error: null,
  pending: false,
  coldWallets: {
    activeTab: tabsIndex.coldStorage.overView,
    pending: false,
    data: [],
    totalBalanceFiat: "0",
    totalBalanceCrypto: "0",
  },
  coldWalletCreateVaultPayload: {
    chainId: process.env.REACT_APP_ENV === "development" ? "97" : "56",
    currencyCode: "",
    isAcceptTerms: false,
    amount: "",
  },
};

const coldWalletsSlice = createSlice({
  name: "coldWallets",
  initialState: {
    ...initialState,
  },
  reducers: {
    setColdStorageTab: (state, action: PayloadAction<number>) => {
      state.coldWallets.activeTab = action.payload;
    },
    setColdWalletCreateVaultPayload: (state, action: PayloadAction<Partial<ColdWalletCreateVaultPayload>>) => {
      state.coldWalletCreateVaultPayload = { ...state.coldWalletCreateVaultPayload, ...action.payload };
    },
    setWithdrawPayload: (state, action: PayloadAction<WithdrawPayload>) => {
      state.withdrawPayload = { ...state.withdrawPayload, ...action.payload };
    },
    updateColdWallet: (state, action: PayloadAction<UpdateWalletResponse>) => {
      if (action.payload.wallet.type === WalletsTypeEnum.cold) {
        const updatedWalletIndex = state.coldWallets.data.findIndex(wallet => wallet.id === action.payload.wallet.id);
        let updatedList = state.coldWallets.data.map(wallet =>
          wallet.id === action.payload.wallet.id ? { ...wallet, ...action.payload.wallet } : wallet,
        );

        if (updatedWalletIndex < 0) {
          updatedList = [...updatedList, action.payload.wallet];
        }

        state.coldWallets.data = updatedList;
        state.coldWallets.totalBalanceFiat = getTotalBalance(updatedList, "fiat");
        state.coldWallets.totalBalanceCrypto = getTotalBalance(updatedList, "crypto");
      }
    },
    resetColdWallets: () => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getWallets.fulfilled, (state, action) => {
        if (action.payload.type === WalletsTypeEnum.cold) {
          state.coldWallets.data = action.payload.wallets;
          state.coldWallets.pending = false;
          state.coldWallets.totalBalanceFiat = getTotalBalance(action.payload.wallets, "fiat");
          state.coldWallets.totalBalanceCrypto = getTotalBalance(action.payload.wallets, "crypto");
        }
        state.error = null;
      })
      .addCase(getWallets.pending, (state, action) => {
        if (action.meta.arg.type === WalletsTypeEnum.cold) {
          state.coldWallets.pending = true;
        }
      })
      .addCase(getWallets.rejected, (state, action) => {
        if (action.payload) {
          if (action.payload.type === WalletsTypeEnum.cold) {
            state.coldWallets = initialState.coldWallets;
          }
          state.error = action.payload;
        }
      })
      .addCase(depositToColdWallet.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(depositToColdWallet.pending, state => {
        state.pending = true;
      })
      .addCase(depositToColdWallet.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(sendCodesForWithdraw.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(sendCodesForWithdraw.pending, state => {
        state.pending = true;
      })
      .addCase(sendCodesForWithdraw.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(confirmWithdraw.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(confirmWithdraw.pending, state => {
        state.pending = true;
      })
      .addCase(confirmWithdraw.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(cancelColdWalletsWithdrawal.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(cancelColdWalletsWithdrawal.pending, state => {
        state.pending = true;
      })
      .addCase(cancelColdWalletsWithdrawal.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(checkMitekStatus.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(checkMitekStatus.pending, state => {
        state.pending = true;
      })
      .addCase(checkMitekStatus.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      });
  },
});

export const {
  setColdStorageTab,
  updateColdWallet,
  resetColdWallets,
  setColdWalletCreateVaultPayload,
  setWithdrawPayload,
} = coldWalletsSlice.actions;
export default coldWalletsSlice;
