import React from "react";
import BigNumber from "bignumber.js";

import { useTranslation } from "context";
import { useAppSelector } from "store/store";
import { useTooltip } from "hooks";

import { Box, FlexGap, Image, Text } from "components";
import { StyledUserAssetsContainer } from "./styled";

import { NATIVE_CURRENCIES_LOGO, SHORT_CHAIN_NAMES } from "configs/web3";

import { WalletsTypeEnum } from "store/wallets/enums";
import { WalletSingleItem } from "store/wallets/types";

const UserAssets: React.FC = () => {
  const wallets = useAppSelector(state => state.profile.userAssets.wallets);
  const allTokensList = useAppSelector(state => state.tokensList.allTokensList);

  const { t } = useTranslation();
  const { targetRef: targetRefNetwork, tooltip: networkTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const { targetRef: targetRefBalance, tooltip: balanceTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const allUsersWalletsAssets = Object.entries(wallets ?? {});

  const sortArrayByChainId = (arr: WalletSingleItem[]) => {
    const result: { [key: string]: WalletSingleItem[] } = {};

    arr.forEach(obj => {
      const id = obj.currency.chainId;

      if (!result[id]) {
        result[id] = [];
      }

      result[id].push(obj);
    });

    return Object.values(result);
  };

  return (
    <StyledUserAssetsContainer>
      {allUsersWalletsAssets.map((item, index) => {
        const [type, wallets] = item;

        const isFirstIndex = index === 0;

        const assetsByChainId = sortArrayByChainId(wallets);

        return (
          <Box key={index} width="100%" mb={isFirstIndex ? "10px" : "0px"}>
            <Text color="neutral500" textScale="body3">
              {type === WalletsTypeEnum.hot ? "Wallets assets" : "Cold storage assets"}
            </Text>

            {assetsByChainId.length ? (
              assetsByChainId.map((item, index) => {
                const isFirstAsset = index === 0;
                return (
                  <div key={index}>
                    {item.map((assets, index) => {
                      const isFirstIndex = index === 0;

                      const currency = assets.currency;

                      return (
                        <FlexGap
                          justifyContent="space-between"
                          width="100%"
                          borderTop={isFirstAsset ? "none" : "1px solid"}
                          borderTopColor="neutral300"
                          pt={isFirstAsset ? "0px" : "14px"}
                          key={index}
                        >
                          <Box width="50%">
                            {isFirstAsset && isFirstIndex && (
                              <Text color="neutral500" textScale="caption2" $fontWeight="medium" my="14px">
                                {t("Network")}
                              </Text>
                            )}

                            {isFirstIndex && (
                              <FlexGap alignItems="center" pb="14px">
                                <Box minHeight="24px" minWidth="24px">
                                  <Image
                                    src={NATIVE_CURRENCIES_LOGO[currency.chainId]}
                                    alt={SHORT_CHAIN_NAMES[currency.chainId]}
                                    width="24px"
                                    aspectRatio={1}
                                    variant="circle"
                                  />
                                </Box>

                                <Text px="12px" color="neutral800" textScale="body3" ellipsis ref={targetRefNetwork}>
                                  {SHORT_CHAIN_NAMES[currency.chainId]}
                                </Text>

                                {networkTooltip}
                              </FlexGap>
                            )}
                          </Box>

                          <FlexGap flexDirection="column" width="50%">
                            {isFirstAsset && isFirstIndex && (
                              <Text color="neutral500" textScale="caption2" $fontWeight="medium" my="14px">
                                {t("Amount")}
                              </Text>
                            )}

                            <FlexGap gap="8px" flexDirection="column" pb="14px">
                              <FlexGap gap="8px" alignItems="center">
                                <Box minHeight="24px" minWidth="24px">
                                  <Image
                                    src={
                                      allTokensList
                                        ? allTokensList[assets.currency.chainId][assets.currency.publicCode]["logoURI"]
                                        : ""
                                    }
                                    alt={currency.symbol}
                                    width="24px"
                                    aspectRatio={1}
                                    variant="circle"
                                  />
                                </Box>

                                <Text
                                  color="neutral800"
                                  textScale="caption1"
                                  $fontWeight="medium"
                                  ref={targetRefBalance}
                                  ellipsis
                                >
                                  {BigNumber(assets.balance).toFormatExtended(8)}
                                </Text>

                                {balanceTooltip}

                                <Text color="neutral800" textScale="caption1" $fontWeight="medium">
                                  {currency.symbol}
                                </Text>
                              </FlexGap>
                            </FlexGap>
                          </FlexGap>
                        </FlexGap>
                      );
                    })}
                  </div>
                );
              })
            ) : (
              <Text color="neutral600" textScale="caption1" textAlign="center">
                {t("You don’t have any assets of this type of wallet")}
              </Text>
            )}
          </Box>
        );
      })}
    </StyledUserAssetsContainer>
  );
};

export default UserAssets;
