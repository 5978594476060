import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";
import { DeliveryAddressesSideModalProps } from "./types";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => (state.modal.props as unknown as DeliveryAddressesSideModalProps).currentAddress,
    (state: AppState) => (state.modal.props as unknown as DeliveryAddressesSideModalProps).setAddressHandler,
    (state: AppState) => state.addresses.addressesList,
  ],
  (currentAddress, setAddressHandler, addressesList) => ({
    currentAddress,
    setAddressHandler,
    addressesList,
  }),
);
