import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "navigation/routes";

import { Flex } from "components/layout";

import { StyledFlex, StyledLogo, StyledLogoText } from "./styled";

import { LogoProps } from "./types";

const Logo: React.FC<LogoProps> = ({ isShowLogoText = false, size = "small", ...props }) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(ROUTES.home);
  };

  return (
    <StyledFlex
      p={{ laptopL: `${isShowLogoText ? "0px 24px" : "0px 16px"}` }}
      justifyContent={{ tablet: "center", laptopL: "flex-start" }}
      alignItems={{ tablet: "center" }}
    >
      <Flex alignItems="center" {...props}>
        <StyledLogo size={size} cursor="pointer" onClick={onClickHandler} />

        <Flex alignItems="center">
          <StyledLogoText size={size} isShowLogoText={isShowLogoText} cursor="pointer" onClick={onClickHandler} />
        </Flex>
      </Flex>
    </StyledFlex>
  );
};

export default Logo;
