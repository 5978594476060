import React from "react";
import isEmpty from "lodash/isEmpty";

import {
  Text,
  Box,
  Button,
  InputGroup,
  Input,
  Camera,
  FlexGap,
  InputNumeric,
  WalletErrorMessage,
  StyledLink,
  SingleDateSelect,
} from "components";
import { ImageUpload, Select } from "../";

import { useSupport } from "hooks";
import { useCameraHandle, useKycFormHandle } from "../../hooks";

import { useTranslation } from "context";
import { IdentityVerificationStep2Props } from "../types";

const IdentityVerificationStep2: React.FC<IdentityVerificationStep2Props> = ({
  type,
  fieldsList,
  errors,
  touched,
  values,
  isValid,
  fieldProps,
  stepCount,
  currentStep,
  pending,
  isCameraStarted,
  setFieldValue,
  setFieldTouched,
  handleSubmit,
  setCurrentStep,
  backToStep1,
  setIsCameraStarted,
}) => {
  const { t } = useTranslation();
  const { supportHandler } = useSupport();

  const { cameraErrorMessage, startCamera, cameraFieldPath, setCameraErrorMessage } = useCameraHandle({
    setIsCameraStarted,
  });

  const {
    continueBtnClick,
    isCurrentStepValid,
    fieldsListToFlatArr,
    getIndexedProperty,
    onUserInput,
    getDateOfBirth,
    setDateOfBirth,
    setDateOfBirthTouched,
    getDateOfBirthErrors,
    isDateOfBirthTouched,
  } = useKycFormHandle({
    stepCount,
    currentStep,
    handleSubmit,
    setCurrentStep,
    setFieldValue,
    setFieldTouched,
    isValid,
    errors,
    type,
    touched,
  });

  const onBackClick = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    } else {
      backToStep1();
    }
  };

  return (
    <Box width="100%">
      {!cameraErrorMessage && isCameraStarted ? (
        <Camera
          cameraFieldPath={cameraFieldPath}
          setFieldValue={setFieldValue}
          setIsCameraStarted={setIsCameraStarted}
          setCameraErrorMessage={setCameraErrorMessage}
        />
      ) : (
        <Box>
          {cameraErrorMessage ? <WalletErrorMessage message={cameraErrorMessage} mt="16px" /> : null}
          <Text mt="28px" mb="16px" textScale="caption1" color="neutral500">
            {t("Please fill all fields below")}
          </Text>

          <form onSubmit={handleSubmit}>
            {!isEmpty(fieldsList) && !isEmpty(values)
              ? fieldsListToFlatArr(fieldsList).map((item, index) => {
                  const currentValue = getIndexedProperty<string>(values, item.path!);
                  // fix bug with uncontrolled input
                  return currentValue !== undefined ? (
                    <Box key={index} width="100%">
                      {item.type === "string" ? (
                        <InputGroup
                          label={item.label ?? item.name}
                          error={getIndexedProperty(errors, item.path!)}
                          isTouched={getIndexedProperty(touched, item.path!)}
                        >
                          <Input placeholder={item.label ?? item.name} {...fieldProps(item.path!)} />
                        </InputGroup>
                      ) : item.type === "integer" &&
                        item.name !== "MonthOfBirth" &&
                        item.name !== "DayOfBirth" &&
                        item.name !== "YearOfBirth" ? (
                        <InputGroup
                          label={item.label ?? item.name}
                          error={getIndexedProperty(errors, item.path!)}
                          isTouched={getIndexedProperty(touched, item.path!)}
                        >
                          <InputNumeric
                            placeholder={item.label ?? item.name}
                            value={getIndexedProperty<string>(values, item.path!)}
                            onUserInput={value => onUserInput(value, item.path!)}
                            onBlur={async () => await setFieldTouched(item.path!, true)}
                          />
                        </InputGroup>
                      ) : item.type === "integer" && item.name === "MonthOfBirth" ? (
                        <InputGroup
                          label={t("Date Of Birth")}
                          error={getDateOfBirthErrors(item.path!)}
                          isTouched={isDateOfBirthTouched(item.path!)}
                        >
                          <SingleDateSelect
                            selectedDay={getDateOfBirth(values, item.path!)}
                            setSelectedDay={val => setDateOfBirth(val, item.path!)}
                            onBlur={() => setDateOfBirthTouched(item.path!)}
                          />
                        </InputGroup>
                      ) : item.type === "enum" ? (
                        <Select
                          path={item.path!}
                          item={item}
                          error={getIndexedProperty(errors, item.path!)}
                          touched={getIndexedProperty(touched, item.path!)}
                          currentValue={getIndexedProperty(values, item.path!)}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                      ) : item.type === "file" ? (
                        item.name === "LivePhoto" && !getIndexedProperty(values, item.path!) ? (
                          <Box mb="24px">
                            <Text mb="8px" textScale="caption1" color="neutral500">
                              {item.label}
                            </Text>
                            <Button
                              disabled={!!cameraErrorMessage}
                              onClick={() => startCamera(item, item.path!)}
                              variant="outline"
                              width="170px"
                            >
                              {t("Start camera")}
                            </Button>
                          </Box>
                        ) : (
                          <ImageUpload
                            path={item.path!}
                            src={getIndexedProperty(values, item.path!)}
                            item={item}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                          />
                        )
                      ) : null}
                    </Box>
                  ) : null;
                })
              : null}
            <StyledLink
              color="neutral500"
              mb="18px"
              textScale="caption2"
              $fontWeight="medium"
              onClick={() => supportHandler()}
              width="100%"
              justifyContent="center"
            >
              {t("Do you need help?")}
            </StyledLink>
            <FlexGap gap="8px" mb={{ _: "20px", tablet: "30px" }}>
              <Button width="100%" variant="outline" onClick={onBackClick} isLoading={pending}>
                {t("Back")}
              </Button>
              <Button disabled={!isCurrentStepValid()} width="100%" onClick={continueBtnClick} isLoading={pending}>
                {t("Continue")}
              </Button>
            </FlexGap>
          </form>
        </Box>
      )}
    </Box>
  );
};

export default IdentityVerificationStep2;
