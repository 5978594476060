import React from "react";
import BigNumber from "bignumber.js";

import { useTranslation } from "context";
import { useGetFiatSymbolAndAbbreviation, useTooltip } from "hooks";

import { Box, Flex, Column, Row, Text, Skeleton, FlexGap, Heading } from "components";
import { EyeClosedIcon, EyeIcon, RotateIcon } from "components/svg";

import { useAppDispatch, useAppSelector } from "store/store";

import { hiddenBalance } from "pages/main/wallets/components/balance-preview";
import { setTotalBalanceVisibility } from "store/wallets/hot-wallets";

import { TotalBalanceProps } from "./types";

const TotalBalance: React.FC<TotalBalanceProps> = ({ balance }) => {
  const pending = useAppSelector(state => state.hotWallets.wallets.pending);
  const isBalanceVisible = useAppSelector(state => state.hotWallets.isBalanceVisible);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { fiatAbbreviation } = useGetFiatSymbolAndAbbreviation();

  const { targetRef: targetRefBalance, tooltip: balanceTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });
  const { targetRef: targetRefCurrency, tooltip: currencyTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const handleBalanceVisibility = () => {
    dispatch(setTotalBalanceVisibility(!isBalanceVisible));
  };

  return (
    <Box width={{ _: "100%", laptop: "50%" }}>
      <Column>
        <Row alignItems="center">
          <Text textScale="body2" color="neutral600" $fontWeight="medium">
            {t("Total Balance")}
          </Text>

          <Flex mx="12px" width="24px" height="24px" onClick={handleBalanceVisibility}>
            <RotateIcon
              variant="rotateY"
              isToggled={isBalanceVisible}
              firstIcon={<EyeIcon width="24px" height="24px" />}
              secondIcon={<EyeClosedIcon width="24px" height="24px" />}
            />
          </Flex>
        </Row>

        {pending ? (
          <Skeleton height="48px" width="300px" mt="12px" mb="24px" />
        ) : (
          <FlexGap gap="4px" mt="12px" mb="24px" maxWidth="100%">
            <Box maxWidth="70%">
              <Heading as="h3" scale="h3" ref={targetRefBalance} ellipsis={isBalanceVisible}>
                {isBalanceVisible ? `~${BigNumber(balance).toFormatExtended(2)}` : hiddenBalance}
              </Heading>
            </Box>

            <Box maxWidth="30%">
              <Heading as="h3" scale="h3" ref={targetRefCurrency} ellipsis>
                {fiatAbbreviation}
              </Heading>
            </Box>

            {currencyTooltip}
            {balanceTooltip}
          </FlexGap>
        )}
      </Column>
    </Box>
  );
};

export default TotalBalance;
