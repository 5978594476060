import React, { useEffect, useRef } from "react";

import { useForm, useScript } from "hooks";
import { useValidationSchema, useHandleAddress, useCountryCode } from "../../hooks";

import { Text, Column, Input, InputGroup, Checkbox, Button, FlexGap } from "components";
import { ArrowRightIcon } from "components/svg";
import CloseModalWrapper from "components/modal/components/close-modal-wrapper";
import { StyledAddressesForm } from "../../styled";
import { StyledSideModalWrapper } from "components/modal/components/styled";

import { useTranslation } from "context";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";

import { SCRIPT_SRCS } from "configs";
import { AddressesSideModalInnerProps } from "./types";

const AddressesSideModalInner: React.FC<AddressesSideModalInnerProps> = ({
  closeHandler,
  onBackBtnClick,
  saveCallBack,
}) => {
  const pending = useAppSelector(state => state.addresses.pending);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const countryAutoCompleteRef = useRef<HTMLInputElement>(null);
  const stateAutoCompleteRef = useRef<HTMLInputElement>(null);
  const cityAutoCompleteRef = useRef<HTMLInputElement>(null);
  const streetAutoCompleteRef = useRef<HTMLInputElement>(null);
  let countryAutoComplete: Window;
  let stateAutoComplete: Window;
  let cityAutoComplete: Window;
  let streetAutoComplete: Window;

  const status = useScript(SCRIPT_SRCS.googleapis, {
    removeOnUnmount: true,
  });

  const { validationSchema, initialValues } = useValidationSchema();
  const { errors, touched, values, fieldProps, handleSubmit, setFieldTouched, setFieldValue, isValid } = useForm({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit(values) {
      saveHandler(values);
    },
  });

  const { handleScriptLoad, saveHandler, setDefaultAddress, setCountryCodeOnBlur } = useHandleAddress({
    values,
    setFieldValue,
    setFieldTouched,
    saveCallBack,
  });

  useEffect(() => {
    if (status === "ready") {
      handleScriptLoad(countryAutoCompleteRef, countryAutoComplete, "country", "country");
      handleScriptLoad(stateAutoCompleteRef, stateAutoComplete, "administrative_area_level_1", "state");
      handleScriptLoad(cityAutoCompleteRef, cityAutoComplete, "locality", "city");
      handleScriptLoad(streetAutoCompleteRef, streetAutoComplete, "route", "street");
    }
  }, [status]);

  useCountryCode(setFieldTouched);

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  return (
    <StyledSideModalWrapper>
      <Column width="100%" height="max-content" flexGrow="1">
        <CloseModalWrapper closeModalHandler={closeModalHandler} mb="56px">
          <FlexGap gap="12px">
            {onBackBtnClick && (
              <ArrowRightIcon width="24px" height="24px" color="neutral600" rotate="180deg" onClick={onBackBtnClick} />
            )}

            <Text textScale="body2" color="neutral600" $fontWeight="medium">
              {t("New Address Details")}
            </Text>
          </FlexGap>
        </CloseModalWrapper>

        <StyledAddressesForm onSubmit={handleSubmit}>
          <InputGroup label={t("Address Name *")} error={errors.address} isTouched={touched.address}>
            <Input placeholder={t("Address Name *")} {...fieldProps("address")} />
          </InputGroup>

          <InputGroup label={t("Country *")} error={errors.country} isTouched={touched.country}>
            <Input
              placeholder={t("Country *")}
              {...fieldProps("country")}
              onBlur={setCountryCodeOnBlur}
              ref={countryAutoCompleteRef}
            />
          </InputGroup>

          <InputGroup label={t("State/Province")} error={errors.state} isTouched={touched.state}>
            <Input placeholder={t("State/Province")} {...fieldProps("state")} ref={stateAutoCompleteRef} />
          </InputGroup>

          <InputGroup label={t("City *")} error={errors.city} isTouched={touched.city}>
            <Input placeholder={t("City *")} {...fieldProps("city")} ref={cityAutoCompleteRef} />
          </InputGroup>

          <InputGroup label={t("Street *")} error={errors.street} isTouched={touched.street}>
            <Input placeholder={t("Street *")} {...fieldProps("street")} ref={streetAutoCompleteRef} />
          </InputGroup>

          <InputGroup label={t("Building Number *")} error={errors.buildingNumber} isTouched={touched.buildingNumber}>
            <Input placeholder={t("Building Number *")} {...fieldProps("buildingNumber")} />
          </InputGroup>

          <InputGroup label={t("Apartment")} error={errors.apartments} isTouched={touched.apartments}>
            <Input placeholder={t("Apartment")} {...fieldProps("apartments")} />
          </InputGroup>

          <InputGroup label={t("Post Code *")} error={errors.postCode} isTouched={touched.postCode}>
            <Input placeholder={t("Post Code *")} {...fieldProps("postCode")} />
          </InputGroup>

          <Checkbox
            mb="24px"
            label={t("Set as default address")}
            checked={values.isDefault}
            onChange={setDefaultAddress}
          />

          <FlexGap gap="14px" mt="auto">
            <Button width="100%" variant="outline" onClick={closeHandler} isLoading={pending}>
              {t("Cancel")}
            </Button>
            <Button disabled={!isValid} width="100%" type="submit" isLoading={pending}>
              {t("Save")}
            </Button>
          </FlexGap>
        </StyledAddressesForm>
      </Column>
    </StyledSideModalWrapper>
  );
};

export default AddressesSideModalInner;
