import React from "react";

import { useTranslation } from "context";
import { isErrorResult } from "services";

import { ModalDeleteIcon } from "components/svg";

import { hideModal, partialUpdateModalProps, showModal } from "store/modal";
import { useAppDispatch, useAppSelector } from "store/store";
import { cancelEscrow } from "store/escrow/escrow-process/actions";

import { ModalProps, PartialUpdateModalProps } from "store/modal/types";
import { ActionModalProps } from "components/modal/components/action-modal/types";

export const useShowModals = () => {
  const currentEscrow = useAppSelector(state => state.escrowProcess.currentEscrow);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const confirmEscrowDeletion = () => {
    const _partialProps = partialUpdateModalProps as PartialUpdateModalProps<ActionModalProps>;

    dispatch(_partialProps({ isLoading: true }));
    dispatch(cancelEscrow(currentEscrow!.body.id)).then(res => {
      if (!isErrorResult(res.payload)) {
        closeModalHandler();
      }
      dispatch(_partialProps({ isLoading: false }));
    });
  };

  const cancelHandler = () => {
    const _showModal = showModal as ModalProps<ActionModalProps>;

    dispatch(
      _showModal({
        modalName: "actionModal",
        rootId: "escrow-process",
        props: {
          icon: <ModalDeleteIcon height="72px" width="72px" />,
          title: t("Cancel Escrow?"),
          text: t("Are you sure want to cancel this escrow?"),
          isLoading: false,
          btnArr: [
            {
              buttonText: t("No"),
              callback: closeModalHandler,
              props: {
                variant: "outline",
              },
            },
            {
              buttonText: t("Cancel Escrow"),
              callback: confirmEscrowDeletion,
            },
          ],
        },
      }),
    );
  };

  const modify = () => {
    const _showModal = showModal as ModalProps<ActionModalProps>;

    dispatch(
      _showModal({
        modalName: "escrowDetailsModifySideModal",
        rootId: "escrow-process",
        modalType: "rightMenu",
      }),
    );
  };

  return { modify, cancelHandler };
};
