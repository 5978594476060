import React from "react";

import { Text, Column, StyledLink } from "components";
import { StyledMicrophoneContainerWrapper } from "./styled";
import CloseModalWrapper from "../close-modal-wrapper";
import { MicrophoneLoading, Microphone, Recording } from "./components";

import { useTranslation } from "context";

import { useHandlePageVisibility, useMatchBreakpoints } from "hooks";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";

import { microphoneStatusEnum } from "./types";
import { StyledModalWrapper } from "../styled";
import { selectCombinedData } from "./selectors";

const VoiceCaptureModal: React.FC = () => {
  const {
    title,
    verifyDescription,
    microphoneStatus = microphoneStatusEnum.LOADING,
    verifyCallback,
    closeCallback,
  } = useAppSelector(selectCombinedData);

  const { t } = useTranslation();
  const { isMobile } = useMatchBreakpoints();
  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    closeCallback?.();
    dispatch(hideModal());
  };

  useHandlePageVisibility(closeModalHandler);

  const Component = {
    [microphoneStatusEnum.LOADING]: MicrophoneLoading,
    [microphoneStatusEnum.LOADED]: Microphone,
    [microphoneStatusEnum.RECORDING]: Recording,
  }[microphoneStatus];

  const voiceCaptureDescription = verifyCallback
    ? t("Click the button below and pronounce the phrase under the button.")
    : t("Click the button below and pronounce the phrase under the button.") + " " + t("Repeat three times.");

  return (
    <StyledModalWrapper>
      <Column width="100%">
        <CloseModalWrapper closeModalHandler={closeModalHandler}>
          {isMobile && (
            <Text color="neutral600" textAlign="center" textScale="body2" $fontWeight="bold">
              {title}
            </Text>
          )}
        </CloseModalWrapper>

        {!isMobile && (
          <Text color="neutral600" textAlign="center" textScale="body2" $fontWeight="bold">
            {title}
          </Text>
        )}

        <Text
          wordBreak="break-word"
          color="neutral600"
          mt={{ _: "18px", laptop: "12px" }}
          textAlign="center"
          textScale="caption1"
        >
          {voiceCaptureDescription}
        </Text>
      </Column>

      <StyledMicrophoneContainerWrapper>
        <Component />

        {microphoneStatus !== microphoneStatusEnum.LOADING && (
          <>
            {verifyDescription && (
              <Text textScale="caption1" color="neutral600" mt="12px">
                {verifyDescription}
              </Text>
            )}

            {verifyCallback && (
              <StyledLink
                mt="6px"
                color="primary"
                onClick={verifyCallback}
                textScale="caption1"
                $fontWeight="bold"
                textAlign="center"
              >
                {t("Verify with codes")}
              </StyledLink>
            )}
          </>
        )}
      </StyledMicrophoneContainerWrapper>
    </StyledModalWrapper>
  );
};

export default VoiceCaptureModal;
