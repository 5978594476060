import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";

import { Flex, Button, Loader, Column, toastOptionsError } from "components";
import { StyledVideo } from "./styled";

import { BUFFER_LENGTH } from "hooks/use-upload-image";
import { useTranslation } from "context";

import { compressImage } from "utils/helpers";

import { MAX_SIZE } from "components/modal/components/identity-verification-modal/components/image-upload";

import { CameraProps } from "./types";

const Camera: React.FC<CameraProps> = ({
  cameraFieldPath,
  setFieldValue,
  setIsCameraStarted,
  setCameraErrorMessage,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const streamRef = useRef<MediaStream | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (videoRef.current) {
      startStream();
    }

    return () => {
      stopStream();
    };
  }, [videoRef.current]);

  const startStream = async () => {
    setIsLoading(true);

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
      streamRef.current = stream;

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
      setIsLoading(false);
    } catch (error) {
      setCameraErrorMessage(
        t(
          "Please, use your camera to take a real-time photo. Otherwise, you will be unable to proceed. To grant access to the camera on your device, go to the following path. Chrome: Settings > Privacy and security > Site settings > Camera. Opera: Settings > Advanced > Privacy and security > Site settings > Camera. Firefox: Preferences > Privacy and security > Permissions > Camera > Settings. Safari: Safari > Websites > Camera",
        ),
      );
      setIsLoading(false);
      console.error("stream error", error);
    }
  };

  const stopStream = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
  };

  const convertFileToDataURL = (file: File) => {
    const reader = new FileReader();

    reader.onload = () => {
      onImageLoad(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const onImageLoad = async (imageURL: string) => {
    await setFieldValue(cameraFieldPath, imageURL);
  };

  const takePhoto = async () => {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      canvas.width = video.getBoundingClientRect().width;
      canvas.height = video.getBoundingClientRect().height;
      context ? context.drawImage(video, 0, 0, canvas.width, canvas.height) : null;

      const photoURL = canvas.toDataURL("image/jpeg");
      stopStream();
      try {
        const compressedFile = await compressImage(photoURL, BUFFER_LENGTH * MAX_SIZE);
        convertFileToDataURL(compressedFile);
      } catch (error) {
        toast.error(t("We were unable to compress your file, please try another one."), toastOptionsError);
        console.error("compressImage", error);
      }
      setIsCameraStarted(false);
    }
  };

  return (
    <Flex alignItems="center" justifyContent="center" mt="28px">
      {isLoading && (
        <Flex justifyContent="center" width="100%">
          <Loader height="100px" />
        </Flex>
      )}
      <Column alignItems="center" style={{ display: isLoading ? "none" : "flex" }}>
        <StyledVideo ref={videoRef} autoPlay playsInline muted />
        <Button onClick={takePhoto} variant="outline" mt="16px">
          {t("Take Snapshot")}
        </Button>
        <canvas ref={canvasRef} style={{ display: "none" }} />
      </Column>
    </Flex>
  );
};

export default Camera;
