import {
  BuyCryptoIcon,
  ColdStorageIcon,
  EscrowIcon,
  SwapSidebarIcon,
  WalletIcon,
  DashboardIcon,
  MessagesIcon,
  StatementsIcon,
} from "components/svg";
import { ROUTES } from "navigation/routes";

export const DESKTOP_MENU_OPEN_SIZE = "260px";

export const DESKTOP_MENU_CLOSE_SIZE = "72px";

export const NAVIGATE_ITEM = [
  { title: "Dashboard", icon: DashboardIcon, path: ROUTES.dashboard },
  { title: "Wallet", icon: WalletIcon, path: ROUTES.wallet },
  { title: "Buy", icon: BuyCryptoIcon, path: ROUTES.buyCrypto },
  { title: "Swap", icon: SwapSidebarIcon, path: ROUTES.swap },
  { title: "Cold Storage", icon: ColdStorageIcon, path: ROUTES.coldStorage },
  { title: "Escrow", icon: EscrowIcon, path: ROUTES.escrows },
  { title: "Messages", icon: MessagesIcon, path: ROUTES.messages },
  { title: "Statements", icon: StatementsIcon, path: ROUTES.statements },
];
