import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import { useEffect } from "react";
import { useAppSelector } from "../store/store";

export const useHideOverflow = ({ isMobileMenuOpen }: { isMobileMenuOpen: boolean }) => {
  const modalName = useAppSelector(state => state.modal.modalName);

  const { visible } = useWalletModal();

  useEffect(() => {
    if (isMobileMenuOpen || visible || modalName) {
      document.body.style.overflowY = "hidden";
    } else if (!isMobileMenuOpen && !visible && !modalName) {
      document.body.style.overflowY = "auto";
    }
  }, [isMobileMenuOpen, visible, modalName]);
};
