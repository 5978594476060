import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral1000", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 16 12" {...props} color="transparent">
      <path
        d="M14.6663 1.41797L5.49967 10.5846L1.33301 6.41797"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
