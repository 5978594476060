import { toast } from "react-toastify";
import { isErrorResult } from "services";

import { toastOptionsError } from "components";

import { useAppDispatch } from "store/store";
import { verify2FASecret } from "store/profile/actions";
import { hideModal } from "store/modal";

import { initialValues } from "./use-validation-schema";
import { UseHandleDataArgs } from "./types";

export const useHandleData = ({
  setFieldValue,
  setFieldError,
  successCallback,
  showErrorToaster,
}: UseHandleDataArgs) => {
  const dispatch = useAppDispatch();

  const submitHandler = async (formValues: typeof initialValues) => {
    dispatch(verify2FASecret({ token: formValues.code })).then(async res => {
      if (isErrorResult(res.payload)) {
        if (showErrorToaster && res.payload.code === 408) {
          toast.error(res.payload.message, toastOptionsError);
          dispatch(hideModal());
        } else {
          await setFieldValue("code", "");
          setFieldError("code", res.payload.message);
        }
      } else {
        successCallback();
      }
    });
  };

  const onUserInput = async (value: string) => {
    await setFieldValue("code", value);
  };

  return {
    submitHandler,
    onUserInput,
  };
};
