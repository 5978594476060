import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";
import { VoiceCaptureModalProps } from "./types";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => (state.modal.props as unknown as VoiceCaptureModalProps).title,
    (state: AppState) => (state.modal.props as unknown as VoiceCaptureModalProps).verifyDescription,
    (state: AppState) => (state.modal.props as unknown as VoiceCaptureModalProps).microphoneStatus,
    (state: AppState) => (state.modal.props as unknown as VoiceCaptureModalProps).verifyCallback,
    (state: AppState) => (state.modal.props as unknown as VoiceCaptureModalProps).closeCallback,
  ],
  (title, verifyDescription, microphoneStatus, verifyCallback, closeCallback) => ({
    title,
    verifyDescription,
    microphoneStatus,
    verifyCallback,
    closeCallback,
  }),
);
