import React from "react";

import { Flex, Text } from "components";
import { RotateIcon, BellIcon } from "components/svg";
import { StyledCountWrapper } from "./styled";

import { showModal } from "store/modal";
import { useAppDispatch, useAppSelector } from "store/store";

import { ActionModalProps } from "components/modal/components/action-modal/types";
import { ModalProps } from "store/modal/types";

const Notifications: React.FC = () => {
  const unreadNotificationCount = useAppSelector(state => state.notifications.unreadNotificationCount);

  const dispatch = useAppDispatch();

  const showNotificationsSideModal = () => {
    const _showModal = showModal as ModalProps<ActionModalProps>;
    dispatch(
      _showModal({
        modalName: "notificationsSideModal",
        rootId: "/",
        modalType: "rightMenu",
      }),
    );
  };

  return (
    <Flex onClick={showNotificationsSideModal} position="relative" cursor="pointer">
      <RotateIcon
        variant="rotateY"
        isToggled
        firstIcon={<BellIcon width="24px" height="24px" />}
        secondIcon={<BellIcon width="24px" height="24px" />}
      />
      {unreadNotificationCount > 0 ? (
        <StyledCountWrapper>
          <Text color="neutral0" $fontWeight="medium" textScale="hairlineS">
            {unreadNotificationCount > 99 ? "99+" : unreadNotificationCount}
          </Text>
        </StyledCountWrapper>
      ) : null}
    </Flex>
  );
};

export default Notifications;
