import { TransactionStatusType, EscrowStatusType, StatusesType, ChatStatusType } from "components/colored-tags/types";
import { EscrowStatusEnum } from "store/escrow/enums";
import { ChatStatusEnum } from "store/messages/types";
import { TransactionTypeEnum } from "store/wallets/enums";

export const TRANSACTION_STATUS: TransactionStatusType = {
  [TransactionTypeEnum.PENDING]: {
    title: "Pending",
    light: {
      colorBackground: "yellowLight",
      colorText: "yellow",
    },
    dark: {
      colorBackground: "yellowDark",
      colorText: "yellow",
    },
  },
  [TransactionTypeEnum.COMPLETED]: {
    title: "Completed",
    light: {
      colorBackground: "greenLight",
      colorText: "green",
    },
    dark: {
      colorBackground: "green",
      colorText: "greenLight",
    },
  },
  [TransactionTypeEnum.ACTIVE]: {
    title: "Active",
    light: {
      colorBackground: "greenLight",
      colorText: "green",
    },
    dark: {
      colorBackground: "green",
      colorText: "greenLight",
    },
  },
  [TransactionTypeEnum.FAILED]: {
    title: "Failed",
    light: {
      colorBackground: "redLight",
      colorText: "red",
    },
    dark: {
      colorBackground: "red",
      colorText: "redLight",
    },
  },
  [TransactionTypeEnum.REQUESTED]: {
    title: "Requested",
    light: {
      colorBackground: "blueLight",
      colorText: "blue",
    },
    dark: {
      colorBackground: "blue",
      colorText: "blueLight",
    },
  },
  [TransactionTypeEnum.REJECTED]: {
    title: "Rejected",
    light: {
      colorBackground: "redLight",
      colorText: "red",
    },
    dark: {
      colorBackground: "red",
      colorText: "redLight",
    },
  },
  [TransactionTypeEnum.CANCELED]: {
    title: "Canceled",
    light: {
      colorBackground: "redLight",
      colorText: "red",
    },
    dark: {
      colorBackground: "red",
      colorText: "redLight",
    },
  },
  [TransactionTypeEnum.UNDER_CANCELLATION]: {
    title: "Cancellation",
    light: {
      colorBackground: "blueLight",
      colorText: "blue",
    },
    dark: {
      colorBackground: "blue",
      colorText: "blueLight",
    },
  },
  [TransactionTypeEnum.BLACKLISTED]: {
    title: "Blacklisted",
    light: {
      colorBackground: "redLight",
      colorText: "red",
    },
    dark: {
      colorBackground: "red",
      colorText: "redLight",
    },
  },
  [TransactionTypeEnum.UNKNOWN]: {
    title: "Unknown",
    light: {
      colorBackground: "yellowLight",
      colorText: "yellow",
    },
    dark: {
      colorBackground: "yellowLight",
      colorText: "yellow",
    },
  },
};

const ESCROW_STATUS: EscrowStatusType = {
  [EscrowStatusEnum.ACTIVE]: {
    title: "Active",
    light: {
      colorBackground: "yellowLight",
      colorText: "yellow",
    },
    dark: {
      colorBackground: "yellowLight",
      colorText: "yellow",
    },
  },
  [EscrowStatusEnum.CANCELED]: {
    title: "Canceled",
    light: {
      colorBackground: "redLight",
      colorText: "red",
    },
    dark: {
      colorBackground: "red",
      colorText: "redLight",
    },
  },
  [EscrowStatusEnum.COMPLETED]: {
    title: "Completed",
    light: {
      colorBackground: "greenLight",
      colorText: "green",
    },
    dark: {
      colorBackground: "green",
      colorText: "greenLight",
    },
  },
  [EscrowStatusEnum.NEW]: {
    title: "New",
    light: {
      colorBackground: "primaryLight",
      colorText: "primary",
    },
    dark: {
      colorBackground: "primaryLight",
      colorText: "primary",
    },
  },
  [EscrowStatusEnum.REQUIRES_ACTION]: {
    title: "Req.Action",
    light: {
      colorBackground: "primaryLight",
      colorText: "primary",
    },
    dark: {
      colorBackground: "primaryLight",
      colorText: "primary",
    },
  },
  [EscrowStatusEnum.CLAIMED]: {
    title: "Claimed",
    light: {
      colorBackground: "redLight",
      colorText: "red",
    },
    dark: {
      colorBackground: "red",
      colorText: "redLight",
    },
  },
  [EscrowStatusEnum.COMPLETED_BY_AML]: {
    title: "Completed",
    light: {
      colorBackground: "greenLight",
      colorText: "green",
    },
    dark: {
      colorBackground: "green",
      colorText: "greenLight",
    },
  },
  [EscrowStatusEnum.CANCELED_BY_AML]: {
    title: "Canceled",
    light: {
      colorBackground: "redLight",
      colorText: "red",
    },
    dark: {
      colorBackground: "red",
      colorText: "redLight",
    },
  },
};

export const CHAT_STATUS: ChatStatusType = {
  [ChatStatusEnum.ACTIVE]: {
    title: "Active",
    light: {
      colorBackground: "yellowLight",
      colorText: "yellow",
    },
    dark: {
      colorBackground: "yellowLight",
      colorText: "yellow",
    },
  },
  [ChatStatusEnum.ARCHIVED]: {
    title: "Archived",
    light: {
      colorBackground: "redLight",
      colorText: "red",
    },
    dark: {
      colorBackground: "red",
      colorText: "redLight",
    },
  },
};

export const STATUSES: StatusesType = {
  ...TRANSACTION_STATUS,
  ...ESCROW_STATUS,
  ...CHAT_STATUS,
};
