import { createAsyncThunk } from "@reduxjs/toolkit";

import { isErrorResult, makeApiRequest } from "services";
import { ENDPOINTS_PROFILE } from "services";

import { ErrorResult } from "services/types";
import { AddressRequest, AddressResponse } from "./types";

export const createAddress = createAsyncThunk<AddressResponse, AddressRequest, { rejectValue: ErrorResult }>(
  "user/create-address",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<AddressResponse>({
      method: "POST",
      url: ENDPOINTS_PROFILE.addresses,
      data,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const updateAddress = createAsyncThunk<AddressResponse, AddressRequest, { rejectValue: ErrorResult }>(
  "user/update-address",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<AddressResponse>({
      method: "PATCH",
      url: ENDPOINTS_PROFILE.addresses + data.id,
      data,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const deleteAddress = createAsyncThunk<AddressResponse, AddressRequest, { rejectValue: ErrorResult }>(
  "user/delete-address",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<AddressResponse>({
      method: "DELETE",
      url: ENDPOINTS_PROFILE.addresses + data.id,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getAddressesList = createAsyncThunk<AddressResponse[], undefined, { rejectValue: ErrorResult }>(
  "user/get-addresses-list",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<AddressResponse[]>({
      method: "GET",
      url: ENDPOINTS_PROFILE.addresses,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);
