import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LOCAL_STORAGE_KEYS } from "configs";
import { tabsIndex } from "configs/tabs";

import {
  forgotPassword,
  loginUser,
  loginUserApple,
  loginUserGoogle,
  registerUser,
  resetPassword,
  verifyEmail,
  getZendeskToken,
  confirmTwoFaAuth,
  sendCodesForTwoFa,
  finishSession,
  changePasswordCheck,
  changePasswordStatus,
  changePasswordConfirm,
} from "./actions";
import { AuthState } from "./types";

const applyIsLoggedIn = (key: string, isLoggedIn: "1" | "0") => {
  if (isLoggedIn === "1") {
    localStorage.setItem(key, isLoggedIn);
  } else {
    localStorage.removeItem(key);
  }

  return isLoggedIn === "1";
};

const initialState: AuthState = {
  isLoggedIn: false,
  isTemporaryLoggedIn: false,
  pending: false,
  error: null,
  activeTab: tabsIndex.auth.personalAccount,
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    ...initialState,
    isLoggedIn: localStorage.getItem(LOCAL_STORAGE_KEYS.isLoggedIn) === "1",
  },
  reducers: {
    resetAuth() {
      applyIsLoggedIn(LOCAL_STORAGE_KEYS.isLoggedIn, "0");

      return initialState;
    },
    setIsLoggedIn(state) {
      state.isLoggedIn = applyIsLoggedIn(LOCAL_STORAGE_KEYS.isLoggedIn, "1");
      state.isTemporaryLoggedIn = false;
    },
    setIsTemporaryLoggedIn(state) {
      state.isTemporaryLoggedIn = state.isLoggedIn;
      state.isLoggedIn = applyIsLoggedIn(LOCAL_STORAGE_KEYS.isLoggedIn, "0");
    },
    setAuthTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      // Login action
      .addCase(loginUser.pending, state => {
        state.pending = true;
      })
      .addCase(loginUser.fulfilled, state => {
        state.isTemporaryLoggedIn = true;

        state.error = null;
        state.pending = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.isTemporaryLoggedIn = false;
          state.pending = false;
        }
      })
      // Login Google
      .addCase(loginUserGoogle.fulfilled, state => {
        state.isTemporaryLoggedIn = true;

        state.error = null;
        state.pending = false;
      })
      .addCase(loginUserGoogle.pending, state => {
        state.pending = true;
      })
      .addCase(loginUserGoogle.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.isTemporaryLoggedIn = false;
          state.pending = false;
        }
      })
      // Login Apple
      .addCase(loginUserApple.fulfilled, state => {
        state.isTemporaryLoggedIn = true;

        state.error = null;
        state.pending = false;
      })
      .addCase(loginUserApple.pending, state => {
        state.pending = true;
      })
      .addCase(loginUserApple.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.isTemporaryLoggedIn = false;
          state.pending = false;
        }
      })
      // Registration
      .addCase(registerUser.fulfilled, state => {
        state.pending = false;
        state.error = null;
      })
      .addCase(registerUser.pending, state => {
        state.pending = true;
      })
      .addCase(registerUser.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      // Verify email
      .addCase(verifyEmail.fulfilled, state => {
        state.pending = false;
        state.error = null;
      })
      .addCase(verifyEmail.pending, state => {
        state.pending = true;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      // Forgot Password
      .addCase(forgotPassword.fulfilled, state => {
        state.pending = false;
        state.error = null;
      })
      .addCase(forgotPassword.pending, state => {
        state.pending = true;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      // Reset Password
      .addCase(resetPassword.fulfilled, state => {
        state.pending = false;
        state.error = null;
      })
      .addCase(resetPassword.pending, state => {
        state.pending = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      // Zendesk
      .addCase(getZendeskToken.pending, state => {
        state.pending = true;
      })
      .addCase(getZendeskToken.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(getZendeskToken.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      // Resend Codes for two-fa on email
      .addCase(sendCodesForTwoFa.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(sendCodesForTwoFa.pending, state => {
        state.pending = true;
      })
      .addCase(sendCodesForTwoFa.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      // Confirm two-fa auth
      .addCase(confirmTwoFaAuth.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(confirmTwoFaAuth.pending, state => {
        state.pending = true;
      })
      .addCase(confirmTwoFaAuth.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      // Finish session
      .addCase(finishSession.pending, state => {
        state.pending = true;
      })
      .addCase(finishSession.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(finishSession.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(changePasswordCheck.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(changePasswordCheck.pending, state => {
        state.pending = true;
      })
      .addCase(changePasswordCheck.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(changePasswordStatus.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(changePasswordStatus.pending, state => {
        state.pending = true;
      })
      .addCase(changePasswordStatus.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(changePasswordConfirm.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(changePasswordConfirm.pending, state => {
        state.pending = true;
      })
      .addCase(changePasswordConfirm.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      });
  },
});

export const { resetAuth, setAuthTab, setIsLoggedIn, setIsTemporaryLoggedIn } = authSlice.actions;

export default authSlice;
