import styled from "styled-components";
import { StyledBiometricModalWrapper } from "../setup-biometric-modal/styled";

export const StyledFaceBiometricModalWrapper = styled(StyledBiometricModalWrapper)<{
  isShowHintMessage?: boolean;
  isVerify?: boolean;
}>`
  padding: 12px 0;
  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: ${({ isVerify }) => (isVerify ? "24px 0" : "24px 0px 0px")};
  }
  & .mitek-generic-guide {
    box-shadow: none;
    border: 2px solid ${({ theme }) => theme.colors.neutral0};
  }

  #mitekHintMessage {
    border-radius: ${({ theme }) => theme.radii.semiMedium};
    display: ${({ isShowHintMessage }) => (isShowHintMessage ? "block" : "none")};
    border: none;
    top: auto;
    bottom: -65px;

    &.small {
      font-size: 14px;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.neutral600};
      padding: 8px 0;
    }
  }
`;

export const StyledCameraContainerWrapper = styled.div<{ isVerify?: boolean }>`
  display: flex;
  position: relative;
  width: 100%;
  margin: 32px 0;
  height: auto;
  min-height: 600px;
  align-items: center;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin: 24px 0 0;
    height: 460px;
    min-height: auto;

    #mitekDisplayContainer {
      border-bottom-right-radius: ${({ theme, isVerify }) => !isVerify && theme.radii.medium};
      border-bottom-left-radius: ${({ theme, isVerify }) => !isVerify && theme.radii.medium};
    }
  }
`;
