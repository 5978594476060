import React from "react";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";

import { AddressesSideModalProps } from "./types";
import AddressesSideModalInner from "./components/addresses-sid-modal-inner";

const AddressesSideModal: React.FC = () => {
  const onBackBtnClick = useAppSelector(
    state => (state.modal.props as unknown as AddressesSideModalProps).onBackBtnClick,
  );
  const saveCallBack = useAppSelector(state => (state.modal.props as unknown as AddressesSideModalProps).saveCallBack);

  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  return (
    <AddressesSideModalInner
      closeHandler={closeModalHandler}
      onBackBtnClick={onBackBtnClick}
      saveCallBack={saveCallBack}
    />
  );
};

export default AddressesSideModal;
