import { Loader } from "components";
import { lazyLoad } from "utils/loadable";

export const TotalBalanceChart = lazyLoad(
  () => import("./total-balance-chart"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);
export const DashboardTransactions = lazyLoad(
  () => import("./dashboard-transactions"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);
export const BalanceChart = lazyLoad(
  () => import("./balance-chart"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);
