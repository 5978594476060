import React, { PropsWithChildren } from "react";

import { useTranslation } from "context";
import { Text, Link } from "components";

import { getScanLinkSolana, truncateHash } from "configs/web3";
import { SOLANA_NETWORK } from "configs/solana";

import { ToastDescriptionWithTxProps } from "./types";

const SolanaToastDescriptionWithTx: React.FC<PropsWithChildren<ToastDescriptionWithTxProps>> = ({
  txHash,
  children,
  connected,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {typeof children === "string" ? (
        <Text color="neutral600" textScale="caption1">
          {children}
        </Text>
      ) : (
        children
      )}
      {txHash && connected && (
        <Link external href={getScanLinkSolana(txHash, "transaction", SOLANA_NETWORK)}>
          {t("View on scan")}: {truncateHash(txHash, 8, 0)}
        </Link>
      )}
    </>
  );
};

export default SolanaToastDescriptionWithTx;
