import React from "react";

import { useForm, useMatchBreakpoints } from "hooks";
import { useValidationSchema, useHandleData } from "./hooks";

import { StyledContentWrapper, FlexGap } from "components";
import { WidgetPreview, EscrowHeader, EscrowPaymentsInfo, CreateEscrowStepWrapper } from "./components";

import { useAppSelector } from "store/store";

import { CreateEscrowSteps, Roles } from "./types";

import { ROUTES } from "navigation/routes";
import { TABS } from "configs";

const CreateEscrowPage: React.FC = () => {
  const coversEscryptoFeeTab = useAppSelector(state => state.createEscrow.coversEscryptoFeeTab);
  const coversBrokersCommissionTab = useAppSelector(state => state.createEscrow.coversBrokersCommissionTab);

  const { isDesktop } = useMatchBreakpoints();

  const { validationSchema, initialValues } = useValidationSchema();
  const { errors, touched, values, fieldProps, setFieldValue, setFieldTouched, isValid, handleSubmit, resetForm } =
    useForm({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      onSubmit(values) {
        submitHandler(values);
      },
    });

  const {
    currentStepNumber,
    currentStep,
    isWidgetShowed,
    onBackBtnClick,
    onContinueBtnClick,
    isCurrentStepValid,
    submitHandler,
  } = useHandleData({ isValid, errors, values, initialValues, handleSubmit, resetForm });

  return (
    <FlexGap gap="24px" id={ROUTES.createEscrow} flexDirection="column" flexGrow="1">
      <StyledContentWrapper minHeight="176px" height="unset">
        <EscrowHeader currentStepNumber={currentStepNumber} />
      </StyledContentWrapper>

      <FlexGap gap="24px" flexGrow="1" flexDirection={{ _: "column", laptop: "row" }}>
        <StyledContentWrapper
          width={{
            _: "100%",
            laptop:
              isWidgetShowed || (currentStepNumber === 4 && currentStep === CreateEscrowSteps.preview)
                ? //   WidgetPreview or EscrowPaymentsInfo minWidth + gap (400+24)
                  "calc(100% - 424px)"
                : "100%",
          }}
          minWidth={{ _: "unset", laptop: "456px" }}
          minHeight="unset"
        >
          <CreateEscrowStepWrapper
            currentStepNumber={currentStepNumber}
            values={values}
            currentStep={currentStep}
            errors={errors}
            touched={touched}
            isWidgetShowed={isWidgetShowed}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            fieldProps={fieldProps}
            onBackBtnClick={onBackBtnClick}
            isCurrentStepValid={isCurrentStepValid}
            onContinueBtnClick={onContinueBtnClick}
          />
        </StyledContentWrapper>

        {isWidgetShowed && isDesktop && (
          <StyledContentWrapper minWidth="400px" maxWidth="456px" width="100%" minHeight="unset">
            <WidgetPreview />
          </StyledContentWrapper>
        )}

        {currentStepNumber === 4 && currentStep === CreateEscrowSteps.preview && (
          <StyledContentWrapper
            minWidth={{ _: "unset", laptop: "400px" }}
            maxWidth={{ _: "100%", laptop: "456px" }}
            width="100%"
            minHeight="unset"
          >
            <EscrowPaymentsInfo
              currency={values.currency.currency}
              network={values.currency.network}
              amount={values.currency.amount}
              escryptoFee={values.currency.escryptoFee}
              showBrokerFields={values.role.role === Roles.broker}
              brokersCommission={values.role.role === Roles.broker ? values.currency.brokersCommission : "0"}
              coversEscryptoFee={TABS.createEscrowCommissionPayer[coversEscryptoFeeTab].title}
              coversBrokersCommission={TABS.createEscrowCommissionPayer[coversBrokersCommissionTab].title}
              isShipped={values.escrowInfo.isShipped}
            />
          </StyledContentWrapper>
        )}
      </FlexGap>
    </FlexGap>
  );
};

export default CreateEscrowPage;
