import React from "react";

import { StyledBurgerMenu, StyledBurgerMenuSpan } from "./styled";

import { HeaderDesktopProps } from "components/header/type";

const BurgerMenu: React.FC<HeaderDesktopProps> = ({ isMenuOpen, setIsMenuOpen }) => {
  const handlerMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <StyledBurgerMenu onClick={handlerMenuOpen} isMenuOpen={isMenuOpen}>
      <StyledBurgerMenuSpan width={!isMenuOpen ? "12px" : "0px"} $backgroundColor="neutral600" top="5px" />
      <StyledBurgerMenuSpan width={!isMenuOpen ? "8px" : "0px"} $backgroundColor="neutral600" top="15px" />
    </StyledBurgerMenu>
  );
};

export default BurgerMenu;
