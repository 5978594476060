import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  return (
    <Svg viewBox="0 0 72 84" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_2476_51953)">
        <rect x="18" y="49" width="36" height="12" rx="4" fill="#50CF7C" />
      </g>
      <circle cx="36" cy="36" r="28" fill="#ECF8EF" />
      <circle cx="36" cy="36" r="27.5" stroke="#50CF7C" strokeOpacity="0.5" />
      <path d="M36 46.5L36 25.5" stroke="#50CF7C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M43.5015 39L36 46.5015L28.4985 39"
        stroke="#50CF7C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke="#50CF7C" strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_2476_51953"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_2476_51953" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
