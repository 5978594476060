import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ ...props }) => {
  return (
    <Svg viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        d="M14.5 16V19.124C14.5 19.698 14.109 20.198 13.553 20.337L11.053 20.962C10.264 21.159 9.5 20.562 9.5 19.749V13.5L4.293 8.293C4.105 8.106 4 7.851 4 7.586V5C4 4.448 4.448 4 5 4H19C19.552 4 20 4.448 20 5V6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14 10L16 12L19 9" stroke="#50CF7C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
};

export default Icon;
