import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { BLOCK_EXPLORER_URLS, BLOCK_EXPLORER_URLS_SOLANA } from "configs/web3";

export const getScanLink = (
  data: string | number,
  type: "transaction" | "token" | "address" | "block" | "countdown",
  chainId: number,
) => {
  switch (type) {
    case "transaction": {
      return `${BLOCK_EXPLORER_URLS[chainId]}/tx/${data}`;
    }
    case "token": {
      return `${BLOCK_EXPLORER_URLS[chainId]}/token/${data}`;
    }
    case "block": {
      return `${BLOCK_EXPLORER_URLS[chainId]}/block/${data}`;
    }
    case "countdown": {
      return `${BLOCK_EXPLORER_URLS[chainId]}/block/countdown/${data}`;
    }
    default: {
      return `${BLOCK_EXPLORER_URLS[chainId]}/address/${data}`;
    }
  }
};

export const getScanLinkSolana = (
  data: string | number,
  type: "transaction" | "token" | "account" | "block",
  network: WalletAdapterNetwork,
) => {
  const cluster = network === WalletAdapterNetwork.Devnet ? `?cluster=${WalletAdapterNetwork.Devnet}` : "";

  switch (type) {
    case "transaction": {
      return `${BLOCK_EXPLORER_URLS_SOLANA}/tx/${data}${cluster}`;
    }
    case "token": {
      return `${BLOCK_EXPLORER_URLS_SOLANA}/token/${data}${cluster}`;
    }
    case "account": {
      return `${BLOCK_EXPLORER_URLS_SOLANA}/account/${data}${cluster}`;
    }
    case "block": {
      return `${BLOCK_EXPLORER_URLS_SOLANA}/block/${data}${cluster}`;
    }
  }
};
