import { ROUTES, ROUTE_PARAMS } from "navigation/routes";

export const MATCH_ROUTES = [
  `/${ROUTES.wallet}`,
  `/${ROUTES.wallet}/:${ROUTE_PARAMS.network}`,
  `/${ROUTES.wallet}/:${ROUTE_PARAMS.network}/:${ROUTE_PARAMS.token}`,
  `/${ROUTES.wallet}/:${ROUTE_PARAMS.network}/:${ROUTE_PARAMS.token}/${ROUTES.deposit}`,
  `/${ROUTES.wallet}/:${ROUTE_PARAMS.network}/:${ROUTE_PARAMS.token}/${ROUTES.withdraw}`,
  `/${ROUTES.wallet}/:${ROUTE_PARAMS.network}/:${ROUTE_PARAMS.token}/${ROUTES.whitelist}`,
  `/${ROUTES.wallet}/:${ROUTE_PARAMS.network}/:${ROUTE_PARAMS.token}/${ROUTES.history}`,
];
