import { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "store/store";
import { setWhitelistModalPayload } from "store/wallets/whitelist";
import { getWhitelistModal } from "store/wallets/whitelist/actions";
import { selectCombinedData } from "./selectors";

export const useHandlePayload = () => {
  const { pending, data, count, page, size, search, chainId } = useAppSelector(selectCombinedData);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getWhitelistModal({
        page,
        size,
        search: search ? search : undefined,
        chainId: chainId ? chainId : undefined,
      }),
    );
  }, [page, size, search, chainId]);

  const handlePageClick = (selectedItem: { selected: number }) => {
    const currentPage = selectedItem.selected + 1;
    dispatch(setWhitelistModalPayload({ page: currentPage }));
  };

  return { data, count, pending, page, size, handlePageClick };
};
