import styled from "styled-components";
import { Colors } from "theme/types";
import { Flex } from "components";

export const StyledWrapper = styled(Flex)<{ bgColor?: keyof Colors }>`
  background: ${({ theme, bgColor }) => (bgColor ? theme.colors[bgColor] + "33" : null)};
`;

export const StyledList = styled.ul`
  list-style-type: none;
`;

export const StyledNote = styled.li<{ noteColor?: keyof Colors }>`
  margin-bottom: 8px;

  ::before {
    content: "";
    display: inline-block;
    height: 4px;
    width: 4px;
    vertical-align: middle;
    border-radius: 50%;
    background-color: ${({ theme, noteColor }) => (noteColor ? theme.colors[noteColor] : theme.colors.neutral600)};
    margin-inline-end: 4px;
    ${({ theme }) => theme.mediaQueries.tablet} {
      margin-inline-end: 12px;
    }
  }
`;
