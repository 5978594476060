import { Area } from "react-easy-crop";

const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", error => reject(error));
    image.src = url;
  });

export default async function getCroppedImg(imageSrc: string, pixelCrop: Area) {
  const image = (await createImage(imageSrc)) as HTMLImageElement;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  // Set canvas size to match the bounding box
  canvas.width = image.width;
  canvas.height = image.height;

  // Draw image
  ctx.drawImage(image, 0, 0);

  // CroppedAreaPixels values are bounding box relative
  // Extract the cropped image using these values
  const data = ctx.getImageData(
    (image.width * pixelCrop.x) / 100,
    (image.height * pixelCrop.y) / 100,
    (image.width * pixelCrop.width) / 100,
    (image.height * pixelCrop.height) / 100,
  );

  // Set canvas width to final desired crop size - this will clear existing context
  canvas.width = (image.width * pixelCrop.width) / 100;
  canvas.height = (image.height * pixelCrop.height) / 100;

  // Paste generated image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  return canvas.toDataURL("image/jpeg");
}
