import { Zero } from "@ethersproject/constants";
import { useWeb3React } from "@web3-react/core";
import { Contract } from "@ethersproject/contracts";
import { formatUnits } from "@ethersproject/units";
import { TransactionRequest } from "@ethersproject/abstract-provider";
import useSWR from "swr";
import BigNumber from "bignumber.js";

import { nativeOnChain, useGasPrice } from "configs/web3";

export const useEstimateTransferFee = <T extends Contract = Contract>(
  contract: T,
  isNative: boolean,
  to: TransactionRequest["to"],
  value: TransactionRequest["value"],
) => {
  const { chainId, provider } = useWeb3React();
  const { gasPrice } = useGasPrice();
  const nativeCurrency = nativeOnChain(chainId);

  const getEstimateTxFeeNative = async () => {
    return await provider!.estimateGas({ to, value });
  };

  const getEstimateTxFee = async () => {
    return await contract.estimateGas.transfer(to, value);
  };

  const {
    data = Zero,
    isValidating,
    error,
  } = useSWR(
    chainId && to && value && provider && gasPrice
      ? `${chainId}/${gasPrice.toString()}/estimateTransferFee/${to}/${value}/${isNative}/${contract?.address}`
      : null,
    async () => {
      let gasEstimate = Zero;
      if (isNative) {
        gasEstimate = await getEstimateTxFeeNative();
      } else {
        gasEstimate = await getEstimateTxFee();
      }

      return gasPrice.mul(gasEstimate);
    },
  );

  return { gasEstimation: BigNumber(formatUnits(data, nativeCurrency?.decimals)), isValidating, error };
};
