import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import useSWR from "swr";
import { formatUnits, parseUnits } from "@ethersproject/units";
import BigNumber from "bignumber.js";
import { BigNumber as BigNumberETH } from "@ethersproject/bignumber";
import { PublicKey, Transaction } from "@solana/web3.js";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { TOKEN_PROGRAM_ID, createTransferInstruction, getAccount, getAssociatedTokenAddress } from "@solana/spl-token";

import { Currency, logError } from "configs/web3";
import { SOLANA } from "../chains";
import { useTranslation } from "context";

export const useEstimateTxFeeSolana = (
  currency: Currency | null,
  value: string,
  createTxToSend: (value: BigNumberETH, toPubkey: PublicKey) => Promise<Transaction | undefined>,
  toPubkey?: PublicKey,
) => {
  const { publicKey } = useWallet();
  const { connection } = useConnection();
  const { t } = useTranslation();

  //We have similar function in use-send-token-solana hook, but here we take an associated token address for create transaction and get solana network fee from this transaction 
  const estimateFeeTransferForNonNativeTokenTx = async (value: BigNumberETH, toPublicKey: PublicKey | undefined) => {
    try {
      if (!publicKey) {
        throw new WalletNotConnectedError(t("Solana wallet is not connected"));
      }
      if (!currency?.address) {
        throw new Error(t("There's no address for selected token"));
      }

      if (!toPublicKey) {
        throw new Error(t("There's no deposit address"));
      }

      const transaction = new Transaction();
      const tokenPublicKey = new PublicKey(currency.address);

      const associatedTokenFrom = await getAssociatedTokenAddress(tokenPublicKey, publicKey);
      const fromTokenAccount = await getAccount(connection, associatedTokenFrom);

      const associatedTokenTo = await getAssociatedTokenAddress(tokenPublicKey, toPublicKey);
      const toTokenAccount = await getAccount(connection, associatedTokenTo);

      if (fromTokenAccount && toTokenAccount) {
        const instruction = createTransferInstruction(
          fromTokenAccount.address,
          toTokenAccount.address,
          publicKey,
          +value.toString(),
          [],
          TOKEN_PROGRAM_ID,
        );

        transaction.add(instruction);

        const { blockhash } = await connection.getLatestBlockhash();
        transaction.feePayer = publicKey;
        transaction.recentBlockhash = blockhash;

        return transaction;
      }
    } catch (error) {
      logError(
        error,
        `createCustomTransferTx in useSendTokenSolana - ${currency?.address} ${toPublicKey?.toBase58()} ${value.toString()} -`,
      );
    }
  };

  const {
    data = 0,
    isValidating,
    error,
  } = useSWR(
    publicKey && currency && toPubkey && value
      ? `${publicKey.toBase58()}/txEstimation/${currency}/${value}/${toPubkey.toBase58()}`
      : null,
    async () => {
      const valueBN = parseUnits(value || "0", currency?.decimals);
      let tx: Transaction | undefined;

      const isNative = currency?.isNative;

      if (isNative) {
        tx = await createTxToSend(valueBN, toPubkey!);
      } else {
        tx = await estimateFeeTransferForNonNativeTokenTx(valueBN, toPubkey!);
      }

      if (tx) {
        return (await tx.getEstimatedFee(connection)) || 0;
      }
    },
  );

  return { estimateTxSolana: BigNumber(formatUnits(data, SOLANA.decimals)), isValidating, error };
};
