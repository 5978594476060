import React, { useRef, useState } from "react";
// Components
import { Box, Button, Flex, Image as PreviewImage } from "components";
import { DeleteIcon } from "components/svg";
import { StyledImageCropperInput } from "components/image-cropper/styled";
// Context
import { useTranslation } from "context";
// Types
import { ImagePreviewerProps } from "./types";

export const ImagePreviewer: React.FC<ImagePreviewerProps> = ({
  accept,
  image,
  resetImage,
  handleUploadImage,
  isLoading = false,
}) => {
  const { t } = useTranslation();

  const [aspectRatio, setAspectRatio] = useState(1);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const initClickInput = () => {
    fileInputRef?.current?.click();
  };

  const getDynamicAspectRatio = () => {
    const img = new Image();

    img.src = image.src;

    img.onload = () => {
      const w = img.width;
      const h = img.height;

      setAspectRatio(w / h);
    };
  };

  getDynamicAspectRatio();

  return (
    <>
      {image.src ? (
        <Flex flexDirection="column" maxWidth="135px">
          <Box position="relative" top="15px" left="120px" zIndex="1">
            <DeleteIcon width="24px" height="24px" cursor="pointer" onClick={resetImage} />
          </Box>
          <PreviewImage src={image.src} width="135px" aspectRatio={aspectRatio} />
        </Flex>
      ) : (
        <>
          <Button width="170px" height="44px" variant="outline" onClick={initClickInput} isLoading={isLoading}>
            {t("Upload")}
          </Button>
          <StyledImageCropperInput ref={fileInputRef} accept={accept} type="file" onChange={handleUploadImage} />
        </>
      )}
    </>
  );
};

export default ImagePreviewer;
