import { useAppSelector } from "store/store";

import { ChainId, Currency } from "configs/web3";

export const useCurrency = (chainId: ChainId, code: string) => {
  const tokens = useCurrencies(chainId);

  const token = tokens && tokens[code];

  return token;
};

export const useCurrencies = (chainId: ChainId) => {
  const allTokensList = useAppSelector(state => state.tokensList.allTokensList);

  const tokens: { [x: string]: Currency } | null = chainId && allTokensList ? allTokensList[chainId] : null;
  return tokens;
};
