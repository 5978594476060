export enum SecurityTogglesSettingsTypeEnum {
  faceBiometric = "faceBiometric",
  voiceBiometric = "voiceBiometric",
  yubiKeyLogin = "yubiKeyLogin",
  yubiKeyNewWithdrawAddress = "yubiKeyNewWithdrawAddress",
  yubiKeyWithdrawFromColdStorage = "yubiKeyWithdrawFromColdStorage",
  twoFaLogin = "twoFaLogin",
  twoFaNewWithdrawAddress = "twoFaNewWithdrawAddress",
  twoFaWithdrawFromColdStorage = "twoFaWithdrawFromColdStorage",
}

export type SecuritySettingsPayload = {
  login?: CommonAuthMethodEnum;
  addWithdrawalAddress?: CommonAuthMethodEnum;
  coldWalletWithdrawal?: ColdWalletWithdrawalAuthMethodEnum[];
};

export type SystemSettingsPayload = {
  noticeSettings?: ProfileSettingsPayload;
  fiatCurrencyCode?: FiatCurrencyCodeEnum;
};

export type ProfileSettingsPayload = {
  escrowCreatedNotice?: boolean;
  escrowUpdatedNotice?: boolean;
  changeSettingsNotice?: boolean;
  chatMessageNotice?: boolean;
};

export enum FiatCurrencyCodeEnum {
  USD = "usd",
  AED = "aed",
  ARS = "ars",
  AUD = "aud",
  BDT = "bdt",
  BHD = "bhd",
  BMD = "bmd",
  BRL = "brl",
  CAD = "cad",
  CHF = "chf",
  CLP = "clp",
  CNY = "cny",
  CZK = "czk",
  DKK = "dkk",
  EUR = "eur",
  GBP = "gbp",
  HKD = "hkd",
  HUF = "huf",
  IDR = "idr",
  ILS = "ils",
  INR = "inr",
  JPY = "jpy",
  KRW = "krw",
  KWD = "kwd",
  LKR = "lkr",
  MMK = "mmk",
  MXN = "mxn",
  MYR = "myr",
  NGN = "ngn",
  NOK = "nok",
  NZD = "nzd",
  PHP = "php",
  PKR = "pkr",
  PLN = "pln",
  RUB = "rub",
  SAR = "sar",
  SEK = "sek",
  SGD = "sgd",
  THB = "thb",
  TRY = "try",
  UAH = "uah",
  VEF = "vef",
  VND = "vnd",
  ZAR = "zar",
}

export enum CommonAuthMethodEnum {
  YUBIKEY = "yubikey",
  TWO_FA = "twofa",
}

export enum ColdWalletWithdrawalAuthMethodEnum {
  YUBIKEY = "yubikey",
  TWO_FA = "twofa",
  MITEK_VOICE = "mitek_voice",
  MITEK_SELFIE = "mitek_selfie",
}
