import { useLayoutEffect, useRef, useState } from "react";

import { useThemeContext, useTranslation } from "context";

import { useEventSubscribers } from "./use-events-subscriber";
import { DraggableData, DraggableEvent } from "react-draggable";
import { ELEMENTS_ID } from "configs";

export type SliderStates = {
  isDragging: boolean;
  sliderWidth: number;
};

const initialPosition = { x: 0, y: 0 };

const initialState = {
  isDragging: false,
  sliderWidth: 0,
};

export const useControls = (onConfirmCallback: (arg: boolean) => void) => {
  const [position, setPosition] = useState(initialPosition);
  const [sliderStates, setSliderStates] = useState(initialState);

  const { theme } = useThemeContext();
  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const sliderBackgroundRef = useRef<HTMLDivElement | null>(null);
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const sliderTextEl = document.getElementById(ELEMENTS_ID.sliderText);

  // width 46px + 6px paddingX
  const thumbSpacing = 52;

  useEventSubscribers(wrapperRef, setSliderStates);

  useLayoutEffect(() => {
    if (sliderTextEl) {
      sliderTextEl.style.setProperty("--content", `'${getText(0)}'`);
    }
  }, [sliderTextEl]);

  const slideToMaxPosition = () => {
    if (sliderBackgroundRef.current && sliderTextEl) {
      sliderTextEl.style.setProperty("--content", `'${getText(sliderStates.sliderWidth)}'`);
      sliderBackgroundRef.current.style.width = `${sliderStates.sliderWidth}px`;
      changeTextColor(sliderStates.sliderWidth);

      setPosition(prev => ({ ...prev, x: sliderStates.sliderWidth - thumbSpacing }));
    }
  };

  const slideToInitialPosition = () => {
    if (sliderBackgroundRef.current && sliderTextEl) {
      sliderTextEl.style.setProperty("--content", `'${getText(0)}'`);
      sliderBackgroundRef.current.style.width = `${0}px`;
      setPosition(prev => ({ ...prev, x: 0 }));
      changeTextColor(0);
    }
  };

  const slideToPosition = (currentX: number) => {
    if (sliderBackgroundRef.current && sliderTextEl) {
      sliderTextEl.style.setProperty("--content", `'${getText(currentX)}'`);
      sliderBackgroundRef.current.style.width = `${currentX + thumbSpacing}px`;
      changeTextColor(currentX);

      setPosition(prev => ({ ...prev, x: currentX }));
    }
  };

  const handleStart = () => {
    setSliderStates(prev => ({ ...prev, isDragging: true }));
  };

  const handleDrag = (_: DraggableEvent, data: DraggableData) => {
    slideToPosition(data.x);
  };

  const handleStop = (e: DraggableEvent, data: DraggableData) => {
    setSliderStates(prev => ({ ...prev, isDragging: false }));

    if (data.x >= sliderStates.sliderWidth * 0.75) {
      onConfirm();
    } else {
      slideToInitialPosition();
    }
  };

  const onConfirm = () => {
    slideToMaxPosition();
    onConfirmCallback(true);
  };

  const resetState = () => {
    setPosition(initialPosition);
    setSliderStates(prev => ({ ...prev, isDragging: false }));
  };

  const getText = (currentX: number) => {
    return currentX <= 0
      ? t("Slide to confirm")
      : currentX >= sliderStates.sliderWidth
        ? t("Confirmed!")
        : t("Confirming...");
  };

  const changeTextColor = (currentX: number) => {
    if (sliderTextEl) {
      if (sliderStates.sliderWidth * 0.5 > currentX) {
        sliderTextEl.style.color = theme.colors.neutral900;
      } else {
        sliderTextEl.style.color = theme.colors.neutral0;
      }
    }
  };

  return {
    wrapperRef,
    sliderBackgroundRef,
    nodeRef,
    sliderStates,
    position,
    thumbSpacing,
    resetState,
    slideToPosition,
    slideToInitialPosition,
    handleStart,
    handleDrag,
    handleStop,
  };
};
