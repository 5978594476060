import { createAsyncThunk } from "@reduxjs/toolkit";

import { isErrorResult, makeApiRequest } from "services";
import { ENDPOINTS_STATEMENT } from "services";

import { ErrorResult } from "services/types";
import { StatementInfoPayload, StatementTransactionPayload } from "./types";
import { StatementSingleItem, TransactionListResponse } from "../types";

export const getStatementItem = createAsyncThunk<
  StatementSingleItem,
  StatementInfoPayload,
  { rejectValue: ErrorResult }
>("statement/get-statement-info", async ({ id }, { rejectWithValue }) => {
  const result = await makeApiRequest<StatementSingleItem>({
    method: "GET",
    url: `${ENDPOINTS_STATEMENT.statements}${id}/`,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const getStatementTransactions = createAsyncThunk<
  TransactionListResponse,
  StatementTransactionPayload,
  { rejectValue: ErrorResult }
>("statement/get-statement-transaction", async ({ params, statementId }, { rejectWithValue }) => {
  const result = await makeApiRequest<TransactionListResponse>({
    method: "GET",
    url: `${ENDPOINTS_STATEMENT.statements}${statementId}${ENDPOINTS_STATEMENT.transactions}`,
    params,
    isShowError: false,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});
