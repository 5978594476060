import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral500", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent" stroke={theme.colors[color]}>
      <path
        d="M22.3944 18.0019V15.0006C22.3944 14.171 20.7137 13.5 18.6428 13.5C16.5719 13.5 14.8923 14.1721 14.8912 15.0006V21.0031C14.8923 21.8317 16.5709 22.5037 18.6428 22.5037C20.7147 22.5037 22.3933 21.8317 22.3944 21.0031V15.0006"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.62817 15.8672L12.2857 13.6373V8.98438"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7725 10.9989C19.7241 6.40644 15.3123 3.39904 10.6544 4.10169C5.99653 4.80433 2.66816 8.97936 3.02113 13.6767C3.3741 18.374 7.289 22.0048 11.9996 22.0034"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8923 15C14.8923 15.8286 16.572 16.5006 18.6439 16.5006C20.7158 16.5006 22.3944 15.8286 22.3944 15"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8923 18.0039C14.8923 18.8325 16.572 19.5045 18.6439 19.5045C20.7158 19.5045 22.3955 18.8325 22.3955 18.0039"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
