import React from "react";

import { useThemeContext } from "context";

import { Svg } from "components";

import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 20 20" {...props} color={color}>
      <circle
        cx="9.99988"
        cy="10.0001"
        r="6.75281"
        stroke={theme.colors.neutral500}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.25 12.6261H10.9827" stroke={theme.colors.neutral500} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M10.1187 12.6261V9.43726H9.25586"
        stroke={theme.colors.neutral500}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0745 7.18424C10.0745 7.28783 9.99047 7.37182 9.88687 7.37182C9.78328 7.37182 9.6993 7.28783 9.6993 7.18424C9.6993 7.08064 9.78328 6.99666 9.88687 6.99666"
        stroke={theme.colors.neutral500}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.88684 6.9967C9.99043 6.9967 10.0744 7.08068 10.0744 7.18428"
        stroke={theme.colors.neutral500}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
