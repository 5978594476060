import React from "react";

import { useThemeContext } from "context";

import { Svg } from "components";

import { SvgProps } from "components/svg/types";

const Icon: React.FC<SvgProps> = ({ color = "blue", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" color="transparent" {...props}>
      <path
        d="M20 10.5V5C20 3.895 19.105 3 18 3H5C3.895 3 3 3.895 3 5V15"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 18H3C2.448 18 2 17.552 2 17V16C2 15.448 2.448 15 3 15H15"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 21H16C15.448 21 15 20.552 15 20V11.5C15 10.948 15.448 10.5 16 10.5H21C21.552 10.5 22 10.948 22 11.5V20C22 20.552 21.552 21 21 21Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15 18H22" stroke={theme.colors[color]} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
};

export default Icon;
