import { useNavigate } from "react-router-dom";

import { setIsLoggedIn } from "store/auth";
import { useAppDispatch } from "store/store";

import { PingOneRisk, UserTwoFaStatusEnum } from "store/profile/types";
import { ROUTES } from "navigation/routes";

export const useCheckRisk = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const checkRiskLevel = (riskLevel: PingOneRisk, twoFaStatus: UserTwoFaStatusEnum) => {
    if (riskLevel === PingOneRisk.high || riskLevel === PingOneRisk.medium) {
      navigate(`/${ROUTES.confirmAuth}`);
      return;
    } else if (twoFaStatus === UserTwoFaStatusEnum.ABSENT) {
      navigate(`/${ROUTES.twoFactorSetup}`);
      return;
    }
    dispatch(setIsLoggedIn());
  };

  return {
    checkRiskLevel,
  };
};
