import React from "react";

import { useTooltip } from "hooks";
import { useTranslation } from "context";

import { Text, Image, Box } from "components";
import { StyledNetworkWrapper } from "./styled";

import { useAppSelector } from "store/store";

import { NATIVE_CURRENCIES_LOGO, SHORT_CHAIN_NAMES } from "configs/web3";

const NetworkAndToken: React.FC = () => {
  const currentEscrow = useAppSelector(state => state.escrowProcess.currentEscrow);
  const allTokensList = useAppSelector(state => state.tokensList.allTokensList);

  const { t } = useTranslation();

  const { targetRef: networkRef, tooltip: networkTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const { targetRef: currencyRef, tooltip: currencyTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const currencyCode = currentEscrow ? currentEscrow.body.head.currency.publicCode : "";

  return (
    <>
      {currentEscrow && (
        <>
          <Text textScale="caption1" mb="8px" color="neutral600">
            {t("Network")}
          </Text>

          <StyledNetworkWrapper mb="24px">
            <Box minHeight="24px" minWidth="24px">
              <Image
                src={NATIVE_CURRENCIES_LOGO[currentEscrow.body.head.currency.chainId]}
                alt={SHORT_CHAIN_NAMES[currentEscrow.body.head.currency.chainId]}
                width="24px"
                aspectRatio={1}
                variant="circle"
              />
            </Box>

            <Text textScale="caption1" color="neutral800" $fontWeight="medium" ref={networkRef} ellipsis>
              {SHORT_CHAIN_NAMES[currentEscrow.body.head.currency.chainId]}
            </Text>
            {networkTooltip}
          </StyledNetworkWrapper>

          <Text textScale="caption1" mb="8px" color="neutral600">
            {t("Token")}
          </Text>

          <StyledNetworkWrapper>
            <Box minHeight="24px" minWidth="24px">
              <Image
                src={
                  allTokensList
                    ? allTokensList[currentEscrow.body.head.currency.chainId][
                        currentEscrow.body.head.currency.publicCode
                      ].logoURI
                    : ""
                }
                alt={currencyCode}
                width="24px"
                aspectRatio={1}
                variant="circle"
              />
            </Box>

            <Text textScale="caption1" color="neutral800" $fontWeight="medium" ref={currencyRef} ellipsis>
              {currencyCode}
            </Text>
            {currencyTooltip}
          </StyledNetworkWrapper>
        </>
      )}
    </>
  );
};

export default NetworkAndToken;
