import React, { useEffect } from "react";
import Draggable from "react-draggable";

import { SlideIcon, SlideWrapper, StyledBg, StyledText } from "./styled";
import { ArrowRightIcon, CheckIcon, SpinnerIcon, CloseIcon } from "components/svg";
// Hooks
import { useControls } from "./hooks";
// Types
import { SliderProps } from "./types";

const SlideToConfirm: React.FC<SliderProps> = ({ disabled, isConfirmed, isLoading, onConfirmCallback }) => {
  const isDisabled = isLoading || disabled;

  const {
    wrapperRef,
    sliderBackgroundRef,
    nodeRef,
    sliderStates,
    position,
    thumbSpacing,
    resetState,
    slideToInitialPosition,
    handleStart,
    handleDrag,
    handleStop,
  } = useControls(onConfirmCallback);

  useEffect(() => {
    if (!isConfirmed) {
      resetState();
      slideToInitialPosition();
    }
  }, [isConfirmed]);

  return (
    <SlideWrapper ref={wrapperRef}>
      <StyledBg ref={sliderBackgroundRef}>
        <StyledText id="slider-text" width={sliderStates.sliderWidth} disabled={isDisabled} />
        <Draggable
          axis="x"
          handle=".handle"
          nodeRef={nodeRef}
          position={position}
          onStart={handleStart}
          onDrag={handleDrag}
          onStop={handleStop}
          disabled={disabled || isConfirmed}
          bounds={{ left: 0, right: sliderStates.sliderWidth - thumbSpacing }}
        >
          <SlideIcon
            className="handle"
            disabled={isDisabled}
            isDragging={sliderStates.isDragging}
            isConfirmed={isConfirmed}
            ref={nodeRef}
          >
            {isLoading ? (
              <SpinnerIcon width="24px" color="neutral0" spin />
            ) : disabled ? (
              <CloseIcon width="16px" color="neutral0" />
            ) : isConfirmed ? (
              <CheckIcon width="24px" />
            ) : (
              <ArrowRightIcon width="24px" color={sliderStates.isDragging ? "neutral1000" : "neutral0"} />
            )}
          </SlideIcon>
        </Draggable>
      </StyledBg>
    </SlideWrapper>
  );
};

export default SlideToConfirm;
