import React from "react";

import { useTranslation } from "context";

import { Text, Image, Box } from "components";
import { StyledModalWrapper } from "../styled";
import CloseModalWrapper from "../close-modal-wrapper";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";
import { setWhitelistModalPayload } from "store/wallets/whitelist";

import { SUPPORTED_CHAINS, NATIVE_CURRENCIES_LOGO, SHORT_CHAIN_NAMES } from "configs/web3";

import { DepositChooseNetworkModalProps } from "./types";
import { StyledCard } from "../deposit-choose-wallet-modal/styled";

const DepositChooseNetworkModal: React.FC = () => {
  const onClick = useAppSelector(state => (state.modal.props as unknown as DepositChooseNetworkModalProps).onClick);
  const network = useAppSelector(state => (state.modal.props as unknown as DepositChooseNetworkModalProps).network);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const onCardClick: DepositChooseNetworkModalProps["onClick"] = chainId => {
    dispatch(setWhitelistModalPayload({ chainId: chainId.toString() }));
    onClick(chainId);
    closeModalHandler();
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} pb="24px">
        <Text textAlign="start" color="neutral600" textScale="body2" $fontWeight="bold" wordBreak="break-all">
          {t("Select Network")}
        </Text>
      </CloseModalWrapper>
      {SUPPORTED_CHAINS.map(chainId => {
        return (
          <StyledCard key={chainId} onClick={() => onCardClick(chainId)} isSelected={network === chainId}>
            <Box minHeight="40px" minWidth="40px">
              <Image
                src={NATIVE_CURRENCIES_LOGO[chainId]}
                alt={SHORT_CHAIN_NAMES[chainId]}
                variant="circle"
                width="40px"
                aspectRatio={1}
              />
            </Box>

            <Box ellipsis>
              <Text color="neutral600" textScale="body3" $fontWeight="bold" ellipsis>
                {SHORT_CHAIN_NAMES[chainId]}
              </Text>
            </Box>
          </StyledCard>
        );
      })}
    </StyledModalWrapper>
  );
};

export default DepositChooseNetworkModal;
