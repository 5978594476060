import React from "react";

import { InputGroup, Input, Box, StyledLink, Button } from "components";
import { EyeClosedIcon, EyeIcon, RotateIcon } from "components/svg";

import { AuthFormProps } from "./types";
import { useTranslation } from "context";

import { useAppDispatch } from "store/store";
import { showModal } from "store/modal";

const AuthForm: React.FC<AuthFormProps> = ({
  errors,
  touched,
  isValid,
  isPassword,
  pending,
  togglePasswordVisibleHandler,
  fieldProps,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const showForgotPasswordModal = () => {
    dispatch(showModal({ modalName: "forgotPassword", rootId: "login" }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputGroup label={t("Email")} error={errors.email} isTouched={touched.email}>
        <Input {...fieldProps("email")} placeholder={t("Email")} autoComplete="email" />
      </InputGroup>

      <Box position="relative">
        <InputGroup
          label={t("Password")}
          error={errors.password}
          isTouched={touched.password}
          endIcon={
            <RotateIcon
              variant="rotateY"
              isToggled={isPassword}
              onClick={togglePasswordVisibleHandler}
              firstIcon={<EyeIcon width="24px" />}
              secondIcon={<EyeClosedIcon width="24px" />}
            />
          }
        >
          <Input
            {...fieldProps("password")}
            type={isPassword ? "password" : "text"}
            autoComplete="current-password"
            placeholder={t("Password")}
          />
        </InputGroup>

        <Box position="absolute" top="0" right="0" onClick={showForgotPasswordModal}>
          <StyledLink color="blue" textScale="caption1">
            {t("Forgot your password?")}
          </StyledLink>
        </Box>
      </Box>

      <Button disabled={!isValid} type="submit" isLoading={pending} color="primary" width="100%">
        {t("Log In")}
      </Button>
    </form>
  );
};

export default AuthForm;
