import React from "react";

import { useThemeContext } from "context";

import { Svg } from "components";

import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color={color}>
      <path
        d="M9.09435 21.007C8.46557 20.9728 7.87389 20.6986 7.44135 20.241C6.92395 19.7432 6.46223 19.1906 6.06435 18.593C5.44284 17.703 4.95155 16.7288 4.60535 15.7C4.21321 14.5932 4.00676 13.4292 3.99435 12.255C3.96421 11.1029 4.24986 9.96443 4.82035 8.963C5.23792 8.2411 5.83353 7.63825 6.55035 7.212C7.25923 6.78756 8.06816 6.5591 8.89435 6.55C9.51717 6.58685 10.1292 6.7292 10.7043 6.971C11.1629 7.17276 11.6455 7.31459 12.1403 7.393C12.6864 7.27861 13.2199 7.11115 13.7333 6.893C14.341 6.65571 14.9844 6.52284 15.6363 6.5C15.7233 6.5 15.8093 6.5 15.8923 6.51C17.3219 6.55146 18.6515 7.25318 19.4923 8.41C18.1553 9.12485 17.3344 10.5313 17.3693 12.047C17.3561 13.1966 17.8355 14.2969 18.6863 15.07C19.0693 15.4348 19.5151 15.7272 20.0023 15.933C19.9023 16.233 19.7873 16.523 19.6653 16.815C19.388 17.4603 19.0459 18.0758 18.6443 18.652C18.2652 19.2292 17.8239 19.763 17.3283 20.244C16.876 20.6929 16.273 20.9577 15.6363 20.987C15.0967 20.9631 14.5666 20.8365 14.0743 20.614C13.5436 20.3827 12.9731 20.2561 12.3943 20.241C11.7992 20.2527 11.2119 20.3786 10.6643 20.612C10.1914 20.8254 9.6861 20.9582 9.16935 21.005L9.09435 21.007ZM12.2443 6.5C12.1693 6.5 12.0943 6.5 12.0193 6.491C12.0036 6.37197 11.9952 6.25207 11.9943 6.132C12.0271 5.13313 12.4174 4.17923 13.0943 3.444C13.4727 3.02158 13.9312 2.6786 14.4433 2.435C14.9212 2.189 15.4435 2.0411 15.9793 2C15.9943 2.131 15.9943 2.259 15.9943 2.381C15.9802 3.36415 15.6108 4.30898 14.9543 5.041C14.3109 5.90378 13.3189 6.43787 12.2443 6.5Z"
        fill={theme.colors.neutral800}
      />
    </Svg>
  );
};

export default Icon;
