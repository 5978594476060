import { TABS } from "configs";
import { useAppDispatch, useAppSelector } from "store/store";
import { setEscrowsTableTab } from "store/escrow/escrows-table";

export const useHandleTabAndFilter = () => {
  const activeTab = useAppSelector(state => state.escrowsTable.activeTab);

  const dispatch = useAppDispatch();

  const onTabClick = (index: number) => {
    dispatch(setEscrowsTableTab(index));
  };

  return {
    tabs: TABS.escrows,
    activeTab,
    pending: false,
    options: [],
    selectedOption: null,
    isSelectShow: false,
    onTabClick,
    handleSelectChange: () => {},
  };
};
