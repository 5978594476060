import React from "react";
import { Flex } from "components";
import { MoonIcon, SunIcon, RotateIcon } from "components/svg";
import { useThemeContext } from "context";

const ThemeToggle: React.FC = () => {
  const { theme, toggleTheme } = useThemeContext();

  const handleTheme = () => {
    return toggleTheme();
  };

  return (
    <Flex onClick={handleTheme}>
      <RotateIcon
        variant="rotateY"
        isToggled={theme.isDark}
        firstIcon={<MoonIcon width="24px" height="24px" />}
        secondIcon={<SunIcon width="24px" height="24px" />}
      />
    </Flex>
  );
};

export default ThemeToggle;
