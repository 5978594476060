import { BigNumber as BigNumberETH } from "@ethersproject/bignumber";
import { formatUnits } from "@ethersproject/units";
import BigNumber from "bignumber.js";

export const bigNumberToSwapFormat = (amount: BigNumber, decimals: number) => {
  const _amount = formatValueToBNString(amount);
  const amountAsBigNumberETH = formatUnits(BigNumberETH.from(_amount), decimals);
  const amountAsString = BigNumber(amountAsBigNumberETH).toFixed(8);

  return BigNumber(amountAsString).toFixed(8);
};

export const formatValueToBNString = (val: BigNumber | string | number | null | undefined) => {
  return val ? BigNumber(BigNumber(val).toFixed(18)).toString() : "0";
};

export const formatBigNumberToFixed = (number: BigNumberETH, displayDecimals = 18, decimals = 18) => {
  const formattedString = formatUnits(number, decimals);
  return BigNumber(formattedString).toFormatExtended(displayDecimals);
};
