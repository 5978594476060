import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => state.settings.activeTab,
    (state: AppState) => state.profile.pending,
    (state: AppState) => state.profile.user,
    (state: AppState) => state.profile.user?.securitySettings.yubikey.login,
  ],
  (activeTab, pending, user, isYubikeyLogin) => ({
    activeTab,
    pending,
    user,
    isYubikeyLogin,
  }),
);
