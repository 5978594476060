import { LINKS } from "configs";

import { getZendeskToken } from "store/auth/actions";
import { useAppDispatch, useAppSelector } from "store/store";

import { isErrorResult } from "../services";

export const useSupport = () => {
  const pending = useAppSelector(state => state.auth.pending);

  const dispatch = useAppDispatch();

  const supportHandler = (isNewRequest?: boolean) => {
    dispatch(getZendeskToken()).then(response => {
      if (!isErrorResult(response.payload)) {
        const url = isNewRequest
          ? `${LINKS.zendeskLink}${response.payload!.token}&return_to=${LINKS.zendeskNewRequestLink}`
          : `${LINKS.zendeskLink}${response.payload!.token}`;

        window.open(url, "_blank");
      }
    });
  };

  return { supportHandler, pending };
};
