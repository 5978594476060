import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral600", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" width="24px" {...props} color="transparent">
      <path
        id="Path"
        d="M12.0002 6.5V17.5046"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_2"
        d="M15.0012 9.5V14.5021"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_3"
        d="M18.0026 11V13.0008"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_4"
        d="M8.99874 14.5021V9.5"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_5"
        d="M2.99658 16.0039V18.0047C2.99658 19.6623 4.34029 21.006 5.99783 21.006H7.99867"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_6"
        d="M2.99658 7.99818V5.99734C2.99658 4.3398 4.34029 2.99609 5.99783 2.99609H7.99867"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_7"
        d="M16.002 21.006H18.0028C19.6603 21.006 21.004 19.6623 21.004 18.0047V16.0039"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_8"
        d="M16.002 2.99609H18.0028C19.6603 2.99609 21.004 4.3398 21.004 5.99734V7.99818"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Path_9"
        d="M5.99777 13.0008V11"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
