import { FlexGap, Text } from "components";

import styled from "styled-components";

export const StyledYubikeysListModalInnerContainer = styled(FlexGap)`
  overflow-y: auto;
  max-height: 600px;
`;

export const StyledClicableYubikeyText = styled(Text)`
  &:hover {
    transition: all 0.3s ease;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`;
