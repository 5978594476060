import { createAsyncThunk } from "@reduxjs/toolkit";

import { ENDPOINTS_WIDGET, isErrorResult, makeApiRequest } from "services";

import { ErrorResult } from "services/types";
import { WidgetIdPayload, SuccessResponse, WidgetItem, WidgetPreviewResponse, WidgetPreviewPayload } from "./types";
import { EscrowIdResponse } from "../escrow-process/types";

export const savePreview = createAsyncThunk<SuccessResponse, WidgetIdPayload, { rejectValue: ErrorResult }>(
  "widget/save-preview",
  async (widgetId, { rejectWithValue }) => {
    const result = await makeApiRequest<SuccessResponse>({
      method: "POST",
      url: ENDPOINTS_WIDGET.widgets + widgetId + "/preview/",
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getEscrowPreview = createAsyncThunk<WidgetItem, WidgetIdPayload, { rejectValue: ErrorResult }>(
  "widget/get-widget-preview",
  async (widgetId, { rejectWithValue }) => {
    const result = await makeApiRequest<WidgetItem>({
      method: "GET",
      url: ENDPOINTS_WIDGET.widgets + widgetId + "/preview/",
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const removePreview = createAsyncThunk<SuccessResponse, WidgetIdPayload, { rejectValue: ErrorResult }>(
  "widget/remove-preview",
  async (widgetId, { rejectWithValue }) => {
    const result = await makeApiRequest<SuccessResponse>({
      method: "DELETE",
      url: ENDPOINTS_WIDGET.widgets + widgetId + "/preview/",
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const createEscrowFromWidget = createAsyncThunk<EscrowIdResponse, WidgetIdPayload, { rejectValue: ErrorResult }>(
  "widget/create-escrow-from-widget",
  async (widgetId, { rejectWithValue }) => {
    const result = await makeApiRequest<EscrowIdResponse>({
      method: "POST",
      url: ENDPOINTS_WIDGET.widgets + widgetId + "/escrows/",
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getWidgetPreviewList = createAsyncThunk<
  WidgetPreviewResponse,
  WidgetPreviewPayload,
  { rejectValue: ErrorResult }
>("widget/get-widget-preview-list", async (params, { rejectWithValue }) => {
  const result = await makeApiRequest<WidgetPreviewResponse>({
    method: "GET",
    url: ENDPOINTS_WIDGET.widgets + "previews/",
    params,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});
