import { useEffect, useState } from "react";

import { useElementDimensions } from "hooks";
import { isErrorResult } from "services";

import { getNotificationById, getNotificationsList, markAllNotificationAsRead } from "store/notifications/actions";
import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";
import { setNotificationsListPayload, setUnreadNotificationCount } from "store/notifications";

import { NotificationsListSingleItem } from "store/notifications/types";

import { ELEMENTS_ID } from "configs";
import { NOTIFICATION_ITEM_HEIGHT } from "../constants";
import { selectCombinedData } from "./selectors";

const useHandleData = () => {
  const { page, size, unreadNotificationCount, data } = useAppSelector(selectCombinedData);

  const [multiplier, setMultiplier] = useState(0);

  const dispatch = useAppDispatch();

  const { offsetHeight } = useElementDimensions(ELEMENTS_ID.notificationContainer);

  useEffect(() => {
    if (offsetHeight) {
      let _multiplier = 2;

      // i = 2 is the value of how many times more elements we should get in the first request
      // 94 is the height of one notification
      for (let i = 2; offsetHeight / NOTIFICATION_ITEM_HEIGHT > size * i; ++i) {
        _multiplier = i + 1;
      }
      setMultiplier(_multiplier);
    }

    // additional request only if, after changing the height of the notification window, the initial number of elements to display has increased
    if (multiplier && data.length < size * multiplier) {
      dispatch(getNotificationsList({ page, size: size * multiplier }));
    }
  }, [multiplier, offsetHeight]);

  useEffect(() => {
    if (page !== 1) {
      dispatch(getNotificationsList({ page, size }));
    }
  }, [page]);

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const readAllHandler = () => {
    if (unreadNotificationCount) {
      dispatch(markAllNotificationAsRead());
    }
  };

  const loadMore = () => {
    // due to the fact that the first request needs to get 20 elements and then 10 each
    dispatch(setNotificationsListPayload({ page: page === 1 ? page + multiplier : page + 1 }));
  };

  const getCurrentNotification = (item: NotificationsListSingleItem) => {
    dispatch(getNotificationById(item.id)).then(res => {
      if (!isErrorResult(res.payload) && !item.isRead) {
        const value = unreadNotificationCount - 1;

        dispatch(setUnreadNotificationCount(value));
      }
    });
  };

  return { closeModalHandler, readAllHandler, loadMore, getCurrentNotification };
};

export default useHandleData;
