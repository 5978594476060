import * as yup from "yup";
import BigNumber from "bignumber.js";

import { useGetFiatSymbolAndAbbreviation } from "hooks";
import { useTranslation } from "context";
import { formatValueToBNString } from "configs/web3";

import { useAppSelector } from "store/store";

import { ValidationArgument } from "./types";
import { Roles } from "../types";

import { REGEX } from "configs";
import { BROKERS_COMMISSION_PERCENTAGE } from "../constants";

export const useValidationTests = () => {
  const user = useAppSelector(state => state.profile.user);
  const rates = useAppSelector(state => state.rates.rates);
  const minAmount = useAppSelector(state => state.createEscrow.minAmount);

  const { t } = useTranslation();
  const { fiatSymbol } = useGetFiatSymbolAndAbbreviation();

  const buyersEmailNotOneOfCallBack = (arg: ValidationArgument) => {
    if (arg.value === user?.email) {
      return t("You cannot use your email address");
    } else if (arg.value) {
      return t("Email addresses cannot be the same");
    }
  };

  const brokersCommissionValueLimit = (val: string | undefined, context: yup.TestContext<yup.AnyObject>) => {
    if (context.from?.[1].value.role.role === Roles.broker && val && +val <= 0) {
      return context.createError({
        path: context.path,
        message: t("Minimum broker fee > 0%"),
      });
    } else if (
      (context.from?.[1].value.role.role === Roles.broker &&
        val &&
        +val > +context.from?.[1].value.currency.amount * BROKERS_COMMISSION_PERCENTAGE) ||
      !context.from?.[1].value.currency.amount
    ) {
      return context.createError({
        path: context.path,
        message: t("Maximum fee for broker = 20%"),
      });
    }
    return true;
  };

  const brokersCommissionIsRequired = (val: string | undefined, context: yup.TestContext<yup.AnyObject>) => {
    if (context.from?.[1].value.role.role === Roles.broker && !val) {
      return false;
    }
    return true;
  };

  const amountMinValue = (val: string, context: yup.TestContext<yup.AnyObject>) => {
    const amountInFee = rates
      ? formatValueToBNString(BigNumber(val).times(rates?.[context.from?.[1].value.currency.currency]))
      : "0";

    if (context.from?.[1].value.role.role === Roles.broker && BigNumber(amountInFee).lt(minAmount.withBroker.inFiat)) {
      return context.createError({
        path: context.path,
        message: t("Minimum value, equivalent to %value%", {
          value: `${fiatSymbol}${BigNumber(minAmount.withBroker.inFiat).toFormatExtended(2)}`,
        }),
      });
    } else if (
      context.from?.[1].value.role.role !== Roles.broker &&
      BigNumber(amountInFee).lt(minAmount.withoutBroker.inFiat)
    ) {
      return context.createError({
        path: context.path,
        message: t("Minimum value, equivalent to %value%", {
          value: `${fiatSymbol}${BigNumber(minAmount.withoutBroker.inFiat).toFormatExtended(2)}`,
        }),
      });
    }

    return true;
  };

  const addressIsRequired = (val: number | undefined | null, context: yup.TestContext<yup.AnyObject>) => {
    if (!val && context.from?.[1].value.role.role === Roles.buyer && context.from?.[1].value.escrowInfo.isShipped) {
      return false;
    }
    return true;
  };

  const buyersEmailValidation = (fields: string[] | boolean[]) => {
    if ((fields[0] === Roles.seller && !fields[1]) || fields[0] === Roles.broker) {
      return yup
        .string()
        .required(t("This field is required"))
        .matches(REGEX.email, t("Invalid email address"))
        .notOneOf([yup.ref("sellersEmail"), user?.email], buyersEmailNotOneOfCallBack);
    }
    return yup.string();
  };

  const sellersEmailEmailValidation = (fields: string[]) => {
    if (fields[0] === Roles.buyer || fields[0] === Roles.broker) {
      return yup
        .string()
        .required(t("This field is required"))
        .matches(REGEX.email, t("Invalid email address"))
        .notOneOf([user?.email], t("You cannot use your email address"));
    }
    return yup.string();
  };

  return {
    brokersCommissionValueLimit,
    brokersCommissionIsRequired,
    amountMinValue,
    addressIsRequired,
    buyersEmailValidation,
    sellersEmailEmailValidation,
  };
};
