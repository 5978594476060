import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg viewBox="0 0 60 60" {...props} color="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.0109 37.5V37.5C25.8667 37.5 22.5078 34.1411 22.5078 29.9969V14.9906C22.5078 10.8464 25.8667 7.48749 30.0109 7.48749V7.48749C34.1552 7.48749 37.5141 10.8464 37.5141 14.9906V29.9969C37.5141 34.1411 34.1552 37.5 30.0109 37.5Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.0164 27.4958V29.9969C45.0164 38.2853 38.2986 45.0031 30.0102 45.0031V45.0031C21.7217 45.0031 15.0039 38.2853 15.0039 29.9969V27.4958"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M30.0103 45.0031V52.5062" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22.5078 52.5063H37.5141" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
};

export default Icon;
