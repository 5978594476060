import React from "react";
import BigNumber from "bignumber.js";

import { useTranslation } from "context";
import { useGetFiatSymbolAndAbbreviation, useTooltip, useTokenBE } from "hooks";

import { Box, Flex, Image, Text } from "components";
import { StyledRow } from "./styled";

import { NATIVE_CURRENCIES_LOGO, SHORT_CHAIN_NAMES } from "configs/web3";

import { VaultInfoProps } from "./types";

const VaultInfo: React.FC<VaultInfoProps> = ({ wallet }) => {
  const { t } = useTranslation();
  const { fiatSymbol } = useGetFiatSymbolAndAbbreviation();

  const { targetRef: targetRefBalance, tooltip: balanceTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const { targetRef: targetRefVaultPrice, tooltip: vaultPriceTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });
  const { token } = useTokenBE(wallet.currency.chainId, wallet.currency.publicCode);

  return (
    <Box width="100%">
      <StyledRow>
        <Text color="neutral500" textScale="caption1">
          {t("Network")}
        </Text>
        <Flex alignItems="center">
          <Box minWidth="24px" minHeight="24px" px="12px">
            <Image
              src={NATIVE_CURRENCIES_LOGO[wallet.currency.chainId]}
              alt={token?.symbol}
              width="24px"
              aspectRatio={1}
              variant="circle"
            />
          </Box>

          <Text textScale="body3" color="neutral800" ellipsis maxWidth="160px">
            {token?.chainId ? SHORT_CHAIN_NAMES[token?.chainId] : ""}
          </Text>
        </Flex>
      </StyledRow>

      <StyledRow>
        <Text color="neutral500" textScale="caption1">
          {t("Balance")}
        </Text>

        <Flex alignItems="center">
          <Box minWidth="24px" minHeight="24px" px="12px">
            <Image src={token?.logoURI ?? ""} alt={token?.symbol} width="24px" aspectRatio={1} variant="circle" />
          </Box>

          <Flex alignItems="baseline">
            <Text textScale="body3" color="neutral800" ellipsis ref={targetRefBalance} px="4px" maxWidth="160px">
              {BigNumber(wallet.availableBalance).toFormatExtended(8)}
            </Text>
            {balanceTooltip}

            <Text textScale="body3" color="neutral800">
              {token?.symbol}
            </Text>
          </Flex>
        </Flex>
      </StyledRow>

      <StyledRow>
        <Text color="neutral500" textScale="caption1">
          {t("Current Vault Price")}
        </Text>
        <Text textScale="body3" color="neutral800" ellipsis ref={targetRefVaultPrice} maxWidth="160px">
          {`${fiatSymbol}${BigNumber(wallet.availableBalance).times(wallet.marketInfo[0].price).toFormatExtended(2)}`}
        </Text>
        {vaultPriceTooltip}
      </StyledRow>
    </Box>
  );
};

export default VaultInfo;
