import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "green", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 72 72" color="transparent" {...props} stroke={theme.colors[color]}>
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.green} strokeWidth="0.5" />
      <g opacity="0.25" filter="url(#filter0_f_1906_68545)">
        <rect x="18" y="49" width="36" height="12" rx="4" fill={theme.colors.green} />
      </g>
      <circle cx="36" cy="36" r="28" fill={theme.colors.greenLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.green} strokeOpacity="0.5" />
      <g transform="translate(18,18)">
        <path d="M14.25 18.2134L17.7862 21.7496L14.25 25.2858L10.7138 21.7496L14.25 18.2134Z" strokeWidth="1.5" />
        <path d="M12 6H9C5.6865 6 3 8.6865 3 12" strokeWidth="1.5" />
        <path d="M9 3L12 6L9 9" strokeWidth="1.5" />
        <path d="M24 30H27C30.3135 30 33 27.3135 33 24" strokeWidth="1.5" />
        <path d="M27 33L24 30L27 27" strokeWidth="1.5" />
        <path
          d="M13.5361 13.536C13.8991 9.315 17.4346 6 21.7501 6C26.3071 6 30.0001 9.693 30.0001 14.25C30.0001 18.5655 26.6851 22.101 22.4641 22.464"
          strokeWidth="1.5"
        />
        <path
          d="M20.0836 15.9164C23.3054 19.1382 23.3054 24.3618 20.0836 27.5836C16.8618 30.8054 11.6382 30.8054 8.41636 27.5836C5.19455 24.3618 5.19455 19.1382 8.41636 15.9164C11.6382 12.6945 16.8618 12.6945 20.0836 15.9164"
          strokeWidth="1.5"
        />
      </g>
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.green} strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_1906_68545"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_1906_68545" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
