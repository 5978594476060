import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";
import { ColdStorageWithdrawSuccessModalProps } from "./types";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => (state.modal.props as unknown as ColdStorageWithdrawSuccessModalProps).amount,
    (state: AppState) => (state.modal.props as unknown as ColdStorageWithdrawSuccessModalProps).currencyCode,
    (state: AppState) => (state.modal.props as unknown as ColdStorageWithdrawSuccessModalProps).fiatAmount,
    (state: AppState) => state.walletsFee.coldWalletFee,
  ],
  (amount, currencyCode, fiatAmount, coldWalletFee) => ({
    amount,
    currencyCode,
    fiatAmount,
    coldWalletFee,
  }),
);
