import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral500", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" {...props} color="transparent" stroke={theme.colors[color]}>
      <path d="M5 7H19" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M18 7V18C18 19.105 17.105 20 16 20H8C6.895 20 6 19.105 6 18V7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M15 3.75H9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 11V16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14 11V16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
};

export default Icon;
