import React from "react";

import { Flex, Text, SingleSelect, Box } from "components";
import { ModalProfileIcon } from "components/svg";

import { useAppSelector } from "store/store";
import { useTranslation } from "context";

import { IdentityVerificationStep1Props } from "../types";
import { AccountType } from "store/profile/types";

const IdentityVerificationStep1: React.FC<IdentityVerificationStep1Props> = ({ selectedOption, onSelectChange }) => {
  const countryCodes = useAppSelector(state => state.kyc.countryCodes);
  const user = useAppSelector(state => state.profile.user);
  const { t } = useTranslation();

  return (
    <Box width="100%">
      <Flex mt="26px" mb="16px" justifyContent="center" width="100%">
        <ModalProfileIcon height="72px" width="72px" />
      </Flex>

      <Text textScale="body2" color="neutral600" textAlign="center">
        {t(user?.accountType === AccountType.BUSINESS ? "Business verification" : "Identity verification")}
      </Text>

      <Text mt="36px" mb="16px" textScale="caption2" textAlign="center" color="neutral500">
        {t("Please go through verification to continue")}
      </Text>

      <SingleSelect
        options={countryCodes}
        value={selectedOption}
        isSearchable
        onChange={onSelectChange}
        placeholder={t("Select your country")}
      />
    </Box>
  );
};

export default IdentityVerificationStep1;
