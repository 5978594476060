import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { tabsIndex } from "configs/tabs";

import { getChatsList, getMessagesList, getChatInfo } from "./actions";

import {
  ChatListPayload,
  ChatMessageStatusEnum,
  ChatRoles,
  CurrentChatRoomPayload,
  MessagesState,
  SetCurrentChatInfoPayload,
  SetNewMessagePayload,
  SetStatusToMessagePayload,
} from "./types";
import { OrderByEnum } from "types/responses";

const initialState: MessagesState = {
  activeTab: tabsIndex.messagesList.buyer,
  error: null,
  chatsListData: {
    buyer: {
      pending: false,
      data: [],
      count: 0,
    },
    seller: {
      pending: false,
      data: [],
      count: 0,
    },
  },
  chatInfo: {
    pending: false,
    chatByReference: {},
  },
  buyerChatsListPayload: {
    page: 1,
    size: 50,
    role: ChatRoles.buyer,
    orderBy: OrderByEnum.updatedAt,
  },
  sellerChatsListPayload: {
    page: 1,
    size: 50,
    role: ChatRoles.seller,
  },
  chatMessagesListData: {},
  chatMessagesIdArray: [],
  currentChatRoomPayload: {
    page: 1,
    size: 50,
  },
  chatListRole: ChatRoles.buyer,
};

const messagesSlice = createSlice({
  name: "messages",
  initialState: {
    ...initialState,
  },
  reducers: {
    resetMessages: () => {
      return initialState;
    },
    resetCurrentChatRoom: state => {
      state.currentChatRoomPayload = initialState.currentChatRoomPayload;
    },
    getChatListRole: (state, action: PayloadAction<ChatRoles>) => {
      state.chatListRole = action.payload;
    },
    setMessagesTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
    setBuyerChatListPayload: (state, action: PayloadAction<Partial<ChatListPayload>>) => {
      state.buyerChatsListPayload = { ...state.buyerChatsListPayload, ...action.payload };
    },
    setSellerChatListPayload: (state, action: PayloadAction<Partial<ChatListPayload>>) => {
      state.sellerChatsListPayload = { ...state.sellerChatsListPayload, ...action.payload };
    },
    setCurrentChatRoomPayload: (state, action: PayloadAction<Partial<CurrentChatRoomPayload>>) => {
      state.currentChatRoomPayload = { ...state.currentChatRoomPayload, ...action.payload };
    },
    setStatusToMessage: (state, action: PayloadAction<SetStatusToMessagePayload>) => {
      const {
        currentMessageId: id,
        chatReference,
        pending,
        isSendSuccess,
        isMessagesRead,
        isDeliveredMessageSuccessfully,
      } = action.payload;

      const currentMessageId = id ?? 0;

      const chatByReferenceData = state.chatMessagesListData[chatReference]
        ? [...state.chatMessagesListData[chatReference].data]
        : [];

      if (chatByReferenceData.length) {
        const data = [currentMessageId, ...state.chatMessagesIdArray];

        state.chatMessagesIdArray = data;
      } else {
        state.chatMessagesIdArray = [currentMessageId];
      }

      const newMessagesList = chatByReferenceData.filter(item =>
        state.chatMessagesIdArray.includes(item.currentMessageId ?? 0),
      );

      newMessagesList.map(item => (item.pending = pending));

      if (isSendSuccess) {
        state.chatMessagesIdArray = initialState.chatMessagesIdArray;
      }

      if (isDeliveredMessageSuccessfully) {
        state.chatInfo.chatByReference[chatReference].isUnread = false;
      }

      if (isMessagesRead) {
        chatByReferenceData.map(item => (item.status = ChatMessageStatusEnum.read));
      }
    },
    updateMessagesList: (state, action: PayloadAction<SetNewMessagePayload>) => {
      const { reference, newMessage } = action.payload;

      const chatReference = reference ?? "";

      if (state.chatMessagesListData[chatReference]) {
        const data = [newMessage, ...state.chatMessagesListData[chatReference].data];
        const count = state.chatMessagesListData[chatReference].count + 1;
        state.chatMessagesListData[chatReference] = { data, count };
      } else {
        const data = [newMessage];
        const count = 1;
        state.chatMessagesListData = {
          ...state.chatMessagesListData,
          [chatReference]: { data, count },
        };
      }
    },
    updateCurrentChatInfo: (state, action: PayloadAction<SetCurrentChatInfoPayload>) => {
      const { reference, chat, activeTabForRole } = action.payload;

      const chatReference = reference ?? "";

      state.chatInfo.chatByReference[chatReference] = chat;

      if (activeTabForRole === state.activeTab) {
        if (state.chatsListData[state.chatListRole].data.length) {
          const data = [...state.chatsListData[state.chatListRole].data].filter(
            item => item.reference !== chat.reference,
          );

          const newData = [chat, ...data];

          state.chatsListData[state.chatListRole].data = newData;
        } else {
          state.chatsListData[state.chatListRole].data = [chat];
        }
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getChatsList.fulfilled, (state, action) => {
        const isBuyerMessagesList = state.activeTab === tabsIndex.messagesList.buyer;
        if (isBuyerMessagesList) {
          if (state.buyerChatsListPayload.page === 1) {
            state.chatsListData.buyer = { ...action.payload, pending: false };
          } else {
            state.chatsListData.buyer = {
              ...state.chatsListData.buyer,
              data: [...state.chatsListData.buyer.data, ...action.payload.data],
              pending: false,
            };
          }
        } else {
          if (state.sellerChatsListPayload.page === 1) {
            state.chatsListData.seller = { ...action.payload, pending: false };
          } else {
            state.chatsListData.seller = {
              ...state.chatsListData.seller,
              data: [...state.chatsListData.seller.data, ...action.payload.data],
              pending: false,
            };
          }
        }

        state.error = null;
      })
      .addCase(getChatsList.pending, state => {
        state.chatsListData[state.chatListRole].pending = true;
      })
      .addCase(getChatsList.rejected, (state, action) => {
        if (action.payload) {
          state.chatsListData = initialState.chatsListData;
          state.error = action.payload;
        }
      })
      .addCase(getMessagesList.fulfilled, (state, action) => {
        const { page } = state.currentChatRoomPayload;
        const reference = action.meta.arg.reference ?? "";

        if (page === 1) {
          state.chatMessagesListData = {
            ...state.chatMessagesListData,
            [reference]: { data: action.payload.data, count: action.payload.count },
          };
        } else {
          state.chatMessagesListData = {
            ...state.chatMessagesListData,
            [reference]: {
              data: [...state.chatMessagesListData[reference].data, ...action.payload.data],
              count: action.payload.count,
            },
          };
        }
        state.error = null;
      })
      .addCase(getMessagesList.rejected, (state, action) => {
        if (action.payload) {
          state.chatMessagesListData = initialState.chatMessagesListData;
          state.error = action.payload;
        }
      })
      .addCase(getChatInfo.fulfilled, (state, action) => {
        // to solve case when payload is null
        // we make 2 different request to receiving data for messenger, and in getChatInfo when reference is wrong, we receive null except error to avoid double toaster notification
        if (action.payload) {
          const { reference } = action.payload;

          state.chatInfo.chatByReference[reference] = action.payload;
          state.chatInfo.pending = false;
          state.error = null;
        }
      })
      .addCase(getChatInfo.pending, state => {
        state.chatInfo.pending = true;
      })
      .addCase(getChatInfo.rejected, (state, action) => {
        if (action.payload) {
          state.chatInfo = initialState.chatInfo;
          state.error = action.payload;
        }
      });
  },
});

export const {
  resetMessages,
  resetCurrentChatRoom,
  setMessagesTab,
  setBuyerChatListPayload,
  setSellerChatListPayload,
  setCurrentChatRoomPayload,
  setStatusToMessage,
  updateMessagesList,
  updateCurrentChatInfo,
  getChatListRole,
} = messagesSlice.actions;
export default messagesSlice;
