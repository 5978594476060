import { ResetAuthIcon, ModalResetAuthIcon } from "components/svg";
import { Column, Text, List } from "components";

import { useTranslation } from "context";
import { isErrorResult } from "services";

import { hideModal, showModal, partialUpdateModalProps } from "store/modal";
import {
  resetAuthenticatorApp,
  highRiskResetAuthenticatorApp,
  confirmResetAuthenticatorApp,
} from "store/profile/actions";
import { useAppDispatch } from "store/store";

import { ModalProps, PartialUpdateModalProps } from "store/modal/types";
import { ActionModalProps } from "components/modal/components/action-modal/types";
import { TwoFactorConfirmModalProps } from "../../components/modal/components/two-factor-confirm-modal/types";
import { ResetTwoFaPayload } from "store/profile/types";
import { NOTES } from "./constants";

const useResetAuthenticatorApp = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const resetAuth = () => {
    dispatch(highRiskResetAuthenticatorApp()).then(res => {
      if (!isErrorResult(res.payload)) {
        showResetModal();
      }
    });
  };

  const resetAuthFromSettings = () => {
    showQuestionModal();
  };

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const showResetModal = () => {
    const _showModal = showModal as ModalProps<ActionModalProps>;

    dispatch(
      _showModal({
        modalName: "actionModal",
        clickOutsideHandler: closeModalHandler,
        props: {
          icon: <ModalResetAuthIcon height="72px" width="72px" />,
          title: t("Authenticator app reset was initiated"),
          text: t("Instructions with a link to reset authenticator app were sent to your email"),
          btnArr: [
            {
              buttonText: t("OK"),
              callback: closeModalHandler,
              props: {
                width: "100%",
              },
            },
          ],
        },
      }),
    );
  };

  const showQuestionModal = () => {
    const _showModal = showModal as ModalProps<ActionModalProps>;

    const notes = NOTES.map(note => t(note));

    dispatch(
      _showModal({
        modalName: "actionModal",
        clickOutsideHandler: closeModalHandler,
        props: {
          icon: <ResetAuthIcon width="72px" />,
          title: t("Are you sure you want to reset the authenticator app?"),
          text: (
            <Column>
              <Text color="neutral600" textScale="caption1" textAlign="center" mt="12px" mb="16px">
                {t("Next actions will be disabled for 24 hours to ensure the safety of your assets:")}
              </Text>
              <List notes={notes} textColor="neutral600" noteColor="primary" />
            </Column>
          ),
          isLoading: false,
          btnArr: [
            {
              buttonText: t("Cancel"),
              callback: closeModalHandler,
              props: {
                width: "100%",
                variant: "outline",
              },
            },
            {
              buttonText: t("Continue"),
              callback: confirmQuestionModalHandler,
              props: {
                width: "100%",
              },
            },
          ],
        },
      }),
    );
  };

  const confirmQuestionModalHandler = () => {
    const _partialProps = partialUpdateModalProps as PartialUpdateModalProps<ActionModalProps>;

    dispatch(_partialProps({ isLoading: true }));
    dispatch(resetAuthenticatorApp()).then(res => {
      if (!isErrorResult(res.payload)) {
        callTwoFactorModal();
      }

      dispatch(_partialProps({ isLoading: false }));
    });
  };

  const callTwoFactorModal = () => {
    const _showModal = showModal as ModalProps<TwoFactorConfirmModalProps<ResetTwoFaPayload, undefined>>;

    dispatch(
      _showModal({
        modalName: "twoFactorConfirmModal",
        rootId: "settings",
        props: {
          title: t("Verification"),
          propsForAction: {
            otps: { emailOtp: "", authenticatorOtp: "" },
          },
          propsForResend: undefined,

          callbackForAction: confirmResetAuthenticatorApp,
          callbackForSuccess: callTwoFaSetupModal,
          callbackForResend: resetAuthenticatorApp,
        },
      }),
    );
  };

  const callTwoFaSetupModal = () => {
    dispatch(
      showModal({
        modalName: "twoFactorSetupModal",
        rootId: "settings",
      }),
    );
  };

  return { resetAuth, resetAuthFromSettings };
};

export default useResetAuthenticatorApp;
