import { useEffect } from "react";

import { useGetMitekSDK } from "hooks";
import { useHandleVerifyStatus } from "./";

import { useTranslation } from "context";

import { hideModal, partialUpdateModalProps, showModal } from "store/modal";
import { useAppDispatch } from "store/store";
import { setIsShowError } from "store/verify-biometric";

import { mitekActions } from "configs";

import { MitekVerifyError } from "types";
import { ModalProps, PartialUpdateModalProps } from "store/modal/types";
import { VoiceCaptureModalProps, microphoneStatusEnum } from "components/modal/components/voice-capture-modal/types";

const useHandleVoiceVerify = () => {
  const { mitekScienceSDK } = useGetMitekSDK();

  const { handleVoiceVerify, stopMitekSDK, handleVerifyCallback, showErrorModal } =
    useHandleVerifyStatus(mitekScienceSDK);

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    if (mitekScienceSDK) {
      // on load component, we setup mitek SDK with base params
      // preloadComponents is array of different actions, that we will do. ex: make photo, upload files, voice verify, etc.
      mitekScienceSDK.cmd(mitekActions.componentPreload, {
        preloadComponents: ["VOICE"],
        mitekSDKPath: "mitekSDK/",
        options: {
          license: process.env.REACT_APP_MIPASS_LICENSE,
        },
      });
      mitekScienceSDK.on(mitekActions.sdkError, (err: MitekVerifyError) => {
        showErrorModal(err.type);
      });
    }
  }, []);

  const handleCloseVerifyModal = () => {
    dispatch(setIsShowError(false));
    stopMitekSDK();
    dispatch(hideModal());
  };

  const handleVerify = () => {
    const _showModal = showModal as ModalProps<VoiceCaptureModalProps>;

    const _partialProps = partialUpdateModalProps as PartialUpdateModalProps<VoiceCaptureModalProps>;

    dispatch(_partialProps({ microphoneStatus: microphoneStatusEnum.LOADING }));
    dispatch(hideModal());
    dispatch(
      _showModal({
        modalName: "voiceCaptureModal",
        rootId: "cold-storage",
        clickOutsideHandler: handleCloseVerifyModal,
        props: {
          microphoneStatus: microphoneStatusEnum.LOADING,
          title: t("Voice capture"),
          closeCallback: handleCloseVerifyModal,
          verifyCallback: () => handleVerifyCallback(stopMitekSDK, "voice"),
          verifyDescription: t("Have troubles with the verification?"),
        },
      }),
    );

    handleVoiceVerify();
  };

  return { handleVerify, isLoading: !mitekScienceSDK, stopMitekSDK, showErrorModal };
};

export default useHandleVoiceVerify;
