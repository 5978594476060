import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => state.notifications.currentNotification,
    (state: AppState) => state.notifications.notificationsListData.data,
    (state: AppState) => state.notifications.notificationsListData.total,
    (state: AppState) => state.notifications.notificationsListData.pending,
    (state: AppState) => state.notifications.unreadNotificationCount,
  ],
  (currentNotification, data, total, pending, unreadNotificationCount) => ({
    currentNotification,
    data,
    total,
    pending,
    unreadNotificationCount,
  }),
);
