import { useEffect, useState } from "react";

export const useOutsideClick = ({ callback }: { callback: () => void }) => {
  const [element, setElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (element && !element.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [callback]);

  return { ref: setElement };
};
