import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => state.walletsHistory.historyPayload.size,
    (state: AppState) => state.walletsHistory.historyPayload.page,
    (state: AppState) => state.walletsHistory.historyPayload.chainId,
    (state: AppState) => state.walletsHistory.historyPayload.currencyCode,
    (state: AppState) => state.walletsHistory.historyPayload.type,
    (state: AppState) => state.walletsHistory.historyPayload.dateFrom,
    (state: AppState) => state.walletsHistory.historyPayload.dateTo,
    (state: AppState) => state.walletsHistory.historyInfo.data,
    (state: AppState) => state.walletsHistory.historyInfo.count,
    (state: AppState) => state.walletsHistory.historyInfo.pending,
    (state: AppState) => state.profile.user,
  ],
  (size, page, chainId, currencyCode, type, dateFrom, dateTo, data, count, pending, user) => ({
    size,
    page,
    chainId,
    currencyCode,
    type,
    dateFrom,
    dateTo,
    data,
    count,
    pending,
    user,
  }),
);
