import { useEffect, useState } from "react";

import { MitekSDK } from "./types";

const useGetMitekSDK = () => {
  const [mitekScienceSDK, setSDK] = useState<MitekSDK | undefined>();

  useEffect(() => {
    if (window?.mitekScienceSDK) {
      setSDK(window.mitekScienceSDK);
    }
  }, []);

  return { mitekScienceSDK };
};

export default useGetMitekSDK;
