import React, { useState } from "react";

import { useTranslation } from "context";
import { useAppDispatch } from "store/store";

import { Box, Button, FlexGap, InputGroup, Text } from "components";
import { StyledModalWrapper } from "../styled";
import CloseModalWrapper from "../close-modal-wrapper";
import { DeleteAssetsIcon } from "components/svg";
import { StyledDeletionReasonTextArea } from "./styled";

import { hideModal, showModal } from "store/modal";
import { setDeletionReason } from "store/profile";

const DeletionReasonModal: React.FC = () => {
  const [deleteReason, setDeleteReason] = useState("");

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const deleteAccountClick = () => {
    dispatch(setDeletionReason(deleteReason));
    dispatch(showModal({ rootId: "settings", modalName: "deleteAccountModal" }));
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} mb="8px" />

      <DeleteAssetsIcon width="72px" />

      <Text pb={{ _: "12px", tablet: "24px" }} pt="14px" textScale="body2" color="neutral600" textAlign="center">
        {t("Deletion reason")}
      </Text>

      <Box mb={{ _: "24px", tablet: "36px" }} width="100%">
        <InputGroup width="100%" isShowError={false}>
          <StyledDeletionReasonTextArea
            placeholder={t("Tell us about the reason of deleting your Zert account (optional)")}
            lineHeight="24px"
            onChange={e => setDeleteReason(e.target.value)}
          />
        </InputGroup>
      </Box>

      <FlexGap gap="14px" mb="16px" flexDirection={{ _: "column-reverse", tablet: "row" }} width="100%">
        <Button variant="outline" minWidth={{ _: "100%", tablet: "170px" }} onClick={closeModalHandler}>
          {t("Cancel")}
        </Button>

        <Button minWidth={{ _: "100%", tablet: "170px" }} onClick={deleteAccountClick}>
          {t("Delete Account")}
        </Button>
      </FlexGap>
    </StyledModalWrapper>
  );
};

export default DeletionReasonModal;
