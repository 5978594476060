import React from "react";

import { useThemeContext } from "context";

import { Svg } from "components";

import { SvgProps } from "components/svg/types";

const Icon: React.FC<SvgProps> = ({ color = "neutral600", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg width="20" height="20" viewBox="0 0 20 20" color="transparent" {...props}>
      <circle
        cx="9.99984"
        cy="10.0001"
        r="9.00375"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0014 12.001H5.99805V6.99895C5.99805 6.44672 6.44623 5.99854 6.99846 5.99854H13.001C13.5532 5.99854 14.0014 6.44672 14.0014 6.99895V12.001Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.99707 16.7099V14.0018C3.99707 12.8963 4.89244 12.001 5.9979 12.001H14.0012C15.1067 12.001 16.0021 12.8963 16.0021 14.0018V16.7099"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2502 8.49951V9.49993"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.74923 8.49951V9.49993"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
