import React from "react";

import { Column, StyledQr, Text, Flex } from "components";

import { useThemeContext, useTranslation } from "context";

import { WalletQrProps } from "./types";

const WalletQr: React.FC<WalletQrProps> = ({ address }) => {
  const { t } = useTranslation();
  const { theme } = useThemeContext();

  const qrCodeString = address ?? t("Deposit address is not valid");

  return (
    <Column mt="24px">
      <Text textScale="caption1" mb="8px" color="neutral600">
        {t("QR code")}
      </Text>

      <Flex justifyContent="center">
        <StyledQr
          fgColor={address && !theme.isDark ? theme.colors.neutral1000 : theme.colors.neutral200}
          value={qrCodeString}
        />
      </Flex>
    </Column>
  );
};

export default WalletQr;
