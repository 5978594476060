import { BiometricType } from "./types";

export const biometricImageHeight = {
  [BiometricType.face]: {
    mobile: "310px",
    laptop: "355px",
    aspectRatioLaptop: 0.71,
    aspectRatioMobile: 0.97,
  },
  [BiometricType.voice]: {
    mobile: "300px",
    laptop: "254px",
    aspectRatioLaptop: 1,
    aspectRatioMobile: 1,
  },
  [BiometricType.both]: {
    mobile: "300px",
    laptop: "254px",
    aspectRatioLaptop: 1,
    aspectRatioMobile: 1,
  },
};
