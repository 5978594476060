import styled from "styled-components";
import { layout, LayoutProps } from "styled-system";

export const StyledAddresses = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral300};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral300};
  padding: 36px 0;
`;

export const StyledAddBtn = styled.div<LayoutProps>`
  display: flex;
  border: 1px dashed ${({ theme }) => theme.colors.neutral300};
  min-height: 206px;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  cursor: pointer;

  ${layout}
`;
