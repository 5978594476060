import memoize from "lodash/memoize";
import { EN, LOCAL_STORAGE_KEYS, REGEX } from "configs";

export const fetchLocale = async () => {
  try {
    const response = await fetch("/locales/en-US.json");

    const data = await response.json();
    return data;
  } catch (error) {
    return null;
  }
};

export const getLanguageCodeFromLS = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEYS.language) ?? EN.locale;
};

export const translatedTextIncludesVariable = memoize(translatedText => {
  return !!translatedText?.match(REGEX.includesVariableRegex);
});
