import React from "react";
// Components + styling
import { Box, Flex, Image, Text } from "components";
import { NetworkContainer } from "./styled";

import { SingleNetworkItemProps } from "./types";
import { SwapNetworkTokenProps } from "components/modal/components/swap-network-token-modal/types";
import { ChainId, NATIVE_CURRENCIES_LOGO, SHORT_CHAIN_NAMES } from "configs/web3";

import { useAppDispatch, useAppSelector } from "store/store";
import { setFromData, setToData, switchedInsurance } from "store/swap";

const SingleNetworkItem: React.FC<SingleNetworkItemProps> = ({ networkItem, resetAmount }) => {
  const dispatch = useAppDispatch();
  const swapType = useAppSelector(state => (state.modal.props as unknown as SwapNetworkTokenProps).swapType);
  const swap = useAppSelector(state => state.swap);

  const setSwapNetwork = () => {
    dispatch(swapType === "from" ? setFromData({ network: networkItem }) : setToData({ network: networkItem }));
    resetAmount();
    checkIfNetworkSame(swapType, networkItem);
  };

  const checkIfNetworkSame = (swapType: "from" | "to", network: ChainId) => {
    if (swap[swapType]?.network === network) {
      dispatch(switchedInsurance(false));
    }
  };

  return (
    <NetworkContainer isSelected={swap[swapType]?.network === networkItem} onClick={setSwapNetwork}>
      <Flex height="100%" width="100%" alignItems="center" justifyContent="center">
        <Box minWidth="24px" minHeight="24px">
          <Image
            src={NATIVE_CURRENCIES_LOGO[networkItem]}
            alt={SHORT_CHAIN_NAMES[networkItem]}
            variant="circle"
            width="24px"
            aspectRatio={1}
          />
        </Box>

        <Text mx="8px" textScale="body3" color="neutral600" textAlign="center" $fontWeight="medium">
          {SHORT_CHAIN_NAMES[networkItem]}
        </Text>
      </Flex>
    </NetworkContainer>
  );
};

export default SingleNetworkItem;
