import styled from "styled-components";
import { layout, space, SpaceProps } from "styled-system";

import { Flex, Row, Column, Text } from "components";

export const StyledModalWrapper = styled(Flex)`
  width: 288px;
  padding: 12px;

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 24px;
    width: 418px;
  }

  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.neutral200};
  border-radius: 16px;
`;

export const SIDE_MODAL_LAPTOP_WIDTH = 400;
export const SIDE_MODAL_LAPTOP_X_PADDING = 36;
export const SIDE_MODAL_MOBILE_WIDTH = "100vw";
export const SIDE_MODAL_MOBILE_X_PADDING = 16;

export const StyledSideModalWrapper = styled(Column)`
  width: ${SIDE_MODAL_MOBILE_WIDTH};
  padding: 20px ${SIDE_MODAL_MOBILE_X_PADDING}px;
  height: 100dvh;
  overflow-y: auto;
  overflow-x: hidden;
  ${({ theme }) => theme.mediaQueries.mobileL} {
    width: ${SIDE_MODAL_LAPTOP_WIDTH}px;
    padding: 24px ${SIDE_MODAL_LAPTOP_X_PADDING}px;
  }
  ${layout}
`;

export const StyledRow = styled(Row)`
  flex-direction: column;
  justify-content: center;
  padding-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral200};
`;

export const RotateIconWrapper = styled.div<{ rotateUp?: boolean }>`
  transform: ${({ rotateUp }) => (rotateUp ? `rotate(-90deg)` : `rotate(90deg)`)};
`;

export const StyledHr = styled.div<SpaceProps>`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.neutral200};
  ${space}
`;

export const StyledFilterSectionTitle = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.neutral600};
`;

//92px - it`s height of confirm container

export const StyledConfirmContainer = styled.div`
  width: 100%;
  padding: 24px 28px;
  box-shadow: ${({ theme }) => theme.shadows.confirmContainer};
  background-color: ${({ theme }) => theme.colors.neutral0};
  position: fixed;
  top: calc(100dvh - 92px);
  left: 0;
  z-index: 10;
`;
