import useSWR from "swr";
import { getWallets } from "../store/wallets/cold-wallets/actions";
import { setNetworks } from "store/wallets/hot-wallets";
import { useAppDispatch, useAppSelector } from "store/store";

import { setNetworksWithCurrencies } from "configs/web3";

import { isErrorResult } from "services";

import { UserWalletsStatusEnum } from "store/profile/types";
import { WalletsTypeEnum } from "store/wallets/enums";

const useGetWalletsAndSetNetworks = () => {
  const user = useAppSelector(state => state.profile.user);
  const allTokensList = useAppSelector(state => state.tokensList.allTokensList);
  const pending = useAppSelector(state => state.tokensList.pending);

  const dispatch = useAppDispatch();

  const { mutate } = useSWR(
    user && user.isVerifiedKYC && !pending && user.walletsStatus === UserWalletsStatusEnum.CREATED
      ? "getWalletsAndSetNetworks"
      : null,
    () => {
      dispatch(getWallets({ type: WalletsTypeEnum.hot })).then(response => {
        if (!isErrorResult(response.payload)) {
          const networks = setNetworksWithCurrencies(response.payload!.wallets, allTokensList);

          if (networks) {
            dispatch(setNetworks(networks));
          }
        }
      });
    },
    { revalidateOnFocus: false },
  );
  return { refresh: mutate };
};

export default useGetWalletsAndSetNetworks;
