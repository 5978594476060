import styled from "styled-components";

import { FlexGap } from "components/layout";

export const StyledUserAssetsContainer = styled(FlexGap)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral100};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutral200};
  padding: 18px 14px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center;
`;
