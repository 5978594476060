import React from "react";
import BigNumber from "bignumber.js";

import { useTranslation } from "context";
import { useTokenBE } from "hooks";

import { StyledModalWrapper } from "../styled";
import { Button, Row, RowBetween, Text } from "components";
import CloseModalWrapper from "../close-modal-wrapper";

import { formatValueToBNString, NATIVE_CURRENCIES } from "configs/web3";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";

import { selectCombinedData } from "./selectors";

const WithdrawPayWithTokenModal: React.FC = () => {
  const { handleWithdraw, paramsChainId, tokenKey, amount, fee, pending } = useAppSelector(selectCombinedData);

  const { t } = useTranslation();
  const { token } = useTokenBE(paramsChainId.toString(), tokenKey);

  const nativeToken = NATIVE_CURRENCIES[paramsChainId!];
  const payValue = BigNumber(formatValueToBNString(BigNumber(amount).plus(fee.tokensAmount)));
  const formattedPayValue = payValue.toFormatExtended(18);

  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} mb="16px" />

      <Row justifyContent="center" mb="8px">
        <Text $fontWeight="medium" textScale="body3" color="neutral800" textAlign="center">
          {t("Pay with %tokenKey%", { tokenKey: `${token?.symbol}` })}
        </Text>
      </Row>

      <Text textScale="caption1" color="neutral600" textAlign="center">
        {t(
          "Oops, seems like you don’t have enough %symbol% to cover the gas fee. Let us help you get it covered in %tokenKey%. The amount of the fee is %tokenAmount% %tokenKey%. In total you will pay ~%payValue% %tokenKey%",
          {
            symbol: nativeToken.symbol,
            tokenKey: token?.symbol ? token?.symbol : "-",
            tokenAmount: `${BigNumber(fee.tokensAmount ?? "0").toFormatExtended(18)}`,
            payValue: formattedPayValue,
          },
        )}
      </Text>

      <RowBetween
        mt={{ _: "16px", tablet: "36px" }}
        mb={{ _: "20px", tablet: "30px" }}
        flexDirection={{ _: "column", tablet: "row" }}
      >
        <Button
          variant="outline"
          px="20px"
          minWidth={{ _: "100%", tablet: "160px" }}
          onClick={closeModalHandler}
          isLoading={pending}
        >
          {t("Decline")}
        </Button>

        <Button
          px="20px"
          minWidth={{ _: "100%", tablet: "160px" }}
          variant="primary"
          onClick={handleWithdraw}
          isLoading={pending}
          mt={{ _: "12px", tablet: "0px" }}
        >
          {t("Accept")}
        </Button>
      </RowBetween>
    </StyledModalWrapper>
  );
};

export default WithdrawPayWithTokenModal;
