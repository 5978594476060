import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  getEscrowById,
  cancelEscrow,
  setEscrowDeliveryAddress,
  modifyEscrowProducts,
  acceptEscrow,
  prepayEscrow,
  payEscrow,
  getDeliveryCouriers,
  setShipments,
  confirmDelivery,
} from "./actions";

import { EscrowProcessState } from "./types";
import { MyEscrowsSingleItem } from "../escrows-table/types";

const initialState: EscrowProcessState = {
  error: null,
  pending: false,
  currentEscrow: null,
  currentStepNumber: 1,
  couriersList: [],
};

const escrowProcessSlice = createSlice({
  name: "escrowProcess",
  initialState: {
    ...initialState,
  },
  reducers: {
    resetEscrowProcess: () => {
      return initialState;
    },
    setCurrentStepNumber: (state, action: PayloadAction<number>) => {
      state.currentStepNumber = action.payload;
    },
    updateCurrentEscrow: (state, action: PayloadAction<MyEscrowsSingleItem>) => {
      if (state.currentEscrow?.body.id === action.payload.body.id) {
        state.currentEscrow = action.payload;
      }
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getEscrowById.fulfilled, (state, action) => {
        state.currentEscrow = action.payload;

        state.pending = false;
        state.error = null;
      })
      .addCase(getEscrowById.pending, state => {
        state.pending = true;
      })
      .addCase(getEscrowById.rejected, (state, action) => {
        if (action.payload) {
          state.currentEscrow = initialState.currentEscrow;

          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(cancelEscrow.fulfilled, (state, action) => {
        state.currentEscrow = action.payload;

        state.pending = false;
        state.error = null;
      })
      .addCase(cancelEscrow.pending, state => {
        state.pending = true;
      })
      .addCase(cancelEscrow.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(setEscrowDeliveryAddress.fulfilled, (state, action) => {
        state.currentEscrow = action.payload;

        state.pending = false;
        state.error = null;
      })
      .addCase(setEscrowDeliveryAddress.pending, state => {
        state.pending = true;
      })
      .addCase(setEscrowDeliveryAddress.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(modifyEscrowProducts.fulfilled, (state, action) => {
        state.currentEscrow = action.payload;

        state.pending = false;
        state.error = null;
      })
      .addCase(modifyEscrowProducts.pending, state => {
        state.pending = true;
      })
      .addCase(modifyEscrowProducts.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(acceptEscrow.fulfilled, (state, action) => {
        state.currentEscrow = action.payload;

        state.pending = false;
        state.error = null;
      })
      .addCase(acceptEscrow.pending, state => {
        state.pending = true;
      })
      .addCase(acceptEscrow.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(prepayEscrow.fulfilled, (state, action) => {
        state.currentEscrow = action.payload;

        state.pending = false;
        state.error = null;
      })
      .addCase(prepayEscrow.pending, state => {
        state.pending = true;
      })
      .addCase(prepayEscrow.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(payEscrow.fulfilled, (state, action) => {
        state.currentEscrow = action.payload;

        state.pending = false;
        state.error = null;
      })
      .addCase(payEscrow.pending, state => {
        state.pending = true;
      })
      .addCase(payEscrow.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(getDeliveryCouriers.fulfilled, (state, action) => {
        state.couriersList = action.payload;

        state.pending = false;
        state.error = null;
      })
      .addCase(getDeliveryCouriers.pending, state => {
        state.pending = true;
      })
      .addCase(getDeliveryCouriers.rejected, (state, action) => {
        if (action.payload) {
          state.couriersList = [];

          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(setShipments.fulfilled, (state, action) => {
        state.currentEscrow = action.payload;

        state.pending = false;
        state.error = null;
      })
      .addCase(setShipments.pending, state => {
        state.pending = true;
      })
      .addCase(setShipments.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(confirmDelivery.fulfilled, (state, action) => {
        state.currentEscrow = action.payload;

        state.pending = false;
        state.error = null;
      })
      .addCase(confirmDelivery.pending, state => {
        state.pending = true;
      })
      .addCase(confirmDelivery.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      });
  },
});

export const { resetEscrowProcess, setCurrentStepNumber, updateCurrentEscrow } = escrowProcessSlice.actions;
export default escrowProcessSlice;
