import React, { useEffect, useRef } from "react";

import { useAppDispatch, useAppSelector } from "store/store";
import { useElementDimensions, useMatchBreakpoints } from "hooks";
import { useGetSearchParams } from "hooks";

import { Column, FlexGap, StyledContentWrapper } from "components";
import { MessagesList, MessengerWindow } from "./components";

import { ROUTES } from "navigation/routes";
import { SEARCH_PARAMS } from "navigation/search-params";

import { resetCurrentChatRoom } from "store/messages";
import { selectCombinedData } from "./selectors";

const Messages: React.FC = () => {
  const { user, chatsListData, pending, chatByReference, chatListRole } = useAppSelector(selectCombinedData);

  const { isMobile } = useMatchBreakpoints();
  const dispatch = useAppDispatch();
  const { currentSearchParams: currentChatReference } = useGetSearchParams(SEARCH_PARAMS.currentChatId);
  const ref = useRef<HTMLDivElement>(null);
  const { offsetHeight: messagesContentWrapperHeight } = useElementDimensions(ROUTES.messages);

  const chat = chatByReference[currentChatReference];

  const data = chatsListData[chatListRole].data;

  useEffect(() => {
    return () => {
      dispatch(resetCurrentChatRoom());
    };
  }, []);

  useEffect(() => {
    //This crutch for correct ui display

    if (ref.current && pending && !chat) {
      ref.current.style.height = "100%";
      ref.current.style.minHeight = "100%";
    }

    if (ref.current && !pending && chat) {
      ref.current.style.minHeight = "300px";
    }
  }, [ref.current, pending, chat]);

  return (
    <FlexGap gap="24px" height="100%" id={ROUTES.messages}>
      <StyledContentWrapper
        minWidth={{ _: "100%", tablet: "280px", laptop: "40%" }}
        maxWidth={{ _: "100%", tablet: "40%" }}
        maxHeight={`${messagesContentWrapperHeight}px !important`}
      >
        <Column height="100%">{currentChatReference && isMobile ? <MessengerWindow /> : <MessagesList />}</Column>
      </StyledContentWrapper>

      {(!currentChatReference && !data.length) || !user || isMobile ? null : (
        <StyledContentWrapper
          maxHeight={`${messagesContentWrapperHeight}px !important`}
          minWidth={{ _: "368px", laptop: "60% - 24px" }}
          width="100%"
          ref={ref}
        >
          <MessengerWindow />
        </StyledContentWrapper>
      )}
    </FlexGap>
  );
};

export default Messages;
