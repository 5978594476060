import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { ROUTES } from "navigation/routes";

export type ModalState<T> = {
  modalName: null | keyof typeof ModalNames;
  rootId: (typeof ROUTES)[keyof typeof ROUTES] | "modal-story" | "modal";
  props?: T;
  modalType?: keyof typeof ModalType;
  clickOutsideHandler?: (() => void) | null;
};

export type ShowModalProps<T> = {
  modalName: ModalState<T>["modalName"];
  rootId?: ModalState<T>["rootId"];
  props?: T;
  modalType?: ModalState<T>["modalType"];
  clickOutsideHandler?: (() => void) | null;
};

export interface ModalProps<T> extends ActionCreatorWithPayload<ShowModalProps<T>, string> {}

export enum ModalNames {
  testModal = "testModal",
  imageCropperModal = "imageCropperModal",
  actionModal = "actionModal",
  identityVerification = "identityVerification",
  forgotPassword = "forgotPassword",
  newPassword = "newPassword",
  buyCryptoModal = "buyCryptoModal",
  swapNetworkTokenModal = "swapNetworkTokenModal",
  coldStorageDepositModal = "coldStorageDepositModal",
  coldStorageDepositSuccessModal = "coldStorageDepositSuccessModal",
  coldStorageWithdrawModal = "coldStorageWithdrawModal",
  coldStorageWithdrawSuccessModal = "coldStorageWithdrawSuccessModal",
  twoFactorConfirmModal = "twoFactorConfirmModal",
  swapSuccessModal = "swapSuccessModal",
  withdrawPayWithTokensModal = "withdrawPayWithTokensModal",
  depositChooseWalletModal = "depositChooseWalletModal",
  depositChooseNetworkModal = "depositChooseNetworkModal",
  depositChooseTokenModal = "depositChooseTokenModal",
  whitelistAddNewModal = "whitelistAddNewModal",
  whitelistSideModal = "whitelistSideModal",
  walletHistoryFiltersMobileModal = "walletHistoryFiltersMobileModal",
  addressesSideModal = "addressesSideModal",
  widgetBuyersListSideModal = "widgetBuyersListSideModal",
  deliveryAddressesSideModal = "deliveryAddressesSideModal",
  setupBiometricModal = "setupBiometricModal",
  automaticFaceCaptureModal = "automaticFaceCaptureModal",
  widgetCreatedModal = "widgetCreatedModal",
  escrowCreatedModal = "escrowCreatedModal",
  voiceCaptureModal = "voiceCaptureModal",
  escrowDetailsModifySideModal = "escrowDetailsModifySideModal",
  escrowConfirmPaymentModal = "escrowConfirmPaymentModal",
  addYourYubikeyModal = "addYourYubikeyModal",
  yubikeyNameRegisterModal = "yubikeyNameRegisterModal",
  yubikeysListModal = "yubikeysListModal",
  deleteYubikeyModal = "deleteYubikeyModal",
  yubikeyVerificationModal = "yubikeyVerificationModal",
  escrowDashboardMobileFilterModal = "escrowDashboardMobileFilterModal",
  reviewAssetsFromDeleteAccountModal = "reviewAssetsFromDeleteAccountModal",
  deletionReasonModal = "deletionReasonModal",
  deleteAccountModal = "deleteAccountModal",
  twoFactorSetupModal = "twoFactorSetupModal",
  notificationsSideModal = "notificationsSideModal",
}

export enum ModalType {
  modal = "modal",
  rightMenu = "rightMenu",
  fullScreenOnMobile = "fullScreenOnMobile",
}

export type PartialUpdateModalProps<T> = ActionCreatorWithPayload<Partial<ShowModalProps<T>["props"]>>;
