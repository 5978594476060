import React from "react";

import { useThemeContext } from "context";

import { Svg } from "components";

import { SvgProps } from "components/svg/types";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg width="72" viewBox="0 0 72 84" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_9396_103324)">
        <rect x="18" y="49" width="36" height="17" rx="4" fill={theme.colors.yellow} />
      </g>
      <circle cx="36" cy="36" r="28" fill={theme.colors.yellowLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.yellow} strokeOpacity="0.5" />
      <circle
        cx="35.9998"
        cy="36"
        r="13.5056"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.003 39.0013H29.998V31.4982C29.998 30.6698 30.6703 29.9976 31.4987 29.9976H40.5024C41.3308 29.9976 42.003 30.6698 42.003 31.4982V39.0013Z"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.9961 46.0647V42.0025C26.9961 40.3443 28.3392 39.0012 29.9973 39.0012H42.0023C43.6605 39.0012 45.0036 40.3443 45.0036 42.0025V46.0647"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.8753 33.749V35.2496"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.1234 33.749V35.2496"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="47"
        cy="47"
        r="5"
        fill={theme.colors.primaryLight}
        stroke={theme.colors.yellowLight}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="47"
        cy="47"
        r="4"
        fill={theme.colors.yellowLight}
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.9997 47.9946L46.0012 46.005"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.0047 48.0012L47.9943 46.0027"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.yellow} strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_9396_103324"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_9396_103324" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
