export const autoCaptureOptions = {
  qualityPercent: 90,
  hintFrequencyMS: 1000,
  hintAriaLive: 0,
  faceDetectionLevel: 1,
  hintMessageSize: 1,
  disableSmileDetection: true,
  disablePerpendicularCapture: true,
  videoRecordingEnabled: false,
  audioRecordingEnabled: false,
  redactMRZOptionalData: true,
  license: process.env.REACT_APP_MIPASS_LICENSE,
};

export const voiceCaptureOptions = {
  license: process.env.REACT_APP_MIPASS_LICENSE,
  phrase: "My voice is my password",
};

export const mitekActions = {
  cameraStarted: "CAMERA_DISPLAY_STARTED",
  captureAndProcessFrame: "CAPTURE_AND_PROCESS_FRAME",
  sdkError: "SDK_ERROR",
  frameCaptureResult: "FRAME_CAPTURE_RESULT",
  frameProcessFeedback: "FRAME_PROCESSING_FEEDBACK",
  componentPreload: "COMPONENT_PRELOAD",
  sdkStop: "SDK_STOP",
  showHint: "SHOW_HINT",
  frameProcessingStated: "FRAME_PROCESSING_STARTED",
  captureAndProcessVoice: "CAPTURE_AND_PROCESS_VOICE",
  voiceRecordingStarted: "VOICE_RECORDING_STARTED",
  voiceCaptureResult: "VOICE_CAPTURE_RESULT",
  voiceProcessingStarted: "VOICE_PROCESSING_STARTED",
};
