import React from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "context";

import { Box, Text } from "components";
import { ArrowRightIcon } from "components/svg";
import { StyledBackButton, StyledWrapper } from "./styled";

import { ROUTES } from "navigation/routes";

const BackButton: React.FC<{ onBackButtonClick?: () => void }> = ({ onBackButtonClick }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const clickHandler = () => {
    if (onBackButtonClick) {
      onBackButtonClick();
    } else {
      navigate(`/${ROUTES.login}`);
    }
  };

  return (
    <StyledWrapper>
      <Box width="100%" maxWidth={{ _: "484px", laptop: "452px" }} px="16px">
        <StyledBackButton onClick={clickHandler}>
          <ArrowRightIcon color="neutral600" rotate="180deg" />

          <Text mx="8px" textScale="body3" color="neutral600">
            {t("Back")}
          </Text>
        </StyledBackButton>
      </Box>
    </StyledWrapper>
  );
};

export default BackButton;
