import React from "react";

import { useTooltip } from "hooks";

import { Text } from "components";
import { StyledFiltersTag } from "./styled";
import { CloseIcon } from "components/svg";

import { FiltersTagProps } from "../types";

const FiltersTag: React.FC<FiltersTagProps> = ({ name, removeTagHandler, ...props }) => {
  const { targetRef: targetRefName, tooltip: nameTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  return (
    <StyledFiltersTag alignItems="center" ellipsis {...props}>
      {name ? (
        <>
          <Text textScale="caption2" color="neutral800" ref={targetRefName} ellipsis>
            {name.charAt(0).toUpperCase() + name.slice(1).replaceAll("_", " ")}
          </Text>

          {nameTooltip}
        </>
      ) : null}

      <CloseIcon width="12px" onClick={removeTagHandler} cursor="pointer" />
    </StyledFiltersTag>
  );
};

export default FiltersTag;
