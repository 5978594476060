import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";
import { YubikeyVerificationModalProps } from "./types";

export const selectCombinedData = <T, R>() => {
  const selector = createSelector(
    [
      (state: AppState) => (state.modal.props as unknown as YubikeyVerificationModalProps<T, R>).propsForSignInCallback,
      (state: AppState) => (state.modal.props as unknown as YubikeyVerificationModalProps<T, R>).signInActionCallback,
      (state: AppState) => (state.modal.props as unknown as YubikeyVerificationModalProps<T, R>).signInSuccessCallback,
      (state: AppState) => (state.modal.props as unknown as YubikeyVerificationModalProps<T, R>).pending,
      (state: AppState) =>
        (state.modal.props as unknown as YubikeyVerificationModalProps<T, R>).switchAnotherMethodVerificationCallback,
    ],
    (
      propsForSignInCallback,
      signInActionCallback,
      signInSuccessCallback,
      pending,
      switchAnotherMethodVerificationCallback,
    ) => ({
      propsForSignInCallback,
      signInActionCallback,
      signInSuccessCallback,
      pending,
      switchAnotherMethodVerificationCallback,
    }),
  );

  return selector;
};
