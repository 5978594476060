import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 72 72" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_4623_103893)">
        <rect x="18" y="49" width="36" height="12" rx="4" fill={theme.colors.primary} />
      </g>
      <circle cx="36" cy="36" r="28" fill="transparent" />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.primary} strokeOpacity="0.5" />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.primary} strokeWidth="0.5" />
      <path
        d="M32.4983 32.9988V33.9992"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.5002 32.9988V33.9992"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.501 38.6891C37.4947 40.4393 34.5043 40.4393 32.498 38.6891"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.3607 22.7175C37.5927 22.584 36.8067 22.5 35.9997 22.5C35.1927 22.5 34.4067 22.584 33.6387 22.7175"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M48.7771 31.4595C49.3066 32.913 49.6096 34.4745 49.6096 36.1095"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.4213 27.3645C45.4043 26.154 44.1758 25.1295 42.7988 24.333"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.2006 24.333C27.8236 25.1295 26.5966 26.154 25.5781 27.3645"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.3904 36.1095C22.3904 34.473 22.6934 32.9115 23.2229 31.4595"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.2012 47.886C30.5512 48.6675 32.0467 49.221 33.6397 49.5"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.2227 40.7595C23.7717 42.2655 24.5667 43.65 25.5777 44.8545"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.3613 49.5C39.9543 49.221 41.4483 48.6675 42.7998 47.886"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.4219 44.8545C47.4329 43.65 48.2279 42.2655 48.7769 40.7595"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_f_4623_103893"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_4623_103893" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
