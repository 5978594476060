import React from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "context";
import { useAppDispatch, useAppSelector } from "store/store";

import { StyledModalWrapper } from "../styled";
import CloseModalWrapper from "../close-modal-wrapper";
import { DeleteAssetsIcon } from "components/svg";
import { Button, FlexGap, Text } from "components";

import { useActivationState } from "configs/web3";
import { resetStore } from "services/axios";
import { ROUTES } from "navigation/routes";

import { hideModal, showModal } from "store/modal";
import { setDeletionReason } from "store/profile";
import { softDeleteCheck, softDeleteConfirm } from "store/profile/actions";

import { ModalProps } from "store/modal/types";
import { TwoFactorConfirmModalProps } from "../two-factor-confirm-modal/types";
import { SoftDeletePayload } from "store/profile/types";
import { isErrorResult } from "services";

const DeleteAccountModal: React.FC = () => {
  const deletionReason = useAppSelector(state => state.profile.deletionReason);
  const pending = useAppSelector(state => state.profile.userAssets.pending);

  const { t } = useTranslation();
  const { deactivate } = useActivationState();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    dispatch(setDeletionReason(""));
    dispatch(hideModal());
  };

  const deleteSuccessHandler = async () => {
    await deactivate();
    await resetStore(); // resetStore, in addition to resetting the entire store, also resets our authorization token to 0, so it redirects us to the login page.
    //Using await, we queue the call to resetStore function after navigate to prevent redirects us to the login page
    navigate(`/${ROUTES.deleteCompleted}`, { state: "settings" });
  };

  const callTwoFactorModal = () => {
    const _showModal = showModal as ModalProps<TwoFactorConfirmModalProps<SoftDeletePayload, undefined>>;

    dispatch(
      _showModal({
        modalName: "twoFactorConfirmModal",
        rootId: "settings",
        props: {
          title: t("Security verification"),
          propsForAction: { otps: { emailOtp: "", authenticatorOtp: "" }, deletionReason },
          propsForResend: undefined,

          callbackForAction: softDeleteConfirm,
          callbackForSuccess: deleteSuccessHandler,
          callbackForResend: softDeleteCheck,
        },
      }),
    );
  };

  const deleteAccountClick = () => {
    dispatch(softDeleteCheck()).then(response => {
      if (!isErrorResult(response.payload)) {
        callTwoFactorModal();
      }
    });
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} mb="8px" />

      <DeleteAssetsIcon width="72px" />

      <Text pb="12px" pt="14px" textScale="body2" color="neutral600" textAlign="center">
        {t("Delete account")}
      </Text>

      <Text color="neutral600" textAlign="center" textScale="caption1" pb={{ _: "24px", tablet: "36px" }}>
        {t(
          'This is a final warning. By clicking "Delete account" you will lose access to your Zert account permanently.',
        )}
      </Text>

      <FlexGap gap="14px" flexDirection={{ _: "column-reverse", tablet: "row" }} width="100%">
        <Button
          minWidth={{ _: "100%", tablet: "170px" }}
          onClick={closeModalHandler}
          variant="outline"
          isLoading={pending}
        >
          {t("Cancel")}
        </Button>

        <Button minWidth={{ _: "100%", tablet: "170px" }} onClick={deleteAccountClick} isLoading={pending}>
          {t("Delete Account")}
        </Button>
      </FlexGap>
    </StyledModalWrapper>
  );
};

export default DeleteAccountModal;
