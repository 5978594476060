import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => state.walletsHistory.historyPayload.dateFrom,
    (state: AppState) => state.walletsHistory.historyPayload.dateTo,
    (state: AppState) => state.walletsHistory.historyPayload.type,
    (state: AppState) => state.walletsHistory.historyPayload.chainId,
    (state: AppState) => state.walletsHistory.historyPayload.currencyCode,
  ],
  (dateFrom, dateTo, type, chainId, currencyCode) => ({
    dateFrom,
    dateTo,
    type,
    chainId,
    currencyCode,
  }),
);
