import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral500", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent" stroke={theme.colors[color]}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.90007 15.6468L5.29807 15.8608C6.33007 16.0188 7.06707 16.9428 6.99107 17.9838L6.88807 19.3948C6.85807 19.8058 7.08207 20.1928 7.45307 20.3718L8.48707 20.8698C8.85807 21.0488 9.30107 20.9818 9.60407 20.7028L10.6431 19.7428C11.4091 19.0348 12.5911 19.0348 13.3581 19.7428L14.3971 20.7028C14.7001 20.9828 15.1421 21.0488 15.5141 20.8698L16.5501 20.3708C16.9201 20.1928 17.1431 19.8068 17.1131 19.3968L17.0101 17.9838C16.9341 16.9428 17.6711 16.0188 18.7031 15.8608L20.1011 15.6468C20.5081 15.5848 20.8361 15.2798 20.9281 14.8778L21.1831 13.7598C21.2751 13.3578 21.1121 12.9408 20.7721 12.7088L19.6051 11.9098C18.7441 11.3198 18.4811 10.1678 19.0011 9.26278L19.7061 8.03678C19.9111 7.67978 19.8771 7.23278 19.6201 6.91078L18.9051 6.01378C18.6481 5.69178 18.2201 5.55878 17.8261 5.67978L16.4741 6.09378C15.4751 6.39978 14.4101 5.88678 14.0261 4.91578L13.5081 3.60278C13.3561 3.21878 12.9851 2.96678 12.5721 2.96778L11.4261 2.97078C11.0131 2.97178 10.6431 3.22578 10.4931 3.61078L9.98807 4.90878C9.60807 5.88578 8.53807 6.40278 7.53607 6.09478L6.12807 5.66278C5.73307 5.54078 5.30307 5.67478 5.04607 5.99878L4.33607 6.89678C4.07907 7.22178 4.04807 7.66978 4.25707 8.02678L4.97807 9.25578C5.50907 10.1618 5.24907 11.3248 4.38307 11.9178L3.23007 12.7078C2.89007 12.9408 2.72707 13.3578 2.81907 13.7588L3.07407 14.8768C3.16507 15.2798 3.49307 15.5848 3.90007 15.6468V15.6468Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.916 10.0835C14.974 11.1415 14.974 12.8575 13.916 13.9155C12.858 14.9735 11.142 14.9735 10.084 13.9155C9.02603 12.8575 9.02603 11.1415 10.084 10.0835C11.142 9.02554 12.858 9.02554 13.916 10.0835"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
