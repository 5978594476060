import React from "react";
import { Outlet } from "react-router-dom";

import { useCheckIfBlacklisted } from "hooks";
import { useHandleData } from "./hooks";

import { ROUTES } from "navigation/routes";
import { TABS, tabsIndex } from "configs";

import { EmptyWallets, Loader, StyledContentWrapper, TabPanel } from "components";
import { WalletBreadCrumbs } from "./components";

import { useAppSelector } from "store/store";
import { selectCombinedData } from "./selecrots";

const Wallets: React.FC = () => {
  const { pendingUser, pendingWallets, walletsData, networks } = useAppSelector(selectCombinedData);

  // All redirect logic will happen in next hook
  // Now u can take network and token from params without extra check
  const { network, activeTab, onTabClick, checkIsOnDisabledWalletTab } = useHandleData();

  useCheckIfBlacklisted(checkIsOnDisabledWalletTab);

  // networks, deposit and withdraw must be with loader and empty wallets
  // whitelist & history - without
  const tabsWithLoading = activeTab !== 3 && activeTab !== 4;

  return (
    <StyledContentWrapper id={ROUTES.wallet} height="100%">
      <WalletBreadCrumbs />
      {/* Here usual approach was used with TabPanel because logic is different */}
      {network && (
        <TabPanel
          maxWidth={{ _: "100%", tablet: "580px" }}
          tabs={TABS.wallets}
          activeTab={activeTab ?? tabsIndex.wallets.network}
          onTabClick={onTabClick}
          mb={{ _: "32px", tablet: "40px" }}
          idForTabPanel="walletTabPanel"
        />
      )}

      {(pendingUser || pendingWallets) && tabsWithLoading && !walletsData.length ? (
        <Loader height="100%" />
      ) : !networks && tabsWithLoading ? (
        <EmptyWallets />
      ) : (
        <Outlet context={{ activeTab }} />
      )}
    </StyledContentWrapper>
  );
};

export default Wallets;
