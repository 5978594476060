import invariant from "tiny-invariant";
import { Currency } from "./currency";
import { ChainId } from "../types";
import { NATIVE_ADDRESS } from "configs/web3";
import { NATIVE_ADDRESS_SOLANA } from "configs/solana";

/**
 * A currency is any fungible financial instrument, including Ether, all ERC20 tokens, and other chain-native currencies
 */
export abstract class BaseCurrency {
  /**
   * Returns whether the currency is native to the chain and must be wrapped (e.g. Ether)
   */
  public abstract readonly isNative: boolean;
  /**
   * Returns whether the currency is a token that is usable in Uniswap without wrapping
   */
  public abstract readonly isToken: boolean;

  /**
   * The chain ID on which this currency resides
   */
  public readonly chainId: ChainId;
  /**
   * The decimals used in representing currency amounts
   */
  public readonly decimals: number;
  /**
   * The symbol of the currency, i.e. a short textual non-unique identifier
   */
  public readonly symbol: string;
  /**
   * The name of the currency, i.e. a descriptive textual non-unique identifier
   */
  public readonly name: string;
  /**
   * The code of the currency to which network it exist
   */
  public readonly publicCode: string;
  /**
   * The contract address of the currency
   */
  public readonly address: string;
  /**
   * The logo of the currency
   */
  public readonly logoURI: string;

  /**
   * Constructs an instance of the base class `BaseCurrency`.
   * @param chainId the chain ID on which this currency resides
   * @param decimals decimals of the currency
   * @param symbol symbol of the currency
   * @param name  name of the currency
   * @param publicCode code of the currency
   * @param logoURI code of the currency
   */
  protected constructor(
    chainId: ChainId,
    decimals: number,
    symbol: string,
    name: string,
    publicCode: string,
    address: string,
    logoURI?: string,
  ) {
    invariant(decimals >= 0 && decimals < 255 && Number.isInteger(decimals), "DECIMALS");

    this.chainId = chainId;
    this.decimals = decimals;
    this.symbol = symbol;
    this.name = name;
    this.publicCode = publicCode;
    this.address = address;

    if (logoURI) {
      if (address === NATIVE_ADDRESS || address === NATIVE_ADDRESS_SOLANA) {
        this.logoURI = logoURI;
      } else {
        const logoDomain =
          window.location.hostname === "localhost"
            ? process.env.REACT_APP_API_PROXY_TOKEN_LOGO_URL
            : process.env.REACT_APP_API_URL;

        this.logoURI = `${logoDomain}${logoURI}`;
      }
    } else {
      this.logoURI = "";
    }
  }

  /**
   * Returns whether this currency is functionally equivalent to the other currency
   * @param other the other currency
   */
  public abstract equals(other: Currency): boolean;

  /**
   * Return the wrapped version of this currency that can be used with the contracts. Currencies must
   * implement this to be used
   */

  // TODO: check if we need this func

  //   public abstract get wrapped(): Token;
}
