import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { FlexGap, StyledContentWrapper } from "components";
import { LeftPart, RightPart } from "./components";

import { useAppDispatch } from "store/store";
import { getEscrowPreview } from "store/escrow/widget/actions";

import { LOCAL_STORAGE_KEYS } from "configs";
import { ROUTES } from "navigation/routes";

const EscrowPreviewPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { escrowPreviewId } = useParams();

  useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.widgetId)) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.widgetId);
    }

    if (escrowPreviewId) {
      dispatch(getEscrowPreview(escrowPreviewId));
    }
  }, []);

  return (
    <FlexGap gap="24px" flexGrow="1" flexDirection={{ _: "column", laptop: "row" }} id={ROUTES.escrowPreview}>
      <StyledContentWrapper width={{ _: "100%", laptop: "calc(100% - 424px)" }} minWidth="unset" minHeight="unset">
        <LeftPart />
      </StyledContentWrapper>

      <StyledContentWrapper
        minWidth={{ _: "unset", laptop: "400px" }}
        maxWidth={{ _: "unset", laptop: "456px" }}
        width="100%"
      >
        <RightPart />
      </StyledContentWrapper>
    </FlexGap>
  );
};

export default EscrowPreviewPage;
