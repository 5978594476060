import React from "react";

import { useThemeContext } from "context";
import { useAppSelector } from "store/store";

import { Column, FlexGap, Image } from "components";
import { MyNetworks } from "./components";
import { TotalBalance } from "../../components";

export const hiddenBalance = "**********";

const BalancePreview: React.FC = () => {
  const totalBalanceFiat = useAppSelector(state => state.hotWallets.wallets.totalBalanceFiat);

  const { theme } = useThemeContext();

  return (
    <Column flex="1">
      <FlexGap
        gap="16px"
        justifyContent="space-between"
        flexDirection={{ _: "column", tablet: "row" }}
        flexWrap="wrap"
        borderBottom="1px solid"
        borderBottomColor="neutral200"
        alignItems={{ _: "flex-start", tablet: "center" }}
      >
        <TotalBalance balance={totalBalanceFiat} />

        <Image
          src={
            theme.isDark
              ? "/images/coin-cover/coin-cover-logo-dark.webp"
              : "/images/coin-cover/coin-cover-logo-light.webp"
          }
          width={{ _: "280px", laptop: "307px" }}
          aspectRatio={3.49}
          marginBottom="12px"
        />
      </FlexGap>
      <MyNetworks />
    </Column>
  );
};

export default BalancePreview;
