import { useCallback } from "react";
import { Socket } from "socket.io-client";

import { useAppDispatch } from "store/store";

import { SOCKET_EVENT_NAMES } from "../socket-event-names";

import { setStatusToMessage, updateMessagesList } from "store/messages";

import { SendMessagePayload } from "store/messages/types";

export const useEmitSocketEventHandlers = (socket: Socket) => {
  const dispatch = useAppDispatch();

  const sendMessage = useCallback((data: SendMessagePayload, reference: string) => {
    const { currentMessageId } = data;

    socket.emit(SOCKET_EVENT_NAMES.send_message, data);

    dispatch(updateMessagesList({ reference, newMessage: data }));
    dispatch(setStatusToMessage({ chatReference: reference, currentMessageId, pending: true }));
  }, []);

  const readMessage = useCallback((reference: string, messageId: number) => {
    socket.emit(SOCKET_EVENT_NAMES.read_message, { chatReference: reference, messageId });

    dispatch(
      setStatusToMessage({
        chatReference: reference,
        isDeliveredMessageSuccessfully: true,
      }),
    );
  }, []);

  return { sendMessage, readMessage };
};
