import { createAsyncThunk } from "@reduxjs/toolkit";

import { isErrorResult, makeApiRequest } from "services";
import { ENDPOINTS_SWAP } from "services/endpoints";

import { ErrorResult } from "services/types";
import {
  HistoryResponse,
  HistoryPayload,
  SwapPayload,
  SwapResponse,
  GetOffersPayload,
  LifiRouteItem,
  HealthStatusResponse,
} from "./types";

export const makeSwap = createAsyncThunk<SwapResponse, SwapPayload, { rejectValue: ErrorResult }>(
  "swap/swap",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<SwapResponse>({
      method: "POST",
      url: ENDPOINTS_SWAP.swaps,
      data,
      timeout: 30_000,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getOffers = createAsyncThunk<{ routes: LifiRouteItem[] }, GetOffersPayload, { rejectValue: ErrorResult }>(
  "swap/get-offers",
  async ({ action, offersPayload }, { rejectWithValue, dispatch }) => {
    const result = await makeApiRequest<{ routes: LifiRouteItem[] }>({
      method: "POST",
      url: ENDPOINTS_SWAP.getOffers,
      data: offersPayload,
      isShowError: false,
      withCredentials: false,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    } else {
      dispatch(action(result.routes));
    }

    return result;
  },
);

export const getHistoryList = createAsyncThunk<HistoryResponse, HistoryPayload, { rejectValue: ErrorResult }>(
  "swap/get-history-list",
  async (params, { rejectWithValue }) => {
    const result = await makeApiRequest<HistoryResponse>({
      method: "GET",
      url: ENDPOINTS_SWAP.swaps,
      params,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const checkHealthStatus = createAsyncThunk<HealthStatusResponse, undefined, { rejectValue: ErrorResult }>(
  "swap/check-health-status",
  async (params, { rejectWithValue }) => {
    const result = await makeApiRequest<HealthStatusResponse>({
      method: "GET",
      url: ENDPOINTS_SWAP.healthStatus,
      params,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);
