import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { tabsIndex } from "configs/tabs";

import { getHistoryList, getOffers, makeSwap, checkHealthStatus } from "./actions";

import { HistoryPayload, HistoryResponseSingleItem, LifiRouteItem, SwapDataProps, SwapState } from "./types";
import { BEToken } from "store/wallets/types";
import { ChainId } from "configs/web3";

const initialState: SwapState = {
  pending: false,
  error: null,
  activeTab: tabsIndex.swap.swap,
  from: null,
  to: null,
  swapData: null,
  amount: "",
  offers: {
    pending: false,
    error: null,
    insuranceRoutes: [],
    unInsuranceRoutes: [],
  },
  selectedOffer: null,
  historyPayload: {
    page: 1,
    size: 10,
  },
  historyInfo: {
    pending: false,
    count: 0,
    data: [],
  },
  isInsurance: false,
  slippage: "",
  healthStatus: null,
};

const swapSlice = createSlice({
  name: "swap",
  initialState: {
    ...initialState,
  },
  reducers: {
    resetSwap() {
      return initialState;
    },
    resetOfferList: state => {
      state.offers = initialState.offers;
      state.selectedOffer = null;
    },
    switchedInsurance: (state, action: PayloadAction<boolean>) => {
      state.isInsurance = action.payload;
    },
    setSwapTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
    setInsuranceRoutes: (state, action: PayloadAction<LifiRouteItem[]>) => {
      state.offers.insuranceRoutes = action.payload;
    },
    setUnInsuranceRoutes: (state, action: PayloadAction<LifiRouteItem[]>) => {
      state.offers.unInsuranceRoutes = action.payload;
    },
    setFromData: (state, action: PayloadAction<{ network: ChainId; token?: BEToken } | null>) => {
      state.from = action.payload;
    },
    setToData: (state, action: PayloadAction<{ network: ChainId; token?: BEToken } | null>) => {
      state.to = action.payload;
    },
    setAmount: (state, action: PayloadAction<string>) => {
      state.amount = action.payload;
    },
    setSlippage: (state, action: PayloadAction<string>) => {
      state.slippage = action.payload;
    },
    setSwapData: (state, action: PayloadAction<SwapDataProps | null>) => {
      state.swapData = action.payload;
    },
    setSwapHistoryPayload: (state, action: PayloadAction<Partial<HistoryPayload>>) => {
      state.historyPayload = { ...state.historyPayload, ...action.payload };
    },
    setSelectedOffer: (state, action: PayloadAction<LifiRouteItem | null>) => {
      state.selectedOffer = action.payload;
    },
    changeBetweenSwap: state => {
      const from = state.from;
      const to = state.to;
      state.from = to;
      state.to = from;
      state.amount = initialState.amount;
    },
    updateSwapHistory: (state, action: PayloadAction<HistoryResponseSingleItem>) => {
      const newSwapHistoryData = state.historyInfo.data.map(item => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item;
      });

      state.historyInfo = { ...state.historyInfo, data: newSwapHistoryData };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(makeSwap.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(makeSwap.pending, state => {
        state.pending = true;
      })
      .addCase(makeSwap.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(getOffers.fulfilled, state => {
        state.error = null;
        state.offers.pending = false;
      })
      .addCase(getOffers.pending, state => {
        state.offers.pending = true;
      })
      .addCase(getOffers.rejected, (state, action) => {
        if (action.payload) {
          // on LIFI request error when Amount is to big
          if (action.payload.message === 'Parameter "fromAmount" is invalid (not a stringified number).') {
            state.offers.error = { message: "The amount is too big for swap", isError: true, code: 400 };
          } else if (action.payload.message === "None of the allowed tools can complete this action.") {
            state.offers.error = { message: "The amount is too small for swap", isError: true, code: 400 };
          } else {
            state.offers.error = action.payload;
          }
          state.offers.pending = false;
        }
      })
      .addCase(getHistoryList.fulfilled, (state, action) => {
        state.historyInfo = action.payload;
        state.error = null;
        state.historyInfo.pending = false;
      })
      .addCase(getHistoryList.pending, state => {
        state.historyInfo.pending = true;
      })
      .addCase(getHistoryList.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.historyInfo.pending = false;
        }
      })
      .addCase(checkHealthStatus.fulfilled, (state, action) => {
        state.healthStatus = action.payload;
        state.error = null;
        state.pending = false;
      })
      .addCase(checkHealthStatus.pending, state => {
        state.pending = true;
      })
      .addCase(checkHealthStatus.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      });
  },
});

export const {
  resetSwap,
  resetOfferList,
  setSwapTab,
  setFromData,
  setToData,
  setAmount,
  setSwapData,
  setSwapHistoryPayload,
  setSelectedOffer,
  changeBetweenSwap,
  setInsuranceRoutes,
  setUnInsuranceRoutes,
  switchedInsurance,
  updateSwapHistory,
  setSlippage,
} = swapSlice.actions;

export default swapSlice;
