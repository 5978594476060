import React from "react";

import { useGetEscrowParticipants } from "pages/main/escrow-process/hooks";
import { useTranslation } from "context";

import { Column, FlexGap, Text } from "components";

import { Roles } from "pages/main/create-escrow/types";
import { AccountType } from "store/profile/types";

const Parties: React.FC = () => {
  const { t } = useTranslation();

  const { broker, buyer, seller, userRole } = useGetEscrowParticipants();

  return (
    <Column mb="32px">
      <Text textScale="hairlineS" color="neutral500" textTransform="uppercase" $fontWeight="medium" mb="14px">
        {t("Parties")}
      </Text>

      {broker && (
        <FlexGap gap="4px" justifyContent="space-between" mb="12px">
          <FlexGap gap="4px">
            <Text textScale="caption1" color="neutral800" wordBreak="break-all">
              {broker.firstName} {broker.lastName}
            </Text>

            {userRole === Roles.broker && (
              <Text textScale="caption1" color="neutral500">
                ({t("You")})
              </Text>
            )}
          </FlexGap>

          <Text textScale="caption1" color="neutral800">
            {t("Broker")}
          </Text>
        </FlexGap>
      )}

      {buyer && (
        <FlexGap gap="4px" justifyContent="space-between" mb="12px">
          <FlexGap gap="4px">
            <Text textScale="caption1" color="neutral800" wordBreak="break-all">
              {(broker && userRole === Roles.seller) || buyer.accountType === AccountType.EMPTY
                ? t("Unknown")
                : `${buyer.firstName} ${buyer.lastName}`}
            </Text>

            {userRole === Roles.buyer && (
              <Text textScale="caption1" color="neutral500">
                ({t("You")})
              </Text>
            )}
          </FlexGap>

          <Text textScale="caption1" color="neutral800">
            {t("Buyer")}
          </Text>
        </FlexGap>
      )}

      {seller ? (
        <FlexGap gap="4px" justifyContent="space-between">
          <FlexGap gap="4px">
            <Text textScale="caption1" color="neutral800" wordBreak="break-all">
              {(broker && userRole === Roles.buyer) || seller.accountType === AccountType.EMPTY
                ? t("Unknown")
                : `${seller.firstName} ${seller.lastName}`}
            </Text>

            {userRole === Roles.seller && (
              <Text textScale="caption1" color="neutral500">
                ({t("You")})
              </Text>
            )}
          </FlexGap>

          <Text textScale="caption1" color="neutral800">
            {t("Seller")}
          </Text>
        </FlexGap>
      ) : null}
    </Column>
  );
};

export default Parties;
