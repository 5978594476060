import React, { useEffect } from "react";

import { useGetColdWalletDebtAndFees, useSupport } from "hooks";

import { StyledLink, Text, VaultInfo } from "components";
import { ArrowRightIcon } from "components/svg";
import { DepositSection } from "./components";
import CloseModalWrapper from "../close-modal-wrapper";
import { StyledSideModalWrapper, RotateIconWrapper } from "../styled";

import { resetColdWalletFee } from "store/wallets/wallets-fee";
import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";

import { useTranslation } from "context";

import { ColdStorageDepositModalProps } from "./types";

const ColdStorageDepositModal: React.FC = () => {
  const coldWallet = useAppSelector(state => (state.modal.props as unknown as ColdStorageDepositModalProps).coldWallet);
  const data = useAppSelector(state => state.coldWallets.coldWallets.data);

  const actualColdWallet = data.find(wallet => wallet.id === coldWallet.id);
  const wallet = actualColdWallet ? actualColdWallet : coldWallet;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { supportHandler } = useSupport();

  useEffect(() => {
    dispatch(resetColdWalletFee());
  }, []);

  useGetColdWalletDebtAndFees(wallet.currency.publicCode, wallet.currency.chainId, wallet.availableBalance);

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  return (
    <StyledSideModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} mb="38px">
        <>
          <RotateIconWrapper>
            <ArrowRightIcon width="24px" height="24px" color="neutral600" />
          </RotateIconWrapper>

          <Text color="neutral600" textScale="body2" $fontWeight="medium" px="12px">
            {t("Deposit")}
          </Text>
        </>
      </CloseModalWrapper>

      <VaultInfo wallet={wallet} />

      <DepositSection coldWallet={wallet} />

      <StyledLink
        color="neutral500"
        mt="18px"
        textScale="caption2"
        $fontWeight="medium"
        onClick={() => supportHandler()}
        width="100%"
        justifyContent="center"
      >
        {t("Do you need help?")}
      </StyledLink>
    </StyledSideModalWrapper>
  );
};

export default ColdStorageDepositModal;
