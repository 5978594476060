import { toast } from "react-toastify";

import { useTranslation } from "context";

import { useShow2FaModal } from "../";

import { confirmWithdraw } from "store/wallets/cold-wallets/actions";
import { hideModal, showModal } from "store/modal";
import store, { useAppDispatch, useAppSelector } from "store/store";
import { setIsShowError } from "store/verify-biometric";

import { toastOptionsError } from "components/alerts";
import { isErrorResult } from "services";
import { convertToMaybeBase64, getBiometricErrorMessage } from "utils/helpers";

import { ResponseData } from "types";
import { ModalProps } from "store/modal/types";
import { AutomaticFaceCaptureModalProps } from "components/modal/components/automatic-face-capture-modal/types";

const useHandleMitekData = (stopSDK: () => void) => {
  const withdrawPayload = useAppSelector(state => state.coldWallets.withdrawPayload);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { callSuccessModal, handleVerifyCallback, showErrorModal, showLoadingModal } = useShow2FaModal();

  const checkImageResult = ({ response }: ResponseData) => {
    const isShowErrorModal = store.getState().verifyBiometric.isShowErrorModal;
    if (isShowErrorModal) {
      if (response.status === "success") {
        showLoadingModal();
        const imageData = convertToMaybeBase64(response.imageData);
        const verifyData = {
          ...withdrawPayload!.withdrawPayload,
          verificationData: { selfieImage: { data: imageData } },
        };
        dispatch(confirmWithdraw({ mitek: verifyData })).then(res => {
          if (isErrorResult(res.payload)) {
            dispatch(hideModal());
            toast.error(getBiometricErrorMessage(res.payload.message), toastOptionsError);
          } else {
            callSuccessModal();
          }
        });
      } else {
        handleErrorModal(response.warnings.key);
      }
    }
  };

  const handleCloseVerifyModal = () => {
    dispatch(setIsShowError(false));
    stopSDK();
    dispatch(hideModal());
  };

  const handleErrorModal = (key?: string) => {
    showErrorModal(key, { callback: () => handleVerifyCallback(stopSDK), title: t("Verify with codes") });
  };

  const showVerifyCaptureModal = () => {
    const _showModal = showModal as ModalProps<AutomaticFaceCaptureModalProps>;
    dispatch(
      _showModal({
        modalName: "automaticFaceCaptureModal",
        modalType: "fullScreenOnMobile",
        rootId: "cold-storage",
        clickOutsideHandler: handleCloseVerifyModal,
        props: {
          isCameraLoaded: false,
          isShowHintMessage: false,
          title: t("Verify with face biometric"),
          verifyDescription: t("Have troubles with the verification?"),
          verifyCallback: () => handleVerifyCallback(stopSDK),
          closeCallback: handleCloseVerifyModal,
        },
      }),
    );
  };

  return {
    handleErrorModal,
    showVerifyCaptureModal,
    checkImageResult,
  };
};

export default useHandleMitekData;
