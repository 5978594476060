import { createAsyncThunk } from "@reduxjs/toolkit";

import { isErrorResult, makeApiRequest } from "services";
import { ENDPOINTS_STATEMENT } from "services";

import { ErrorResult } from "services/types";
import { StatementsListPayload, StatementsListResponse } from "./types";

export const getAllStatements = createAsyncThunk<
  StatementsListResponse,
  StatementsListPayload,
  { rejectValue: ErrorResult }
>("statements/get-all-statements", async (_, { rejectWithValue }) => {
  const result = await makeApiRequest<StatementsListResponse>({
    method: "GET",
    url: ENDPOINTS_STATEMENT.statements,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});
