import axios, { isAxiosError } from "axios";
import * as Sentry from "@sentry/react";

import { isErrorResult } from "services";
// Store
import store from "store/store";
import { resetProfile } from "store/profile";
import { resetKYC } from "store/kyc";
import { resetAuth } from "store/auth";
import { resetBuyCrypto } from "store/buy-crypto";
import { resetSwap } from "store/swap";
import { resetWalletsFee } from "store/wallets/wallets-fee";
import { resetWalletsHistory } from "store/wallets/wallets-history";
import { resetWhitelist } from "store/wallets/whitelist";
import { resetColdWallets } from "store/wallets/cold-wallets";
import { resetHotWallets } from "store/wallets/hot-wallets";
import { resetEscrowsTable } from "store/escrow/escrows-table";
import { resetAddresses } from "store/addresses";
import { hideModal } from "store/modal";
import { resetVerifyBiometric } from "store/verify-biometric";
import { resetTransactionsState } from "store/transactions/actions";
import { finishSession } from "store/auth/actions";
import { resetCreateEscrow } from "store/escrow/create-escrow";
import { resetEscrowProcess } from "store/escrow/escrow-process";
import { resetMessages } from "store/messages";
import { resetYubikey } from "store/yubikey";
import { resetWidget } from "store/escrow/widget";
import { resetWeb3WalletState } from "store/web3-wallet";
import { resetCreateStatementsState } from "store/statements/create-statement";
import { resetStatementsState } from "store/statements/statements-list";
import { resetStatementInfoState } from "store/statements/statement-info";
import { resetDashboard } from "store/dashboard";
import { resetNotifications } from "store/notifications";

let isRefreshing = false;

let tokenUpdateTimestamp = 0;

export const timeout = 15_000;

export function resetStore(withFinishSession = true) {
  if (withFinishSession) {
    store.dispatch(finishSession());
  }
  store.dispatch(resetAuth());
  store.dispatch(resetProfile());
  store.dispatch(hideModal());
  store.dispatch(resetKYC());
  store.dispatch(resetCreateStatementsState());
  store.dispatch(resetSwap());
  store.dispatch(resetColdWallets());
  store.dispatch(resetHotWallets());
  store.dispatch(resetWalletsFee());
  store.dispatch(resetVerifyBiometric());
  store.dispatch(resetWalletsHistory());
  store.dispatch(resetWhitelist());
  store.dispatch(resetBuyCrypto());
  store.dispatch(resetEscrowsTable());
  store.dispatch(resetAddresses());
  store.dispatch(resetCreateEscrow());
  store.dispatch(resetEscrowProcess());
  store.dispatch(resetMessages());
  store.dispatch(resetYubikey());
  store.dispatch(resetWidget());
  store.dispatch(resetStatementsState());
  store.dispatch(resetWeb3WalletState());
  store.dispatch(resetStatementInfoState());
  store.dispatch(resetDashboard());
  store.dispatch(resetNotifications());
  resetTransactionsState({ chainId: undefined });
  Sentry.setUser(null);
}

export function getInstance(baseURL = process.env.REACT_APP_API_URL) {
  const instance = axios.create({
    withCredentials: true,
    baseURL,
    timeout,
  });

  instance.interceptors.request.use(
    config => {
      return config;
    },
    error => Promise.reject(error),
  );

  instance.interceptors.response.use(
    success => success,
    error => {
      if (isAxiosError(error) && error.response && error.config) {
        const {
          response: { status },
        } = error;

        if (status === 401) {
          const nowTimeStamp = new Date().getTime();

          if (tokenUpdateTimestamp === 0 || nowTimeStamp - tokenUpdateTimestamp > timeout) {
            if (!isRefreshing) {
              isRefreshing = true;
              tokenUpdateTimestamp = new Date().getTime();

              store.dispatch(finishSession()).then(response => {
                if (isErrorResult(response.payload)) {
                  isRefreshing = false;
                } else {
                  tokenUpdateTimestamp = new Date().getTime();
                  isRefreshing = false;
                }
                resetStore(false);
              });
            }
          }
        }
      }

      return Promise.reject(error);
    },
  );

  return instance;
}
