import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 72 72" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_1906_68559)">
        <rect x="18" y="49" width="36" height="12" rx="4" fill={theme.colors.primary} />
      </g>
      <circle cx="36" cy="36" r="28" fill={theme.colors.primaryLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.primary} strokeOpacity="0.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.75 49.5H26.25C23.3505 49.5 21 47.1495 21 44.25C21 41.3505 23.3505 39 26.25 39H45.75C48.6495 39 51 41.3505 51 44.25C51 47.1495 48.6495 49.5 45.75 49.5Z"
        fill={theme.colors.primary}
        fillOpacity="0.14"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.366 44.2485C45.366 44.046 45.2025 43.8825 45 43.884C44.7975 43.884 44.634 44.0475 44.634 44.25C44.634 44.4525 44.7975 44.616 45 44.616C45.2025 44.616 45.366 44.4525 45.366 44.2485"
        stroke={theme.colors.primary}
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.366 44.2485C39.366 44.046 39.2025 43.8825 39 43.884C38.7975 43.884 38.634 44.0475 38.634 44.25C38.634 44.4525 38.7975 44.616 39 44.616C39.201 44.616 39.366 44.4525 39.366 44.2485"
        stroke={theme.colors.primary}
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.366 44.2485C33.366 44.046 33.2025 43.8825 33 43.884C32.7975 43.884 32.634 44.0475 32.634 44.25C32.634 44.4525 32.7975 44.616 33 44.616C33.201 44.616 33.366 44.4525 33.366 44.2485"
        stroke={theme.colors.primary}
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.366 44.2485C27.366 44.046 27.2025 43.8825 27 43.884C26.7975 43.884 26.634 44.0475 26.634 44.25C26.634 44.4525 26.7975 44.616 27 44.616C27.201 44.616 27.366 44.4525 27.366 44.2485"
        stroke={theme.colors.primary}
        strokeWidth="1.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.5 30.8552V26.9492"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.5 30.906V27"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.181 23.8185C34.939 25.5765 34.939 28.425 33.181 30.183C31.423 31.941 28.5745 31.941 26.8165 30.183C25.0585 28.425 25.0585 25.5765 26.8165 23.8185C28.5745 22.0605 31.4245 22.0605 33.181 23.8185"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.5 26.9518L34.5 27.0013"
        stroke={theme.colors.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.primary} strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_1292_55585"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_1292_55585" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
