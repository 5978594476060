import * as yup from "yup";

import { useTranslation } from "context";
import { InitialValuesType } from "./types";
import { useValidationTests } from "./use-validation-test";

export const initialValues: InitialValuesType = {
  emailOtp: "",
  authenticatorOtp: "",
};

export const MIN_LENGTH = 6;

const useValidationSchema = () => {
  const { t } = useTranslation();

  const { authenticatorOtpIsRequired } = useValidationTests();

  const validationSchema = yup.object().shape({
    emailOtp: yup
      .string()
      .required(t("Please enter the code"))
      .min(MIN_LENGTH, t("Code length must contain %amount% digits", { amount: MIN_LENGTH })),
    authenticatorOtp: yup
      .string()
      .min(MIN_LENGTH, t("Code length must contain %amount% digits", { amount: MIN_LENGTH }))
      .test("isRequired", t("Please enter the code"), authenticatorOtpIsRequired),
  });

  return { validationSchema, initialValues };
};

export default useValidationSchema;
