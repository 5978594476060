import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { StatementsListPayload, StatementsListState } from "./types";

import { getAllStatements } from "./actions";

const initialState: StatementsListState = {
  error: null,
  statementsPayload: {
    page: 1,
    size: 10,
  },
  statements: {
    pending: false,
    data: [],
    count: 0,
  },
};

const statementsListSlice = createSlice({
  name: "statementsList",
  initialState: {
    ...initialState,
  },
  reducers: {
    resetStatementsState: () => {
      return initialState;
    },
    setStatementsPayload: (state, action: PayloadAction<Partial<StatementsListPayload>>) => {
      state.statementsPayload = { ...state.statementsPayload, ...action.payload };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAllStatements.fulfilled, (state, action) => {
        state.statements = { ...action.payload, pending: false };
        state.error = null;
      })
      .addCase(getAllStatements.pending, state => {
        state.statements.pending = true;
      })
      .addCase(getAllStatements.rejected, (state, action) => {
        if (action.payload) {
          state.statements = initialState.statements;
          state.error = action.payload;
        }
      });
  },
});

export const { resetStatementsState, setStatementsPayload } = statementsListSlice.actions;

export default statementsListSlice;
