import { useEffect, useState } from "react";
import { SingleValue } from "react-select";

import { useGetFilteredOptions, useHandleInputDebounce } from "hooks";
import { useAppDispatch, useAppSelector } from "store/store";
import { useTranslation } from "context";

import { escrowStatusList } from "pages/main/escrows/const";

import { setWidgetsBuyersListPayload } from "store/escrow/escrows-table";

import { SingleOption } from "components/selects/single-select/types";
import { EscrowStatusEnum } from "store/escrow/enums";

export const useHandleFilters = () => {
  const status = useAppSelector(state => state.escrowsTable.widgetBuyersListPayload.status);
  const search = useAppSelector(state => state.escrowsTable.widgetBuyersListPayload.search);

  const { t } = useTranslation();
  const { options } = useGetFilteredOptions({
    statusList: escrowStatusList,
  });
  const dispatch = useAppDispatch();

  const { statusOptions } = options;

  const statusRadioOption = statusOptions.map(item => {
    const { value, label } = item;
    return {
      value,
      label,
      name: label ?? t("All Statuses"),
    };
  });

  const presetStatusOption = statusOptions.find(el => el.value === status) ?? statusOptions[0];

  const [selectedStatus, setSelectedStatus] = useState(presetStatusOption);

  const { value, handleInputChange, clearValue } = useHandleInputDebounce(search ?? "", debounceCallback);

  useEffect(() => {
    if (!status) {
      setSelectedStatus(statusOptions[0]);
    }
  }, [status]);

  function debounceCallback(val: string) {
    dispatch(setWidgetsBuyersListPayload({ page: 1, search: val }));
  }

  const handleSelectedStatus = (selectedOption: SingleValue<SingleOption>) => {
    if (selectedOption) {
      setSelectedStatus({ value: selectedOption.value, label: selectedOption.label });
    }
  };

  const applyHandler = (dates: { dateFrom: string | undefined; dateTo: string | undefined }) => {
    dispatch(
      setWidgetsBuyersListPayload({
        page: 1,
        status: selectedStatus.value ? (selectedStatus.value as EscrowStatusEnum) : undefined,
        dateFrom: dates.dateFrom ? dates.dateFrom : undefined,
        dateTo: dates.dateTo ? dates.dateTo : undefined,
      }),
    );
  };

  const selectsList = [
    {
      option: statusRadioOption,
      handler: handleSelectedStatus,
      value: selectedStatus,
      name: "Status",
    },
  ];

  return {
    selectsList,
    handleInputChange,
    searchValue: value,
    applyHandler,
    clearValue,
  };
};
