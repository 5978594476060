import styled from "styled-components";
import { space } from "styled-system";

import { FlexGap, Text } from "components";

export const StyledTableWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`;

export const Table = styled.table<{ isSideModal?: boolean }>`
  table-layout: fixed;

  max-width: calc(100% + ${({ isSideModal }) => (isSideModal ? "72px" : "48px")});
  width: calc(100% + ${({ isSideModal }) => (isSideModal ? "72px" : "48px")});
  margin: 0 ${({ isSideModal }) => (isSideModal ? "-36px" : "-24px")};

  ${space}
`;

export const StyledCardWrapper = styled(FlexGap)`
  flex-direction: column;
  padding-bottom: 24px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral200};
  padding-top: 24px;
  &:last-child {
    border-bottom: none;
  }

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 18px 20px;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.neutral200};
    width: calc(50% - 8px);
    &:last-child {
      border: 1px solid ${({ theme }) => theme.colors.neutral200};
    }
  }
`;

export const StyledCardRowBetween = styled(FlexGap)`
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const StyledCardTitle = styled(Text).attrs({
  textScale: "caption2",
  whiteSpace: "nowrap",
  color: "neutral500",
  $fontWeight: "medium",
})``;
