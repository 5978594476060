import styled from "styled-components";
import { space, typography, layout, opacity, border, shadow, variant } from "styled-system";
import { scaleVariants, variantStyles } from "./theme";
import Text from "components/text";

import { ButtonProps } from "./types";

const StyledButton = styled.button<ButtonProps>`
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  height: 44px;
  padding: 14px 28px;
  position: relative;
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-family: inherit;
  justify-content: center;
  line-height: 16px;
  outline: 0;
  font-size: 14px;
  transition: all 0.3s ease;
  width: fit-content;
  border: 0;
  gap: 8px;

  &:focus {
    outline: unset;
  }

  ${variant({
    prop: "scale",
    variants: scaleVariants,
  })};

  ${({ theme, variant, color }) => variantStyles(theme, variant, color)};

  ${Text} {
    color: inherit;
    font-weight: inherit;
  }

  ${space}
  ${typography}
  ${layout}
  ${opacity}
  ${border}
  ${shadow}
`;

export default StyledButton;
