import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { confirmTwoFaAuth, loginUser, loginUserApple, loginUserGoogle } from "store/auth/actions";
import {
  generate2FASecret,
  getMyProfile,
  verify2FASecret,
  changeAvatar,
  deleteAvatar,
  resetAuthenticatorApp,
  confirmResetAuthenticatorApp,
  softDeleteValidation,
  confirmHighRiskResetAuthenticatorApp,
  highRiskResetAuthenticatorApp,
  softDeleteCheck,
  softDeleteConfirm,
} from "./actions";
import { ProfileResponse, ProfileState } from "./types.js";
import { updateSecuritySettings, updateSystemSettings } from "./settings/actions";
import { yubikeyLogin } from "store/yubikey/actions";

const initialState: ProfileState = {
  error: null,
  pending: false,
  user: null,
  twoFactorAuth: {
    pendingVerify: false,
    pendingGenerate: false,
    pendingReset: false,
    secret: null,
    error: null,
  },
  isBlacklisted: false,
  userAssets: {
    pending: false,
    wallets: null,
  },
  deletionReason: "",
};

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    ...initialState,
  },
  reducers: {
    updateUser: (state, action: PayloadAction<ProfileResponse>) => {
      state.user = action.payload;
    },
    setDeletionReason: (state, action: PayloadAction<string>) => {
      state.deletionReason = action.payload;
    },
    resetProfile: () => {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getMyProfile.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isBlacklisted = action.payload.isUnderAml;
        state.error = null;
        state.pending = false;
      })
      .addCase(getMyProfile.pending, state => {
        state.pending = true;
      })
      .addCase(getMyProfile.rejected, (state, action) => {
        if (action.payload) {
          state.pending = false;
          state.error = action.payload;
          state.user = null;
          state.isBlacklisted = false;
        }
      })
      .addCase(loginUser.pending, state => {
        state.pending = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        const { user } = action.payload;
        state.user = user;
        state.isBlacklisted = user.isUnderAml;
        state.error = null;
        state.pending = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        if (action.payload) {
          state.pending = false;
          state.error = action.payload;
          state.user = null;
          state.isBlacklisted = false;
        }
      })
      // Login Google
      .addCase(loginUserGoogle.fulfilled, (state, action) => {
        const { user } = action.payload;

        state.user = user;
        state.isBlacklisted = user.isUnderAml;
        state.error = null;
        state.pending = false;
      })
      .addCase(loginUserGoogle.pending, state => {
        state.pending = true;
      })
      .addCase(loginUserGoogle.rejected, (state, action) => {
        if (action.payload) {
          state.pending = false;
          state.error = action.payload;
          state.user = null;
          state.isBlacklisted = false;
        }
      })
      // Login Apple
      .addCase(loginUserApple.fulfilled, (state, action) => {
        const { user } = action.payload;

        state.user = user;
        state.isBlacklisted = user.isUnderAml;
        state.error = null;
        state.pending = false;
      })
      .addCase(loginUserApple.pending, state => {
        state.pending = true;
      })
      .addCase(loginUserApple.rejected, (state, action) => {
        if (action.payload) {
          state.pending = false;
          state.error = action.payload;
          state.user = null;
          state.isBlacklisted = false;
        }
      })
      .addCase(generate2FASecret.fulfilled, (state, action) => {
        state.twoFactorAuth = { ...initialState.twoFactorAuth, secret: action.payload };
      })
      .addCase(generate2FASecret.pending, state => {
        state.twoFactorAuth.pendingGenerate = true;
      })
      .addCase(generate2FASecret.rejected, (state, action) => {
        if (action.payload) {
          state.twoFactorAuth = { ...state.twoFactorAuth, pendingGenerate: false, error: action.payload };
        }
      })
      .addCase(verify2FASecret.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isBlacklisted = action.payload.isUnderAml;
        state.twoFactorAuth = { ...initialState.twoFactorAuth };
      })
      .addCase(verify2FASecret.pending, state => {
        state.twoFactorAuth.pendingVerify = true;
      })
      .addCase(verify2FASecret.rejected, (state, action) => {
        if (action.payload) {
          state.twoFactorAuth = { ...state.twoFactorAuth, pendingVerify: false, error: action.payload };
          state.isBlacklisted = false;
        }
      })
      .addCase(confirmTwoFaAuth.fulfilled, (state, action) => {
        state.error = null;
        state.pending = false;
        state.user = action.payload;
      })
      .addCase(confirmTwoFaAuth.pending, state => {
        state.pending = true;
      })
      .addCase(confirmTwoFaAuth.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(changeAvatar.fulfilled, (state, action) => {
        state.error = null;
        state.pending = false;
        state.user = action.payload;
      })
      .addCase(changeAvatar.pending, state => {
        state.pending = true;
      })
      .addCase(changeAvatar.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(deleteAvatar.fulfilled, (state, action) => {
        state.error = null;
        state.pending = false;
        state.user = action.payload;
      })
      .addCase(deleteAvatar.pending, state => {
        state.pending = true;
      })
      .addCase(deleteAvatar.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(updateSecuritySettings.fulfilled, (state, action) => {
        state.error = null;
        state.pending = false;
        state.user = action.payload;
      })
      .addCase(updateSecuritySettings.pending, state => {
        state.pending = true;
      })
      .addCase(updateSecuritySettings.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
          state.user = null;
        }
      })
      .addCase(updateSystemSettings.fulfilled, (state, action) => {
        state.error = null;
        state.pending = false;
        state.user = action.payload;
      })
      .addCase(updateSystemSettings.pending, state => {
        state.pending = true;
      })
      .addCase(updateSystemSettings.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
          state.user = null;
        }
      })
      // Reset authenticator app from settings
      .addCase(resetAuthenticatorApp.fulfilled, state => {
        state.error = null;
        state.twoFactorAuth.pendingReset = false;
      })
      .addCase(resetAuthenticatorApp.pending, state => {
        state.twoFactorAuth.pendingReset = true;
      })
      .addCase(resetAuthenticatorApp.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.twoFactorAuth.pendingReset = false;
        }
      })
      // High risk reset authenticator app
      .addCase(highRiskResetAuthenticatorApp.fulfilled, state => {
        state.error = null;
        state.twoFactorAuth.pendingReset = false;
      })
      .addCase(highRiskResetAuthenticatorApp.pending, state => {
        state.twoFactorAuth.pendingReset = true;
      })
      .addCase(highRiskResetAuthenticatorApp.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.twoFactorAuth.pendingReset = false;
        }
      })
      // confirm reset authenticator app from settings
      .addCase(confirmResetAuthenticatorApp.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(confirmResetAuthenticatorApp.pending, state => {
        state.pending = true;
      })
      .addCase(confirmResetAuthenticatorApp.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      // confirm reset authenticator for high risk
      .addCase(confirmHighRiskResetAuthenticatorApp.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(confirmHighRiskResetAuthenticatorApp.pending, state => {
        state.pending = true;
      })
      .addCase(confirmHighRiskResetAuthenticatorApp.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(yubikeyLogin.fulfilled, (state, action) => {
        const { user } = action.payload;

        state.user = user;
        state.error = null;
        state.pending = false;
      })
      .addCase(yubikeyLogin.pending, state => {
        state.pending = true;
      })
      .addCase(yubikeyLogin.rejected, (state, action) => {
        if (action.payload) {
          state.user = initialState.user;
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(softDeleteValidation.fulfilled, (state, action) => {
        const { wallets } = action.payload;

        state.userAssets = { wallets, pending: false };
        state.error = null;
      })
      .addCase(softDeleteValidation.pending, state => {
        state.userAssets.pending = true;
      })
      .addCase(softDeleteValidation.rejected, (state, action) => {
        if (action.payload) {
          state.userAssets = initialState.userAssets;
          state.error = action.payload;
        }
      })
      .addCase(softDeleteCheck.fulfilled, state => {
        state.error = null;
        state.userAssets.pending = false;
      })
      .addCase(softDeleteCheck.pending, state => {
        state.userAssets.pending = true;
      })
      .addCase(softDeleteCheck.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.userAssets.pending = false;
        }
      })
      .addCase(softDeleteConfirm.fulfilled, state => {
        state.error = null;
        state.userAssets.pending = false;
      })
      .addCase(softDeleteConfirm.pending, state => {
        state.userAssets.pending = true;
      })
      .addCase(softDeleteConfirm.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.userAssets.pending = false;
        }
      });
  },
});

export const { updateUser, resetProfile, setDeletionReason } = profileSlice.actions;
export default profileSlice;
