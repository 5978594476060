import { ActionModalProps } from "components/modal/components/action-modal/types";
import { ModalDeleteIcon } from "components/svg";

import { useTranslation } from "context";
import { isErrorResult } from "services";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal, partialUpdateModalProps, showModal } from "store/modal";
import { deleteWhitelistItem, getWhitelist } from "store/wallets/whitelist/actions";

import { ModalProps, PartialUpdateModalProps } from "store/modal/types";
import { selectCombinedDataForUseDeleteItem } from "./selectors";

const useDeleteItem = (id: number) => {
  const { page, size, search, chainId } = useAppSelector(selectCombinedDataForUseDeleteItem);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const onConfirm = () => {
    const _partialProps = partialUpdateModalProps as PartialUpdateModalProps<ActionModalProps>;
    dispatch(_partialProps({ isLoading: true }));

    dispatch(deleteWhitelistItem({ id })).then(res => {
      if (!isErrorResult(res.payload)) {
        // chainId and search undefined due to BE enum not accepting empty
        dispatch(
          getWhitelist({
            page,
            size,
            search: search ? search : undefined,
            chainId: chainId ? chainId : undefined,
          }),
        );
        dispatch(hideModal());
      }
      dispatch(_partialProps({ isLoading: false }));
    });
  };

  const onDelete = () => {
    const _showModal = showModal as ModalProps<ActionModalProps>;
    dispatch(
      _showModal({
        modalName: "actionModal",
        rootId: "whitelist",
        props: {
          icon: <ModalDeleteIcon height="72px" width="72px" />,
          title: t("Remove from whitelist?"),
          text: t("Are you sure you want to delete this address?"),
          btnArr: [
            {
              buttonText: t("Cancel"),
              props: {
                variant: "outline",
              },
              callback: closeModalHandler,
            },
            {
              buttonText: t("Remove"),
              callback: onConfirm,
            },
          ],
        },
      }),
    );
  };

  return { onDelete };
};

export default useDeleteItem;
