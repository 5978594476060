import useSWR from "swr";

import { estimateColdWalletWithdrawFee } from "store/wallets/wallets-fee/actions";
import { useAppDispatch, useAppSelector } from "store/store";

export const useGetColdWalletDebtAndFees = (currencyCode: string, chainId: string, amount: string) => {
  const networks = useAppSelector(state => state.hotWallets.networks);
  const dispatch = useAppDispatch();

  const { mutate } = useSWR(
    currencyCode && chainId && amount && networks && networks[chainId as keyof typeof networks][currencyCode]
      ? `${currencyCode}/${chainId}/${amount}`
      : null,
    () => {
      dispatch(estimateColdWalletWithdrawFee({ currencyCode, chainId, amount }));
    },
    { revalidateOnFocus: false },
  );

  return { refresh: mutate };
};
