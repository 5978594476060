import React from "react";

import { useHandleData, useValidationSchema } from "./hooks";
import { useForm } from "hooks";
import { useTranslation } from "context";

import {
  Text,
  Column,
  FlexGap,
  Input,
  InputGroup,
  Textarea,
  Switcher,
  Flex,
  Button,
  NetworkAndToken,
} from "components";
import CloseModalWrapper from "../close-modal-wrapper";
import { Amounts, Tabs } from "./components";
import { EscrowDetailsIcon } from "components/svg";
import { StyledSideModalWrapper } from "../styled";
import { StyledAddressesForm } from "./styled";

import { useAppSelector } from "store/store";

import { DESCRIPTION_LENGTH } from "pages/main/create-escrow/constants";

const EscrowDetailsModifySideModal: React.FC = () => {
  const currentEscrow = useAppSelector(state => state.escrowProcess.currentEscrow);
  const pending = useAppSelector(state => state.escrowProcess.pending);

  const { t } = useTranslation();

  const { validationSchema, initialValues } = useValidationSchema();
  const { values, errors, touched, isValid, fieldProps, setFieldValue, setFieldTouched, handleSubmit } = useForm({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit() {
      saveHandler();
    },
  });

  const { closeModalHandler, onTabClick, handleSwitchIsShipped, saveHandler } = useHandleData({
    setFieldValue,
    setFieldTouched,
    values,
  });

  return (
    <StyledSideModalWrapper>
      {currentEscrow && (
        <Column width="100%" height="max-content" flexGrow="1">
          <CloseModalWrapper closeModalHandler={closeModalHandler} mb="56px">
            <FlexGap gap="12px">
              <EscrowDetailsIcon width="24px" height="24px" />

              <Text textScale="body2" color="neutral600" $fontWeight="medium">
                {t("Escrow Details")}
              </Text>
            </FlexGap>
          </CloseModalWrapper>

          <StyledAddressesForm onSubmit={handleSubmit}>
            <InputGroup label={t("Escrow Name *")} error={errors.name} isTouched={touched.name}>
              <Input placeholder={t("Give escrow a name")} {...fieldProps("name")} />
            </InputGroup>

            <InputGroup
              label={t("Item description")}
              error={errors.description}
              isTouched={touched?.description}
              endLabelText={`${values.description.length}/${DESCRIPTION_LENGTH}`}
            >
              <Textarea placeholder={t("Enter description text")} {...fieldProps("description")} />
            </InputGroup>

            <InputGroup label={t("Website URL")} error={errors.website} isTouched={touched?.website}>
              <Input placeholder={t("https://")} {...fieldProps("website")} />
            </InputGroup>

            <NetworkAndToken />

            <Amounts
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
            />

            <Tabs onTabClick={onTabClick} />

            <Flex justifyContent="space-between" mb="24px" alignItems="baseline">
              <Text textScale="caption1" color="neutral600">
                {t("Is this a physical item that needs to be shipped?")}
              </Text>

              <Switcher checked={values.isShipped} onChange={handleSwitchIsShipped} />
            </Flex>

            <FlexGap gap="14px">
              <Button onClick={closeModalHandler} variant="outline" width="100%" isLoading={pending}>
                {t("Cancel")}
              </Button>

              <Button type="submit" width="100%" isLoading={pending} disabled={!isValid}>
                {t("Save")}
              </Button>
            </FlexGap>
          </StyledAddressesForm>
        </Column>
      )}
    </StyledSideModalWrapper>
  );
};

export default EscrowDetailsModifySideModal;
