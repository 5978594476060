import React from "react";

import { useThemeContext } from "context";

import { Svg } from "components";

import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 72 84" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_12883_115380)">
        <rect x="18" y="49" width="36" height="17" rx="4" fill={theme.colors.yellow} />
      </g>
      <circle cx="36" cy="36" r="28" fill={theme.colors.yellowLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.yellow} strokeOpacity="0.5" />
      <path
        d="M36 37.6803V32.0703"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.9985 42.1875C35.7915 42.1875 35.6235 42.3555 35.625 42.5625C35.625 42.7695 35.793 42.9375 36 42.9375C36.207 42.9375 36.375 42.7695 36.375 42.5625C36.375 42.3555 36.207 42.1875 35.9985 42.1875"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.0431 24.2088L50.5316 44.3148C51.8666 46.6518 50.1791 49.5603 47.4881 49.5603H24.5111C21.8186 49.5603 20.1311 46.6518 21.4676 44.3148L32.9561 24.2088C34.3016 21.8523 37.6976 21.8523 39.0431 24.2088Z"
        fill={theme.colors.yellow}
        fillOpacity="0.14"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.yellow} strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_12883_115380"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_12883_115380" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
