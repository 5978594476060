import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 72 72" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_13398_144609)">
        <rect x="18" y="49" width="36" height="17" rx="4" fill={theme.colors.yellow} />
      </g>
      <circle cx="36" cy="36" r="28" fill={theme.colors.yellowLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.yellow} strokeOpacity="0.5" />
      <path
        d="M48.0028 32.9966V25.4934C48.0028 23.8359 46.6591 22.4922 45.0016 22.4922H25.4934C23.8359 22.4922 22.4922 23.8359 22.4922 25.4934V46.5022C22.4922 48.1597 23.8359 49.5034 25.4934 49.5034H32.9966"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4961 40.5003H32.9967"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4961 34.5003H38.9992"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1839 40.6879C27.2875 40.6879 27.3715 40.6039 27.3715 40.5003C27.3715 40.3967 27.2875 40.3127 27.1839 40.3127C27.0803 40.3127 26.9963 40.3967 26.9963 40.5003C26.9963 40.6039 27.0803 40.6879 27.1839 40.6879"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1839 34.6879C27.2875 34.6879 27.3715 34.6039 27.3715 34.5003C27.3715 34.3967 27.2875 34.3127 27.1839 34.3127C27.0803 34.3127 26.9963 34.3967 26.9963 34.5003C26.9963 34.6039 27.0803 34.6879 27.1839 34.6879"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.253 51.0058C41.5218 51.0058 39.0594 49.3606 38.0142 46.8372C36.969 44.3138 37.5468 41.4093 39.4781 39.4781C41.4093 37.5468 44.3138 36.969 46.8372 38.0142C49.3606 39.0594 51.0058 41.5218 51.0058 44.253C51.0058 47.9825 47.9825 51.0058 44.253 51.0058Z"
        fill={theme.colors.yellow}
        fillOpacity="0.14"
      />
      <path
        d="M44.253 51.0058C41.5218 51.0058 39.0594 49.3606 38.0142 46.8372C36.969 44.3138 37.5468 41.4093 39.4781 39.4781C41.4093 37.5468 44.3138 36.969 46.8372 38.0142C49.3606 39.0594 51.0058 41.5218 51.0058 44.253C51.0058 47.9825 47.9825 51.0058 44.253 51.0058"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4961 28.5003H38.9992"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.1839 28.6879C27.2875 28.6879 27.3715 28.6039 27.3715 28.5003C27.3715 28.3967 27.2875 28.3127 27.1839 28.3127C27.0803 28.3127 26.9963 28.3967 26.9963 28.5003C26.9963 28.6039 27.0803 28.6879 27.1839 28.6879"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.7539 48.75L48.7539 39.75"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.yellow} strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_13398_144609"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_13398_144609" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
