import { createAsyncThunk } from "@reduxjs/toolkit";
import { isErrorResult, makeApiRequest, ENDPOINTS_BUY } from "services";
import { ErrorResult } from "services/types";
import { HistoryResponse, MarketPayload, MarketResponse, HistoryPayload } from "./types";

export const getMarketInfo = createAsyncThunk<MarketResponse, MarketPayload, { rejectValue: ErrorResult }>(
  "buy-crypto/market-info",
  async (params, { rejectWithValue }) => {
    const result = await makeApiRequest<MarketResponse>({
      method: "GET",
      url: ENDPOINTS_BUY.getMarketInfo,
      params,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getCryptoPurchases = createAsyncThunk<HistoryResponse, HistoryPayload, { rejectValue: ErrorResult }>(
  "buy-crypto/get-crypto-purchases",
  async (params, { rejectWithValue }) => {
    const result = await makeApiRequest<HistoryResponse>({
      method: "GET",
      url: ENDPOINTS_BUY.getCryptoPurchases,
      params,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);
