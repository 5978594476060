import styled from "styled-components";
import { Svg } from "components";

const StyledCustomPaginate = styled.div`
  .pagination {
    display: flex;
    list-style: none;
  }

  .page-link {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: unset;
    border-radius: ${({ theme }) => theme.radii.small};
    background: ${({ theme }) => theme.colors.transparent};
    color: ${({ theme }) => theme.colors.neutral500};

    &:hover {
      color: ${({ theme }) => (theme.isDark ? theme.colors.neutral1000 : theme.colors.neutral700)};

      ${Svg} {
        path {
          stroke: ${({ theme }) => (theme.isDark ? theme.colors.neutral1000 : theme.colors.neutral700)};
        }
      }
    }

    &:focus {
      box-shadow: unset;
    }
  }

  .page-item {
    border: unset;
    display: flex;
    color: ${({ theme }) => theme.colors.neutral500};
    user-select: none;
    font-size: 14px;
    line-height: 21px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: ${({ theme }) => theme.radii.small};
    cursor: pointer;
    margin-left: 4px;
    margin-right: 4px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .active {
    color: ${({ theme }) => (theme.isDark ? theme.colors.neutral1000 : theme.colors.neutral0)};
    cursor: default;
    user-select: none;
  }

  .page-item.active .page-link {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};

    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryHover};
      border-color: ${({ theme }) => theme.colors.primaryHover};
      color: ${({ theme }) => (theme.isDark ? theme.colors.neutral1000 : theme.colors.neutral0)};
    }
  }

  .transparent > .page-link {
    opacity: 0.3;
    cursor: default;

    &:hover {
      ${Svg} {
        path {
          stroke: ${({ theme }) => theme.colors.neutral500};
        }
      }
    }
  }
`;

export default StyledCustomPaginate;
