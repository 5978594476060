import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import lookup from "country-code-lookup";

import { getKycCountryCodes, getKycFields, getMyKycStatus, verifyKyc } from "./actions";
import { KYCState, MyKYCStatusResponse, KYCStatuses, MatchStatuses } from "./types";

const initialState: KYCState = {
  countryCodes: [],
  fields: {
    identity: null,
    document: null,
    business: null,
  },
  error: null,
  pending: false,
  statusKYC: {
    business: null,
    document: null,
    identity: null,
  },
  matchStatuses: {
    business: null,
    document: null,
    identity: null,
  },
};

const kycSlice = createSlice({
  name: "kyc",
  initialState: {
    ...initialState,
  },
  reducers: {
    resetKYC: () => {
      return initialState;
    },
    updateKYCData: (state, action: PayloadAction<MyKYCStatusResponse>) => {
      Object.keys(action.payload).forEach(key => {
        const verifyTypeData = action.payload[key as keyof MyKYCStatusResponse];

        if (verifyTypeData) {
          state.statusKYC[key as keyof KYCStatuses] = verifyTypeData.status;
        }
      });
      Object.keys(action.payload).forEach(key => {
        const matchStatus = action.payload[key as keyof MyKYCStatusResponse];

        if (matchStatus) {
          state.matchStatuses[key as keyof MatchStatuses] = matchStatus.matchStatus;
        }
      });
    },
  },
  extraReducers: builder => {
    builder
      // Country codes
      .addCase(getKycCountryCodes.fulfilled, (state, action) => {
        const options = action.payload.map(countryCode => ({
          value: countryCode,
          label: lookup.byIso(countryCode)?.country,
        }));

        const filteredArray = options.filter(({ label }) => label !== undefined) as {
          value: string;
          label: string;
        }[];

        state.countryCodes = filteredArray.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
        state.error = null;
        state.pending = false;
      })
      .addCase(getKycCountryCodes.pending, state => {
        state.pending = true;
      })
      .addCase(getKycCountryCodes.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
          state.countryCodes = [];
        }
      })
      // Fields for KYC
      .addCase(getKycFields.fulfilled, (state, action) => {
        state.fields = action.payload;
        state.error = null;
        state.pending = false;
      })
      .addCase(getKycFields.pending, state => {
        state.pending = true;
      })
      .addCase(getKycFields.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.fields = initialState.fields;
          state.pending = false;
        }
      })
      // Verify KYC
      .addCase(verifyKyc.fulfilled, state => {
        state.error = null;
        state.pending = false;
      })
      .addCase(verifyKyc.pending, state => {
        state.pending = true;
      })
      .addCase(verifyKyc.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      // Get KYC status for current user
      .addCase(getMyKycStatus.fulfilled, (state, action) => {
        state.error = null;
        state.pending = false;
        Object.keys(action.payload).forEach(key => {
          const verifyTypeData = action.payload[key as keyof MyKYCStatusResponse];

          if (verifyTypeData) {
            state.statusKYC[key as keyof KYCStatuses] = verifyTypeData.status;
          }
        });
        Object.keys(action.payload).forEach(key => {
          const matchStatus = action.payload[key as keyof MyKYCStatusResponse];

          if (matchStatus) {
            state.matchStatuses[key as keyof MatchStatuses] = matchStatus.matchStatus;
          }
        });
      })
      .addCase(getMyKycStatus.pending, state => {
        state.pending = true;
      })
      .addCase(getMyKycStatus.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
          state.statusKYC = initialState.statusKYC;
          state.matchStatuses = initialState.matchStatuses;
        }
      });
  },
});

export const { resetKYC, updateKYCData } = kycSlice.actions;
export default kycSlice;
