export const getCircleFill = (fill: number) => {
  return {
    hidden: {
      strokeDashoffset: fill,
      transition: {
        duration: 1,
        ease: "easeIn",
      },
    },
    show: {
      strokeDashoffset: 0,
      transition: {
        duration: 1,
        ease: "easeIn",
      },
    },
  };
};
