import { Table, Th } from "components";

import { useTranslation } from "context";
import { DesktopGridProps } from "./types";

export const DesktopGrid = <T,>({ data, headers, isSideModal = false, TableRow }: DesktopGridProps<T>): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Table isSideModal={isSideModal}>
      <thead>
        <tr>
          {headers.map((th, index) => (
            <Th width={th.width} key={index}>
              {t(th.text)}
            </Th>
          ))}
        </tr>
      </thead>

      <tbody>
        {data.map((item, index) => (
          <TableRow item={item} key={index} />
        ))}
      </tbody>
    </Table>
  );
};
