import React from "react";

import { useTooltip } from "hooks";
// Components
import { Row, Text, Timer } from "components";
import { StyledStatusTag } from "./styled";
// Context
import { useThemeContext, useTranslation } from "context";
// Const
import { STATUSES } from "./constants";
// Types
import { StatusTagProps } from "../types";
import { Colors } from "theme/types";
import { TransactionTypeEnum } from "store/wallets/enums";

const StatusTag: React.FC<StatusTagProps> = ({
  status,
  timer,
  isTimeOnly,
  isLetterFormat,
  onTimerReachZero,
  ...props
}) => {
  const { theme } = useThemeContext();
  const { t } = useTranslation();

  const blacklistedTooltipText =
    status === TransactionTypeEnum.BLACKLISTED
      ? t("This address was blacklisted. You can’t use it for any actions. Please, use another address.")
      : null;

  const { targetRef: targetRefStatus, tooltip: statusTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });
  const { targetRef: targetRefBlacklisted, tooltip: blacklistedTooltip } = useTooltip(blacklistedTooltipText, {
    placement: "top",
  });

  const getColor = (
    theme: { isDark: boolean },
    status: StatusTagProps["status"],
    colorType: "colorBackground" | "colorText",
  ): keyof Colors => {
    return theme.isDark
      ? (STATUSES[status].dark[colorType] as keyof Colors)
      : (STATUSES[status].light[colorType] as keyof Colors);
  };

  return (
    <StyledStatusTag color={getColor(theme, status, "colorBackground")} ellipsis {...props}>
      <Row justifyContent="center" alignItems="center" ref={targetRefBlacklisted} height="100%">
        {!isTimeOnly && (
          <>
            <Text textScale="body3" color={getColor(theme, status, "colorText")} ellipsis ref={targetRefStatus}>
              {STATUSES[status].title}
            </Text>

            {statusTooltip}
          </>
        )}
        {timer && onTimerReachZero && (
          <Timer
            mx="4px"
            timestamp={timer}
            isLetterFormat={isLetterFormat}
            onReachZeroCallback={onTimerReachZero}
            textProps={{
              textScale: "caption1",
              color: getColor(theme, status, "colorText"),
              $fontWeight: "bold",
            }}
            numberHeight={14}
            numberWidth={9}
            keyListShow={["hours", "mins"]}
          />
        )}
      </Row>

      {blacklistedTooltipText && blacklistedTooltip}
    </StyledStatusTag>
  );
};

export default StatusTag;
