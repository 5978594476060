import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { StyleSheetManager, ThemeProvider } from "styled-components";
import { HelmetProvider } from "react-helmet-async";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
// Solana
import "@solana/wallet-adapter-react-ui/styles.css";
// Styles
import { GlobalStyle, StyledToastContainer } from "styles";
// Components
import { Web3SolanaProvider } from "components/providers";
// Context
import { LanguageContextProvider, ThemeContextProvider, useThemeContext, SocketContextProvider } from "context";
// Store
import store from "store/store";
// Components
import { ErrorBoundary, LogoLoader, Modal } from "components";
import Navigation from "navigation";

import { shouldForwardProp } from "utils/helpers";

const ThemedApp: React.FC = () => {
  const { theme } = useThemeContext();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <Suspense fallback={<LogoLoader isAnimate={false} />}>
          <ErrorBoundary>
            <LanguageContextProvider fallback={<LogoLoader isAnimate={false} />}>
              <Provider store={store}>
                <Web3SolanaProvider>
                  <SocketContextProvider>
                    <Modal />
                    <Navigation />
                    <StyledToastContainer />
                  </SocketContextProvider>
                </Web3SolanaProvider>
              </Provider>
            </LanguageContextProvider>
          </ErrorBoundary>
        </Suspense>
      </StyleSheetManager>
    </ThemeProvider>
  );
};

const Root: React.FC = () => {
  return (
    <HelmetProvider>
      <ThemeContextProvider>
        <ThemedApp />
      </ThemeContextProvider>
    </HelmetProvider>
  );
};

// TODO Refactor according to documentation https://reactrouter.com/en/main/upgrading/v6-data
const router = createBrowserRouter([{ path: "*", Component: Root }]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default Sentry.withProfiler(App);
