import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getExchangeRates } from "./actions";
import { RatesState, Rates, ExchangeRatesResponse } from "./types";
import { FiatCurrencyCodeEnum } from "store/profile/settings/types";
import { getMyProfile } from "store/profile/actions";
import { loginUser, loginUserGoogle, loginUserApple } from "../auth/actions";
import { yubikeyLogin } from "../yubikey/actions";

const initialState: RatesState = {
  error: null,
  pending: false,
  rates: null,
  rateFiatCurrencyCode: FiatCurrencyCodeEnum.USD,
};

const configureRates = (payload: ExchangeRatesResponse[], fiatCurrencyCode: FiatCurrencyCodeEnum) => {
  const result: Rates = {};
  payload.forEach(item => {
    result[item.currency] = item.prices[fiatCurrencyCode];
  });

  return result;
};

const ratesSlice = createSlice({
  name: "rates",
  initialState: {
    ...initialState,
  },
  reducers: {
    updateRates: (state, action: PayloadAction<ExchangeRatesResponse[]>) => {
      state.rates = configureRates(action.payload, state.rateFiatCurrencyCode);
    },
    setFiatCurrencyCodeForRate: (state, action: PayloadAction<FiatCurrencyCodeEnum>) => {
      state.rateFiatCurrencyCode = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getExchangeRates.fulfilled, (state, action) => {
        state.rates = configureRates(action.payload, state.rateFiatCurrencyCode);
        state.error = null;
        state.pending = false;
      })
      .addCase(getExchangeRates.pending, state => {
        state.pending = true;
      })
      .addCase(getExchangeRates.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.pending = false;
        }
      })
      .addCase(getMyProfile.fulfilled, (state, action) => {
        state.rateFiatCurrencyCode = action.payload.fiatCurrencyCode;
        state.error = null;
        state.pending = false;
      })
      .addCase(getMyProfile.pending, state => {
        state.pending = true;
      })
      .addCase(getMyProfile.rejected, (state, action) => {
        if (action.payload) {
          state.pending = false;
          state.error = action.payload;
          state.rateFiatCurrencyCode = initialState.rateFiatCurrencyCode;
        }
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.rateFiatCurrencyCode = action.payload.user.fiatCurrencyCode;
        state.error = null;
        state.pending = false;
      })
      .addCase(loginUser.pending, state => {
        state.pending = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        if (action.payload) {
          state.pending = false;
          state.error = action.payload;
          state.rateFiatCurrencyCode = initialState.rateFiatCurrencyCode;
        }
      })
      .addCase(loginUserGoogle.fulfilled, (state, action) => {
        state.rateFiatCurrencyCode = action.payload.user.fiatCurrencyCode;
        state.error = null;
        state.pending = false;
      })
      .addCase(loginUserGoogle.pending, state => {
        state.pending = true;
      })
      .addCase(loginUserGoogle.rejected, (state, action) => {
        if (action.payload) {
          state.pending = false;
          state.error = action.payload;
          state.rateFiatCurrencyCode = initialState.rateFiatCurrencyCode;
        }
      })
      .addCase(loginUserApple.fulfilled, (state, action) => {
        state.rateFiatCurrencyCode = action.payload.user.fiatCurrencyCode;
        state.error = null;
        state.pending = false;
      })
      .addCase(loginUserApple.pending, state => {
        state.pending = true;
      })
      .addCase(loginUserApple.rejected, (state, action) => {
        if (action.payload) {
          state.pending = false;
          state.error = action.payload;
          state.rateFiatCurrencyCode = initialState.rateFiatCurrencyCode;
        }
      })
      .addCase(yubikeyLogin.fulfilled, (state, action) => {
        state.rateFiatCurrencyCode = action.payload.user.fiatCurrencyCode;
        state.error = null;
        state.pending = false;
      })
      .addCase(yubikeyLogin.pending, state => {
        state.pending = true;
      })
      .addCase(yubikeyLogin.rejected, (state, action) => {
        if (action.payload) {
          state.pending = false;
          state.error = action.payload;
          state.rateFiatCurrencyCode = initialState.rateFiatCurrencyCode;
        }
      });
  },
});

export const { updateRates, setFiatCurrencyCodeForRate } = ratesSlice.actions;

export default ratesSlice;
