import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  const { theme } = useThemeContext();
  return (
    <Svg viewBox="0 0 72 72" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_8606_116687)">
        <rect x="18" y="49" width="36" height="12" rx="4" fill={theme.colors.yellow} />
      </g>
      <circle cx="36" cy="36" r="28" fill={theme.colors.yellowLight} />
      <circle cx="36" cy="36" r="27.5" stroke={theme.colors.yellow} strokeOpacity="0.5" />
      <path
        d="M36 37.6799V32.0699"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.9985 42.1875C35.7915 42.1875 35.6235 42.3555 35.625 42.5625C35.625 42.7695 35.793 42.9375 36 42.9375C36.207 42.9375 36.375 42.7695 36.375 42.5625C36.375 42.3555 36.207 42.1875 35.9985 42.1875"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.0436 24.2085L50.5321 44.3145C51.8671 46.6515 50.1796 49.56 47.4886 49.56H24.5116C21.8191 49.56 20.1316 46.6515 21.4681 44.3145L32.9566 24.2085C34.3021 21.852 37.6981 21.852 39.0436 24.2085Z"
        fill={theme.colors.yellow}
        fillOpacity="0.14"
        stroke={theme.colors.yellow}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke={theme.colors.yellow} strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_8606_116687"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_8606_116687" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
