import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { RequireAuth, TemporaryTokenHandler, TokenHandler } from "./components";
import { ROUTES, ROUTE_PARAMS } from "./routes";
import { LINKS } from "configs";

import { MainOutlet, AuthOutlet } from "components";
import {
  LoginPage,
  WalletsPage,
  WalletsBalancePreview,
  WalletsNetworkPreview,
  WalletsDeposit,
  WalletsWithdraw,
  WalletsWhitelist,
  WalletsHistory,
  RegistrationPage,
  VerifyEmailPage,
  BuyCryptoPage,
  ColdStoragePage,
  ForgotPasswordPage,
  SwapPage,
  TwoFactorSetupPage,
  ConfirmAuthPage,
  PrivacyPolicyPage,
  TermsAndConditionsPage,
  NotFoundPage,
  ComingSoonPage,
  SettingsPage,
  BannedPage,
  EscrowsPage,
  CreateEscrowPage,
  EscrowPreviewPage,
  Messages,
  EscrowProcessPage,
  TwoFactorResetPage,
  LoginWithYubikeyPage,
  LoginWithYubikeyVerificationPage,
  AccountDeletionCompletedPage,
  CreateStatementPage,
  ListOfTransactionsPage,
  StatementsListPage,
  StatementItemPage,
  DashboardPage,
  EscrowWidgetTest,
} from "pages";

// TODO Migrate to a new flow "lazy" according to the documentation https://reactrouter.com/en/6.16.0/route/lazy
const Navigation: React.FC = () => {
  return (
    <Routes>
      <Route
        path={ROUTES.login}
        element={
          <TokenHandler>
            <AuthOutlet />
          </TokenHandler>
        }
      >
        <Route index element={<LoginPage />} />
      </Route>

      <Route
        path={ROUTES.registration}
        element={
          <TokenHandler>
            <AuthOutlet />
          </TokenHandler>
        }
      >
        <Route index element={<RegistrationPage />} />
      </Route>

      <Route
        path={ROUTES.confirmAuth}
        element={
          <TemporaryTokenHandler>
            <AuthOutlet />
          </TemporaryTokenHandler>
        }
      >
        <Route index element={<ConfirmAuthPage />} />
      </Route>

      <Route
        path={ROUTES.twoFactorSetup}
        element={
          <TemporaryTokenHandler>
            <AuthOutlet />
          </TemporaryTokenHandler>
        }
      >
        <Route index element={<TwoFactorSetupPage />} />
      </Route>

      <Route path={ROUTES.twoFactorReset} element={<TwoFactorResetPage />} />

      <Route
        path={ROUTES.verifyEmail}
        element={
          <TokenHandler>
            <VerifyEmailPage />
          </TokenHandler>
        }
      />

      <Route
        path={ROUTES.forgotPassword}
        element={
          <TokenHandler>
            <ForgotPasswordPage />
          </TokenHandler>
        }
      />

      <Route
        path={ROUTES.loginWithYubikey}
        element={
          <TokenHandler>
            <AuthOutlet />
          </TokenHandler>
        }
      >
        <Route index element={<LoginWithYubikeyPage />} />

        <Route path={ROUTES.verification}>
          <Route index element={<LoginWithYubikeyVerificationPage />} />
        </Route>
      </Route>

      <Route
        path={ROUTES.deleteCompleted}
        element={
          <TokenHandler>
            <AccountDeletionCompletedPage />
          </TokenHandler>
        }
      />

      <Route
        path={ROUTES.home}
        element={
          <RequireAuth>
            <MainOutlet />
          </RequireAuth>
        }
      >
        <Route index element={<Navigate replace to={"/" + ROUTES.dashboard} />} />

        <Route path={ROUTES.dashboard}>
          <Route index element={<DashboardPage />} />
        </Route>

        <Route path={ROUTES.wallet} element={<WalletsPage />}>
          <Route index element={<WalletsBalancePreview />} />

          <Route path={`:${ROUTE_PARAMS.network}`}>
            <Route index element={<WalletsNetworkPreview />} />

            <Route path={`:${ROUTE_PARAMS.token}`}>
              <Route index element={<Navigate replace to={ROUTES.withdraw} />} />
              <Route path={ROUTES.deposit} element={<WalletsDeposit />} />
              <Route path={ROUTES.withdraw} element={<WalletsWithdraw />} />
              <Route path={ROUTES.whitelist} element={<WalletsWhitelist />} />
              <Route path={ROUTES.history} element={<WalletsHistory />} />
            </Route>
          </Route>
        </Route>

        <Route path={ROUTES.coldStorage}>
          <Route index element={<ColdStoragePage />} />
        </Route>

        <Route path={ROUTES.buyCrypto}>
          <Route index element={<BuyCryptoPage />} />
        </Route>

        <Route path={ROUTES.swap}>
          <Route index element={<SwapPage />} />
        </Route>

        <Route path={ROUTES.settings}>
          <Route index element={<SettingsPage />} />
        </Route>

        <Route path={ROUTES.escrows}>
          <Route index element={<EscrowsPage />} />

          <Route path={ROUTES.createEscrow}>
            <Route index element={<CreateEscrowPage />} />
          </Route>

          <Route path={ROUTES.escrowProcess}>
            <Route index element={<Navigate replace to={"/" + ROUTES.escrows} />} />
            <Route path={`:${ROUTE_PARAMS.escrowId}`}>
              <Route index element={<EscrowProcessPage />} />
            </Route>
          </Route>

          <Route path={ROUTES.escrowPreview}>
            <Route index element={<Navigate replace to={"/" + ROUTES.escrows} />} />
            <Route path={`:${ROUTE_PARAMS.escrowPreviewId}`}>
              <Route index element={<EscrowPreviewPage />} />
            </Route>
          </Route>
        </Route>

        {/*  TODO refactor with using queryParams chatId.
        this will solve the problem with double calls to component unmount and loss of data in the store
        */}
        <Route path={ROUTES.messages}>
          <Route index element={<Messages />} />
        </Route>

        <Route path={ROUTES.statements}>
          <Route index element={<StatementsListPage />} />

          <Route path={`:${ROUTE_PARAMS.statementId}`}>
            <Route index element={<StatementItemPage />} />
          </Route>

          <Route path={ROUTES.createStatement}>
            <Route index element={<CreateStatementPage />} />

            <Route path={ROUTES.statementsTransaction}>
              <Route index element={<ListOfTransactionsPage />} />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path={ROUTES.comingSoon} element={<ComingSoonPage />} />

      <Route path={ROUTES.banned} element={<BannedPage />} />

      <Route path={ROUTES.termsAndConditions} element={<TermsAndConditionsPage />} />

      <Route path={ROUTES.privacyPolicy} element={<PrivacyPolicyPage src={LINKS.iubendaPrivacyPolicyLink} />} />

      <Route
        path={ROUTES.escrowWidgetTest}
        element={process.env.REACT_APP_ENV === "development" ? <EscrowWidgetTest /> : <NotFoundPage />}
      />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Navigation;
