import React, { PropsWithChildren } from "react";
import { AnimatePresence } from "framer-motion";

import AlertTextWithDescription from "./AlertTextWithDescription";
import { StyledAlertBanner } from "./styled";
import { Flex } from "components";
import { CloseIconWithHover } from "components/svg";

import { appearanceAnimationMap, appearanceAnimationVariants } from "theme";

import { AlertBannerProps } from "./types";

const AlertBanner: React.FC<PropsWithChildren<AlertBannerProps>> = ({
  children,
  text,
  description,
  variant,
  visible,
  actionButton,
  onCloseClick,
  ...props
}) => {
  return (
    <AnimatePresence>
      {visible && (
        <StyledAlertBanner
          key="alertBanner"
          {...appearanceAnimationMap}
          variants={appearanceAnimationVariants}
          transition={{ duration: 0.3 }}
          variant={variant}
          {...props}
        >
          <Flex flexGrow={1}>
            <AlertTextWithDescription text={text} description={description}>
              {children}
            </AlertTextWithDescription>
          </Flex>

          {React.isValidElement(actionButton) && actionButton}

          {onCloseClick && <CloseIconWithHover onClick={onCloseClick} />}
        </StyledAlertBanner>
      )}
    </AnimatePresence>
  );
};

AlertBanner.defaultProps = {
  variant: "success",
  visible: true,
};

export default AlertBanner;
