import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  return (
    <Svg viewBox="0 0 24 24" {...props} color={color}>
      <circle cx="12.001" cy="12" r="12" fill="#EFEBFF" />
      <path d="M12.001 17.25L12.001 6.75" stroke="#8E59FF" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M15.752 13.5L12.0012 17.2508L8.25045 13.5"
        stroke="#8E59FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
