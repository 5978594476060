import styled from "styled-components";

export const StyledRow = styled.div`
  display: flex;
  padding-bottom: 18px;
  padding-top: 18px;
  width: 100%;
  justify-content: space-between;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral200};
  }
`;
