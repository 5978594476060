import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral500", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 18 20" {...props} color="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0012 17.4032V8.86266C12.0012 8.43748 11.8321 8.03031 11.532 7.73018L9.26903 5.46724C8.9689 5.16712 8.56173 4.99805 8.13656 4.99805H2.59725C1.71288 4.99805 0.996582 5.71435 0.996582 6.59871V17.4032C0.996582 18.2876 1.71288 19.0039 2.59725 19.0039H10.4005C11.2849 19.0039 12.0012 18.2876 12.0012 17.4032Z"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.001 15.0024H15.4025C16.2868 15.0024 17.0031 14.2861 17.0031 13.4017V4.86119C17.0031 4.43601 16.834 4.02884 16.5339 3.72872L14.271 1.46578C13.9709 1.16565 13.5637 0.996582 13.1385 0.996582H7.5992C6.71483 0.996582 5.99854 1.71288 5.99854 2.59725V4.99825"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
