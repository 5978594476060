import { useEffect } from "react";

import { useTranslation } from "context";
import { useAppSelector } from "store/store";

import { StyledTabPanel, StyledTabPanelButton } from "./styled";
import { Box } from "components/layout";

import { scrollToTop } from "utils/helpers";

import { TabPanelProps } from "./types";

const TabPanel: React.FC<TabPanelProps> = ({
  tabs,
  onTabClick,
  activeTab,
  disabled,
  idForTabPanel,
  dontScrollTop,
  ...props
}) => {
  const isBlacklisted = useAppSelector(state => state.profile.isBlacklisted);

  const { t } = useTranslation();

  const element = document.querySelector(`#${idForTabPanel}`);

  const getMaxWidthOfTabs = () => {
    const tabsWidthArray: number[] = [];

    if (element) {
      element.childNodes.forEach(el => {
        if (el instanceof HTMLElement) {
          tabsWidthArray.push(el.offsetWidth);
        }
      });
    }

    return Math.max(...tabsWidthArray);
  };

  const scroll = (index: number) => {
    if (element) {
      element.scrollTo({ left: index > 0 ? getMaxWidthOfTabs() * (index - 1) : 0, top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    scroll(activeTab);
  }, [element]);

  //TODO: After adding translations, check the scroll logic on tabs

  //This logic for navigate user with tab on mobile version

  return (
    <Box width="100%">
      <StyledTabPanel
        id={idForTabPanel ? idForTabPanel : undefined}
        tabsCount={tabs.length}
        activeTab={activeTab}
        {...props}
      >
        {tabs.map(({ title, disableIfBlacklisted }, index) => (
          <StyledTabPanelButton
            key={index}
            id={`navTab${index}`}
            isActive={activeTab === index}
            disabled={disabled || (disableIfBlacklisted && isBlacklisted)}
            onClick={() => {
              dontScrollTop ? null : scrollToTop();
              scroll(index);
              onTabClick(index);
            }}
          >
            {t(title)}
          </StyledTabPanelButton>
        ))}
      </StyledTabPanel>
    </Box>
  );
};

export default TabPanel;
