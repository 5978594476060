import { FieldsType, FieldsConfig, KycFormHandleHookArgs } from "../types";
import { InitialValues } from "./use-validation-schema";

type A = {
  [key: string]: boolean | undefined | string | A;
};

type AKey = keyof A;
type AValueType = A[AKey];

export const useKycFormHandle = ({
  stepCount,
  currentStep,
  handleSubmit,
  setCurrentStep,
  setFieldValue,
  setFieldTouched,
  isValid,
  errors,
  type,
  touched,
}: KycFormHandleHookArgs) => {
  const continueBtnClick = () => {
    if (stepCount === currentStep) {
      handleSubmit();
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const isCurrentStepValid = () => {
    return stepCount === currentStep ? isValid : !errors[type];
  };

  const moveElementBefore = (arr: FieldsType[], targetName: string, beforeName: string) => {
    const targetIndex = arr.findIndex(obj => obj.name === targetName);
    const beforeIndex = arr.findIndex(obj => obj.name === beforeName);

    if (targetIndex === -1 || beforeIndex === -1) {
      // One of the elements was not found, return the original array
      return arr;
    }

    const [elementToMove] = arr.splice(targetIndex, 1);
    arr.splice(beforeIndex, 0, elementToMove);

    return arr;
  };

  const fieldsListToFlatArr = (fieldsList: FieldsConfig) => {
    const _fieldsListToFlatArr = (
      currentObj: FieldsConfig | FieldsType,
      cumulativeArr: FieldsType[],
      str: string,
      key?: string | undefined,
    ) => {
      if (key?.length) {
        str = str + `.${key}`;
      }

      if (!("required" in currentObj)) {
        Object.entries(currentObj).forEach(([key, value]) => {
          _fieldsListToFlatArr(value, cumulativeArr, str, key);
        });
      } else {
        cumulativeArr.push({ ...currentObj, path: str } as FieldsType);
      }
    };

    const res: FieldsType[] = [];
    const fieldsListByType = fieldsList[type];

    _fieldsListToFlatArr(fieldsListByType, res, type);

    return moveElementBefore(res, "DocumentType", "DocumentFrontImage");
  };

  const getIndexedProperty = <T>(obj: A, str: string) => {
    const keys = str.split(".");
    let currentValue: AValueType = obj;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (typeof currentValue === "object") {
        currentValue = currentValue[key];
      }
    }

    return currentValue as T;
  };

  const getPathPart = (path: string) => {
    const keys = path.split(".");
    keys.pop();
    return keys.join(".");
  };

  const getDateOfBirth = (values: InitialValues, path: string) => {
    const pathPart = getPathPart(path);

    const monthOfBirth = getIndexedProperty(values, `${pathPart}.MonthOfBirth`);
    const dayOfBirth = getIndexedProperty(values, `${pathPart}.DayOfBirth`);
    const yearOfBirth = getIndexedProperty(values, `${pathPart}.YearOfBirth`);

    return monthOfBirth && dayOfBirth && yearOfBirth ? `${monthOfBirth}/${dayOfBirth}/${yearOfBirth}` : "";
  };

  const setDateOfBirth = async (val: Date, path: string) => {
    const pathPart = getPathPart(path);

    const monthOfBirth = val.getMonth() + 1;
    const dayOfBirth = val.getDate();
    const yearOfBirth = val.getFullYear();

    await setFieldValue(`${pathPart}.MonthOfBirth`, monthOfBirth.toString());
    await setFieldValue(`${pathPart}.DayOfBirth`, dayOfBirth.toString());
    await setFieldValue(`${pathPart}.YearOfBirth`, yearOfBirth.toString());

    await setDateOfBirthTouched(path);
  };

  const setDateOfBirthTouched = async (path: string) => {
    const pathPart = getPathPart(path);

    await setFieldTouched(`${pathPart}.MonthOfBirth`, true);
    await setFieldTouched(`${pathPart}.DayOfBirth`, true);
    await setFieldTouched(`${pathPart}.YearOfBirth`, true);
  };

  const getDateOfBirthErrors = (path: string) => {
    const pathPart = getPathPart(path);

    const monthOfBirth = getIndexedProperty<string>(errors, `${pathPart}.MonthOfBirth`);
    const dayOfBirth = getIndexedProperty<string>(errors, `${pathPart}.DayOfBirth`);
    const yearOfBirth = getIndexedProperty<string>(errors, `${pathPart}.YearOfBirth`);

    return monthOfBirth || dayOfBirth || yearOfBirth;
  };

  const isDateOfBirthTouched = (path: string) => {
    const pathPart = getPathPart(path);

    const monthOfBirth = getIndexedProperty(touched, `${pathPart}.MonthOfBirth`);
    const dayOfBirth = getIndexedProperty(touched, `${pathPart}.DayOfBirth`);
    const yearOfBirth = getIndexedProperty(touched, `${pathPart}.YearOfBirth`);

    return !!(monthOfBirth || dayOfBirth || yearOfBirth);
  };

  const onUserInput = async (value: string, path: string) => {
    await setFieldValue(path, value);
  };

  return {
    continueBtnClick,
    isCurrentStepValid,
    fieldsListToFlatArr,
    getIndexedProperty,
    onUserInput,
    getDateOfBirth,
    setDateOfBirth,
    setDateOfBirthTouched,
    getDateOfBirthErrors,
    isDateOfBirthTouched,
  };
};
