import styled from "styled-components";
import { Text, FlexGap } from "components";

export const StyledTableRowMobileContainer = styled.div`
  cursor: pointer;
  padding: 0 36px;
  margin: 0 -36px;
  width: calc(100% + 72px);

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey1};
    cursor: ${"url('/images/cursor.svg') 0 0, auto"};
  }

  &:last-child {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      width: calc(100% - 72px);
      height: 1px;
      background-color: ${({ theme }) => theme.colors.neutral200};
    }
  }
`;

export const StyledTableRowMobileInnerContainer = styled(FlexGap)`
  padding: 14px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.neutral200};
  gap: 10px;
  justify-content: space-between;
`;

export const StyledTableRowMobileText = styled(Text)`
  padding-inline-end: 10px;
`;
