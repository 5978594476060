import styled from "styled-components";

import { Box } from "components/layout";

export const StyledBurgerMenu = styled.div<{ isMenuOpen: boolean }>`
  width: 16px;
  height: 16px;
  position: relative;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1.5px;
    top: ${({ isMenuOpen }) => (isMenuOpen ? "5px" : "0px")};
    background-color: ${({ theme }) => theme.colors.neutral600};
    transform: ${({ isMenuOpen }) => (isMenuOpen ? `rotate(45deg)` : `rotate(0deg)`)};
    transition: transform 0.3s ease;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1.5px;
    top: ${({ isMenuOpen }) => (isMenuOpen ? "5px" : "10px")};
    transform: ${({ isMenuOpen }) => (isMenuOpen ? `rotate(-45deg)` : `rotate(0deg)`)};
    background-color: ${({ theme }) => theme.colors.neutral600};
    transition: transform 0.3s ease;
  }
`;

export const StyledBurgerMenuSpan = styled(Box)`
  position: absolute;
  height: 1.5px;
  transition: width 0.3s ease;
`;
