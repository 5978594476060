import { useAppDispatch } from "store/store";
import { changeWhitelistItemStatus } from "store/wallets/whitelist";

import { UsePendingProps } from "./types";

const usePendingItem = ({ validatedAt, id }: UsePendingProps) => {
  const dispatch = useAppDispatch();

  const now = new Date().getTime();
  const targetTime = new Date(validatedAt).getTime();
  const diffInSeconds = Math.round((targetTime - now) / 1000);

  const timer = (now + diffInSeconds * 1000) / 1000;

  const onTimerReachZero = async () => {
    dispatch(changeWhitelistItemStatus({ id }));
  };

  return { timer, onTimerReachZero };
};

export default usePendingItem;
