export const MITEK_ERRORS = {
  PARAMETER_TYPE_ERROR: "You have a problem with setup mitek SDK. Please contact support.",
  NO_CAMERA_FOUND: "Camera not found",
  NO_FACE_FOUND: "No Face Detected",
  MISNAP_HEAD_OUTSIDE: "Slowly place your face in oval",
  MISNAP_HEAD_SKEWED: "Look straight ahead",
  MISNAP_AXIS_ANGLE: "Hold your phone upright",
  MISNAP_HEAD_TOO_CLOSE: "Slowly move farther away",
  MISNAP_HEAD_TOO_FAR: "Get closer",
  MISNAP_STAY_STILL: "Hold still",
  MISNAP_SUCCESS: "Success",
  MISNAP_STOP_SMILING: "Do not smile",
  MISNAP_SMILE: "Hold a smile",
  MISNAP_READY_POSE: "Hold",
  MITEK_ERROR_GLARE: "Reduce glare",
  MITEK_ERROR_FOUR_CORNER: "Document Not Found",
  MITEK_ERROR_TOO_DARK: "Improve lighting",
  MITEK_ERROR_FOCUS: "The image is blurry",
  CAMERA_PERMISSION_DENIED:
    "Face verification is impossible to set up without camera permission. Please provide the app with camera access to continue",
  DEFAULT: "Something went wrong. Please, try again",
  FRAUDULENT: "For security reasons, you will only be able to continue after 24 hours.",
  UNDETERMINED: "Suspicious activity detected. Try again later.",
  FACE_NOT_DETECTED:
    "We didn’t detect your face. Please ensure your face is clearly visible by the camera during the next try",
  FACE_TOO_CLOSE:
    "Your face was too close to the camera to properly capture it. Please move your face slightly away during the next try",
  FACE_TOO_SMALL:
    "Your face was too far from the camera to properly capture it. Please move your face slightly closer during the next try",
  FACE_ANGLE_TOO_LARGE:
    "Your face was not pointed directly at the camera. Please look straight at the camera during the next try",
  FAILED_TO_READ_IMAGE:
    "There was an internal error. Please try again. If problem persists, contact our customer support team",
  FAILED_QUALITY_CHECK:
    "There was an internal error. Please try again. If problem persists, contact our customer support team",
  FACE_CROPPED:
    "Your face was not fully visible. Please match your face image with the oval guideline during the next try",
  TOO_MANY_FACES:
    "We detected several faces during the face capture. Please ensure that only your face is present during the next try",
  FACE_NOT_ENROLLED: "Your face doesn’t match the saved biometrics",
  FACE_FAILED_COMPARE: "Your face doesn’t match the saved biometrics",
  FACE_FAILED_LIVENESS: "Your face doesn’t match the saved biometrics",
  FACE_DATA_EXPIRED: "Your face doesn’t match the saved biometrics",

  VOICE_NOT_DETECTED:
    "We didn’t detect your voice. Please ensure you read the phrase loud and clear during the next try",
  VOICE_FILE_INVALID:
    "There was an internal error. Please try again. If problem persists, contact our customer support team",
  VOICE_TOO_NOISY:
    "We had problems detecting your voice. Please ensure you read the phrase in quitter conditions during the next try",
  VOICE_TOO_SMALL_SPEECH_TOTAL_LENGTH:
    "We had problems detecting your voice. Please ensure you read the phrase more slowly during the next try",
  DIFFERENT_VOICES_DETECTED: "There were different voices detected in the voice files.",
  VOICE_NOT_ENROLLED: "Your voice doesn’t match the saved biometrics",
  VOICE_FAILED_COMPARE: "Your voice doesn’t match the saved biometrics",
  VOICE_FAILED_LIVENESS: "Your voice doesn’t match the saved biometrics",
  VOICE_DATA_EXPIRED: "Your voice doesn’t match the saved biometrics",
  VOICE_SAMPLE_SHOULD_BE_PROVIDED: "Voice sample should be provided for authentication process",
  FACE_SAMPLE_SHOULD_BE_PROVIDED: "Selfie sample should be provided for authentication process",
  VOICE_AUTHENTIFICATION_SHOULD_BE_ACTIVATED: "You have to up voice authentication in security settings first",
  FACE_AUTHENTIFICATION_SHOULD_BE_ACTIVATED: "You have to set up face authentication in security settings first",
};
