import React from "react";
import BigNumber from "bignumber.js";
import { Zero } from "@ethersproject/constants";

import { WalletErrorMessage } from "components";
import { NetworkFee } from "../";

import { useTranslation } from "context";

import { DepositTransferDetailProps } from "./types";

import { formatBigNumberToFixed, formatValueToBNString } from "configs/web3";

const DepositTransferDetail: React.FC<DepositTransferDetailProps> = ({
  balance,
  estimate,
  nativeCurrency,
  currency,
  isExceededBalance,
  isWalletActive,
  isWalletError,
  estimateLoading,
  values,
  errors,
  touched,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {isWalletActive ? (
        <NetworkFee
          // if the balance is 0 or there is an error, the network fee is displayed as 0
          fee={
            isWalletError || BigNumber(formatBigNumberToFixed(balance, 18, currency?.decimals)).lte(0) ? Zero : estimate
          }
          currency={nativeCurrency}
          pending={estimateLoading}
          value={formatValueToBNString(values.amount)}
         
        />
      ) : null}
      {isExceededBalance && touched["amount"] && isWalletActive ? (
        <WalletErrorMessage mt="12px" message={t("Insufficient funds to deposit or to cover network fee")} />
      )  : !!errors["amount"] && touched["amount"] && isWalletActive ? (
        <WalletErrorMessage mt="12px" message={errors["amount"]} />
      ) : null}
    </>
  );
};

export default DepositTransferDetail;
