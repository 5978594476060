import styled from "styled-components";

import { Textarea } from "components/inputs";

export const StyledDeletionReasonTextArea = styled(Textarea)`
  height: 138px;

  ${({ theme }) => theme.mediaQueries.tablet} {
    height: 90px;
  }
`;
