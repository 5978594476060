import { useTranslation } from "context";

import { Box, Button, FlexGap } from "components";

import { AmountToDepositButtonsSectionProps } from "components/amount-to-deposit/types";

const ButtonsSection: React.FC<AmountToDepositButtonsSectionProps> = ({
  handleMaxButtonClick,
  handleMinButtonClick,
  isMaxButtonDisabled,
  isMinButtonDisabled,
  pending,
}) => {
  const { t } = useTranslation();

  return (
    <Box mt="8px">
      <FlexGap justifyContent="end" gap="12px" mb="0px">
        {handleMinButtonClick && (
          <Button
            variant="chip"
            scale="sm"
            disabled={isMinButtonDisabled}
            onClick={handleMinButtonClick}
            isLoading={pending}
          >
            {t("min")}
          </Button>
        )}

        {handleMaxButtonClick && (
          <Button
            variant="chip"
            scale="sm"
            disabled={isMaxButtonDisabled}
            onClick={handleMaxButtonClick}
            isLoading={pending}
          >
            {t("max")}
          </Button>
        )}
      </FlexGap>
    </Box>
  );
};

export default ButtonsSection;
