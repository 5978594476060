export enum Roles {
  buyer = "buyer",
  seller = "seller",
  broker = "broker",
}

export enum CreateEscrowSteps {
  role = "role",
  escrowInfo = "escrowInfo",
  currency = "currency",
  preview = "preview",
}

export enum CommissionPayer {
  buyer = "buyer",
  seller = "seller",
  inHalf = "inHalf",
}

export enum DeliverySteps {
  notShipped = "notShipped",
  shippedWaitingInfo = "shippedWaitingInfo",
  inProgress = "inProgress",
  distributeFunds = "distributeFunds",
  delivered = "delivered",
}
