import React from "react";

import { Box, Text, ImagePreviewer } from "components";
import { useUploadImage } from "hooks";

import { ImageUploadProps } from "../types";
import { ImageProps } from "components/image-cropper/types";

// we set a compression limit of 1 MB, since after compression there is a conversion to base64 and an increase in file size,
// since we cannot control the increase in size, we set a smaller limit in advance
export const MAX_SIZE = 1;

const ImageUpload: React.FC<ImageUploadProps> = ({ path, src, item, setFieldValue, setFieldTouched }) => {
  const onImageLoad = async (image: ImageProps) => {
    await setFieldValue(path, image.src);
  };

  const { suitableExtension, resetImage, handleUploadImage, isLoading } = useUploadImage(onImageLoad, MAX_SIZE);

  const _image = { src: src, name: item.name };

  const onImageReset = async () => {
    await setFieldValue(path, "");
    await setFieldTouched(path, true);
    resetImage();
  };

  return (
    <Box mb="24px">
      <Text mb="8px" textScale="caption1" color="neutral500">
        {item.label}
      </Text>

      <ImagePreviewer
        image={_image}
        accept={suitableExtension}
        isLoading={isLoading}
        resetImage={onImageReset}
        handleUploadImage={handleUploadImage}
      />
    </Box>
  );
};

export default ImageUpload;
