import { BigNumber } from "@ethersproject/bignumber";
import { PublicKey } from "@solana/web3.js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import useSWR from "swr";

import { FAST_INTERVAL_SOLANA } from "configs/solana";

export const useNativeBalanceSolana = () => {
  const { publicKey } = useWallet();
  const { connection } = useConnection();

  const { data = "0", isValidating } = useSWR(
    publicKey ? `${publicKey.toBase58()}/nativeBalanceSolana` : null,
    async () => {
      const response = await connection.getBalance(publicKey!);
      return response.toString();
    },
    { refreshInterval: FAST_INTERVAL_SOLANA },
  );

  return { balance: BigNumber.from(data), isValidating };
};

export const useTokenBalanceSolana = (tokenAddress?: string) => {
  const { publicKey } = useWallet();
  const { connection } = useConnection();

  const { data = "0", isValidating } = useSWR(
    publicKey && tokenAddress ? `${publicKey.toBase58()}/tokenBalanceSolana/${tokenAddress}` : null,
    async () => {
      const tokenPublicKey = new PublicKey(tokenAddress!);

      const response = await connection.getParsedTokenAccountsByOwner(publicKey!, { mint: tokenPublicKey });
      return (response.value[0]?.account?.data?.parsed?.info?.tokenAmount?.amount ?? "0") as string;
    },
    { refreshInterval: FAST_INTERVAL_SOLANA },
  );

  return { balance: BigNumber.from(data), isValidating };
};
