import React from "react";

import { useTranslation } from "context";

import { Text } from "components";
import { StyledDivider } from "./styled";

const Divider: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledDivider>
      <Text mx="8px" textScale="caption1" color="neutral600">
        {t("or")}
      </Text>
    </StyledDivider>
  );
};

export default Divider;
