import React from "react";
import BigNumber from "bignumber.js";

import { useTranslation } from "context";
import { useTooltip } from "hooks";

import { Flex, Text, Skeleton, FlexGap, Box } from "components";

import { formatBigNumberToFixed, formatValueToBNString } from "configs/web3";

import { NetworkFeeProps } from "./types";

const NetworkFee: React.FC<NetworkFeeProps> = ({ fee, currency, pending, value }) => {
  const { t } = useTranslation();

  const formattedFee = formatBigNumberToFixed(fee, 8, currency.decimals);
  const { targetRef: targetRefFeeAmount, tooltip: tooltipFeeAmount } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  return (
    <Flex mt="12px">
      <Text textScale="caption2" color="neutral500">
        {t("Network fee")}:
      </Text>

      {pending ? (
        <Skeleton mx="4px" height="100%" width="100px" />
      ) : (
        <FlexGap gap="6px" mx="6px" maxWidth="60%">
          <Box ellipsis>
            <Text textScale="caption2" $fontWeight="bold" ref={targetRefFeeAmount} ellipsis>
              {BigNumber(formatValueToBNString(value)).gt(0)
                ? BigNumber(formattedFee).toFormatExtended(8)
                : BigNumber("0").toFormatExtended(8)}{" "}
            </Text>
          </Box>

          <Text textScale="caption2" $fontWeight="bold">
            {currency.symbol}
          </Text>

          {tooltipFeeAmount}
        </FlexGap>
      )}
    </Flex>
  );
};

export default NetworkFee;
