import BigNumber from "bignumber.js";

import { useGetCurrencyRate, useGetFiatSymbolAndAbbreviation, useTokenBE, useTooltip } from "hooks";

import { historyTypeImage } from "../constants";

import { WalletsHistoryTableRowProps } from "../components/types";

export const useHandleTableData = ({ item }: WalletsHistoryTableRowProps) => {
  const { currentRate } = useGetCurrencyRate(item.currency.publicCode);
  const { fiatAbbreviation } = useGetFiatSymbolAndAbbreviation();
  const { token } = useTokenBE(item.currency.chainId, item.currency.publicCode);

  const { targetRef: targetRefDate, tooltip: dateTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const { targetRef: targetRefCurrency, tooltip: currencyTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const { targetRef: targetRefNetwork, tooltip: networkTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const { targetRef: targetRefTotalCurrency, tooltip: totalCurrencyTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const { targetRef: targetRefFee, tooltip: feeTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const { targetRef: targetRefType, tooltip: typeTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const { targetRef: targetRefFiat, tooltip: fiatTooltip } = useTooltip(undefined, {
    placement: "top",
    isEllipsis: true,
  });

  const balanceInFiat = BigNumber(item.amount).times(currentRate).toFormatExtended(2);
  const TypeImage = historyTypeImage[item.type as keyof typeof historyTypeImage];

  return {
    fiatAbbreviation,
    token,
    balanceInFiat,
    TypeImage,
    targetRefDate,
    targetRefCurrency,
    targetRefNetwork,
    targetRefTotalCurrency,
    targetRefFee,
    targetRefType,
    targetRefFiat,
    dateTooltip,
    currencyTooltip,
    networkTooltip,
    totalCurrencyTooltip,
    feeTooltip,
    typeTooltip,
    fiatTooltip,
  };
};
