import { useAppSelector } from "store/store";

import { fiatCurrenciesList, fiatCurrencies } from "configs";

import { FiatCurrencyCodeEnum } from "store/profile/settings/types";

export const useGetFiatSymbolAndAbbreviation = (fiatCurrencyKey?: FiatCurrencyCodeEnum) => {
  const user = useAppSelector(state => state.profile.user);

  const isCurrentFiatUSD = user?.fiatCurrencyCode === FiatCurrencyCodeEnum.USD;

  const fiatObject = fiatCurrenciesList.find(item => {
    if (fiatCurrencyKey) {
      return item.value.toLowerCase() === fiatCurrencyKey;
    } else {
      return item.value.toLowerCase() === user?.fiatCurrencyCode;
    }
  });

  return {
    fiatSymbol: fiatObject?.symbol ?? fiatCurrencies.usd.symbol,
    fiatAbbreviation: fiatObject?.value ?? fiatCurrencies.usd.value,
    isCurrentFiatUSD,
  };
};
