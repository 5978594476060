export const BITCOIN_CHAINS = {
  BTC: "btc",
  BTC_TEST: "btc_test",
};

export const BITCOIN = {
  name: "Bitcoin",
  symbol: "BTC",
  decimals: 8,
};

export const BITCOIN_NETWORK =
  process.env.REACT_APP_ENV === "development" ? BITCOIN_CHAINS.BTC_TEST : BITCOIN_CHAINS.BTC;
