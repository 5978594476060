import { DeliverySteps } from "pages/main/create-escrow/types";
import { useAppSelector } from "store/store";

const DELIVERY_STATUS_CODE = "delivery_delivered";

export const useDeliveryInfo = () => {
  const currentEscrow = useAppSelector(state => state.escrowProcess.currentEscrow);

  const deliveryEvents =
    currentEscrow?.body.delivery.shipments[0] && currentEscrow?.body.delivery.shipments[0].events
      ? currentEscrow?.body.delivery.shipments[0].events
      : [];

  const isShipped = currentEscrow?.body.delivery.isShipped;
  const isShippedWaitingInfo = isShipped && currentEscrow?.body.delivery.isDelivered;

  // The order of checks below is important
  const deliveryStep = !isShipped
    ? DeliverySteps.notShipped
    : currentEscrow.isShippable && isShipped && !deliveryEvents?.length
      ? DeliverySteps.shippedWaitingInfo
      : isShippedWaitingInfo
        ? DeliverySteps.distributeFunds
        : currentEscrow.isShippable &&
            isShipped &&
            deliveryEvents[deliveryEvents.length - 1].statusCode === DELIVERY_STATUS_CODE
          ? DeliverySteps.delivered
          : !currentEscrow.isShippable && isShipped
            ? DeliverySteps.delivered
            : isShipped && deliveryEvents?.length
              ? DeliverySteps.inProgress
              : null;

  return { deliveryEvents, deliveryStep, isShippedWaitingInfo, DELIVERY_STATUS_CODE };
};
