import BigNumber from "bignumber.js";
import { Roles } from "pages/main/create-escrow/types";
import { TABS } from "configs";
import { useHandleDataProps } from "./types";

export const useHandleData = ({
  coversEscryptoFee,
  escryptoFee,
  brokersCommission,
  coversBrokersCommission,
  amount,
}: useHandleDataProps) => {
  const getPaysAndReceives = (role: keyof typeof Roles) => {
    const zertFeePart =
      coversEscryptoFee === TABS.createEscrowCommissionPayer[2].title
        ? BigNumber(escryptoFee).div(2)
        : coversEscryptoFee === TABS.createEscrowCommissionPayer[role === Roles.buyer ? 0 : 1].title
          ? BigNumber(escryptoFee)
          : BigNumber("0");

    const brokersCommissionPart =
      brokersCommission && coversBrokersCommission === TABS.createEscrowCommissionPayer[2].title
        ? BigNumber(brokersCommission).div(2)
        : brokersCommission &&
            coversBrokersCommission === TABS.createEscrowCommissionPayer[role === Roles.buyer ? 0 : 1].title
          ? BigNumber(brokersCommission)
          : BigNumber("0");

    if (role === Roles.buyer) {
      return BigNumber(amount).plus(zertFeePart).plus(brokersCommissionPart).toFormatExtended(8);
    } else {
      return BigNumber(amount).minus(zertFeePart).minus(brokersCommissionPart).toFormatExtended(8);
    }
  };

  return { getPaysAndReceives };
};
