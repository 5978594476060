import React from "react";

import { useMatchBreakpoints, useTooltip } from "hooks";
import { useHandleData } from "./hooks";

import { Text, Column, TabPanel, Input, InputGroup, Switcher, Flex, Box, FlexGap } from "components";
import { TooltipIcon } from "components/svg";
import { WidgetPreview } from "../index";

import { useAppSelector } from "store/store";

import { useTranslation } from "context";

import { Roles } from "../../types";
import { AccountType } from "store/profile/types";
import { RoleProps } from "./types";

const Role: React.FC<RoleProps> = ({
  setFieldValue,
  setFieldTouched,
  errors,
  values,
  fieldProps,
  touched,
  isWidgetShowed,
}) => {
  const roleTab = useAppSelector(state => state.createEscrow.roleTab);
  const user = useAppSelector(state => state.profile.user);

  const { t } = useTranslation();
  const { isDesktop } = useMatchBreakpoints();

  const { getTabs, onTabClick, handleSwitchWidget } = useHandleData({ setFieldValue, setFieldTouched, values });

  const { targetRef: targetRefInfo, tooltip: tooltipInfo } = useTooltip(
    t(
      'By using this functionality, you can incorporate an escrow directly into your webpage, even if it is not part of our platform. To get a clear idea of how it will appear on your website, please refer to the "Widget Preview" for a visual representation.',
    ),
    {
      placement: "top",
    },
  );

  return (
    <Column mt="24px" height="100%">
      <Text textScale="caption1" color="neutral500" mb="8px">
        {t("Choose your Role")}
      </Text>

      <TabPanel tabs={getTabs()} activeTab={roleTab} onTabClick={onTabClick} maxWidth="100% !important" mb="24px" />

      <form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        {values.role.role === Roles.seller && user?.accountType === AccountType.BUSINESS && (
          <Flex justifyContent="space-between" mb="24px">
            <FlexGap gap="14px" alignItems="center">
              <Text textScale="caption1" color="neutral600">
                {t("Create a widget")}
              </Text>

              <Box ref={targetRefInfo}>
                <TooltipIcon cursor="pointer" />
              </Box>
              {tooltipInfo}
            </FlexGap>

            <Switcher checked={values.role.createAWidget} onChange={handleSwitchWidget} />
          </Flex>
        )}

        {(values.role.role === Roles.broker || values.role.role === Roles.buyer) && (
          <InputGroup
            label={t("Add Seller’s email address *")}
            error={errors.role?.sellersEmail}
            isTouched={touched?.role?.sellersEmail}
          >
            <Input placeholder={t("Add Seller’s email address *")} {...fieldProps("role.sellersEmail")} />
          </InputGroup>
        )}

        {(values.role.role === Roles.seller && !values.role.createAWidget) || values.role.role === Roles.broker ? (
          <InputGroup
            label={t("Add Buyer’s email address *")}
            error={errors.role?.buyersEmail}
            isTouched={touched?.role?.buyersEmail}
          >
            <Input placeholder={t("Add Buyer’s email address *")} {...fieldProps("role.buyersEmail")} />
          </InputGroup>
        ) : null}
      </form>

      {!isDesktop && isWidgetShowed && <WidgetPreview />}
    </Column>
  );
};

export default Role;
