import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral600", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 26 26" {...props} color="transparent" stroke={theme.colors[color]}>
      <path
        d="M19.5029 13.0002C19.5029 13.2765 19.2789 13.5004 19.0026 13.5004C18.7264 13.5004 18.5024 13.2765 18.5024 13.0002C18.5024 12.724 18.7264 12.5 19.0026 12.5C19.2789 12.5 19.5029 12.724 19.5029 13.0002"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5004 13.0002C13.5004 13.2765 13.2765 13.5004 13.0002 13.5004C12.724 13.5004 12.5 13.2765 12.5 13.0002C12.5 12.724 12.724 12.5 13.0002 12.5C13.2765 12.5 13.5004 12.724 13.5004 13.0002"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49773 13.0002C7.49773 13.2765 7.27378 13.5004 6.99752 13.5004C6.72127 13.5004 6.49731 13.2765 6.49731 13.0002C6.49731 12.724 6.72127 12.5 6.99752 12.5C7.27378 12.5 7.49773 12.724 7.49773 13.0002"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
