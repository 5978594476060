import React, { ChangeEvent, useEffect, useState } from "react";

import { useTranslation } from "context";
import { useDebounce } from "hooks";

import { Column, Flex, InputGroup, Input, Text, EmptyDataMessage, FlexGap } from "components";
import { CloseIcon, SearchIcon } from "components/svg";
import { SingleNetworkItem, SingleTokenItem } from "./components";
import { StyledColumn } from "components/modal/components/deposit-choose-token-modal/styled";
import CloseModalWrapper from "../close-modal-wrapper";
import { StyledModalWrapper } from "../styled";

import { SUPPORTED_CHAIN_IDS } from "configs/web3";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";

import { selectCombinedData } from "./selectors";

const SwapNetworkTokenModal: React.FC = () => {
  const { swap, swapType, resetAmount, networks } = useAppSelector(selectCombinedData);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 1000);

  const currentNetwork = networks ? networks[swap[swapType]?.network as keyof typeof networks] : null;
  const [tokenList, setTokenList] = useState(currentNetwork ? Object.values(currentNetwork) : []);
  const isResultListExist = tokenList.some(
    item =>
      item.symbol.toUpperCase().match(debouncedSearchValue) || item.name.toUpperCase().match(debouncedSearchValue),
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value.toUpperCase());
  };

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  useEffect(() => {
    const tokensList = currentNetwork ? Object.values(currentNetwork) : [];
    setTokenList(tokensList);
  }, [currentNetwork]);

  return (
    <StyledModalWrapper>
      <Flex width="100%" flexDirection="column">
        <CloseModalWrapper closeModalHandler={closeModalHandler} mb="26px">
          <Text textScale="body2" $fontWeight="medium" color="neutral600">
            {t("Select Network")}
          </Text>
        </CloseModalWrapper>

        <FlexGap gap="8px" flexWrap="wrap">
          {SUPPORTED_CHAIN_IDS.map((item, index) => (
            <SingleNetworkItem key={index} networkItem={item} resetAmount={resetAmount} />
          ))}
        </FlexGap>

        {swap[swapType]?.network && networks ? (
          tokenList.length ? (
            <Column>
              <Text textScale="body2" mt="36px" $fontWeight="medium" color="neutral600">
                {t("Select Token")}
              </Text>

              <InputGroup
                mt="14px"
                startIcon={<SearchIcon width="24px" height="24px" />}
                endIcon={
                  searchValue ? (
                    <CloseIcon width="14px" cursor="pointer" color="neutral600" onClick={() => setSearchValue("")} />
                  ) : undefined
                }
              >
                <Input placeholder={t("Search Token")} value={searchValue} onChange={handleInputChange} />
              </InputGroup>

              <StyledColumn>
                {isResultListExist ? (
                  tokenList.map((item, index) => {
                    if (
                      item.symbol.toUpperCase().match(debouncedSearchValue) ||
                      item.name.toUpperCase().match(debouncedSearchValue)
                    ) {
                      return <SingleTokenItem key={index} tokenItem={item} resetAmount={resetAmount} />;
                    }
                  })
                ) : (
                  <Flex my="12px">
                    <EmptyDataMessage titleProps={{ scale: "h4" }} />
                  </Flex>
                )}
              </StyledColumn>
            </Column>
          ) : (
            <Flex mt="40px" mb="12px">
              <EmptyDataMessage titleProps={{ scale: "h4" }} />
            </Flex>
          )
        ) : null}
      </Flex>
    </StyledModalWrapper>
  );
};

export default SwapNetworkTokenModal;
