import { HistoryWithdrawIcon, HistoryDepositIcon } from "components/svg";

import { HotWalletTransactionsTypeEnum } from "store/wallets/enums";

export const HEADERS = [
  {
    width: "15%",
    text: "Date",
  },
  {
    width: "12%",
    text: "Status",
  },
  {
    width: "15%",
    text: "Network",
  },
  {
    width: "12%",
    text: "Currency",
  },
  {
    width: "10%",
    text: "Total",
  },
  {
    width: "15%",
    text: "Fee paid",
  },
  {
    width: "20%",
    text: "Type",
  },
];

export const historyTypeImage = {
  [HotWalletTransactionsTypeEnum.WALLET_DEPOSIT]: HistoryDepositIcon,
  [HotWalletTransactionsTypeEnum.WALLET_WITHDRAW]: HistoryWithdrawIcon,
  [HotWalletTransactionsTypeEnum.ESCROW_BROKER_INCOME]: HistoryDepositIcon,
  [HotWalletTransactionsTypeEnum.ESCROW_SELLER_INCOME]: HistoryDepositIcon,
  [HotWalletTransactionsTypeEnum.ESCROW_BUYER_OUTCOME]: HistoryWithdrawIcon,
  [HotWalletTransactionsTypeEnum.ESCROW_BUYER_REFUND]: HistoryDepositIcon,
  [HotWalletTransactionsTypeEnum.DEPOSIT_TO_COLD_WALLET]: HistoryDepositIcon,
  [HotWalletTransactionsTypeEnum.WITHDRAW_FROM_COLD_WALLET]: HistoryWithdrawIcon,
  [HotWalletTransactionsTypeEnum.COLD_WALLET_SUBSCRIPTION_FEE_DEBT]: HistoryDepositIcon,
  [HotWalletTransactionsTypeEnum.COLD_WALLET_SUBSCRIPTION_FEE_REGULAR]: HistoryDepositIcon,
  [HotWalletTransactionsTypeEnum.SWAP_INCOME_ADDITIONAL]: HistoryWithdrawIcon,
  [HotWalletTransactionsTypeEnum.OPERATIONAL_GAS_STATION]: HistoryWithdrawIcon,
  [HotWalletTransactionsTypeEnum.SWAP_INCOME]: HistoryDepositIcon,
  [HotWalletTransactionsTypeEnum.SWAP_OUTCOME]: HistoryWithdrawIcon,
  [HotWalletTransactionsTypeEnum.CRYPTO_PURCHASE]: HistoryWithdrawIcon,
};

export const historyTypeText = {
  [HotWalletTransactionsTypeEnum.WALLET_DEPOSIT]: "Deposit",
  [HotWalletTransactionsTypeEnum.WALLET_WITHDRAW]: "Withdraw",
  [HotWalletTransactionsTypeEnum.ESCROW_BROKER_INCOME]: "Broker Income",
  [HotWalletTransactionsTypeEnum.ESCROW_SELLER_INCOME]: "Seller Income",
  [HotWalletTransactionsTypeEnum.ESCROW_BUYER_OUTCOME]: "Buyer Outcome",
  [HotWalletTransactionsTypeEnum.ESCROW_BUYER_REFUND]: "Buyer Refund",
  [HotWalletTransactionsTypeEnum.DEPOSIT_TO_COLD_WALLET]: "Deposit to Cold Wallet",
  [HotWalletTransactionsTypeEnum.WITHDRAW_FROM_COLD_WALLET]: "Withdraw from Cold Wallet",
  [HotWalletTransactionsTypeEnum.COLD_WALLET_SUBSCRIPTION_FEE_REGULAR]: "Cold Wallet Subscription",
  [HotWalletTransactionsTypeEnum.COLD_WALLET_SUBSCRIPTION_FEE_DEBT]: "Cold Wallet Subscription",
  [HotWalletTransactionsTypeEnum.SWAP_INCOME_ADDITIONAL]: "Miscellaneous",
  [HotWalletTransactionsTypeEnum.OPERATIONAL_GAS_STATION]: "Miscellaneous",
  [HotWalletTransactionsTypeEnum.SWAP_INCOME]: "Swap Income",
  [HotWalletTransactionsTypeEnum.SWAP_OUTCOME]: "Swap Outcome",
  [HotWalletTransactionsTypeEnum.CRYPTO_PURCHASE]: "Crypto Purchase",
};

export const historyTypeSelectList = [
  { value: HotWalletTransactionsTypeEnum.WALLET_DEPOSIT, label: "Deposit" },
  { value: HotWalletTransactionsTypeEnum.WALLET_WITHDRAW, label: "Withdraw" },
  { value: HotWalletTransactionsTypeEnum.DEPOSIT_TO_COLD_WALLET, label: "Deposit to Cold Wallet" },
  { value: HotWalletTransactionsTypeEnum.WITHDRAW_FROM_COLD_WALLET, label: "Withdraw from Cold Wallet" },
  { value: HotWalletTransactionsTypeEnum.SWAP_INCOME, label: "Swap Income" },
  { value: HotWalletTransactionsTypeEnum.SWAP_OUTCOME, label: "Swap Outcome" },
  { value: HotWalletTransactionsTypeEnum.ESCROW_BUYER_OUTCOME, label: "Buyer Outcome" },
  { value: HotWalletTransactionsTypeEnum.ESCROW_SELLER_INCOME, label: "Seller Income" },
  { value: HotWalletTransactionsTypeEnum.ESCROW_BROKER_INCOME, label: "Broker Income" },
  { value: HotWalletTransactionsTypeEnum.ESCROW_BUYER_REFUND, label: "Buyer Refund" },
  { value: HotWalletTransactionsTypeEnum.CRYPTO_PURCHASE, label: "Crypto Purchase" },
];
