import { useState } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { Transaction, VersionedTransaction } from "@solana/web3.js";

export const useWaitTransactionSolana = () => {
  const { connection } = useConnection();
  const { sendTransaction } = useWallet();

  const [loading, setLoading] = useState(false);

  const fetchWithCatchTxErrorSolana = async (transaction: Transaction | VersionedTransaction) => {
    try {
      setLoading(true);

      const signature = await sendTransaction(transaction, connection);

      const {
        value: { blockhash, lastValidBlockHeight },
      } = await connection.getLatestBlockhashAndContext();

      const confirmed = await connection.confirmTransaction({ blockhash, lastValidBlockHeight, signature });

      return { ...confirmed, signature };
    } catch (error) {
      // Error message fires at SolanaContext onError method
      console.error("Error in fetchWithCatchTxErrorSolana: ", error);
    } finally {
      setLoading(false);
    }

    return null;
  };

  return {
    fetchWithCatchTxErrorSolana,
    loading,
  };
};
