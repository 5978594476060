import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";
import { ColdStorageDepositSuccessModalProps } from "./types";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => (state.modal.props as unknown as ColdStorageDepositSuccessModalProps).amount,
    (state: AppState) => (state.modal.props as unknown as ColdStorageDepositSuccessModalProps).currencyCode,
    (state: AppState) => (state.modal.props as unknown as ColdStorageDepositSuccessModalProps).currentVaultPrice,
    (state: AppState) => (state.modal.props as unknown as ColdStorageDepositSuccessModalProps).currentRate,
    (state: AppState) => (state.modal.props as unknown as ColdStorageDepositSuccessModalProps).withdrawalFee,
    (state: AppState) => (state.modal.props as unknown as ColdStorageDepositSuccessModalProps).subscriptionFee,
  ],
  (amount, currencyCode, currentVaultPrice, currentRate, withdrawalFee, subscriptionFee) => ({
    amount,
    currencyCode,
    currentVaultPrice,
    currentRate,
    withdrawalFee,
    subscriptionFee,
  }),
);
