import moment from "moment";
import { DateRange } from "react-day-picker";

import { RangeDatePickerHooksProps } from "../types";

const useRangeDayPickerHandlers = ({
  selectedRange,
  setFromValue,
  setSelectedRange,
  setToValue,
  setDoneClick,
}: RangeDatePickerHooksProps) => {
  const handleDateChange = ({ value, rangeType }: { value: string; rangeType: "from" | "to" }) => {
    const date = moment(value, "MM/DD/YY", true);
    const today = new Date();

    if (rangeType === "from") {
      setFromValue(value);

      if (!date.isValid() || date.isAfter(today)) {
        setSelectedRange({ from: undefined, to: selectedRange?.to });
        setToValue();
      } else if (date.isBefore(selectedRange?.to || today)) {
        setSelectedRange({ from: date.toDate(), to: selectedRange?.to });
      }
    } else {
      setToValue(value);

      if (!date.isValid() || date.isAfter(today)) {
        setSelectedRange({ from: selectedRange?.from, to: undefined });
      } else if (selectedRange?.from && date.isAfter(selectedRange?.from)) {
        setSelectedRange({ from: selectedRange?.from, to: date.toDate() });
      }
    }
  };

  const handleRangeSelect = (range: DateRange | undefined) => {
    if (range?.from) {
      setFromValue(moment(range.from).format("MM/DD/YY"));
    } else {
      setFromValue();
    }

    if (range?.to) {
      setToValue(moment(range.to).format("MM/DD/YY"));
    } else {
      setToValue();
    }

    setSelectedRange(range);
  };

  const handleDoneClick = () => {
    if (selectedRange) {
      setDoneClick(selectedRange);
    }
  };

  return { handleDoneClick, handleRangeSelect, handleDateChange };
};

export default useRangeDayPickerHandlers;
