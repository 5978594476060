import { css } from "styled-components";
import styled, { DefaultTheme } from "styled-components";

import ReactInputMask from "react-input-mask";
import { Column } from "components";

const getNavigateMonthArrowSidebarStyle = (theme: DefaultTheme) => {
  return css`
    .rdp-months {
      justify-content: center;
    }

    .rdp-caption {
      position: relative;
      height: 40px;
      margin-bottom: 10px;
      justify-content: center;
    }

    .rdp-nav_button_previous {
      position: absolute;
      left: 0;
      top: 0;
    }

    .rdp-nav_button_next {
      position: absolute;
      right: 0;
      top: 0;
    }

    .rdp-nav {
      path {
        fill: ${theme.colors.neutral600};
      }
    }
  `;
};

const getNavigateMonthArrowTooltipStyle = () => {
  return css`
    .rdp-months {
      justify-content: stretch;
    }

    .rdp-caption {
      height: unset;
      margin-bottom: 0;
      justify-content: space-between;
    }

    .rdp-nav_button_previous {
      position: static;
    }

    .rdp-nav_button_next {
      position: static;
    }
  `;
};

const getRangeDayPickerBorderStyle = (theme: DefaultTheme) => {
  return css`
    box-shadow: ${theme.shadows.dayPicker};
    border: 1px solid ${theme.isDark ? theme.colors.neutral400 : theme.colors.neutral300};
  `;
};

export const StyledRangeDayPicker = styled(Column)<{ isSideMenuVersion: boolean }>`
  align-items: center;
  box-shadow: none;
  border-radius: ${({ theme }) => theme.radii.medium};
  border: 1px solid ${({ theme }) => theme.colors.transparent};
  background-color: ${({ theme }) => theme.colors.neutral0};
  transition: opacity 0.3s ease;

  ${({ theme, isSideMenuVersion }) => !isSideMenuVersion && getRangeDayPickerBorderStyle(theme)}

  ${({ isSideMenuVersion, theme }) =>
    !isSideMenuVersion ? getNavigateMonthArrowTooltipStyle() : getNavigateMonthArrowSidebarStyle(theme)}

  .rdp-multiple_months {
    margin-bottom: 0;
  }

  .rdp-caption_label {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral800};
  }

  .rdp-row {
    border-bottom: 1px solid transparent;
  }

  .rdp-cell {
    height: unset;
    width: unset;
  }

  .rdp-cell:has(.rdp-day_range_start) {
    background-color: ${({ theme }) => theme.colors.neutral200};
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
  }

  .rdp-row .rdp-cell:last-child:has(.rdp-day_range_start) {
    border-radius: 100%;
  }

  .rdp-row .rdp-cell:first-child:has(.rdp-day_range_end) {
    border-radius: 100%;
  }

  .rdp-cell:has(.rdp-day_range_end) {
    background-color: ${({ theme }) => theme.colors.neutral200};
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
  }

  .rdp-cell .rdp-day_selected {
    background-color: ${({ theme }) => theme.colors.neutral200};
    border-radius: 0;
  }

  .rdp-cell .rdp-day_range_start {
    background: ${({ theme }) => theme.colors.neutral600};
    color: ${({ theme }) => theme.colors.neutral0};
    border-radius: 100%;

    :hover {
      background: ${({ theme }) => theme.colors.neutral500};
      border-color: ${({ theme }) => theme.colors.neutral500};
    }

    :focus {
      background: ${({ theme }) => theme.colors.neutral600};
      border-color: ${({ theme }) => theme.colors.neutral600};
    }
  }

  .rdp-cell .rdp-day_range_end {
    background: ${({ theme }) => theme.colors.neutral600};
    color: ${({ theme }) => theme.colors.neutral0};
    border-radius: 100%;

    :hover {
      background: ${({ theme }) => theme.colors.neutral500};
      border-color: ${({ theme }) => theme.colors.neutral500};
    }

    :focus {
      background: ${({ theme }) => theme.colors.neutral600};
      border-color: ${({ theme }) => theme.colors.neutral600};
    }
  }

  .rdp:not([dir="rtl"]) .rdp-day_range_start:not(.rdp-day_range_end) {
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
  }

  .rdp:not([dir="rtl"]) .rdp-day_range_end:not(.rdp-day_range_start) {
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
  }

  .rdp-head_cell {
    text-transform: unset;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral800};
  }

  .rdp-cell:last-child {
    border-radius: 0 37px 37px 0;
    overflow: hidden;
  }

  .rdp-cell:first-child {
    border-radius: 37px 0 0 37px;
    overflow: hidden;
  }

  .rdp-day {
    min-width: 40px;
    min-height: 40px;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral500};
  }

  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
    :hover {
      background: ${({ theme }) => theme.colors.neutral500};
      color: ${({ theme }) => theme.colors.neutral0};
      border-radius: 100%;
    }
  }

  .rdp-day_range_middle {
    background: ${({ theme }) => theme.colors.neutral0};

    :hover {
      background: ${({ theme }) => theme.colors.neutral500};
      color: ${({ theme }) => theme.colors.neutral0};
    }

    :focus {
      background: ${({ theme }) => theme.colors.neutral600};
      border-color: ${({ theme }) => theme.colors.neutral600};
      border-radius: 100%;
      color: ${({ theme }) => theme.colors.neutral0};
    }
  }

  .rdp-day:not(.rdp-day_range_start, .rdp-day_range_end, .rdp-day_range_middle, .rdp-day_disabled) {
    :hover {
      border-color: ${({ theme }) => theme.colors.neutral500};
      background-color: ${({ theme }) => theme.colors.neutral500};
      color: ${({ theme }) => theme.colors.neutral0};
    }

    :focus {
      background: ${({ theme }) => theme.colors.neutral600};
      border-color: ${({ theme }) => theme.colors.neutral600};
      border-radius: 100%;
      color: ${({ theme }) => theme.colors.neutral0};
    }
  }

  .rdp-nav_button_next,
  .rdp-nav_button_previous {
    :hover {
      background-color: unset;
    }
    :focus {
      border: unset;
      background-color: unset;
    }
  }

  .bnt-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
  }

  .rdp-day_selected:focus-visible {
    outline: none;
  }

  .rdp-button:focus-visible:not([disabled]) {
    border: none;
  }
`;

export const StyledSingleDayPicker = styled(StyledRangeDayPicker)`
  border-radius: ${({ theme }) => theme.radii.semiMedium};

  .rdp {
    --rdp-cell-size: 36px; /* Size of the day cells. */
    --rdp-background-color: unset;

    margin: 3px;
  }

  .rdp-dropdown {
    padding-left: 8px;
    padding-right: 8px;
  }

  .rdp-cell .rdp-day_selected {
    background-color: ${({ theme }) => theme.colors.neutral200};
  }

  .rdp-day {
    min-width: unset;
    min-height: unset;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: ${({ theme }) => theme.colors.neutral500};
  }
`;

export const StyledRangeDayPickerInputMask = styled(ReactInputMask)`
  border: 1px solid ${({ theme }) => theme.colors.neutral300};
  background-color: ${({ theme }) => theme.colors.neutral0};
  border-radius: ${({ theme }) => theme.radii.semiMedium};
  color: ${({ theme }) => theme.colors.neutral800};
  display: block;
  font-size: 14px;
  font-weight: 600;
  height: 44px;
  outline: 0;
  padding: 0 14px;
  max-width: 110px;
  text-align: center;

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral600};
    opacity: 0.6;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.grey1};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.neutral1000}24;
    cursor: not-allowed;
    border: none;
  }

  &:focus:not(:disabled) {
    border: 1px solid ${({ theme }) => theme.colors.neutral400};
  }

  &:focus-visible:not(:disabled) {
    border: 1px solid ${({ theme }) => theme.colors.neutral400};
  }

  &:hover:not(:disabled) {
    border: 1px solid ${({ theme }) => theme.colors.neutral400};
  }
`;
