import React, { useState } from "react";

import { useTranslation } from "context";
import { useAppDispatch, useAppSelector } from "store/store";

import { StyledModalWrapper } from "../styled";
import CloseModalWrapper from "../close-modal-wrapper";
import { Box, Image, Loader, Text } from "components";
import { VerificationProcess } from "./components";
import { VerificationFailed } from "pages/auth/login-with-yubikey-verification/components";

import { hideModal } from "store/modal";

import { selectCombinedData } from "./selectors";

const YubikeyVerificationModal = <T, R>(): JSX.Element => {
  const {
    propsForSignInCallback,
    signInActionCallback,
    signInSuccessCallback,
    pending,
    switchAnotherMethodVerificationCallback,
  } = useAppSelector(selectCombinedData<T, R>());

  const [isVerificationFailed, setIsVerificationFailed] = useState(false);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const tryAgainClick = () => {
    setIsVerificationFailed(false);
  };

  const verificationFailed = () => {
    setIsVerificationFailed(true);
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} mb="14px" />

      <Text textAlign="center" $fontWeight="medium" textScale="body2" color="neutral600">
        {t("Yubikey Verification")}
      </Text>

      <Box mt={{ _: "18px", tablet: "32px" }} mb="24px">
        {isVerificationFailed ? (
          <VerificationFailed
            verificationCallback={tryAgainClick}
            onSwitchMethodCallback={switchAnotherMethodVerificationCallback}
          />
        ) : (
          <VerificationProcess<T, R>
            signInSuccessCallback={signInSuccessCallback}
            verificationCallback={verificationFailed}
            signInActionCallback={signInActionCallback}
            propsForSignInCallback={propsForSignInCallback}
          />
        )}
      </Box>

      {pending ? (
        <Box mt="10px" width="100%" height="244px">
          <Loader width="100%" height="100%" />
        </Box>
      ) : (
        <Box mb="10px">
          <Image src="/images/yubikey/login-with-yubikey-process-image.webp" width="100%" aspectRatio={1.39} />
        </Box>
      )}
    </StyledModalWrapper>
  );
};

export default YubikeyVerificationModal;
