import { DefaultTheme } from "styled-components";

export const getTooltipStyles = (theme: DefaultTheme) => {
  return {
    tooltip: {
      padding: 0,
      border: "none",
      borderRadius: theme.radii.medium,
      maxWidth: "unset",
    },
    arrow: {
      display: "none",
    },
  };
};

export const getSingleDayPickerTooltipStyles = (theme: DefaultTheme) => {
  const res = getTooltipStyles(theme);

  return { ...res, tooltip: { ...res.tooltip, borderRadius: theme.radii.semiMedium, width: "100%" } };
};
