import { createAsyncThunk } from "@reduxjs/toolkit";
import { isErrorResult, makeApiRequest, ENDPOINTS_WALLET, ENDPOINTS_COLD_WALLET } from "services";
import { ErrorResult } from "services/types";

import { formatAmount } from "configs/web3";
import {
  WalletSingleItem,
  WalletsPayload,
  GetWalletsResponse,
  SuccessStatusResponse,
  DepositToColdWalletPayload,
  CancelColdWalletsWithdrawalPayload,
  SuccessResponse,
  SendCodesPayloadWithdraw,
  ConfirmWithdrawPayload,
} from "./types";

export const getWallets = createAsyncThunk<
  GetWalletsResponse,
  WalletsPayload,
  { rejectValue: ErrorResult & { type: WalletsPayload["type"] } }
>("wallets/get-wallets", async (params, { rejectWithValue }) => {
  const result = await makeApiRequest<WalletSingleItem[]>({
    method: "GET",
    url: ENDPOINTS_WALLET.wallets,
    params,
  });

  if (isErrorResult(result)) {
    return rejectWithValue({ ...result, type: params.type });
  }

  return { wallets: result, type: params.type };
});

export const depositToColdWallet = createAsyncThunk<
  SuccessStatusResponse,
  DepositToColdWalletPayload,
  { rejectValue: ErrorResult }
>("wallets/deposit-to-cold-wallet", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<SuccessStatusResponse>({
    method: "POST",
    url: ENDPOINTS_COLD_WALLET.createVault,
    data: formatAmount(data),
    timeout: 60_000,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const cancelColdWalletsWithdrawal = createAsyncThunk<
  SuccessStatusResponse,
  CancelColdWalletsWithdrawalPayload,
  { rejectValue: ErrorResult }
>("wallets/cancel-cold-wallets-withdrawal", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<SuccessStatusResponse>({
    method: "POST",
    url: ENDPOINTS_COLD_WALLET.cancelColdWalletsWithdrawal,
    data,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const sendCodesForWithdraw = createAsyncThunk<
  SuccessResponse,
  SendCodesPayloadWithdraw,
  { rejectValue: ErrorResult }
>("wallets/send-codes-for-withdraw", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<SuccessResponse>({
    method: "POST",
    url: ENDPOINTS_COLD_WALLET.sendCodesForWithdraw,
    data: formatAmount(data),
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const confirmWithdraw = createAsyncThunk<SuccessResponse, ConfirmWithdrawPayload, { rejectValue: ErrorResult }>(
  "wallets/confirm-withdraw",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<SuccessResponse>({
      method: "POST",
      url: ENDPOINTS_COLD_WALLET.confirmWithdraw,
      data: data.mitek ? { ...data, mitek: formatAmount(data.mitek) } : data,
      isShowError: false,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);
