import React, { useEffect } from "react";

import { HeaderProps } from "./type";
import { useMatchBreakpoints } from "hooks";

import { StyledHeaderContainer, StyledMenuIcon } from "./styled";
import { Text, Flex, Image, Skeleton, FlexGap, Logo } from "components";
import { HeaderMenuIcon } from "components/svg";
import { BurgerMenu, MobileMenu, ThemeToggle, Notifications } from "./components";

import { useAppDispatch, useAppSelector } from "store/store";
import { getUnreadNotificationCount } from "store/notifications/actions";

const Header: React.FC<HeaderProps> = ({
  isMenuOpen,
  isMobileMenuOpen,
  pageName,
  setIsMenuOpen,
  setIsMobileMenuOpen,
}) => {
  const user = useAppSelector(state => state.profile.user);
  const pending = useAppSelector(state => state.profile.pending);

  const dispatch = useAppDispatch();

  const { isLaptopL, isMobile } = useMatchBreakpoints();
  const imageUrl = user?.imageUrl;

  useEffect(() => {
    if (!isMobile) {
      setIsMobileMenuOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    dispatch(getUnreadNotificationCount());
  }, []);

  return (
    <StyledHeaderContainer id="header">
      {isMobile ? (
        <Logo size="small" />
      ) : (
        <Flex alignItems="center">
          {isLaptopL && (
            <StyledMenuIcon isMenuOpen={isMenuOpen} onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <HeaderMenuIcon width="24px" />
            </StyledMenuIcon>
          )}

          <Text color="neutral600" textScale="body1" $fontWeight="medium" px={{ _: "0px", tablet: "12px" }}>
            {pageName}
          </Text>
        </Flex>
      )}

      {isMobile ? <MobileMenu isMenuOpen={isMobileMenuOpen} setIsMenuOpen={setIsMobileMenuOpen} /> : null}

      <FlexGap gap={{ _: "24px", tablet: "34px" }} alignItems="center">
        <ThemeToggle />
        <Notifications />
        {imageUrl && !pending ? (
          <Image src={`${process.env.REACT_APP_MEDIA_URL}/${imageUrl}`} width="40px" aspectRatio={1} variant="circle" />
        ) : (
          <Skeleton width="40px" height="40px" variant="circle" />
        )}

        {isMobile && (
          <Flex alignItems="center">
            <BurgerMenu isMenuOpen={isMobileMenuOpen} setIsMenuOpen={setIsMobileMenuOpen} />
          </Flex>
        )}
      </FlexGap>
    </StyledHeaderContainer>
  );
};

export default Header;
