import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
import { isUserRejected } from "configs/web3";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [new BrowserTracing(), new Sentry.Replay()],
  environment: process.env.REACT_APP_ENV === "development" ? "development" : "production",
  tracesSampleRate: 0.5,
  beforeSend(event, hint) {
    if (window.location.hostname === "localhost") {
      return null;
    }

    const error = hint?.originalException;
    // For web3 wallets
    if (error && isUserRejected(error as Error & { code: number })) {
      return null;
    }
    return event;
  },
  // For solana wallet
  ignoreErrors: ["User rejected the request."],
});
