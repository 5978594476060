import styled from "styled-components";
import { Flex } from "../flex";
import { ColumnProps } from "../types";

export const contentWrapperPaddingDesktop = 24;
export const contentWrapperPaddingTablet = 18;
export const contentWrapperPaddingMobile = 12;

export const Column = styled(Flex)<ColumnProps>`
  flex-direction: column;
`;

export const ColumnBetween = styled(Column)<ColumnProps>`
  justify-content: space-between;
`;

export const StyledContentWrapper = styled(Column)`
  border-radius: 16px;
  background-color: ${({ theme }) => (theme.isDark ? theme.colors.neutral100 : theme.colors.neutral0)};
  position: relative;
  max-height: 100%;
  padding: ${() => `${contentWrapperPaddingMobile}px`};

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: ${() => `${contentWrapperPaddingTablet}px`};
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: ${() => `${contentWrapperPaddingDesktop}px`};
  }
`;
