import React, { useEffect } from "react";
import BigNumber from "bignumber.js";

import { useTranslation } from "context";
import { useSupport } from "hooks";

import { WalletErrorMessage, VaultInfo, StyledLink, Text } from "components";
import { ArrowRightIcon } from "components/svg";
import { WithdrawSection } from "./components";
import { StyledSideModalWrapper, RotateIconWrapper } from "../styled";
import CloseModalWrapper from "../close-modal-wrapper";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";
import { resetColdWalletFee } from "store/wallets/wallets-fee";

import { formatValueToBNString } from "configs/web3";

import { ColdStorageWithdrawModalProps } from "./types";

const ColdStorageWithdrawModal: React.FC = () => {
  const coldWallet = useAppSelector(
    state => (state.modal.props as unknown as ColdStorageWithdrawModalProps).coldWallet,
  );
  const networks = useAppSelector(state => state.hotWallets.networks);
  const data = useAppSelector(state => state.coldWallets.coldWallets.data);

  const isDebt =
    networks &&
    BigNumber(formatValueToBNString(networks[coldWallet.currency.chainId][coldWallet.currency.publicCode].debt)).gt(0);
  const actualColdWallet = data.find(wallet => wallet.id === coldWallet.id);
  const wallet = actualColdWallet ? actualColdWallet : coldWallet;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { supportHandler } = useSupport();

  useEffect(() => {
    dispatch(resetColdWalletFee());
  }, []);

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  return (
    <StyledSideModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} mb="38px">
        <>
          <RotateIconWrapper rotateUp>
            <ArrowRightIcon width="24px" height="24px" color="neutral600" />
          </RotateIconWrapper>

          <Text color="neutral600" textScale="body2" $fontWeight="medium" px="12px">
            {t("Withdraw")}
          </Text>
        </>
      </CloseModalWrapper>

      <VaultInfo wallet={wallet} />

      {isDebt ? (
        <WalletErrorMessage
          message={t("The withdrawal of funds is blocked due to the debt for the contents of the wallet.")}
        />
      ) : null}

      <WithdrawSection isDebt={isDebt} coldWallet={wallet} />

      <StyledLink
        color="neutral500"
        mt="18px"
        textScale="caption2"
        textAlign="center"
        $fontWeight="medium"
        onClick={() => supportHandler()}
        width="100%"
        justifyContent="center"
      >
        {t("Do you need help?")}
      </StyledLink>
    </StyledSideModalWrapper>
  );
};

export default ColdStorageWithdrawModal;
