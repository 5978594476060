import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";
import { ActionModalProps } from "./types";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => (state.modal.props as unknown as ActionModalProps).icon,
    (state: AppState) => (state.modal.props as unknown as ActionModalProps).title,
    (state: AppState) => (state.modal.props as unknown as ActionModalProps).text,
    (state: AppState) => (state.modal.props as unknown as ActionModalProps).btnArr,
    (state: AppState) => (state.modal.props as unknown as ActionModalProps).isLoading,
    (state: AppState) => (state.modal.props as unknown as ActionModalProps).linkInfo,
    (state: AppState) => (state.modal.props as unknown as ActionModalProps).isShowTitle,
    (state: AppState) => (state.modal.props as unknown as ActionModalProps).icon,
    (state: AppState) => state.modal.clickOutsideHandler,
  ],
  (icon, title, text, btnArr, isLoading, linkInfo, isShowTitle, showCloseModalIcon, clickOutsideHandler) => ({
    icon,
    title,
    text,
    btnArr,
    isLoading,
    linkInfo,
    isShowTitle,
    showCloseModalIcon,
    clickOutsideHandler,
  }),
);
