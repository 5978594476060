import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral500", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" color="transparent" stroke={theme.colors[color]} {...props}>
      <path
        d="M9.67969 4.942C12.2687 2.352 16.4677 2.353 19.0577 4.942C21.6477 7.531 21.6477 11.73 19.0577 14.32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.264 14.368C16.264 18.031 13.295 21 9.632 21C5.969 21 3 18.031 3 14.368C3 10.705 5.969 7.73599 9.632 7.73599C13.295 7.73599 16.264 10.705 16.264 14.368"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.63184 11.32L12.6321 14.3202L9.63184 17.3204L6.63161 14.3202L9.63184 11.32Z"
        strokeWidth="1.49999"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
