import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = props => {
  return (
    <Svg viewBox="0 0 45 42" {...props}>
      <path
        d="M41.9978 1L25.1447 13.5032L28.2746 6.12661L41.9978 1Z"
        fill="#E17726"
        stroke="#E17726"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.1322 1L19.832 13.6215L16.8554 6.12661L3.1322 1Z"
        fill="#E27625"
        stroke="#E27625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.9398 29.9844L31.4565 36.8543L41.0498 39.4926L43.7914 30.136L35.9398 29.9844Z"
        fill="#E27625"
        stroke="#E27625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.35547 30.136L4.08044 39.4926L13.6737 36.8543L9.20705 29.9844L1.35547 30.136Z"
        fill="#E27625"
        stroke="#E27625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1483 18.3754L10.475 22.4204L19.9849 22.8437L19.6632 12.6055L13.1483 18.3754Z"
        fill="#E27625"
        stroke="#E27625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.9812 18.3765L25.3647 12.4883L25.1447 22.8448L34.6545 22.4215L31.9812 18.3765Z"
        fill="#E27625"
        stroke="#E27625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6735 36.857L19.4101 34.0637L14.4685 30.207L13.6735 36.857Z"
        fill="#E27625"
        stroke="#E27625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7196 34.0637L31.4562 36.857L30.6612 30.207L25.7196 34.0637Z"
        fill="#E27625"
        stroke="#E27625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.4562 36.8558L25.7196 34.0625L26.1763 37.8025L26.1263 39.3758L31.4562 36.8558Z"
        fill="#D5BFB2"
        stroke="#D5BFB2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6735 36.8558L19.0034 39.3758L18.9701 37.8025L19.4101 34.0625L13.6735 36.8558Z"
        fill="#D5BFB2"
        stroke="#D5BFB2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1047 27.7324L14.3331 26.329L17.6998 24.7891L19.1047 27.7324Z"
        fill="#233447"
        stroke="#233447"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.0251 27.7324L27.4301 24.7891L30.8134 26.329L26.0251 27.7324Z"
        fill="#233447"
        stroke="#233447"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6735 36.8543L14.5018 29.9844L9.20691 30.136L13.6735 36.8543Z"
        fill="#CC6228"
        stroke="#CC6228"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.6277 29.9844L31.456 36.8543L35.9393 30.136L30.6277 29.9844Z"
        fill="#CC6228"
        stroke="#CC6228"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.6545 22.4219L25.1447 22.8452L26.0246 27.7335L27.4296 24.7902L30.8129 26.3302L34.6545 22.4219Z"
        fill="#CC6228"
        stroke="#CC6228"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3333 26.3302L17.6999 24.7902L19.1049 27.7335L19.9849 22.8452L10.475 22.4219L14.3333 26.3302Z"
        fill="#CC6228"
        stroke="#CC6228"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.475 22.4219L14.4683 30.2051L14.3333 26.3302L10.475 22.4219Z"
        fill="#E27525"
        stroke="#E27525"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.8134 26.3302L30.6617 30.2051L34.655 22.4219L30.8134 26.3302Z"
        fill="#E27525"
        stroke="#E27525"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.9853 22.8438L19.1053 27.732L20.222 33.502L20.4587 25.9054L19.9853 22.8438Z"
        fill="#E27525"
        stroke="#E27525"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1453 22.8438L24.6886 25.8887L24.9086 33.502L26.0252 27.732L25.1453 22.8438Z"
        fill="#E27525"
        stroke="#E27525"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.0251 27.7354L24.9084 33.5053L25.7201 34.0636L30.6617 30.207L30.8134 26.332L26.0251 27.7354Z"
        fill="#F5841F"
        stroke="#F5841F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3331 26.332L14.4681 30.207L19.4097 34.0636L20.2214 33.5053L19.1047 27.7354L14.3331 26.332Z"
        fill="#F5841F"
        stroke="#F5841F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.1267 39.3754L26.1767 37.8021L25.755 37.4305H19.3751L18.9701 37.8021L19.0034 39.3754L13.6735 36.8555L15.5351 38.3771L19.3251 41.0004H25.805L29.595 38.3771L31.4566 36.8555L26.1267 39.3754Z"
        fill="#C0AC9D"
        stroke="#C0AC9D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7197 34.0622L24.908 33.5039H20.2214L19.4097 34.0622L18.9697 37.8022L19.3747 37.4305H25.7547L26.1763 37.8022L25.7197 34.0622Z"
        fill="#161616"
        stroke="#161616"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.7077 14.3165L44.1294 7.41326L41.9978 1L25.7196 13.0815L31.9812 18.3765L40.8294 20.9664L42.7761 18.6815L41.9294 18.0731L43.2827 16.8365L42.2511 16.0248L43.6044 14.9932L42.7077 14.3165Z"
        fill="#763E1A"
        stroke="#763E1A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 7.41326L2.43832 14.3165L1.525 14.9932L2.87831 16.0248L1.84666 16.8365L3.19998 18.0731L2.35332 18.6815L4.29996 20.9664L13.1482 18.3765L19.4098 13.0815L3.13164 1L1 7.41326Z"
        fill="#763E1A"
        stroke="#763E1A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.83 20.965L31.9817 18.375L34.655 22.42L30.6617 30.2032L35.94 30.1349H43.7916L40.83 20.965Z"
        fill="#F5841F"
        stroke="#F5841F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1487 18.375L4.30044 20.965L1.35547 30.1349H9.20705L14.4687 30.2032L10.4754 22.42L13.1487 18.375Z"
        fill="#F5841F"
        stroke="#F5841F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.1451 22.8431L25.7201 13.0799L28.2751 6.125H16.8552L19.4102 13.0799L19.9852 22.8431L20.2052 25.9214L20.2219 33.5014H24.9085L24.9251 25.9214L25.1451 22.8431Z"
        fill="#F5841F"
        stroke="#F5841F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default Icon;
