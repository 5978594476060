import BigNumber from "bignumber.js";

import { formatValueToBNString } from "configs/web3";

import { WalletSingleItem } from "store/wallets/types";

export const getTotalBalance = (arr: WalletSingleItem[], type: "fiat" | "crypto") => {
  const res = arr.reduce((sum, elem) => {
    return type === "crypto"
      ? sum.plus(elem.availableBalance)
      : BigNumber(elem.availableBalance).times(elem.marketInfo[0].price).plus(sum);
  }, BigNumber(0));

  return formatValueToBNString(res);
};
