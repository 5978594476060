import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { getStatementItem, getStatementTransactions } from "./actions";

import { StatementItemState, StatementQueryPayload, StatementTokenItem } from "./types";
import { CurrencyItem } from "store/tokens/types";

const initialState: StatementItemState = {
  error: null,
  pending: false,
  statementItem: null,
  statementTokens: [],
  transactionPayload: {
    page: 1,
    size: 10,
  },
  statementTransactions: {
    pending: false,
    data: [],
    count: 0,
  },
};

const sortStatementTokens = (tokens: CurrencyItem[]) => {
  return tokens.reduce((acc: StatementTokenItem[], currency) => {
    const existedNetworkTokens = acc.find(item => item.chainId === currency.chainId);
    if (existedNetworkTokens) {
      const filteredStatementTokens = acc.filter(item => item.chainId !== currency.chainId);
      return [
        ...filteredStatementTokens,
        { ...existedNetworkTokens, tokens: [...existedNetworkTokens.tokens, currency] },
      ];
    } else {
      return [...acc, { chainId: currency.chainId, tokens: [currency] }];
    }
  }, []);
};

const statementInfoSlice = createSlice({
  name: "statementInfo",
  initialState: {
    ...initialState,
  },
  reducers: {
    resetStatementInfoState: () => {
      return initialState;
    },
    setStatementTransactionsPayload: (state, action: PayloadAction<Partial<StatementQueryPayload>>) => {
      state.transactionPayload = { ...state.transactionPayload, ...action.payload };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getStatementItem.fulfilled, (state, action) => {
        state.pending = false;
        state.statementItem = action.payload;
        state.statementTokens = sortStatementTokens(action.payload.currencies);
        state.error = null;
      })
      .addCase(getStatementItem.pending, state => {
        state.pending = true;
      })
      .addCase(getStatementItem.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.statementItem = null;
          state.pending = false;
        }
      })
      .addCase(getStatementTransactions.fulfilled, (state, action) => {
        state.statementTransactions = { ...action.payload, pending: false };

        state.error = null;
      })
      .addCase(getStatementTransactions.pending, state => {
        state.statementTransactions.pending = true;
      })
      .addCase(getStatementTransactions.rejected, (state, action) => {
        if (action.payload) {
          state.error = action.payload;
          state.statementTransactions = initialState.statementTransactions;
        }
      });
  },
});
export const { resetStatementInfoState, setStatementTransactionsPayload } = statementInfoSlice.actions;

export default statementInfoSlice;
