import * as yup from "yup";
import BigNumber from "bignumber.js";

import { useTranslation } from "context";
import { useAppSelector } from "store/store";
import { useGetFiatSymbolAndAbbreviation, useGetCurrencyRate } from "hooks";

import { InitialValuesType } from "./types";

import { formatValueToBNString } from "configs/web3";
import { getTokenFromNetworks } from "utils/helpers";
import { selectCombinedData } from "./selectors";

export const initialValues: InitialValuesType = {
  amount: "",
};

export const useValidationSchema = () => {
  const { coldWallet, networks, minAmountToCreateInFiat, minAmountToDepositInFiat } =
    useAppSelector(selectCombinedData);

  const { t } = useTranslation();
  const { fiatSymbol } = useGetFiatSymbolAndAbbreviation();

  const currencyCode = coldWallet.currency.publicCode;

  const { currentRate } = useGetCurrencyRate(currencyCode);

  const tokenBE = getTokenFromNetworks(networks, coldWallet.currency.chainId, coldWallet.currency.publicCode);

  const tokenBalance = tokenBE?.balance ?? "0";

  const validationSchema = yup.object().shape({
    amount: yup
      .string()
      .required(
        t("Deposit amount has to be greater than %amount%", {
          amount: `${fiatSymbol}${BigNumber(minAmountToDepositInFiat).toFormatExtended(2)}`,
        }),
      )
      .test("balanceExceeded", `${t("The amount of the deposit exceeds the balance of your wallet")}`, value => {
        const _value = value ?? "0";

        return BigNumber(formatValueToBNString(_value)).lte(tokenBalance);
      })
      .test(
        "minAmount",
        `${t("Deposit amount has to be greater than %amount%", {
          amount: `${fiatSymbol}${BigNumber(minAmountToDepositInFiat).toFormatExtended(2)}`,
        })}`,
        value => {
          const _value = value ?? "0";
          const amount = formatValueToBNString(BigNumber(currentRate).times(_value));

          return BigNumber(amount).gte(minAmountToDepositInFiat);
        },
      )
      .test(
        "minAmountWithZeroBalance",
        `${t("In case of a balance less than %amount%, the minimum deposit must be at least %amount%", {
          amount: `${fiatSymbol}${BigNumber(minAmountToCreateInFiat).toFormatExtended(2)}`,
        })}`,
        value => {
          const _value = value ?? "0";
          const availableBalance = formatValueToBNString(coldWallet.availableBalance);

          if (BigNumber(availableBalance).times(currentRate).lt(minAmountToCreateInFiat)) {
            const amountInFee = formatValueToBNString(BigNumber(_value).times(currentRate));

            return BigNumber(amountInFee).gte(minAmountToCreateInFiat);
          }

          return true;
        },
      ),
  });

  return { validationSchema, initialValues };
};
