import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = ({ color = "transparent", ...props }) => {
  return (
    <Svg viewBox="0 0 72 72" {...props} color={color}>
      <g opacity="0.25" filter="url(#filter0_f_1906_68545)">
        <rect x="18" y="49" width="36" height="12" rx="4" fill="#50CF7C" />
      </g>
      <circle cx="36" cy="36" r="28" fill="#ECF8EF" />
      <circle cx="36" cy="36" r="27.5" stroke="#50CF7C" strokeOpacity="0.5" />
      <path
        d="M38.2905 34.6875V32.6655C38.2905 31.401 37.2645 30.375 36 30.375V30.375C34.7355 30.375 33.7095 31.401 33.7095 32.6655V34.6875"
        stroke="#50CF7C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.625 34.6875H33.375C32.547 34.6875 31.875 35.3595 31.875 36.1875V39.3735C31.875 40.2015 32.547 40.8735 33.375 40.8735H38.625C39.453 40.8735 40.125 40.2015 40.125 39.3735V36.1875C40.125 35.3595 39.453 34.6875 38.625 34.6875Z"
        stroke="#50CF7C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48 34.8626C48 41.4146 43.2645 47.5556 36.78 49.3916C36.273 49.5356 35.727 49.5356 35.22 49.3916C28.7355 47.5571 24 41.4146 24 34.8626V28.8206C24 27.6026 24.7365 26.5046 25.8645 26.0441L33.1605 23.0591C34.9815 22.3136 37.0215 22.3136 38.841 23.0591L46.137 26.0441C47.2635 26.5046 48 27.6026 48 28.8206V34.8626Z"
        stroke="#50CF7C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle opacity="0.18" cx="36" cy="36" r="35.75" stroke="#50CF7C" strokeWidth="0.5" />
      <defs>
        <filter
          id="filter0_f_3282_85487"
          x="0"
          y="31"
          width="72"
          height="53"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur_3282_85487" />
        </filter>
      </defs>
    </Svg>
  );
};

export default Icon;
