import React from "react";

import { useGetEscrowParticipants } from "pages/main/escrow-process/hooks";
import { useTranslation } from "context";

import { Text, Column, TabPanel } from "components";

import { useAppSelector } from "store/store";

import { Roles } from "pages/main/create-escrow/types";
import { TabsProps } from "./types";

import { TABS } from "configs";

const Tabs: React.FC<TabsProps> = ({ onTabClick }) => {
  const currentEscrow = useAppSelector(state => state.escrowProcess.currentEscrow);
  const coversBrokersCommissionTab = useAppSelector(state => state.createEscrow.coversBrokersCommissionTab);
  const coversEscryptoFeeTab = useAppSelector(state => state.createEscrow.coversEscryptoFeeTab);

  const { t } = useTranslation();

  const { broker, userRole } = useGetEscrowParticipants();

  return (
    <>
      {currentEscrow && (
        <>
          <Column mb="24px">
            <Text textScale="caption1" color="neutral500" mb="8px">
              {t("Who covers Zert fee?")}
            </Text>

            <TabPanel
              tabs={TABS.createEscrowCommissionPayer}
              activeTab={coversEscryptoFeeTab}
              onTabClick={tab => onTabClick(tab, true)}
              maxWidth={{ _: "100%", laptop: "580px" }}
              disabled={currentEscrow.body.head.isWidget}
              dontScrollTop
            />
          </Column>

          {broker && userRole === Roles.broker ? (
            <Column mb="24px">
              <Text textScale="caption1" color="neutral500" mb="8px">
                {t("Broker's commission is paid by")}
              </Text>

              <TabPanel
                tabs={TABS.createEscrowCommissionPayer}
                activeTab={coversBrokersCommissionTab}
                onTabClick={tab => onTabClick(tab)}
                maxWidth={{ _: "100%", laptop: "580px" }}
                disabled={currentEscrow.body.head.isWidget}
                dontScrollTop
              />
            </Column>
          ) : null}
        </>
      )}
    </>
  );
};

export default Tabs;
