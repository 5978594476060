import React, { useEffect } from "react";

import { useKycHandler, useTwoFaLastUpdateCheck } from "hooks";
import { useGetEscrowParticipants } from "pages/main/escrow-process/hooks";
import { useTranslation } from "context";
import { getAddress } from "configs/web3";

import { Button, Column, FlexGap, Text, WalletErrorMessage } from "components";
import { EditIcon } from "components/svg";

import { isErrorResult } from "services";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal, partialUpdateModalProps, showModal } from "store/modal";
import { setEscrowDeliveryAddress } from "store/escrow/escrow-process/actions";
import { getAddressesList } from "store/addresses/actions";

import { DeliveryAddressesSideModalProps } from "components/modal/components/delivery-addresses-modal/types";
import { AddressesSideModalProps } from "components/modal/components/addresses-side-modal/types";
import { ModalProps, PartialUpdateModalProps } from "store/modal/types";
import { AddressResponse } from "store/addresses/types";
import { Roles } from "pages/main/create-escrow/types";
import { EscrowStatusEnum } from "store/escrow/enums";

const DeliveryAddress: React.FC = () => {
  const currentEscrow = useAppSelector(state => state.escrowProcess.currentEscrow);
  const currentStepNumber = useAppSelector(state => state.escrowProcess.currentStepNumber);
  const addressesList = useAppSelector(state => state.addresses.addressesList);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { callbackWithKycCheck } = useKycHandler({ shouldCheckStatus: false });
  const { callbackWithTwoFaLastUpdateCheck } = useTwoFaLastUpdateCheck({ rootId: "escrow-process" });

  const { userRole } = useGetEscrowParticipants();

  useEffect(() => {
    dispatch(getAddressesList());
  }, []);

  const saveCallBack = (address: AddressResponse | undefined) => {
    if (address && currentEscrow) {
      const data = {
        id: currentEscrow?.body.id,
        addressId: address.id,
      };
      dispatch(setEscrowDeliveryAddress(data)).then(res => {
        if (!isErrorResult(res.payload)) {
          dispatch(hideModal());
        }
      });
    }
  };

  const handleAddressesSideModal = () => {
    const _showModal = showModal as ModalProps<AddressesSideModalProps>;

    dispatch(
      _showModal({
        modalName: "addressesSideModal",
        modalType: "rightMenu",
        rootId: "escrow-process",
        props: {
          saveCallBack: address => saveCallBack(address),
        },
      }),
    );
  };

  const setAddressHandler = async (address?: AddressResponse) => {
    const _partialProps = partialUpdateModalProps as PartialUpdateModalProps<DeliveryAddressesSideModalProps>;

    if (address) {
      saveCallBack(address);
      dispatch(_partialProps({ currentAddress: address }));
      dispatch(hideModal());
    }
  };

  const showDeliveryAddressesSideModal = () => {
    const _showModal = showModal as ModalProps<DeliveryAddressesSideModalProps>;

    dispatch(
      _showModal({
        modalName: "deliveryAddressesSideModal",
        modalType: "rightMenu",
        rootId: "escrow-process",
        props: {
          currentAddress: currentEscrow?.body.delivery.address
            ? //   overwriting the id field for visual selection of the correct item
              { ...currentEscrow.body.delivery.address, id: currentEscrow.body.delivery.address.userAddressId }
            : null,
          setAddressHandler,
        },
      }),
    );
  };

  const twoFaLastUpdateCheck = () => {
    callbackWithTwoFaLastUpdateCheck(addressesList.length ? showDeliveryAddressesSideModal : handleAddressesSideModal);
  };

  const addAddressHandler = () => {
    callbackWithKycCheck(twoFaLastUpdateCheck);
  };

  return (
    <Column mb="32px">
      <Text textScale="hairlineS" color="neutral500" textTransform="uppercase" $fontWeight="medium" mb="14px">
        {t("Delivery Address")}
      </Text>

      {currentEscrow?.body.delivery.address ? (
        <FlexGap gap="14px" justifyContent="space-between">
          <Text textScale="caption1" color="neutral800" wordBreak="break-word">
            {getAddress(currentEscrow?.body.delivery.address)}
          </Text>

          {userRole === Roles.buyer &&
            currentStepNumber === 1 &&
            currentEscrow?.status !== EscrowStatusEnum.CANCELED && (
              <EditIcon width="24px" height="24px" cursor="pointer" onClick={showDeliveryAddressesSideModal} />
            )}
        </FlexGap>
      ) : userRole === Roles.buyer && currentStepNumber === 1 ? (
        <Button
          variant="outline"
          onClick={addAddressHandler}
          disabled={currentEscrow?.status === EscrowStatusEnum.CANCELED}
        >
          {t(`${addressesList.length ? "Add Address" : "+ Add New Address"}`)}
        </Button>
      ) : currentEscrow?.status !== EscrowStatusEnum.CANCELED ? (
        <WalletErrorMessage message={t("Buyer should modify escrow and provide shipping address")} variant="warning" />
      ) : null}
    </Column>
  );
};

export default DeliveryAddress;
