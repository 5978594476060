import React from "react";

import { useTranslation } from "context";

import { Box, Text } from "components";
import { StyledModalWrapper } from "../styled";
import CloseModalWrapper from "../close-modal-wrapper";
import { StyledCard } from "./styled";

import { useAppDispatch, useAppSelector } from "store/store";
import { hideModal } from "store/modal";

import { DepositChooseWalletModalProps } from "./types";

import { useProviders } from "configs/web3";

const DepositChooseWalletModal: React.FC = () => {
  const network = useAppSelector(state => (state.modal.props as unknown as DepositChooseWalletModalProps).network);
  const onClick = useAppSelector(state => (state.modal.props as unknown as DepositChooseWalletModalProps).onClick);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { connections } = useProviders(network);

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const onCardClick: DepositChooseWalletModalProps["onClick"] = provider => {
    onClick(provider);
    closeModalHandler();
  };

  return (
    <StyledModalWrapper>
      <CloseModalWrapper closeModalHandler={closeModalHandler} pb="24px">
        <Text textAlign="start" color="neutral600" textScale="body2" $fontWeight="bold" wordBreak="break-all">
          {t("Choose a wallet")}
        </Text>
      </CloseModalWrapper>

      {connections.map(connection => {
        const { getName, getIcon } = connection;
        const Icon = getIcon?.();
        return (
          <StyledCard key={getName()} onClick={() => onCardClick(connection)}>
            <Box minWidth="40px" minHeight="40px">
              {Icon && <Icon width="40px" height="40px" />}
            </Box>

            <Box ellipsis>
              <Text color="neutral600" textScale="body3" $fontWeight="bold" ellipsis>
                {getName()}
              </Text>
            </Box>
          </StyledCard>
        );
      })}
    </StyledModalWrapper>
  );
};

export default DepositChooseWalletModal;
