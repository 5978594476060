import React, { useEffect, useState } from "react";
import { isEqual } from "lodash";

import { useAppDispatch, useAppSelector } from "store/store";
import { useTranslation } from "context";
import { useHandleFilters } from "components/modal/components/widget-buyers-list-side-modal/hooks";

import { Box, Button, MobileRangeDateSelect, RadioButton, RadioButtonGroup, Text } from "components";
import { ArrowRightIcon } from "components/svg";
import CloseModalWrapper from "components/modal/components/close-modal-wrapper";
import {
  StyledConfirmContainer,
  StyledFilterSectionTitle,
  StyledSideModalWrapper,
} from "components/modal/components/styled";

import { hideModal } from "store/modal";

import { SingleRadioOption } from "components/radio-button/types";

const FiltersSection: React.FC<{ showFiltersModal: () => void }> = ({ showFiltersModal }) => {
  const dateFrom = useAppSelector(state => state.escrowsTable.widgetBuyersListPayload.dateFrom);
  const dateTo = useAppSelector(state => state.escrowsTable.widgetBuyersListPayload.dateTo);
  const status = useAppSelector(state => state.escrowsTable.widgetBuyersListPayload.status);

  const [dates, setDates] = useState({ dateFrom, dateTo });

  const { t } = useTranslation();

  const { selectsList, applyHandler } = useHandleFilters();

  const dispatch = useAppDispatch();

  const stateFiltersObj = {
    dateFrom: dates.dateFrom,
    dateTo: dates.dateTo,
    status: selectsList[0].value.value ? selectsList[0].value.value : undefined,
  };

  const storeFiltersObj = {
    dateFrom,
    dateTo,
    status,
  };

  useEffect(() => {
    if (!dateFrom && !dateTo) {
      setDates({ dateFrom: undefined, dateTo: undefined });
    }
  }, [dateFrom, dateTo]);

  const closeModalHandler = () => {
    dispatch(hideModal());
  };

  const handleApplyFilters = () => {
    showFiltersModal();
    applyHandler(dates);
  };

  return (
    <StyledSideModalWrapper height="calc(100dvh - 92px)">
      <Box>
        <CloseModalWrapper closeModalHandler={closeModalHandler} mb="56px">
          <ArrowRightIcon
            width="24px"
            height="24px"
            color="neutral600"
            rotate="180deg"
            cursor="pointer"
            onClick={showFiltersModal}
          />

          <Text textScale="body2" $fontWeight="medium" color="neutral600" mx="16px">
            {t("Filters")}
          </Text>
        </CloseModalWrapper>

        <Box mb="24px">
          <StyledFilterSectionTitle textScale="body3">{t("Date")}</StyledFilterSectionTitle>

          <Box mt="20px">
            <MobileRangeDateSelect dates={dates} setDates={setDates} />
          </Box>
        </Box>

        <Box borderTop="1px solid" borderColor="neutral200">
          <Box pt="24px">
            {selectsList.map(({ value, handler, option, name }, index) => {
              const { value: selectedValue } = value;
              return (
                <Box key={index} mb="24px">
                  <StyledFilterSectionTitle textScale="body3">{t(name)}</StyledFilterSectionTitle>

                  <RadioButtonGroup
                    selectedValue={selectedValue}
                    onClickRadioButton={handler}
                    name={name}
                    mb="32px"
                    mt="20px"
                  >
                    {(option as SingleRadioOption[]).map(({ value, label, name }, index) => {
                      return (
                        <RadioButton
                          name={name}
                          key={index}
                          value={value}
                          label={label}
                          width="100%"
                          mb={option.length - 1 !== index ? "16px" : ""}
                        />
                      );
                    })}
                  </RadioButtonGroup>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>

      <StyledConfirmContainer>
        <Button width="100%" onClick={handleApplyFilters} disabled={isEqual(stateFiltersObj, storeFiltersObj)}>
          {t("Apply")}
        </Button>
      </StyledConfirmContainer>
    </StyledSideModalWrapper>
  );
};

export default FiltersSection;
