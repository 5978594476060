import { Loader } from "components";
import { lazyLoad } from "utils/loadable";

export const Overview = lazyLoad(
  () => import("./overview"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const CreateVault = lazyLoad(
  () => import("./create-vault"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);

export const History = lazyLoad(
  () => import("./history"),
  module => module.default,
  { fallback: <Loader height="100%" width="100%" /> },
);
