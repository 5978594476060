import React from "react";

import { Text } from "components";
import { StyledList, StyledNote, StyledWrapper } from "./styled";

import { ListProps } from "./types";

const List: React.FC<ListProps> = ({ notes, textColor, noteColor, bgColor, ...props }) => {
  return (
    <StyledWrapper bgColor={bgColor} {...props}>
      <StyledList>
        {notes.map((note, i) => {
          return (
            <StyledNote key={i} noteColor={noteColor}>
              <Text as="span" color={textColor} textScale={{ _: "caption2", tablet: "caption1" }}>
                {note}
              </Text>
            </StyledNote>
          );
        })}
      </StyledList>
    </StyledWrapper>
  );
};

export default List;
