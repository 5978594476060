export const SOCKET_EVENT_NAMES = {
  complete_kyc: "complete_kyc",
  exception: "exception",
  update_current_user: "update_current_user",
  balance_updated: "balance_updated",
  currencies_rates_updated: "currencies_rates_updated",
  swap_updated: "swap_updated",
  crypto_purchase_updated: "crypto_purchase_updated",
  wallet_history_updated: "wallet_history_updated",
  kicked: "kicked",
  receive_message: "receive_message",
  send_message: "send_message",
  created_message: "created_message",
  send_message_exception: "send_message_exception",
  transaction_completed: "transaction_completed",
  read_message: "read_message",
  read_message_update: "read_message_update",
  create_escrow: "create_escrow",
  update_escrow: "update_escrow",
  notification: "notification",
  unread_notification_count: "unread_notification_count",
};
