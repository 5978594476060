export interface VoiceCaptureModalProps {
  title: string;
  microphoneStatus: microphoneStatusEnum;
  verifyDescription?: string;
  verifyCallback?: () => void;
  closeCallback?: () => void;
}

export enum microphoneStatusEnum {
  LOADING = "LOADING",
  LOADED = "LOADED",
  RECORDING = "RECORDING",
}
