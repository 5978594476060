import React from "react";

import { Box } from "components";
import { CommissionRow } from "./components";

import { CommissionsProps } from "./types";

const Commissions: React.FC<CommissionsProps> = ({ commissionsList, showIcon = true, ...props }) => {
  return (
    <Box {...props}>
      {commissionsList.map((item, index) => {
        return (
          <CommissionRow
            commissionsName={item.commissionsName}
            commissionsValue={item.commissionsValue}
            tooltipText={item.tooltipText}
            showIcon={showIcon}
            pending={item.pending}
            key={index}
          />
        );
      })}
    </Box>
  );
};

export default Commissions;
