import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "store/store";

export const selectCombinedData = createSelector(
  [
    (state: AppState) => state.coldWallets.pending,
    (state: AppState) => state.hotWallets.networks,
    (state: AppState) => state.walletsFee.coldWalletFee,
    (state: AppState) => state.walletsFee.fiatFeeColdStorage.minAmountToDeposit.inFiat,
    (state: AppState) => state.walletsFee.fiatFeeColdStorage.minAmountToCreate.inFiat,
  ],
  (pending, networks, coldWalletFee, minAmountToDepositInFiat, minAmountToCreateInFiat) => ({
    pending,
    networks,
    coldWalletFee,
    minAmountToDepositInFiat,
    minAmountToCreateInFiat,
  }),
);
