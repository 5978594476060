import { createAsyncThunk } from "@reduxjs/toolkit";

import { isErrorResult, makeApiRequest } from "services";
import { ENDPOINTS_CREATE_ESCROW } from "services/endpoints";

import { ErrorResult } from "services/types";
import {
  EstimateEscrowFeeRequest,
  EstimateEscrowFeeResponse,
  GetParticipantRequest,
  GetParticipantResponse,
  CreateEscrowRequest,
  CreateEscrowResponse,
  CreateWidgetResponse,
  CreateWidgetRequest,
  EscrowMinAmountResponse,
} from "./types";

export const estimateEscrowFee = createAsyncThunk<
  EstimateEscrowFeeResponse,
  EstimateEscrowFeeRequest,
  { rejectValue: ErrorResult }
>("create-escrow/estimate-escrow-fee", async (data, { rejectWithValue }) => {
  const result = await makeApiRequest<EstimateEscrowFeeResponse>({
    method: "POST",
    url: ENDPOINTS_CREATE_ESCROW.estimateEscrowFee,
    data,
    isShowError: false,
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const getParticipant = createAsyncThunk<
  GetParticipantResponse,
  GetParticipantRequest,
  { rejectValue: ErrorResult }
>("create-escrow/get-participant", async (email, { rejectWithValue }) => {
  const result = await makeApiRequest<GetParticipantResponse>({
    method: "GET",
    url: ENDPOINTS_CREATE_ESCROW.getParticipant,
    params: { email },
  });

  if (isErrorResult(result)) {
    return rejectWithValue(result);
  }

  return result;
});

export const createEscrow = createAsyncThunk<CreateEscrowResponse, CreateEscrowRequest, { rejectValue: ErrorResult }>(
  "create-escrow/create-escrow",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<CreateEscrowResponse>({
      method: "POST",
      url: ENDPOINTS_CREATE_ESCROW.createEscrow,
      data,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const createWidget = createAsyncThunk<CreateWidgetResponse, CreateWidgetRequest, { rejectValue: ErrorResult }>(
  "create-escrow/create-widget",
  async (data, { rejectWithValue }) => {
    const result = await makeApiRequest<CreateWidgetResponse>({
      method: "POST",
      url: ENDPOINTS_CREATE_ESCROW.createWidget,
      data,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);

export const getEscrowMinAmount = createAsyncThunk<EscrowMinAmountResponse, undefined, { rejectValue: ErrorResult }>(
  "create-escrow/get-escrow-min-amount",
  async (_, { rejectWithValue }) => {
    const result = await makeApiRequest<EscrowMinAmountResponse>({
      method: "GET",
      url: ENDPOINTS_CREATE_ESCROW.escrowMinAmount,
    });

    if (isErrorResult(result)) {
      return rejectWithValue(result);
    }

    return result;
  },
);
