import { Web3Provider } from "@ethersproject/providers";
import BigNumber from "bignumber.js";
import { NATIVE_ADDRESS_SOLANA } from "configs/solana";

import { Currency, formatValueToBNString, NATIVE_ADDRESS } from "configs/web3";

// account is not optional
export const getSigner = (library: Web3Provider, account: string) => {
  return library.getSigner(account).connectUnchecked();
};

// account is optional
export const getProviderOrSigner = (library: Web3Provider, account?: string | null | undefined) => {
  return account ? getSigner(library, account) : library;
};

export const isExceededBalance = ({
  currency,
  tokenBalance,
  nativeBalance,
  gasEstimation,
  value,
}: {
  currency: Currency | undefined | null;
  tokenBalance: string;
  nativeBalance: string;
  gasEstimation: string;
  value: string;
}) => {
  if (!currency) {
    return true;
  }

  const isNative = currency.address.toLowerCase() === NATIVE_ADDRESS;
  const isNativeSolana = currency.address.toLowerCase() === NATIVE_ADDRESS_SOLANA;

  if (isNative || isNativeSolana) {
    return BigNumber(formatValueToBNString(BigNumber(gasEstimation).plus(value))).gt(nativeBalance);
  } else {
    return (
      BigNumber(formatValueToBNString(tokenBalance)).lt(value) ||
      BigNumber(formatValueToBNString(nativeBalance)).lt(gasEstimation)
    );
  }
};
