import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useInterval } from "hooks";

export const useTimer = ({ timestamp, stopTimer }: { timestamp: number; stopTimer?: boolean }) => {
  const initTimeRemaining = useMemo(
    () => (timestamp === 0 ? 0 : +(timestamp - moment().valueOf() / 1000).toFixed()),
    [timestamp],
  );

  const [remainingSeconds, setRemainingSeconds] = useState(-1);

  useEffect(() => {
    setRemainingSeconds(initTimeRemaining);
  }, [timestamp, initTimeRemaining]);

  const updateRemainingSeconds = useCallback(() => {
    setRemainingSeconds(prev => {
      return prev - 1;
    });
  }, []);

  useInterval(updateRemainingSeconds, remainingSeconds === 0 || stopTimer ? null : 1 * 1000);

  return { initTimeRemaining, remainingSeconds };
};
