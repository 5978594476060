import React from "react";
import { Svg } from "components";
import { SvgProps } from "../types";
import { useThemeContext } from "context";

const Icon: React.FC<SvgProps> = ({ color = "neutral800", ...props }) => {
  const { theme } = useThemeContext();

  return (
    <Svg viewBox="0 0 24 24" {...props} color="transparent" stroke={theme.colors[color]}>
      <path
        d="M8.99888 14.0017L6.99805 12.0008L8.99888 10"
        stroke={theme.colors[color]}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0015 10L17.0023 12.0008L15.0015 14.0017"
        stroke={theme.colors[color]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="2.99634"
        y="2.99609"
        width="18.0075"
        height="18.0075"
        rx="5"
        stroke={theme.colors[color]}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12.7763 9L11.1086 15.0025" stroke={theme.colors[color]} strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
};

export default Icon;
