import { Loader } from "components";
import { lazyLoad } from "utils/loadable";

export const MyEscrowsTable = lazyLoad(
  () => import("./my-escrows-table"),
  module => module.default,
  { fallback: <Loader width="100%" height="100%" /> },
);

export const WidgetsTable = lazyLoad(
  () => import("./widgets-table"),
  module => module.default,
  { fallback: <Loader width="100%" height="100%" /> },
);
