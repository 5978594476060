import { ModalDeleteIcon, ModalSuccessIcon } from "components/svg";

import { useTranslation } from "context";

import { hideModal, showModal } from "store/modal";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  createWhitelistItem,
  getWhitelist,
  getWhitelistWithdrawal,
  sendCodesForWhitelist,
} from "store/wallets/whitelist/actions";

import { isErrorResult } from "services";

import { ModalProps } from "store/modal/types";
import { TwoFactorConfirmModalProps } from "../../two-factor-confirm-modal/types";
import { CreateWhitelistPayload, SendCodesPayloadWhiteList } from "store/wallets/types";
import { ActionModalProps } from "components/modal/components/action-modal/types";
import { selectCombinedData } from "./selectots";

const useShowModals = () => {
  const { rootId, page, size, chainId, search, withdrawalPage, withdrawalSize } = useAppSelector(selectCombinedData);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const closeModalHandler = () => {
    dispatch(
      getWhitelist({
        page,
        size,
        // chainId: chainId ? chainId : undefined due to BE enum not accepting empty row
        search: search ? search : undefined,
        chainId: chainId ? chainId : undefined,
      }),
    );
    dispatch(getWhitelistWithdrawal({ page: withdrawalPage, size: withdrawalSize }));
    dispatch(hideModal());
  };

  const whitelistRejectModal = (text: string) => {
    const _showModal = showModal as ModalProps<ActionModalProps>;

    dispatch(
      _showModal({
        modalName: "actionModal",
        rootId,
        props: {
          icon: <ModalDeleteIcon height="72px" width="72px" />,
          title: t("Rejected"),
          text,
          btnArr: [
            {
              buttonText: t("OK"),
              callback: () => dispatch(hideModal()),
            },
          ],
        },
      }),
    );
  };

  const callSuccessModal = () => {
    const _showModal = showModal as ModalProps<ActionModalProps>;

    dispatch(
      _showModal({
        modalName: "actionModal",
        rootId,
        clickOutsideHandler: closeModalHandler,
        props: {
          icon: <ModalSuccessIcon height="72px" width="72px" />,
          title: t("Address Added Successfully"),
          text: t("You will be able to use the address in 24 hours"),
          btnArr: [
            {
              buttonText: t("OK"),
              callback: closeModalHandler,
            },
          ],
        },
      }),
    );
  };

  const callTwoFactorModal = (data: SendCodesPayloadWhiteList) => {
    const _showModal = showModal as ModalProps<
      TwoFactorConfirmModalProps<CreateWhitelistPayload, SendCodesPayloadWhiteList>
    >;

    dispatch(
      _showModal({
        modalName: "twoFactorConfirmModal",
        rootId,
        props: {
          title: t("Security verification"),
          propsForAction: { otps: { emailOtp: "", authenticatorOtp: "" } },
          propsForResend: data,

          callbackForAction: createWhitelistItem,
          callbackForSuccess: callSuccessModal,
          callbackForResend: sendCodesForWhitelist,
        },
      }),
    );
  };

  const handleVerifyCallback = (data: SendCodesPayloadWhiteList) => {
    const dataWithOtp = { ...data, sendOtp: true };

    dispatch(sendCodesForWhitelist(dataWithOtp)).then(res => {
      if (!isErrorResult(res.payload)) {
        callTwoFactorModal(dataWithOtp);
      }
    });
  };

  return { whitelistRejectModal, callTwoFactorModal, callSuccessModal, handleVerifyCallback };
};

export default useShowModals;
