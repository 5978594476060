import BigNumber from "bignumber.js";

import { useAppSelector } from "store/store";

import { formatValueToBNString } from "configs/web3";

import { useAmountToDepositHandlersArgs } from "./types";

const useAmountToDepositHandlers = <T>({
  setFieldValue,
  setFieldTouched,
  tokenBalance,
  currentRate,
}: useAmountToDepositHandlersArgs<T>) => {
  const minAmountToDepositInFiat = useAppSelector(
    state => state.walletsFee.fiatFeeColdStorage.minAmountToDeposit.inFiat,
  );

  const isHotWalletMaxButtonDisabled = BigNumber(formatValueToBNString(BigNumber(tokenBalance ?? "0"))).lte(0);

  const isColdStorageMaxBtnDisabled = BigNumber(
    formatValueToBNString(BigNumber(tokenBalance ?? "0").times(currentRate ?? 0)),
  ).lt(minAmountToDepositInFiat);

  const isColdStorageMinBtnDisabled = BigNumber(
    formatValueToBNString(BigNumber(tokenBalance ?? "0").times(currentRate ?? 0)),
  ).lt(minAmountToDepositInFiat);

  const onUserInput = async (value: string, fieldName = "amount") => {
    await setFieldValue(fieldName, value);
  };

  const onBlur = async (fieldName = "amount") => {
    await setFieldTouched(fieldName, true);
  };

  return {
    onUserInput,
    onBlur,
    isHotWalletMaxButtonDisabled,
    isColdStorageMaxBtnDisabled,
    isColdStorageMinBtnDisabled,
  };
};

export default useAmountToDepositHandlers;
