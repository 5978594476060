export enum TransactionOperationEnum {
  ESCROW = "escrow",
  DEPOSIT = "deposit",
  WITHDRAW = "withdraw",
  DEPOSIT_TO_COLD = "deposit_to_cold",
  WITHDRAW_FROM_COLD = "withdraw_from_cold",
  SUBSCRIPTION_FEE = "subscription_fee",
  SWAP = "swap",
  UNKNOWN = "unknown",
}

export enum HotWalletTransactionsTypeEnum {
  WALLET_DEPOSIT = "deposit",
  WALLET_WITHDRAW = "withdraw",
  CRYPTO_PURCHASE = "crypto_purchase",
  ESCROW_BROKER_INCOME = "escrow_broker_income",
  ESCROW_SELLER_INCOME = "escrow_seller_income",
  ESCROW_BUYER_OUTCOME = "escrow_buyer_outcome",
  ESCROW_BUYER_REFUND = "escrow_buyer_refund",
  SWAP_INCOME_ADDITIONAL = "SWAP_INCOME_ADDITIONAL",
  OPERATIONAL_GAS_STATION = "operational_gas_station_refill",
  DEPOSIT_TO_COLD_WALLET = "deposit_to_cold_wallet",
  WITHDRAW_FROM_COLD_WALLET = "withdraw_from_cold_wallet",
  COLD_WALLET_SUBSCRIPTION_FEE_REGULAR = "cold_wallet_subscription_fee_regular_charge",
  COLD_WALLET_SUBSCRIPTION_FEE_DEBT = "cold_wallet_subscription_fee_debt_charge",
  SWAP_INCOME = "swap_income",
  SWAP_OUTCOME = "swap_outcome",
}

export enum TransactionTypeEnum {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  CANCELED = "CANCELED",
  REQUESTED = "REQUESTED",
  REJECTED = "REJECTED",
  UNDER_CANCELLATION = "UNDER_CANCELLATION",
  ACTIVE = "ACTIVE",
  BLACKLISTED = "BLACKLISTED",
  UNKNOWN = "UNKNOWN",
}

export enum WhitelistStateEnum {
  active = "active",
  pending = "pending",
  blacklisted = "blacklisted",
}

export const whitelistEnumStatusToStatus = {
  [WhitelistStateEnum.active]: TransactionTypeEnum.ACTIVE,
  [WhitelistStateEnum.pending]: TransactionTypeEnum.PENDING,
  [WhitelistStateEnum.blacklisted]: TransactionTypeEnum.BLACKLISTED,
};

export enum TransactionSubStatusEnum {
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  AMOUNT_TOO_SMALL = "AMOUNT_TOO_SMALL",
  UNSUPPORTED_ASSET = "UNSUPPORTED_ASSET",
  UNAUTHORISED__MISSING_PERMISSION = "UNAUTHORISED__MISSING_PERMISSION",
  INVALID_SIGNATURE = "INVALID_SIGNATURE",
  API_INVALID_SIGNATURE = "API_INVALID_SIGNATURE",
  UNAUTHORISED__MISSING_CREDENTIALS = "UNAUTHORISED__MISSING_CREDENTIALS",
  UNAUTHORISED__USER = "UNAUTHORISED__USER",
  UNAUTHORISED__DEVICE = "UNAUTHORISED__DEVICE",
  INVALID_UNMANAGED_WALLET = "INVALID_UNMANAGED_WALLET",
  INVALID_EXCHANGE_ACCOUNT = "INVALID_EXCHANGE_ACCOUNT",
  INSUFFICIENT_FUNDS_FOR_FEE = "INSUFFICIENT_FUNDS_FOR_FEE",
  INVALID_ADDRESS = "INVALID_ADDRESS",
  WITHDRAW_LIMIT = "WITHDRAW_LIMIT",
  API_CALL_LIMIT = "API_CALL_LIMIT",
  ADDRESS_NOT_WHITELISTED = "ADDRESS_NOT_WHITELISTED",
  TIMEOUT = "TIMEOUT",
  CONNECTIVITY_ERROR = "CONNECTIVITY_ERROR",
  THIRD_PARTY_INTERNAL_ERROR = "THIRD_PARTY_INTERNAL_ERROR",
  CANCELLED_EXTERNALLY = "CANCELLED_EXTERNALLY",
  INVALID_THIRD_PARTY_RESPONSE = "INVALID_THIRD_PARTY_RESPONSE",
  VAULT_WALLET_NOT_READY = "VAULT_WALLET_NOT_READY",
  MISSING_DEPOSIT_ADDRESS = "MISSING_DEPOSIT_ADDRESS",
  ONE_TIME_ADDRESS_DISABLED = "ONE_TIME_ADDRESS_DISABLED",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  AUTHORIZER_NOT_FOUND = "AUTHORIZER_NOT_FOUND",
  INSUFFICIENT_RESERVED_FUNDING = "INSUFFICIENT_RESERVED_FUNDING",
  MANUAL_DEPOSIT_ADDRESS_REQUIRED = "MANUAL_DEPOSIT_ADDRESS_REQUIRED",
  INVALID_FEE = "INVALID_FEE",
  ERROR_UNSUPPORTED_TRANSACTION_TYPE = "ERROR_UNSUPPORTED_TRANSACTION_TYPE",
  UNSUPPORTED_OPERATION = "UNSUPPORTED_OPERATION",
  THIRD_PARTY_PROCESSING = "3RD_PARTY_PROCESSING",
  PENDING_BLOCKCHAIN_CONFIRMATIONS = "PENDING_BLOCKCHAIN_CONFIRMATIONS",
  THIRD_PARTY_CONFIRMING = "3RD_PARTY_CONFIRMING",
  CONFIRMED = "CONFIRMED",
  THIRD_PARTY_COMPLETED = "3RD_PARTY_COMPLETED",
  REJECTED_BY_USER = "REJECTED_BY_USER",
  CANCELLED_BY_USER = "CANCELLED_BY_USER",
  THIRD_PARTY_CANCELLED = "3RD_PARTY_CANCELLED",
  THIRD_PARTY_REJECTED = "3RD_PARTY_REJECTED",
  REJECTED_AML_SCREENING = "REJECTED_AML_SCREENING",
  BLOCKED_BY_POLICY = "BLOCKED_BY_POLICY",
  FAILED_AML_SCREENING = "FAILED_AML_SCREENING",
  PARTIALLY_FAILED = "PARTIALLY_FAILED",
  THIRD_PARTY_FAILED = "3RD_PARTY_FAILED",
  DROPPED_BY_BLOCKCHAIN = "DROPPED_BY_BLOCKCHAIN",
  REJECTED_BY_BLOCKCHAIN = "REJECTED_BY_BLOCKCHAIN",
  INVALID_FEE_PARAMS = "INVALID_FEE_PARAMS",
  MISSING_TAG_OR_MEMO = "MISSING_TAG_OR_MEMO",
  SIGNING_ERROR = "SIGNING_ERROR",
  GAS_LIMIT_TOO_LOW = "GAS_LIMIT_TOO_LOW",
  TOO_MANY_INPUTS = "TOO_MANY_INPUTS",
  MAX_FEE_EXCEEDED = "MAX_FEE_EXCEEDED",
  ACTUAL_FEE_TOO_HIGH = "ACTUAL_FEE_TOO_HIGH",
  INVALID_CONTRACT_CALL_DATA = "INVALID_CONTRACT_CALL_DATA",
  INVALID_NONCE_TOO_LOW = "INVALID_NONCE_TOO_LOW",
  INVALID_NONCE_TOO_HIGH = "INVALID_NONCE_TOO_HIGH",
  INVALID_NONCE_FOR_RBF = "INVALID_NONCE_FOR_RBF",
  FAIL_ON_LOW_FEE = "FAIL_ON_LOW_FEE",
  TOO_LONG_MEMPOOL_CHAIN = "TOO_LONG_MEMPOOL_CHAIN",
  TX_OUTDATED = "TX_OUTDATED",
  INCOMPLETE_USER_SETUP = "INCOMPLETE_USER_SETUP",
  SIGNER_NOT_FOUND = "SIGNER_NOT_FOUND",
  INVALID_TAG_OR_MEMO = "INVALID_TAG_OR_MEMO",
  ZERO_BALANCE_IN_PERMANENT_ADDRESS = "ZERO_BALANCE_IN_PERMANENT_ADDRESS",
  NEED_MORE_TO_CREATE_DESTINATION = "NEED_MORE_TO_CREATE_DESTINATION",
  NON_EXISTING_ACCOUNT_NAME = "NON_EXISTING_ACCOUNT_NAME",
  ENV_UNSUPPORTED_ASSET = "ENV_UNSUPPORTED_ASSET",
}

export enum WalletsTypeEnum {
  hot = "hot",
  cold = "cold",
}
